import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';

const CurrentPanel = ({panelId, links = [], currentTitle=null, defaultState='expand'}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: `/brand/panel/${panelId}/home`, title: 'Home', ifActive: '' },
        {path: `/brand/panel/${panelId}/edit`, title: 'Edit', ifActive: '' },
        {path: `/brand/panel/${panelId}/filter`, title: 'Filter', ifActive: '' },
        {path: `/brand/panel/${panelId}/preview`, title: 'Preview', ifActive: ''},
        {path: `/brand/panel/${panelId}/submissions`, title: 'Job Submitters', ifActive: ''},
        {path: `/brand/panel/${panelId}/email/tracker`, title: 'Email Tracker', ifActive: ''}
    ];

    //set current
    if(currentTitle) {
        for(let k in defaultLinks) {
            if(defaultLinks[k]['title'] == currentTitle)
                defaultLinks[k]['ifActive'] = 'active-item';
        }
    }


    return (
        <SidebarTemplate heading="Current Job"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-campaign-icon" />
    );
}

export default CurrentPanel;
