import axios from "axios";
import CONFIG from ".";

class ServerToken {

    constructor() {
        this.token = '';
        this.clientId = CONFIG['tokenGeneration']['clientId'];
        this.clientSecret = CONFIG['tokenGeneration']['clientSecret'];
        this.fetchToken();
    }

    fetchToken() {
        const data = {
            grant_type: 'client_credentials',
            client_id: this.clientId,
            client_secret: this.clientSecret
        }
        axios.post(`${CONFIG['apiBaseOauth']}oauth/token`, data).then(xhrResponse => {
            if (xhrResponse.status === 200 && xhrResponse.data.access_token) {
                this.token = xhrResponse.data.access_token;
            }
            console.log('Server Oauth Token updated');
        }).catch(error => {
            console.log('Error fetching server oauth token - ', error);
        });
    }

}

export default new ServerToken();