import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../actions';
import MobWelcomeFaqComponent from '../../../../components/Mob/Welcome/Faq';
import { translate } from 'react-i18next';
import CONFIG from '../../../../config';
import { myIsSet } from '../../../../helpers'

const Faq = (props) => {

    return (
        <div>
        	<MobWelcomeFaqComponent t={props.t}
                welcomeUrl={`../welcome?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
        		welcomeFeedbackUrl={`../welcome/feedback?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
        	/>
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Faq));
