import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseLayout from '../../../../../components/Layout/Brand/BaseLayout';
import Add from '../../../../../components/Brand/Credits/Evoucher/Add';
import { useCountryOptions } from '../../../../../hooks/generic';
import {
    brandCreditsAddEvoucherProvider,
    brandCreditsEvoucherProviderList,
    brandCreditsEvoucherTemplateDownload,
    brandCreditsEvoucherTemplateUpload
} from '../../../../../actions';
import Loader from '../../../../../components/UIElems/Loader';
import notification from '../../../../../components/UIElems/Notification';
import AddProviderModal from '../../../../../components/Brand/Credits/Evoucher/Provider/Add/Modal';


const BrandCreditsEvoucherAdd = props => {
    const [providerList, setProviderList] = useState([]);
    const [evoucherProvider, setEvoucherProvider] = useState("");
    const [evoucherFile, setEvoucherFile] = useState();
    const [isAddProviderModalVisible, setIsAddProviderModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    
    const [countryOptions, setCountryOptions] = useCountryOptions();

    useEffect(() => {
        setIsLoading(true);
        props.actions.brandCreditsEvoucherProviderList()
        .then(data => {
            if (!data.err)
                setProviderList(data.data.list);
            else
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
            setIsLoading(false);
        });
    }, [forceRefresh])

    const onEvoucherProviderChanged = e => {
        if (e.target.value === 'add-provider') {
            setIsAddProviderModalVisible(true);
        } else {
            setEvoucherProvider(e.target.value);
        }
    }

    const onAddProvider = data => {
        setIsLoading(true);
        props.actions.brandCreditsAddEvoucherProvider(data)
        .then(data => {
            setIsLoading(false);
            if (!data.err) {
                setForceRefresh(!forceRefresh);
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Successfully added new provider!', type: 'success' });
            } else {
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error' });
            }
        });
    }

    const onTemplateDownloadButtonClicked = e => {
        if (!evoucherProvider || evoucherProvider === 'add-provider' || evoucherProvider === '') {
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly select a provider!', type: 'error'});
        }
        props.actions.brandCreditsEvoucherTemplateDownload(evoucherProvider)
        .then(data => {
            const buffer = Buffer.from(data.data, 'base64');
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Evoucher_Template_${evoucherProvider}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    }

    const onFileUpload = e => {
        const file = e.target.files[0];
        const ext = file.name.split('.')[1];
        if (['xlsx', 'xls'].includes(ext) && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
            setEvoucherFile(e.target.files[0]);
        } else {
            notification.send({ device: props.rdx.appReducer.deviceType, message: 'Please upload either an xlsx or an xls file only!', type: 'error' });
        }
    }

    const onSubmit = () => {
        if (!evoucherFile) {
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload excel with the codes!', type: 'error'});
        }
        setIsLoading(true);
        evoucherFile.arrayBuffer().then(arrayBuffer => {
            const buffer = Buffer.from(arrayBuffer, 'base64');
            props.actions.brandCreditsEvoucherTemplateUpload(buffer)
            .then(data => {
                setIsLoading(false);
                if (!data.err) {
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Successfully uploaded excel. Please review the codes on the next page!', type: 'success'});
                    setTimeout(() => {
                        window.location = `/brand/credits/evoucher/preview/${data.data.batchId}`
                    }, 1000);
                } else {
                    const errMsg = data.msg ?
                        data.msg :
                        'Something went wrong!';
                    notification.send({device: props.rdx.appReducer.deviceType, message: errMsg, type: 'error'});
                }
            });
        });
    }

    const onCancel = () => {
        window.location = '/brand/credits/evoucher/all';
    }

    const sendNotification = (msg, type) => {
        notification.send({device: props.rdx.appReducer.deviceType, message: msg, type: type});
    }

    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-credits">
                <h1>Upload VIP eVoucher</h1>
            </div>
            <Add evoucherProvider={evoucherProvider}
                providerList={providerList}
                onEvoucherProviderChanged={onEvoucherProviderChanged}
                onTemplateDownloadButtonClicked={onTemplateDownloadButtonClicked}
                onFileUpload={onFileUpload}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
            <AddProviderModal
                isAddProviderModalVisible={isAddProviderModalVisible}
                setIsAddProviderModalVisible={setIsAddProviderModalVisible}
                countryOptions={countryOptions}
                onAddProvider={onAddProvider}
                sendNotification={sendNotification}
                isEdit={false}
                provider={null}
            />
            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state})

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCreditsAddEvoucherProvider,
        brandCreditsEvoucherProviderList,
        brandCreditsEvoucherTemplateDownload,
        brandCreditsEvoucherTemplateUpload
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandCreditsEvoucherAdd);