import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { 
    influencerRegisterProfileDefaultsAction,
    influencerRegisterKidsDefaultsAction,
    influencerRegisterProfileSave,
    influencerRegisterKidsSave
} from '../../../../actions';
import Loader from '../../../../components/UIElems/Loader';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import ProfileEditForm from '../../../../components/Influencer/Profile/Edit/Profile';
import KidsEditForm from '../../../../components/Influencer/Profile/Edit/Kids';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';
import Tabs from '../../../../components/UIElems/Tabs';
import CreditSection from '../../../../components/Common/CreditSection';
const Profile = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const t = props.t;
    //defaults
    useEffect(() => {
        //profile
        props.actions.influencerRegisterProfileDefaultsAction(props.rdx.influencerReducer.id)
        //kids
        props.actions.influencerRegisterKidsDefaultsAction(props.rdx.influencerReducer.id)
    }, [])

    const onTabChange = (activeKey) => {
    }

    const onProfileSubmit = (data) => {
        setIsLoading(true);
        props.actions.influencerRegisterProfileSave(data)
        .then(response => {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
            if(!response.err) window.location.reload(true);
        })
        .catch(error => console.log(error))
    }

    const onKidsSubmit = (data) => {
        setIsLoading(true);
        data['influencerId'] = props.rdx.influencerReducer.id;
        props.actions.influencerRegisterKidsSave(data)
        .then(response => {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
            if(!response.err) window.location.reload(true);
        })
        .catch(error => console.log(error))
    }

    return (
       
        <BaseLayout leftCards={[]}>
            <CreditSection t={props.t} loaded={!!props.rdx.influencerReducer.registerProfileDefaults} creditBalance={(props.rdx.influencerReducer.registerProfileDefaults || {}).vipCreditBalance || 0}/>
            <Tabs tabsHandler={onTabChange}
                elems={[
                    {
                        name: t("registration_label_profile"),
                        elem: (
                            <ProfileEditForm onSubmit={onProfileSubmit}
                                values={props.rdx.influencerReducer.registerProfileDefaults}
                                t={props.t}
                                dispatch={props.dispatch} />
                        )
                    },
                    {
                        name: t("registration_label_kids"),
                        elem: (
                            <KidsEditForm onSubmit={onKidsSubmit}
                            values={props.rdx.influencerReducer.registerKidsDefaults}
                            profile={props.rdx.influencerReducer.registerProfileDefaults}
                            t={props.t} />
                        )
                    }
                ]} />           

            { isLoading && <Loader /> }    
        </BaseLayout>
        
        
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerRegisterProfileDefaultsAction,
        influencerRegisterKidsDefaultsAction,
        influencerRegisterProfileSave,
        influencerRegisterKidsSave
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Profile));