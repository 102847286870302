import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';

const Sidebar = ({heading, iconClass, links = [], defaultState='expand', t}) => {
    const [navState, setNavState] = useState(defaultState);

    const onClick = () => {
        setNavState((navState == 'expand') ? 'collapse' : 'expand');
    }

    const onListItemClicked = (e, d) => {
        if(d.onClick) {
            d.onClick(e);
        }
    }
    
    return (
       <div>
            <div className={cx("toggle-navigation")}>
                <div onClick={onClick} className={cx("nav-heading")}>
                    <h3>
                        <span className={cx("campaign-sidebar-nav-sprite", "sidebar-icon-spacing", iconClass)}></span>
                        <span className={cx("navigation-title")}>{ heading }</span>
                    </h3>
                    <span className={cx("campaign-sidebar-nav-sprite", `sidebar-${navState}-chevron-icon`)}></span>
                </div>
                <ul className={cx(`sidebar-container-${navState}`)}>
                    {
                        [...links].map((d, i) => {
                            return <li key={i} className={d.ifActive} onClick={(e) => onListItemClicked(e, d)}>
                                <a href={d.path}>{d.title}</a>
                            </li>
                        })
                    }
                </ul>
            </div>
       </div>
    );
}

export default translate()(Sidebar);