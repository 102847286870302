import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../actions';
import { useBankList, useInfluencerPaymentDetails } from '../../../../hooks/generic';
import MobCampaignDetailComponent from '../../../../components/Mob/Campaign/Detail';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';


const CampaignDetail = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);

    const onCopy = () => {
        notification.send({device: props.rdx.appReducer.deviceType, message: 'Copied!', type: 'success'});
    }

    return (
        <MobCampaignDetailComponent data={props.rdx.webviewReducer.campaignDetail}
            shareUrl={props.rdx.webviewReducer.shareUrl}
            campaignApplicationStatus={props.rdx.webviewReducer.campaignApplicationStatus}
            deadlineDetails={props.rdx.webviewReducer.deadlineDetails}
            declineDetails={props.rdx.webviewReducer.declineDetails}
            t={props.t}
            onCopy={onCopy}
            deviceType={props.rdx.appReducer.deviceType}
            profile={props.rdx.webviewReducer.profile} />
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignDetail));