import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import crypto from 'crypto';
import Modal from '../../../UIElems/Modal';

const EmailLogin = ({emailCheck, onEmailLogin, sendNotification, onPasswordForgot, t}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');    
    const [name, setName] = useState('');
    const [shouldDisplayName, setShouldDisplayName] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [passwordForgotEmail, setPasswordForgotEmail] = useState('');

    useEffect(() => {
        const timeout = setTimeout(() => onEmailChange(email), 300);
        return () => clearTimeout(timeout);
    }, [email])

    const onPasswordForgotClick = (e) => {
        setIsModalOpen(true);
    }

    const onPasswordForgotModalClose = () => {
        setIsModalOpen(false);
    }

    const onPasswordForgotModalOk = () => {
        if(validateEmail(passwordForgotEmail)) {
            onPasswordForgot(passwordForgotEmail);
        } else {
            sendNotification('Enter a proper email address!', 'error');
        }        
    }

    const validateEmail = (email) => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }

    const onEmailChange = (email) => {
        setIsLoading(true);
        if(validateEmail(email)) {
            setIsEmailValid(true);
            // fire api
            emailCheck(email, emailCount => {
                setIsLoading(false);
                setShouldDisplayName(!emailCount);
            });
        } else {
            setIsEmailValid(false);
            setIsLoading(false);
            setShouldDisplayName(false);
        }
    }

    const onEmailSignupSubmit = (e) => {
        e.preventDefault();
        if(shouldDisplayName && !name) return sendNotification('Enter name!', 'error');
        if(password.length < 6) return sendNotification('min 6 characters required!', 'error');
        let key = new Buffer('tickled012345678', 'utf8');
        let plaintext = new Buffer(password, 'utf8');
        let cipher = crypto.createCipheriv("aes-128-ecb", key, Buffer.alloc(0));
        cipher.setAutoPadding(true);
        let ciphertext = Buffer.concat([
            cipher.update(plaintext, 'utf8'),
            cipher.final()
        ]);
        let pwd = ciphertext.toString('base64');
        //
        onEmailLogin({
            email: email,
            name: name,
            password: pwd
        });
    }

    return (
        <div className={cx("login-using-email")}>

        <div className={cx("or-login")}></div>

            <form onSubmit={onEmailSignupSubmit}>
                <div className={cx("login-field-wrap")}>
                    <div className={cx("login-field", "email")}>
                        <input id="input-user-email" name="input_user_email" type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" autoComplete="off" />
                    </div>

                    {
                        shouldDisplayName &&
                        <div className={cx("login-field")} >
                            <input id="input-user-name" name="input_user_name" type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
                        </div>

                    }                    

                    <div className={cx("login-field")} >
                        <input id="input-user-password" name="input_user_password" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder={t("new_login_password")} />
                        <p>
                            <a onClick={e => onPasswordForgotClick(e)}>{t("new_login_forgot_password")}?</a>
                        </p>
                    </div>
                </div>
                <div className={cx("btn-login-wrap", "animation-2")}>
                    <button className={cx("btn", "btn-primary", "btn-login-using-email", "pulse", (isLoading || !isEmailValid) && "disabled")} type="submit" disabled={isLoading || !isEmailValid}>{t("login_login_using_email")}</button>
                </div>
            </form>
            <Modal title="Forgot Password"
                openModal={isModalOpen}
                onClose={onPasswordForgotModalClose}
                onOk={onPasswordForgotModalOk} >

                <div className={cx("form-group")}>
                    <label htmlFor="forgot-password-email">Email: </label>
                    <input id="forgot-password-email" type="text" value={passwordForgotEmail} onChange={e => setPasswordForgotEmail(e.target.value)} size="40" />
                </div>

            </Modal>
        </div>
    );
}

export default EmailLogin;