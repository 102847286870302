import React from 'react';
import { useState } from 'react';
import cx from 'classnames/bind';
import {connect} from 'react-redux';
import { useJobList, useCountryList } from '../../../../hooks/generic';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import { brandPanelActivate } from '../../../../actions';
import { brandCampaignActivate } from '../../../../actions';
import { bindActionCreators } from 'redux';

const JobList = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [countryIdFilter, setCountryIdFilter] = useState('');
    const [isActiveFilter, setIsActiveFilter] = useState('');
    const [jobTypeFilter, setJobTypeFilter] = useState('vip_all');
    //conditions
    const conditions = [ 
        ...('conditions' in props && props.conditions)
    ];
    //extra
    const extra = {
        ...('extra' in props && props.extra),
        countryId: countryIdFilter,
        isActive: isActiveFilter
    };

    const countryList = useCountryList();

    const jobList = useJobList(
        () => setLoading(false),
        props.dispatch,
        props.rdx.brandReducer.brandId,
        page,
        pageSize,
        sorted,
        filtered,
        conditions,
        extra,
        false,
        false,
        false,
        forceUpdate,
        jobTypeFilter
    );

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        const id = rowData.id;
        if (rowData.job_type === 'vip_campaign') {
            links.push({title: 'Edit', handler: () => window.location = `/brand/campaign/${id}/edit`});
            links.push({title: 'Filter', handler: () => window.location = `/brand/campaign/${id}/filter`});
            links.push({title: 'Preview', handler: () => window.location = `/brand/campaign/${id}/preview`});
            links.push({title: 'Manage Influencers', handler: () => window.location = `/brand/campaign/${id}/applications`});
            links.push({title: 'Moderation', handler: () => window.location = `/brand/campaign/${id}/moderation`});
            links.push({title: 'Submissions', handler: () => window.location = `/brand/campaign/${id}/submissions`});
            links.push({title: 'Feedback', handler: () => window.location = `/brand/campaign/${id}/feedback/answers`});

            const campaignActivate = (campaignId, flag) => {
                props.actions.brandCampaignActivate(campaignId, flag)
                .then(() => setForceUpdate(!forceUpdate));
            }

            if(rowData.isActiveByDate) {
                links.push({
                    title: rowData.isActive ? 'Deactivate' : 'Activate',
                    handler: () => campaignActivate(id, rowData.isActive ? 0 : 1)
                });
            }
        } else if(rowData.job_type === 'vip_content') {
            links.push({title: 'Home', handler: () => window.location = `/brand/content-creator-jobs/${id}/home`});
            links.push({title: 'Edit', handler: () => window.location = `/brand/content-creator-jobs/${id}/edit`});
            links.push({title: 'Filter', handler: () => window.location = `/brand/content-creator-jobs/${id}/filter`});
            links.push({title: 'Preview', handler: () => window.location = `/brand/content-creator-jobs/${id}/preview`});
        } else {
            links.push({title: 'Home', handler: () => window.location = `/brand/panel/${id}/home`});
            links.push({title: 'Edit', handler: () => window.location = `/brand/panel/${id}/edit`});
            links.push({title: 'Filter', handler: () => window.location = `/brand/panel/${id}/filter`});
            links.push({title: 'Preview', handler: () => window.location = `/brand/panel/${id}/preview`});
            const panelActivate = (panelId, flag) => {
                props.actions.brandPanelActivate(panelId, flag)
                .then(() => setForceUpdate(!forceUpdate));
            }
            if(rowData.isActiveByDate) {
                links.push({
                    title: rowData.isActive ? 'Deactivate' : 'Activate',
                    handler: () => panelActivate(id, rowData.isActive ? 0 : 1)
                });
            }
        }

        return <MenuPopper title='Manage' links={links} />
    }

    const columns = [
        {
            id: "clientName",
            Header: "Client",
            accessor: "",
            filterable: true,
            sortable: false,
            Footer: "Total: " + jobList.total,
            Cell: props => props.value.clientName? props.value.clientName: props.value.client_name
        },
        {
            Header: "Job Type",
            accessor: "",
            sortable: false,
            Cell: props => props.value.job_type === 'vip_campaign'? 'Influencer': (props.value.job_type === 'vip_content' ? "Content Creator" : 'Panel')
        },
        {
            id: "brandName",
            Header: "Brand",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props => props.value.brandName? props.value.brandName: props.value.brand_name
        },
        {
            id: "jobName",
            Header: "Job Title",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props => {
                if (props.value.job_type === 'vip_campaign') {
                    return <a href={`/brand/campaign/${props.value.id}/home`}>{props.value.jobName}</a>
                } else if (props.value.job_type === 'vip_content') {
                    return <a href={`/brand/content-creator-jobs/${props.value.id}/home`}>{props.value.jobName}</a>
                } else {
                    return <a href={`/brand/panel/${props.value.id}/home`}>{props.value.jobName}</a>
                }
            } 
        },
        {
            Header: "Country",
            accessor: "countryCode",
            filterable: false,
            sortable: false
        },
        {
            Header: "Created By",
            accessor: "createdByName",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Last Edited By",
            accessor: "editedByName",
            filterable: false,
            sortable: false
        },
        {
            Header: "Last Updated At",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => props.value.lastUpdatedAt ? format(props.value.lastUpdatedAt, 'DD-MM-YYYY') :
                            props.value.updated_at ? format(props.value.updated_at, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Is Active",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => props.value.isActive === 1 ? <span className={cx("status-bg-green", "status-text-green", "status-style")}>Yes</span> : <span className={cx("status-bg-red", "status-text-red", "status-style")}>No</span>
        },
        {
            Header: "Start Date",
            accessor: "",
            filterable: false,
            sortable: true,
            Cell: props => props.value.startDate ? format(props.value.startDate, 'DD-MM-YYYY') :
                            props.value.start_date ? format(props.value.start_date, 'DD-MM-YYYY') : ''
        },
        {
            Header: "End Date",
            accessor: "",
            filterable: false,
            sortable: true,
            Cell: props => props.value.endDate ? format(props.value.endDate, 'DD-MM-YYYY') :
                            props.value.end_date ? format(props.value.end_date, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Submission End Date",
            accessor: "submissionEndDate",
            filterable: false,
            sortable: true,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Manage",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div className="">
            <div className="user-filters">
                <div className="form-horizontal-row">
                    <div className="form-wrap">
                        <label htmlFor="country-list">Country: </label>
                        <select id="country-list" value={countryIdFilter} onChange={e => setCountryIdFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                    return <option value={country.id} key={i}>{country.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="form-wrap">
                        <label htmlFor="is-active">Is Active: </label>
                        <select id="is-active" value={isActiveFilter} onChange={e => setIsActiveFilter(e.target.value)}>
                            <option value="">select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                    <div className="form-wrap">
                        <label htmlFor="job-type">Job Type: </label>
                        <select className="drop-down" id="job-type" value={jobTypeFilter} onChange={e => setJobTypeFilter(e.target.value)}>
                            <option value="vip_all">All</option>
                            <option value="vip_campaign">Influencer</option>
                            <option value="vip_panel">Panel</option>
                            <option value="vip_content">Content Creator</option>
                        </select>
                    </div>

                    <div className="form-wrap">
                        <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceUpdate(!forceUpdate)}>Fetch</button>
                    </div>
                </div>
            </div>                
            <Table
                columns={columns}
                manual
                data={jobList.list}
                pages={Math.ceil(jobList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignActivate,
        brandPanelActivate
    }, dispatch),
    dispatch
})

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

export default connect(mapStateToProps, mapDispatchToProps)(JobList);
