import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Menu, Dropdown, Icon } from 'antd';

const MenuPopper = ({title, links = [], showTitle = false}) => {
    const menu = (
        <Menu>
            {
                links.map((d, i) => {
                    return <Menu.Item key={i}>
                        {
                            d.extraElem
                            ? 
                                d.extraElem
                            :
                                <a onClick={d.handler}>
                                    {d.icon ? <span>{d.icon}&nbsp;&nbsp;{d.title}</span> : d.title} 
                                </a>
                        }
                    </Menu.Item>
                })
            }
        </Menu>
    );


    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" href="#!">
                {
                    showTitle
                    ? 
                        <Fragment>
                            {title} <Icon type="down" />
                        </Fragment>                        
                    : 
                        <Fragment>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/dot-dot.svg" width="10px" alt=""/>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/dot-dot.svg" width="10px" alt=""/>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/dot-dot.svg" width="10px" alt=""/>
                        </Fragment> 
                }                
            </a>
        </Dropdown>
    );
}

export default MenuPopper;
