import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getRandomHexColor } from '../../../../utils';

const LineChartElem = (props) => {
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);
    const [colorByKey, setColorByKey] = useState(null);

    const chartRef = useRef(null);

    const transformData = (data) => {
        let mData = [];
        let keys = {};        
        if(data.length) {
            for(let i in data) {
                let line = data[i];                
                //data points
                let dataPoints = [];
                //key
                let key = line[0].axisAgainstKeys.split(', ')[0];
                keys[key] = 1;
                //
                dataPoints = line.map(d => {
                    let r = {};
                    r['name'] = d['axisBaseValue'];
                    r[key] = d[key];
                    setColorByKey(prev => ({...prev, [key + 'Color']: d[key + 'Color'] }));
                    return r;
                })
                mData = [...mData, ...dataPoints];
            }
        }
        setKeys(Object.keys(keys));
        setData(mData);
    }

    //transform data
    useEffect(() => {
        transformData(props.data);
    }, [props.refresh]);

    // const CustomizedLabel = (props) => {            
    //     return <text x={props.x} y={props.y} dy={-4} fill={'#8884d8'} fontSize={15} textAnchor="middle">Total: {props.value}</text>
    // }
    
    return (
        <LineChart data={data}
            width={'width' in props ? props.width : 400}
            height={'height' in props ? props.height : 250}>
           <XAxis dataKey="name" padding={{left: 40, right: 20}} />
           <YAxis/>
           <CartesianGrid strokeDasharray="3 3"/>
           <Tooltip/>
           <Legend />
           {
                keys.map(key => {
                    return <Line type="monotone" dataKey={key} stroke={colorByKey[key + 'Color']} activeDot={{r: 8}}/>
                })
                
            }
      </LineChart>
    );
};

export default LineChartElem;