import { Popover } from 'antd';
import React from 'react';

const DescriptionPopover = ({code, description = null}) => {
    const lines = description.split('\n');
    const content = (
        <div>
            {
                lines.map(line => <div>{line}</div>)
            }
        </div>
    )
    return (
        <Popover title="Description" content={content} placement="bottomRight">
            <span style={{cursor: "pointer", color: "#249aff"}}>{code}</span>
        </Popover>
    )
}

export default DescriptionPopover;