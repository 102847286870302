import React, { Fragment } from 'react';
import { implementBarChart, implementPieChart, implementLineChart } from './interface';
import BarChartNivo from './BarChartNivo';
import BarChartRecharts from './BarChartRecharts';
import PieChartRecharts from './PieChartRecharts';
import LineChartRecharts from './LineChartRecharts';

//bar chart
export const BarChart = implementBarChart(BarChartRecharts);
//pie chart
export const PieChart = implementPieChart(PieChartRecharts);
//pie chart
export const LineChart = implementLineChart(LineChartRecharts);