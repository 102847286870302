import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignSubmissions } from '../../../../../../hooks/generic';
import { brandCampaignSubmissionVerify, workerDaemonCall, triggerNotification } from '../../../../../../actions';
import Table from '../../../../../../components/Common/Table';
import MenuPopper from '../../../../../../components/UIElems/MenuPopperAntd';
import { Popover, Icon, Modal } from 'antd';
import notification from '../../../../../../components/UIElems/Notification';

const SubmissionFeedbackModal = ({rowData, callback}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");

    const onClick = () => {
        if(window.confirm('Send feedback?')) {            
            setIsModalVisible(true);
        }
    }

    const onOk = () => {
        if(!feedbackText.length) return alert("Feedback can't be empty!");
        //
        let msg = `${rowData.type}: ${rowData.url}\n` + feedbackText;
        // notification
        callback({
            eventType: 'submissionFeedback',
            entityId: rowData.influencerId,
            entityType: 'influencer',
            campaignId:  rowData.campaignId,
            customMessage: msg
        })
        .then(() => {
            setIsModalVisible(false);
            notification.send({device: 'desktop', message: 'Sent!', type: 'success'});
        })
    }

    return (
        <span>
            <a onClick={onClick} style={{color: 'inherit'}}>Send Feedback</a>
            <Modal
                title={'Feedback'}
                visible={isModalVisible}
                okText="Send"
                onOk={onOk}
                confirmLoading={isModalLoading}
                onCancel={e => setIsModalVisible(false)}
                maskClosable={false}>
                    <div>
                        <p>{`${rowData.type}: ${rowData.url}`}</p>
                        <textarea value={feedbackText} onChange={e => setFeedbackText(e.target.value)} rows="3" cols="50" />
                    </div>                    
            </Modal>
        </span>
    );
}

const ViewDetailModal = (props) => {
    return (
        <Modal
            title={'Campaign Submission Details'}
            visible={props.data && props.data.campaignId ? true : false}
            onCancel={e => props.callbackClose()} 
            footer={null}
        >   
            {
                props.data &&
                <Fragment>
                    <p><b>Url:</b> {props.data.url}</p>
                    <div>
                        <b>Description:</b> 
                        <p>{props.data.description}</p>
                    </div>
                    <p><b>Preview:</b> {props.data.preview}</p>
                    <p><b>Comment Count:</b> {props.data.commentCount || '-'}</p>
                    <p><b>Type:</b> {props.data.type}</p>
                </Fragment>
            }
        </Modal>
    )
}

const SubmissionTable = (props) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [forceUpdate, setForceUpdate] = useState(false);
    const submissionList = useBrandCampaignSubmissions(props.dispatch, props.campaignId, props.influencerId, page, pageSize, props.conditions, forceUpdate);
    
    const [campaignSubmissionDetail, setCampaignSubmissionDetail] = useState({})

    const onFetchData = (state, instance) => {
        setLoading(false);
        setPage(state.page + 1);
        setPageSize(state.pageSize);
    };

    const onSubmissionVerify = (submissionId, verify) => {
        props.actions.brandCampaignSubmissionVerify(submissionId, props.campaignId, props.influencerId, verify)
        .then(() => {
            setForceUpdate(!forceUpdate);
        })
    }

    const createActionLink = (rowData) => {
        let links = [];
        //view detail
        //submission feedback
        let submissionFeedbackLink1 = 
            <SubmissionFeedbackModal callback={props.actions.triggerNotification}
                rowData={{
                    campaignId: props.campaignId,
                    influencerId: props.influencerId,
                    type: rowData.type,
                    url: rowData.url
                }} />;
        links.push({title: 'View Detail', handler: () => setCampaignSubmissionDetail(rowData)});
        
        // approval
        const verificationLink = rowData.isVerified
            ? { title: 'Unverify', handler: () => onSubmissionVerify(rowData.id, 0) }
            : { title: 'Verify', handler: () => onSubmissionVerify(rowData.id, 1) };
        links.push(verificationLink);
        //feedback
        //submission feedback
        let submissionFeedbackLink = 
            <SubmissionFeedbackModal callback={props.actions.triggerNotification}
                rowData={{
                    campaignId: props.campaignId,
                    influencerId: props.influencerId,
                    type: rowData.type,
                    url: rowData.url
                }} />;
        links.push({title: 'Send Feedback', extraElem: submissionFeedbackLink});

        return <MenuPopper title='Manage' links={links} />
    }

    const verificationStatus = (isVerified) => {
        return <span style={{color: (isVerified ? 'green' : 'red')}}>{isVerified ? 'Yes' : 'No'}</span>;
    }

    const completedOn = (props) => {
        return props.jobStatus ? props.updatedAt : '';
    }

    const PreviewPopover = ({previewUrl}) => {
        const previewContent = previewUrl            
            ?  
                <div style={{width: 420, height: 340}}>
                    <a href={previewUrl} target="_blank">
                        <img src={previewUrl} alt="preview"/>
                    </a>                    
                </div>
            : 
                <div>No preview available</div>;

        return (
            <Popover content={previewContent} title="Preview - click to enlarge">
                {
                    previewUrl
                    ? <Icon type="camera" />
                    : <span>no preview</span>
                }
            </Popover>
        );
    }

    const columns = [
        {
            Header: "Url",
            accessor: "url",
            Cell: props => <a href={(props.value.indexOf('http') > -1 ? props.value : ('https://' + props.value))} target="_blank">{props.value}</a>,
            Footer: "Total: " + submissionList.total
        },
        {
            Header: "Preview",
            accessor: "preview",
            Cell: props => <PreviewPopover previewUrl={props.value} />
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Type",
            accessor: "type"
        },
        {
            Header: "If Verified",
            accessor: "isVerified",
            Cell: props => verificationStatus(props.value)
        },
        {
            Header: "Manage",
            accessor: "",
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <Table
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                columns={columns}
                manual
                data={submissionList.list}
                pages={Math.ceil(submissionList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} 
            />

            <ViewDetailModal 
                data={campaignSubmissionDetail}
                callbackClose={() => setCampaignSubmissionDetail({})}
            />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignSubmissionVerify,
        triggerNotification
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionTable);
