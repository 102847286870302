import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';
import { translate } from 'react-i18next';
import { gtmSend } from "../../../../utils";

const ContentCreator = ({links = [], defaultState='expand', t}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const contentCreatorLinkClickedHandler = (e) => {
        gtmSend("vip_content_creator", "VIP", "VIP Content Creator", "View Jobs", 1, { "action": "View Jobs" });
    }

    const defaultLinks = [
        {path: '/content-creator/jobs', title: (t('sidebar_tab_live') || "Live"), onClick: contentCreatorLinkClickedHandler, ifActive: ((pathname == '/content-creator/jobs') ? 'active-item' : '')},
        {path: '/content-creator/jobs/published', title: t('sidebar_Applied'), ifActive: ((pathname == '/content-creator/jobs/published') ? 'active-item' : '')}
    ];

    return (
        <SidebarTemplate heading={t('sidebar_content_creator') || 'Content Creator'}
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="influ-sidebar-campaign-icon" />
    );
}

export default translate()(ContentCreator);

