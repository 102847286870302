import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import GradingTable from '../../Grading/Table';
import { genericAction } from '../../../../actions';
import { Typography } from 'antd';

const { Text } = Typography;

const CampaignInfluencerGrading = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);
    
    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Influencer Grading'/>
            ]}>
            <div className="campaign-payments">
                <h1>Influencer Ratings - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <GradingTable campaignId={props.match.params.id}
                    conditions={[
                    ]} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfluencerGrading);