import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import { format } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Affix, Button } from 'antd';
import {Icon} from '../../../Common/Icon';
import { gtmSend } from '../../../../utils';

const PanelDetail = ({data, shareUrl, onCopy, t, deviceType}) => {

	const paymentScrollRef = useRef(null);

    const smoothScroll = (e, refElem) => {
        e.preventDefault();
        refElem.current.scrollIntoView({behavior: 'smooth'});
    }

    return (
       <div className={cx("mob-influencer-panel-detail-wrapper", "influencer-main-container")}>

       		<div className={cx("card-wrap")}>
	            <div className={cx("panel-product-image")}>
	                <img src={data.jobImage} alt="product-image" width="500" />
                    <span className={cx("panel-cat")}>
                        {data.category}
                    </span>
	            </div>

	            <div className={cx("panel-details-body")}>
	                <div className={cx("white-block")}>
	                    <div className={cx("panel-sub-title")}>
	                        <h1 className={cx("cd-margin-bottom0")}> {data.jobName} </h1>
	                    {
                         //    <div className={cx("posted-date")}>
	                        //     Posted 2 days ago
	                        // </div>
                        }
	                    </div>
	                    
	                    <div className={cx("points-wrapper")}>
	                        <span className={cx("pts-row-one")}>
	                            <span className={"pts"}>
	                                <img src={data.paymentTypeImage} alt=""/> {data.paymentString}
	                            </span>
	                        </span>
	                        <span className={cx("pts-row-two")}>
	                            <a onClick={(e) => {smoothScroll(e, paymentScrollRef); gtmSend('dynamic','paymentDetails','click');}}>
	                                {t("mob_panel_detail_see_payment_details")}
	                            </a>
	                        </span>
	                    </div>
	                    {
                            !data.isExternalSurvey &&
                            <div className={cx("panel-date")}>
                                <span></span>
                                <span className={cx("icon-calender")}> <Icon name="icon-brand-calender"></Icon> {t("mob_panel_detail_campaign_date")} : </span>
                                <span> { (data.start_date && data.end_date) ? format(data.start_date, 'Do MMM YYYY') + ' - ' + format(data.end_date, 'Do MMM YYYY') : '' } </span>
                            </div>
                        }
	                </div>
	            </div>
            </div>

            <div className={cx("panel-details-body")}>
                <div className={cx("white-block", "padding-bot-zero")}>
                    <div className={cx("panel-sub-title")}>
                        <h2 className={cx("cd-margin-bottom0")}>{t("mob_panel_detail_job_details")}</h2>
                    </div>

                    <div className={cx("panel-detail-description")}>
                    	{ data.description }
                    </div>

                    <div className={cx("panel-detail-description", "section-top-pad")}>
                    	<span>{t("mob_panel_detail_survey_duration")}: </span>
                    	<span>{data.surveyDurationString}</span>
                    </div>
                    {
                        !data.isExternalSurvey &&
                        !data.hasApplied &&
                        <React.Fragment>
                            <div className={cx("panel-detail-description", "section-top-pad")}>
                                <span className={cx("section-head")}>{t("mob_panel_detail_survey_URL")}: </span>
                            </div>
                            <div className={cx("points-wrapper", "border-none")}>
                                <span className={cx("pts-row-one", "flex-basis-65P")}>
                                    <span className={"pts", "ellipsis"}>
                                        {data.webviewJobDetail}
                                    </span>
                                </span>
                                <CopyToClipboard text={data.webviewJobDetail}
                                    onCopy={() => onCopy()}>
                                    <a onClick={() => {gtmSend('dynamic','paymentDetailsCopylink','click'); }} className={cx("btn-copy")}><Icon name="icon-brand-copy"></Icon>{t("mob_panel_detail_copy")}</a>
                                </CopyToClipboard>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>


            <div className={cx("panel-details-body")} ref={paymentScrollRef}>
                <div className={cx("white-block")}>
                    <div className={cx("panel-sub-title")}>
                        <h2 className={cx("cd-margin-bottom0")}>{t("mob_panel_detail_payment")}</h2>
                    </div>

                    <div className={cx("panel-detail-description")}>
                    	{data.paymentDescription}
                    </div>
                </div>
            </div>

            <div className={cx("panel-details-body")}>
                <div className={cx("white-block", "padding-bot-zero")}>
                    <div className={cx("panel-sub-title")}>
                        <h2 className={cx("cd-margin-bottom0")}>{t("terms_and_conditions")}</h2>
                    </div>
                    
                    <div className={cx("panel-detail-description", "section-top-pad")}>
                    	<span className={cx("section-head")}>{t("mob_panel_detail_please_read_here")} </span>
                    </div>
                    <div className={cx("points-wrapper", "border-none")}>
                        <span className={cx("pts-row-one", "flex-basis-65P")}>
                            <a onClick={() => {gtmSend('dynamic','paymentDetailsTnC','click'); }} href="https://tickledmedia.com/research-panel-terms-conditions/" className={"pts", "ellipsis"}>
                            	https://tickledmedia.com/research-panel-terms-conditions/
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            {   
                data.hasApplied === 1
                ?
                <div className={cx("panel-details-body")}>
                    <div className={cx("notification-stripe")}>
                        {t("mob_panel_detail_this_survey_is_completed_lets_start_another_one")} 
                    </div>
                </div>
                :
                ""
            }
        </div>
    );
}


export default PanelDetail;