import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import BaseLayout from '../../../components/Layout/Influencer/BaseLayout';
import Login from '../../../components/Influencer/LoginNew';
import Button from '@material-ui/core/Button';
import { translate } from 'react-i18next';

const InfluencerLogin = ({t}) => {

    return (            
        <div className={cx("influencer-Login-wrapper")}>
            <Login t={t} />
        </div>
    );
}

export default translate()(InfluencerLogin);