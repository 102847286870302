import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import cx from 'classnames/bind';
// import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';
import { useCountryList, useCitiesList, useBankList } from '../../../../../hooks/generic';
import { createImagePreview } from '../../../../../utils';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import enGB from "date-fns/locale/en";

const Form = ({onSubmit, values, t, dispatch}) => {
    
    const dV = (key, values, defaultVal) => {
        return values[key] ? values[key] : defaultVal;
    }

    const [id, setId] = useState(dV('id', values, null));
    const [influencerId, setInfluencerId] = useState(dV('influencerId', values, null));
    const [origin, setOrigin] = useState(dV('origin', values, 'web'));
    const [isApproved, setIsApproved] = useState(dV('isApproved', values, 2));
    const [name, setName] = useState(dV('name', values, ''));
    const [nricName, setNricName] = useState(dV('nricName', values, ''));
    const [gender, setGender] = useState(dV('gender', values, ''));
    const [countryId, setCountryId] = useState(dV('countryId', values, 192));
    const [cityId, setCityId] = useState(dV('cityId', values, 0));
    const [jobCategory, setJobCategory] = useState(dV('jobCategory', values, ''));
    const [job, setJob] = useState(dV('job', values, ''));
    const [birthDate, setBirthDate] = useState(null);
    const [email, setEmail] = useState(dV('email', values, ''));
    const [countryCode, setCountryCode] = useState(dV('countryCode', values, ''));
    const [langCode, setLangCode] = useState(dV('langCode', values, 'en'));
    const [phoneNo, setPhoneNo] = useState(dV('phoneNo', values, ''));
    const [nricNo, setNricNo] = useState(dV('nricNo', values, ''));
    const [address, setAddress] = useState(dV('address', values, ''));
    const [gopayPhoneNo, setGopayPhoneNo] = useState(dV('gopayPhoneNo', values, ''));
    const [influencerBankRowId, setInfluencerBankRowId] = useState(dV('influencerBankRowId', values, ''));
    const [bankId, setBankId] = useState(dV('bankId', values, ''));
    const [beneficiaryAccountNo, setBeneficiaryAccountNo] = useState(dV('beneficiaryAccountNo', values, ''));
    const [beneficiaryEmail, setBeneficiaryEmail] = useState(dV('beneficiaryEmail', values, ''));
    const [beneficiaryName, setBeneficiaryName] = useState(dV('beneficiaryName', values, ''));
    // const [profileImage, setProfileImage] = useState("");
    // const [profileImagePreview, setProfileImagePreview] = useState("");

    //custom hooks
    const countryList = useCountryList();
    let countryListArr = countryList ? countryList.filter(country => [99, 100, 129, 169, 192, 211, 232].indexOf(country.id) > -1) : [];

    const citiesList = useCitiesList(countryId); 
    
    const bankList = useBankList(dispatch, countryId);    

    // const onProfileImageChange = (e) => {
    //     setProfileImage(e.target.files[0]);
    //     createImagePreview(e.target.files[0], previewImage => setProfileImagePreview(previewImage));
    // }
    
    const countryCodeByIsoOrId = (byId = false) => {
        return !byId
            ?
                {
                    in: 91,
                    id: 62,
                    my: 60,
                    ph: 63,
                    sg: 65,
                    th: 66,
                    vn: 84
                }
            : 
                {   
                    99: 91,
                    100: 62,
                    129: 60,
                    169: 63,
                    192: 65,
                    211: 66,
                    232: 84
                };
    }

    const onCountrySelect = (countryId) => {
        setCountryId(countryId);
    }

    const onCountryFlagSelect = (v) => {
        setCountryCode(countryCodeByIsoOrId()[v.toLowerCase()]);
    }

    const onBankChange = (bankId) => {
        setBankId(bankId);
    }

    //set date
    useEffect(() => {
        registerLocale("en", enGB);
        if(values.birthDate) setBirthDate(parse(values.birthDate));
    }, []);

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            id,
            influencerId,
            origin,
            isApproved,
            name,
            nricName,
            gender,
            countryId,
            cityId,
            langCode,
            jobCategory,
            job,
            birthDate,
            email,
            countryCode,
            phoneNo,
            nricNo,
            address,
            gopayPhoneNo,
            influencerBankRowId,
            bankId,
            beneficiaryAccountNo,
            beneficiaryEmail,
            beneficiaryName
        });
    }

    return (

        <div className={cx("influencer-registration-form")}>
            <h1>{t('registration_profile_form_title')}</h1>
            <form onSubmit={onFormSubmit}>

                <div className={cx("form-group")}>
                    <label htmlFor="name">{t('registration_profile_name')}: </label>
                    <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} required />
                </div>

                <div>
                    {
                        countryId == 192 &&
                        <div className={cx("form-group")}>
                            <label htmlFor="nric-name">{t('registration_profile_as_per_nric')}: </label>
                            <input id="nric-name" type="text" value={nricName} onChange={e => setNricName(e.target.value)} />
                        </div>
                    }
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_profile_gender')}: </label>
                    <select id="gender" value={gender} onChange={e => setGender(e.target.value)} required>
                        <option value="">{t('registration_profile_please_select')}</option>
                        <option value="male">{t('registration_profile_male')}</option>
                        <option value="female">{t('registration_profile_female')}</option>
                        <option value="other">{t('registration_profile_other')}</option>
                    </select>
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_profile_nationality')}: </label>
                    <select id="country-list" value={countryId} onChange={e => onCountrySelect(e.target.value)} required>
                        {
                            countryListArr && countryListArr.length !== 0 &&
                            countryListArr.map((country, i) => {
                                return <option value={country.id} key={i}>{country.name}</option>
                            })
                        }
                    </select>
                </div>
                
                {
                    citiesList && citiesList.length !== 0 &&
                    <div className={cx("form-group")}>
                        <label htmlFor="">{t('registration_profile_city')}: </label>
                        <select id="cities-list" value={cityId} onChange={e => setCityId(e.target.value)} required>
                            <option value={0} >Select City</option>
                            {
                                citiesList.map((city, i) => {
                                    return <option value={city.id} key={i}>{city.name}</option>
                                })
                            }
                        </select>
                    </div>
                }

                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_profile_language')}: </label>
                    <select id="profile-language" value={langCode} onChange={e => setLangCode(e.target.value)} required>
                        <option value="en">English</option>
                        <option value="th">ภาษาไทย</option>
                        <option value="id">Bahasa Indo</option>
                        <option value="vn">Vietnamese</option>
                        <option value="my">Melayu</option>
                        <option value="ph">Filipino</option>
                    </select>
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_profile_category')}: </label>
                    <select id="job-category" value={jobCategory} onChange={e => setJobCategory(e.target.value)} required>
                        <option value="">{t('registration_profile_please_select')}</option>
                        <option value="full_time">{t('registration_profile_full_time')}</option>
                        <option value="part_time">{t('registration_profile_half_time')}</option>
                        <option value="stay_at_home">{t('registration_profile_stay_at_home')}</option>
                    </select>
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="job">{t('registration_profile_job')}: </label>
                    <textarea id="job" type="text" value={job} onChange={e => setJob(e.target.value)} cols="20" rows="2"></textarea>
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="age">{t('registration_profile_dob')}: </label>
                    <DatePicker locale={"en"}
                        showMonthDropdown
                        showYearDropdown
                        selected={birthDate}
                        onChange={birthDate => setBirthDate(birthDate)}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={t('registration_profile_dob')}
                        popperPlacement="top-end"
                        required />
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="email">{t('registration_profile_email_address')}: </label>
                    <input id="email" type="text" value={email} onChange={e => setEmail(e.target.value)} required/>
                </div>

                <div className={cx("form-group")}>
                    <label htmlFor="age">{t('registration_profile_phone_no')}: </label>
                    <div className={cx("")}>
                        <ReactFlagsSelect
                            placeholder="select"
                            countries={['IN', 'ID', 'MY', 'PH', 'SG', 'TH', 'VN']}
                            onSelect={onCountryFlagSelect}
                            selectedSize={20}
                            optionsSize={24}
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            alignOptions="left" />
                        <input id="phone-no" type="text" value={phoneNo} onChange={e => setPhoneNo(e.target.value)} required/>
                    </div>
                </div>    

                <div>
                    {
                        countryId == 192 &&
                        <div className={cx("form-group")}>
                            <label htmlFor="nric-no">{t('registration_profile_nric_number')}</label>
                            <input id="nric-no" type="text" value={nricNo} maxLength="4" onChange={e => setNricNo(e.target.value)} />
                        </div>
                    }
                </div>                        
                        
                <div className={cx("form-group")}>
                    <label htmlFor="age">{t('registration_profile_address')}: </label>
                    <textarea id="address" type="text" value={address} onChange={e => setAddress(e.target.value)} cols="20" rows="2"></textarea>
                </div>

                {/*<div className={cx("form-group")}>
                    <label htmlFor="profile-image">Profile Image: </label>
                    <span className={cx("btn-choose-file")}>
                        <input id="profile-image" type="file" className={cx("choose-file")} onChange={onProfileImageChange} />
                        <label htmlFor="">
                            <span>Choose File</span>
                            <span className={cx("upload-icon")}></span>
                        </label>
                    </span>
                    { profileImagePreview && <img src={profileImagePreview} alt="profile-preview" width="120" height="60" /> }
                </div>*/}

                <div>
                    <hr style={{marginTop: '2rem', marginBottom: '1.5rem'}} />

                    <h2>Payment</h2>

                    {/*<div className={cx("form-group")}>
                        <label htmlFor="gopay-phone-no">GoPay {t('registration_profile_phone_no')}: </label>
                        <div className={cx("")}>
                            <input id="gopay-phone-no" type="text" value={gopayPhoneNo} onChange={e => setGopayPhoneNo(e.target.value)} required/>
                        </div>
                    </div>*/}

                    <div className={cx("form-group")}>
                        <label htmlFor="">Bank Name: </label>
                        <select id="bank-code" value={bankId} onChange={e => onBankChange(e.target.value)} required>
                            <option value="">{t('registration_profile_please_select')}</option>
                            {
                                bankList && Array.isArray(bankList) && 
                                bankList.map((d, i) => {
                                    return <option key={i} value={d.id}>{d.bankName}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className={cx("form-group")}>
                        <label htmlFor="beneficiary-no">Beneficiary Account No: </label>
                        <div className={cx("")}>
                            <input id="beneficiary-no" type="text" value={beneficiaryAccountNo} onChange={e => setBeneficiaryAccountNo(e.target.value)} required/>
                        </div>
                    </div>

                    <div className={cx("form-group")}>
                        <label htmlFor="beneficiary-name">Beneficiary Name: </label>
                        <div className={cx("")}>
                            <input id="beneficiary-name" type="text" value={beneficiaryName} onChange={e => setBeneficiaryName(e.target.value)} required/>
                        </div>
                    </div>

                     <div className={cx("form-group")}>
                        <label htmlFor="beneficiary-email">Beneficiary Email: </label>
                        <div className={cx("")}>
                            <input id="beneficiary-email" type="text" value={beneficiaryEmail} onChange={e => setBeneficiaryEmail(e.target.value)} required/>
                        </div>
                        <div>*Payment receipt will be sent to this email</div>
                    </div>
                </div>

                <div className={cx("button-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">{t('registration_profile_button_join')}</button>
                </div>
           </form>
           
        </div>        
    );
}

export default Form;