/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
//initial state wi;l be empty after adding login
function IS() {
    return {
    };    
}

const INITIAL_STATE = IS();

export var webviewReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();
    //    
    switch (action.type) {
        case 'START_WEBVIEW_REDUCER':
            return {
                ...state,
                isLoading: true
            }
        break;
        case 'COMPLETE_WEBVIEW_REDUCER':
            return {
                ...state,
                ...action.payload.data,
                isLoading: false
            }
        break;
        default:
            return state;
    }
};