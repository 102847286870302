import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Transfer, Button, Typography } from 'antd';

const { Text } = Typography;

const CampaignManager = ({campaignId, managersFetch, managersSave}) => {
    const [allAdmins, setAllAdmins] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [headManagerId, setHeadManagerId] = useState('');

    //initial
    useEffect(() => {
        managersFetch(1, 500, [], [], {campaignId})
        .then(response => {
            if(!response.err) {
                setAllAdmins(response.data.list);
                buildData(response.data.list);
            }
        })
    }, [])

    const onHeadManagerChange = (newHeadManagerId) => {
        newHeadManagerId = parseInt(newHeadManagerId);
        //handle mail targets        
        //source
        let newSourceData = [];        
        //add old
        if(headManagerId) {
            for(let i in allAdmins) {
                if(allAdmins[i].adminId == headManagerId) {
                    newSourceData.push({
                        managerRowId: allAdmins[i].managerRowId,
                        isHead: 0,
                        key: allAdmins[i].adminId,
                        title: allAdmins[i].email,
                        description: '',
                        chosen: true
                    });
                    break;
                }
            }
        }
        for(let k in sourceData) {
            //skip new head
            if(sourceData[k].key != newHeadManagerId)
                newSourceData.push(sourceData[k]);
        }
        setSourceData(newSourceData);
        //target
        setTargetKeys([headManagerId,...targetKeys.filter(d => d != newHeadManagerId)]);        
        //set head
        setHeadManagerId(newHeadManagerId);
    }

    const onManagerSave = () => {
        if(!headManagerId) return alert("Set the campaign manager!");
        // adminId, campaignId, isHead
        managersSave(
            [
                [headManagerId, campaignId, 1],
                ...targetKeys.filter(k => k).map(adminId => [adminId, campaignId, 0])
            ],
            campaignId
        );
    }

    const buildData = (initialSourceData) => {
        const sData = [];
        const tKeys = [];
        for(let k in initialSourceData) {
            const data = {
                managerRowId: initialSourceData[k].managerRowId,
                isHead: initialSourceData[k].isHead,
                key: initialSourceData[k].adminId,
                title: initialSourceData[k].email,
                description: '',
                chosen: initialSourceData[k].campaignId ? true : false,
            };
            //if head
            if(initialSourceData[k].isHead) {
                setHeadManagerId(initialSourceData[k].adminId);
            } else {
                if(data.chosen) tKeys.push(data.key);
                sData.push(data);
            }
        }
        //set
        setSourceData(sData);
        setTargetKeys(tKeys);
    }

    const handleChange = (targetKeys) => {
        setTargetKeys(targetKeys);
    };

    const renderFooter = () => {
        return null;
    }

    
    return (
        <div className={cx("campaign-manager-wrap")}>
            <div>
                <h2>Assign Manager</h2>

                <div className={cx("form-horizontal-row")}>
                    <div className={cx("form-wrap")}>
                        <label htmlFor="">Current Manager: </label>
                        <select name="head-manager" onChange={e => onHeadManagerChange(e.target.value)} value={headManagerId}> 
                            <option value="">select</option>
                            {
                                allAdmins.map((d, i) => {
                                    return <option key={i} value={d['adminId']}>{d['email']}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <hr/>

            <div>
                <h2>Select admins who will receive emails for this campaign(above manager will receive these mails by default) -</h2>
                <div>
                    <Transfer
                        titles={['Available', 'Assigned']}
                        dataSource={sourceData}
                        showSearch
                        listStyle={{
                            width: 250,
                            height: 300,
                        }}
                        operations={['to right', 'to left']}
                        targetKeys={targetKeys}
                        onChange={handleChange}
                        render={item => item.title}
                        footer={renderFooter} />
                </div>
            </div>

            <hr/>

            <div>
                <div> 
                    <Button type="primary" className={cx("btn", "btn-blue")} onClick={e => onManagerSave()}>Save</Button>
                </div>
            </div>            
        </div>        
    );
}

export default CampaignManager;