import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Icon } from 'antd';
import ElemModal from '../../../UIElems/Modal';
import { useCurrencyList } from '../../../../hooks/generic';

const Adjust = ({title='Payment', onAdjust, dispatch}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentType, setPaymentType] = useState('monetary');
    const [currencyCode, setCurrencyCode] = useState('INR');
    const [actionType, setActionType] = useState('add');
    const [amount, setAmount] = useState(actionType == 'add' ? 1 : -1);
    const [comments, setComments] = useState('');

    const currencyList = useCurrencyList(dispatch);

    const onActionChange = (actionType) => {
        setActionType(actionType);
        setAmount(actionType == 'add' ? 1 : -1);
    }

    const onAdjustClick = (e) => {
        setIsModalOpen(true);
    }

    const onModalOk = (e) => {
        if(e) e.preventDefault();
        setIsLoading(true);
        onAdjust(
            {
                paymentType,
                amount,
                actionType,
                comments: comments ? comments : null,
                currencyCode
            },            
            () => setIsLoading(false)
        );
    }

    const onModalClose = () => {
        setIsModalOpen(false);
    }

    const ActionableElem = ({type, onAdjustClick}) => {
        let elem = null;
        switch(type) {
            default:
                elem =
                    <a onClick={onAdjustClick}>
                        <Icon type="edit" />
                        <span> &nbsp;Adjust Payment</span>
                    </a>
            break;
        }

        return elem;
    }

    return (
        <div className={cx("")}>
            <ActionableElem type=""
                onAdjustClick={onAdjustClick} />

            <ElemModal title={title}
                OkText="Save"
                openModal={isModalOpen}
                onClose={onModalClose}
                onOk={onModalOk}
                isLoading={isLoading} >

                <div className={cx("payment-modal-wrapper", "brand-main-container")}>
                    <form onSubmit={onModalOk}>
                        <div className={cx("form-group")}>
                            <label htmlFor="">Payment type: </label>
                            <div className={cx("radio-type-wrap")}>
                                <div>
                                    <input type="radio" value="monetary" checked={paymentType == 'monetary'} onChange={e => setPaymentType(e.target.value)} />
                                    <span> Monetary </span>
                                </div>
                                
                                <div>
                                    <input type="radio" value="points" checked={paymentType == 'points'} onChange={e => setPaymentType(e.target.value)} />
                                    <span> Points </span>
                                </div>
                            </div>
                        </div>
                        {
                            paymentType == 'monetary' &&
                            <div className={cx("form-group")}>
                                <label htmlFor="">Currency: </label>
                                <select id="currency-code" value={currencyCode} onChange={e => setCurrencyCode(e.target.value)}>
                                    {
                                        currencyList.map((d, i) => {
                                            return <option key={i} value={d.currencyCode}>{d.currencyCode + ' ' + d.currencySymbol}</option>
                                        })
                                    }
                                </select>
                            </div>                        
                        }                    
                        <div className={cx("form-group")}>
                            <label htmlFor="">Action: </label>

                            <div className={cx("radio-type-wrap")}>
                                <div>
                                    <input type="radio" value="add" checked={actionType == 'add'} onChange={e => onActionChange(e.target.value)} />
                                    <span> Credit </span>
                                </div>
                                
                                <div>
                                    <input type="radio" value="sub" checked={actionType == 'sub'} onChange={e => onActionChange(e.target.value)} />
                                    <span> Debit </span>
                                </div>
                            </div>
                        </div>
                        <div className={cx("form-group")}>
                            <label htmlFor="">Amount: </label>
                            {
                                actionType == 'add'
                                ? <input type="number" min="1" value={amount} onChange={e => setAmount(e.target.value)} />
                                : <input type="number" max="-1" value={amount}  onChange={e => setAmount(e.target.value)} />
                            }
                        </div>
                        <div className={cx("form-group")}>
                            <label htmlFor="comments">Comments: </label>
                            <textarea name="comments" id="comments" cols="30" rows="3" value={comments} onChange={e => setComments(e.target.value)} />
                        </div>
                   </form>
               </div>

            </ElemModal>
        </div>
    );
}

export default Adjust;