import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { influencerCampaignFeedbackUpdate } from '../../../../../../actions';
import { useCampaignFeedbackQuestions } from '../../../../../../hooks/generic';
import notification from '../../../../../UIElems/Notification';

const Answer = (props) => {
    const t = props.t;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [answers, setAnswers] = useState([]);

    const existingQuestions = useCampaignFeedbackQuestions(props.dispatch, props.campaignId, false);

    const onAnswerChange = (i, value) => {
        let shallowArr = [...answers];
        shallowArr[i].value = value
        setAnswers(shallowArr);
        props.assignUpdater(shallowArr);
    }

    const sendFeedback = () => {
        props.actions.influencerCampaignFeedbackUpdate(props.campaignId, props.rdx.webviewReducer.profile.influencerId, answers.filter(d => d.value.length))
        .then(() => {
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Sent!', type: 'success'});
        })
    } 

    //update answer list
    useEffect(() => {
        let arr = existingQuestions.list
            .map((d, i) => {
                return {value: '', id: d.id, q: d.question}
            });
        setAnswers(arr);
    }, [existingQuestions.list.length]);
    
    return (
        answers.length
            ?
                <div className={cx("feedback-answer", "white-block")}>
                    <div className={cx("campaign-sub-title", "cd-margin-bottom0")}>
                        <h2>{t('campaign_submission_feedback')}</h2>
                    </div>
                    {
                        answers.map((d, i) => {
                            return <div className={cx("form-group")} key={i}>
                                <input type="text" value={d.q} name={'old-' + d.id} readOnly size="90" />
                                <input type="text" value={answers[i].value} name={'answer-' + d.id} onChange={e => onAnswerChange(i, e.target.value)} size="90" />
                            </div>                        
                        })
                    }

                    {/*<div className={cx("button-wrap-center-align")}>
                        <button className={cx("btn", "btn-blue")} type="button" onClick={sendFeedback}>submit feedback</button>
                    </div>*/}
                </div>
            :
                null        
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerCampaignFeedbackUpdate
    }, dispatch),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Answer);
