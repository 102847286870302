import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';

export const implementBarChart = (Chart) => (props) => {
    let elem = null;
    //check
    if('data' in props && props.data && props.data.length && 'refresh' in props) {
        elem = 
            <Chart {...props}
                data={props.data}
                refresh={props.refresh} />;
    } else {
        elem = 
            <div className="no-data">
                No data to display
            </div>
    }

    return elem;
}

export const implementPieChart = (Chart) => (props) => {
    let elem = null;
    //check
    if('data' in props && props.data && props.data.length && 'refresh' in props) {
        elem = 
            <Chart {...props}
                data={props.data}
                refresh={props.refresh} />;
    } else {
        elem = 
            <div className="no-data-pie">
                No data to display
            </div>
    }

    return elem;
}

export const implementLineChart = (Chart) => (props) => {
    let elem = null;
    //check
    if('data' in props && props.data && props.data.length && 'refresh' in props) {
        elem = 
            <Chart {...props}
                data={props.data}
                refresh={props.refresh} />;
    } else {
        elem = 
            <div className="ant-card-body">
                No data to display
            </div>
    }

    return elem;
}