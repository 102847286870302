import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../actions';
import { useInfluencerInstaPostMetrics } from '../../../../hooks/generic';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import AboutSidebar from '../../../../components/Influencer/Sidebar/About';
import CampaignSidebar from '../../../../components/Influencer/Sidebar/Campaign';
import ProfileSidebar from '../../../../components/Influencer/Sidebar/Profile';
import SocialProfileComponent from '../../../../components/Influencer/Profile/Social';


const SocialProfile = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const posts = useInfluencerInstaPostMetrics(() => setIsLoading(false), props.dispatch, props.rdx.influencerReducer.id, 'insta', forceUpdate);
    console.log(posts);
    return (
        <BaseLayout leftCards={[<CampaignSidebar />, <ProfileSidebar />, <AboutSidebar />]}>
            <div className={cx('')}>
                <SocialProfileComponent postList={posts.list} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialProfile);