import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { useCountryList } from '../../../../hooks/generic';

const BulkRemind = ({bulkRemindUsers}) => {
    const [countryId, setCountryId] = useState('');
    const [isApproved, setIsApproved] = useState(2);
    const countryList = useCountryList();

    const bulkRemind = () => {
        if(countryId) {
            bulkRemindUsers({countryId, isApproved});
        } else {
            alert('Kindly select a country!');
        }
    }

    return (
        <div>
            <div className="bulk-remind">
                Bulk Remind To Fill Details:
                <div className={cx("form-group")}>
                    <label htmlFor="">Country: </label>
                    <select id="country-list" value={countryId} onChange={e => setCountryId(e.target.value)} >
                        <option value="">select</option>
                        {
                            countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                return <option value={country.id} key={i}>{country.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className={cx("form-group")}>
                    <label htmlFor="">Approval Type: </label>
                    <select id="approval-type" value={isApproved} onChange={e => setIsApproved(e.target.value)} >
                        <option value="2">Unapproved</option>
                        <option value="1">Approved</option>
                    </select>
                </div>
                <button type="button" onClick={bulkRemind}>Bulk Remind</button>
            </div>
        </div>
    );
}

export default BulkRemind;