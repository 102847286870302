import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Icon } from 'antd';
import { format } from 'date-fns';
import CardElem from '../../../UIElems/Card';
import GradeAdjust from '../../../../containers/Brand/Grading/Rate';
import GradeComment from '../../../../containers/Brand/Grading/Comment';
import RatingPopover from '../../../../containers/Brand/Grading/Popover';

const GradingHistory = ({name, data, gradingAverageData}) => {    

    return (
        <div className={cx('grading-history')}>
            <h2 className={cx('text-center')} style={{marginBottom: '30px'}}>Rating History - {name}</h2>
            <div>
                <h3>Average Rating</h3>
                { 
                    gradingAverageData.total > 0 &&
                    <RatingPopover data={gradingAverageData.list[0]}
                        mode="inline" />
                }
            </div>

            <hr/>

            <div>
                <h3>Ratings By Campaign</h3>
                {
                    data.list.map((d, i) => {
                        return (
                            <CardElem title={d.campaignName} bordered={false}>
                                <div>
                                    Comment - &nbsp;
                                    <GradeComment comment={d.comment}
                                        campaignId={d.campaignId}
                                        influencerId={d.influencerId}
                                        sliceLength={50} />
                                </div>
                                <div>
                                    Primary Rating -
                                    <GradeAdjust value={d.primaryRating}
                                        campaignId={d.campaignId}
                                        influencerId={d.influencerId}
                                        type="primary_rating"
                                        comment={d.comment} /> 
                                </div>
                                <div>
                                    Correctness -
                                    <GradeAdjust value={d.correctness}
                                        campaignId={d.campaignId}
                                        influencerId={d.influencerId}
                                        type="correctness"
                                        comment={d.comment} /> 
                                </div>
                                <div>
                                    Creativity -
                                    <GradeAdjust value={d.creativity}
                                        campaignId={d.campaignId}
                                        influencerId={d.influencerId}
                                        type="creativity"
                                        comment={d.comment} /> 
                                </div>
                                <div>
                                    Punctuality -
                                    <GradeAdjust value={d.punctuality}
                                        campaignId={d.campaignId}
                                        influencerId={d.influencerId}
                                        type="punctuality"
                                        comment={d.comment} /> 
                                </div>
                            </CardElem>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default GradingHistory;