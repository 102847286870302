import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Carousel } from 'antd';

const CarouselComponent = ({elems = [], autoplay = true, autoplaySpeed=6000, dots =  true, afterChange=() => {}}) => {
    return (
        <div>
            <Carousel autoplay={autoplay} autoplaySpeed={autoplaySpeed} afterChange={afterChange} dots >
                {
                    elems.map((elem, i) => {
                        return <div key={i}>
                            {elem}
                        </div>
                    })
                }
            </Carousel>
        </div>        
    );
}

export default CarouselComponent;