import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ProfileForm from '../../../../components/Influencer/Registration/Profile/Form';
import { influencerRegisterProfileSave } from '../../../../actions';
import Loader from '../../../../components/UIElems/Loader';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import { translate } from 'react-i18next';
import { format, parse } from 'date-fns';
import notification from '../../../../components/UIElems/Notification';

const Profile = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        if(!data.birthDate)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Please set your birth date!', type: 'error'});
        //
        data.birthDate = format(data.birthDate, 'YYYY-MM-DD');
        setIsLoading(true);
        props.actions.influencerRegisterProfileSave(data)
        .then(results => {
            setIsLoading(false);
            window.location = '/influencer/register/kids';
        })
        .catch(error => console.log(error))
    }

    const defaultValues = props.rdx.influencerReducer.registerProfileDefaults
        ? props.rdx.influencerReducer.registerProfileDefaults
        : {};

    return (
        <BaseLayout leftCards={[]} shouldKeepEmpty={true}>
            <ProfileForm onSubmit={onSubmit}
                values={defaultValues}
                t={props.t}
                dispatch={props.dispatch} />

            { isLoading && <Loader /> }    
        </BaseLayout>
        
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerRegisterProfileSave
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Profile));
