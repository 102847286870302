import React from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';

const SocialProfile = ({postList}) => {
    if(!postList) return null;
    return (
        <div className={cx("card-social-wrap")}>
            <h1>Last 5 posts:</h1>
            <div className={cx("social-card")}>
            {
                postList.map((d, i) => {
                    return <div className={cx("card")} key={i}>
                        <h2>Caption: {d.post.caption}</h2>
                        <img src={d.post.mediaUrl} alt="post" height="320"/>
                        <div className={cx("card-body")}>
                            <h3>Metrics</h3>
                            <div className={cx("card-metrics")}>
                                <div>
                                    <span className={cx("metrics-item")}>Like Count:</span> 
                                    <span>{d.post.likeCount}</span> 
                                </div>
                                <div>
                                    <span className={cx("metrics-item")}>Comment Count:</span> 
                                    <span>{d.post.commentCount}</span> 
                                </div>
                                {
                                    d.metrics.map((m, i) => { 
                                        return <div key={i}>
                                            <div><span className={cx("metrics-item")}>{m.title}:</span> <span>{m.values[0].value}</span></div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                })
            }
            </div>
        </div>
    );
}

export default SocialProfile;