import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { genericAction } from '../../../../actions';
import { useStatsSubmissions } from '../../../../hooks/stats';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';
import CardElem from '../../../../components/UIElems/Card';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const USER_DATE_FORMAT = 'DD-MM-YYYY';
const SYS_DATE_FORMAT = 'YYYY-MM-DD';

const Submissions = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldRefreshChart, setShouldRefreshChart] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    // const [fromDateMoment, setFromDateMoment] = useState(moment('2019-01-01', SYS_DATE_FORMAT));
    // const [toDateMoment, setToDateMoment] = useState(moment());
    // 
    const data = useStatsSubmissions(
        () => {
            setIsLoading(false);
            setTimeout(() => setShouldRefreshChart(!shouldRefreshChart), 1000);
        },
        props.dispatch,
        props.campaignId,
        forceUpdate
    );

    // const onFetch = () => {
    //     if(!fromDateMoment && !toDateMoment) {
    //         return alert('Set a proper date range!');
    //     } else {
    //         setIsLoading(true);
    //         setForceUpdate(!forceUpdate);
    //     }
    // }
    
    return (
        data
        ?
            <div>
                {/*<div className="filters" style={{marginBottom: '10px'}}>
                    <div className="row1 row-wrapper">
                        <span>
                            <DatePicker value={fromDateMoment}
                                onChange={(d, s) => setFromDateMoment(d)} />
                            <span> ~ </span>
                            <DatePicker value={toDateMoment}
                                onChange={(d, s) => setToDateMoment(d)} />
                        </span>
                    </div>

                    <div>&nbsp;</div>

                    <div className="row4">
                        <button type="button" className={cx("btn", "btn-blue")} onClick={e => onFetch()}>Fetch</button>
                    </div>
                </div>*/}

                <div className={cx("stats-cards-blue")} onClick={e => window.open(`/brand/campaign/${props.campaignId}/submissions`, '_blank')}>
                    <CardElem title="Submission Stats" bordered={false} style={{cursor: 'pointer'}}>
                        <div>Verified Submissions: {data['Total Verified'] ? data['Total Verified'] : 0}</div>
                        <div>Unverified Submissions: {data['Total Unverified'] ? data['Total Unverified'] : 0}</div>
                        <div>Total Submissions: {data['total'] ? data['total'] : 0}</div>
                        <div>Total Submitters: {data['Total Submitters']}</div>
                    </CardElem>
                </div>
            </div>
        :
            <div className={cx("stats-cards-blue")} onClick={e => window.open(`/brand/campaign/${props.campaignId}/submissions`, '_blank')}>
            <CardElem title="Submission Stats" bordered={false} style={{cursor: 'pointer'}}>
                No data to display
            </CardElem>
                { isLoading && <Loader /> }
            </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);