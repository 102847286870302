import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import { Icon } from '../../Common/Icon';
import Carousel from '../../UIElems/Carousel';
import { brandEnquirySave } from '../../../actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { gtmSend } from '../../../utils';

const BrandEnquiryPage = (props) => {

    const t = props.t,
    [showNotificationBar, setNotificationBar] = useState(true),
    [closeStickyFormPanel, setCloseStickyFormPanel] = useState(true),
    [showStickyFormPanel, setShowStickyFormPanel] = useState(true),
    [showStickyInputFormPanel, setShowStickyInputFormPanel] = useState(false),
    // [hidePartialForm, setHidePartialForm] = useState(false),
    [isSubmitted, setIsSubmitted] = useState(false),
    [stickyPanelSize, setStickyPanelSize] = useState(),
    [isThankyouMsg, setThankyouMsg] = useState(false),
    enrollScrollRef = useRef(null);

    const smoothScroll = (e, refElem) => {
        e.preventDefault();
        refElem.current.scrollIntoView({behavior: 'smooth'});
    }

    // For closing opened nitificationbar
    const closeNotification = (e) => {
        e.preventDefault();
        setNotificationBar(false);
    }

    // For closing opened Sticky floating form
    const toggleFloatingForm = (e) => {
        e.preventDefault();
        setCloseStickyFormPanel(false);
        setShowStickyInputFormPanel(false);
        if(closeStickyFormPanel === false) {
            setCloseStickyFormPanel(true);
            setShowStickyInputFormPanel(false);
            setStickyPanelSize('350px');
        }
    }

    // For opening enquiry flaoting form
    const showFlaotingEnquiryForm = (e) => {
        e.preventDefault();
        setCloseStickyFormPanel(false);
        setShowStickyInputFormPanel(true);
        setStickyPanelSize('450px');
    }

    const closeStickyPanel = (e) => {
        e.preventDefault();
        setCloseStickyFormPanel(false);
        setShowStickyInputFormPanel(false);
        setStickyPanelSize('350px');
    }

    const closeTyPanel = (e) => {
        setThankyouMsg(false);
        window.location.reload(false);
    }

    //For showing full floating form
    // const showFullEnquiryForm = (e) => {
    //     // e.preventDefault();
    //     e.target.parentElement.style.display='none';
    //     setHidePartialForm(true);
    // }
    
    //Submit Form
    const onFormSubmit = (event, uniqueFormId) => {
        event.preventDefault();

        let brandEnquiryFormRefernce = ""; //Getting Form element by two different ID

        if(uniqueFormId === "brandEnquiryFloatingForm") {
            brandEnquiryFormRefernce = document.getElementById("brandEnquiryFloatingForm");
        } else if(uniqueFormId === "brandEnquiryForm") {
            brandEnquiryFormRefernce = document.getElementById("brandEnquiryForm");
        }
          
        const country = Number(brandEnquiryFormRefernce.elements.namedItem('country').value);

        let checkCountryValue = ""; 

        if(country === 100) {
            checkCountryValue = "Indonesia";
        } else if(country === 129) {
            checkCountryValue = "Malaysia";   
        } else if(country === 169) {
            checkCountryValue = "Philippines";   
        } else if(country === 192) {
            checkCountryValue = "Singapore";   
        } else if(country === 211) {
            checkCountryValue = "Thailand";   
        } else if(country === 232) {
            checkCountryValue = "Vietnam";   
        }

        const data = {
            "enquirer_name" : brandEnquiryFormRefernce.elements.namedItem('name').value,
            "work_email" : brandEnquiryFormRefernce.elements.namedItem('workEmail').value,
            "company_name" : brandEnquiryFormRefernce.elements.namedItem('company').value,
            "phone_number" : brandEnquiryFormRefernce.elements.namedItem('phoneNumber').value,
            "hear_about_us" : brandEnquiryFormRefernce.elements.namedItem('hearAboutUs').value,
            "kind_of_company" : brandEnquiryFormRefernce.elements.namedItem('kindOfCompany').value,
            "no_of_people_in_company_globally" : brandEnquiryFormRefernce.elements.namedItem('noOfPeopleInCompanyGlobally').value,
            "country" : {
                "id": country,
                "name": checkCountryValue
            }
        }

        

        props.actions.brandEnquirySave(data)
        .then(response => {
            setThankyouMsg(true);
            // if(!response.err) window.location.reload(true);
        })
        .catch(error => console.log(error))
    }

    // Header Notification bar
    const NotificationBar = () => {
        return (
            <div className={cx("sub-header-notification-wrap", "only-desktop")}>
                <span>{t("brand_enquiry_get_a_FREE_consultation_or_ﬁnd_out_more_about_us_by_emailing_us_at")}</span>
                <span className={cx("icon-mail")}> <Icon name="icon-email" /> </span>
                <a href="mailto:vipadmin@tickledmedia.com" onClick={() => {gtmSend('dynamic','Email consultation','click'); }} >vipadmin@tickledmedia.com</a>
                <span className={cx("close-notification")} onClick={closeNotification}>
                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon-white-close.png" alt="VIParent logo" />
                </span>
            </div>
        )
    }

    // onclick hide video frame and show youtube video
    const YoutubeVideo = (event) => {
        event.target.parentElement.nextElementSibling.style.display='block'; 
        event.target.parentElement.style.display='none';
    }

    // FLoating Enquiry FOrm
    const FloatingEnquiryFormWidget = () => {
        return (
            <div className={cx("floating-form", "only-desktop")}>
                <div className={cx("sticky-panel")} style={{width: stickyPanelSize}}>
                    {
                        closeStickyFormPanel &&
                        <div className={cx("content-panel")}>
                            <div  onClick={e => {showFlaotingEnquiryForm(e); gtmSend('dynamic','Floating form open','click'); }} className={cx("flex-columns", "form-desc")}>
                                <div className={cx("flex-column-17P")}>
                                    <Icon name="icon-enquiry-girl" />
                                </div>
                                <div className={cx("flex-column-80P")}>
                                    <p>
                                        {t("brand_enquiry_click_here_to_find_out_more")}
                                    </p>
                                </div>
                            </div>
                            <div className={cx("close")} onClick={closeStickyPanel}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon-black-close.png" alt="close" />
                            </div>
                        </div>
                    }
                    {
                        showStickyInputFormPanel &&
                        <div className={cx("content-panel")}>
                            <div className={cx("overflow-panel")}>
                                <div className={cx("login-title-wrapper")}>
                                    <h2 className={cx("title")}>
                                        <span className={cx("title-center")}>
                                            {t("brand_enquiry_learn_more_about")} <br/>
                                            {t("brand_enquiry_SEA_Parenting_Community")}
                                        </span>
                                    </h2>
                                </div>

                                <form id="brandEnquiryFloatingForm" onSubmit={e => onFormSubmit(e, "brandEnquiryFloatingForm")}>

                                    <EnquiryForm bigForm={"stickyFormRadi"} />

                                </form>
                            </div>
                            <div className={cx("close")} onClick={closeStickyPanel}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon-black-close.png" alt="close" />
                            </div>
                        </div>   
                    }
                    <div onClick={toggleFloatingForm} className={cx("icon-wrap")}>
                        <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/vip-fab-btn.png" alt="VIParent logo" />
                    </div>
                </div>
            </div>
        );
    }

    // Basic Enquiry Form Component
    const EnquiryForm = (props) => {
        return (
            <Fragment>
                <div className={cx("form-group")}>
                    <input 
                        type="text" 
                        placeholder={t("brand_enquiry_name")}
                        name="name"
                        required
                    />
                </div>
                <div className={cx("form-group")}>
                    <input 
                        type="email" 
                        placeholder={t("brand_enquiry_work_email")}
                        name="workEmail"
                        required
                    />
                </div>
                <div className={cx("form-group")}>
                    <input 
                        type="text" 
                        placeholder={t("brand_enquiry_company")}
                        name="company"
                        required
                    />
                </div>
                <div className={cx("form-group")}>
                    <input 
                        type="number" 
                        placeholder={t("brand_enquiry_phone_number")}
                        name="phoneNumber"
                        required
                    />
                </div>
                <div className={cx("form-group")}>
                    <div className={cx("flex-columns", "radio-spacing")}>
                        <label className={cx("label-country")} htmlFor="">
                            {t("brand_enquiry_country")}
                        </label>
                        <div className={cx("elements-in-row")}>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="192" 
                                        defaultChecked
                                        id={`${props.bigForm}Singapore`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}Singapore`}>{t("brand_enquiry_singapore")} </label>
                                </div>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="129"
                                        id={`${props.bigForm}Malaysia`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}Malaysia`}>{t("brand_enquiry_malaysia")}</label>
                                </div>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="100"
                                        id={`${props.bigForm}Indonesia`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}Indonesia`}>{t("brand_enquiry_indonesia")}</label>
                                </div>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="211" 
                                        id={`${props.bigForm}Thailand`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}Thailand`}>{t("brand_enquiry_thailand")} </label>
                                </div>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="169"
                                        id={`${props.bigForm}philippines`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}philippines`}>{t("brand_enquiry_philippines")}</label>
                                </div>
                                <div>
                                    <input 
                                        type="radio" 
                                        name="country" 
                                        value="232"
                                        id={`${props.bigForm}Vietnam`}
                                    />
                                    <label className={cx("style-for-radio")} htmlFor={`${props.bigForm}Vietnam`}>{t("brand_enquiry_vietnam")}</label>
                                </div>
                        </div>
                    </div>
                </div>
                <div className={cx("form-group")}>
                    <select 
                        name="hearAboutUs"
                        required
                    >
                        <option value="">{t("brand_enquiry_how_did_you_hear_about_us")}</option>
                        <option value={t("brand_enquiry_theAsianparent_channels")}>{t("brand_enquiry_theAsianparent_channels")}</option>
                        <option value={t("brand_enquiry_Facebook")}>{t("brand_enquiry_Facebook")}</option>
                        <option value={t("brand_enquiry_Instagram")}>{t("brand_enquiry_Instagram")}</option>
                        <option value={t("brand_enquiry_Google")}>{t("brand_enquiry_Google")}</option>
                        <option value={t("brand_enquiry_LinkedIn")}>{t("brand_enquiry_LinkedIn")}</option>
                        <option value={t("brand_enquiry_news_articles")}>{t("brand_enquiry_news_articles")}</option>
                        <option value={t("brand_enquiry_referral_recommendations")}>{t("brand_enquiry_referral_recommendations")}</option>
                        <option value={t("brand_enquiry_Others")}>{t("brand_enquiry_Others")}</option>
                    </select>
                </div>
                <div className={cx("form-group")}>
                    <select 
                        name="kindOfCompany"
                        required
                    >
                        <option value="">{t("brand_enquiry_what_kind_of_company_are_you")}</option>
                        <option value={t("brand_enquiry_Small_business")}>{t("brand_enquiry_Small_business")}</option>
                        <option value={t("brand_enquiry_Medium_business")}>{t("brand_enquiry_Medium_business")}</option>
                        <option value={t("brand_enquiry_top_brands")}>{t("brand_enquiry_top_brands")}</option>
                        <option value={t("brand_enquiry_marketing_agencies")}>{t("brand_enquiry_marketing_agencies")}</option>
                        <option value={t("brand_enquiry_Media")}>{t("brand_enquiry_Media")}</option>
                        <option value={t("brand_enquiry_Others")}>{t("brand_enquiry_Others")}</option>
                    </select>
                </div>
                <div className={cx("form-group")}>
                    <select 
                        name="noOfPeopleInCompanyGlobally"
                        required
                    >
                        <option value="">{t("brand_enquiry_how_many_people_are_in_your_company_globally")}</option>
                        <option value="10>">{`10>`}</option>
                        <option value="10-50">10-50</option>
                        <option value="50-200">50-200</option>
                        <option value="200-1000">200-1000</option>
                        <option value="1000<">{`1000<`}</option>
                    </select>
                </div>
                <div className={cx("form-group")}>
                    <div className={cx("btn-wrapper", "animation-1", "btn-wrap-mob")}>
                            <button onClick={() => {gtmSend('dynamic','Form submit','click'); }} className={cx("btn btn-primary-rounded", "btn-hover", "pulse")} type="submit">
                                {t("brand_enquiry_Submit")}
                            </button>
                    </div>
                </div>
                <p className={cx("download-msg")}>
                    {t("brand_enquiry_E_book_will_be_send_to_your_email_after_you_filled_up_this_form")}
                </p>
            </Fragment>
        );
    }

    return (
        <Fragment>
        	 <div className={cx("brand-fluid-base-layout")}>
                <header className={cx("brand-fluid-header")}>
                    <div className={cx("header-top")}>
                        <div className={cx("h-left")}>
                            <div className={cx("logo-wrap")}>
                                <a href='#'>
                                    <img className={cx('not-mobile', 'logo')} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/vip+tap+logo.png'} 
                                height="44" width="270" alt="logo" />

                                    <img className={cx('only-mobile', 'logo')} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/vip+tap+logo-01.png'} alt="logo" />
                                </a>
                            </div>  
                        </div>

                        <div className={cx("h-right", "not-mobile")}>
                            <ul className={cx("primary")}>
                                <li>
                                    <a href="mailto:vipadmin@tickledmedia.com">{t("brand_enquiry_send_query")}</a>
                                </li>
                                <li>
                                    <a href="#enrollCTA" onClick={e => smoothScroll(e, enrollScrollRef)}>{t("brand_enquiry_download_e-book")}</a>
                                </li>
                                <li>
                                    <a href="https://www.parents.vip/" target="blank">{t("brand_enquiry_community")}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={cx("only-mobile", "menu-wrap")}>
                        <nav>
                            <ul className={cx("flex", "flex-center", "primary")}>
                                <li>
                                    <a href="mailto:vipadmin@tickledmedia.com" onClick={() => {gtmSend('dynamic','Send query','click'); }}>{t("brand_enquiry_send_query")}</a>
                                </li>
                                <li>
                                    <a href="#enrollCTA" onClick={e => {gtmSend('dynamic','Download ebook','click'); smoothScroll(e, enrollScrollRef)}}>{t("brand_enquiry_download_e-book")}</a>
                                </li>
                                <li>
                                    <a href="https://www.parents.vip/" onClick={() => {gtmSend('dynamic','Community','click'); }} target="blank">{t("brand_enquiry_community")}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    
                    {
                        showNotificationBar &&
                         <NotificationBar />
                    }
                </header>
                <div className={cx("brand-fluid-main-container")}>
                    <div className={cx("right-panel-decative")}>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "position-relative", "wrapper-gap")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("flex-columns", "main-header-wrapper")}>
                        <div className={cx("flex-half-column", "brief")}>
                            <div className={cx("login-title-wrapper")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>
                                    	{t("brand_enquiry_we_are_the_largest_parents_community_platform_in_aoutheast_asia")}
                                    </span>
                                </h1>
                            </div>
                            <div className={cx("description", "login-margin-bottom-30", "login-margin-bottom-15", "text-style")}>
                                {t("brand_enquiry_on_board_with_us_are_30000_influential_parents_with_a_social_media_presence_of_500_to_100000_followers_each")}
                            </div>
                            <div className={cx("btn-wrapper", "animation-1", "btn-wrap-mob")}>
                                <div>
                                    <a href="enrollCTA" className={cx("btn btn-primary-rounded", "btn-hover", "pulse")} onClick={e => {gtmSend('dynamic','Find out more','click'); smoothScroll(e, enrollScrollRef)}}>
                                        {t("brand_enquiry_enroll_now")}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx("flex-half-column", "image-wrapp")} > 
                            <div className={cx("mummy-images", "only-desktop")}>
                                <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/vip_parents_larget_community.png" alt={t("brand_enquiry_we_are_the_largest_parents_community_platform_in_aoutheast_asia")}/>
                            </div>
                            <div className={cx("mummy-mob-images","only-mobile")}>
                                <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/Mob_vip_parents_larget_community.png" alt={t("brand_enquiry_we_are_the_largest_parents_community_platform_in_aoutheast_asia")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "position-relative", "wrapper-map-gap", "bg-light-blue", "overflow-hidden")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("flex-columns", "main-header-wrapper")}>
                        <div className={cx("flex-half-column", "image-wrapp")} > 
                            <div className={cx("left-images", "only-desktop")}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/VIPmap.png" alt="Parenting portal across SEA"/>
                            </div>
                        </div>
                        <div className={cx("flex-half-column", "brief")}>
                            <div className={cx("login-title-wrapper")}>
                                <h2 className={cx("title")}>
                                    <span className={cx("title-center-left")}>
                                        {t("brand_enquiry_VIP_Parents_is_an_initiative_by_theAsianparent_the_1_parenting_portal_across_Southeast_Asia_reaching_33_million_parents")}
                                    </span>
                                </h2>
                            </div>
                            <div className={cx("mummy-mob-images","only-mobile")}>
                                <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/VIPmapMobile.png" alt="Parenting portal across SEA"/>
                            </div>
                            <div className={cx("desc")}>
                                <p>{t("brand_enquiry_we_have_office")}</p>
                            </div>
                            <div className={cx("vip-counries")}>
                                <ul>
                                    <li><span>• </span>{t("brand_enquiry_singapore")}</li>
                                    <li><span>• </span>{t("brand_enquiry_malaysia")}</li>
                                    <li><span>• </span>{t("brand_enquiry_indonesia")}</li>
                                </ul>
                                <ul>
                                    <li><span>• </span>{t("brand_enquiry_thailand")}</li>
                                    <li><span>• </span>{t("brand_enquiry_philippines")}</li>
                                    <li><span>• </span>{t("brand_enquiry_vietnam")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                // <div className={cx("brand-fluid-container-fluid", "position-relative", "wrapper-vision-gap")}>
                //     <div className={cx("brand-fluid-container")}>
                //         <div className={cx("flex-columns", "main-header-wrapper")}>
                //             <div className={cx("flex-half-column", "brief")}>
                //                 <div className={cx("login-title-wrapper")}>
                //                     <h2 className={cx("title", "our-vision-title")}>
                //                         <span className={cx("title-left")}>
                //                             {t("brand_enquiry_Our_Vision_is")}
                //                         </span>
                //                     </h2>
                //                 </div>
                //                 <div className={cx("vision-mob-images","only-mobile")}>
                //                     <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/mob-our-vision2.png" alt={t("brand_enquiry_Our_Vision_is")}/>
                //                 </div>
                //                 <div className={cx("description", "login-margin-bottom-30", "login-margin-bottom-15", "vision-desc")}>
                //                    {t("brand_enquiry_to_empower_and_enable_parents_in_Southeast_Asia_to_improve_their_social_capital")}
                //                 </div>
                //             </div>
                //             <div className={cx("flex-half-column", "image-wrapp")} > 
                //                 <div className={cx("mummy-images", "only-desktop")}>
                //                     <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/our_vision3.png" alt={t("brand_enquiry_Our_Vision_is")}/>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            }

           <div className={cx("brand-fluid-container-fluid", "brand-benifit-wrapper", "position-relative" ,"wrapper-gap-2")}>
                <div className={cx("brand-fluid-container", "padding-zero")}>
            		<div className={cx("login-title-wrapper")}>
                            <h2 className={cx("title")}>
                                <span className={cx("title-center")}>
                                    {t("brand_enquiry_with_our_dashboard_you_can_scale_campaigns_for_hundreds_and_thousands")}
                                </span>
                            </h2>
                        </div>

            		<div className={cx("brand-details-warpper")}>
                        <div className={cx("content-section")}>
                            <ul>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_1.png" alt={t("brand_enquiry_an_increase_in_followers_and_visits_to_your_page")}/>
                                    <span>
                                        {t("brand_enquiry_an_increase_in_followers_and_visits_to_your_page")}
                                    </span>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_6.png" alt={t("brand_enquiry_high_social_media_engagement")}/>
                                    <span>
                                       {t("brand_enquiry_high_social_media_engagement")}
                                    </span>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_2.png" alt={t("brand_enquiry_peer_consideration_following_trusted_parent_reviews")}/>
                                    <span>
                                       {t("brand_enquiry_peer_consideration_following_trusted_parent_reviews")}
                                    </span>
                                </li>

                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_3.png" alt={t("brand_enquiry_online_or_offline_sales_conversion")}/>
                                    <span>
                                        {t("brand_enquiry_online_or_offline_sales_conversion")}
                                    </span>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_4.png" alt={t("brand_enquiry_loyalty_through_Brand_Ambassadors")}/>
                                    <span>
                                        {t("brand_enquiry_loyalty_through_Brand_Ambassadors")}
                                    </span>
                                </li>

                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/icon_5.png" alt={t("brand_enquiry_increased_word_of_mouth_recommendations")}/>
                                    <span>
                                        {t("brand_enquiry_increased_word_of_mouth_recommendations")}
                                    </span>
                                </li>
                                {
                                    // <li className={cx("only-mobile")}>
                                    //     <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-9.png" alt=""/>
                                    //     <span>
                                    //     <strong>{t("new_login_connect_and_meet")} </strong> {t("new_login_other_vip_members")}
                                    //     </span>
                                    // </li>
                                }
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>


            <div className={cx("brand-fluid-container-fluid", "position-relative" ,"wrapper-gap-3", "video-intro-wrapper")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("main-header-wrapper")}>
                        <div className={cx("login-title-wrapper")}>
                            <h2 className={cx("title")}>
                                <span className={cx("title-center")}>
                                    {t("brand_enquiry_watch_our_introduction_video")}
                                </span>
                            </h2>
                        </div>
                        <div className={cx("yt-video-wrap")}>
                            <div onClick={e => {YoutubeVideo(e); gtmSend('dynamic','Play Video','click');}}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/video_thumb.png" className={cx("intro-video")} alt={t('intro-video')} />
                            </div>
                            <div style={{display: "none"}}>
                                <iframe className={cx('iframe-video')} src="https://www.youtube.com/embed/nd_CbdptJ9M" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "case-study-warpper", "bg-light-blue")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("login-title-wrapper")}>
                        <h2 className={cx("title")}>
                            <span className={cx("title-center")}>
                                {t("brand_enquiry_browse_our_case_studies")}
                            </span>
                        </h2>
                    </div>
                    <div className={cx("content-section")}>
                        <Carousel
                            elems={[
                                <div className={cx("brands-img-container")}>
                                    <div className={cx("flex-columns")}>
                                        <div className={cx("flex-column-40P")}>
                                            <div className={cx("login-title-wrapper", "only-desktop")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-left")}>
                                                        <strong>DANONE BEBELAC</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className={cx("case-study-items")}>
                                                <ul>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Campaign Objective:</strong> <br/>
                                                            Danone wanted parents to cook recipes from Bebelac.co.id using Bebelac milk as one of the ingredients!
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Geo:</strong> <br/>
                                                            Indonesia
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Strategy:</strong> <br/>
                                                            We invited our  VIParents to participate on a weekly cooking contest hosted by Bebelac brand with a task to post video challenge in Facebook and Instagram, tagging-challenge 3 of their friends. 
Many parents in our community know each other which built up the word-of-mouth effect where many more parents mentioned  about Bebelac brands and participated in the contest.
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Result:</strong> <br/>
                                                                <span>
                                                                    • About 150 UGC videos submitted  within just one week for each period!
                                                                </span>
                                                                <span>
                                                                    • Over 40,000  likes with  over 9,000 comments and shares were generated
                                                                </span>
                                                            </p>
                                                      </li>
                                                </ul>
                                          </div>
                                        </div>
                                        <div className={cx("flex-column-60P")}>
                                            <div className={cx("login-title-wrapper", "only-mobile", "title-wrap")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-center")}>
                                                        <strong>DANONE BEBELAC</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <img className={cx("case-study-logo")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/logo-bebelac.png" alt="DANONE BEBELAC" />
                                        </div>
                                    </div>
                                </div>,
                                <div className={cx("brands-img-container")}>
                                    <div className={cx("flex-columns")}>
                                        <div className={cx("flex-column-40P")}>
                                            <div className={cx("login-title-wrapper", "only-desktop")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-left")}>
                                                         <strong>SANOFI FAMHEALTHY</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className={cx("case-study-items")}>
                                                <ul>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Campaign Objective:</strong> <br/>
                                                            The client wanted to show support for families during the  lockdown through an online event and get as much participation as possible.
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Geo:</strong> <br/>
                                                            Phillipine
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Strategy:</strong> <br/>
                                                            When the lockdown started in March 2020, theAsianparent insights identified that 46% of moms in Philippines had said that they will rely on medical professionals in helping them make their purchase decision especially during this period. 
Our solution was  to bring our experts closer to Moms through hosting FAMHealthy LIVE webinars and involve our VIParents to start dialogues about the webinars and relevant topics.
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Result:</strong> <br/>
                                                                <span>
                                                                    • Total reach is 98,200
                                                                </span>
                                                                <span>
                                                                    • Total engagement is satisfactory at 37,800 likes, 20,300 comments, and 1,125 shares
                                                                </span>
                                                            </p>
                                                      </li>
                                                </ul>
                                          </div>
                                        </div>
                                        <div className={cx("flex-column-60P")}>
                                            <div className={cx("login-title-wrapper", "only-mobile", "title-wrap")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-center")}>
                                                         <strong>SANOFI FAMHEALTHY</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <img className={cx("case-study-logo")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/logo-sanofi.png" alt=" SANOFI FAMHEALTHY" />
                                        </div>
                                    </div>
                                </div>,
                                <div className={cx("brands-img-container")}>
                                    <div className={cx("flex-columns")}>
                                        <div className={cx("flex-column-40P")}>
                                            <div className={cx("login-title-wrapper", "only-desktop")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-left")}>
                                                         <strong>HAPPY FRESH</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className={cx("case-study-items")}>
                                                <ul>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Campaign Objective:</strong> <br/>
                                                            Acquire quality New users  and 1st time orders. Create brand awareness and showcase user experience.
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Geo:</strong> <br/>
                                                            Malaysia, Indonesia, and Thailand
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Strategy:</strong> <br/>
                                                            Our VIParent  influencers  influenced their followers through video posts and  share their Happy Fresh's experience to their loved ones and ask them to try it, they also  educate people about using the referral program. On top of that, if people refer to their friends with the referral program, they can get extra discounts as well.

                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Result:</strong> <br/>
                                                                <span>
                                                                    • Total reach is over 250,000
                                                                </span>
                                                                <span>
                                                                    • Over 20,000 likes with 600 comments and shares were generated
                                                                </span>
                                                            </p>
                                                      </li>
                                                </ul>
                                          </div>
                                        </div>
                                        <div className={cx("flex-column-60P")}>
                                            <div className={cx("login-title-wrapper", "only-mobile", "title-wrap")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-center")}>
                                                         <strong>HAPPY FRESH</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <img className={cx("case-study-logo")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/logo-happyfresh.png" alt="HAPPY FRESH" />
                                        </div>
                                    </div>
                                </div>,
                                <div className={cx("brands-img-container")}>
                                    <div className={cx("flex-columns")}>
                                        <div className={cx("flex-column-40P")}>
                                            <div className={cx("login-title-wrapper", "only-desktop")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-left")}>
                                                         <strong>PHOTOBOOK</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <div className={cx("case-study-items")}>
                                                <ul>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Campaign Objective:</strong> <br/>
                                                            Photobook wanted to increase app downloads and purchases
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Geo:</strong> <br/>
                                                            Singapore, Malaysia, Indonesia, Thailand, Philippines and Vietnam
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Strategy:</strong> <br/>
                                                            After products arrived, our parents posted their reviews of the products on their social media and prompted their friends and followers to buy by sharing discount voucher code in their caption post.
                                                            </p>
                                                      </li>
                                                      <li>
                                                            <span>
                                                                  <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png"/>
                                                            </span>
                                                            <p>
                                                            <strong>Result:</strong> <br/>
                                                                <span>
                                                                    • Total participants: 688 VIPP with a total reach over 300K out of which 55% were new users
                                                                </span>
                                                                <span>
                                                                    • 175 posts  on IG and IG story with over 1,000 comments and shares
                                                                </span>
                                                            </p>
                                                      </li>
                                                </ul>
                                          </div>
                                        </div>
                                        <div className={cx("flex-column-60P")}>
                                            <div className={cx("login-title-wrapper", "only-mobile", "title-wrap")}>
                                                <h2 className={cx("title", "custom-title-style")}>
                                                    <span className={cx("title-center")}>
                                                         <strong>PHOTOBOOK</strong>
                                                    </span>
                                                </h2>
                                            </div>
                                            <img className={cx("case-study-logo")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/logo-photobook.png" alt=" PHOTOBOOK" />
                                        </div>
                                    </div>
                                </div>
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "position-relative", "wrapper-gp", "connect-with-community")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("flex-columns", "main-header-wrapper")}>
                        <div className={cx("flex-half-column", "image-wrapp")} > 
                            <div className={cx("group-of-people-img", "only-desktop")}>
                                <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/parenting-community.png" alt={t("brand_enquiry_connect_with_our_parenting_community")}/>
                            </div>
                        </div>
                        <div className={cx("flex-half-column", "brief")}>
                            <div className={cx("login-title-wrapper")}>
                                <h2 className={cx("title")}>
                                    <span className={cx("title-center-left")}>
                                        {t("brand_enquiry_connect_with_our_parenting_community")}
                                    </span>
                                </h2>
                            </div>
                            <div className={cx("mob-group-of-people-img","only-mobile")}>
                                <img className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/parenting-community.png" alt={t("brand_enquiry_connect_with_our_parenting_community")}/>
                            </div>
                            <div className={cx("desc-wrap")}> 
                            <div className={cx("description")}>
                                <p>
                                    {t("brand_enquiry_we_work_with_all_kinds_of_partners_from_top_brands_to_small_and_medium_size_businesses")} {t("brand_enquiry_get_a_FREE_consultation_or_find_out_more_about_us_by_emailing_us_at")}

                                </p>
                            </div>
                            <div className={cx("btn-wrapper", "animation-1", "btn-wrap-mob")} id="enrollCTA" ref={enrollScrollRef}>
                                <div className={cx("btn-center")}>
                                    <a onClick={() => {gtmSend('dynamic','Connect with community click','click'); }} href="mailto:vipadmin@tickledmedia.com" className={cx("btn btn-primary-rounded", "btn-hover", "pulse")}>
                                        vipadmin@tickledmedia.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "form-wrapper", "wrapper-gap")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("flex-columns", "form-content")}>
                        <div className={cx("flex-column-40P")}>
                            <div className={cx("description")}>
            					<p>
            						{t("brand_enquiry_would_you_like_to_learn_more_about_Southeast_Asia_parenting_community_demographic")}
            					</p>
            				</div>
            				<div className={cx("flex-column", "image-padding")}>
            					<div className={cx("img-wrap")}>
            						<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-6.png" alt=""/>
            					</div>
        						<div className={cx("description")}>
	            					<p>
	            						{t("brand_enquiry_download_our_comprehensive_insights_e_book_by_filling_out_this_form")}
	            					</p>
	            				</div>
            				</div>
                        </div>
                        <div className={cx("flex-column-60P")}>
                            
                            <div className={cx("submit-form-wrapper")}>

                            	<form id="brandEnquiryForm" onSubmit={e => onFormSubmit(e, "brandEnquiryForm")}>

                                    <EnquiryForm bigForm={"bigFormRadio"} />
                            		
                            	</form>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "brand-warpper", "wrapper-gap")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("login-title-wrapper", "center")}>
                        <h2 className={cx("title")}>
                            <span className={cx("title-center")}>
                                {t("brand_enquiry_Brands_we_have_worked_with")}
                            </span>
                        </h2>
                    </div>
                    <div className={cx("brand-details-warpper")}>
                        <div className={cx("content-section")}>
                            <ul className={cx("bNw-brands")}>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-1.png" alt="Arla"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-2.png" alt="Bosch"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-3.png" alt="Kodomo Challenge"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-4.png" alt="Tech Observer"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-5.png" alt="Posb"/>
                                </li>
                               
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-7.png" alt="Media Literacy Council"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-8.png" alt="Danone"/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-8.png" alt="Danone"/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-9.png" alt="Curel"/>
                                </li>

                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-10.png" alt="Prudential"/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-11.png" alt="Discovery Camps"/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-12.png" alt="Health Promotion Board"/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-13.png" alt="Philips"/>
                                </li>
                            </ul>
                            <ul className={cx("bNw-brands", "only-desktop")} style={{marginTop:"50px"}}>
                                
                                
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-9.png" alt="Curel"/>
                                </li>

                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-10.png" alt="Prudential"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-11.png" alt="Discovery Camps"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-12.png" alt="Health Promotion Board"/>
                                </li>
                                <li>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-13.png" alt="Philips"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cx("brand-fluid-container-fluid", "footer-image-wrapper", "wrapper-gap")}>
                <div className={cx("brand-fluid-container")}>
                    <div className={cx("footer-image")}>
                        <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/brand_enquiry/vip_p_logo.png" alt="VIParents logo" />
                    </div>
                </div>
            </div>

             <FloatingEnquiryFormWidget />
             {
                isThankyouMsg &&
                 <div className={cx("thankyou-overlay")}>
                    <div className={cx("ty-panel")}>
                        <div className={cx("ty-body")}>
                            <div className={cx("ty-img")}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-6.png" />
                            </div>
                            <div className={cx("ty-text")}>
                                <h4>{t("brand_enquiry_thank_you")}</h4>
                            </div>
                            <p>
                                {t("brand_enquiry_one_of_our_team_will_reach_out")} 
                            </p>
                            <div className={cx("btn-wrapper", "animation-1", "btn-wrap-mob")}>
                                <div>
                                    <a onClick={closeTyPanel} className={cx("btn btn-primary-rounded", "btn-hover", "pulse", "text-center")}>
                                        {t("brand_enquiry_back_to_homepage")}
                                    </a>
                                </div>
                            </div>
                           {
                               //  <p className={cx("text-red")}>
                               //     Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
                               // </p>
                            }
                        </div>
                    </div>
                 </div>
            }
        </Fragment>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandEnquirySave
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandEnquiryPage);
