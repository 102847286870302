import React, { Fragment, useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import cx from 'classnames/bind';
import AboutSidebar from '../../../Influencer/Sidebar/About';
import CampaignSidebar from '../../../Influencer/Sidebar/Campaign';
import ContentCreatorSidebar from '../../../Influencer/Sidebar/ContentCreator';
import JobsSidebar from '../../../Influencer/Sidebar/Jobs';
import ProfileSidebar from '../../../Influencer/Sidebar/Profile';
import FeedbackSidebar from '../../../Influencer/Sidebar/Feedback';
import { getUrlParameter } from '../../../../helpers';
import { Modal } from 'antd';

const BaseLayout = ({children=null, leftCards=[], rightCards=[], shouldKeepEmpty = false}) => {
    const [showMsgPopup, setShowMsgPopup] = useState('')

    //sdiebar defaults
    leftCards = [
        ...leftCards,
        ...(
            shouldKeepEmpty
            ? []
            : [
                //<CampaignSidebar />,
                //<ContentCreatorSidebar />,
                <JobsSidebar />,
                <ProfileSidebar />,
                <AboutSidebar />,
                <FeedbackSidebar />
            ]
        )
    ];

    useEffect(() => {
        if(getUrlParameter('msg') &&
            ['influencerSocialAccountsValidatorForGet', 'influencerCampaignPermissionValidatorForGET',
            'influencerRegistrationValidator', 'influencerCampaignModerationValidatorForGET'].indexOf(getUrlParameter('msg')) !== -1)
        {
            setShowMsgPopup(getUrlParameter('msg'));

            let url = window.location.href;
            url = url.replace(`&msg=${getUrlParameter('msg')}`, '');
            url = url.replace(`msg=${getUrlParameter('msg')}`, '');
            url = url.replace('?&', '?');
            if(url[url.length - 1] == '?') {
                url = url.replace('?', '');
            }

            let state = {};
            let title = "";
            window.history.replaceState(state, title, url);
        }
    }, [])

    let ifLeftCards = false;
    if(typeof leftCards != "undefined" && leftCards.length != 0) {
        ifLeftCards = true;
    }
    let ifRightCards = false;
    if(typeof rightCards != "undefined" && rightCards.length != 0) {
        ifRightCards = true;
    }

    return (
        <div className={cx('influencer-base-layout')}>

            <Header />

            <main className={cx("influencer-main-container")}>

                <div id="startMainContentSection"></div>

                <div className={cx(!ifLeftCards && "left-panel-decative", !ifRightCards && "right-panel-decative")}>
                    {
                        ifLeftCards &&

                        <aside className={cx("left-panel")}>

                            <div className={cx("")}>
                                <div id="leftPanelTop"></div>

                                <div id="leftPanelMain" className={cx("influencer-sidebar-container")}>
                                    {
                                        leftCards && leftCards.length !== 0 &&
                                        leftCards.map((card, i) => {
                                            return <Fragment key={i}>{card}</Fragment>
                                        })
                                    }
                                </div>

                                <div id="leftPanelBottom"></div>
                            </div>
                        </aside>
                    }

                    <div className={cx("center-panel")}>
                        <div id="centerPanelTop"></div>

                        <div id="centerPanelMain">
                            {children}
                        </div>

                        <div id="centerPanelBottom"></div>
                    </div>

                    {
                        ifRightCards &&

                        <aside className={cx("right-panel")}>
                            <div className="">
                                <div id="rightPanelTop"></div>

                                <div id="rightPanelMain">
                                    {
                                        rightCards && rightCards.length !== 0 &&
                                        rightCards.map((card, i) => {
                                            return <Fragment key={i}>{card}</Fragment>
                                        })
                                    }
                                </div>

                                <div id="rightPanelBottom"></div>
                            </div>
                        </aside>
                    }
                </div>

            </main>

            <Modal
                title={'Some thing went wrong'}
                visible={showMsgPopup ? true : false}
                onCancel={e => setShowMsgPopup('')}
                footer={null}
            >
                <div>
                    {
                        showMsgPopup == "influencerSocialAccountsValidatorForGet" &&
                        <p>Social Media Account connection is not completed</p>
                    }
                    {
                        showMsgPopup == "influencerCampaignPermissionValidatorForGET" &&
                        <p>Campaign Permission is not set for your account. Contact our team to resolve this issue</p>
                    }
                    {
                        showMsgPopup == "influencerRegistrationValidator" &&
                        <p>Registration is not completed</p>
                    }
                    {
                        showMsgPopup == "influencerCampaignModerationValidatorForGET" &&
                        <p>Campaign Moderation is not completed. Contact our team to resolve this issue</p>
                    }
                </div>
            </Modal>

            <Footer />

        </div>
    );
}

export default BaseLayout;
