/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
import update from 'react-addons-update';

function IS() {
    return {
        list: [],
        page: 1,
        total: 0
    }    
}

const INITIAL_STATE = IS();

export var campaignFeedReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();
    //
    let index, subIndex;

    switch (action.type) {        
        case 'COMPLETE_CAMPAIGN_FEED_FETCH':
            return {
                ...state,
                page: action.payload.page,
                list: [...(action.payload.shouldReset ? action.payload.data.list : [...state.list, ...action.payload.data.list])],
                total: action.payload.data.total
            };
        break;                    
        default:
            return state;
    }
};

const findIndexById = (id, list) => {
    let returnIndex = -1;
    for(let i in list) {
        if(list[i].id == id){
            returnIndex = i;
            break
        }
    }
    return parseInt(returnIndex);
}
