import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerCampaignModeration, useInfluencerIfCampaignSubmissionRequired, useInfluencerCampaignSubmissionStatus } from '../../../../hooks/generic';
import { influencerCampaignSubmission, influencerCampaignFeedbackUpdate, influencerCampaignSubmissionEdit } from '../../../../actions';
import SubmissionForm from '../../../../components/Mob/Campaign/Submission/Form';
import FeedbackAnswer from '../../../../components/Mob/Campaign/Submission/Feedback/Answer';
import notification from '../../../../components/UIElems/Notification';
import { translate } from 'react-i18next';
import { isValidUrl } from '../../../../utils';
import Loader from '../../../../components/UIElems/Loader';
import CONFIG from '../../../../config';
import q from 'q';

const CampaignSubmission = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const campaignDetail = props.rdx.webviewReducer.campaignDetail;
    const [submissionData, setSubmissionData] = useState();
    let feedbackData;

    const ifCampaignSubmissionRequired = useInfluencerIfCampaignSubmissionRequired(() => {}, props.dispatch, campaignDetail.campaignId, false);

    const isSubmitted = useInfluencerCampaignSubmissionStatus(
        () => setIsLoading(false),
        props.dispatch,
        campaignDetail.campaignId,
        props.rdx.webviewReducer.profile.influencerId,
        forceUpdate
    );

    const moderationData = useInfluencerCampaignModeration(
        () => setIsLoading(false),
        props.dispatch,
        campaignDetail.campaignId,
        props.rdx.webviewReducer.profile.influencerId,
        forceUpdate
    );

    const sendFeedback = () => {
        return props.actions.influencerCampaignFeedbackUpdate(campaignDetail.campaignId, props.rdx.webviewReducer.profile.influencerId, feedbackData.filter(d => d.value.length));
        // .then(() => {
        //     notification.send({device: props.rdx.appReducer.deviceType, message: 'Feedback Sent!', type: 'success'});
        // })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(!(submissionData || feedbackData)) return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly fill everything!', type: 'error'});
        setIsLoading(true);
        //all requests
        let allRequests = [];
        //submission
        let campaignId, influencerId;
        if(submissionData) {
            let finalData = Object.values(submissionData).filter(d => {
                if (d.filter !== 'actionImage' && Array.isArray(d.url)) {
                    return d.url.length > 0;
                } else if (d.filter === 'actionImage') {
                    return d.ifRequired;
                } else {
                    return d.url && d.url > '';
                }
            });
            influencerId = props.rdx.webviewReducer.profile.influencerId;
            campaignId = campaignDetail.campaignId;
            let actionImageData = 'actionImage' in submissionData && submissionData['actionImage']['data'] ? submissionData['actionImage']['data'] : null;
            //url validation
            let errUrls = getErrorUrls(finalData);
            if(errUrls.length > 0) {
                setIsLoading(false);
                return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly enter valid urls!', type: 'error'});
            }
            //validation
            if(!finalData.length && !actionImageData) {
                setIsLoading(false);
                return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly fill everything!', type: 'error'});
            }

            if (isSubmitted === 5) {
                if (submissionData['actionImage'].ifRequired
                    && ((actionImageData.length === 0 && submissionData['actionImage']['url'].length < campaignDetail[submissionData['actionImage'].numLinks])
                    || (actionImageData.length > 0 && actionImageData.length + submissionData['actionImage']['url'].length < campaignDetail[submissionData['actionImage'].numLinks]))
                ) {
                    setIsLoading(false);
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload all the required images!', type: 'error'});
                }

                allRequests.push(
                    props.actions.influencerCampaignSubmissionEdit({campaignId, influencerId, finalData: JSON.stringify(finalData), ...(actionImageData && actionImageData.length > 0? actionImageData: null)})
                );
            } else {
                //image validation
                if(submissionData['actionImage'].ifRequired && (!actionImageData || (actionImageData && actionImageData.length < campaignDetail[submissionData['actionImage'].numLinks]))) {
                    setIsLoading(false);
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload all the required images!', type: 'error'});
                }
                //
                allRequests.push(
                    props.actions.influencerCampaignSubmission({campaignId, influencerId, finalData: JSON.stringify(finalData), ...(actionImageData && actionImageData.length > 0? actionImageData: null)})
                );
            }

        }        
        //feedback
        if(feedbackData) allRequests.push(sendFeedback());
        //all requests
        if(allRequests.length) {
            let successUrl = CONFIG['siteUrl'] + `mob/campaign/${campaignId}/submission/success?influencerId=${influencerId}`;
            let failureUrl = CONFIG['siteUrl'] + `mob/campaign/${campaignId}/submission/failure?influencerId=${influencerId}`;
            //request
            q.all(allRequests)
            .then(values => {
                setIsLoading(false);
                if (values && values[0] && 'err' in values[0] && !values[0].err) {
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Done!', type: 'success'});
                    setTimeout(() => window.location = successUrl, 1500);
                } else {
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
                    setTimeout(() => window.location = failureUrl, 1500);
                }
            })
            .catch(e => {
                setIsLoading(false);
                notification.send({device: props.rdx.appReducer.deviceType, message: e.message || 'Something went wrong!', type: 'error'});
                setTimeout(() => window.location = failureUrl, 1500);
            });
        }        
    }
    
    function getErrorUrls(data) {
        let errorUrls = [];
        data.forEach(d => {
            if (Array.isArray(d.url)) {
                const url = d.url.filter(url => (!isValidUrl(url) && ['other', 'image'].indexOf(d.type) === -1));
                errorUrls = [...errorUrls, ...url];
            }
        });
        return errorUrls;
    }
    
    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <SubmissionForm assignUpdater={setSubmissionData}
                    campaignDetail={campaignDetail}
                    moderationData={moderationData}
                    t={props.t}
                    deviceType={props.rdx.appReducer.deviceType}
                    influencerId={props.rdx.webviewReducer.profile.influencerId}
                    dispatch={props.dispatch}
                    submissionStatus={isSubmitted}
                    setIsLoading={setIsLoading} />

                <FeedbackAnswer assignUpdater={data => feedbackData = data}
                    campaignId={campaignDetail.campaignId}
                    t={props.t} />

                {
                    ifCampaignSubmissionRequired &&
                    isSubmitted !== 4 &&
                    <div className={cx("button-wrap-center-align", "white-block", "text-center", "cd-padding-top25-bot15")}>
                        <button className={cx("btn", "btn-blue")} type="submit" >{props.t('campaign_button_submit')}</button>
                    </div>
                }                
                {
                    ifCampaignSubmissionRequired &&
                    isSubmitted === 4 &&
                    <div className={cx("button-wrap-center-align", "white-block", "text-center", "cd-padding-top25-bot15")}>
                        <button disabled className={cx("btn", "btn-success")} type="submit">{props.t('approval_status_4')}</button>
                    </div>
                }
            </form>

            { isLoading && <Loader /> }
        </Fragment>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerCampaignSubmission,
        influencerCampaignFeedbackUpdate,
        influencerCampaignSubmissionEdit
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignSubmission));