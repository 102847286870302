import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useAllUsers, useCountryList, useCitiesList } from '../../../../hooks/generic';
import { 
    brandInfluencerApprove,
    brandInfluencerRemind,
    usersDownload,
    brandCampaignBriefForInfluencer,
    campaignListFetch,
    genericAction
} from '../../../../actions';
import { Parser, parseAsync } from 'json2csv';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import notification from '../../../../components/UIElems/Notification';
import RatingPopover from '../../Grading/Popover';
import { format } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en";
// import "react-datepicker/dist/react-datepicker.css";
import qs from 'qs';
import { Popconfirm, Modal, Tooltip, Popover, Button } from 'antd';
import Loader from '../../../../components/UIElems/Loader';
import RangeSelector from '../../../../components/UIElems/RangeSelector';

const ACCOUNT_TYPE = {
    0: 'contributor',
    1: 'vip',
    2: 'tapfluencer',
    3: 'expert'
}

const CustomReminderModal = ({rowData, callback}) => {
    const [isCustomReminderVisible, setIsCustomReminderVisible] = useState(false);
    const [isCustomReminderLoading, setIsCustomReminderLoading] = useState(false);
    const [customReminderText, setCustomReminderText] = useState("");

    const onClick = () => {
        if(window.confirm('Remind user about social accounts?')) {
            setIsCustomReminderVisible(true);
        }
    }

    return (
        <span>
            <a onClick={onClick} style={{color: 'inherit'}}>Remind</a>
            <Modal
                title={'Custom Message'}
                visible={isCustomReminderVisible}
                okText="Send"
                onOk={ () => { setIsCustomReminderLoading(true); callback(rowData.influencerId, customReminderText); } }
                confirmLoading={isCustomReminderLoading}
                onCancel={e => setIsCustomReminderVisible(false)}
                maskClosable={false}>
                <textarea value={customReminderText} onChange={e => setCustomReminderText(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

const CampaignBriefModal = ({rowData, campaignList, callback}) => {
    const [isCampaignBriefVisible, setIsCampaignBriefVisible] = useState(false);
    const [isCampaignBriefLoading, setIsCampaignBriefLoading] = useState(false);
    const [campaignId, setCampaignId] = useState('');

    const onClick = () => {
        if(window.confirm('Send campaign brief to the user?')) {
            setIsCampaignBriefVisible(true);
        }
    }
    
    return (
        <span>
            <a onClick={onClick} style={{color: 'inherit'}}>Send campaign brief</a>
            <Modal
                title={'Select a campaign'}
                visible={isCampaignBriefVisible}
                okText="Send"
                onOk={ () => { setIsCampaignBriefLoading(true); callback(campaignId, rowData.influencerId); } }
                confirmLoading={isCampaignBriefLoading}
                onCancel={e => setIsCampaignBriefVisible(false)}
                maskClosable={false}>            
                <div>
                    <label htmlFor="campaign-brief">Select an active campaign: </label>
                    <select name="campaign-brief" id="campaign-brief" value={campaignId} onChange={e => setCampaignId(e.target.value)}>
                        <option value="">select</option>
                        {
                            campaignList.map((d, i) => {
                                return <option key={i} value={d.campaignId}>{d.campaignName}</option>
                            })
                        }
                    </select>
                </div>
            </Modal>
        </span>
    );
}

const CustomDeclineModal = ({rowData, flag, callback}) => {
    const [isCustomDeclineVisible, setIsCustomDeclineVisible] = useState(false);
    const [isCustomDeclineLoading, setIsCustomDeclineLoading] = useState(false);
    const [customDeclineText, setCustomDeclineText] = useState("");

    const onClick = () => {
        setIsCustomDeclineVisible(true);
    }

    const onOk = () => {
        if(!customDeclineText.length) return alert("You need to specify the reason for declining the user!");
        setIsCustomDeclineLoading(true);
        callback(rowData.influencerId, flag, customDeclineText);
    }

    return (
        <span>
            <a onClick={onClick} style={{color: 'inherit'}}>Decline</a>
            <Modal
                title={'Reason for declining?'}
                visible={isCustomDeclineVisible}
                okText="Send"
                onOk={onOk}
                confirmLoading={isCustomDeclineLoading}
                onCancel={e => setIsCustomDeclineVisible(false)}
                maskClosable={false}>
                <textarea value={customDeclineText} onChange={e => setCustomDeclineText(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

const SocialInfoPopover = ({data}) => {
    let ifNoAccounts = !data.fbCount && !data.instaCount && !data.fbPageCount && !data.twitterCount && !data.youtubeCount && !data.tiktokCount;
    const content = (
        <div>
            {
                data.fbCount > 0 &&
                <div>
                    <div>
                        FB - <span style={{color: 'green'}}>{data.fbCount}</span>  ({format(data.fbCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                data.tiktokCount > 0 && 
                <div>
                    <div>
                        Tiktok - <span style={{color: 'green'}}>{data.tiktokCount}</span>  ({format(data.tiktokCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                data.instaCount > 0 &&
                <div>
                    <div>
                        Insta - <span style={{color: 'green'}}>{data.instaCount}</span>  ({format(data.instaCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                data.fbPageCount > 0 &&
                <div>
                    <div>
                        FB Page - <span style={{color: 'green'}}>{data.fbPageCount}</span>  ({format(data.fbPageCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                data.twitterCount > 0 &&
                <div>
                    <div>
                        Twitter - <span style={{color: 'green'}}>{data.twitterCount}</span>  ({format(data.twitterCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                data.youtubeCount > 0 &&
                <div>
                    <div>
                        Youtube - <span style={{color: 'green'}}>{data.youtubeCount}</span>  ({format(data.youtubeCountLastUpdatedAt, 'DD-MM-YY')})
                    </div>
                </div>
            }
            {
                ifNoAccounts &&
                <div>No Accounts</div>
            }                        
        </div>
    );
    
    return (
        <Popover content={content}
            title="Social Accounts"
            trigger="hover">
            <div>
                <a href="#!" className={cx(ifNoAccounts ? ["status-bg-red", "status-text-red", "status-style"] : ["status-bg-green", "status-text-green", "status-style"])}>info</a> 
            </div>
        </Popover>
    );
}

const TotalReachPopover = ({dataFetcher, filtered, conditions}) => {
    const[isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({fbCount: 0, fbPageCount: 0, instaCount: 0, twitterCount: 0, youtubeCount: 0});

    const onClick = () => {
        setIsLoading(true);        
        dataFetcher('allUsersTotalReach', [filtered, conditions])
        .then(response => {
            setIsLoading(false);
            setData(response.data);
        })
    }

    const content = (
        <div>
            <div>
                <div>
                    Fb - <span style={{color: 'green'}}>{data.fbCount}</span>
                </div>
            </div>
        
            <div>
                <div>
                    Insta - <span style={{color: 'green'}}>{data.instaCount}</span>
                </div>
            </div>
        
            <div>
                <div>
                    Fb Page - <span style={{color: 'green'}}>{data.fbPageCount}</span>
                </div>
            </div>
            <div>
                <div>
                    Twitter - <span style={{color: 'green'}}>{data.twitterCount}</span>
                </div>
            </div>
            <div>
                <div>
                    Youtube - <span style={{color: 'green'}}>{data.youtubeCount}</span>
                </div>
            </div>            
        </div>
    );
    
    return (
        <Popover content={content}
            title="Total Reach"
            trigger="click">
            <span>
                <button type="button" className={cx("btn", "btn-blue")} onClick={onClick}>Total Reach</button>
                { isLoading && <Loader /> } 
            </span>
        </Popover>
    );
}

const EmailPopover = ({email, secondaryEmail = null}) => {
    const content = (
        <div>
            <span>{secondaryEmail}</span>
        </div>
    )
    return (
        <Popover title="Secondary Email" content={content} placement="topLeft">
            <span style={{cursor: "pointer"}}>{email}</span>
        </Popover>
    )
}

const UserTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [reRender, setReRender] = useState(false);    
    const [accountTypeFilter, setAccountTypeFilter] = useState("");
    const [approvalFilter, setApprovalFilter] = useState("");
    const [genderFilter, setGenderFilter] = useState("");
    const [ifAgeRange, setIfAgeRange] = useState(false);
    const [ageRangeFilter, setAgeRangeFilter] = useState({min: 0, max: 100});
    const [ifChildFilter, setIfChildFilter] = useState(false);
    const [childAgeRangeFilter, setChildAgeRangeFilter] = useState({min: 0, max: 20});
    const [childGenderFilter, setChildGenderFilter] = useState("");
    const [ifFollowerCount, setIfFollowerCount] = useState(false);
    const [ifFbFollowerCount, setIfFbFollowerCount] = useState(false);
    const [ifFbPageFollowerCount, setIfFbPageFollowerCount] = useState(false);
    const [ifInstaFollowerCount, setIfInstaFollowerCount] = useState(false);
    const [ifTwitterFollowerCount, setIfTwitterFollowerCount] = useState(false);
    const [ifYoutubeFollowerCount, setIfYoutubeFollowerCount] = useState(false);
    const [ifTiktokFollowerCount, setIfTiktokFollowerCount] = useState(false);
    const [fbCountMin, setFbCountMin] = useState(0);
    const [fbCountMax, setFbCountMax] = useState(0);
    const [fbPageCountMin, setFbPageCountMin] = useState(0);
    const [fbPageCountMax, setFbPageCountMax] = useState(0);
    const [instaCountMin, setInstaCountMin] = useState(0);
    const [instaCountMax, setInstaCountMax] = useState(0);
    const [twitterCountMin, setTwitterCountMin] = useState(0);
    const [twitterCountMax, setTwitterCountMax] = useState(0);
    const [youtubeCountMin, setYoutubeCountMin] = useState(0);
    const [youtubeCountMax, setYoutubeCountMax] = useState(0);
    const [tiktokCountMin, setTiktokCountMin] = useState(0);
    const [tiktokCountMax, setTiktokCountMax] = useState(0);
    const [ifDueDate, setIfDueDate] = useState(false);
    const [dueDateStart, setDueDateStartFilter] = useState(null);
    const [dueDateEnd, setDueDateEndFilter] = useState(null);
    const [countryIdFilter, setCountryIdFilter] = useState('');
    const [cityIdFilter, setCityIdFilter] = useState('');
    const [influencerId, setInfluencerId] = useState(0);
    const [ifPregnantFilter, setIfPregnantFilter] = useState(false);
    const [campaignList, setCampaignList] = useState([]);   
    
    const countryList = useCountryList();

    const citiesList = useCitiesList(countryIdFilter || 0); 

    const conditions = { 
        ...props.conditions,
        ...{
                ...(accountTypeFilter && {accountType: accountTypeFilter}),
                ...(approvalFilter && {isApproved: approvalFilter}),
                countryId: countryIdFilter,
                cityId: cityIdFilter,
                gender: genderFilter,
                influencerId,
                ...(ifAgeRange && {ageRange: ageRangeFilter}),
                ifChildFilter: +ifChildFilter,
                ...(ifChildFilter && {childAgeRange: childAgeRangeFilter}),
                ...(ifChildFilter && {childGender: childGenderFilter}),
                ifPregnantFilter: +ifPregnantFilter,
                ifFollowerCount,
                ifFbFollowerCount,
                ifInstaFollowerCount,
                ifFbPageFollowerCount,
                ifTwitterFollowerCount,
                ifYoutubeFollowerCount,
                ifTiktokFollowerCount,
                ...(ifFbFollowerCount && {fbCountMin, fbCountMax}),
                ...(ifInstaFollowerCount && {instaCountMin, instaCountMax}),
                ...(ifFbPageFollowerCount && {fbPageCountMin, fbPageCountMax}),
                ...(ifTwitterFollowerCount && {twitterCountMin, twitterCountMax}),
                ...(ifYoutubeFollowerCount && {youtubeCountMin, youtubeCountMax}),
                ...(ifTiktokFollowerCount && {tiktokCountMin, tiktokCountMax}),
                ...(ifDueDate && {dueDate: {start: (dueDateStart ? format(dueDateStart, 'YYYY-MM-DD') : null), end: (dueDateEnd ? format(dueDateEnd, 'YYYY-MM-DD') : null)}}),
        }
    };
    let [userList,setUserList] = useAllUsers(() => setLoading(false), props.dispatch, page, pageSize, sorted, filtered, conditions, forceUpdate);

    //linked filters
    useEffect(() => {
        registerLocale("en", enGB);
        let filters;        
        //approval filter
        if(window.location.search && window.location.search.indexOf('approvalFilter') > -1) {
            filters = qs.parse(window.location.search.replace('?', ''));
            setApprovalFilter(filters.approvalFilter);
            setTimeout(() => setForceUpdate(!forceUpdate), 1000);
        } 
        //from single campaign filter
        if(window.location.search && window.location.search.indexOf('filters') > -1) {
            filters = qs.parse(window.location.search.replace('?', ''));
            // console.log(filters);
            setApprovalFilter(1);
            setIfAgeRange(true);
            setAgeRangeFilter({min: parseInt(filters.ageRangeMin), max: parseInt(filters.ageRangeMax)});
            setCountryIdFilter(filters.countryId);
            setCityIdFilter(filters.cityId);
            setIfFollowerCount(true);
            setIfFbFollowerCount(filters.ifFbFollowerCount == 'true');
            setIfInstaFollowerCount(filters.ifInstaFollowerCount == 'true');
            setIfFbPageFollowerCount(filters.ifFbPageFollowerCount == 'true');
            setIfTwitterFollowerCount(filters.ifTwitterFollowerCount == 'true');
            setIfYoutubeFollowerCount(filters.ifYoutubeFollowerCount == 'true');
            setIfTiktokFollowerCount(filters.ifTiktokFollowerCount === 'true');
            setFbCountMin(parseInt(filters.fbCountMin));
            setFbCountMax(parseInt(filters.fbCountMax));
            setInstaCountMin(parseInt(filters.instaCountMin));
            setInstaCountMax(parseInt(filters.instaCountMax));
            setFbPageCountMin(parseInt(filters.fbPageCountMin));
            setFbPageCountMax(parseInt(filters.fbPageCountMax));
            setTwitterCountMin(parseInt(filters.twitterCountMin));
            setTwitterCountMax(parseInt(filters.twitterCountMax));
            setYoutubeCountMin(parseInt(filters.youtubeCountMin));
            setYoutubeCountMax(parseInt(filters.youtubeCountMax));
            setTiktokCountMin(parseInt(filters.tiktokCountMin));
            setTiktokCountMax(parseInt(filters.tiktokCountMax));
            setGenderFilter(filters.gender);
            setIfChildFilter(filters.ifKidFilter == 'true');
            setIfPregnantFilter(filters.ifPregnantFilter == 'true');
            setChildAgeRangeFilter({min: parseInt(filters.kidAgeRangeMin), max: parseInt(filters.kidAgeRangeMax)});
            setChildGenderFilter(filters.kidGender);
            setTimeout(() => setForceUpdate(!forceUpdate), 1000);
        }
    }, [])

    //fetch campaign list
    useEffect(() => {
        props.actions.campaignListFetch(1, 1, 500, [], [], [], null, false, false, true)
        .then(response => {
            setCampaignList(response.data.list.filter(d => d.isActive))
        })
    }, [])

        useEffect(() => {
            userList = userList;
        }, [reRender])
    
    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const influencerApprove = (influencerId, flag, reason) => {
        props.actions.brandInfluencerApprove(influencerId, flag, reason)
        .then(() => {
            notification.send({device: 'desktop', message: 'Done!', type: 'success'});
            for (const index in userList.list) {
                let element = userList.list[index];
                if(element.influencerId === influencerId){
                    element.isApproved = flag;
                    userList.list[index] = element;
                    break;
                }
            }
            // setForceUpdate(!forceUpdate);
            setReRender(!reRender);
            setUserList(userList);
        });
    }

    const influencerRemind = (influencerId, customReminderText='') => {        
        props.actions.brandInfluencerRemind(influencerId, customReminderText)
        .then(() => {
            notification.send({device: 'desktop', message: 'Done!', type: 'success'});
            setTimeout(() => setForceUpdate(!forceUpdate), 1500);
        }) 
    }

    const sendCampaignBrief = (campaignId, influencerId) => {        
        props.actions.brandCampaignBriefForInfluencer(campaignId, influencerId)
        .then(() => {
            notification.send({device: 'desktop', message: 'Done!', type: 'success'});
            setTimeout(() => setForceUpdate(!forceUpdate), 1500);
        }) 
    }

    const PopApproveConfirm = ({onConfirm, onCancel}) => {
        return <Popconfirm placement="top"
            title="account type?"
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText="tapfluencer"
            cancelText="vip"
            okType="default">
            <a href="#!">Approve</a>
        </Popconfirm>;
    }


    const createActionLink = (rowData) => {
        let links = [];
        //influencer approval
        let approvalLink = 
            <PopApproveConfirm onConfirm={e => influencerApprove(rowData.influencerId, 2)}
                onCancel={e => influencerApprove(rowData.influencerId, 1)} />;
        //influencer decline
        let declineLink =            
            <CustomDeclineModal rowData={rowData}
                flag={0}
                callback={influencerApprove} />;        
        //declined        
        if(rowData.isApproved == 0) {
            //approve            
            links.push({title: 'Approve', extraElem: approvalLink});
            //more info
            links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.influencerId, 3)});            
        }
        //approved
        if(rowData.isApproved == 1) {
            links.push({title: 'Decline', extraElem: declineLink});
            links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.influencerId, 3)});
        }
        //pending        
        if(rowData.isApproved === 2 || rowData.isApproved === 9) {
            //approve            
            links.push({title: 'Approve', extraElem: approvalLink});
            //decline
            links.push({title: 'Decline', extraElem: declineLink});
            //more info
            links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.influencerId, 3)});            
        }
        //more info
        if(rowData.isApproved == 3) {
            links.push({title: 'Approve', extraElem: approvalLink});
            links.push({title: 'Decline', extraElem: declineLink});
        }        
        //view profile
        links.push({title: 'View Profile', handler: () => window.open(`/brand/user/${rowData.influencerId}`, '_blank')});
        //remind
        let reminderLink =            
            <CustomReminderModal rowData={rowData}
                callback={influencerRemind}/>;
        links.push({title: 'Remind', extraElem: reminderLink});
        //campaign brief
        let campaignBriefLink =            
            <CampaignBriefModal rowData={rowData}
                campaignList={campaignList}
                callback={sendCampaignBrief}/>;
        links.push({title: 'Send campaign brief', extraElem: campaignBriefLink});
        //ratings history
        links.push({title: 'Ratings History', handler: () => window.open(`/brand/user/${rowData.influencerId}/grading/history`, '_blank')});

        return <MenuPopper title='Manage' links={links} />
    }

    const approvalStatus = (status) => {
        const statusString = {
            0: 'Declined',
            1: 'Approved',
            2: 'Pending',
            3: 'More Info Required'
        }
        const color = {
            0: ["status-bg-red", "status-text-red", "status-style"],
            1: ["status-bg-green", "status-text-green", "status-style"],
            2: ["status-bg-orange", "status-text-orange", "status-style"],
            3: ["status-bg-blue", "status-text-blue", "status-style"]
        }

        return <span className={cx(color[status])}>{statusString[status]}</span>;
    }

    const onAccountTypeFilter = (value) => {
        setAccountTypeFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onApprovalStatusFilter = (value) => {
        setApprovalFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onGenderFilter = (value) => {
        setGenderFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onChildGenderFilter = (value) => {
        setChildGenderFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onAgeRangeFilter = (value) => {
        setAgeRangeFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onChildAgeRangeFilter = (value) => {
        setChildAgeRangeFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onDueDateFilter = (type, value) => {
        if(type == 'start') {
            setDueDateStartFilter(value);
        } else {
            setDueDateEndFilter(value);
        }
        // setForceUpdate(!forceUpdate);        
    }

    const onEnablingConditionalFilter = (value, callback) => {
        callback(value);
        // setForceUpdate(!forceUpdate);
    }

    const onUsersDownload = () => {
        if(window.confirm('Download all users?')) {
            props.actions.usersDownload(sorted, filtered, conditions)
            .then(response => {
                // show success notification
                notification.send({device: 'desktop', message: 'Request recieved! File download will happen in the newly opened tab!', type: 'success'});
                // open download file link in new tab
                setTimeout(() => {window.open(`/brand/users/influencer/download?${response.querystring}`)}, 1000);                
            })
        }        
    }

    const columns = [
        {
            Header: "Id",
            accessor: "influencerId",
            filterable: false,
            sortable: false,
            Footer: "Total: " + userList.total
        },
        {
            Header: "Name",
            accessor: "fullname",
            filterable: true,
            sortable: false
        },
        {
            id: "email",
            Header: "Email",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props => (
                !props.value.secondaryEmail || props.value.email === props.value.secondaryEmail?
                    props.value.email:
                    <EmailPopover email={props.value.email} secondaryEmail={props.value.secondaryEmail} />
            )
        },
        {
            Header: "Account Type",
            accessor: "accountType",
            filterable: false,
            sortable: true,
            Cell: props => (props.value in ACCOUNT_TYPE ? ACCOUNT_TYPE[props.value] : '')
        },
        {
            Header: "Average Rating",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <RatingPopover data={props.value} />
        },
        {
            Header: "Origin",
            accessor: "origin",
            filterable: false,
            sortable: true
        },
        {
            Header: "Status",
            accessor: "isApproved",
            filterable: false,
            sortable: true,
            Cell: props => approvalStatus(props.value)
        },
        {
            Header: "Country",
            accessor: "country",
            filterable: false,
            sortable: true
        },
        {
            Header: "Gender",
            accessor: "gender",
            filterable: false,
            sortable: true
        },
        {
            Header: "Social Info",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <SocialInfoPopover data={props.value} />
        },
        {
            Header: "Last Reminder On",
            accessor: "reminderOn",
            filterable: false,
            sortable: true
        },
        {
            Header: "Action",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div className="user-filters">
                <div className="form-horizontal-row">
                    <div className={cx("form-wrap")}>
                        <label htmlFor="is-approved">Approval Status: </label>
                        <select id="is-approved" name="is_approved" value={approvalFilter} onChange={e => onApprovalStatusFilter(e.target.value)}>
                            <option value="">All</option>
                            <option value="0">Declined</option>
                            <option value="1">Approved</option>
                            <option value="2">Pending</option>
                            <option value="3">More Info Required</option>
                        </select>
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="country-list">Country: </label>
                        <select id="country-list" value={countryIdFilter} onChange={e => setCountryIdFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                    return <option value={country.id} key={i}>{country.name}</option>
                                })
                            }
                        </select>
                    </div>  
                      
                    <div className={cx("form-wrap")}>
                        <label htmlFor="city-list">City: </label>
                        <select id="city-list" value={cityIdFilter} onChange={e => setCityIdFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                citiesList.map((city, i) => {
                                    return <option value={city.id} key={i}>{city.name}</option>
                                })
                            }
                        </select>
                    </div>                 
                </div>

                <div className="form-horizontal-row">
                    <div className={cx("form-wrap")}>
                        <label htmlFor="gender">Gender: </label>
                        <select id="gender" name="gender" value={genderFilter} onChange={e => onGenderFilter(e.target.value)}>
                            <option value="">All</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="influencer-id">User Id: </label>
                        <input type="number" id="influencer-id" name="influencer-id" value={influencerId} onChange={e => setInfluencerId(e.target.value)} />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="account-type">Account Type: </label>
                        <select id="account-type" value={accountTypeFilter} onChange={e => onAccountTypeFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                Object.keys(ACCOUNT_TYPE).map((k, i) => {
                                    return <option value={k} key={i}>{ACCOUNT_TYPE[k]}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="form-horizontal-row">
                    <div className="form-wrap">
                        <div className="custom-checkbox-wrap">
                            <input className="styled-checkbox" id="chk-follower-count" type="checkbox" checked={ifFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfFollowerCount)} />
                            <label for="chk-follower-count">Follower Count:</label>
                        </div>
                    </div>

                    <span className="user-age-range">
                        <div className="custom-checkbox-wrap ">
                            <input className="styled-checkbox" id="chk-user-age" type="checkbox" checked={ifAgeRange} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfAgeRange)} />
                            <label for="chk-user-age">User Age:</label>
                        </div>

                        {
                            ifAgeRange &&
                            <RangeSelector
                                maxValue={100}
                                minValue={0}
                                timeout={0}
                                value={ageRangeFilter}
                                onChange={val => onAgeRangeFilter(val)}
                            />
                        }
                    </span>
                </div> 

                <div className="form-horizontal-row-with-label" style={{display: `${ifFollowerCount ? 'flex' : 'none'}`}}>
                        { 
                            ifFollowerCount &&
                            <div className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-fb" type="checkbox" checked={ifFbFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfFbFollowerCount)} />
                                    <label for="chk-fb">Fb:</label>
                                </div>
                                {
                                    ifFbFollowerCount &&
                                    <span className={cx("form-col-wrap")}>
                                        <Tooltip title="min">
                                            <input id="fb-count-min" type="number" onChange={e => setFbCountMin(e.target.value)} value={fbCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="fb-count-max" type="number" onChange={e => setFbCountMax(e.target.value)} value={fbCountMax} />
                                        </Tooltip>
                                    </span>
                                }                                                                
                            </div>
                        }

                        { 
                            ifFollowerCount &&
                            <span className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-insta" type="checkbox" checked={ifInstaFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfInstaFollowerCount)} />
                                    <label for="chk-insta">Insta:</label>
                                </div>
                                {
                                    ifInstaFollowerCount &&
                                    <span className={cx("form-col-wrap")}>
                                        <Tooltip title="min">
                                            <input id="insta-count-min" type="number" onChange={e => setInstaCountMin(e.target.value)} value={instaCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="insta-count-max" type="number" onChange={e => setInstaCountMax(e.target.value)} value={instaCountMax} />
                                        </Tooltip>
                                    </span>
                                }                                
                            </span>
                        }

                        <span>&nbsp;&nbsp;&nbsp;</span>

                        { 
                            ifFollowerCount &&
                            <span className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-fb-page" type="checkbox" checked={ifFbPageFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfFbPageFollowerCount)} />
                                    <label for="chk-fb-page">FbPage:</label>
                                </div>
                                {
                                    ifFbPageFollowerCount &&
                                    <span className="form-col-wrap">
                                        <Tooltip title="min">
                                            <input id="fb-page-count-min" type="number" onChange={e => setFbPageCountMin(e.target.value)} value={fbPageCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="fb-page-count-max" type="number" onChange={e => setFbPageCountMax(e.target.value)} value={fbPageCountMax} />
                                        </Tooltip>
                                    </span>
                                }
                            </span>
                        }

                         <span>&nbsp;&nbsp;&nbsp;</span>

                        { 
                            ifFollowerCount &&
                            <span className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-twitter" type="checkbox" checked={ifTwitterFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfTwitterFollowerCount)} />
                                    <label for="chk-twitter">Twitter:</label>
                                </div>
                                {
                                    ifTwitterFollowerCount &&
                                    <span className="form-col-wrap">
                                        <Tooltip title="min">
                                            <input id="twitter-count-min" type="number" onChange={e => setTwitterCountMin(e.target.value)} value={twitterCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="twitter-count-max" type="number" onChange={e => setTwitterCountMax(e.target.value)} value={twitterCountMax} />
                                        </Tooltip>
                                    </span>
                                }
                            </span>
                        }

                         <span>&nbsp;&nbsp;&nbsp;</span>

                        { 
                            ifFollowerCount &&
                            <span className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-youtube" type="checkbox" checked={ifYoutubeFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfYoutubeFollowerCount)} />
                                    <label for="chk-youtube">Youtube:</label>
                                </div>
                                {
                                    ifYoutubeFollowerCount &&
                                    <span className="form-col-wrap">
                                        <Tooltip title="min">
                                            <input id="youtube-count-min" type="number" onChange={e => setYoutubeCountMin(e.target.value)} value={youtubeCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="youtube-count-max" type="number" onChange={e => setYoutubeCountMax(e.target.value)} value={youtubeCountMax} />
                                        </Tooltip>
                                    </span>
                                }
                            </span>
                        }

                        <span>&nbsp;&nbsp;&nbsp;</span>

                        { 
                            ifFollowerCount &&
                            <span className="form-wrap">
                                <div className="custom-checkbox-wrap">
                                    <input className="styled-checkbox" id="chk-tiktok" type="checkbox" checked={ifTiktokFollowerCount} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfTiktokFollowerCount)} />
                                    <label for="chk-tiktok">Tiktok:</label>
                                </div>
                                {
                                    ifTiktokFollowerCount &&
                                    <span className="form-col-wrap">
                                        <Tooltip title="min">
                                            <input id="tiktok-count-min" type="number" onChange={e => setTiktokCountMin(e.target.value)} value={tiktokCountMin} />
                                        </Tooltip>
                                        <Tooltip title="max">
                                            <input id="tiktok-count-max" type="number" onChange={e => setTiktokCountMax(e.target.value)} value={tiktokCountMax} />
                                        </Tooltip>
                                    </span>
                                }
                            </span>
                        }
                </div>               

                <div className="form-horizontal-row-with-label ">
                    <div className={cx("form-wrap")}>
                        <div className="custom-checkbox-wrap">
                            <input className="styled-checkbox" id="chk-duedate" type="checkbox" checked={ifDueDate} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfDueDate)} />
                            <label for="chk-duedate">Due Date:</label>
                        </div>
                        <div className={cx("form-row-wrap")}>
                        {
                            ifDueDate &&
                            <React.Fragment>
                                <DatePicker locale={"en"}
                                    showMonthDropdown
                                    showYearDropdown
                                    selected={dueDateStart}
                                    onChange={dueDateStart => onDueDateFilter('start', dueDateStart)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Start Date"
                                    popperPlacement="top-end" />

                                <DatePicker locale={"en"}
                                    showMonthDropdown
                                    showYearDropdown
                                    selected={dueDateEnd}
                                    onChange={dueDateEnd => onDueDateFilter('end', dueDateEnd)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="End Date"
                                    popperPlacement="top-end" />
                            </React.Fragment>
                        }
                        </div>
                    </div>

                    <div className="form-wrap">
                        <div className="custom-checkbox-wrap">
                            <input className="styled-checkbox" id="chk-child-filters" type="checkbox" checked={ifChildFilter} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfChildFilter)} />
                            <label for="chk-child-filters">Child Filters:</label>
                        </div>             
                        <div className={cx("form-row-wrap")}>
                        {
                            ifChildFilter &&              
                            <RangeSelector
                                maxValue={20}
                                minValue={0}
                                timeout={0}
                                value={childAgeRangeFilter}
                                onChange={val => onChildAgeRangeFilter(val)}
                            />
                        }

                        {
                            ifChildFilter &&
                            <span className={cx("space-between-fields")}>
                                <select id="child-gender" name="child_gender" value={childGenderFilter} onChange={e => onChildGenderFilter(e.target.value)}>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </span>                                
                        }   
                        </div>                     
                    </div>
                </div>

                <div className="row4">
                    <Button type="primary" className={cx("btn", "btn-blue")} onClick={() => {setForceUpdate(!forceUpdate); setLoading(true)}} loading={loading} >Fetch</Button>
                    <button type="button" className={cx("btn", "btn-danger")} onClick={e => onUsersDownload()} style={{float: 'right'}}>Download</button>

                    <span style={{float: 'right', margin: '0 5px'}}>
                        <TotalReachPopover dataFetcher={props.actions.genericAction}
                            filtered={filtered}
                            conditions={conditions} />
                    </span>
                </div>                                
            </div>           

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={userList.list}
                pages={Math.ceil(userList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerApprove,
        brandInfluencerRemind,
        usersDownload,
        brandCampaignBriefForInfluencer,
        campaignListFetch,
        genericAction
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
