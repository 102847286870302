import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {Icon} from '../../../Common/Icon';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import Drawer from '../Drawer';
import ProfileElem from '../../../UIElems/Profile';
import Avatar from '../../../UIElems/Avatar';
import { translate } from 'react-i18next';

const Header = (props) => {
    const t = props.t;
    
    return (
        <header className={cx("influencer-header")}>

            <div className={cx("header-top")}>
                <div className={cx("h-left")}>
                    <div className={cx("only-mobile", "hamburger-wrap")}>
                        {/*<Icon name="hamburger-regular-dark-md" />*/}
                        { 
                            typeof window != 'undefined' && (window.location.pathname.indexOf('register') == -1 && window.location.pathname.indexOf('login') == -1) &&
                            <Drawer />
                        }
                    </div>
                    <div className={cx("logo-wrap")}>
                        <a href='/'>
                            <img className={cx('not-mobile', 'logo')} src={'https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/logo.png'}
                                height="44" width="170" alt="logo" />

                            <img className={cx('only-mobile', 'logo')} src={'https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/logo.png'}
                                height="33" width="130" alt="logo" />
                        </a>
                    </div>                
                </div>

                <div className={cx("h-center")}>
                    {/*<nav className={cx("not-mobile")}>
                                            <ul className={cx("flex", "flex-center")}>
                                                <li><a href='/' className={cx("")}>Home</a></li>
                                            </ul>
                                        </nav>*/}
                </div>

                <div className={cx("h-right")}>
                    {
                        props.rdx.influencerReducer.id &&
                        <ProfileElem links={[
                            {handler: () => window.location = '/logout', title: t('user_logout')}
                        ]}>
                            <img src={props.rdx.influencerReducer.image} height="32" width="32" />
                        </ProfileElem>
                    }                    
                </div>
            </div>

            <div className={cx("only-mobile", "menu-wrap")}>
                <nav>
                    <ul className={cx("flex", "flex-center")}>
                        <li><a href='/' className={cx("")}>{t('sidebar_mobile_home')}</a></li>
                    </ul>
                </nav>
            </div>

            <div className={cx("only-mobile", "left-slide-panel")}>

                <ul>
                    <li><a href='/' className={cx("")}>{t('sidebar_mobile_home')}</a></li>
                </ul>

                <div className={cx("download-app-section")}>
                    
                </div>
            </div>
            
        </header>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Header));