import React, { useState, useEffect } from "react";

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

const RangeSelector = ({ value = { min: 0, max: 5}, minValue = 0, maxValue = 10, timeout = 1000, onChange = () => {}, formatLabel }) => {
    const [ range, setRange ] = useState(value);
    const [ timer, setTimer ] = useState();

    const formatLabelCb = (value, type) => {
        if(formatLabel) {
            return formatLabel(value, type);
        }
        return value;
    }

    const onRangeChanged = (val) => {
        setRange(val);
        if(timeout <= 0) {
            onChange(val);
            return;
        }
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                onChange(val);
            }, timeout)
        );
    }

    return (
        <InputRange
            minValue={minValue}
            maxValue={maxValue}
            value={range}
            onChange={val => onRangeChanged(val)}
            formatLabel={formatLabelCb}
        />
    )
}

export default RangeSelector;