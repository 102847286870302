import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import FeedbackCreate from '../../../../../components/Brand/Campaign/Feedback/Create';

const Feedback = (props) => {

    return (
        <div className={cx("campaign-feedback-wrapper")}>
            <h2>Post Campaign Questions</h2>
            <FeedbackCreate campaignId={props.campaignId} />
        </div>        
    );
}

export default Feedback;
