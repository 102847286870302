import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useCampaignDetail, useInfluencerCampaignModeration, useInfluencerCampaignSubmissionStatus, useInfluencerIfCampaignSubmissionRequired } from '../../../../hooks/generic';
import { influencerCampaignSubmission, influencerCampaignFeedbackUpdate, influencerCampaignSubmissionEdit } from '../../../../actions';
import SubmissionForm from '../../../../components/Influencer/Campaign/Submission/Form';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import FeedbackAnswer from '../../../../components/Influencer/Campaign/Feedback/Answer';
import notification from '../../../../components/UIElems/Notification';
import { translate } from 'react-i18next';
import { isValidUrl } from '../../../../utils';
import Loader from '../../../../components/UIElems/Loader';
import q from 'q';

const CampaignSubmission = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const campaignDetail = useCampaignDetail(props.dispatch, props.match.params.id);    
    const [submissionData, setSubmissionData] = useState();
    let feedbackData;

    const ifCampaignSubmissionRequired = useInfluencerIfCampaignSubmissionRequired(() => {}, props.dispatch, props.match.params.id, false);

    const isInfluencerCampaignSubmitted = useInfluencerCampaignSubmissionStatus(
        () => {},
        props.dispatch,
        props.match.params.id,
        props.rdx.influencerReducer.id,
        forceUpdate
    );

    const moderationData = useInfluencerCampaignModeration(
        () => setIsLoading(false),
        props.dispatch,
        props.match.params.id,
        props.rdx.influencerReducer.id,
        forceUpdate
    );

    const sendFeedback = () => {
        return props.actions.influencerCampaignFeedbackUpdate(props.match.params.id, props.rdx.influencerReducer.id, feedbackData.filter(d => d.value.length));
        // .then(() => {
        //     notification.send({device: props.rdx.appReducer.deviceType, message: 'Feedback Sent!', type: 'success'});
        // })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if(!(submissionData || feedbackData)) return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly fill everything!', type: 'error'});
        setIsLoading(true);
        //all requests
        let allRequests = [];
        //submission
        if(submissionData) {
            let finalData = Object.values(submissionData).filter(d => {
                if (d.filter !== 'actionImage' && Array.isArray(d.url)) {
                    return d.url.length > 0;
                } else if (d.filter === 'actionImage') {
                    return d.ifRequired;
                } else {
                    return d.url && d.url > '';
                }
            });
            let influencerId = props.rdx.influencerReducer.id;
            let campaignId = props.match.params.id;
            let actionImageData = 'actionImage' in submissionData && submissionData['actionImage']['data'] ? submissionData['actionImage']['data'] : null;
            //url validation
            let errUrls = getErrorUrls(finalData);
            if(errUrls.length > 0) {
                setIsLoading(false);
                return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly enter valid urls!', type: 'error'});
            }
            //validation
            if(!finalData.length && !actionImageData) {
                setIsLoading(false);
                return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly fill everything!', type: 'error'});
            }

            if (isInfluencerCampaignSubmitted === 5) {
                if (submissionData['actionImage'].ifRequired
                    && ((actionImageData.length === 0 && submissionData['actionImage']['url'].length < campaignDetail[submissionData['actionImage'].numLinks])
                    || (actionImageData.length > 0 && actionImageData.length + submissionData['actionImage']['url'].length < campaignDetail[submissionData['actionImage'].numLinks]))
                ) {
                    setIsLoading(false);
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload all the required images!', type: 'error'});
                }

                allRequests.push(
                    props.actions.influencerCampaignSubmissionEdit({campaignId, influencerId, finalData: JSON.stringify(finalData), ...(actionImageData && actionImageData.length > 0? actionImageData: null)})
                );
            } else {
                //image validation
                if(submissionData['actionImage'].ifRequired && (!actionImageData || (actionImageData && actionImageData.length < campaignDetail[submissionData['actionImage'].numLinks]))) {
                    setIsLoading(false);
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload all the required images!', type: 'error'});
                }
                //
                allRequests.push(
                    props.actions.influencerCampaignSubmission({campaignId, influencerId, finalData: JSON.stringify(finalData), ...(actionImageData && actionImageData.length > 0? actionImageData: null)})
                );
            }
            
        }        
        //feedback
        if(feedbackData) allRequests.push(sendFeedback());
        //all requests
        if(allRequests.length) {
            q.all(allRequests)
            .then(values => {
                setIsLoading(false);
                if (values && values[0] && 'err' in values[0] && !values[0].err) {
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Done!', type: 'success'});
                    setTimeout(() => window.location = `/influencer/campaign/${props.match.params.id}`, 1500);
                } else {
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
                }
            })
            .catch(e => {
                setIsLoading(false);
                notification.send({device: props.rdx.appReducer.deviceType, message: e.message || 'Something went wrong!', type: 'error'})
            });
        }        
    }
    
    function getErrorUrls(data) {
        let errorUrls = [];
        data.forEach(d => {
            if (Array.isArray(d.url)) {
                const url = d.url.filter(url => (!isValidUrl(url) && ['other', 'image'].indexOf(d.type) === -1));
                errorUrls = [...errorUrls, ...url];
            }
        });
        return errorUrls;
    }
    
    return (
        <BaseLayout leftCards={[]}>
            <form onSubmit={onSubmit}>
                <SubmissionForm assignUpdater={setSubmissionData}
                    campaignDetail={campaignDetail}
                    moderationData={moderationData}
                    t={props.t}
                    deviceType={props.rdx.appReducer.deviceType}
                    influencerId={props.rdx.influencerReducer.id}
                    dispatch={props.dispatch}
                    submissionStatus={isInfluencerCampaignSubmitted}
                    setIsLoading={setIsLoading} />

                <FeedbackAnswer assignUpdater={data => feedbackData = data}
                    campaignId={props.match.params.id}
                    t={props.t} />
                
                {
                    ifCampaignSubmissionRequired &&
                    isInfluencerCampaignSubmitted !== 4 &&
                    <div className={cx("button-wrap-center-align")}>
                        <button className={cx("btn", "btn-blue")} type="submit">{props.t('campaign_button_submit')}</button>
                    </div>
                }   
                {
                    ifCampaignSubmissionRequired &&
                    isInfluencerCampaignSubmitted === 4 &&
                    <div className={cx("button-wrap-center-align")}>
                        <button disabled className={cx("btn", "btn-success")} type="submit">{props.t('approval_status_4')}</button>
                    </div>
                }             
            </form>

            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerCampaignSubmission,
        influencerCampaignFeedbackUpdate,
        influencerCampaignSubmissionEdit
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignSubmission));