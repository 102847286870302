import React, { useEffect, useState } from 'react';
import AddProvider from '..';
import { Modal } from 'antd';

const AddProviderModal = ({isAddProviderModalVisible, setIsAddProviderModalVisible, countryOptions, onAddProvider, sendNotification, isEdit = false, provider = null, onUpdateProvider}) => {
    
    const [providerName, setProviderName] = useState();
    const [providerLogo, setProviderLogo] = useState();
    const [providerSubcategories, setProviderSubcategories] = useState([]);
    const [providerCountries, setProviderCountries] = useState();
    const [forceRefresh, setForceRefresh] = useState(false);
    const [dataCleared, setDataCleared] = useState(false);
    const [deletedSubcategories, setDeletedSubcategories] = useState([]);

    useEffect(() => {
        if (isEdit && provider) {
            setProviderName(provider.providerName);
            setProviderLogo(provider.providerLogo);
            setProviderCountries(provider.providerCountries.map(country => country.id));
            setProviderSubcategories(provider.providerSubcategories);
        }
        setForceRefresh(!forceRefresh);
    }, [isEdit, provider]);

    useEffect(() => {
        setForceRefresh(!forceRefresh);
    }, [isAddProviderModalVisible]);

    const onOk = () => {
        if (!providerName || providerName === '')
            return sendNotification('Kindly provide the provider name!', 'error');
        if (!providerLogo)
            return sendNotification('Kindly provide the provider logo!', 'error');
        if (!providerCountries || providerCountries.length === 0)
            return sendNotification('Kindly specify the countries provider is active in!', 'error');
        if (providerSubcategories && providerSubcategories.length && providerSubcategories.filter(sub => sub.subcategoryName === '').length)
            return sendNotification('Kindly do not leave names empty for subcategories!', 'error');
        if (providerSubcategories && providerSubcategories.length && new Set(providerSubcategories.map(sub => sub.subcategoryName)).size !== providerSubcategories.length)
            return sendNotification('Kindly use unique names for subcategories!', 'error');
        if (!isEdit) {
            onAddProvider({
                providerName,
                providerLogo,
                providerSubcategories: providerSubcategories.map(sub => sub.subcategoryName),
                providerCountries
            });
        } else {
            if (deletedSubcategories && deletedSubcategories.length) {
                deletedSubcategories.forEach(sub => sub.subcategoryStatus = 0)
            }
            onUpdateProvider({
                providerId: provider.providerId,
                providerName,
                providerLogo,
                providerSubcategories: JSON.stringify([...providerSubcategories, ...deletedSubcategories]),
                providerCountries
            });
        }
        clearData();
        setIsAddProviderModalVisible(false);
    }

    const onCancel = () => {
        clearData();
        setIsAddProviderModalVisible(false);
    }

    const clearData = () => {
        setProviderCountries();
        setProviderLogo();
        setProviderName('');
        setProviderSubcategories();
        setDeletedSubcategories([]);
        setDataCleared(!dataCleared);
    }

    return (
        <Modal
            title="eVoucher Provider details"
            visible={isAddProviderModalVisible}
            okText={isEdit ? 'Update': 'Add'}
            onOk={onOk}
            onCancel={onCancel}
            maskClosable={false}
        >
            <AddProvider
                countryOptions={countryOptions}
                providerName={providerName}
                setProviderName={setProviderName}
                providerLogo={providerLogo}
                setProviderLogo={setProviderLogo}
                setProviderCountries={setProviderCountries}
                providerSubcategories={providerSubcategories}
                setProviderSubcategories={setProviderSubcategories}
                dataCleared={dataCleared}
                forceRefresh={forceRefresh}
                deletedSubcategories={deletedSubcategories}
                setDeletedSubcategories={setDeletedSubcategories}
                sendNotification={sendNotification}
            />
        </Modal>
    )
}

export default AddProviderModal;