import {combineReducers} from 'redux';

import {appReducer} from './app';
import {influencerReducer} from './influencer';
import {brandReducer} from './brand';
import {campaignFeedReducer} from './campaignFeed';
import {webviewReducer} from './webview';
import { panelReducer } from './panel';

/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

const allReducers = combineReducers({
    appReducer,
    influencerReducer,
    brandReducer,
    campaignFeedReducer,
    webviewReducer,
    panelReducer
});

export default allReducers;
