import React, { Fragment } from 'react';
import cx from 'classnames/bind';

const Footer = () => {
    
    return (
        <footer className={cx("brand-footer")}>
            <div className={cx("footer-container")}></div>    
            <div className={cx("footer-row3")}>
                <div className={cx("bottom-nav-container")}>
                    <div className={("copyright")}>
                        &copy; Copyright theAsianparent 2019. All rights reserved
                    </div>
                    <div className={("right-nav")}>
                           {/*<ul>
                                <li><a href="/">Home</a></li>
                           </ul>*/}
                    </div>
                    <div className={cx("clear-fix")}></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
