import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { genericAction } from '../../../../actions';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import PanelListTable from '../List';

const BrandPanelAll = props => {
    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-home">
                <h1>Jobs: Panels</h1>
                <PanelListTable brandId={props.rdx.brandReducer.brandId}
                    conditions={[]}
                    extra={null} />
            </div>
        </BaseLayout>
    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state})

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandPanelAll);