import React from 'react';
import { useState } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { genericAction } from '../../../../../actions';
import { useStatsPanelJobSubmissions } from '../../../../../hooks/stats';
import Loader from '../../../../../components/UIElems/Loader';
import CardElem from '../../../../../components/UIElems/Card';


const Submissions = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldRefreshChart, setShouldRefreshChart] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    // 
    const data = useStatsPanelJobSubmissions(
        () => {
            setIsLoading(false);
            setTimeout(() => setShouldRefreshChart(!shouldRefreshChart), 1000);
        },
        props.dispatch,
        props.rdx.panelReducer.mobPanelWebviewData,
        forceUpdate
    );
    
    return (
        data
        ?
            <div>
                <div className={cx("stats-cards-blue")} onClick={e => window.open(`/brand/panel/${props.panelId}/submissions`, '_blank')}>
                    <CardElem title="Submission Stats" bordered={false} style={{cursor: 'pointer'}}>
                        {'totalSubmissions' in data && <div>Total Surveys Started: {data['totalSubmissions'] || 0}</div>}
                        {'totalCompletions' in data && <div>Total Eligible Surveys: {data['totalCompletions'] || 0}</div>}
                        {'totalIneligible' in data && <div>Total Ineligible Surveys: {data['totalIneligible'] || 0}</div>}

                        {'totalJobsCompleted' in data && <div>Total Jobs Completed: {data['totalJobsCompleted'] || 0}</div>}                        
                        {'pendingJobs' in data && <div>Pending Jobs: {data['pendingJobs'] || 0}</div>}
                        {'pollResponseLimit' in data && <div>Poll Response Limit: {data['pollResponseLimit'] || 0}</div>}
                        
                    </CardElem>
                </div>
            </div>
        :
            <div className={cx("stats-cards-blue")} onClick={e => window.open(`/brand/panel/${props.panelId}/submissions`, '_blank')}>
            <CardElem title="Submission Stats" bordered={false} style={{cursor: 'pointer'}}>
                No data to display
            </CardElem>
                { isLoading && <Loader /> }
            </div>
    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state});

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);