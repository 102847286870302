import React, { Fragment } from "react";
import generateAssetCdnUrl from "../../../utils/helpers/generateAssetCdnUrl";

const CreditSection = (props) => {
    const t = props.t;
    return (
        <div className="credit-section">
            <div className="background-image-container">
                <div className="grid-container">
                    <div className="reward-container">
                        <div>
                            <img
                                className="reward-star"
                                src={generateAssetCdnUrl("tapfluencer", "profile_reward_star.png")}
                            />
                        </div>
                        <div className="credit-point">
                            {
                                props.loaded &&
                                <b>{props.creditBalance || 0}</b>
                            }
                        </div>
                    </div>
                    <div>
                        {t('vip_credit_balance')}
                    </div>
                </div>
            </div>
            <div className="container-secondary  ">
                <div className="grid-container">
                    <div className="heading">
                        <p dangerouslySetInnerHTML={{ __html: t('vip_credit_claim') }} ></p>
                    </div>
                    <div className="container-main ">
                        <div className="app-container">
                            <div>
                                <a href="https://theasianparent.onelink.me/N2Rf/viplogin">
                                    <img
                                        className="playstore-banner"
                                        src="https://tapfluencer.s3.ap-southeast-1.amazonaws.com/playstore_banner.png"
                                    />
                                </a>
                            </div>
                            <div>
                                <a href="https://theasianparent.onelink.me/N2Rf/viplogin">
                                    <img
                                        className="appstore-banner"
                                        src="https://tapfluencer.s3.ap-southeast-1.amazonaws.com/appstore_banner.png"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="qr-container">
                            <img
                                className="qrcode "
                                src={generateAssetCdnUrl("tapfluencer", "viplogin-qr-code.png")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditSection;
