import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerCampaignActions, useInfluencerCampaignModeration } from '../../../../hooks/generic';
import { genericAction, influencerCampaignModerationUpdate } from '../../../../actions';
import ModerationCreateComponent from '../../../../components/Influencer/Campaign/Moderation/Create';
import ModerationPostFeedbackComponent from './Feedback';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';
import Loader from '../../../../components/UIElems/Loader';
import Tabs from '../../../../components/UIElems/Tabs';

const Moderation = (props) => {
    const t = props.t;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const actionData = useInfluencerCampaignActions(
        () => setIsLoading(false),
        props.dispatch,
        props.match.params.id,
        props.rdx.influencerReducer.id,
        forceUpdate
    );

    const moderationData = useInfluencerCampaignModeration(
        () => setIsLoading(false),
        props.dispatch,
        props.match.params.id,
        props.rdx.influencerReducer.id,
        forceUpdate
    );

    const onSubmit = (data) => {
        setIsLoading(true);
        props.actions.influencerCampaignModerationUpdate(data)
        .then(() => {
            setForceUpdate(!forceUpdate);
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
        })
        .catch(() => setIsLoading(false))
    }

    const notify = (message, type) => {
        notification.send({device: props.rdx.appReducer.deviceType, message: message, type: type});
    }

    const POST_TYPES = {
        fb: 'Facebook',
        fbStory: 'Facebook Story',
        insta: 'Instagram',
        instaStory: 'Instagram Story',
        youtube: 'Youtube',
        twitter: 'Twitter'
    };

    const PostTab = () => {
        return (
            <div className={cx("moderation-wrapper")}>
                {
                    moderationData
                    .map((d, i) => {
                        return (
                            <div className={cx("moderation-card")} key={i}>
                                <h2>{d.type in POST_TYPES ? POST_TYPES[d.type] : d.type}</h2>
                                <Tabs tabsHandler={() => {}}
                                    defaultActiveKey={1}
                                    elems={[
                                        {
                                            name: t('mob_moderation_post'),
                                            elem: <ModerationCreateComponent campaignId={props.match.params.id}
                                                    influencerId={props.rdx.influencerReducer.id}
                                                    postType={d.type}
                                                    mode="edit"
                                                    defaultValues={d}
                                                    onSubmit={onSubmit}
                                                    notify={notify}
                                                    t={props.t} />
                                        },
                                        {
                                            name: t('mob_moderation_feedback'),
                                            elem: <ModerationPostFeedbackComponent postId={d.id} />
                                        }
                                    ]} />                            
                            </div>
                        )
                    })                    
                }
                {
                    actionData.pendingFields
                    .filter(f => ['other', 'image', 'blog', 'event'].indexOf(f.name) == -1)
                    .map((field, i) => {
                        return (
                            <div className={cx("moderation-card")} key={i}>
                                <h2>{field.name in POST_TYPES ? POST_TYPES[field.name] : field.name}</h2>
                                <Tabs tabsHandler={() => {}}
                                    defaultActiveKey={1}
                                    elems={[
                                        {
                                            name: t('mob_moderation_post'),
                                            elem: <ModerationCreateComponent campaignId={props.match.params.id}
                                                    influencerId={props.rdx.influencerReducer.id}
                                                    postType={field.name}
                                                    mode="create"
                                                    onSubmit={onSubmit}
                                                    notify={notify} 
                                                    t={props.t} />
                                        },
                                        {
                                            name: t('mob_moderation_feedback'),
                                            elem: <ModerationPostFeedbackComponent postId={null} />
                                        }
                                    ]} />                            
                            </div>
                        )
                    })                    
                }
            </div>
        );
    }
    
    return (
        <BaseLayout leftCards={[]}>
            <h2>{t("mob_moderation_create_post")}</h2>
            <h3>{t("mob_moderation_before_you_create_a_post")}</h3>
            <PostTab />
            { isLoading && <Loader /> }            
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
        influencerCampaignModerationUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Moderation));