import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { APIPanelDetailFetch } from '../../../../actions';
import { useBankList, useInfluencerPaymentDetails } from '../../../../hooks/generic';
import MobPanelDetailComponent from '../../../../components/Mob/Panel/Detail';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';
import {
  useLocation
} from "react-router-dom";


const PanelDetail = (props) => {
	const [forceUpdate, setForceUpdate] = useState(false);

    const onCopy = () => {
        notification.send({device: props.rdx.appReducer.deviceType, message: `${props.t("mob_panel_detail_copied")}`, type: 'success'});
    }

    return (
        <MobPanelDetailComponent 
            t={props.t}
            onCopy={onCopy}
            deviceType={props.rdx.appReducer.deviceType}
            data={props.rdx.panelReducer.mobPanelWebviewData}
        />
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        APIPanelDetailFetch
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PanelDetail));