import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import { Affix, Button } from 'antd';
import {Icon} from '../../Common/Icon';
import Carousel from '../../UIElems/Carousel';
import { gtmSend } from '../../../utils';

const Welcome = ({t, welcomeFaqUrl, welcomeFeedbackUrl}) => {
    const homeScrollRef = useRef(null);
    const benefitsScrollRef = useRef(null);
    const platformScrollRef = useRef(null);
    const requirementScrollRef = useRef(null);

    const smoothScroll = (e, refElem) => {
        e.preventDefault();
        refElem.current.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className={cx("mob-welcome-landing-page-wrapper")}>
            <div className={cx("mob-navigation")}>
                <ul>
                    <li>
                        <a href="#Home" className={cx("active")} onClick={(e) => {smoothScroll(e, homeScrollRef); gtmSend('dynamic','menu','click');}}>
                            {t("mob_onboarding_home")}
                        </a>
                    </li>
                    <li>
                        <a href="#Benefits" onClick={(e) => {smoothScroll(e, benefitsScrollRef); gtmSend('dynamic','benefits','click');}}>
                            {t("mob_onboarding_benefits")}
                        </a>
                    </li>
                    <li>
                        <a href={welcomeFaqUrl} onClick={() => {gtmSend('dynamic','faqs','click'); }}>
                            {t("mob_welcome_faq")}
                        </a>
                    </li>
                    <li>
                        <a href={welcomeFeedbackUrl} onClick={() => {gtmSend('dynamic','feedback','click'); }}>
                            {t("mob_feedback_feedback")}
                        </a>
                    </li>
                </ul>
            </div>
            <div className={cx("section-block", "cd-padding-top70")} id="Home" ref={homeScrollRef}>
                <div className={cx("campaign-sub-title", "text-center", "page-spacing")}>
                    <h1>
                        {t("webview_welcome_to_the_largest_parenting_community_in_Southeast_Asia")}
                    </h1>

                    <p>
                        {t("webview_welcome_our_mission_is_to_help_parents_earn_a_livelihood_by_promoting_brands")}                    
                    </p>
                </div>

                <div className={cx("welcome-banner")}>
                {
                    <div className={cx("welcome-img")}>
                        <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/welcome_vip_parent.png" alt="VIParents welcome image" />
                    </div>
                }
                </div>
            </div>

            <div className={cx("section-block", "page-spacing", "margin-top-20")}>
                <div className={cx("campaign-sub-title", "text-center")}>
                    <h1>
                       {t("mob_onboarding_brands_we_worked_with")}
                    </h1>
                </div>
            </div>

             <div className={cx("section-block", "campaign-brands-carousel", "cd-margin-top-bottom26", "page-spacing")}>
                <Carousel autoplay={true}
                    elems={[
                        <div className={cx("brands-img-container")}>
                            <img src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_arla_logo.png'} alt="img1"/>
                            <img className={cx("brand-philips")} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_philips_logo.png'} alt="img2"/>
                            <img src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_kodomo_challenge_logo_copy_2.png'} alt="img3"/>
                        </div>,
                        <div className={cx("brands-img-container")} id="Benefits" ref={benefitsScrollRef}>
                            <img src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_arla_logo.png'} alt="img1"/>
                            <img className={cx("brand-philips")} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_philips_logo.png'} alt="img2"/>
                            <img src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/slider_kodomo_challenge_logo_copy_2.png'} alt="img3"/>
                        </div>
                    ]}
                />
            </div>

            <div className={cx("section-block", "page-spacing")}>
                <div className={cx("campaign-sub-title", "text-center", "benefits-of-bcom-vip")}>
                    <h1>
                       {t("mob_onboarding_benefits_for_a_vip_mum")}
                    </h1>
                </div>

                <div className={cx("Benefits-wrapper")}>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_1.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_earn_extra_income_from_home_or_remotely")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_2.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_get_free_products_from_brands")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_3.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_receive_exclusive_event_invitations")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_4.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_learn_new_skills_through_our_workshops")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_5.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_participate_in_charity_programs")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_6.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_enjoy_discounts_and_special_giveaways")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_7.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_participate_in_branded_surveys_and_polls")}
                        </div>
                    </div>
                    <div className={cx("icons-wrapper")}>
                        <div className={cx("text-center")}>
                            <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/web-view/welcome/benefit_8.png" alt="" />
                        </div>
                        <div className={cx("icon-title", "text-center")}>
                            {t("webview_welcome_be_invited_to_our_online_groups")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;