import React from 'react';
import TabbedFAQ from '../../Common/TabbedFAQ';

const About = ({t, country}) => {
    const onTabChange = (activeKey) => {}

    return (
        <div className={"about-wrap"}>
            <TabbedFAQ
                activeKey={0}
                animated={{inkBar: true, tabPane: false}}
                t={t}
            />
        </div>
    )
}

export default About;