/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
//initial state wi;l be empty after adding login
function IS() {
    return {
        brandId: null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        adminType: null,
        company: "",
        address: "",
        isLoading: false,
        currentCampaign: null,
        currentFilter: null,
        currentPanel: null,
        list: []
    }
}

const INITIAL_STATE = IS();

export var brandReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();
    //    
    switch (action.type) {
        case 'START_BRAND_AUTHENTICATION':
            return {
                ...state,
                isLoading: true
            }
        break;
        case 'COMPLETE_BRAND_AUTHENTICATION':
            return {
                ...state,
                ...action.payload,
                isLoading: false
            }
        break;
        case 'COMPLETE_CAMPAIGN_SAVE':
            if(!state.brandId) return state;
            return {
                ...state,
                currentCampaign: action.payload.data,
                isLoading: false
            }
        break;
        case 'COMPLETE_PANEL_SAVE':
            if(!state.brandId) return state;
            return {
                ...state,
                currentPanel: action.payload.data,
                isLoading: false
            }
        break;
        case 'COMPLETE_BRAND_FILTER_SAVE':
            return {
                ...state,
                currentFilter: action.payload.data ? action.payload.data : null,
                isLoading: false
            }
        break;
        case 'COMPLETE_JOB_LIST_FETCH':
            return {
                ...state,
                page: action.payload.page,
                list: [...(action.payload.shouldReset ? action.payload.data.list : [...state.list, ...action.payload.data.list])],
                total: action.payload.data.total
            };
        break;
        default:
            return state;
    }
};