import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useAllAdmins } from '../../../../hooks/generic';
import { brandAdminUpdate } from '../../../../actions';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import notification from '../../../../components/UIElems/Notification';

const AdminTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const adminList = useAllAdmins(() => setLoading(false), props.dispatch, page, pageSize, sorted, filtered, [], forceUpdate);
    const teamMap = {
        'vip_influencer': 'VIP Influencer',
        'vip_panel': 'VIP Panel'
    }

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        // if(state.filtered.length && state.filtered[0]['value'].length < 2) return false;
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const onAdminUpdate = (adminId, flag = '', is_active = '') => {
        props.actions.brandAdminUpdate(adminId, flag, is_active)
        .then(() => {
            notification.send({device: 'desktop', message: 'Done!', type: 'success'});
            setForceUpdate(!forceUpdate);
        });
    }

    const createActionLink = (rowData) => {
        let links = [];
        //admin type
        //super admin
        if(rowData.adminType == 1) {
            links.push({title: 'No Action Available', handler: () => {}});
        }
        //admin
        if(rowData.adminType == 2) {
            links.push({title: 'Mark As Sales', handler: () => onAdminUpdate(rowData.adminId, 3)});
        }
        // admin
        if(rowData.adminType == 3) {
            links.push({title: 'Mark As Admin', handler: () => onAdminUpdate(rowData.adminId, 2)});
        }  
        
        if(rowData.isActive) {
            links.push({title: 'De-Activate', handler: () => onAdminUpdate(rowData.adminId, '' ,0)});
        }
        else {
            links.push({title: 'Activate', handler: () => onAdminUpdate(rowData.adminId, '' ,1)});
        }

        return <MenuPopper title='Manage' links={links} />
    }

    const adminType = (type) => {
        let typeString = "";        
        switch(type) {
            case 1:
            typeString = 'Super Admin';
            break;
            case 2:
            typeString = 'Admin';
            break;
            case 3:
            typeString = 'Sales';
            break;
        }

        return typeString;
    }

    const statusRender = isActive => {
        const status = {
            0: 'Inactive',
            1: 'Active'
        }
        const color = {
            0: ["status-bg-red", "status-text-red", "status-style"],
            1: ["status-bg-green", "status-text-green", "status-style"],
        }

        return <span className={cx(color[isActive])}>{status[isActive]}</span>;
    }

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            filterable: false,
            sortable: false
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: false,
            sortable: false
        },
        {
            Header: "Country",
            accessor: "countryName",
            filterable: false,
            sortable: false
        },
        {
            Header: "Team",
            accessor: "team",
            filterable: false,
            sortable: false,
            Cell: props => <span>{teamMap[props.value] || ''}</span>
        },
        {
            Header: "Role",
            accessor: "adminType",
            filterable: false,
            sortable: false,
            Cell: props => adminType(props.value)
        },
        {
            Header: "Status",
            accessor: "isActive",
            filterable: false,
            sortable: false,
            Cell: props => statusRender(props.value)
        },
        {
            Header: "Action",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>

            <div className="filters">
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                manual
                data={adminList.list}
                pages={Math.ceil(adminList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandAdminUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
