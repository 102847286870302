import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Profile = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (d) => {
        if(d.handler) d.handler();
        setAnchorEl(null);
    }

  return (
        <div>
            <div
                aria-owns={anchorEl ? 'profile' : undefined}
                aria-haspopup="true"
                onClick={handleClick} className="influencer-profile-avatar">
                {props.children}
            </div>
            <Menu id="profile" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {
                    props.links.map((d, i) => {
                        return <MenuItem key={i} onClick={() => handleClose(d)}>{d.title}</MenuItem>
                    })
                }                
            </Menu>
        </div>
    );
}

export default Profile;