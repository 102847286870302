import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandAdminPasswordForget, brandAdminPasswordReset } from '../../../../../actions';
import qs from 'qs';
import Loader from '../../../../../components/UIElems/Loader';
import { decipherData, verifyJwtToken } from '../../../../../utils';
import notification from '../../../../../components/UIElems/Notification';

const Reset = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [isValid, setIsValid] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(null);

    const onPasswordChange = (e, callback) => {
        const check = e.target.name === 'confirm-password' ? password : confirmPassword;
        (check && check !== '' && e.target.value && e.target.value !== '' && check === e.target.value) ? setIsValid(true) : setIsValid(false);
        callback(e.target.value);
    }

    const onSubmit = e => {
        e.preventDefault();
        if (isValid) {
            setIsLoading(true);
            props.actions.brandAdminPasswordReset(email, password)
            .then(results => {
                if (!results.err) {
                    window.location = '/brand/home';
                } else {
                    setIsLoading(false);
                    notification.send({device: props.rdx.appReducer.deviceType, message: 'Some error occurred!', type: 'error'});
                }
            });
        } else {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Passwords don\'t match', type: 'error'});
        }
    }

    useEffect(() => {
        const code = decodeURIComponent(qs.parse(props.location.search, { ignoreQueryPrefix: true }).code);
        if (code) {
            try {
                const token = decipherData(code);
                const payload = verifyJwtToken(token);
                setEmail(payload.email);
                setIsTokenValid(true);
            } catch (err) {
                setIsTokenValid(false);
            }
        } else {
            setIsTokenValid(false);
        }
    }, [])

    return (
        <Fragment>
            <div className={cx("Login")}>
                <div class="split left">
                    <div className={cx("brand-login")}>
                        <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/tap-logo-vip.svg" alt="Logo" />
                        {
                            isTokenValid &&
                            <form onSubmit={onSubmit}>
                                <div className={cx("form-group")}>
                                    <label htmlFor="password">New Password: </label>
                                    <input id="password" type="password" name="password" className={cx(`${isValid ? '' : 'border-red'}`)} value={password} onChange={e => onPasswordChange(e, setPassword)} />
                                </div>
                                <div className={cx("form-group")}>
                                    <label htmlFor="confirm-password">Confirm New Password: </label>
                                    <input id="confirm-password" type="password" name="confirm-password" className={cx(`${isValid ? '' : 'border-red'}`)} value={confirmPassword} onChange={e => onPasswordChange(e, setConfirmPassword)} />
                                </div>
                                <div className={cx("button-wrap")}>
                                    <button className={cx("btn", "btn-blue")} type="submit">Reset Password</button>
                                </div>
                            </form>
                        }
                        {
                            (isTokenValid !== null || isTokenValid !== undefined) && !isTokenValid &&
                            <div className={cx("form-group")}>
                                <label>Incorrect Token</label>
                            </div>
                        }
                    </div>
                </div>

                <div class="split right">
                    <div class="centered">
                    </div>
                </div>
            </div>

            { isLoading && <Loader />}
        </Fragment>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandAdminPasswordForget,
        brandAdminPasswordReset
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
