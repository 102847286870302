import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';

const Empty = ({t}) => {

    return (
        <div className={cx("campaign-empty-container")}>
            <div className={cx("c-header-img")}>
            	<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/og_bg_head.png" alt=""/>
            </div>
            <div className={cx("c-coming-soon")}>
            	<h1>
            		{t("no_campaign_our_Campaigns_are_COMING_SOON_Stay_tuned")}
            	</h1>
            	<p>
            		{t("no_campaign_we_will_notify_you_via_email_or_push_notification_when_there_is_a_new_campaign")}
            	</p>
            </div>
            <div className={cx("c-about-parent")}>
            	<div className={cx("c-header-img")}>
            		<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/c-about-parent-logo.png" alt=""/>
            	</div>
            	<div className={cx("c-block")}>
	            	<h2>{t("no_campaign_about_VIP_Parents")}</h2>

	            	<div className={cx("c-about-img")}>
	            		<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/mom.png" alt=""/>
	            	</div>

	            	<h3>
	            		{t("no_campaign_for_our_mummies")}
	            	</h3>

	            	<p>
	            		{t("no_campaign_many_women_in_Southeast_Asia_like_to_learn_and_do_more_but_do_not_have_the_avenue")}
	            	</p>

	            	<p>
	            		{t("no_campaign_we_started_VIP_Parents_to_empower_women_like_you_to")}
	            	</p>
            	</div>

            	<div className={cx("c-about-types")}>
            		<div>
            			<span>
            				<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-03.svg" alt=""/>
            			</span>
            			<p>
            				{t("no_campaign_earn_extra_income_whilst_staying_at_home")}
            			</p>
            		</div>
            		<div>
            			<span>
            				<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-02.svg" alt=""/>
            			</span>
            			<p>
            				{t("no_campaign_be_educate_through_our_trainings")}
            			</p>
            		</div>
            		<div>
            			<span>
            				<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-01.svg" alt=""/>
            			</span>
            			<p>
            				{t("no_campaign_give_back_through_our_community")}
            			</p>
            		</div>
            	</div>

            	<div className={cx("c-block")}>

	            	<h3>
	            		{t("no_campaign_for_our_daddies")}
	            	</h3>

            		<div className={cx("c-about-img")}>
	            		<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/dadies.png" alt=""/>
	            	</div>

	            	<p>
	            		{t("no_campaign_about_97%_of_dads_now_know_how_to_change_a_diaper")} 
	            	</p>

	            	<p>
	            		{t("no_campaign_we_started_VIP_Parents_to_empower_our_daddies_to")}
	            	</p>

	            	<div className={cx("c-about-types", "c-about-types-imp")}>
	            		<div>
	            			<span>
	            				<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-03.svg" alt=""/>
	            			</span>
	            			<p>
	            				{t("no_campaign_earn_extra_income_doing_projects_you_like")}
	            			</p>
	            		</div>
	            		<div>
	            			<span>
	            				<img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-04.svg" alt=""/>
	            			</span>
	            			<p>
	            				{t("no_campaign_empower_parents_by_sharing_advice")}
	            			</p>
	            		</div>
	            	</div>

            	</div>
            </div>
            <div className={cx("c-past-campaign")}>
                  <div className={cx("c-header-img")}>
                        <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/VIP_parent_icon-05.svg" alt=""/>
                  </div>
                  <div className={cx("c-block")}>
                        <h2>{t("no_campaign_past_campaigns")}</h2>

                        {
                              // <div className={cx("c-campaign-img")}>
                              //       <div>
                              //             <span>Johnson & Johnson Thailand</span>
                              //             <span>
                              //                   <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/j%26j1.png" alt=""/>
                              //             </span>
                              //       </div>
                              //       <div>
                              //             <span> Johnson-Johnson </span>
                              //             <span>
                              //                   <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/j%26j2.png" alt=""/>
                              //             </span>
                              //       </div>
                              // </div>
                        }

                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/1000px_image_0004_jnj.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_e_commerce_activation")}:</strong> <br/>
                                                {t("no_campaign_increase_sales_through_words_of_mouths")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_all_country")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_prompt_our_parents_to_purchase_Photobook_products_from_their_apps_by_giving_them_vouchers")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                {t("no_campaign_hundreds_of_engagement_from_likes_shares_and_comments_were_generated")}
                                                {t("no_campaign_hundreds_of_online_sales_recorded_through_the_code")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/1000px_image_0003_hpb.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_objective")}:</strong> <br/>
                                                {t("no_campaign_education_through_mums_genuine_testimonials")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_singapore")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_get_our_mums_to_share_their_real_life_experiences_and_advises")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                {t("no_campaign_hundreds_of_engagements_from_likes")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/1000px_image_0000_Friso.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_objective")}:</strong> <br/>
                                                {t("no_campaign_invite_parents_to_attend_and_amplify_brand_event")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_singapore")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_invited_our_VIP_Parents_to_come_to_Friso_events")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                {t("no_campaign_the_selected_20_VIP_Parents_attended_the_events")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/1000px_image_0002_mamac.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_objective")}:</strong> <br/>
                                                {t("no_campaign_increase_sales_through_distribution_of_vouchers")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_indonesia")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_invited_a_pool_of_VIP_Parents_to_distribute_vouchers")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                • {t("no_campaign_248_submissions")} <br/>
                                                • {t("no_campaign_total_Instagram_followers_of_all_VIP_parents_accounts_to_422240_users")}<br/>
                                                • {t("no_campaign_direct_purchases_from_the_shares_recorded")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/1000px_image_0005_kithcenlab.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_objective")}:</strong> <br/>
                                                {t("no_campaign_raise_awareness_through_custom_event")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_malaysia")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_hold_a_bloggers_gathering_with_our_VIP_Mums_bloggers_and_their_children")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                {t("no_campaign_8_VIP_Mums_bloggers_attended_the_event_with_their_children")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                        <div className={cx("c-campaign-list-section")}>
                              <div className={cx("list-img")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/j%26j1.png" alt=""/>
                              </div>
                              <div className={cx("list-item-wrap")}>
                                    <ul>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_objective")}:</strong> <br/>
                                                Raise awareness through social post
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_geo")}:</strong> <br/>
                                                {t("no_campaign_philippines")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_strategy")}:</strong> <br/>
                                                {t("no_campaign_as_part_of_their_360_campaign_Nankid_booked_20_VIP_Parents_to_share_their")}
                                                </p>
                                          </li>
                                          <li>
                                                <span>
                                                      <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/mobile/no_campaign/icon-tick.png" alt=""/>
                                                </span>
                                                <p>
                                                <strong>{t("no_campaign_results")}:</strong> <br/>
                                                {t("no_campaign_hundreds_of_engagements_from_likes_comments")}
                                                </p>
                                          </li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </div>
        </div>
    );
}

export default Empty;