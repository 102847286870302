import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames/bind';
import { gtmSend } from '../../../../utils';
import generateAssetCdnUrl from "../../../../utils/helpers/generateAssetCdnUrl";

import { createImagePreview } from '../../../../utils';
import { Icon, Popover } from 'antd';

const Feedback = ({t, welcomeUrl, welcomeFaqUrl, tabIndex, country, lang, onSubmit}) => {

    const [title, setTitle] = useState('');
    const [contactEmail, setEmail] = useState('');
    const [jobCategory, setJobCategory] = useState('');
    const [feedback, setFeedback] = useState('');
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
        createImagePreview(e.target.files[0], previewImage => setImagePreview(previewImage));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            title,
            contactEmail,
            jobCategory,
            feedback,
            image
        })

        
        clearInputField();        

    }

    // setting field value empty
    const clearInputField = () => {
        setTitle("");
        setEmail("");
        setJobCategory("");
        setFeedback("");
        setImagePreview("");
    }

    return (
        <div className={cx("welcome-feedback-wrapper")}>

            <div className={cx("mob-navigation")}>
                <ul>
                    <li>
                        <a href={welcomeUrl} onClick={() => {gtmSend('dynamic','menu','click'); }}>
                            {t("mob_onboarding_home")}
                        </a>
                    </li>
                    <li>
                        <a href={`${welcomeUrl}#Benefits`} onClick={() => {gtmSend('dynamic','benefits','click'); }}>
                            {t("mob_onboarding_benefits")}
                        </a>
                    </li>
                    <li>
                        <a href={welcomeFaqUrl} onClick={() => {gtmSend('dynamic','faqs','click'); }}>
                            {t("mob_welcome_faq")}
                        </a>
                    </li>
                    <li>
                        <a href="#" className={cx("active")} onClick={() => {gtmSend('dynamic','feedback','click'); }}>
                            {t("mob_feedback_feedback")}
                        </a>
                    </li>
                </ul>
            </div>

            <form onSubmit={onFormSubmit}>
                <div className={cx("page-spacing")}>
                    <div className={cx("feedback-form")}>

                        {
                            // <div className={cx("form-group")}>
                            //     <div className={cx("field-icon")}>
                            //         <img src=" https://s3.theasianparent.com/tapfluencer/assets/web-view/feedback/feedback-full-name-icon.png" alt="" /> 
                            //     </div>
                            //     <div className={cx("field-wrap")}>
                            //         <label>{t("mob_feedback_full_name")}</label>
                            //         <div className={cx("field")}>
                            //             <input type="text" />
                            //         </div>
                            //     </div>
                            // </div>
                        }

                        <div className={cx("form-group")}>
                            <div className={cx("field-icon")}>
                                <img src={generateAssetCdnUrl("tapfluencer", "assets/web-view/feedback/feedback-email-icon.png")} alt="" /> 
                            </div>
                            <div className={cx("field-wrap")}>
                                <label>{t("mob_feedback_contact_email")}</label>
                                <div className={cx("field")}>
                                    <input required type="email" value={contactEmail} onChange={e => setEmail(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className={cx("form-group")}>
                            <div className={cx("field-icon")}>
                                <img src={generateAssetCdnUrl("tapfluencer", "assets/web-view/feedback/feedback-job-icon.png")} alt="" /> 
                            </div>
                            <div className={cx("field-wrap")}>
                                <label>{t("mob_feedback_mention_job_category")}</label>
                                <div className={cx("field")}>
                                    <select name="" id="" value={jobCategory} onChange={e => setJobCategory(e.target.value)} required>
                                        <option value="">{t("mob_feedback_mention_job_category")}</option>
                                        <option value={t("mob_feedback_vip_Parent_Influencer")}>{t("mob_feedback_vip_Parent_Influencer")}</option>
                                        <option value={t("mob_feedback_vip_Parent_Panel")}>{t("mob_feedback_vip_Parent_Panel")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                         <div className={cx("form-group")}>
                            <div className={cx("field-icon")}>
                                <img src={generateAssetCdnUrl("tapfluencer", "assets/web-view/feedback/feedback-form-icon.png")} alt="" /> 
                            </div>
                            <div className={cx("field-wrap")}>
                                <label>{t("mob_feedback_title_for_your_concern")}</label>
                                <div className={cx("field")}>
                                    <input required type="text" value={title} onChange={e => setTitle(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className={cx("form-group")}>
                            <div className={cx("field-icon")}>
                                <img src={generateAssetCdnUrl("tapfluencer", "assets/web-view/feedback/feedback-form-icon.png")} alt="" /> 
                            </div>
                            <div className={cx("field-wrap")}>
                                <label>{t("mob_feedback_describe_you_concern")}</label>
                                <div className={cx("field")}>
                                    <textarea required value={feedback} onChange={e => setFeedback(e.target.value)} cols="20" rows="2" />
                                </div>
                            </div>
                        </div>

                        <div className={cx("form-group")}>
                            <div className={cx("file-upload-wrapper")}>
                                 <label className={cx("upload-a-ss")}>
                                       <div className={cx("upload-stuff")}>
                                            <span><img src={generateAssetCdnUrl("tapfluencer", "assets/web-view/feedback/feedback-file-upload-icon.png")} alt="" /></span>
                                            <span>{t("mob_feedback_upload_a_screenshot")}</span>
                                       </div>
                                       <div className={cx("file-format")}>(accepted format jpg, png, tiff)</div>
                                        <input id="image" type="file" className={cx("choose-file")} onChange={onImageChange} />
                                   </label>
                            </div>
                        </div>
                        <div className={cx("image-preview")}>
                            { imagePreview && <img src={imagePreview} alt="image-preview" /> }
                        </div>

                        <div className={cx("form-group")}>
                            <button type="submit">{t("mob_feedback_submit")}</button>
                        </div>

                    </div>
                </div>
            </form>

        </div>
    );
}


export default Feedback;