import React from 'react';

const ScreeningTags = (props) => {
    return (<div>
        {props.reqAns[1]} <label onClick={() => {
            props.removeTag(props.reqAns[0]);
        }}>&#10005;</label>
    </div>);
};

export default ScreeningTags;