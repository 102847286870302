import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';

const Feedback = () => {

    return (
        <div>
            <div className={cx("toggle-navigation")}>
                <div onClick={() => window.location = '/brand/influencer/feedback'} className={cx("nav-heading")}>
                    <h3>
                        <span className={cx("sidebar-icon-spacing", "brand-user-icon")}></span>
                        <span className={cx("navigation-title")}>Feedback</span>
                    </h3>
                </div>
            </div>
       </div>
    );
}

export default Feedback;