import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CreateForm from '../../../../components/Brand/Campaign/Form';
import { brandCampaignSave } from '../../../../actions';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';
import { translate } from 'react-i18next';

const Create = (props) => {
    const [isLoading, setIsLoading] = useState(false);        

    const onSubmit = (data) => {
        if(data.screeningQuestions && data.screeningQuestions.questions) {
            for (let i = 0; i < data.screeningQuestions.questions.length; i++) {
                const q = data.screeningQuestions.questions[i];
                if (q.status === 1 && (q.type === "dropdown" || q.type === "checkbox") && q.options.length === 0) {                    
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Multi/single choice questions need to have atleast 1 option', type: 'error'});
                }
            }    
        }

        data.screeningQuestions = JSON.stringify(data.screeningQuestions);
        //validations
        //logo
        if(!data.logoPreview)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload the logo!', type: 'error'});
        //product image
        if(!data.productImagePreview)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload the product image!', type: 'error'});
        //actionable items
        if(!data.actionOther && !data.blog && !data.attendEvent && !data.igPost && !data.fbPost && !data.instaStory && !data.fbStory && !data.socialMediaPost && !data.actionImage && !data.tiktokPost)
            return notification.send({device: props.rdx.appReducer.deviceType, message: "You need to specify atleast one of the 'Job Details' fields!", type: 'error'});
        //payment value
        if(data.paymentType !== 'other' && !data.paymentValue)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Payment value cant be 0!', type: 'error'});
        //currency type
        if(['monetary', 'vipcredits'].includes(data.paymentType) && data.currencyType.length == 0)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Enter a currency type!', type: 'error'});
        //else
        setIsLoading(true);
        props.actions.brandCampaignSave(data)
        .then(res => {
            setIsLoading(false);
            if(!res.err) window.location = `/brand/campaign/${res.insertId}/filter`;
        });        
    }

    return (
        <BaseLayout leftCards={[]}>
            <div className="campaign-create">
                <h1>Create Campaign</h1>
                <CreateForm onSubmit={onSubmit}
                    values={{}}
                    type="create"
                    setLoading={setIsLoading}
                    sendNotification={(message, type) => notification.send({device: props.rdx.appReducer.deviceType, message, type})}
                    t={props.t} />     
            </div>

            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignSave
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Create));
