/*paths*/
export default {
    'influencerHome': '^/$',
    '404': '/404',
    'login': '/login', //migrated to vip_signup_web
    'logout': '/logout',
    'socialAuth': '/social/auth/:type',
    'authenticateRequest': '/authenticate-request',
    'socialAuthPreview': '/social/auth/preview/:type',
    'socialToken': '/social/token/:type',
    'appSocialToken': '/app/social/token/:type',
    'countryList.post': '/country/list',
    'statesList.post': '/states/list',
    'citiesList.post': '/cities/list',
    'statesCitiesList.post': '/states/cities/list',
    'campaignList.post': '/campaign/list',
    'campaignDetail.post': '/campaign/detail',
    'campaignFeedbackQuestions.post': '/campaign/feedback/questions',
    'workerDaemonCall.post': '/worker/call',
    'genericAction.post': '/generic/action',
    'triggerNotification.post': '/notification/trigger',
    //------ api ------------------------
    'apiTiktokUserInfo.get': '/api/tiktok/user-info',
    'apiTiktokCallback.get': '/api/tiktok/callback',
    'apiTiktokMobCallback.get': '/api/tiktok/mob/callback',
    'apiTiktokCallback.post': '/api/tiktok/callback',
    'apiApprovalStatus.get': '/api/approval/status',
    'apiApprovalStatusV2.get': '/api/v2/approval/status',
    'apiApprovalStatusV3.get': '/api/v3/approval/status',
    'apiCurrencyCreditMapping.get': '/api/currency-credit-mapping',
    'apiEditProfileScreen.get': '/api/edit-profile-screen',
    'apiEditProfileScreenV2.get': '/api/v1/edit-profile-screen',
    'apiVipCreditHistory.get': '/api/vip-credit-history',    
    'apiVipCreditHistoryV1.get': '/api/v1/vip-credit-history',    
    'apiVipCreditRedeemScreen.get': '/api/vip-credit-redeem-screen',
    'apiVipCreditRedeemScreenV1.get': '/api/v1/vip-credit-redeem-screen',
    'apiVipRedeemCashPayout.get': '/api/vip-redeem-cashpayout',
    'apiVipRedeemCashPayoutV1.get': '/api/v1/vip-redeem-cashpayout',
    'apiVipRedeemCashPayoutConfirm.post': '/api/vip-redeem-cashpayout-confirm',
    'apiVipRedeemCashPayoutConfirmV1.post': '/api/v1/vip-redeem-cashpayout-confirm',
    'apiEvoucherDetail.get': '/api/vip-evoucher-detail',
    'apiEvoucherDetailV1.get': '/api/v1/vip-evoucher-detail',
    'apiEvoucherRedeem.post': '/api/vip-evoucher-redeem',
    'apiEvoucherRedeemV1.post': '/api/v1/vip-evoucher-redeem',
    'apiJobChoicesLanding.get': '/api/job-choices/landing',
    'apiJobChoicesLandingV2.get': '/api/landing/selection',
    'apiSocialAccounts.get': '/api/social/accounts',
    'apiSocialAccountsV1.get': '/api/v1/social/accounts',
    'apiSocialAccountSave.post': '/api/social/account/save',
    'apiSocialAccountSaveV1.post': '/api/v1/social/account/save',
    'apiFbPageAccounts.get': '/api/fb/page/accounts',
    'apiFbPageAccountSave.post': '/api/fb/page/account/save',
    'apiCampaignList.get': '/api/campaign/list',
    'apiPanelList.get': '/api/panel/list',
    'apiPollList.get': '/api/poll/list',
    'apiPanelLanding.get': '/api/panel/landing',
    'apiPanelLandingV2.get': '/api/v1/panel/landing',    
    'apiJobList.get': '/api/job/list',
    'apiJobListV2.get': '/api/v2/job/list',
    'apiJobApplied.get': '/api/job/applied',
    'apiJobAppliedV2.get': '/api/v2/job/applied',
    'apiSetUserAttributeMoEngage.post': '/api/set-moengage-user-attribute',
    'apiPushEventMoEngage.post': '/api/push-event-moengage',
    'apiPanelUserInEligible.post': '/api/panel/user-ineligible',
    'apiPanelUserInEligibleV1.post': '/api/v1/panel/user-ineligible',
    'apiCampaignDetail.get': '/api/campaign/detail',
    'apiCampaignDetailV2.get': '/api/v2/campaign/detail',
    'apiPanelDetail.get': '/api/panel/detail',
    'apiPanelDetailV2.get': '/api/v2/panel/detail',
    'apiPollDetail.get': '/api/poll/detail',
    'usersDownloadFile.post': '/api/users/download',
    'usersDownloadFileCheck.post': '/api/users/download/check',    
    'apiPanelSubmitted.post': '/api/panel/submitted',
    'apiPanelSubmittedV1.post': '/api/v1/panel/submitted',
    'apiPollSubmitted.post': '/api/poll/submitted',
    'apiCampaignApply.post': '/api/campaign/apply',
    'apiCampaignApplyV1.post': '/api/v1/campaign/apply',
    'apiCampaignsApplied.get': '/api/campaigns/applied',
    'apiCampaignsAppliedV1.get': '/api/v1/campaigns/applied',
    'apiCampaignApplicationStatus.get': '/api/campaign/application/status',
    'apiNotificationList.get': '/api/notification/list',
    'apiNotificationListV1.get': '/api/v1/notification/list',
    'apiBrandEnquiryDownload.get':'/api/brand/enquiry/download',
    'apiCronTrigger.get': '/api/cron/trigger/:type',
    'apiCintCallbackSuccess.get': '/api/cint/callback/success',
    'apiCintCallbackError.get': '/api/cint/callback/error',
    'apiCintCallbackMob.get': '/api/cint/callback/mob',
    'apiScreeningQuestionnaire.get': '/api/campaign/screening/questionnaire',
    'apiScreeningQuestionnaireSubmit.post': '/api/campaign/screening/questionnaire/submit',
    'apiCintProfilersUpdate.post': '/api/cint/profilers/update',
    'apiCintProfilersFetchCron.get': '/api/cint/profilers/fetch/cron',
    'apiCampaignManagerApplicationStatusEmail.get': '/api/campaign/manager/email',
    //------ mob ------------------------
    'mobWelcome': '/mob/welcome',
    'mobFaq': '/mob/faq',
    'mobHow': '/mob/how',
    'mobWelcomeV1': '/mob/v1/welcome', //migrated to vip_signup_web
    'mobCampaignDetail': '/mob/campaign/:campaignId', //migrated to vip_signup_web
    'mobCampaignModeration': '/mob/campaign/:campaignId/moderation',
    'mobCampaignSubmission': '/mob/campaign/:campaignId/submission', //migrated to vip_signup_web
    'mobCampaignTermsAndConditions': '/mob/campaign/:campaignId/terms-and-conditions', //migrated to vip_signup_web
    'mobCampaignEmpty': '/mob/campaign/empty',
    'mobPanelDetail': '/mob/panel/detail/:id',  //migrated to vip_signup_web
    'mobWelcomeFaq': '/mob/welcome/faq',  //migrated to vip_signup_web
    'mobWelcomeFeedback': '/mob/welcome/feedback',  
    'mob404': '/mob/404', //migrated to vip_signup_web
    'mobLoading': '/mob/loading',
    'mobAuthTiktok': '/mob/auth/tiktok',
    //------ influencer ------------------------    
    'influencerLogin': '/influencer/login', //migrated to vip_signup_web
    'influencerLogin.post': '/influencer/login',
    'influencerRegisterProfile': '/influencer/register/profile',
    'influencerRegisterKids': '/influencer/register/kids',        
    'influencerCampaigns': '/influencer/campaigns',
    'influencerCampaignDetail': '/influencer/campaign/:id',
    'influencerCampaignsApplied': '/influencer/campaigns/applied',
    'influencerProfileConnect': '/influencer/profile/connect',
    'influencerProfileEdit': '/influencer/profile/edit', //migrated to vip_signup_web
    'influencerProfileSocial': '/influencer/profile/social/:type',
    'influencerCampaignModeration': '/influencer/campaign/:id/moderation',
    'influencerCampaignSubmission': '/influencer/campaign/:id/submission',
    'influencerAbout': '/influencer/about', //migrated to vip_signup_web
    'brandEnquiryPage': '/brand/enquiry',
    'influencerDebug': '/influencer/debug',
    'influencerFeedback': '/influencer/feedback',
    'influencerRegisterProfileDefaults.post': '/influencer/register/profile/defaults',    
    'influencerRegisterProfileSave.post': '/influencer/register/profile/save',
    'influencerRegisterKidsSave.post': '/influencer/register/kids/save',
    'influencerRegisterKidsDefaults.post': '/influencer/register/kids/defaults',
    'influencerProfile.post': '/influencer/profile',
    'influencerProfileSocial.post': '/influencer/profile/social',
    'influencerCampaignsApplied.post': '/influencer/campaigns/applied',
    'influencerCampaignApplicationStatus.post': '/influencer/campaign/application/status',
    'influencerCampaignApply.post': '/influencer/campaign/apply',
    'influencerCampaignUrlShorten.post': '/influencer/campaign/url/shorten',
    'influencerSocialAccounts.post': '/influencer/social/accounts',
    'influencerSocialAccountUpdate.post': '/influencer/social/account/update',
    'influencerSocialAccountDelete.post': '/influencer/social/account/delete',
    'influencerCampaignModerationUpdate.post': '/influencer/campaign/moderation/update',
    'influencerCampaignSubmission.post': '/influencer/campaign/submission',
    'influencerCampaignSubmissionEdit.post': '/influencer/campaign/submission/edit',
    'influencerCampaignSubmissionStatus.post': '/influencer/campaign/submission/status',
    'influencerCampaignFeedbackUpdate.post': '/influencer/campaign/feedback/update',
    'influencerSocialFbPageConnectInitiate.post': '/influencer/social/fb/page/connect/initiate',
    'influencerSocialFbPageAccountList.post': '/influencer/social/fb/page/list',
    'influencerSocialFbPageAccountConnect.post': '/influencer/social/fb/page/connect',
    'influencerFeedbackAdd.post': '/influencer/feedback/add',
    'influencerSocialInstaGraphConnectInitiate.post': '/influencer/social/insta/graph/connect/initiate',
    'influencerInstaBusinessAccountConnect.post': '/influencer/social/insta/graph/connect',
    'influencerValidateValue.post': '/influencer/validate/value',
    //------ panel -----------------------------
    'panelList.post': '/panel/list',
    'panelDetail.post': '/panel/detail',
    'panelRegisterProfileSave.post': '/panel/register/profile/save',
    'brandPanelCreate': '/brand/panel/create',
    'brandPanelAll': '/brand/panel/all',
    'brandPanelPreview': '/brand/panel/:id/preview',
    'brandPanelFilter': '/brand/panel/:id/filter',
    'brandPanelEdit': '/brand/panel/:id/edit',
    'brandPanelHome': '/brand/panel/:id/home',
    'brandPanelSubmissions': '/brand/panel/:id/submissions',
    'brandPanelEmailTracker': '/brand/panel/:id/email/tracker',
    'brandPanelCategoryList.post': '/brand/panel/category/list',
    'panelSave.post': '/panel/save',
    'brandPanelEdit.post': '/brand/panel/edit',
    'brandPanelFilterTargetCount.post': '/brand/panel/filter/count',
    'brandPanelFilterSave.post': '/brand/panel/filter/save',
    'brandPanelFilterFetch.post': '/brand/panel/filter/fetch',
    'brandPanelPublish.post': '/brand/panel/publish',
    'brandPanelPublishBulkNotification.post': '/brand/panel/publish/bulk/notification',
    'brandPanelSubmissions.post': '/brand/panel/submissions',
    'brandPanelSubmissionsDownload.post': '/brand/panel/submissions/download',
    'brandPanelPollExists.post': '/brand/panel/poll/exists',
    //------ brand -----------------------------
    'brandHome': '/brand/home',
    'brandAdminSignup': '/brand/admin/signup',
    'brandAdminPasswordReset': '/brand/admin/password/reset',
    'brandAdminSignup.post': '/brand/admin/signup',
    'brandAdminLogin.post': '/brand/admin/login',
    'brandAdminPasswordForgot.post': '/brand/admin/password/forgot',
    'brandAdminPasswordReset.post': '/brand/admin/password/reset',
    'brandCampaignAll': '/brand/campaign/all',
    'brandCampaignList': '/brand/campaign/list',    
    'brandCampaignCreate': '/brand/campaign/create',
    'brandCampaignHome': '/brand/campaign/:id/home',
    'brandCampaignEdit': '/brand/campaign/:id/edit',
    'brandCampaignFilter': '/brand/campaign/:id/filter',
    'brandCampaignScreening': '/brand/campaign/:id/screening',
    'brandCampaignPreview': '/brand/campaign/:id/preview',
    'brandCampaignManager': '/brand/campaign/:id/manager',
    'brandCampaignPayments': '/brand/campaign/:id/payments',
    'brandCampaignsApplicationsAll': '/brand/campaigns/applications/all',
    'brandCampaignsApplicationsPending': '/brand/campaigns/applications/pending',
    'brandCampaignApplications': '/brand/campaign/:id/applications',
    'brandCampaignApplicant': '/brand/campaign/:campaignId/applicant/:influencerId',
    'brandCampaignModeration': '/brand/campaign/:id/moderation',
    'brandCampaignSubmissions': '/brand/campaign/:id/submissions',
    'brandCampaignApplicantSubmissions': '/brand/campaign/:id/applicant/:influencerId/submissions',
    'brandCampaignGrading': '/brand/campaign/:id/grading',
    'brandCampaignFeedbackAnswers': '/brand/campaign/:id/feedback/answers',
    'brandCampaignLinkStats': '/brand/campaign/:id/link/stats',
    'brandCampaignEmailTracker': '/brand/campaign/:id/email/tracker',
    'brandCampaignEmailTrackerLegacySent': '/brand/campaign/:id/email/tracker/legacy/sent',
    'brandCampaignEmailTrackerLegacyOpened': '/brand/campaign/:id/email/tracker/legacy/opened',
    'allInfluencerUsers': '/brand/users/influencer/all',
    'downloadInfluencerUsers': '/brand/users/influencer/download',
    'allPanelUsers': '/brand/users/panel/all',
    'allAdmins': '/brand/admins/all',
    'brandUserProfile': '/brand/user/:id',
    'brandOther': '/brand/other',
    'brandRemote': '/brand/remote',
    'brandInfluencerPaymentHistory': '/brand/user/:influencerId/payment/history',
    'brandInfluencerGradingHistory': '/brand/user/:influencerId/grading/history',
    'brandInfluencerFeedback': '/brand/influencer/feedback',
    'brandCampaignSave.post': '/brand/campaign/save',    
    'brandCampaignEdit.post': '/brand/campaign/:id/edit',    
    'brandCampaignFilter.post': '/brand/campaign/:id/filter',
    'brandCampaignFilterSave.post': '/brand/campaign/:id/filter/save',
    'brandCampaignFilterTargetCount.post': '/brand/campaign/filter/count',
    'brandProductCategoryList.post': '/brand/product/category/list',
    'brandProductCategoryAdd.post': '/brand/product/category/add',
    'brandCampaignPublish.post': '/brand/campaign/publish',
    'brandCampaignApplications.post': '/brand/campaign/applications',
    'brandCampaignApplicationsDownload.post': '/brand/campaign/applications/download',
    'brandCampaignInfluencerApprove.post': '/brand/campaign/influencer/approve',
    'brandCampaignInfluencersApprove.post': '/brand/campaign/influencers/approve',    
    'brandCampaignInfluencerDeadlineEdit.post': '/brand/campaign/influencer/deadline',
    'brandCampaignModerationUpdate.post': '/brand/campaign/moderation/update',
    'brandCampaignSubmissions.post': '/brand/campaign/submissions',
    'brandCampaignSubmittersDownload.post': '/brand/campaign/submitters/download',
    'brandCampaignSubmissionVerify.post': '/brand/campaign/submission/verify',
    'brandInfluencerPayments.post': '/brand/influencer/payments',
    'brandInfluencerPaymentAdjust.post': '/brand/influencer/payment/adjust',
    'brandInfluencerGradeAdjust.post': '/brand/influencer/grade/adjust',    
    'brandCampaignFeedbackQuestionsUpdate.post': '/brand/campaign/feedback/questions/update',
    'brandCampaignFeedbackAnswers.post': '/brand/campaign/feedback/answers',
    'brandCampaignFeedbackDownload.post': '/brand/campaign/feedback/download',
    'brandCampaignLinkStats.post': '/brand/campaign/link/stats',
    'brandCampaignLinkStatsDownload.post': '/brand/campaign/link/stats/download',
    'brandCampaignEmailTracker.post': '/brand/campaign/email/tracker',
    'brandCampaignEmailTrackerDownload.post': '/brand/campaign/email/tracker/download',
    'brandCampaignEmailTrackerLegacyOpened.post': '/brand/campaign/email/tracker/legacy/opened',
    'brandCampaignEmailTrackerLegacySent.post': '/brand/campaign/email/tracker/legacy/sent',
    'brandCampaignManager.post': '/brand/campaign/manager',
    'brandCampaignManagerSave.post': '/brand/campaign/manager/save',
    'brandCampaignBriefForInfluencer.post': '/brand/campaign/brief',
    'allUsers.post': '/users/all',
    'allPanelUsers.post': '/users/panel/all',
    'usersDownload.post': '/users/download',
    'panelUsersDownload.post': '/users/panel/download',
    'allAdmins.post': '/admins/all',
    'brandUserProfile.post': '/brand/user/:id',
    'brandInfluencerApprove.post': '/brand/influencer/approve',
    'brandInfluencerDelete.post': '/brand/influencer/delete',
    'brandCampaignActivate.post': '/brand/campaign/activate',
    'brandPanelActivate.post': '/brand/panel/activate',
    'brandInfluencerRemind.post': '/brand/influencer/remind',
    'brandInfluencerBulkRemind.post': '/brand/influencer/bulk/remind',
    'brandCampaignPublishBulkNotification.post': '/brand/campaign/publish/bulk/notification',
    'brandAdminUpdate.post': '/brand/admin/update',
    'brandCampaignScreeningQuestions.post': '/brand/campaign/screening/questions',
    'brandCampaignScreeningQuestionsUpdate.post': '/brand/campaign/screening/questions/update',
    'brandInfluencerIrisBulkPayoutDownload.post': '/brand/influencer/payment/iris/download',
    'brandAdminAddAdmin.post': '/brand/admin/add-admin',
    'allBrands.post': '/allBrands/all',
    'brandCampaignEngagementDownload.post': '/brand/campaign/engagement/download',
    'brandProfilePostsDownload.post': '/brand/profile/posts/download',
    'brandEnquirySave.post':'/brand/enquiry/save',
    // ------------- Credits ---------------
    'brandCreditsEvoucherAll': '/brand/credits/evoucher/all',
    'brandCreditsEvoucherAdd': '/brand/credits/evoucher/add',
    'brandCreditsEvoucherPreview': '/brand/credits/evoucher/preview/:id',
    'brandCreditsEvoucherProviderAll': '/brand/credits/evoucher/provider/all',
    'brandCreditsEvoucherProviderAdd.post': '/brand/credits/evoucher/provider/add',
    'brandCreditsEvoucherProviderList.post': '/brand/credits/evoucher/provider/list',
    'brandCreditsEvoucherProviderUpdate.post': '/brand/credits/evoucher/provider/update',
    'brandCreditsEvoucherProviderDelete.post': '/brand/credits/evoucher/provider/delete',
    'brandCreditsEvoucherTemplateDownload.post': '/brand/credits/evoucher/template/download',
    'brandCreditsEvoucherTemplateUpload.post': '/brand/credits/evoucher/template/upload',
    'brandCreditsEvoucherPreview.post': '/brand/credits/evoucher/preview',
    'brandCreditsEvoucherPreviewSubmit.post': '/brand/credits/evoucher/preview/submit',
    'brandCreditsEvoucherPreviewCancel.post': '/brand/credits/evoucher/preview/cancel',
    'brandCreditsEvoucherList.post': '/brand/credits/evoucher/list',
    'brandCreditsEvoucherListDownload.post': '/brand/credits/evoucher/list/download',
    //------ stats -----------------------------
    'statsHome': '/stats/home',
    // catchAll404: '*'
}