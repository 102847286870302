import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import EditForm from '../../../../components/Brand/Campaign/Form';
import { brandCampaignEdit } from '../../../../actions';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';
import { translate } from 'react-i18next';

const Edit = (props) => {
    const [isLoading, setIsLoading] = useState(false);        

    const onSubmit = (data) => {
        
        if(data.screeningQuestions && data.screeningQuestions.questions) {
            for (let i = 0; i < data.screeningQuestions.questions.length; i++) {
                const q = data.screeningQuestions.questions[i];
                if (q.status === 1 && (q.type === "dropdown" || q.type === "checkbox") && q.options.length === 0) {                    
                    return notification.send({device: props.rdx.appReducer.deviceType, message: 'Multi/single choice questions need to have atleast 1 option', type: 'error'});
                }
            }    
        }

        data.screeningQuestions = JSON.stringify(data.screeningQuestions);
        //validations
        //logo
        if(!data.logoPreview)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload the logo!', type: 'error'});
        //product image
        if(!data.productImagePreview)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Kindly upload the product image!', type: 'error'});
        //actionable items
        if(!data.actionOther && !data.blog && !data.attendEvent && !data.igPost && !data.fbPost && !data.instaStory && !data.fbStory && !data.socialMediaPost && !data.actionImage && !data.tiktokPost)
            return notification.send({device: props.rdx.appReducer.deviceType, message: "You need to specify atleast one of the 'Job Details' fields!", type: 'error'});
        //payment value
        if(data.paymentType !== 'other' && !data.paymentValue)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Payment value cant be 0!', type: 'error'});
        //currency type
        if(data.paymentType == 'monetary' && data.currencyType.length == 0)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Enter a currency type!', type: 'error'});
        //else
        setIsLoading(true);
        props.actions.brandCampaignEdit(data)
        .then(() => {
            setIsLoading(false);
            // notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
            window.location = `/brand/campaign/${props.match.params.id}/filter`;
        });        
    }

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Edit'/>
            ]}>

            {props.rdx.brandReducer.currentCampaign && <div className="campaign-create">
            <h1>Edit Campaign - {props.rdx.brandReducer.currentCampaign.campaignName}</h1>
                <EditForm onSubmit={onSubmit}
                    values={props.rdx.brandReducer.currentCampaign}
                    type="edit"
                    setLoading={setIsLoading}
                    sendNotification={(message, type) => notification.send({device: props.rdx.appReducer.deviceType, message, type})}
                    t={props.t} />     
            </div>}

            { isLoading && <Loader /> }            
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignEdit
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Edit));
