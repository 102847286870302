import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import ApplicantProfile from '../../../../components/Brand/Campaign/Applicant';
import { brandCampaignInfluencerApprove, brandCampaignInfluencerDeadlineEdit } from '../../../../actions';
import { 
    useInfluencerProfile,
    useInfluencerProfileSocial,
    useInfluencerCampaignsApplied,
    useInfluencerCampaignApplicationStatus
} from '../../../../hooks/generic';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import notification from '../../../../components/UIElems/Notification';

const Profile = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const profile = useInfluencerProfile(props.dispatch, props.match.params.influencerId);
    const profileSocial = useInfluencerProfileSocial(props.dispatch, props.match.params.influencerId);
    const campaignsApplied = useInfluencerCampaignsApplied(props.dispatch, props.match.params.influencerId);
    const applicationStatus = useInfluencerCampaignApplicationStatus(props.dispatch, props.match.params.influencerId, props.match.params.campaignId, forceUpdate);

    const approveApplication = (flag, reason = "") => {
        props.actions.brandCampaignInfluencerApprove(props.match.params.campaignId, props.match.params.influencerId, flag, reason)
        .then(() => {
            setForceUpdate(!forceUpdate);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
        });
    }

    const deadlineEdit = (deadlineDate, comments) => {
        props.actions.brandCampaignInfluencerDeadlineEdit(props.match.params.campaignId, props.match.params.influencerId, deadlineDate, comments)
        .then(() => {
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated and notification sent!', type: 'success'});
        });
    }

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.campaignId} currentTitle='Applications'/>
            ]}>
            <div className="applicant-profile">
                {/*<a href="javascript:history.go(-1)" className={cx("back-btn-wrap")}><span className={cx("campaign-sidebar-nav-sprite campaign-back-icon")}></span> <sapn>Go Back</sapn></a>*/}
                <h1>View Profile</h1>
                <ApplicantProfile campaignId={props.match.params.campaignId}
                    influencerId={props.match.params.influencerId}
                    profile={profile}
                    profileSocial={profileSocial}
                    campaignsApplied={campaignsApplied.list}
                    approveApplication={approveApplication}
                    deadlineEdit={deadlineEdit}
                    applicationStatus={applicationStatus} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignInfluencerApprove,
        brandCampaignInfluencerDeadlineEdit        
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
