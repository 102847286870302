import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { translate } from 'react-i18next';
import { genericAction } from '../../../../actions';
import ElemModal from '../../../UIElems/Modal';
import { Icon } from 'antd';
import { useCountryList, useBankList, useInfluencerPaymentDetails } from '../../../../hooks/generic';
import notification from '../../../../components/UIElems/Notification';

const PaymentDetailsUpdate = (props) => {
    const data = useInfluencerPaymentDetails(() => {}, props.dispatch, props.influencerId, false);
    const bankList = useBankList(props.dispatch, props.countryId);

    const dV = (key, values, defaultVal) => {
        return values && values[key] ? values[key] : defaultVal;
    }

    const t = props.t;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [influencerBankRowId, setInfluencerBankRowId] = useState(dV('influencerBankRowId', data, ''));
    const [bankId, setBankId] = useState(dV('bankId', data, ''));
    const [beneficiaryAccountNo, setBeneficiaryAccountNo] = useState(dV('beneficiaryAccountNo', data, ''));
    const [beneficiaryEmail, setBeneficiaryEmail] = useState(dV('beneficiaryEmail', data, ''));
    const [beneficiaryName, setBeneficiaryName] = useState(dV('beneficiaryName', data, ''));

    //
    useEffect(() => {
        if(data) {
            setBankId(data.influencerBankRowId);
            setBankId(data.bankId);
            setBeneficiaryAccountNo(data.beneficiaryAccountNo);
            setBeneficiaryEmail(data.beneficiaryEmail);
            setBeneficiaryName(data.beneficiaryName);
            setInfluencerBankRowId(data.influencerBankRowId);
        }        
    }, [data]);

   

    const onModalOk = (e) => {
        if(e) e.preventDefault();
        setIsLoading(true);
        //update
        props.actions.genericAction(
            'influencerBankInfoSave',
            [{
                influencerBankRowId,
                bankId,
                beneficiaryAccountNo,
                beneficiaryEmail,
                beneficiaryName,
                countryId: props.countryId,
                influencerId: props.influencerId
            }]
        )
        .then(r => {
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
        })        
    }

    const onModalOpen = (e) => {
        setIsModalOpen(true);
    }

    const onModalClose = () => {
        setIsModalOpen(false);
    }

    return (
        <div>
            <div>
                <ElemModal title={'Bank Details'}
                    OkText="Save"
                    openModal={isModalOpen}
                    onClose={onModalClose}
                    onOk={onModalOk}
                    isLoading={isLoading} >
                        <div>

                            <h2>Payment</h2>

                            {/*<div className={cx("form-wrap")}>
                                <label htmlFor="gopay-phone-no">GoPay {t('registration_profile_phone_no')}: </label>
                                <div className={cx("")}>
                                    <input id="gopay-phone-no" type="text" value={gopayPhoneNo} onChange={e => setGopayPhoneNo(e.target.value)} required/>
                                </div>
                            </div>*/}

                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Bank Name: </label>
                                <select id="bank-code" value={bankId} onChange={e => setBankId(e.target.value)} required>
                                    <option value="">{t('registration_profile_please_select')}</option>
                                    {
                                        bankList.map((d, i) => {
                                            return <option key={i} value={d.id}>{d.bankName}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className={cx("form-wrap")}>
                                <label htmlFor="beneficiary-no">Beneficiary Account No: </label>
                                <div className={cx("")}>
                                    <input id="beneficiary-no" type="text" value={beneficiaryAccountNo} onChange={e => setBeneficiaryAccountNo(e.target.value)} required/>
                                </div>
                            </div>

                            <div className={cx("form-wrap")}>
                                <label htmlFor="beneficiary-name">Beneficiary Name: </label>
                                <div className={cx("")}>
                                    <input id="beneficiary-name" type="text" value={beneficiaryName} onChange={e => setBeneficiaryName(e.target.value)} required/>
                                </div>
                            </div>

                             <div className={cx("form-wrap")}>
                                <label htmlFor="beneficiary-email">Beneficiary Email: </label>
                                <div className={cx("")}>
                                    <input id="beneficiary-email" type="text" value={beneficiaryEmail} onChange={e => setBeneficiaryEmail(e.target.value)} required/>
                                </div>
                                <div>*Payment receipt will be sent to this email</div>
                            </div>
                        </div>
                </ElemModal>
            </div>
            <div>
                <a onClick={onModalOpen}>
                    <Icon type="edit" />
                    <span> &nbsp;Edit</span>
                </a>
            </div>            
        </div>        
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaymentDetailsUpdate));