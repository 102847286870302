import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';
import { translate } from 'react-i18next';

const Campaign = ({links = [], defaultState='expand', t}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: '/influencer/campaigns', title: t('sidebar_all'), ifActive: ((pathname == '/influencer/campaigns' || pathname == '/') ? 'active-item' : '')},
        {path: '/influencer/campaigns/applied', title: t('sidebar_Applied'), ifActive: ((pathname == '/influencer/campaigns/applied') ? 'active-item' : '')}
    ];

    return (
        <SidebarTemplate heading={t('sidebar_campaign')}
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="influ-sidebar-campaign-icon" />
    );
}

export default translate()(Campaign);

