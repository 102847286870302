import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';

const CurrentCampaign = ({campaignId, links = [], currentTitle=null, defaultState='expand'}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: `/brand/campaign/${campaignId}/home`, title: 'Home', ifActive: '' },
        {path: `/brand/campaign/${campaignId}/edit`, title: 'Edit', ifActive: '' },
        {path: `/brand/campaign/${campaignId}/filter`, title: 'Filter', ifActive: ''},
        // {path: `/brand/campaign/${campaignId}/screening`, title: 'Screening', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/preview`, title: 'Preview', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/manager`, title: 'Campaign Manager', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/email/tracker`, title: 'Email Tracker', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/applications`, title: 'Applications', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/moderation`, title: 'Moderation', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/submissions`, title: 'Job Submitters', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/grading`, title: 'Influencer Grading', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/payments`, title: 'Payments', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/link/stats`, title: 'Link Stats', ifActive: ''},
        {path: `/brand/campaign/${campaignId}/feedback/answers`, title: 'Feedback', ifActive: ''}
    ];

    //set current
    if(currentTitle) {
        for(let k in defaultLinks) {
            if(defaultLinks[k]['title'] == currentTitle)
                defaultLinks[k]['ifActive'] = 'active-item';
        }
    }


    return (
        <SidebarTemplate heading="Current Campaign"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-campaign-icon" />
    );
}

export default CurrentCampaign;
