import React from 'react';
import { useState, useEffect } from 'react';
import SidebarTemplate from '../Template';
import { AddBoxOutlined } from '@material-ui/icons';

const Jobs = ({links = [], defaultState = 'expand'}) => {
    const [pathname, setPathname] = useState('');

    useEffect(() => {
        setPathname(window.location.pathname);
    });

    const defaultLinks = [
        [
            {path: '/brand/campaign/all', title: 'Influencers', ifActive: (pathname == '/brand/campaign/all' ? 'active-item' : '')},
            {path: '/brand/campaign/create', title: <AddBoxOutlined />, ifActive: ((pathname == '/brand/campaign/create') ? 'active-item' : '')}
        ],
        [
            {path: '/brand/panel/all', title: 'Panel', ifActive: (pathname == '/brand/panel/all' ? 'active-item' : '')},
            {path: '/brand/panel/create', title: <AddBoxOutlined />, ifActive: (pathname == '/brand/panel/create' ? 'active-item' : '')}
        ],
        [
            {path: '/brand/content-creator-jobs/all', title: 'Content Creator', ifActive: (pathname == '/brand/content-creator-jobs/all' ? 'active-item' : '')},
            {path: '/brand/content-creator-jobs/create', title: <AddBoxOutlined />, ifActive: (pathname == '/brand/content-creator-jobs/create' ? 'active-item' : '')}
        ],
        {path: '/brand/home', title: 'All Jobs', ifActive: (pathname == '/brand/home' ? 'active-item' : '')},
        {path: '/vip_cc_admin/brand/vip-banners/all', title: 'Manage Banners', ifActive: (pathname == '/vip_cc_admin/brand/vip-banners/all' ? 'active-item' : '')}
    ];


    return (
        <SidebarTemplate heading="Jobs"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-campaign-icon" />
    );
}

export default Jobs;