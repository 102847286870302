import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import BrandEnquiryPageComponent from '../../../components/Brand/BrandEnquiryPage';
import { translate } from 'react-i18next';

const BrandEnquiryPage = ({t}) => {
    return (
      
            <div className="brand-fluid-wrapper">
                <BrandEnquiryPageComponent t={t}/> 
            </div>
       
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(BrandEnquiryPage));
