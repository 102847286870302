import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import EmailsOpenedTable from './Table';
import BaseLayout from '../../../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../../../components/Brand/Sidebar/CurrentCampaign';
import { genericAction } from '../../../../../../actions';
import { Typography } from 'antd';

const { Text } = Typography;

const EmailTrackerOpened = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Email Tracker'/>
            ]}>
            <div className="email-tracker">
                <h1>Email Tracker - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <h2>Opened Emails</h2>
                <a href={`/brand/campaign/${props.match.params.id}/email/tracker/legacy/sent`} target="_blank"><Text type="secondary" underline={true}>view sent emails</Text></a>
                <EmailsOpenedTable campaignId={props.match.params.id}
                    conditions={{}} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailTrackerOpened);