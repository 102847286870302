import React, { useRef, createRef } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {} from '../../../../../hooks/generic';
import { createImagePreview } from '../../../../../utils';
import { Icon, Popover } from 'antd';
import notification from '../../../../UIElems/Notification';
import { brandCampaignSubmissions } from '../../../../../actions';

const Form = ({assignUpdater, campaignDetail, moderationData, t, deviceType, influencerId, dispatch, submissionStatus, setIsLoading}) => {
    const [submissionData, setSubmissionData] = useState({
        fb: {url: [], description: '', filter: 'fbPost', title: 'campaign_submission_facebook_post', type: 'fb', numLinks: 'noOfLinksFBPost'},
        fbStory: {url: [], description: '', filter: 'fbStory', title: 'campaign_submission_facebook_story', type: 'fbStory', numLinks: 'noOfLinksFBStory'},
        insta: {url: [], description: '', filter: 'igPost', title: 'campaign_submission_instagram_post', type: 'insta', numLinks: 'noOfLinksIGPost'},
        instaStory: {url: [], description: '', filter: 'instaStory', title: 'campaign_submission_instagram_story', type: 'instaStory', numLinks: 'noOfLinksIGStory'},
        // socialMedia: {url: '', description: '', filter: 'socialMediaPost', title: 'campaign_submission_social_media_post', type: 'socialMedia'},
        event: {url: [], description: '', filter: 'attendEvent', title: 'campaign_submission_event_details', type: 'event', numLinks: 'noOfLinksAttendEvents'},
        blog: {url: [], description: '', filter: 'blog', title: 'campaign_submission_blog_post', type: 'blog', numLinks: 'noOfLinksBlog'},
        actionOther: {url: [], description: '', filter: 'actionOther', title: 'campaign_submission_other', type: 'other', numLinks: 'noOfLinksOther'},
        actionImage: {url: '', description: '', data: [], filter: 'actionImage', title: 'campaign_submission_image', type: 'image', numLinks: 'noOfLinksImage', ifRequired: false},
        tiktok: {url: [], description: '', filter: 'tiktokPost', title: 'campaign_submission_tiktok_post', type: 'tiktok', numLinks: 'noOfLinksTiktokPost'},
    });

    const [actionImagePreview, setActionImagePreview] = useState([]);
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const imageUploadRef = useRef([]);

    useEffect(() => {
        const numLinks = campaignDetail[submissionData['actionImage'].numLinks];
        if (imageUploadRef.current.length !== numLinks) {
            imageUploadRef.current = Array(numLinks).fill().map((_, i) => imageUploadRef.current[i] || createRef())
        }
    }, [campaignDetail])

    //
    useEffect(() => {
        //if image requirement
        const imageRequired = !!campaignDetail['actionImage'];
        const url = imageRequired? ' ': '';
        let updateData = {...submissionData, actionImage: {...submissionData['actionImage'], url, ifRequired: !!campaignDetail['actionImage']}};
        setSubmissionData(updateData);
    }, [campaignDetail])

    useEffect(() => {
        if (submissionStatus === 4 || submissionStatus === 5) {
            setIsLoading(true);
            dispatch(brandCampaignSubmissions(campaignDetail.campaignId, influencerId))
            .then(response => {
                const data = response.data.list;
                const newSubmissionData = {...submissionData};
                let preview = [];
                data.forEach(d => {
                    const typeKey = Object.keys(newSubmissionData).find(key => newSubmissionData[key].type === d.type);
                    const typeObj = newSubmissionData[typeKey];
                    if (typeObj.url && Array.isArray(typeObj.url)) {
                        if ((campaignDetail[typeObj.numLinks] === 0 && typeObj.url.length === 1) ||
                            (campaignDetail[typeObj.numLinks] > 0 && typeObj.url.length >= campaignDetail[typeObj.numLinks])) {
                            return;
                        }
                        typeObj.url.push(d.url);
                    } else {
                        typeObj.url = [d.url];
                    }
                    if (typeObj.urlId && Array.isArray(typeObj.urlId)) {
                        typeObj.urlId.push(d.id);
                    } else {
                        typeObj.urlId = [d.id];
                    }
                    typeObj.description = d.description;
                    if (typeKey === 'actionImage') {
                        preview.push(d.url);
                    }
                });
                setActionImagePreview(preview);
                setSubmissionData(newSubmissionData);
                if (submissionStatus === 4) {
                    setIsInputDisabled(true);
                }
                setIsLoading(false);
            })
            .catch(err => console.log(err));
        }
    }, [campaignDetail, submissionStatus]);

    useEffect(() => {
        assignUpdater(submissionData)
    }, [submissionData]);

    const onActionImageChange = (e) => {
        const file = e.target.files[0];
        new Promise(resolve => {
            createImagePreview(file, previewImage => resolve(previewImage));
        }).then(image => {
            let updateData = {...submissionData};
            updateData.actionImage.data.push(file);
            setSubmissionData(updateData);
            assignUpdater(updateData);
            const preview = [...actionImagePreview, image];
            setActionImagePreview(preview);
        });
    }

    const onBulkActionImageChange = e => {
        const numLinks = campaignDetail[submissionData['actionImage'].numLinks];
        let files = Array.from(e.target.files);
        let updateData = {...submissionData};
        let totalImages = updateData.actionImage.data.length + files.length;
        totalImages = submissionStatus === 5?
            totalImages + updateData.actionImage.url.length:
            totalImages;
        if (totalImages > numLinks) {
            files.splice(-1, (totalImages - numLinks));
            notification.send({device: deviceType, message: `No of images selected exceeds the required ${numLinks} images. Only the first ${numLinks} images will be considered`, type: 'warning'});
        }
        updateData.actionImage.data = [...updateData.actionImage.data, ...files];
        setSubmissionData(updateData);
        assignUpdater(updateData);
        const promises = [];
        files.forEach(file => {
            const promise = new Promise(resolve => {
                createImagePreview(file, previewImage => resolve(previewImage));
            });
            promises.push(promise);
        });
        Promise.all(promises).then(images => setActionImagePreview([...actionImagePreview, ...images]));
    }

    const onDataChange = (key, type, data) => {
        let updateData = {...submissionData, [key]: {...submissionData[key], [type]: data}};
        setSubmissionData(updateData);
        assignUpdater(updateData);
    }

    const onURLChange = (key, index, data) => {
        let updateData = {...submissionData};
        updateData[key]['url'][index] = data;
        setSubmissionData(updateData);
        assignUpdater(updateData);
    }

    const PreviewPopover = ({name, image, caption}) => {
        const previewContent = image            
            ?  
                <div style={{width: 420, height: 340}}>
                    <p>Name: {name}</p>
                    <img src={image} alt="preview" width="320" height="480" />
                    <p>Caption: {caption}</p>   
                </div>
            : 
                <div>No preview available</div>;

        return (
            <Popover content={previewContent} title="Preview - click to enlarge" trigger="click">
                {
                    image
                    ? <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>Approved template. Tap to preview.</span>
                    : <span>No approved template.</span>
                }
            </Popover>
        );
    }

    const hiddenInputClicked = i => imageUploadRef.current[i].current.click();

    const removeImage = index => {
        const images = [...actionImagePreview];
        images.splice(index, 1);
        setActionImagePreview(images);
        const updateData = {...submissionData};
        if (submissionStatus === 5) {
            index < updateData.actionImage.url.length?
                updateData.actionImage.url.splice(index, 1):
                updateData.actionImage.data.splice(index - updateData.actionImage.url.length, 1);
            let removedElements = updateData.actionImage.urlId.splice(index, 1);
            updateData.actionImage.urlId = [...updateData.actionImage.urlId, ...removedElements];
        } else {
            updateData.actionImage.data.splice(index, 1);
        }
        setSubmissionData(updateData);
    }

    const getActionImagePreviews = () => {
        const previews = [];
        const numImages = campaignDetail[submissionData['actionImage'].numLinks];
        for (let i=0; i<numImages; i++) {
            const image = actionImagePreview[i];
            if (image) {
                previews.push(
                    <div className={cx("form-vertical-row mb5")}>
                        <div className={cx("form-wrap mr8 center-elements")} style={{width:"100%"}}>
                            <img src={image} className={cx("action-image-preview preview-image")} alt="Upload Image" width="100%" />
                            {
                                !isInputDisabled &&
                                <button type="button" className={cx("btn-remove")} onClick={e => removeImage(i)}><Icon type="delete" theme="outlined" /> Remove</button>
                            }
                        </div>
                    </div>
                );
            } else {
                previews.push(
                    <div className={cx("form-vertical-row mb5 empty-preview")} onClick={() => hiddenInputClicked(i)}>
                        <div><Icon type="cloud-upload" theme="outlined" style={{color: "#7c808e", fontSize: "28px"}} /></div>
                        <div>Upload Image</div>
                        <input ref={imageUploadRef.current[i]} className="hidden-input" type="file" accept=".jpeg, .jpg, .png" onChange={onActionImageChange} />
                    </div>
                );
            }
        }
        return previews;
    }
    
    return (

        <div className={cx("influencer-campaign-submission-wrapper")}>

            <h1>{t('campaign_submission_title')}</h1>

            {
                Object.keys(submissionData).filter(key => campaignDetail[submissionData[key].filter]).map((key, i) => {
                    const numLinks =
                    campaignDetail[submissionData[key].filter] && campaignDetail[submissionData[key].filter] !== ''?
                        campaignDetail[submissionData[key].numLinks] && campaignDetail[submissionData[key].numLinks] > 0?
                        campaignDetail[submissionData[key].numLinks]:
                        1:
                        null;
                    const linkInputs = [];
                    for (let i=0; i<numLinks; i++) {
                        linkInputs.push(<input key={i} disabled={isInputDisabled} required className={cx("submission-link")} id="fb-link" type="url" value={submissionData[key]['url'][i]} onChange={e => onURLChange(key, i, e.target.value)} placeholder="enter a valid url"/>)
                    }
                    return  (
                        <div className={cx("campaign-fb-submission")} key={i}>
                            <h2>{t(submissionData[key].title)}</h2>
                            {
                                //description
                                <div className={cx("form-group")}>
                                    <div>{campaignDetail[submissionData[key].filter]}</div>                   
                                    <label htmlFor="fb-description">{t('campaign_submission_description')}: </label>
                                    <textarea disabled={isInputDisabled} required id="fb-desc" type="text" value={submissionData[key]['description']} onChange={e => onDataChange(key, 'description', e.target.value)} cols="20" rows="2">
                                    </textarea>
                                </div>
                            }

                            {
                                //url
                                key != 'actionImage' &&
                                <div className={cx("form-group")}>
                                    <label htmlFor="fb-link">{t('campaign_submission_link')}: </label>
                                    {linkInputs}
                                </div>
                            }

                            {
                                //preview
                                key != 'actionImage' &&
                                <div className={cx("form-group")}>
                                    {
                                        moderationData
                                        .filter(m => (m.type == submissionData[key].type) && (['event', 'blog', 'other', 'image'].indexOf(m.type) == -1))
                                        .map((d, i) => {
                                            return <PreviewPopover name={d.name}
                                                image={d.image}
                                                caption={d.caption} />
                                        })
                                    }
                                </div>
                            }

                            {
                                //image
                                key == 'actionImage' &&
                                <div>
                                    <div className={cx("image-grid")}>
                                        {getActionImagePreviews()}
                                    </div>
                                    {
                                        !isInputDisabled &&
                                        <div className={cx("form-group")}>
                                            <span className={cx("btn-choose-file")}>
                                                <input multiple id="action-image" type="file" accept=".jpeg, .jpg, .png" className={cx("choose-file")} onChange={onBulkActionImageChange} />
                                                <label htmlFor="">
                                                    <span>Choose File</span>
                                                    <Icon type="download" theme="filled" />
                                                </label>
                                            </span>
                                            <span>Extension support : jpeg, jpg & png</span>
                                        </div>
                                    }

                                </div>
                            }

                        </div>
                    )
                })
            }           

        </div>        
    );
}

export default Form;