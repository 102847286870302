import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Tooltip, text } from 'recharts';
import { getRandomHexColor } from '../../../../utils';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const PieChartElem = (props) => {
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);
    const [colorByKey, setColorByKey] = useState(null);

    const transformData = (data) => {
        let collector = {};
        if(data) {
            //keys
            let keys = data[0].labelKeys.split(', ');            
            //data points
            for(let k in data) {
                for (let i in keys) {
                    if(keys[i] in collector) {
                        collector[keys[i]]['value'] += data[k][keys[i]];                        
                    } else {
                        collector[keys[i]] = {name: '', value: 0, color: '#0088FE'};
                        collector[keys[i]]['name'] = keys[i];
                        collector[keys[i]]['value'] = data[k][keys[i]];
                        collector[keys[i]]['color'] = data[k][keys[i] + 'Color'];
                    }                    
                }
            }
        }
        setData(Object.values(collector));
    }

    //transform data
    useEffect(() => {
        transformData(props.data);
    }, [props.refresh]);

    const onPieEnter = () => {
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        const radius = outerRadius + 5;
        const x  = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy  + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {/*`${(percent * 100).toFixed(0)}%`*/}
                {percent > 0 ? name : ''}
            </text>
        );
    };

    return (    
        <div>
            <PieChart onMouseEnter={onPieEnter}
                width={'width' in props ? props.width : 400}
                height={'height' in props ? props.height : 250}>
                <Pie
                    isAnimationActive={false}
                    data={data} 
                    cx={200} 
                    cy={110}
                    outerRadius={80} 
                    fill="#8884d8"
                    paddingAngle={5}
                    labelLine={false}
                    label={renderCustomizedLabel} >
                    {
                        data.map((entry, index) => <Cell fill={entry.color} />)
                    }
                </Pie>
                <Tooltip />
            </PieChart>
        </div>
    );
};

export default PieChartElem;

