import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import KidsForm from '../../../../components/Influencer/Registration/Kids/Form';
import { influencerRegisterKidsSave } from '../../../../actions';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import { translate } from 'react-i18next';

const Kids = (props) => {
    const onSubmit = (data) => {
        data['influencerId'] = props.rdx.influencerReducer.id;
        props.actions.influencerRegisterKidsSave(data)
        .then(results => window.location = '/')
        .catch(error => console.log(error))
    }

    return (
        <BaseLayout leftCards={[]} shouldKeepEmpty={true}>
            <KidsForm onSubmit={onSubmit}
                values={props.rdx.influencerReducer.registerKidsDefaults}
                dueDateDefault={props.rdx.influencerReducer.date_of_delivery}
                t={props.t} />
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerRegisterKidsSave
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Kids));
