import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Modal, Button } from 'antd';

const CustomModal = ({onClose, onOk, openModal=false, isLoading=false, okText='Ok', title='', children=null, okButtonProps={}, cancelButtonProps={}}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setIsVisible(true);
    }

    const hideModal = () => {
        if(onClose) onClose();
        setIsVisible(false);
    }

    const toggleModal = () => {
        setIsVisible(!isVisible);
    }

    const modalInteract = (value) => {
        return value ? showModal() : hideModal();
    }

    const handleOk = () => {
        if(onOk) onOk();
        hideModal();
    }

    const handleCancel = () => {
        hideModal();
    }

    //modal visibility
    useEffect(() => {
        modalInteract(openModal);
    }, [openModal])
    //loading
    useEffect(() => {
        setConfirmLoading(isLoading);
    }, [isLoading])

    return (
        <div>
            <Modal title={title}
                visible={isVisible}
                okText={okText}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                maskClosable={false}
                okButtonProps={okButtonProps}
                cancelButtonProps={cancelButtonProps}>
                <div>
                    {children}
                </div>
            </Modal>
        </div>
    );
}

export default CustomModal;