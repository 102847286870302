import i18nClient from '../i18n-client';
import i18nClientDev from '../i18n-client-dev';

let i18nInstance;
if(!module.hot) {
    try {
        i18nInstance = i18nClient;
        i18nInstance.changeLanguage(window.__i18n__.locale);
        i18nInstance.addResourceBundle(window.__i18n__.locale, 'common', window.__i18n__.resources, true);
    } catch(e) {
        // console.log('e', e);
        i18nInstance = i18nClientDev;
    }    
} else {
    // i18nInstance = i18nClientDev;
    i18nInstance = i18nClient;
    i18nInstance.changeLanguage(window.__i18n__.locale);
    i18nInstance.addResourceBundle(window.__i18n__.locale, 'common', window.__i18n__.resources, true);
}

export default i18nInstance;