import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignApplications } from '../../../../../../hooks/generic';
import { brandCampaignSubmittersDownload, brandCampaignEngagementDownload } from '../../../../../../actions';
import Table from '../../../../../../components/Common/Table';
import MenuPopper from '../../../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import { Badge } from 'antd';
import xlsx from 'xlsx';

const SubmittersTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [applicationList] = useBrandCampaignApplications(
        () => setLoading(false),
        props.dispatch,
        props.brandId,
        props.campaignId,
        page,
        pageSize,
        sorted,
        filtered,
        props.conditions,
        'extra' in props ? props.extra : {},
        forceUpdate
    );

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const approveApplication = (campaignId, influencerId, flag) => {
        // props.actions.brandCampaignInfluencerApprove(campaignId, influencerId, flag)
        // .then(() => {
        //     setForceUpdate(!forceUpdate);
        // });
    }

    const createActionLink = (rowData) => {
        let links = [];
        //view submissions
        links.push({title: 'View Submissions', handler: () => window.open(`/brand/campaign/${rowData.campaignId}/applicant/${rowData.influencerId}/submissions`, '_blank')});
        //view profile
        links.push({title: 'View Profile', handler: () => window.open(`/brand/user/${rowData.influencerId}`, '_blank')});

        return <MenuPopper title='Manage' links={links} />
    }

    const jobStatus = (jobStatus) => {
        const status = {
            0: 'In Progress',
            1: 'Completed'
        }

        // return <span style={{color: (jobStatus ? 'green' : 'red')}}>{status[jobStatus]}</span>
        return <span className={cx(jobStatus ? ["status-bg-green", "status-text-green", "status-style"] : ["status-bg-red", "status-text-red", "status-style"])}>{status[jobStatus]}</span>;
    }

    const completedOn = (props) => {
        return props.jobStatus ? format(props.updatedAt, 'DD-MM-YYYY') : '';
    }

    const onDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandCampaignSubmittersDownload(
                props.brandId,
                props.campaignId,
                sorted,
                filtered,
                props.conditions,
                'extra' in props ? props.extra : {}
            )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Campaign_Submitters_${props.campaignId}.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const onEngagementDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandCampaignEngagementDownload(props.campaignId)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `engagement.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = [
        {
            id: "fullname",
            Header: "Name",
            accessor: "",
            sortable: false,
            filterable: true,
            Cell: props => <a target="_blank" href={`/brand/campaign/${props.value.campaignId}/applicant/${props.value.influencerId}/submissions`}>{props.value.fullname}</a>,
            Footer: "Total: " + applicationList.total
        },
        {
            Header: "Email",
            accessor: "email",
            sortable: false,
            filterable: true
        },
        {
            Header: "Campaign Deadline",
            accessor: "deadlineDate",
            sortable: false,
            filterable: false,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Links Submitted",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => 
                <a target="_blank" href={`/brand/campaign/${props.value.campaignId}/applicant/${props.value.influencerId}/submissions`}>
                    <Badge count={props.value.totalLinksSubmitted} style={{ backgroundColor: '#52c41a'}} />
                </a>
        },
        {
            Header: "Job Status",
            accessor: "jobStatus",
            sortable: false,
            filterable: false,
            Cell: props => jobStatus(props.value)
        },
        {
            Header: "Completed On",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => completedOn(props.value)
        },
        {
            Header: "Manage",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div>&nbsp;</div>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button type="button" className={cx("btn", "btn-danger")} onClick={e => onDownload()}>Download</button>
                <button type="button" className={cx("btn", "btn-danger")} onClick={e => onEngagementDownload()}>Download Engagement</button>
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={applicationList.list}
                pages={Math.ceil(applicationList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignSubmittersDownload,
        brandCampaignEngagementDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmittersTable);
