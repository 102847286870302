import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseLayout from '../../../../../../components/Layout/Brand/BaseLayout';
import { brandCreditsEvoucherProviderList, brandCreditsAddEvoucherProvider, brandCreditsEvoucherProviderUpdate, brandCreditsEvoucherProviderDelete } from '../../../../../../actions';
import Loader from '../../../../../../components/UIElems/Loader';
import notification from '../../../../../../components/UIElems/Notification';
import Table from '../../../../../../components/Common/Table';
import { Button, Popover } from 'antd';
import AddProviderModal from '../../../../../../components/Brand/Credits/Evoucher/Provider/Add/Modal';
import { useCountryOptions } from '../../../../../../hooks/generic';
import cx from 'classnames/bind';

const LogoPopover = ({imgSrc}) => {
    const imgPaths = imgSrc.split('/');
    let imgName = imgPaths[imgPaths.length-1];
    if (imgName.startsWith('10')) {
        imgName = imgName.substr(2);
    } else {
        imgName = imgName.substr(1);
    }
    const content = (
        <div>
            <img src={imgSrc} width={120} />
        </div>
    )
    return (
        <Popover title="Provider Logo" content={content} placement="bottom">
            <span style={{cursor: "pointer", color: "#249aff"}}>{imgName}</span>
        </Popover>
    )
}

const SubcategoryPopover = ({subcategories}) => {
    const maxLength = 15;
    const subString = subcategories.map(sub => sub.subcategoryName).join(',');
    const truncatedString = subString.length > maxLength? subString.substring(0, maxLength - 3) + '...': subString;
    const content = (
        <div>
            {subcategories.map(sub => <div>{sub.subcategoryName}</div>)}
        </div>
    )
    return (
        <Popover title="Provider Subcategories" content={content} placement="bottom">
            <span style={{cursor: "pointer", color: "#249aff"}}>{truncatedString}</span>
        </Popover>
    )
}

const All = props => {

    const [providerList, setProviderList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [isAddProviderModalVisible, setIsAddProviderModalVisible] = useState(false);
    const [editProvider, setEditProvider] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const [countryOptions, setCountryOptions] = useCountryOptions();

    useEffect(() => {
        setIsLoading(true)
        props.actions.brandCreditsEvoucherProviderList({
            page,
            pageSize
        }).then(data => {
            setIsLoading(false);
            if (!data.err) {
                setProviderList(data.data.list);
                setTotalCount(data.data.total);
            } else {
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
            }
        });
    }, [forceRefresh])

    const onFetchData = state => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setForceRefresh(!forceRefresh);
    }

    const onAddButtonClicked = () => {
        const newCountryOptions = countryOptions.map(country => ({
            ...country,
            selected: false
        }));
        setCountryOptions(newCountryOptions);
        setIsEdit(false);
        setIsAddProviderModalVisible(true);
    }

    const onEditButtonClicked = provider => {
        const countrySet = new Set(
            provider.providerCountries.map(country => country.id)
        );
        const newCountryOptions = countryOptions.map(country => ({
            ...country,
            selected: countrySet.has(country.value)
        }));
        setCountryOptions(newCountryOptions);
        setEditProvider({...provider});
        setIsEdit(true);
        setIsAddProviderModalVisible(true);
    }

    const onDeleteButtonClicked = providerId => {
        if (window.confirm('Are you sure you want to delete this eVoucher Provider?')) {
            props.actions.brandCreditsEvoucherProviderDelete(providerId)
            .then(data => {
                setIsLoading(false);
                if (!data.err) {
                    setForceRefresh(!forceRefresh);
                    notification.send({ device: props.rdx.appReducer.deviceType, message: 'Successfully deleted specified provider!', type: 'success' });
                } else {
                    notification.send({ device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error' });
                }
            });
        }
    }

    const onAddProvider = data => {
        setIsLoading(true);
        props.actions.brandCreditsAddEvoucherProvider(data)
        .then(data => {
            setIsLoading(false);
            if (!data.err) {
                setForceRefresh(!forceRefresh);
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Successfully added new provider!', type: 'success' });
            } else {
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error' });
            }
        });
    }

    const onUpdateProvider = data => {
        setIsLoading(true);
        props.actions.brandCreditsEvoucherProviderUpdate(data)
        .then(data => {
            setIsLoading(false);
            if (!data.err) {
                setForceRefresh(!forceRefresh);
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Successfully updated provider!', type: 'success' });
            } else {
                notification.send({ device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error' });
            }
        });
    }

    const sendNotification = (msg, type) => {
        notification.send({device: props.rdx.appReducer.deviceType, message: msg, type: type});
    }

    const columns = [
        {
            id: "providerName",
            Header: "Brand",
            accessor: "providerName",
            filterable: false,
            sortable: false
        },
        {
            id: "providerSubcategories",
            Header: "Category",
            accessor: "providerSubcategories",
            filterable: false,
            sortable: false,
            Cell: props => <SubcategoryPopover subcategories={props.value} />
        },
        {
            id: "providerCountries",
            Header: "Country",
            accessor: "providerCountries",
            filterable: false,
            sortable: false,
            Cell: props => <span>{`${props.value && props.value.length? props.value.map(country => country.code).join(','): ''}`}</span>
        },
        {
            id: "providerLogo",
            Header: "Provider Logo",
            accessor: "providerLogo",
            filterable: false,
            sortable: false,
            Cell: props => <LogoPopover imgSrc={props.value} />
        },
        {
            id: "edit",
            Header: "",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <button className={cx("credits-evoucher-provider-all-button", "credits-evoucher-provider-all-button-edit")} onClick={e => onEditButtonClicked(props.value)}>Edit</button>
        },
        {
            id: "delete",
            Header: "",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <button className={cx("credits-evoucher-provider-all-button", "credits-evoucher-provider-all-button-delete")} onClick={e => onDeleteButtonClicked(props.value.providerId)}>Delete</button>
        }
    ]

    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-credits">
                <h1>VIP Credits - eVoucher Provider List</h1>
                <Button type='primary' onClick={onAddButtonClicked}>+ Add Provider</Button>
            </div>
            <Table
                columns={columns}
                manual
                data={providerList}
                pages={Math.ceil(totalCount / pageSize)}
                loading={isLoading}
                onFetchData={onFetchData}
                defaultPageSize={10}
            />
            <AddProviderModal
                isAddProviderModalVisible={isAddProviderModalVisible}
                setIsAddProviderModalVisible={setIsAddProviderModalVisible}
                countryOptions={countryOptions}
                setCountryOptions={setCountryOptions}
                onAddProvider={onAddProvider}
                sendNotification={sendNotification}
                isEdit={isEdit}
                provider={editProvider}
                onUpdateProvider={onUpdateProvider}
            />
            { isLoading && <Loader /> }
        </BaseLayout>
    )
}

const mapStateToProps = (state, ownProps) => ({rdx: state})

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCreditsEvoucherProviderList,
        brandCreditsAddEvoucherProvider,
        brandCreditsEvoucherProviderUpdate,
        brandCreditsEvoucherProviderDelete
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(All);