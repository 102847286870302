import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import EmptyComponent from '../../../../components/Mob/Campaign/Empty';
import { translate } from 'react-i18next';

const Empty = (props) => {

    return (
        <div className="">
            <EmptyComponent t={props.t} /> 
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Empty));