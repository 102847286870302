import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames/bind';
import { createImagePreview } from '../../../../utils';
import { Icon, Popover } from 'antd';

const Add = ({onSubmit, t}) => {
    const [title, setTitle] = useState('');
    const [feedback, setFeedback] = useState('');
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
        createImagePreview(e.target.files[0], previewImage => setImagePreview(previewImage));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            title,
            feedback,
            image
        })
    }
    
    return (
        <div className={cx("")}>
            <form onSubmit={onFormSubmit}>
                <div className={cx("form-group")}>
                    <label htmlFor="">Title: </label>
                    <input required id="" type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="enter a title"/>
                </div>

                <div className={cx("form-group")}>            
                    <label htmlFor="">Feedback: </label>
                    <textarea required id="" type="text" value={feedback} onChange={e => setFeedback(e.target.value)} cols="20" rows="2">
                    </textarea>
                </div>
                
                <div>
                    <div className={cx("form-group")}>
                        <label htmlFor="image">Screenshot:</label>
                        <span className={cx("btn-choose-file")}>
                            <input required id="image" type="file" className={cx("choose-file")} onChange={onImageChange} />
                            <label htmlFor="">
                                <span>Choose File</span>
                                <Icon type="download" theme="filled" />
                            </label>
                        </span>
                        <span>Extension support : jpeg, jpg & png</span>
                    </div>
                    <div>
                        { imagePreview && <img src={imagePreview} className={cx("image-preview")} alt="image-preview" width="400" /> }
                    </div>
                </div>

                <div className={cx("card-button")}>
                    <button className={cx("btn", "btn-blue")} type="submit">Send</button>
                </div>
            </form>            
        </div>        
    );
}

export default Add;