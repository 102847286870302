import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandAdminAddAdmin, allBrands } from '../../../actions';
import AdminTable from './Table';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';
import Loader from '../../../components/UIElems/Loader';
import { Form, Button, Select, Drawer } from 'antd';
import { useCountryList } from '../../../hooks/generic';

const AddBrandAdmins = (props) => {
    /*let validator = {
        get: function(obj, key) {
            switch(key) {
                case 'brandId':
                    return obj[key] ? Number(obj[key]) : '';
                break;

                case 'email':
                    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                    if (obj[key] != '' && !reg.test(obj[key])) 
                    {
                        return '';
                    }
                break;
            }

            return key in obj ? obj[key] : '';
        },
        set: function(obj, key, value) {
            switch(key) {
                case 'brandId':
                    if(value != '' && !Number(value)) {
                        alert('Only Number');
                        return true;
                    }    
                break;
            }

            obj[key] = value;
            return true
        }
    }
    let paramsDefault = new Proxy({
        brandId: '',
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    }, validator) */

    const { Option } = Select;
    const [params, setParams] = useState({
        brandId: '',
        countryId: '',
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        cPassword: '',
        team:''
    });
    const countryList = useCountryList();
    const teamList = [
        {
            label: 'VIP Influencer',
            value: 'vip_influencer'
        },
        {
            label: 'VIP Panel',
            value: 'vip_panel'
        }
    ];
    const roleList = [
        {
            label: 'Super Admin',
            value: 1
        },
        {
            label: 'Admin',
            value: 2
        }
    ]

    const handleSetParams = (key, value) => {
        params[key] = value;
        setParams(params)
    }

    const onFinish = e => {
        e.preventDefault(); 

        if(!params.firstName || !params.email || !params.password) {
            alert(`${!params.firstName ? 'FirstName ' : ''}${!params.email ? 'Email ' : ''}${!params.password ? 'Password ' : ''}mandatory`);
            return false;    
        }

        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if(params.email && !reg.test(params.email)) {
            alert('Invalid email id');
            return false; 
        }

        if(params.password !== params.cPassword) {
            alert('Password do not match');
            return false; 
        }

        if(!params.countryId) {
            alert('Select Country');
            return false;    
        }

        if(!params.team) {
            alert('Select Team');
            return false;    
        }

        if(!params.role) {
            alert('Select Role');
            return false;    
        }

        if(params.countryId && params.team && params.firstName && params.email && params.password && params.role) {
            props.actions.brandAdminAddAdmin(1, params.firstName, params.lastName, params.email, params.password, params.countryId, params.team, params.role);
            props.submitCallBack();
        }
    };

    return (
        <Form 
            labelCol={{span: 8}}
            wrapperCol={{span: 12}}
            onSubmit={onFinish}
        >
            <Form.Item label="Country">
                <Select loading={countryList.length != 0 ? false : true} onChange={(value) => handleSetParams('countryId', value)}>
                    {
                        countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((row, index) => {
                            return(<Option key={index} value={row.id}>{row.name}</Option>)
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item label="Team">
                <Select loading={teamList.length != 0 ? false : true} onChange={(value) => handleSetParams('team', value)}>
                    {
                        teamList.map((row, index) => {
                            return(<Option key={index} value={row.value}>{row.label}</Option>)
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item label="First Name">
                <input type="text" defaultValue={params.firstName} onChange={e => handleSetParams('firstName',e.target.value)} />
            </Form.Item>            
            
            <Form.Item label="Last Name">
                <input type="text" defaultValue={params.lastName} onChange={e => handleSetParams('lastName',e.target.value)} />
            </Form.Item>
            
            <Form.Item label="Email">
                <input  type="text" defaultValue={params.email} onChange={e => handleSetParams('email',e.target.value)} />
            </Form.Item>
            
            <Form.Item label="Password">
                <input  type="password" defaultValue={params.password} onChange={e => handleSetParams('password',e.target.value)} />
            </Form.Item>

            <Form.Item label="Confirm Password">
                <input  type="password" defaultValue={params.cPassword} onChange={e => handleSetParams('cPassword',e.target.value)} />
            </Form.Item>

            <Form.Item label="Role">
                <Select loading={roleList.length != 0 ? false : true} onChange={(value) => handleSetParams('role', value)}>
                    {
                        roleList.map((row, index) => {
                            return(<Option key={index} value={row.value}>{row.label}</Option>)
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

const Admins = (props) => {

    const [showAddBrandAdminsModal, setShowAddBrandAdminsModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleSubmitCallBack = () => {
        setShowAddBrandAdminsModal(false);
        setRefresh(true);

        setTimeout(() => {
            setRefresh(false);
        }, 1000)
    }

    return (
        <BaseLayout leftCards={[]}>
            <div className="all-admins">
                <div className="flex flex-full-width">
                    <h1>Admins</h1>

                    <div style={{'textAlign': 'right', 'paddingTop': '10px'}}> 
                        <Button type="primary" onClick={() => setShowAddBrandAdminsModal(true)}>Add Admin</Button>
                    </div>
                </div>

                {
                    !refresh 
                    ?
                        <AdminTable conditions={{}} />
                        :
                        <Loader />
                }
            </div>

            <Drawer
                title="Add Admin"
                placement="right"
                closable={true}
                onClose={() => setShowAddBrandAdminsModal(false)}
                visible={showAddBrandAdminsModal}
                width="30%"
            >
                <AddBrandAdmins actions={props.actions} submitCallBack={handleSubmitCallBack}/>
            </Drawer>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandAdminAddAdmin
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
