import React, { useState } from 'react';
import cx from 'classnames/bind';
import { Button, Icon } from 'antd'

const Add = ({ evoucherProvider, providerList, onEvoucherProviderChanged, onTemplateDownloadButtonClicked, onFileUpload, onSubmit, onCancel }) => {

    const [fileNamePreview, setFileNamePreview] = useState();

    const onFileChanged = e => {
        setFileNamePreview(e.target.files[0].name);
        onFileUpload(e);
    }

    return (
        <div className={cx("form-vertical-row")}>
            <div className={cx("form-wrap")}>
                <label htmlFor="evoucher-provider-list">eVoucher Provider</label>
                <select id="evoucher-provider-list" value={evoucherProvider} onChange={onEvoucherProviderChanged}>
                    <option value="" hidden>Please Select</option>
                    {
                        providerList.map(provider => (
                            <option key={provider.providerId} value={provider.providerId}>{provider.providerName}</option>
                        ))
                    }
                    <option id="add-provider" key="add-provider" value="add-provider">+Add more</option>
                </select>
            </div>
            <div className={cx("form-wrap")}>
                <h3>
                    Download the template eVoucher excel file here: &nbsp;
                        <Button type="primary" style={{ backgroundColor: "#f5222d", outlineColor: "#f5222d", borderColor: "#f5222d" }} icon="download" onClick={onTemplateDownloadButtonClicked}>Download</Button>
                </h3>
            </div>
            <div className={cx("form-wrap")}>
                <label>
                    Upload eVoucher excel
                </label>
                <span className={cx("btn-choose-file")}>
                    <input className={cx("choose-file")}
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={onFileChanged}
                    />
                    <label>Choose File &nbsp; <span className={cx("upload-icon")}></span></label>
                </span>
                {
                    fileNamePreview &&
                    <span>{fileNamePreview}</span>
                }
            </div>

            <hr />

            <div className={cx("button-wrap")}>
                <button className={cx("btn", "btn-blue")} onClick={onSubmit}>Proceed</button>
                <button className={cx("btn", "btn-light")} onClick={onCancel}>Cancel</button>
            </div>
        </div>
    )

}

export default Add;