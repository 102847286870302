import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseLayout from '../../../../../components/Layout/Brand/BaseLayout';
import { Button } from 'antd'
import { brandCreditsEvoucherList, brandCreditsEvoucherListDownload } from '../../../../../actions'
import notification from '../../../../../components/UIElems/Notification';
import Loader from '../../../../../components/UIElems/Loader';
import Table from '../../../../../components/Common/Table';
import { useCountryList } from '../../../../../hooks/generic';
import cx from 'classnames/bind';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from "date-fns/locale/en";
import moment from 'moment';
import DescriptionPopover from '../../../../../components/Brand/Credits/Evoucher/DescriptionPopover';

const BrandCreditsEvoucherAll = props => {

    const [evoucherList,setEvoucherList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countryIdFilter, setCountryIdFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("1");
    const [dateUploadedFilter, setDateUploadedFilter] = useState();
    const [validTillFilter, setValidTillFilter] = useState();
    const [sorted, setSorted] = useState();
    const [tableFilters, setTableFilters] = useState();

    const countryList = useCountryList();

    const statusMap = {
        0: {
            label: 'Inactive',
            style: 'status-bg-red status-text-red status-style',
        },
        1: {
            label: 'Active',
            style: 'status-bg-green status-text-green status-style',
        },
        2: {
            label: 'Redeemed',
            style: 'status-bg-red status-text-red status-style',
        }
    }

    useEffect(() => {
        registerLocale("en", enGB);
    }, []);

    useEffect(() => {
        props.actions.brandCreditsEvoucherList({
            filters: {
                countryId: countryIdFilter,
                status: statusFilter,
                dateUploaded: dateUploadedFilter? moment(dateUploadedFilter).format('YYYY-MM-DD'): undefined,
                endDate: validTillFilter? moment(validTillFilter).format('YYYY-MM-DD'): undefined
            },
            page,
            pageSize,
            sorted,
            tableFilters
        })
        .then(data => {
            if (data && !data.err) {
                setEvoucherList(data.data.list);
                setTotalRows(data.data.total);
            } else {
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
            }
        });
    }, [forceRefresh]);

    const onProviderListButtonClicked = () => {
        window.location = '/brand/credits/evoucher/provider/all';
    }

    const onAddButtonClicked = () => {
        window.location = '/brand/credits/evoucher/add'
    }

    const onFetchData = state => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setTableFilters(state.filtered);
        setForceRefresh(!forceRefresh)
    }

    const onDownload = () => {
        if (window.confirm('Download?')) {
            props.actions.brandCreditsEvoucherListDownload({
                filters: {
                    countryId: countryIdFilter,
                    status: statusFilter,
                    dateUploaded: dateUploadedFilter? moment(dateUploadedFilter).format('YYYY-MM-DD'): undefined,
                    endDate: validTillFilter? moment(validTillFilter).format('YYYY-MM-DD'): undefined
                },
                page,
                pageSize,
                sorted,
                tableFilters
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Evoucher_List.csv`);
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    const columns = [
        {
            id: "code",
            Header: "eVoucher Code",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <DescriptionPopover code={props.value.code} description={props.value.description} />
        },
        {
            id: "value",
            Header: "Value",
            accessor: "value",
            filterable: false,
            sortable: false
        },
        {
            id: "equivalentCredits",
            Header: "Eq. Credits",
            accessor: "equivalentCredits",
            filterable: false,
            sortable: false
        },
        {
            id: "providerName",
            Header: "Provider",
            accessor: "providerName",
            filterable: false,
            sortable: false
        },
        {
            id: "subcategoryName",
            Header: "Subcategory",
            accessor: "subcategoryName",
            filterable: false,
            sortable: false,
        },
        {
            id: "country",
            Header: "Country",
            accessor: "country",
            filterable: false,
            sortable: false
        },
        {
            id: "status",
            Header: "Status",
            accessor: "status",
            filterable: false,
            sortable: false,
            Cell: props => <span className={cx(statusMap[props.value]['style'])}>{statusMap[props.value]['label']}</span>
        },
        {
            id: "redeemedBy",
            Header: "Redeemed By",
            accessor: "redeemedBy",
            filterable: true,
            sortable: false
        },
        {
            id: "createdAt",
            Header: "Date Uploaded",
            accessor: "createdAt",
            filterable: false,
            sortable: true
        },
        {
            id: "redeemedOn",
            Header: "Date Redeemed",
            accessor: "redeemedOn",
            filterable: false,
            sortable: true
        },
        {
            id: "endDate",
            Header: "Valid Till",
            accessor: "endDate",
            filterable: false,
            sortable: true
        }
    ]

    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-credits">
                <h1>VIP Credits - eVoucher</h1>
                <Button type='primary' onClick={onAddButtonClicked}>+ Upload eVoucher excel</Button>
                <Button type='primary' onClick={onProviderListButtonClicked}>Provider List</Button>
            </div>
            <div className={cx("form-horizontal-row")}>
                <div className="form-vertical-row">
                    <div className={cx("form-wrap")}>
                        <label htmlFor="country-list">Country</label>
                        <select id="country-list" value={countryIdFilter} onChange={e => setCountryIdFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                countryList.filter(country => [100, 129, 169, 192, 211, 232].indexOf(country.id) > -1).map((country, i) => {
                                    return <option value={country.id} key={i}>{country.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className={cx("form-vertical-row")}>
                    <div className={cx("form-wrap")}>
                        <label htmlFor="status-list">Status</label>
                        <select id="status-list" value={statusFilter} onChange={e => setStatusFilter(e.target.value)} >
                            <option value="">All</option>
                            {
                                Object.keys(statusMap).map((key, i) => {
                                    return <option value={key} key={i}>{statusMap[key]['label']}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className={cx("form-vertical-row")}>
                    <div className={cx("form-wrap")}>
                        <label htmlFor="date-uploaded">Date Uploaded</label>
                        <DatePicker
                            locale={"en"}
                            selected={dateUploadedFilter}
                            onChange={setDateUploadedFilter}
                            isClearable
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                <div className={cx("form-vertical-row")}>
                    <div className={cx("form-wrap")}>
                        <label htmlFor="valid-till">Valid Till</label>
                        <DatePicker
                            locale={"en"}
                            selected={validTillFilter}
                            onChange={setValidTillFilter}
                            isClearable
                            dateFormat="dd-MM-yyyy"
                            minDate={dateUploadedFilter}
                        />
                    </div>
                </div>
                <div className={cx("form-vertical-row")}>
                    <div className={cx("form-wrap")}>
                        <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceRefresh(!forceRefresh)}>Fetch</button>
                    </div>
                </div>
                <div className={cx("form-vertical-row")}>
                    <div className={cx("form-wrap")}>
                        <button type="button" className={cx("btn", "btn-danger")} onClick={onDownload}>Download</button>
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                manual
                data={evoucherList}
                pages={Math.ceil(totalRows / pageSize)}
                loading={isLoading}
                onFetchData={onFetchData}
                defaultPageSize={10}
            />
            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state})

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCreditsEvoucherList,
        brandCreditsEvoucherListDownload
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandCreditsEvoucherAll);