import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignLinkStats } from '../../../../../hooks/generic';
import { brandCampaignLinkStatsDownload } from '../../../../../actions';
import Table from '../../../../../components/Common/Table';
import MenuPopper from '../../../../../components/UIElems/MenuPopperAntd';

const LinkStatsTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [conditions, setConditions] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);

    const linkStatsList = useBrandCampaignLinkStats(
        () => setLoading(false),
        props.dispatch,
        props.campaignId,
        page,
        pageSize,
        sorted,
        filtered,
        conditions,
        forceUpdate
    );
    
    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        // if(state.filtered.length && state.filtered[0]['value'].length < 2) return false;
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const onDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandCampaignLinkStatsDownload(props.campaignId)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `link-stats.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = [
        {
            Header: "Short Url",
            accessor: "shortUrl",
            filterable: false,
            sortable: false,
            Cell: props => <a href={'https://tap.red/' + props.value} target="_blank">{'https://tap.red/' + props.value}</a>
        },
        {
            Header: "Long Url",
            accessor: "longUrl",
            filterable: false,
            sortable: false,
            Cell: props => <a href={props.value} target="_blank">{props.value}</a>
        },
        {
            Header: "Clicks",
            accessor: "clicks",
            filterable: false,
            sortable: false,
            Footer: "Total: " + linkStatsList.totalClicks
        }
    ];

    return (
        <div>
            <div>
                <button type="button" className={cx("btn", "btn-danger")} onClick={e => onDownload()}>Download</button>
            </div>

            <div>&nbsp;</div>

            <Table
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                columns={columns}
                manual
                data={linkStatsList.list}
                pages={Math.ceil(linkStatsList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignLinkStatsDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkStatsTable);