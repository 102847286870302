import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';

const Placeholder = ({image, children, t}) => {
    return (
        <div className={cx("Placeholder-wrapper")}>
            <div>
               <img src={image} alt="placeholder"/>
            </div>

            { children }
        </div>
    );
}

export default translate()(Placeholder);