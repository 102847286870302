import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { genericAction, brandProfilePostsDownload } from '../../../../actions';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import ProfileMetricsComponent from '../../../../components/Brand/Other/ProfileMetrics';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';

const ProfileMetrics = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const taskCaller = (data) => {
        if(window.confirm('Queue task?')) {
            setIsLoading(true);
            props.actions.genericAction('brandProfileMetricsCollect', [data])
            .then(() => {
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Queued!', type: 'success'});
                setIsLoading(false);
            })
        }
    }

    const onProfilePostsDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandProfilePostsDownload()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `posts.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    } 

    return (
        <div>
            <ProfileMetricsComponent taskCaller={taskCaller} />

            <hr />

            <div>
                 <button type="button" className={cx("btn", "btn-danger")} onClick={e => onProfilePostsDownload()}>Download Profile Posts</button>
            </div>

            { isLoading && <Loader /> }
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
        brandProfilePostsDownload
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMetrics);
