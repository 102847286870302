import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ApplicationsTable from './Table';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';

const InfluencerApplied = (props) => {
    return (
        <BaseLayout leftCards={[]}>
            <div className="campaigns-applied">
                <h1>Currently Applied</h1>
                <ApplicationsTable influencerId={props.rdx.influencerReducer.id}
                    conditions={[]} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerApplied);