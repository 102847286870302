import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {Button} from "primereact/button";
import {Panel} from 'primereact/panel';
import {Icon} from '../../../../Common/Icon';

export const ListItem = ({data, onPostApprove, feedbackElem, onShowClick}) => {
    return (
        <div className="p-col-12">
            <div className="data-details">
                <div>
                    <img src={data.image} alt={data.name} width="200" />
                    <div className="p-grid">
                        <div className="p-col-12">Name: <b>{data.fullname}</b></div>
                        <div className="p-col-12">{data.type}: <b>{(data.caption.length > 100) ? data.caption.slice(0, 100) + ' ...' : data.caption}</b></div>
                    </div>
                </div>
                <div className={cx("btn-action-wrap")}>
                    <Button className={cx("p-button-success")} icon="pi pi-check" onClick={e => onPostApprove(data, 1)} title="Approve"></Button>
                    <Button icon="pi pi-search" onClick={e => onShowClick(data, true)} title="Show"></Button>
                    <Button className={cx("p-button-danger")} icon="pi pi-times" onClick={e => onPostApprove(data, 0)} title="Decline"></Button>
                    {feedbackElem}
                </div>
            </div>
        </div>
    );
}

export const GridItem = ({data, onPostApprove, feedbackElem, onShowClick}) => {
    const statusText = {
        0: 'declined',
        1: 'approved',
        2: 'pending',
    };
    const statusColor = {
        0: 'red',
        1: 'green',
        2: 'orange',
    };
    const icons = {
        'insta': <Icon name="icon-brand-insta" />,
        'fb': <Icon name="icon-brand-fb" />,
        'yt': <Icon name="icon-brand-yt" />,
        'twitter': <Icon name="icon-brand-twitter" />
    };
    const Header = ({data}) => {
        return (
            <span className={cx("post-approval-header")}>
                {icons[data.type]}
                {data.name}
            </span>
        );
    }

    return (
        <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
            <div>
                <div className="">
                    <div className="p-col-12">Name: <b>{data.fullname} ({data.email})</b></div>
                </div>
            </div>
            <Panel header={<Header data={data} />} className={cx(statusText[data.isApproved])}>
                <img src={data.image} alt={data.name} width="200" />
                <div className="data-detail">{(data.caption.length > 100) ? data.caption.slice(0, 100) + ' ...' : data.caption}</div>
                <div className={cx("btn-action-wrap")}>
                    <Button className={cx("p-button-success")} icon="pi pi-check" onClick={e => onPostApprove(data, 1)} title="Approve"></Button>
                    <Button icon="pi pi-search" onClick={e => onShowClick(data, true)} title="Show"></Button>
                    <Button className={cx("p-button-danger")} icon="pi pi-times" onClick={e => onPostApprove(data, 0)} title="Decline"></Button>
                    {feedbackElem}
                </div>
            </Panel>
        </div>
    );
}

export const DialogContent = ({selectedCard}) => {
    if(selectedCard) {
        return (
            <div className={cx("p-grid", "post-approval-modal")} style={{fontSize: '16px', textAlign: 'center', padding: '20px'}}>
                <div className="p-col-12" style={{textAlign: 'center'}}>
                    <img src={selectedCard.image} alt={selectedCard.name} width="400" />
                </div>

                <div className="p-col-12">{selectedCard.type}: {selectedCard.caption}</div>
            </div>
        );
    }
    else {
        return null;
    }
}