import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, text } from 'recharts';
import { getRandomHexColor } from '../../../../utils';

const BarChart = (props) => {
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);
    const [colorByKey, setColorByKey] = useState(null);

    const chartRef = useRef(null);

    const transformData = (data) => {
        let mData = [];
        if(data.length) {
            //keys
            let keys = data[0].axisAgainstKeys.split(', ');
            setKeys(keys);
            //data points
            mData = data.map(d => {
                let r = {};
                for (let i in keys) {
                    r['name'] = d['axisBaseValue'];
                    r[keys[i]] = d[keys[i]];
                    setColorByKey(prev => ({ ...prev, [keys[i] + 'Color']: d[keys[i] + 'Color'] }))
                }
                r['total'] = d['total'];
                return r;
            });
        }
            setData(mData);
    }

    //transform data
    useEffect(() => {
        transformData(props.data);
    }, [props.refresh]);

    const CustomizedLabel = (props) => {            
        return <text x={props.x} y={props.y} dy={-4} fill={'#8884d8'} fontSize={15} textAnchor="middle">Total: {props.value}</text>
    }

    return (
        <ResponsiveContainer>
            <ComposedChart data={data}
                margin={{top: 20, right: 80, bottom: 20, left: 20}}>                    
                <XAxis dataKey="name" padding={{left: 40, right: 20}} />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid stroke='#f5f5f5'/>
                {
                    keys.map(d => {
                        return <Bar dataKey={d} barSize={40} fill={colorByKey[d + 'Color']} stackId="a" />
                    })
                    
                }
                <Line type="monotone"
                    dataKey="total"
                    stroke="#ffffff"
                    label={<CustomizedLabel />}
                    legendType={'none'}
                    dot={false}
                    activeDot={false}
                    strokeWidth={0} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default BarChart;

