import React from 'react';
import { useState } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useCountryList, usePanelList } from '../../../../hooks/generic';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import { brandPanelActivate } from '../../../../actions';

const PanelList = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isActiveFilter, setIsActiveFilter] = useState('');
    const [countryId, setCountryId] = useState('');
    
    //conditions
    const conditions = [ 
        ...('conditions' in props && props.conditions)
    ];
    //extra
    const extra = {
        ...('extra' in props && props.extra),
        isActive: isActiveFilter,
        countryId
    };

    const countryList = useCountryList();

    const panelList = usePanelList(
        () => setLoading(false),
        props.dispatch,
        props.rdx.brandReducer.brandId,
        page,
        pageSize,
        sorted,
        filtered,
        conditions,
        extra,
        false,
        false,
        false,
        forceUpdate
    );

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const panelActivate = (panelId, flag) => {
        props.actions.brandPanelActivate(panelId, flag)
        .then(() => setForceUpdate(!forceUpdate));
    }

    const createActionLink = (rowData) => {
        let links = [];
        let id = rowData.id;
        links.push({title: 'Home', handler: () => window.location = `/brand/panel/${id}/home`});
        links.push({title: 'Edit', handler: () => window.location = `/brand/panel/${id}/edit`});
        links.push({title: 'Filter', handler: () => window.location = `/brand/panel/${id}/filter`});
        links.push({title: 'Preview', handler: () => window.location = `/brand/panel/${id}/preview`});
        if(rowData.isActiveByDate) {
            links.push({
                title: rowData.isActive ? 'Deactivate' : 'Activate',
                handler: () => panelActivate(id, rowData.isActive ? 0 : 1)
            });
        }

        return <MenuPopper title='Manage' links={links} />
    }

    const columns = [
        {
            id: "clientName",
            Header: "Client",
            accessor: "client_name",
            filterable: true,
            sortable: false,
            Footer: "Total: " + panelList.total
        },
        {
            id: "brandName",
            Header: "Brand",
            accessor: "brand_name",
            filterable: true,
            sortable: false,
        },
        {
            id: "jobName",
            Header: "Panel Title",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props =>  <a href={`/brand/panel/${props.value.id}/home`}>{props.value.jobName}</a>
        },
        {
            Header: "Country",
            accessor: "countryCode",
            filterable: false,
            sortable: false
        },
        {
            Header: "Created By",
            accessor: "createdByName",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Last Edited By",
            accessor: "editedByName",
            filterable: false,
            sortable: false
        },
        {
            Header: "Last Updated At",
            accessor: "updated_at",
            filterable: false,
            sortable: false,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Is Active",
            accessor: "isActive",
            filterable: false,
            sortable: false,
            Cell: props => 
                (props.value == 1)
                ? <span className={cx("status-bg-green", "status-text-green", "status-style")}>Yes</span>
                : <span className={cx("status-bg-red", "status-text-red", "status-style")}>No</span>
        },
        {
            Header: "Start Date",
            accessor: "start_date",
            filterable: false,
            sortable: true,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "End Date",
            accessor: "end_date",
            filterable: false,
            sortable: true,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Manage",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div className="">
            <div className="user-filters">
                <div className="form-horizontal-row">
                    <div className="form-wrap">
                        <label htmlFor="country-list">Country: </label>
                        <select id="country-list" value={countryId} onChange={e => setCountryId(e.target.value)} >
                            <option value="">All</option>
                            {
                                countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                    return <option value={country.id} key={i}>{country.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="form-wrap">
                        <label htmlFor="is-active">Is Active: </label>
                        <select id="is-active" value={isActiveFilter} onChange={e => setIsActiveFilter(e.target.value)}>
                            <option value="">select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                    <div className="form-wrap">
                        <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceUpdate(!forceUpdate)}>Fetch</button>
                    </div>
                </div>
            </div>                
            <Table
                columns={columns}
                manual
                data={panelList.list}
                pages={Math.ceil(panelList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandPanelActivate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PanelList);
