import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandInfluencerFeedback } from '../../../../hooks/generic';
import {} from '../../../../actions';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import notification from '../../../../components/UIElems/Notification';
import { format } from 'date-fns';

const FeedbackTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const feedbackList = useBrandInfluencerFeedback(
        () => setLoading(false),
        props.dispatch,
        page,
        pageSize,
        sorted,
        filtered,
        {},
        forceUpdate
    );

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        // if(state.filtered.length && state.filtered[0]['value'].length < 2) return false;
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        //
        // if(rowData.adminType == 1) {
        //     links.push({title: 'No Action Available', handler: () => {}});
        // }       
        return <MenuPopper title='Manage' links={links} />
    }

    const columns = [
        {
            Header: "Name",
            accessor: "name",
            filterable: false,
            sortable: false
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: false,
            sortable: false
        },
        {
            Header: "Title",
            accessor: "title",
            filterable: false,
            sortable: false
        },
        {
            Header: "Feedback",
            accessor: "feedback",
            filterable: false,
            sortable: false
        },
        {
            Header: "Screenshot",
            accessor: "image",
            filterable: false,
            sortable: false,
            Cell: props => <a href={props.value} target="_blank">screenshot</a>
        },
        {
            Header: "Date",
            accessor: "createdAt",
            filterable: false,
            sortable: false,
            Cell: props => format(props.value, 'DD-MM-YYYY')
        },
        {
            Header: "Action",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>

            <div className="filters">
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                manual
                data={feedbackList.list}
                pages={Math.ceil(feedbackList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTable);
