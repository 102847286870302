import React, { useState} from 'react';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import {connect} from 'react-redux';
import EditForm from '../../../../components/Brand/Panel/Form';
import { brandPanelEdit } from '../../../../actions';
import { bindActionCreators } from 'redux';
import Loader from '../../../../components/UIElems/Loader';
import CurrentPanelSidebar from '../../../../components/Brand/Sidebar/CurrentPanel';
import notification from '../../../../components/UIElems/Notification';

const Edit = props => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = data => {
        if (data.panelType && data.panelType === 'poll') {
            const validPolls = data.pollIds && data.pollIds.length > 0 ?
                data.pollIds.filter(poll => !poll.id || (poll.id && poll.status)) :
                [];
            if (!validPolls.length) {
                return notification.send({device: props.rdx.appReducer.deviceType, message: 'Please add atleast 1 poll to the job!', type: 'error'});
            }
        }
        data['id'] = props.match.params.id;
        setIsLoading(true);
        props.actions.brandPanelEdit(data).then(res => {
            setIsLoading(false);
            if(!res.err) window.location = `/brand/panel/${props.match.params.id}/filter`;
        })
    }

    return (
        <BaseLayout leftCards={[
            <CurrentPanelSidebar panelId={props.match.params.id} currentTitle='Edit'/>
        ]}>
            <div className="jobs-panel-create">
                <h1>Edit Panel Job - {props.rdx.brandReducer.currentPanel.jobName}</h1>
                <EditForm 
                    onSubmit={onSubmit}
                    values={props.rdx.brandReducer.currentPanel}
                    type="edit"
                />
            </div>

            { isLoading && <Loader /> }
        </BaseLayout>

    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state});

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandPanelEdit
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit);