import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useBrandCampaignApplications } from '../../../../hooks/generic';
import { brandCampaignInfluencerApprove, brandCampaignInfluencersApprove, brandCampaignApplicationsDownload } from '../../../../actions';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import { Modal, Badge } from 'antd';
import notification from '../../../../components/UIElems/Notification';
import RatingPopover from '../../Grading/Popover';
import ScreeningFilters from './ScreeningFilters';

const CustomDeclineModal = ({ rowData, flag, callback }) => {
    const [isCustomDeclineVisible, setIsCustomDeclineVisible] = useState(false);
    const [isCustomDeclineLoading, setIsCustomDeclineLoading] = useState(false);
    const [customDeclineText, setCustomDeclineText] = useState("");

    const onClick = () => {
        // if(window.confirm('Remind user about social accounts?')) {
        setIsCustomDeclineVisible(true);
        // }
    }

    const onOk = () => {
        if (!customDeclineText.length) return alert("You need to specify the reason for declining the user!");
        setIsCustomDeclineLoading(true);
        callback(rowData.campaignId, rowData.influencerId, flag, customDeclineText);
    }

    return (
        <span>
            <a onClick={onClick} style={{ color: 'inherit' }}>Decline</a>
            <Modal
                title={'Reason for declining?'}
                visible={isCustomDeclineVisible}
                okText="Send"
                onOk={onOk}
                confirmLoading={isCustomDeclineLoading}
                onCancel={e => setIsCustomDeclineVisible(false)}
                maskClosable={false}>
                <textarea value={customDeclineText} onChange={e => setCustomDeclineText(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

const SelectApplicantsModal = ({ selectedInfluencerIds, applicationList, setSelectedInfluencerIds }) => {
    const [isApplicantModalVisible, setIsApplicantModalVisible] = useState(false);
    const [customCheckBoxValue, setCustomCheckBoxValue] = useState(false);

    const onClick = () => {
        setIsApplicantModalVisible(true);
    }

    const onCancel = () => {
        let updatedSelectedInfluencerIds = selectedInfluencerIds;
        applicationList.list.forEach(l => {
            updatedSelectedInfluencerIds = { ...updatedSelectedInfluencerIds, [l.influencerId]: false };
        });
        setSelectedInfluencerIds(updatedSelectedInfluencerIds);
        setIsApplicantModalVisible(false);
        setCustomCheckBoxValue(false);
    }

    const onOk = () => {
        let updatedSelectedInfluencerIds = selectedInfluencerIds;
        applicationList.list.forEach(l => {
            if (l.isApproved === 2) {
                updatedSelectedInfluencerIds = { ...updatedSelectedInfluencerIds, [l.influencerId]: true };
            }
        });
        setSelectedInfluencerIds(updatedSelectedInfluencerIds);
        setIsApplicantModalVisible(false);
        setCustomCheckBoxValue(true);
    };

    return (
        <span>
            <input type="checkbox"
                onClick={onClick} checked={customCheckBoxValue} style={{ color: 'inherit' }} />
            <Modal
                title={'Do you want to select all pending applications?'}
                visible={isApplicantModalVisible}
                okText="Yes"
                onOk={onOk}
                cancelText="No"
                onCancel={onCancel}
                maskClosable={false}>
                <span>If selected Yes, all the pending application will be selected and if selected No applications on the screen will be selected</span>
            </Modal>
        </span>
    );
}

const ApplicationsTable = (props) => {
    const [screeningQuestions, setScreeningQuestions] = useState(props.campaignDetails && props.campaignDetails.screeningQuestions);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [approvalFilter, setApprovalFilter] = useState(null);
    const [requiredAnswers, setRequiredAnswers] = useState({});
    const [selectedInfluencerIds, setSelectedInfluencerIds] = useState({});
    let [applicationList, setApplicationList] = useBrandCampaignApplications(
        () => setLoading(false),
        props.dispatch,
        props.brandId,
        props.campaignId,
        page,
        pageSize,
        sorted,
        filtered,
        [
            ...props.conditions,
            ...(approvalFilter ? [{ field: 'tapfluencer_campaign_influencer.is_approved', val: approvalFilter, requiredAnswers: { ...requiredAnswers } }] : [{ requiredAnswers: { ...requiredAnswers } }])
        ],
        'extra' in props ? props.extra : {},
        forceUpdate
    );


    const removeTag = (tagId) => {
        delete requiredAnswers[tagId];
        setRequiredAnswers({ ...requiredAnswers });
    }

    const setRequiredAnswersForFilters = (action, opt) => {
        let reqAnswrs = requiredAnswers;
        if (action === 'add') {
            reqAnswrs[opt.id] = opt.value;
        } else if (action === 'delete') {
            delete reqAnswrs[opt.id];
        } else if (action === 'clear') {
            reqAnswrs = {};
        }
        setRequiredAnswers({ ...reqAnswrs });
    }

    useEffect(() => {
        applicationList = applicationList;
    }, [reRender]);

    useEffect(() => { //todo: REMOVE USEEFFECT
        setScreeningQuestions(props.campaignDetails && props.campaignDetails.screeningQuestions);
    }, [props.campaignDetails]);

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for (let k in state.filtered) {
            if (state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if (state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const approveApplication = (campaignId, influencerId, flag, reason = "") => {
        props.actions.brandCampaignInfluencerApprove(campaignId, influencerId, flag, reason)
            .then((res) => {
                if(res.err){
                    notification.send({ device: 'desktop', message: res.msg, type: 'error' });
                } else {
                    notification.send({ device: 'desktop', message: 'Done!', type: 'success' });
                    for (const index in applicationList.list) {
                        let element = applicationList.list[index];
                        if (element.influencerId === influencerId) {
                            element.isApproved = flag;
                            applicationList.list[index] = element;
                            break;
                        }
                    }
    
                    // setForceUpdate(!forceUpdate);
                    setReRender(!reRender);
                    setApplicationList(applicationList);
                }
            });
    }

    const approveAllApplications = (campaignId, influencerIdList, flag) => {
        props.actions.brandCampaignInfluencersApprove(campaignId, influencerIdList, flag)
            .then(() => {
                setForceUpdate(!forceUpdate);
                setSelectedInfluencerIds({});
            });
    }

    const createActionLink = (rowData) => {
        let links = [];
        //view profile
        links.push({ title: 'View Profile', handler: () => window.open(`/brand/user/${rowData.influencerId}?tab=2`, '_blank') });
        //approve application
        links.push({ title: 'Approve', handler: () => approveApplication(rowData.campaignId, rowData.influencerId, 1) });
        //decline application
        let declineLink =
            <CustomDeclineModal rowData={rowData}
                flag={0}
                callback={approveApplication} />;
        links.push({ title: 'Decline', extraElem: declineLink });

        return <MenuPopper title='Manage' links={links} />
    }


    const applicationStatus = (isApproved) => {
        const status = {
            0: 'Declined',
            1: 'Approved',
            2: 'Pending'
        }
        const color = {
            0: ["status-bg-red", "status-text-red", "status-style"],
            1: ["status-bg-green", "status-text-green", "status-style"],
            2: ["status-bg-orange", "status-text-orange", "status-style"]
        }

        return <span className={cx(color[isApproved])}>{status[isApproved]}</span>;
    }

    const multiSelectInputHandler = (props) => {
        const onClick = () => {
            if (selectedInfluencerIds[props.influencerId]) {
                setSelectedInfluencerIds({ ...selectedInfluencerIds, [props.influencerId]: false });
            } else {
                setSelectedInfluencerIds({ ...selectedInfluencerIds, [props.influencerId]: true });
            }
        }

        return <input type="checkbox" onClick={onClick} defaultChecked={!!selectedInfluencerIds[props.influencerId]} />;
    }

    const BulkApproveDecline = () => {
        return <span>
            <button type="button" className={cx("btn", "btn-blue")} style={{ marginLeft: '10px' }} onClick={e => approveAllApplications(props.campaignId, Object.keys(selectedInfluencerIds).filter(k => selectedInfluencerIds[k]), 1)}>Approve</button>
            <button type="button" className={cx("btn", "btn-danger")} style={{ marginLeft: '10px' }} onClick={e => approveAllApplications(props.campaignId, Object.keys(selectedInfluencerIds).filter(k => selectedInfluencerIds[k]), 0)}>Decline</button>
        </span>
    }

    const onDownload = () => {
        if (window.confirm('Download?')) {
            props.actions.brandCampaignApplicationsDownload(
                props.brandId,
                props.campaignId,
                sorted,
                filtered,
                [
                    ...props.conditions,
                    ...(approvalFilter ? [{ field: 'tapfluencer_campaign_influencer.is_approved', val: approvalFilter }] : [])
                ],
                props.extra ? props.extra : {}
            )
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `applications.csv`);
                    document.body.appendChild(link);
                    link.click();
                })
        }
    }

    const UserLink = ({ rowData }) => {
        return (
            rowData.isNew == 1
                ?
                <Badge count="new" style={{ backgroundColor: 'green', fontSize: '10px', padding: '0 6px' }} offset={[13, -5]} title="this user's first campaign">
                    <a target="_blank" href={`/brand/user/${rowData.influencerId}?tab=2`}>
                        {rowData.fullname}
                    </a>
                </Badge>
                :
                <a target="_blank" href={`/brand/user/${rowData.influencerId}?tab=2`}>
                    {rowData.fullname}
                </a>
        );
    }

    const columns = [
        {
            Header: <SelectApplicantsModal selectedInfluencerIds={selectedInfluencerIds} applicationList={applicationList} setSelectedInfluencerIds={setSelectedInfluencerIds} />,
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => multiSelectInputHandler(props.value),
            Footer: "Total: " + applicationList.total
        },
        {
            id: "fullname",
            Header: "Name",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props => <UserLink rowData={props.value} />
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: true,
            sortable: false
        },
        {
            Header: "Average Rating",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <RatingPopover data={props.value} />
        },
        {
            Header: "Applied On",
            accessor: "appliedOn",
            filterable: false,
            sortable: false,
            Cell: props => format(props.value, 'DD-MM-YYYY')
        },
        {
            Header: "Application Status",
            accessor: "isApproved",
            filterable: false,
            sortable: true,
            Cell: props => applicationStatus(props.value)
        },
        ...(
            props.includeCampaignName
                ?
                [{
                    Header: "Campaign Name",
                    accessor: "campaignName",
                    filterable: false,
                    sortable: false
                }]
                :
                []
        ),
        {
            Header: "Campaign Deadline",
            accessor: "deadlineDate",
            filterable: false,
            sortable: true,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        }
    ];

    if (props.campaignDetails && props.campaignDetails.screeningQuestions) {
        columns.push({
                expander: true,
                Header: () => <h2 style={{marginTop:'4px'}}>Screening Answers</h2>,
                Expander: ({ isExpanded, ...rest }) =>
                  <div>
                    {isExpanded
                      ? <span>Check Answers &#9650;</span>
                      : <span>Check Answers &#9660;</span>}
                  </div>,                              
                width: 170,
                style: {
                  cursor: "pointer",
                  color: "#249AFF"
                }
              });
    } else {
        columns.push({
            Header: "Manage",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        });
    }

    const onApprovalFilter = (value) => {
        setApprovalFilter(value);
        setForceUpdate(!forceUpdate);
    }

    const resposeCardBuilder = (response, id) => {
        let answers = '';
        if(response.options && response.options.length > 0) {
            answers = response.options.map(op => op.value).join(" - ");
        } else {
            answers = response.answer;
        }
        return (
            <div className="answer-answer" key={`ans-${id}`}>
                <h4><span className="question-number">Q{id}</span>. {response.question}</h4>
                <div>Ans: {answers}</div>
            </div>
        )
    }

    const subComponentCard = (rowData) => {
        if (props.campaignDetails && props.campaignDetails.screeningQuestions) {
            let responseArr = [];
            if (rowData.original.answeredQuestions) {
                responseArr = JSON.parse(rowData.original.answeredQuestions).answeredQuestions;
            }
            let renderable;
            if (responseArr.length < 5) {
                renderable = [];
                for(let counter = 0; counter < responseArr.length; ++counter) {
                    renderable.push(resposeCardBuilder(responseArr[counter], counter + 1));
                }
            } else {
                let leftRenderable = [];
                for(let counter = 0; counter < responseArr.length; counter+=2) {
                    leftRenderable.push(resposeCardBuilder(responseArr[counter], counter + 1));
                }
                let rightRenderable = [];
                for(let counter = 1; counter < responseArr.length; counter+=2) {
                    rightRenderable.push(resposeCardBuilder(responseArr[counter], counter + 1));
                }

                renderable = (
                    <div className='response-divider'>
                        <div className='left-side'>
                            { leftRenderable }
                        </div>
                        <div className='right-side'>
                            { rightRenderable }
                        </div>
                    </div>
                )

            }
            return <div className="answer-div">
                {
                    renderable
                }
                <div className='btn-holder'>
                    <button type="button" className={cx("btn", "btn-danger")} style={{ marginLeft: '10px' }} onClick={() => {
                        approveApplication(rowData.original.campaignId, rowData.original.influencerId, 0)
                    }}>Decline</button>
                    <button type="button" className={cx("btn", "btn-blue")} style={{ marginLeft: '10px' }} onClick={() => {
                        approveApplication(rowData.original.campaignId, rowData.original.influencerId, 1)
                    }}>Approve</button>
                </div>
            </div>
        } else {
            return null;
        }
    }

    return (
        <div>

            {props.showApplicationStatusFilter &&
                <div>
                    {screeningQuestions && <ScreeningFilters
                        screeningQuestions={screeningQuestions}
                        requiredAnswers={requiredAnswers}
                        forceUpdate={forceUpdate}
                        removeTag={removeTag}
                        setRequiredAnswers={setRequiredAnswersForFilters}
                        setForceUpdate={setForceUpdate}
                    />}
                </div>}
            <div className="filters">
                {
                    <div className="form-horizontal-row" style={{ justifyContent: 'space-between' }}>
                        <div className="form-wrap">
                            <label htmlFor="is-approved">Application Status: </label>
                            <select id="is-approved" name="is_approved" onChange={e => onApprovalFilter(e.target.value)}>
                                <option value="">All</option>
                                <option value="0">Declined</option>
                                <option value="1">Approved</option>
                                <option value="2">Pending</option>
                            </select>
                            {
                                !!Object.keys(selectedInfluencerIds).filter(k => selectedInfluencerIds[k]).length && <BulkApproveDecline />
                            }
                        </div>
                        <div className="form-wrap">
                            <button type="button" className={cx("btn", "btn-danger")} style={{ marginLeft: 'auto' }} onClick={e => onDownload()}>Download</button>
                        </div>
                    </div>
                }

            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                manual
                data={applicationList.list}
                pages={Math.ceil(applicationList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                SubComponent={subComponentCard}
            />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({ rdx: state });

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignInfluencerApprove,
        brandCampaignInfluencersApprove,
        brandCampaignApplicationsDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsTable);
