import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupAdd from '@material-ui/icons/GroupAdd';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import StyleIcon from '@material-ui/icons/Style';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import {Icon} from '../../../Common/Icon';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';
import { gtmSend } from "../../../../utils";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    }
});

const TemporaryDrawer = ({t}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    });

    const toggleDrawer = (side, open) => () => {
        setState({ ...state, [side]: open });
    };

    const contentCreatorLinkClickedHandler = (e) => {
        gtmSend("vip_content_creator", "VIP", "VIP Content Creator", "View Jobs", 1, { "action": "View Jobs" });
    }

    const sideList = (
        <div className={classes.list}>
            <List>
                <ListItem key={'Home'} component="a" href="/">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "home-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_mobile_home') || "Home"} />
                </ListItem>
                <Divider />
                
                <ListItem key={'Influencer'} component="a" href="/influencer/campaigns">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "campaigns-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_mobile_all_campaigns') || "All Campaigns"} />
                </ListItem>
                <ListItem key={'Campaigns Applied'} component="a" href="/influencer/campaigns/applied">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "campaigns-applied-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_mobile_campaigns_applied')} />
                </ListItem>                
                <ListItem key={'Content Creator'} onClick={contentCreatorLinkClickedHandler} component="a" href="/content-creator/jobs">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "campaigns-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_content_creator') || "Content Creator"} />
                </ListItem>
                <ListItem key={'Social Accounts'} component="a" href="/influencer/profile/connect">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "social-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_social_accounts') || "Social Accounts"} />
                </ListItem>
                <ListItem key={'Edit Profile'} component="a" href="/influencer/profile/edit">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "profile-edit-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    {/* <ListItemText className={cx("mobile-nav-style")} primary={'Profile Edit'} /> */}
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_profile_edit') || 'Edit Profile'} />
                </ListItem>
                <Divider />
                <ListItem key={'FAQ'} component="a" href="/influencer/about">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "faq-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={t('sidebar_faq') || "FAQ"} />
                </ListItem>
                <ListItem key={'Feedback'} component="a" href="/influencer/feedback">
                    <ListItemIcon>
                        <span className={cx("mobile-nav-sprite", "campaigns-applied-mobile-nav-icon")}></span>
                    </ListItemIcon>
                    <ListItemText className={cx("mobile-nav-style")} primary={"Feedback"} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <Button onClick={toggleDrawer('left', true)} className={cx("btn-hamburger")}>
                <MenuIcon />
            </Button>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                <div tabIndex={0}
                    role="button"
                    onClick={toggleDrawer('left', false)}
                    onKeyDown={toggleDrawer('left', false)} >
                    {sideList}
                </div>
            </Drawer>      
        </div>
    );
}

export default translate()(TemporaryDrawer);