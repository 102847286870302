/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
//initial state wi;l be empty after adding login
function IS() {
    return {
        token: null,
        id: null,
        url: "",
        fullname: "",
        tagline: "",
        image: "",
        location: "",
        isVerified: null,
        staff: null,
        stepRegister: 0,
        lang_code: '',
        followers: [],
        following: [],
        isLoading: false,
        registerProfileDefaults: null,
        registerKidsDefaults: {
            1: {id: '', gender: '', birthDate: null, name: ''},
            2: {id: '', gender: '', birthDate: null, name: ''},
            3: {id: '', gender: '', birthDate: null, name: ''},
            4: {id: '', gender: '', birthDate: null, name: ''},
            5: {id: '', gender: '', birthDate: null, name: ''},
            6: {id: '', gender: '', birthDate: null, name: ''}
        },
        currentCampaign: null
        // id: 3,
        // url: "https://staging.parenttown.com/user/vishal-vyas",
        // fullname: "User",
        // tagline: "Logged In",
        // image: "http://groups-tap.s3.amazonaws.com/profile_15222430663072.jpg",
        // location: "India",
        // is_verified: 1,
        // staff: 1
    }    
}

const INITIAL_STATE = IS();

export var influencerReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();
    //    
    switch (action.type) {
        case 'START_INFLUENCER_AUTHENTICATION':
            return {
                ...state,
                isLoading: true
            }
        break;
        case 'COMPLETE_INFLUENCER_AUTHENTICATION':
            return {
                ...state,
                ...action.payload.response,
                isLoading: false,
                isVerified: action.payload.response.is_verified,
                stepRegister: action.payload.response.step_register
            }
        break;
        case 'COMPLETE_INFLUENCER_REGISTER_PROFILE_DEFAULTS':
            return {
                ...state,
                registerProfileDefaults: action.payload.data,
                isLoading: false
            }
        break;
        case 'COMPLETE_INFLUENCER_REGISTER_KIDS_DEFAULTS':
            return {
                ...state,
                registerKidsDefaults: mergeKidsDefaults(state.registerKidsDefaults, action.payload.data),
                isLoading: false
            }
        break;
        case 'COMPLETE_CAMPAIGN_SAVE':
            if(!state.id) return state;
            return {
                ...state,
                currentCampaign: action.payload.data,
                isLoading: false
            }
        break;
        default:
            return state;
    }
};

const mergeKidsDefaults = (defaults, existing) => {
    for(let k in existing) {
        defaults[parseInt(k) + 1] = existing[k];  
    }

    return defaults;
}