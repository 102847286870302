import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { campaignListFetch } from '../../../../actions';
import Loader from '../../../../components/UIElems/Loader';
import CampaignListCard from '../../../../components/Influencer/Campaign/Card/List';
import Placeholder from '../../../../components/Influencer/Placeholder';
import { translate } from 'react-i18next';

class Feed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eof: false
        };
    }    

    componentDidMount() {
        window.addEventListener("scroll", this.bindScrollEvent);
        //fetch
        this.props.actions.campaignListFetch(null, 1, 10, [], [], [], null, false, true, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.bindScrollEvent);
    }

    bindScrollEvent = () => {            
        if(window.scrollY >= document.body.scrollHeight - window.innerHeight - 4000) {
            if(!this.props.rdx.appReducer.globalLoader) this.infiniteScrollHandler();
        }
    }

    infiniteScrollHandler = () => {
        if(this.state.eof) return false;
        //add elems
        let page = this.props.rdx.campaignFeedReducer.page + 1;
        let prevLength, currLength;
        prevLength = this.props.rdx.campaignFeedReducer.list.length;
        this.props.actions.campaignListFetch(null, page, 10, [], [], [], null, false, false, false)
        .then(() => {
            currLength = this.props.rdx.campaignFeedReducer.list.length;
            if(prevLength == currLength) this.setState({eof: true});
        });
    }

    render() {
        const t = this.props.t;

        return (
            <div>
                <div className="campaign-feed">
                    { !!this.props.rdx.campaignFeedReducer.list.length && <h1 style={{textAlign: 'center'}}>{t('campaign_list_welcome_header')}</h1> }
                    {
                        this.props.rdx.campaignFeedReducer.list.map((d, i) => {
                            return <div key={i}>
                                <CampaignListCard data={d}
                                    influencerId={this.props.rdx.influencerReducer.id}
                                    dispatch={this.props.dispatch}
                                    t={t} />
                            </div>            
                        })
                    }

                    {
                        
                        this.props.rdx.influencerReducer.isApproved != 1
                            ? 
                                <Placeholder image="https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/vip-campaign-pending-img.png" >
                                    <div>
                                        <p>{t('campaign_placeholder_pending_approval')}</p>
                                        <p>{t('campaign_placeholder_kindly')} <a href="/influencer/profile/connect">{t('campaign_placeholder_add')}</a> {t('campaign_placeholder_waiting_for_approval')}</p>
                                        <p>{t('campaign_placeholder_waiting_for_approval_if_not')}</p>
                                    </div>
                                </Placeholder>
                            :                                
                                !this.props.rdx.campaignFeedReducer.list.length &&
                                <Placeholder image="https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/placeholder-img.png" >
                                    <div>
                                        <p>{t('campaign_no_active_campaigns')}</p>
                                        <p>{t('campaign_check_back_again')}</p>
                                    </div>
                                </Placeholder>
                    }
                </div>

                { this.props.rdx.appReducer.globalLoader && <Loader /> }
            </div>        
        );
    }
    
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        campaignListFetch
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Feed));
