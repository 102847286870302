import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { notification, message } from 'antd';


const mobileNotification = (type, msg, duration) => {
    message[type](msg, duration);
}

const desktopNotification = (type, title, message, duration, placement) => {
    const args = {
        message: title,
        description: message,
        duration: duration,
        placement: placement
    };
    notification[type](args);
}

export const N = () => {

    return {
        send: ({device='desktop', type='success', title='Notification', message='', duration=4, placement='topRight'}) => {
            if(device == 'desktop') {
                desktopNotification(type, title, message, duration, placement);
            } else {
                mobileNotification(type, message, duration);
            }
        }
    }    
}

export default N();
