import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';
import { translate } from 'react-i18next';

const JobsSidebar = ({links = [], defaultState='expand', t}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: '/content-creator/jobs', title: "Content Creator", ifActive: ((pathname == '/content-creator/jobs') ? 'active-item' : '')},
        {path: '/influencer/landing/', title: "Influencer", ifActive: ((pathname == '/influencer/campaigns') ? 'active-item' : '')},
        {path: '/panel/landing/', title: "Panelist", ifActive: ((pathname == '/panel/landing') ? 'active-item' : '')},
    ];

    return (
        <SidebarTemplate heading={"VIP Jobs"}
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="influ-sidebar-campaign-icon" 
        />
    );
}

export default translate()(JobsSidebar);

    