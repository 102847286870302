import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandInfluencerPayments } from '../../../../hooks/generic';
import { brandInfluencerIrisBulkPayoutDownload } from '../../../../actions';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import { Popover, Icon } from 'antd';
import { format } from 'date-fns';
import PaymentAdjust from '../Adjust';

const PaymentsTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [paymentTypeFilter, setPaymentTypeFilter] = useState('');

    const influencerList = useBrandInfluencerPayments(
        () => setLoading(false),
        props.dispatch,
        props.campaignId,
        page,
        pageSize,
        sorted,
        filtered,
        [
            ...props.conditions,
            ...(paymentTypeFilter ? [{field: 'tapfluencer_influencer_payment.payment_type', val: paymentTypeFilter}] : [])
        ],        
        forceUpdate
    );
    
    const profileLink = (influencerId) => {
        let link = props.campaignId
            ? `/brand/user/${influencerId}/payment/history?campaignId=${props.campaignId}`
            : `/brand/user/${influencerId}/payment/history`;

        return link;
    }

    const BankInfoPopover = ({data}) => {
        let style = (!data.bankCode && !data.beneficiaryAccountNo) ? ["status-bg-red", "status-text-red", "status-style"] : ["status-bg-blue", "status-text-blue", "status-style"];        
        const content = (
            <div>
                <p>Bank Name: {data.bankName}</p>
                <p>Bank Code: {data.bankCode}</p>
                <p>Beneficiary Name: {data.beneficiaryName}</p>
                <p>Beneficiary Email: {data.beneficiaryEmail}</p>
                <p>Beneficiary Account No: {data.beneficiaryAccountNo}</p>
                <p>GoPay No: {data.gopayPhoneNo}</p>
            </div>
        );
        
        return (
            <Popover content={content} title="Bank Details" trigger="click">
                <a href="#!" className={style}>info</a>
            </Popover>
        );
    }

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        //add payment
        let paymentElem = 
            <PaymentAdjust title={'Payment - ' + rowData.fullname}
                influencerId={rowData.influencerId}
                campaignId={props.campaignId}
                callback={() => setForceUpdate(!forceUpdate)} />;
        links.push({title: 'Adjust Payment', extraElem: paymentElem});
        //payment history
        links.push({title: 'Payment History', handler: () => window.open(profileLink(rowData.influencerId), '_blank'), icon: <Icon type="history" />});       

        return <MenuPopper title='Manage' links={links} />
    }

    const onExportForIrisBulkPayout = () => {
        if(window.confirm('Download?')) {
            props.actions.brandInfluencerIrisBulkPayoutDownload(
                props.campaignId,
                page,
                pageSize,
                sorted,
                filtered,
                [
                    ...props.conditions,
                    ...(paymentTypeFilter ? [{field: 'tapfluencer_influencer_payment.payment_type', val: paymentTypeFilter}] : [])
                ]
            )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `iris_bulk_payout_campaign_${props.campaignId}.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = [
        {
            Header: "ID",
            accessor: "influencerId",
            sortable: false,
            filterable: false,
            Footer: "Total: " + influencerList.total
        },
        {
            id: "fullname",
            Header: "Name",
            accessor: "",
            sortable: false,
            filterable: true,
            Cell: props => <a target="_blank" href={profileLink(props.value.influencerId)}>{props.value.fullname}</a>
        },
        {
            Header: "Email",
            accessor: "email",
            sortable: false,
            filterable: true
        },
        {
            Header: "Total Amount",
            accessor: "",
            sortable: false,
            filterable: false,
            // Cell: props => props.value.totalAmount + ' ' + props.value.currencyCode
            Cell: props => props.value.totalAmount
        },
        {
            Header: "Payment Type",
            accessor: "paymentType",
            sortable: false,
            filterable: false
        },
        {
            Header: "Bank Info",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => <BankInfoPopover data={props.value} />
        },
        {
            Header: "Comments",
            accessor: "comments",
            sortable: false,
            filterable: false
        },
        {
            Header: "Last Updated On",
            accessor: "lastCreatedAt",
            sortable: false,
            filterable: false,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Last Updated By",
            accessor: "lastUpdatedByName",
            sortable: false,
            filterable: false
        },
        {
            Header: "Manage",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div className="payment-filters">
                <div className={cx("form-horizontal-row")}>
                    <div className={cx("form-wrap")}>
                        <label htmlFor="payment-type">Payment Type: </label>
                        <select id="payment-type" value={paymentTypeFilter} onChange={e => setPaymentTypeFilter(e.target.value)}>
                            <option value="">all</option>
                            <option value="monetary">Monetary</option>
                            <option value="points">Points</option>
                        </select>
                    </div>
                </div>

                <div className="row4">
                    <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceUpdate(!forceUpdate)}>Fetch</button>
                   
                    <button type="button" style={{float: 'right'}} className={cx("btn", "btn-danger")} onClick={e => onExportForIrisBulkPayout()}>Export For Iris Bulk Payout</button>
                </div>
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={influencerList.list}
                pages={Math.ceil(influencerList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerIrisBulkPayoutDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable);
