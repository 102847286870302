import React, { useEffect, useState } from 'react';
import cx from 'classnames/bind';
import { createImagePreview } from '../../../../../../utils';
import MultiSelect from '../../../../../Common/MultiSelect';
import { Button, Icon } from 'antd';

const AddProvider = ({ countryOptions, providerName, setProviderName, providerLogo, setProviderLogo, providerSubcategories, setProviderSubcategories, setProviderCountries, dataCleared, forceRefresh, deletedSubcategories, setDeletedSubcategories, sendNotification }) => {

    const [logoPreview, setLogoPreview] = useState();

    useEffect(() => {
        setLogoPreview(null);
    }, [dataCleared])

    useEffect(() => {
        if (providerLogo) {
            setLogoPreview(providerLogo);
        }
    }, [forceRefresh])

    const onLogoChange = e => {
        const file = e.target.files[0];
        const ext = file.name.split('.')[1];
        if (['png', 'jpg', 'jpeg'].includes(ext) && ['image/png', 'image/jpeg'].includes(file.type)) {
            setProviderLogo(file);
            createImagePreview(file, preview => setLogoPreview(preview));
        } else {
            sendNotification('Kindly upload an image of type png, jpeg or jpg only!', 'error');
        }
    }

    const onSubcategoryAdd = () => {
        const newSubcategoryList = [...providerSubcategories, {subcategoryName: ''}];
        setProviderSubcategories(newSubcategoryList);
    }

    const onSubcategoryDelete = i => {
        const newSubcategoryList = [...providerSubcategories];
        let deletedElemets = newSubcategoryList.splice(i, 1);
        deletedElemets = deletedElemets.filter(element => element.subcategoryId && element.subcategoryId !== '');
        setProviderSubcategories(newSubcategoryList);
        setDeletedSubcategories([...deletedSubcategories, ...deletedElemets]);
    }

    const onSubcategoryChange = (value, i) => {
        const newSubcategoryList = [...providerSubcategories];
        newSubcategoryList[i].subcategoryName = value;
        setProviderSubcategories(newSubcategoryList);
    }

    return (
        <div className={cx("form-vertical-row")}>
            <div className={cx("form-wrap")}>
                <h3>eVoucher Provider name</h3>
                <input type="text" maxLength={50} value={providerName} onChange={e => setProviderName(e.target.value)} />
                <label>Max 50 characters</label>
            </div>
            <div className={cx("form-wrap")}>
                <label htmlFor="logo">eVoucher Provider Logo </label>
                <div>
                    <span className={cx("btn-choose-file")}>
                        <input id="logo" type="file" accept=".jpeg, .jpg, .png" className={cx("choose-file")} onChange={onLogoChange} />
                        <label htmlFor="">
                            <span>Choose File</span>
                            <span className={cx("upload-icon")}></span>
                        </label>
                    </span>
                    <span>Extension support : jpeg, jpg & png</span>
                </div>
            </div>
            <div>
                {logoPreview && <img src={logoPreview} className={cx("logo-preview")} alt="logo-preview" width="120" />}
            </div>
            <div className={cx("form-wrap")}>
                <label>eVoucher Provider Countries</label>
                <MultiSelect
                    options={countryOptions}
                    onChange={setProviderCountries}
                    optionsCleared={dataCleared}
                    refreshDropdown={forceRefresh}
                />
            </div>
            <div className={cx("form-wrap")}>
                <span>eVoucher Provider subcategories &nbsp; <Button type="primary" icon="plus" onClick={onSubcategoryAdd}></Button></span>
                {
                    providerSubcategories &&
                    providerSubcategories.length > 0 &&
                    providerSubcategories.map((sub, i) => (
                        <span key={sub.subcategoryId}>
                            <input value={providerSubcategories[i].subcategoryName} maxLength={100} onChange={e => onSubcategoryChange(e.target.value, i)} />
                            &nbsp;
                            <Button type="danger" icon="delete" onClick={e => onSubcategoryDelete(i)}></Button>
                        </span>
                    ))
                }
            </div>
        </div>
    )

}

export default AddProvider;