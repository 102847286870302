import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import SidebarTemplate from '../Template/index';
import cx from 'classnames/bind';

// const Users = () => {

    // return (
    //     <div>
    //         <div className={cx("toggle-navigation")}>
    //             <div onClick={() => window.location = '/brand/users/all'} className={cx("nav-heading")}>
    //                 <h3>
    //                     <span className={cx("sidebar-icon-spacing", "brand-user-icon")}></span>
    //                     <span className={cx("navigation-title")}>Users</span>
    //                 </h3>
    //             </div>
    //         </div>
    //    </div>
    // );
// }

const Users = ({links = [], currentTitle=null, defaultState='expand'}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: `/brand/users/influencer/all`, title: 'Influencer', ifActive: '' },
        {path: `/brand/users/panel/all`, title: 'Panel', ifActive: ''}
    ];

    //set current
    if(currentTitle) {
        for(let k in defaultLinks) {
            if(defaultLinks[k]['title'] == currentTitle)
                defaultLinks[k]['ifActive'] = 'active-item';
        }
    }


    return (
        <SidebarTemplate heading="Users"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-user-icon" />
    );
}

export default Users;