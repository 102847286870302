import React from 'react';
import { useState } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandPanelSubmitters } from '../../../../../../hooks/generic';
import { brandPanelSubmissionsDownload, brandCampaignEngagementDownload } from '../../../../../../actions';
import Table from '../../../../../../components/Common/Table';
import MenuPopper from '../../../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import RatingPopover from '../../../../Grading/Popover';

const SubmittersTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [submittersList] = useBrandPanelSubmitters(
        () => setLoading(false),
        props.dispatch,
        props.panelId,
        page,
        pageSize,
        sorted,
        filtered,
        props.conditions,
        'extra' in props ? props.extra : {},
        forceUpdate
    );

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        // //view submissions
        // links.push({title: 'View Submissions', handler: () => window.open(`/brand/campaign/${rowData.campaignId}/applicant/${rowData.influencerId}/submissions`, '_blank')});
        //view profile
        links.push({title: 'View Profile', handler: () => window.open(`/brand/user/${rowData.userId}`, '_blank')});

        return <MenuPopper title='Manage' links={links} />
    }

    const completedOn = (props) => {
        return props.completedOn ? format(props.completedOn, 'DD-MM-YYYY') : '';
    }

    const onDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandPanelSubmissionsDownload(
                props.panelId,
                page,
                pageSize,
                sorted,
                filtered,
                props.conditions,
                'extra' in props ? props.extra : {}
            )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `panel_submitters_${props.panelId}.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = props.job.panelType === 'survey' ? [
        {
            id: "id",
            Header: "Id",
            accessor: "userId",
            sortable: false,
            filterable: true,
            Footer: "Total: " + submittersList.total

        },
        {
            id: "fullname",
            Header: "Name",
            accessor: "fullname",
            sortable: false,
            filterable: true,
            // Cell: props => <a target="_blank" href={`/brand/campaign/${props.value.campaignId}/applicant/${props.value.influencerId}/submissions`}>{props.value.fullname}</a>,
        },
        {
            id: "email",
            Header: "Email",
            accessor: "email",
            sortable: false,
            filterable: true
        },
        {
            Header: "Average Rating",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <RatingPopover data={props.value} />
        },
        {
            Header: "Origin",
            accessor: "origin",
            sortable: false,
        },
        {
            Header: "Country",
            accessor: "country",
            sortable: false,
        },
        {
            Header: "Gender",
            accessor: "gender",
            sortable: false,
        },
        {
            Header: "Completed On",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => completedOn(props.value)
        },
        {
            Header: "Manage",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => createActionLink(props.value)
        }
    ] : [
        {
            id: "id",
            Header: "Id",
            accessor: "userId",
            sortable: false,
            filterable: true,
            Footer: "Total: " + submittersList.total

        },
        {
            id: "fullname",
            Header: "Name",
            accessor: "fullname",
            sortable: false,
            filterable: true,
            // Cell: props => <a target="_blank" href={`/brand/campaign/${props.value.campaignId}/applicant/${props.value.influencerId}/submissions`}>{props.value.fullname}</a>,
        },
        {
            id: "email",
            Header: "Email",
            accessor: "email",
            sortable: false,
            filterable: true
        },
        {
            Header: "Last modified on",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => props.value.updatedAt ? format(props.value.updatedAt, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Status",
            accessor: "status",
            sortable: false,
            filterable: false
        },
        {
            Header: "Manage",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div>&nbsp;</div>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button type="button" className={cx("btn", "btn-danger")} onClick={e => onDownload()}>Download</button>
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={submittersList.list}
                pages={Math.ceil(submittersList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandPanelSubmissionsDownload,
        brandCampaignEngagementDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmittersTable);
