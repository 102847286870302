import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../hooks/generic';
import { influencerFeedbackAdd } from '../../../../actions';
import MobWelcomeFeedbackComponent from '../../../../components/Mob/Welcome/Feedback';
import { translate } from 'react-i18next';
import { myIsSet } from '../../../../helpers';
import notification from '../../../../components/UIElems/Notification';
import Loader from '../../../../components/UIElems/Loader';

const Feedback = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        setIsLoading(true);
        data['influencerId'] = props.rdx.webviewReducer.profile.influencerId;
        props.actions.influencerFeedbackAdd(data)
        .then(() => {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Feedback Sent!', type: 'success'});
        })

    }

    return (
        <div>
                <MobWelcomeFeedbackComponent t={props.t}
                    country={myIsSet(props, 'rdx.webviewReducer.profile.country') ? props.rdx.webviewReducer.profile.country : props.rdx.appReducer.country}
                    lang={myIsSet(props, 'rdx.webviewReducer.profile.langCode') ? props.rdx.webviewReducer.profile.langCode : 'en'}
                    welcomeUrl={`../welcome?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
                    welcomeFaqUrl={`../welcome/faq?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
                    onSubmit={onSubmit}
                />
                { isLoading && <Loader />}
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerFeedbackAdd
    }, dispatch),
    dispatch
})


export default connect(mapStateToProps, mapDispatchToProps)(translate()(Feedback));
