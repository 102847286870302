import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {Icon} from '../../Common/Icon';
import Tabs from '../../UIElems/Tabs';

const HowToMain = ({t, country, lang, tabIndex, faqUrl}) => {
    
    const onTabChange = (activeKey) => {
    }

    return (
        <div>
            <div className={cx("influencer-campaign-how-wrapper")}>
                <div className={cx("mob-navigation")}>
                    <ul className={cx("faq-item")}>
                        <li>
                            <a href={faqUrl}>
                                {t("mob-how-faq")}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={cx("page-spacing", "custom-mob-tabs")}>
                <Tabs tabsHandler={onTabChange}
                    defaultActiveKey={tabIndex}
                    elems={[
                        {
                            name: 'Vip Member',
                            elem: (
                                <HowToVipMember t={t}
                                    lang={lang} />
                            )
                        },
                        {
                            name: 'Campaign',
                            elem: (
                                <HowToCampaign t={t}
                                    lang={lang} />
                            )
                        }
                    ]} />
            </div>
        </div>
    );
}

const HowToVipMember = ({t, lang}) => {

    return (
        <div className={cx("influencer-campaign-how-wrapper")}>

            <div className={cx("how-it-works-body", "cd-padding-top60")}>
                <div className={cx("title", "text-center")}>
                    <h1>{t("mob_how_to_become_a_member")} <Icon name="mob-icon-gears"></Icon></h1>
                </div>
            </div>

            <div className={cx("how-it-works-body", "cd-padding-top25", "page-spacing")}>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 1
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_go_to_the_main_menu_of_theAsianparent_app")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step1")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 2
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_select_vip_member")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step2")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 3
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_make_sure_to_fill_in_all_your_information")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step3")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_having_your_full_profile_enables_us_to_customise_the_projects_you_see")}
                        </p>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 4
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_select_your_topics_of_interest")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step4")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_so_that_we_can_show_you_only_projects_that_you_may_like")}
                        </p>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 5
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_connect_your_social_media_accounts")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step5")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_make_sure_to_connect_an_account_that_has_more_than_500_followers")}
                        </p>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 6
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_submit_your_application_for_review")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_vip_member_step6")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_wait_to_hear_back_from_us_through_an_email_and_a_push_notification_from_theAsianparent_app")} 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const HowToCampaign = ({t, lang}) => {

    return (
        <div className={cx("influencer-campaign-how-wrapper")}>

            <div className={cx("how-it-works-body", "cd-padding-top60")}>
                <div className={cx("title", "text-center")}>
                    <h1>{t("mob_how_our_campaign_process_works")} <Icon name="mob-icon-gears"></Icon></h1>
                </div>
            </div>
    
            <div className={cx("how-it-works-body", "cd-padding-top25", "page-spacing")}>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 1
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_from_the_list_of_live_campaigns_select_one_that_you_are_interested_in")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step1")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 2
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_check_the_details_to_ensure_you_fit_the_criteria_for_selection")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step2")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 3
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_apply_for_the_campaign")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step3")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 4
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_wait_for_approval_which_will_come_via_email_and_app_push_notification")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step4")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 5
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_follow_the_email_with_campaign_deliverables_complete_the_project")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step5")} alt=""/>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 6
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_submit_your_work_and_wait_for_approval")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step6")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_make_sure_to_review_your_work_before_submitting")}
                        </p>
                    </div>
                </div>

                <div className={cx("steps-divider")}>
                    <hr/>
                </div>

                <div className={cx("steps-row")}>
                    <div className={cx("steps-count", "text-center")}>
                        {t("mob_how_step")} 7
                    </div>
                    <div className={cx("steps-title", "text-center")}>
                        <h2>{t("mob_how_when_your_work_is_approved_you_get_paid")}</h2>
                    </div>
                    <div className={cx("steps-img")}>
                        <img src={t("mob_how_campaign_step7")} alt=""/>
                    </div>
                    <div className={cx("steps-desc", "text-center")}>
                        <p>
                            {t("mob_how_payment_details_can_be_found_in_the_campaign_briefing_proposal_Payments_will_be_in_the_form_of_cash_products_or_points")}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HowToMain;