import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandInfluencerGradeAdjust } from '../../../../actions';
import { Typography } from 'antd';
import notification from '../../../../components/UIElems/Notification';
import RateElem from '../../../../components/UIElems/Rate';

const { Text } = Typography;

const GradeAdjust = (props) => {
    const [value, setValue] = useState(props.value);
    const [comment, setComment] = useState(props.comment);

    const onAdjust = (newRating) => {
        props.actions.brandInfluencerGradeAdjust(props.influencerId, props.campaignId, [{type: props.type, value: newRating}], comment)
        .then(() => {
            setValue(newRating);
            // notification.send({device: 'desktop', message: 'Done!', type: 'success'});
        })
    }
    
    return (
        <div className={cx('adjust-grade')}>
            <RateElem value={value}
                count={5}
                disabled={'disabled' in props ? props.disabled : false}
                onChange={onAdjust} />    
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerGradeAdjust
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(GradeAdjust);