import config from '../config';
import jwt from 'jsonwebtoken';
import crypto from 'crypto';
import CONFIG from "../config";
import {URL} from "url";
/*utils*/

export const createImagePreview = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (i) => {
        let base64Result = i.target.result;
        let previewImage = base64Result;
        // let imageData = base64Result.split(',')[1];
        callback(previewImage);
    }
}

export const gtmSend = (event, category, action, label, value = 1, params = {}) => {
    if (typeof window !== "undefined") {
        event = event === 'dynamic' ? 'Dynamic GTM Event' : event

        if (process.env.RAZZLE_REACT_APP_ENV !== 'production') {
            // console.log(event, category, action, label, value, params);
            console.log("GTM Event:", event , ' | ', category, ' | ',action, ' | ',label, ' | ',value, ' | ',params);
        }
        console.log('process.env.RAZZLE_REACT_APP_ENV ',process.env.RAZZLE_REACT_APP_ENV)
        
        if (process.env.RAZZLE_REACT_APP_ENV !== 'development') {
            if (window.dataLayer) {
                let toPush = {
                    event: event,
                    eventValue: value
                };
                if (category) toPush['eventCategory'] = category;
                if (action) toPush['eventAction'] = action;
                if (label) toPush['eventLabel'] = label;

                //params
                for (let k in params) {
                    toPush[k] = params[k];
                }
                //console.log(window.dataLayer, toPush);
                window.dataLayer.push(toPush);
                // This code sends gtm events to native apps
                // The trackWebPageEvent function is defined in the native apps and is used to receive the gtm events
                // if ((window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.trackWebPageEvent) || (window.JSBridge && window.JSBridge.trackWebPageEvent)) {
                //     if(window.JSBridge && window.JSBridge.trackWebPageEvent) {
                //         // send gtm events to Android
                //         window.JSBridge.trackWebPageEvent(JSON.stringify(toPush));
                //     } else {
                //         // send gtm events to iOS
                //         window.webkit.messageHandlers.trackWebPageEvent.postMessage(JSON.stringify(toPush));
                //     }
                // }
            }
        }
    }
}

export const isObjectEmpty = (obj) => {
    if(!obj)
        return true;
    else        
        return (Object.entries(obj).length === 0 && obj.constructor === Object);
}

export const isValidUrl = (url) => {
    return url.match(new RegExp(/(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/));
}

export const trimEndSlash = (url) => {
    if(url[url.length - 1] == '/') {
        return url.slice(0, -1);
    } else {
        return url;
    }
}

export const getRandomIntInRange = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export const getRandomHexColor = () => {
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
}

export const escapeString = str => {
    return typeof str === 'string'? `"${str.replace(/\\/g,'\\\\').replace(/\r\n/g, '\r').replace(/\n/g,'\\n').replace(/\t/g,'\\t').replace(/\v/g,'\\v').replace(/'/g,"\\'").replace(/"/g,'\\"')}"`: str;
}

export const createJwtToken = (payload, opts = {}) => {
    const token = jwt.sign(payload, config.jwtSecretKey, {
        algorithm: opts.algorithm || 'HS256',
        expiresIn: opts.expiresIn || '30min'
    });
    return token;
}

export const verifyJwtToken = token => {
    return jwt.verify(token, config.jwtSecretKey);
}

export const cipherData = (data, opts = {}) => {
    const keyString = opts.key || config.cipherKey;
    const key = Buffer.from(keyString, 'base64');
    const ivString = opts.iv || config.cipherIv;
    const iv = Buffer.from(ivString, 'base64');
    const algorithm = opts.algorithm || 'aes256';
    const inputEncoding = opts.inputEncoding || 'utf-8';
    const outputEncoding = opts.outputEncoding || 'base64';
    const cipher = crypto.createCipheriv(algorithm, key, iv);
    let ciphered = cipher.update(data, inputEncoding, outputEncoding);
    ciphered += cipher.final(outputEncoding);
    return ciphered;
}

export const decipherData = (cipheredData, opts = {}) => {
    const keyString = opts.key || config.cipherKey;
    const key = Buffer.from(keyString, 'base64');
    const ivString = opts.iv || config.cipherIv;
    const iv = Buffer.from(ivString, 'base64');
    const algorithm = opts.algorithm || 'aes256';
    const inputEncoding = opts.inputEncoding || 'utf-8';
    const outputEncoding = opts.outputEncoding || 'base64';
    const decipher = crypto.createDecipheriv(algorithm, key, iv);
    let deciphered = decipher.update(cipheredData, outputEncoding, inputEncoding);
    deciphered += decipher.final(inputEncoding);
    return deciphered;
}

export const returnPromiseWithRetry = (promiseFn, numTry = 3, ...fnArgs) => {
    return new Promise((resolve, reject) => {
        let retryCount = 0;
        const runPromiseFn = () => {
            promiseFn(...fnArgs).then(
                data => resolve(data),
                error => {
                    if (retryCount < numTry) {
                        promiseFn(...fnArgs);
                        retryCount++;
                    } else {
                        reject(error)
                    }
                }
            )
        }

        runPromiseFn();
    });
}

export const getKeyFromValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const getDateWithoutTimestamp = date => {
    return new Date(date.toDateString());
}

export const getTodaysDate = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}

export const getLinkToJob = (campaignId) => {
    const { siteUrl } = CONFIG
    const hostname = new URL(siteUrl).hostname
    return `https://www.${hostname}/influencer/campaign/${campaignId}`
}
