import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import { genericAction } from '../../../../actions';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import notification from '../../../../components/UIElems/Notification';
import { Row, Col } from 'antd';
import CardElem from '../../../../components/UIElems/Card';
import CampaignApprovalWidget from '../../../Stats/Widget/CampaignApproval';
import SubmissionsWidget from '../../../Stats/Widget/Submissions';
import RatingsWidget from '../../../Stats/Widget/Ratings';
import ReachWidget from '../../../Stats/Widget/Reach';
import EmailWidget from '../../../Stats/Widget/Email';
import UserActivityWidget from '../../../Stats/Widget/UserActivity';
import { format } from 'date-fns';

const CampaignStats = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);
    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [
            [
                'tapfluencer_campaign.campaign_name as campaignName',
                'date(tapfluencer_campaign.start_date) as startDate',
                'date(tapfluencer_campaign.end_date) as endDate'
            ],
            props.match.params.id
        ])
        .then(res => setCampaignDetails(res.data))
    }, []);

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Home'/>
            ]}>
            <div className="campaign-stats">
                <h1>Campaign Stats - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                
                <Row type="flex" justify="space-between">
                    <Col span={11}>
                        <CardElem bordered={false}>
                            <CampaignApprovalWidget campaignId={props.match.params.id} />
                        </CardElem>
                    </Col>
                    <Col span={11}>
                        <CardElem bordered={false}>
                            <SubmissionsWidget campaignId={props.match.params.id} />
                        </CardElem>
                    </Col>
                </Row>

                <div>&nbsp;</div>

                <Row type="flex" justify="space-between">
                    <Col span={11}>
                        <CardElem bordered={true}>
                            <RatingsWidget campaignId={props.match.params.id} />
                        </CardElem>
                    </Col>
                    <Col span={11}>
                        <CardElem bordered={true}>
                            <EmailWidget campaignId={props.match.params.id} />
                        </CardElem>
                    </Col>
                </Row>

                <div>&nbsp;</div>

                <Row type="flex" justify="space-between">
                    <Col span={11}>
                        <CardElem bordered={true}>
                            <UserActivityWidget campaignId={props.match.params.id}
                                startDate={(campaignDetails && 'startDate' in campaignDetails) ? format(campaignDetails.startDate, 'YYYY-MM-DD') : null}
                                endDate={(campaignDetails && 'endDate' in campaignDetails) ? format(campaignDetails.endDate, 'YYYY-MM-DD') : null} />
                        </CardElem>
                    </Col>
                    <Col span={11}>                        
                        <CardElem bordered={true}>
                            <ReachWidget campaignId={props.match.params.id} />
                        </CardElem>
                    </Col>
                </Row>
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStats);