import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignFeedbackAnswers } from '../../../../../../hooks/generic';
import { brandCampaignFeedbackDownload } from '../../../../../../actions';
import Table from '../../../../../../components/Common/Table';
import MenuPopper from '../../../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';

const AnswersTable = (props) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [forceUpdate, setForceUpdate] = useState(false);
    const answerList = useBrandCampaignFeedbackAnswers(props.dispatch, props.campaignId, null, page, pageSize, props.conditions, forceUpdate);

    const onFetchData = (state, instance) => {
        setLoading(false);
        setPage(state.page + 1);
        setPageSize(state.pageSize);
    };

    const createActionLink = (rowData) => {
        //let links = [];
        //links.push({title: 'View Profile', handler: () => window.location = `/brand/campaign/${rowData.campaignId}/applicant/${rowData.influencerId}`});

        //return <MenuPopper title='Manage' links={links} />
    }

    const onCampaignFeedbackDownload = (campaignId) => {
        props.actions.brandCampaignFeedbackDownload(campaignId)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `feedback_${campaignId}.csv`);
            document.body.appendChild(link);
            link.click();
        })
    }

    const columns = [
        {
            Header: "Name",
            accessor: "fullname"
        },
        {
            Header: "Question",
            accessor: "question"
        },
        {
            Header: "Answer",
            accessor: "answer"
        }
    ];

    return (
        <div>
            <div>
                <button className={cx("btn", "btn-danger")} type="button" onClick={e => onCampaignFeedbackDownload(props.campaignId)}>Download</button>
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={answerList.list}
                pages={Math.ceil(answerList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignFeedbackDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(AnswersTable);
