import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import EmailsSentTable from './Table';
import BaseLayout from '../../../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../../../components/Brand/Sidebar/CurrentCampaign';
import { genericAction } from '../../../../../../actions';
import { Typography } from 'antd';

const { Text } = Typography;

const EmailsSent = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Email Tracker'/>
            ]}>
            <div className="email-tracker">
                <h1>Email Tracker - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <h2>Sent Emails</h2>
                <Text mark>
                    If you just recently clicked on the send notification button, it will be a while before the data here gradually fills up.
                </Text>
                <EmailsSentTable campaignId={props.match.params.id}
                    conditions={{}} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailsSent);