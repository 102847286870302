import React from "react";

const DotIndicator = ({ current, capacity }) => {
    return (
        <div className="dot-indicators">
            {
                Array(Math.min(5, capacity)).fill(1).map((item, index) => {
                    return (
                        <div className={(index + 1) == current ? "dot active": "dot"}>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default DotIndicator;