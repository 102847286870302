import * as rax from 'retry-axios';
import axios from 'axios';
import querystring from 'querystring';
import CONFIG from '../config';
import q from 'q';
import serverToken from '../config/server-token';

const interceptorId = rax.attach();
// axios init
const buildAxiosTap = (dispatch, getState) => {
    let state = getState(); 
    let params = {
        baseURL: CONFIG['apiBase']
        // timeout: 1000,    
    };
    if(state && state.influencerReducer.token) params['headers'] = {'Authorization': 'Bearer ' + state.influencerReducer.token};
    let client = axios.create(params);
    // Add a request interceptor
    client.interceptors.request.use((config) => {
        if(config.method == 'post') {
            config.data['cn'] = state.appReducer.country;
        } else {
            config.params['cn'] = state.appReducer.country;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    // Add a response interceptor
    client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        //if unauthorized
        if(error.response.status == 401) {
            // dispatch(showLoginModal());
        }
        return Promise.reject(error);
    });

    return client;
}

const buildAxiosTapServer = () => {
    let params = {
        baseURL: CONFIG['apiBase']
        // timeout: 1000,    
    };
    params['headers'] = {'Authorization': 'Bearer ' + CONFIG['api']['serverToken']};
    let client = axios.create(params);

    return client;
}

const buildAxiosTapServerWithAdminToken = () => {
    let params = {
        baseURL: CONFIG['apiBase']
        // timeout: 1000,    
    };
    let client = axios.create(params);
    client.interceptors.request.use(conf => {
        conf.headers.Authorization = `Bearer ${serverToken.token}`;
        return conf;
    });

    return client;
}

const buildAxiosTapServerInternal = () => {
    console.log('line 69 ', CONFIG['apiBaseInternal'])
    let params = {
        baseURL: CONFIG['apiBaseInternal'],
        timeout: 1000 * 1000,    
    };
    params['headers'] = {'Authorization': 'Bearer ' + CONFIG['api']['serverToken']};
    let client = axios.create(params);
    console.log('line 76')
    return client;
}

// axios init 
const buildAxiosSite = () => {
    let params = {
        baseURL: CONFIG['apiBaseVIP'],
        timeout: 1000 * 1000,    
    };
    let client = axios.create(params);

    return client;
}

const constructParams = (params, requestParams) => {
    if(params['method'] == 'post'){
        params['data'] = requestParams;
    } else if(params['method'] == 'get'){
        params['params'] = requestParams;
    }
    return params;
}

// export var refreshToken = (state={}) => {
//     if(state.userReducer.token) axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + state.userReducer.token;
//     return axiosClient;
// }

// axios.defaults.baseURL = CONFIG['apiBase'];
// axios.defaults.headers.common['Authorization'] = CONFIG['api']['token'];
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios init


////// - app - start
export var setAppParams = (sData) => {
    return {
        type: 'SET_APP_PARAMS',
        payload: sData
    }
};

export var unsetAppParams = (sData) => {
    return {
        type: 'UNSET_APP_PARAMS',
        payload: sData
    }
};

export var startGlobalLoader = () => {
    return {
        type: 'START_GLOBAL_LOADER'
    }
};

export var completeGlobalLoader = () => {
    return {
        type: 'COMPLETE_GLOBAL_LOADER'
    }
};

////// - webview - start
export var startWebviewReducer = (sData) => {
    return {
        type: 'START_WEBVIEW_REDUCER',
        payload: sData
    }
};

export var completeWebviewReducer = (sData) => {
    return {
        type: 'COMPLETE_WEBVIEW_REDUCER',
        payload: sData
    }
};

////// - influencer - start
export var startInfluencerAuthentication = () => {
    return {
        type: 'START_INFLUENCER_AUTHENTICATION'
    }
};

export var completeInfluencerAuthentication = (sData) => {
    return {
        type: 'COMPLETE_INFLUENCER_AUTHENTICATION',
        payload: sData
    }
};

export var completeInfluencerRegisterProfileDefaults = (sData) => {
    return {
        type: 'COMPLETE_INFLUENCER_REGISTER_PROFILE_DEFAULTS',
        payload: sData
    }
};

export var completeInfluencerRegisterKidsDefaults = (sData) => {
    return {
        type: 'COMPLETE_INFLUENCER_REGISTER_KIDS_DEFAULTS',
        payload: sData
    }
};

////// - brand - start
export var completeCampaignSave = (sData) => {
    return {
        type: 'COMPLETE_CAMPAIGN_SAVE',
        payload: sData
    }
};

export var completeFilterSave = (sData) => {
    return {
        type: 'COMPLETE_BRAND_FILTER_SAVE',
        payload: sData
    }
};

export var completeBrandAuthentication = (sData) => {
    return {
        type: 'COMPLETE_BRAND_AUTHENTICATION',
        payload: sData
    }
};

///////// - campaign feed - start
export var completeCampaignFeedFetch = (sData) => {
    return {
        type: 'COMPLETE_CAMPAIGN_FEED_FETCH',
        payload: sData
    }
};

//---------- Jobs ---------------------------------------------------------

export var completeJobListFetch = (sData) => {
    return {
        type: 'COMPLETE_JOB_LIST_FETCH',
        payload: sData
    }
};

//---------- Panel ---------------------------------------------------------

export var completePanelListFetch = data => {
    return {
        type: 'COMPLETE_PANEL_LIST_FETCH',
        payload: data
    };
};

export var completePanelSave = (sData) => {
    return {
        type: 'COMPLETE_PANEL_SAVE',
        payload: sData
    }
};

export var completeBrandPanelFilterFetch = (sData) => {
    return {
        type: 'COMPLETE_BRAND_PANEL_FILTER_FETCH',
        payload: sData
    }
};

//--------- common ---------------------------------------------------------------------------------------------

export var jobListFetch = (brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset=false, ifDontDispatch=false, jobType='all') => {
    let params = constructParams(
        {
            url: CONFIG['paths']['apiJobList.get'],
            method: 'get'
        },
        {brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, jobType}
    );
    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        dispatch(startGlobalLoader());
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                xhrResponse.data.shouldReset = shouldReset;
                xhrResponse.data.page = page;
                dispatch(completeGlobalLoader());
                if(!ifDontDispatch) {
                    dispatch(completeJobListFetch(xhrResponse.data));
                }
                return xhrResponse.data;                
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var apiJobAppliedListFetch = (userId, jobType, page, pageSize, sorted, filtered, conditions, extra, isAdmin) => {
    const device_type = 'web';
    let params = constructParams(
        {
            url: CONFIG['paths']['apiJobApplied.get'],
            method: 'get'
        },
        {userId, jobType, page, pageSize, sorted, filtered, conditions, extra, isAdmin, device_type, app_version: CONFIG['editJobSubmission']['minVersion'][device_type] }
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status === 200) {
            return xhrResponse.data;
        }
    })
    .catch((error) => {
        return error;
    });
};

export var surveyCategoryListFetch = () => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelCategoryList.post'],
            method: 'post'
        },
        {}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status === 200) {
            return xhrResponse.data;
            // dispatch(completeUserAuthentication(xhrResponse.data));
        }
    })
    .catch((error) => {
        return error;
        // console.log(error);
    });
};

export var productCategoryListFetch = (email, password) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandProductCategoryList.post'],
            method: 'post'
        },
        {}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status == 200) {
            return xhrResponse.data;
            // dispatch(completeUserAuthentication(xhrResponse.data));
        }
    })
    .catch((error) => {
        return error;
        // console.log(error);
    });
};

export var productCategoryAdd = (name) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandProductCategoryAdd.post'],
            method: 'post'
        },
        {name}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status === 200) {
            return xhrResponse.data;
        }
    })
    .catch((error) => {
        return error;
    });
};

export var countryListFetch = (email, password) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['countryList.post'],
            method: 'post'
        },
        {}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status == 200) {
            return xhrResponse.data;
            // dispatch(completeUserAuthentication(xhrResponse.data));
        }
    })
    .catch((error) => {
        return error;
        // console.log(error);
    });
};

export var stateListFetch = countryId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['statesList.post'],
            method: 'post'
        },
        {countryId}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status == 200) {
            return xhrResponse.data;
            // dispatch(completeUserAuthentication(xhrResponse.data));
        }
    })
    .catch((error) => {
        return error;
        // console.log(error);
    });
};

export const citiesListFetch = (countryId) => {
    const params = constructParams({
        url: CONFIG['paths']['citiesList.post'],
        method: 'post'
    }, { city_country: countryId });
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => (xhrResponse.status === 200 && xhrResponse.data))
    .catch((error) => error);
};

export var stateCitiesListFetch = stateIds => {
    let params = constructParams(
        {
            url: CONFIG['paths']['statesCitiesList.post'],
            method: 'post'
        },
        {stateIds}
    );
    
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status == 200) {
            return xhrResponse.data;
            // dispatch(completeUserAuthentication(xhrResponse.data));
        }
    })
    .catch((error) => {
        return error;
        // console.log(error);
    });
};

export var campaignListFetch = (brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset=false, ifDontDispatch=false) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['campaignList.post'],
            method: 'post'
        },
        {brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer}
    );
    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        dispatch(startGlobalLoader());
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                xhrResponse.data.shouldReset = shouldReset;
                xhrResponse.data.page = page;
                dispatch(completeGlobalLoader());
                if(!ifDontDispatch) dispatch(completeCampaignFeedFetch(xhrResponse.data));
                return xhrResponse.data;                
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var campaignDetailFetch = (campaignId, isAdmin=0) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['campaignDetail.post'],
            method: 'post'
        },
        {campaignId, isAdmin}
    );
    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        // dispatch(startUserAuthentication());
        // axiosClient.defaults.headers.post['content-type'] = 'multipart/form-data';
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeCampaignSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
    
};

export var campaignFeedbackQuestions = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['campaignFeedbackQuestions.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var workerDaemonCall = (taskName, args=[]) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['workerDaemonCall.post'],
            method: 'post'
        },
        {taskName, args}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var genericAction = (fnName, args=[]) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['genericAction.post'],
            method: 'post'
        },
        {fnName, args}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var triggerNotification = (payload) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['triggerNotification.post'],
            method: 'post'
        },
        payload
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var currencyCreditConversionList = () => {
    let params = constructParams(
        {
            url: CONFIG['paths']['apiCurrencyCreditMapping.get'],
            method: 'get'
        },
        {}
    );
    return buildAxiosSite().request(params)
    .then((xhrResponse) => (xhrResponse.status === 200 && xhrResponse.data))
    .catch((error) => {
        console.log(error);
    });
};

export var authenticateUser = (authorization) => {
    return buildAxiosTapServer().get('vip/service/auth/user', {
        headers: {
            authorization
        }
    }).then(response => {
        if (response.status === 200) {
            return response.data;
        }
    }).catch(error => {        
        console.log('Error authenticating user');
    });
}

export var getPollsList = (authorization, poll_ids) => {
    return buildAxiosTapServer().request({
        url: 'vip/service/polls/list',
        method: 'get',
        data: { poll_ids },
        headers: {
            authorization,
            'content-type': 'application/json'
        }
    }).then(response => {
        if (response.status === 200 && response.data) {
            return response.data;
        }
    })
    .catch(error => {
        console.log('Error authenticating user while getting polls');
    });
}

export const getPollsListDetails = (authorization, poll_ids) => {
    return buildAxiosTapServer().request({
        url: 'vip/service/polls/list/details',
        method: 'get',
        data: { poll_ids },
        headers: {
            authorization,
            'content-type': 'application/json'
        }
    }).then(response => {
        if (response.status === 200 && response.data) {
            return response.data;
        }
    })
    .catch(error => {
        console.log('Error authenticating user while getting polls details');
    });
}

//--------------------- influencer -----------------------------------------------------------------------------

export var influencerLoginByApi = (authType, email, id, name, gender=null, campaign=null, inviteCode=null, password=null) => {
    let params = constructParams(
        {
            url: CONFIG['api']['influencerLogin']['url'],
            method: CONFIG['api']['influencerLogin']['method']
        },
        { auth_type: authType, device_type: 'mobile', email, id, name, ...(gender && {gender}), ...(campaign && {campaign}), ...(inviteCode && {inviteCode}), ...(password && {password}) }
    );
    return (dispatch, getState) => {
        params.data['device_type'] = getState().appReducer.deviceType;
        dispatch(startInfluencerAuthentication());
        return buildAxiosTap(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status) {
                dispatch(completeInfluencerAuthentication(xhrResponse.data));
                return xhrResponse.data;
            } else {
                throw new Error(xhrResponse.data.message);
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var setInfluencerLoggedIn = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerLogin.post'],
            method: 'post'
        },
        {influencer: data}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCacheUpdateByApi = (vipUserId) => {
    console.log('line 699')
    let params = constructParams(
        {
            url: CONFIG['api']['vipUserCacheUpdate']['url'],
            method: CONFIG['api']['vipUserCacheUpdate']['method']
        },
        {vipUserId}
    );
    console.log('line 707')
    return buildAxiosTapServerInternal().request(params)
    .then(xhrResponse => {
        console.log('line 710')
        if(xhrResponse.status == 200 && xhrResponse.data.status) {
            // dispatch(completeInfluencerAuthentication(xhrResponse.data));
            console.log('line 713')
            return xhrResponse.data;
        } else {
            return xhrResponse.data.message;
            // throw new Error(xhrResponse.data.message);
        }
    })
    .catch(error => {
        return error;
    });
};

export var emailCheckByApi = (email) => {
    let params = constructParams(
        {
            url: CONFIG['api']['emailCheck']['url'],
            method: CONFIG['api']['emailCheck']['method']
        },
        {email}
    );

    return buildAxiosTapServer().request(params)
    .then(xhrResponse => {
        if(xhrResponse.status == 200 && xhrResponse.data.status) {
            // dispatch(completeInfluencerAuthentication(xhrResponse.data));
            return xhrResponse.data;
        } else {
            throw new Error(xhrResponse.data.message);
        }
    })
    .catch(error => {
        return error;
    });
};

export var passwordForgotByApi = (email) => {
    let params = constructParams(
        {
            url: CONFIG['api']['passwordForgot']['url'],
            method: CONFIG['api']['passwordForgot']['method']
        },
        {email}
    );

    return (dispatch, getState) => {
        return buildAxiosTap(dispatch, getState).request(params)
        .then(xhrResponse => {
            if(xhrResponse.status == 200 && xhrResponse.data.status) {
                // dispatch(completeInfluencerAuthentication(xhrResponse.data));
                return xhrResponse.data;
            } else {
                throw new Error(xhrResponse.data.message);
            }
        })
        .catch(error => {
            return error;
        });
    }    
};

export var influencerRegisterProfileDefaultsAction = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerRegisterProfileDefaults.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                dispatch(completeInfluencerRegisterProfileDefaults(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerRegisterProfileSave = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerRegisterProfileSave.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                // dispatch(completeCampaignSave({data}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerRegisterKidsDefaultsAction = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerRegisterKidsDefaults.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                dispatch(completeInfluencerRegisterKidsDefaults(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerRegisterKidsSave = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerRegisterKidsSave.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                // dispatch(completeCampaignSave({data}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerProfile = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerProfile.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerProfileSocial = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerProfileSocial.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignsApplied = (influencerId, page, pageSize, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignsApplied.post'],
            method: 'post'
        },
        {influencerId, page, pageSize, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignApplicationStatus = (influencerId, campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignApplicationStatus.post'],
            method: 'post'
        },
        {influencerId, campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignApply = (influencerId, campaignId, brandId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignApply.post'],
            method: 'post',
            headers: {
                'appVersion': '0', 
                'deviceType': 'web'            
            }
        },
        {influencerId, campaignId, brandId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialAccounts = (influencerId, type=null) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialAccounts.post'],
            method: 'post'
        },
        {influencerId, type}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignModerationUpdate = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignModerationUpdate.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignSubmission = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignSubmission.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            throw error;
            // console.log(error);
        });
    }
};

export const influencerCampaignSubmissionEdit = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignSubmissionEdit.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export const influencerCampaignSubmissionStatus = data => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignSubmissionStatus.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerCampaignFeedbackUpdate = (campaignId, influencerId, answerList) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignFeedbackUpdate.post'],
            method: 'post'
        },
        {campaignId, influencerId, answerList}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialAccountUpdate = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialAccountUpdate.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialAccountDelete = (influencerId, type) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialAccountDelete.post'],
            method: 'post'
        },
        {influencerId, type}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialFbPageConnectInitiate = (influencerId, value) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialFbPageConnectInitiate.post'],
            method: 'post'
        },
        {influencerId, value}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialFbPageAccountList = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialFbPageAccountList.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialFbPageAccountConnect = (influencerId, pageId, name, fanCount, picUrl, accessToken, link) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialFbPageAccountConnect.post'],
            method: 'post'
        },
        {influencerId, pageId, name, fanCount, picUrl, accessToken, link}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerTiktokConnect = (influencerId, username, connectType='new') => {
    const params = constructParams(
        {
            url: '/social/auth/tiktok',
            method: 'get'
        },
        {influencerId, username, connectType}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var influencerTiktokConnectPreview = username => {
    const params = constructParams(
        {
            url: '/social/auth/preview/tiktok',
            method: 'get'
        },
        {username}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var mobTiktokConnectCallback = (influencerId, username, code, state, error) => {
    const params = constructParams(
        {
            url: CONFIG['paths']['apiTiktokMobCallback.get'],
            method: 'get'
        },
        {influencerId, username, code, state, error}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var influencerCampaignUrlShorten = (influencerId, campaignId, url) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerCampaignUrlShorten.post'],
            method: 'post'
        },
        {influencerId, campaignId, url}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerFeedbackAdd = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerFeedbackAdd.post'],
            method: 'post'
        },
        formData
    );
   
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                // dispatch(completeCampaignSave({data}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerSocialInstaGraphConnectInitiate = (influencerId, value) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerSocialInstaGraphConnectInitiate.post'],
            method: 'post'
        },
        {influencerId, value}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerInstaBusinessAccountConnect = (influencerId, pageId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerInstaBusinessAccountConnect.post'],
            method: 'post'
        },
        {influencerId, pageId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var influencerValidateValue = (influencerId, key) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['influencerValidateValue.post'],
            method: 'post'
        },
        {influencerId, key}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

//--------------------- brand -----------------------------------------------------------------------------

export var brandAdminSignup = (brandId, firstName, lastName, email, password) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminSignup.post'],
            method: 'post'
        },
        {brandId, firstName, lastName, email, password}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandAdminLogin = (email, password) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminLogin.post'],
            method: 'post'
        },
        {email, password}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandAdminPasswordForget = email => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminPasswordForgot.post'],
            method: 'post'
        },
        {email}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandAdminPasswordReset = (email, password) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminPasswordReset.post'],
            method: 'post'
        },
        {email, password}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandAdminAddAdmin = (brandId, firstName, lastName, email, password, countryId, team, role) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminAddAdmin.post'],
            method: 'post'
        },
        {brandId, firstName, lastName, email, password, countryId, team, role}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                console.log(xhrResponse.data)
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var allBrands = () => {
    let params = constructParams(
        {
            url: CONFIG['paths']['allBrands.post'],
            method: 'post'
        },
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var brandCampaignSave = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignSave.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                data['campaignId'] = xhrResponse.data.insertId;              
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignEdit = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEdit.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignFilterAction = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFilter.post'],
            method: 'post'
        },
        { campaignId }
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeFilterSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandCampaignFilterSave = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFilterSave.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(completeCampaignSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandCampaignFilterTargetCount = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFilterTargetCount.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(completeCampaignSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandCampaignPublish = (campaignId, startDate, endDate, submissionEndDate) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignPublish.post'],
            method: 'post'
        },
        { campaignId, startDate, endDate, submissionEndDate }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignApplicationsFetch = (brandId=null, campaignId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignApplications.post'],
            method: 'post'
        },
        {brandId, campaignId, page, pageSize, sorted, filtered, conditions, extra}
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignApplicationsDownload = (brandId=null, campaignId=null, sorted=[], filtered=[], conditions=[], extra={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignApplicationsDownload.post'],
            method: 'post'
        },
        {brandId, campaignId, sorted, filtered, conditions, extra}
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignSubmittersDownload = (brandId=null, campaignId=null, sorted=[], filtered=[], conditions=[], extra={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignSubmittersDownload.post'],
            method: 'post'
        },
        {brandId, campaignId, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignInfluencerApprove = (campaignId, influencerId, flag, reason='') => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignInfluencerApprove.post'],
            method: 'post'
        },
        {campaignId, influencerId, flag, reason}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignInfluencersApprove = (campaignId, influencerIdList, flag) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignInfluencersApprove.post'],
            method: 'post'
        },
        { campaignId, influencerIdList, flag }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignSubmissions = (campaignId, influencerId, page=1, pageSize=10, conditions=[]) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignSubmissions.post'],
            method: 'post'
        },
        { campaignId, influencerId, page, pageSize, conditions }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignInfluencerDeadlineEdit = (campaignId, influencerId, deadlineDate, comments) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignInfluencerDeadlineEdit.post'],
            method: 'post'
        },
        {campaignId, influencerId, deadlineDate, comments}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignSubmissionVerify = (submissionId, campaignId, influencerId, verify) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignSubmissionVerify.post'],
            method: 'post'
        },
        {submissionId, campaignId, influencerId, verify}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandInfluencerPayments = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerPayments.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandInfluencerPaymentAdjust = (influencerId, paymentType, amount, actionType, comments, currencyCode, campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerPaymentAdjust.post'],
            method: 'post'
        },
        {influencerId, paymentType, amount, actionType, comments, currencyCode, campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandInfluencerGradeAdjust = (influencerId, campaignId, ratingList, comment) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerGradeAdjust.post'],
            method: 'post'
        },
        {influencerId, campaignId, ratingList, comment}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandInfluencerIrisBulkPayoutDownload = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerIrisBulkPayoutDownload.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignFeedbackQuestionsUpdate = (campaignId, questionList) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFeedbackQuestionsUpdate.post'],
            method: 'post'
        },
        {campaignId, questionList}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignFeedbackAnswers = (campaignId, influencerId, page, pageSize, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFeedbackAnswers.post'],
            method: 'post'
        },
        {campaignId, influencerId, page, pageSize, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignFeedbackDownload = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignFeedbackDownload.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignEngagementDownload = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEngagementDownload.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandProfilePostsDownload = (influencerId = null) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandProfilePostsDownload.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var usersDownload = (sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['usersDownload.post'],
            method: 'post'
        },
        {sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var usersDownloadFileCheck = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['usersDownloadFileCheck.post'],
            method: 'post'
        },
        { data }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var usersDownloadFile = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['usersDownloadFile.post'],
            method: 'post'
        },
        { data }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var panelUsersDownload = conditions => {
    let params = constructParams(
        {
            url: CONFIG['paths']['panelUsersDownload.post'],
            method: 'post'
        },
        conditions
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var allUsers = (page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['allUsers.post'],
            method: 'post'
        },
        {page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var allPanelUsers = (conditions, filtered={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['allPanelUsers.post'],
            method: 'post'
        },
        {...conditions, filtered}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var allAdmins = (page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['allAdmins.post'],
            method: 'post'
        },
        {page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandInfluencerApprove = (influencerId, flag, reason='') => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerApprove.post'],
            method: 'post'
        },
        {influencerId, flag, reason}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandInfluencerDelete = (influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerDelete.post'],
            method: 'post'
        },
        {influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export const brandPanelActivate = (panelId, flag) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelActivate.post'],
            method: 'post'
        },
        {panelId, flag}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
        });
    }
};

export var brandCampaignActivate = (campaignId, flag) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignActivate.post'],
            method: 'post'
        },
        {campaignId, flag}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandInfluencerRemind = (influencerId, customReminderText='') => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerRemind.post'],
            method: 'post'
        },
        {influencerId, customReminderText}
    );
    return (dispatch, getState) => {
        // dispatch(startUserAuthentication());
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandInfluencerBulkRemind = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandInfluencerBulkRemind.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        // dispatch(startUserAuthentication());
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignPublishBulkNotification = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignPublishBulkNotification.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        // dispatch(startUserAuthentication());
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandInfluencerApproveTapServer = (
  applicationUserId,
  accountType
) => {
  const params = constructParams(
    {
      url: CONFIG['api']['vipUserApprove']['url'],
      method: CONFIG['api']['vipUserApprove']['method']
    },
    { applicationUserId, accountType }
  )
  //request
  return buildAxiosTapServerInternal()
    .request(params)
    .then((xhrResponse) => {
      if (xhrResponse.status == 200) {
        return xhrResponse.data
      }
    })
    .catch((error) => {
      return error
    })
}

export var brandInfluencerRoleUserTapServer = (userId, roleId, status) => {
  const methodName = 'brandInfluencerRoleUserTapServer'

  const params = constructParams(
    {
      url: CONFIG['api']['vipUserRoleUpdate']['url'],
      method: CONFIG['api']['vipUserRoleUpdate']['method']
    },
    { userId, roleId, status }
  )

  //request
  buildAxiosTapServerWithAdminToken()
    .request(params)
    .then((xhrResponse) => {
      if (xhrResponse.status === 200) {
        return xhrResponse.data
      }
    })
    .catch((error) => {
      return error
    })
}

export var brandAdminUpdate = (adminId, flag = '', is_active = '') => {
    let values = {adminId}
    if(flag !== '') values['flag'] = flag;
    if(is_active !== '') values['is_active'] = is_active;

    let params = constructParams(
        {
            url: CONFIG['paths']['brandAdminUpdate.post'],
            method: 'post'
        },
        values
    );
    return (dispatch, getState) => {
        // dispatch(startUserAuthentication());
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignScreeningQuestions = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignScreeningQuestions.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignScreeningQuestionsUpdate = (campaignId, questionList) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignScreeningQuestionsUpdate.post'],
            method: 'post'
        },
        {campaignId, questionList}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignLinkStats = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignLinkStats.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignLinkStatsDownload = (campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignLinkStatsDownload.post'],
            method: 'post'
        },
        {campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCampaignEmailTracker = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEmailTracker.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignEmailTrackerDownload = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEmailTrackerDownload.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignEmailTrackerLegacyOpened = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEmailTrackerLegacyOpened.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignEmailTrackerLegacySent = (campaignId, page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignEmailTrackerLegacySent.post'],
            method: 'post'
        },
        {campaignId, page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignManager = (page, pageSize, sorted, filtered, conditions) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignManager.post'],
            method: 'post'
        },
        {page, pageSize, sorted, filtered, conditions}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignManagerSave = (managers, campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignManagerSave.post'],
            method: 'post'
        },
        {managers, campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignBriefForInfluencer = (campaignId, influencerId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignBriefForInfluencer.post'],
            method: 'post'
        },
        {campaignId, influencerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

export var brandCampaignModerationUpdate = (postId, payload, influencerId, campaignId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCampaignModerationUpdate.post'],
            method: 'post'
        },
        {postId, payload, influencerId, campaignId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            // console.log(error);
            return error;
        });
    }
};

// For Brand Enquiry Form
export var brandEnquirySave = (enquiryData) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandEnquirySave.post'],
            method: 'post'
        },
        enquiryData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {              
                // dispatch(completeInfluencerRegisterProfileDefaults(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

// ---------- panel --------------
export var panelSave = (data) => {
    const formData = new FormData();
    for (let k in data) {
        if (Array.isArray(data[k])) {
            data[k] = JSON.stringify(data[k]);
        }
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['panelSave.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                data['panelId'] = xhrResponse.data.insertId;              
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandPanelEdit = (data) => {
    const formData = new FormData();
    for (let k in data) {
        if (Array.isArray(data[k])) {
            data[k] = JSON.stringify(data[k]);
        }
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelEdit.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var panelListFetch = (brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset=false, ifDontDispatch=false, isAdmin=1) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['apiPanelList.get'],
            method: 'get'
        },
        {brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, isAdmin}
    );
    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        dispatch(startGlobalLoader());
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                xhrResponse.data.shouldReset = shouldReset;
                xhrResponse.data.page = page;
                dispatch(completeGlobalLoader());
                if(!ifDontDispatch) dispatch(completePanelListFetch(xhrResponse.data));
                return xhrResponse.data;                
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var panelDetailFetch = (panelId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['panelDetail.post'],
            method: 'post'
        },
        {panelId}
    );

    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        // dispatch(startUserAuthentication());
        // axiosClient.defaults.headers.post['content-type'] = 'multipart/form-data';
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePanelSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};


export var APIPanelDetailFetch = (panelId, userId = userId, lng = 'en') => {
    let params = constructParams(
        {
            url: CONFIG['paths']['apiPanelDetail.get'],
            method: 'get'
        },
        {panelId, userId, lng}
    );

    return (dispatch, getState) => {
        // params.data['device_type'] = getState().appReducer.deviceType;
        // dispatch(startUserAuthentication());
        // axiosClient.defaults.headers.post['content-type'] = 'multipart/form-data';
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePanelSave(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandPanelFilterTargetCount = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelFilterTargetCount.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandPanelFilterSave = (data) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelFilterSave.post'],
            method: 'post'
        },
        data
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandPanelFilterAction = (panelId) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelFilterFetch.post'],
            method: 'post'
        },
        { panelId }
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeBrandPanelFilterFetch(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;            
        });
    }
};

export var brandPanelPublish = (panelId, startDate, endDate) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelPublish.post'],
            method: 'post'
        },
        { panelId, startDate, endDate }
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandPanelPublishBulkNotification = panelId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelPublishBulkNotification.post'],
            method: 'post'
        },
        {panelId}
    );
    return (dispatch, getState) => {
        // dispatch(startUserAuthentication());
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandPanelSubmittersFetch = (panelId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelSubmissions.post'],
            method: 'post'
        },
        {panelId, page, pageSize, sorted, filtered, conditions, extra}
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandPanelSubmissionsDownload = (panelId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandPanelSubmissionsDownload.post'],
            method: 'post'
        },
        {panelId, page, pageSize, sorted, filtered, conditions, extra}
    );
    return (dispatch, getState) => {
        return buildAxiosSite().request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandPanelPollExists = pollId => {
    const params = constructParams(
        {
            url: CONFIG['paths']['brandPanelPollExists.post'],
            method: 'post'
        },
        {pollId}
    );
    return buildAxiosSite().request(params)
    .then((xhrResponse) => {
        if(xhrResponse.status === 200) {
            return xhrResponse.data;
        }
    })
    .catch((error) => {
        return error;
    });
};

// ----------------------- credits ---------------------------------------------------------------------------
export var brandCreditsAddEvoucherProvider = (data) => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherProviderAdd.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherProviderList = (body = {}) => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherProviderList.post'],
            method: 'post'
        },
        body ? body: {}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherProviderUpdate = data => {
    const formData = new FormData();
    for (let k in data) {
        formData.append(k, data[k]);
    }
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherProviderUpdate.post'],
            method: 'post'
        },
        formData
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherProviderDelete = providerId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherProviderDelete.post'],
            method: 'post'
        },
        {providerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherTemplateDownload = providerId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherTemplateDownload.post'],
            method: 'post'
        },
        {providerId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherTemplateUpload = evoucherFile => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherTemplateUpload.post'],
            method: 'post'
        },
        {data: evoucherFile}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherPreview = batchId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherPreview.post'],
            method: 'post'
        },
        {batchId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherPreviewSubmit = batchId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherPreviewSubmit.post'],
            method: 'post'
        },
        {batchId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherPreviewCancel = batchId => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherPreviewCancel.post'],
            method: 'post'
        },
        {batchId}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherList = body => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherList.post'],
            method: 'post'
        },
        body
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

export var brandCreditsEvoucherListDownload = body => {
    let params = constructParams(
        {
            url: CONFIG['paths']['brandCreditsEvoucherListDownload.post'],
            method: 'post'
        },
        body
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                // dispatch(setAppParams({loader: false}));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            return error;
            // console.log(error);
        });
    }
};

//--------- stats ---------------------------------------------------------------------------------------------
// export var statsUserApproval = () => {
//     let params = constructParams(
//         {
//             url: CONFIG['paths']['statsUserApproval.post'],
//             method: 'post'
//         },
//         {}
//     );
//     return (dispatch, getState) => {
//         return buildAxiosSite(dispatch, getState).request(params)
//         .then((xhrResponse) => {
//             if(xhrResponse.status == 200) {
//                 return xhrResponse.data;
//                 // dispatch(completeUserAuthentication(xhrResponse.data));
//             }
//         })
//         .catch((error) => {
//             // console.log(error);
//             return error;
//         });
//     }
// };