import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { influencerSocialAccountDelete } from '../../../../../../actions';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';
import { Icon, Typography } from 'antd';

const IconSvg = () => {
    return (
        <span style={{ display: 'inline-block', borderRadius: '50%', width: '30px', height: '30px', position: 'absolute', top: '47px', left: '51px', background: '#517fa5', border: 'none' }}>
            <Icon type="twitter" style={{fontSize: '22px', color: 'white', marginTop: '4px'}} />
        </span>
    );
}

const Twitter = (props) => {
    const t = props.t;
    
    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'twitter');

    const onConnect = () => {
        window.location = '/social/auth/twitter';
    }

    const onCountRefresh = () => {
        if(window.confirm("Are you sure you want to refresh the account?")) {
            props.actions.influencerSocialAccountDelete(props.rdx.influencerReducer.id, 'twitter')
            .then(res => {
                window.location = '/social/auth/twitter';
            })
        }        
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("twitter-icon-social-account-bg")}>
                    <div className={cx("twitter-followers-social-icon")}></div>
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>{t('social_account_connect')} +</button>
            </div>
        );        
    }

    const ConnectedState = () => {
        let data = socialAccount.twitter;

        return (
            <div className="">
                {
                    data.profileImage
                    ? 
                        <div style={{position: 'relative'}} className={cx("followers-profile-image-wrap")}>
                            <img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                            <IconSvg />
                        </div>
                    : 
                        <div style={{position: 'relative'}} className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")}></div>
                            <IconSvg />
                        </div>
                }
                {/*<div>{data.socialName}</div>
                <div><img src={} alt=""/></div>*/}
                <div className={cx("btn", "btn-followers")}>{t('social_account_followers')}: {data.followerCount ? data.followerCount : 0}</div>
                {/*<div>Posts: {data.postsCount ? data.postsCount : 0}</div>*/}

                <div className={cx("refresh-followers-wrap")}>
                    <a href="#" className={cx("btn-refresh")} onClick={e => onCountRefresh()}>
                        <span className={cx("icon-refresh")}></span>
                        <span>{t('social_account_refresh')}</span>
                    </a>
                </div>
            </div>
        );        
    }
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.twitter
                ? <ConnectedState />
                : <ConnectState />
            }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerSocialAccountDelete
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
