import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { genericAction, brandCampaignModerationUpdate } from '../../../../../actions';
import { useBrandCampaignModeration } from '../../../../../hooks/generic';
import CurrentCampaignSidebar from '../../../../../components/Brand/Sidebar/CurrentCampaign';
import notification from '../../../../../components/UIElems/Notification';
import { format, parse } from 'date-fns';
import BaseLayout from '../../../../../components/Layout/Brand/BaseLayout';
import { Modal } from 'antd';
import {Dialog} from 'primereact/dialog';
import {Panel} from 'primereact/panel';
import {DataView, DataViewLayoutOptions} from "primereact/dataview";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import Loader from '../../../../../components/UIElems/Loader';
import { ListItem, GridItem, DialogContent } from '../../../../../components/Brand/Campaign/Moderation/DataGrid';


const ModerationData = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [layout, setLayout] = useState('grid');
    const [selectedCard, setSelectedCard] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [sortKey, setSortKey] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);

    const moderationList = useBrandCampaignModeration(
        () => {},
        props.dispatch,
        props.campaignId,
        null,
        page,
        pageSize,
        forceUpdate
    )    

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    const onPagination = (e) => {
        setPage(e.first);
        setPageSize(e.rows);
    }

    const onPostApprove = (data, isApproved) => {
        setIsLoading(true);
        props.actions.brandCampaignModerationUpdate(data.id, {action: 'approve', value: isApproved}, data.influencerId, props.campaignId)
        .then(() => {
            setIsLoading(false);
            setForceUpdate(!forceUpdate);
            let updateMessage = (isApproved == 1) ? 'Post approved!' : 'Post rejected!';
            notification.send({device: props.rdx.appReducer.deviceType, message: updateMessage, type: 'success'});
        })
    }

    const onShowClick = (data, isVisible) => {
        setSelectedCard(data);
        setIsVisible(isVisible);
    }

    const FeedbackModal = ({data, campaignId}) => {
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
        const [feedback, setFeedback] = useState("");

        const onClick = () => {
            if(window.confirm('Send Feedback?')) {
                setIsModalVisible(true);
            }
        }

        return (
            <span>
                <Button icon="pi pi-comment" onClick={onClick} title="Feedback"></Button>
                <Modal
                    title={'Send Feedback'}
                    visible={isModalVisible}
                    okText="Send"
                    onOk={() => { 
                        setIsFeedbackLoading(true);
                        props.actions.brandCampaignModerationUpdate(data.id, {action: 'feedback', value: feedback}, data.influencerId, campaignId)
                        .then(() => {
                            setForceUpdate(!forceUpdate);
                            notification.send({device: props.rdx.appReducer.deviceType, message: 'Sent!', type: 'success'});
                        }) 
                    }}
                    confirmLoading={isFeedbackLoading}
                    onCancel={e => setIsModalVisible(false)}
                    maskClosable={false}>
                    <textarea value={feedback} onChange={e => setFeedback(e.target.value)} rows="3" cols="50" />
                </Modal>
            </span>
        );
    }    

    const itemTemplate = (data, layout) => {
        if (!data) {
            return null;
        }
        // 
        if (layout === 'list')
            return <ListItem data={data}
                onShowClick={onShowClick} 
                onPostApprove={onPostApprove}
                feedbackElem={<FeedbackModal data={data} campaignId={props.campaignId} />} />;
        else if (layout === 'grid')
            return <GridItem data={data}
                onShowClick={onShowClick} 
                onPostApprove={onPostApprove}
                feedbackElem={<FeedbackModal data={data} campaignId={props.campaignId} />} />;
    }        

    const Header = () => {
        const sortOptions = [
            {label: 'Newest First', value: '!year'},
            {label: 'Oldest First', value: 'year'},
            {label: 'Brand', value: 'brand'}
        ];

        return (
            <div className="p-grid">
                {/*<div className="p-col-6" style={{textAlign: 'left'}}>
                    <Dropdown options={sortOptions} value={sortKey} placeholder="Sort By" onChange={onSortChange} />
                </div>*/}

                <div className="p-col-12" style={{textAlign: 'right'}}>
                    <DataViewLayoutOptions layout={layout} onChange={e => setLayout(e.value)} />
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="content-section introduction">
                <div className="feature-intro">
                    <h1></h1>
                </div>
            </div>

            <div className="content-section implementation">
                <DataView value={moderationList.list}
                    totalRecords={moderationList.total}
                    layout={layout}
                    header={<Header />}
                    itemTemplate={itemTemplate}
                    paginatorPosition={'both'} paginator={true} rows={10} onPage={e => onPagination(e)}
                    sortOrder={sortOrder}
                    sortField={sortField} />

                <Dialog header={selectedCard ? `${selectedCard.type} - ${selectedCard.name}` : ''}
                    visible={isVisible}
                    width="225px" modal={true}
                    onHide={() => setIsVisible(false)}>
                    <DialogContent selectedCard={selectedCard} />
                </Dialog>
            </div>

            {/*<DataViewDoc />*/}

            { isLoading && <Loader /> }
        </div>
    );
}




//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
        brandCampaignModerationUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ModerationData);