import App from './App';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './concern/history';//important!! -- dont use browser router
import configureStore from "./store";
import React from 'react';
import { hydrate } from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
// import Loadable from 'react-loadable';
// import bugsnag from '@bugsnag/js';
// import bugsnagReact from '@bugsnag/plugin-react';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from './other/localization/i18n';

// Let the reducers handle initial state
const store = configureStore(window.__REDUX_STATE__ || null);
//render or hydrate -- ssr
// let renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
//bugsnag
// const bugsnagConfig = {
//     apiKey: process.env.RAZZLE_REACT_APP_ENV == 'production' ? '50d9c8e94db2e39314a6e781956bf8e2' : '3dddc9f4872d27251e09d84b89d84cac',
//     beforeSend: beforeErrorSend
// };
// const bugsnagClient = bugsnag(bugsnagConfig);
// bugsnagClient.use(bugsnagReact, React);
// const ErrorBoundary = bugsnagClient.getPlugin('react');
// const beforeErrorSend = (report) => {
//     return false;
//     // if(report.errorClass.indexOf('Error') == -1) return false;
// }

hydrate(
    <I18nextProvider i18n={i18nInstance}>
        <Provider store={store}>
            <Router history={history}>
                {/*<ErrorBoundary beforeSend={beforeErrorSend}>*/}
                <App />
                {/*</ErrorBoundary>*/}
            </Router>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
