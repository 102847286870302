import React, { Fragment } from 'react';

const StickyFooter = props => {
    return (
        <Fragment>
            <div className="sticky-footer-phantom"></div>
            <div className="sticky-footer">
                {props.children}
            </div>
        </Fragment>

    )
}

export default StickyFooter;