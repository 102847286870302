import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import FaqComponent from '../../../components/Mob/Faq';
import { translate } from 'react-i18next';
import CONFIG from '../../../config';
import { myIsSet } from '../../../helpers';

const Faq = (props) => {

    return (
        <div className="">
            <FaqComponent t={props.t}
                country={myIsSet(props, 'rdx.webviewReducer.profile.country') ? props.rdx.webviewReducer.profile.country : props.rdx.appReducer.country}
                lang={myIsSet(props, 'rdx.webviewReducer.profile.langCode') ? props.rdx.webviewReducer.profile.langCode : 'en'}
                howToUrl={CONFIG['siteUrl'] + `mob/how?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Faq));
