import { Carousel } from "antd";
import React, { useState } from "react";

import LineIndicator from "../LineIndicator";

const EarningSteps = (props) => {
    const [ slide, setSlide ] = useState(0);

    const { title, steps = [] } = props;

    const setCurrentSlide = (s) => {
        setSlide(s);
    }

    return (
        <div className={"earning-steps"}>
            <div className={"es-title"}>
                { title }
            </div>
            <LineIndicator
                current={slide + 1}
                capacity={steps.length}
            />
            <Carousel
                afterChange={setCurrentSlide}
                autoplay={false}
            >
                {
                    steps.map((item, index) => {
                        return (
                            <div className={"e-step"} key={`e-s-${index}`}>
                                <span>{item.title}</span>
                                <img src={item.image} />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default EarningSteps;