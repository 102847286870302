import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandInfluencerGradeAdjust } from '../../../../actions';
import { Typography, Tooltip, Modal, Icon } from 'antd';
import notification from '../../../../components/UIElems/Notification';

const { Text } = Typography;

const Comment = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState(props.comment ? props.comment : '');

    let sliceLength = 'sliceLength' in props ? props.sliceLength : 8;

    const onClick = () => {
        setIsVisible(true);
    }

    return (
        <span>            
            <Tooltip title={comment}>
                {(comment && comment.length > sliceLength) ? comment.slice(0, sliceLength) + '...' : comment}
            </Tooltip>
            <a onClick={onClick} style={{color: 'inherit'}}><Icon type="edit" /></a>            
            <Modal
                title={'Comment'}
                visible={isVisible}
                okText="Save"
                onOk={() => {
                    setIsLoading(true);
                    //set
                    props.actions.brandInfluencerGradeAdjust(props.influencerId, props.campaignId, [], comment)
                    .then(() => {
                        setIsLoading(false);
                        setIsVisible(false);
                        // notification.send({device: 'desktop', message: 'Done!', type: 'success'});
                    })
                }}
                confirmLoading={isLoading}
                onCancel={e => setIsVisible(false)}
                maskClosable={false}>
                <textarea value={comment} onChange={e => setComment(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerGradeAdjust
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment);