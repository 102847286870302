import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import SubmitterTable from './Table/Submitters';
import { genericAction } from '../../../../actions';

const CampaignAllSubmissions = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);
    
    return (
        <BaseLayout leftCards={[
                <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Job Submitters'/>
            ]}>
            <div className="campaign-applications">
                <h1>Approved Submitters - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <SubmitterTable campaignId={props.match.params.id}
                    conditions={[
                        {field: 'tapfluencer_campaign_influencer.is_approved', val: 1}
                    ]} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignAllSubmissions);