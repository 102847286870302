import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parse } from 'date-fns';
// import "react-datepicker/dist/react-datepicker.css";
import FeedbackCreate from '../../../../containers/Brand/Campaign/Feedback/Create';
import notification from '../../../UIElems/Notification';
import { Icon, Popover } from 'antd';
import enGB from "date-fns/locale/en";
import { useCurrencyCreditConversionList } from '../../../../hooks/generic';
import CONFIG from '../../../../config';

const Preview = ({data, onPanelPublish, panelPublishBulkNotification}) => {
    const conversionList = useCurrencyCreditConversionList();
    if(conversionList.length && data.currency_type && data.payment_type==='vipcredits'){
        data.payment_value = data.payment_value * conversionList.find(c => c.currency_code.toLowerCase() === data.currency_type.toLowerCase())['equivalent_credits'];
    }
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //set intitial date
    useEffect(() => {
        registerLocale("en", enGB);
        if(data.start_date) setStartDate(parse(data.start_date));
        if(data.end_date) setEndDate(parse(data.end_date));
    }, [])

    const PollImagePopover = ({imgSrc}) => {
        const imgPaths = imgSrc.split('/');
        let imgName = imgPaths[imgPaths.length-1];
        if (imgName.startsWith('10')) {
            imgName = imgName.substr(2);
        } else {
            imgName = imgName.substr(1);
        }
        const content = (
            <div>
                <img src={imgSrc} width={420} />
            </div>
        )
        return (
            <Popover content={content} title="Poll Image" placement="bottomLeft">
                <span style={{cursor: "pointer", color: "#249aff", fontWeight:"bold"}}>{imgName}</span>
            </Popover>
        );
    }

    return (
        <Fragment>
            <div className={cx("campaign-preview-container")}>
                <div className={cx("campaign-preview-wrap")}>
                    
                    <div className={cx("campaign-review-wrap")}>
                        <div className="campaign-review">                            
                            {
                                data.panelType === 'survey' &&
                                <Fragment>
                                    <div className={cx("preview-content")}>
                                        <label>Survey Category</label>
                                        <div>{data.category}</div>
                                    </div>
                                    <div>
                                        <img src={data.jobImage} className={cx("logo-preview")} width="400" alt="survey image" />
                                    </div>
                                    <div className={cx("preview-content")}>
                                        <label>Survey Duration</label>
                                        <div>{data.survey_duration}</div>
                                    </div><div className={cx("preview-content")}>
                                        <label>Survey Description</label>
                                        <div>{data.description}</div>
                                    </div>
                                    <div className={cx("preview-content")}>
                                        <label>Survey Link</label>
                                        <div>{data.webviewJobSubmission}</div>
                                    </div>
                                </Fragment>
                            }
                            {
                                data.panelType === 'poll' &&
                                <Fragment>
                                    <div className={cx("preview-content")}>
                                        <label>Poll Job Name</label>
                                        <div>{data.jobName}</div>
                                    </div>
                                    <div className={cx("preview-content")}>
                                        <label>Image of the Poll(s)</label>
                                        <div>
                                            <PollImagePopover imgSrc={data.jobImage} />
                                        </div>
                                    </div>
                                    <div className={cx("preview-content")}>
                                        <label>Poll Cap number</label>
                                        <div>{data.responseLimit}</div>
                                    </div>
                                </Fragment>
                            }
                            <div className={cx("preview-content")}>
                                <label>Payment point and value</label>
                                <div><span><Icon component={() => <img width="18" height="18" src={data.paymentTypeImage} />} /></span> {data.payment_value}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Payment Description</label>
                                <div>{data.paymentDescription}</div>
                            </div>
                            <div className={cx("form-wrap")}>
                                <label>Start &amp; End Date: </label>
                                <div className={cx("form-horizontal-row")}>
                                    <DatePicker locale={"en"}
                                        selected={startDate}
                                        onChange={setStartDate}
                                        maxDate={endDate}
                                        dateFormat="dd-MM-yyyy" 
                                    /> 
                                    &nbsp; to &nbsp;
                                    <DatePicker locale={"en"}
                                        selected={endDate}
                                        onChange={setEndDate}
                                        minDate={startDate}
                                        dateFormat="dd-MM-yyyy" 
                                    /> 
                                </div>
                            </div>                         
                        </div>
                    </div>
                    <div className={cx("client-details")}>
                            <h2>Client Details</h2>
                            <div className={cx("preview-content")}>
                                <label>Client Name</label>
                                <div>
                                    <h3 className={cx("")}>{data.client_name}</h3>
                                </div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Brand Name</label>
                                <div>{data.brand_name}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>IO Number</label>
                                <div>{data.io_number}</div>
                            </div>
                    </div>
                </div>

                <div className={cx('button-wrap-left')}>
                    <h2>Notifications</h2>
                    <button type="button" className={cx("btn", "btn-blue")} onClick={panelPublishBulkNotification}>
                        Notify users
                    </button>
                </div>

                {
                    data.panelType === 'poll' &&
                    <div className={cx("brand-panel-poll-grid")} style={{margin: '40px 0'}}>
                        {
                            data.pollIds.filter(poll => (poll.id && poll.status) || !poll.id).map((obj, i) => (
                                <div className={cx("form-wrap center-elements")}>
                                    <div className={cx("iframe-label")}>
                                        #{obj.pollId}
                                    </div>
                                    <div className={cx("iframe-wrapper")}>
                                        <iframe src={`${CONFIG['communityUrl']}${CONFIG['communityPollsUrl']}${obj.pollId}`} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }

                <div className={cx("button-wrap")}>                    
                    <button className={cx("btn", "btn-blue")} type="button" onClick={e => onPanelPublish(startDate, endDate, data.id)}>
                        Save
                    </button>
                    <button className={cx("btn", "btn-light")}>Cancel</button>
                </div>

            </div>

        </Fragment>
    );
}

export default Preview;