import React, { useState } from 'react';
import cx from 'classnames/bind';
import ScreeningTags from './ScreeningTags';
import AccordionTab from '../../../../components/UIElems/AccordionTab';

const ScreeningFilters = (props) => {
    const [ isOpen, setIsOpen ] = useState(false);
    let screeningQuestions = props.screeningQuestions;
    let requiredAnswers = props.requiredAnswers;
    let forceUpdate = props.forceUpdate;
    let qNumber = 1;

    return (
        <div>
            <AccordionTab 
                isOpen={isOpen} 
                header={<div>Screening Questions Filters</div>}
                onOpen={() => { setIsOpen(true) }}
                onClose={() => { setIsOpen(false) }}
            >
                <div>
                    {screeningQuestions && <div className="screening-filters">
                        <div className="filtering-meta">
                            <h3 className="filtering-title">{screeningQuestions.screeningFormTitle}</h3>
                            <p className="filtering-desc">{screeningQuestions.screeningFormDesc}</p>
                        </div>
                        <div className='questions-wrapper'>
                            {screeningQuestions.questions.map((q, i) => {
                                return (q.status > 0 && ['checkbox', 'dropdown'].includes(q.type) && <div className="question-div">
                                    <div className="question-title">Q{qNumber++}. {q.question}</div>
                                    {<div>
                                        {q.options && q.options.map((opt, j) => {
                                            return (opt.status > 0 && <div className="answer-option">
                                                <input type="checkbox" checked={requiredAnswers[opt.id]} id={opt.id} name={opt.id} onChange={e => {
                                                    if (e.target.checked === true) {
                                                        props.setRequiredAnswers('add', opt);
                                                    } else {
                                                        props.setRequiredAnswers('delete', opt);
                                                    }
                                                }} />
                                                <label className="option-value">{opt.value}</label>
                                            </div>);
                                        })}
                                    </div>}
                                </div>);
                            })}
                        </div>
                        <div className='btn-holder'>
                            <button type="button" className={cx("btn", "btn-blue")} style={{ marginLeft: '10px' }} onClick={e => {
                                setIsOpen(false);
                                props.setForceUpdate(!forceUpdate);
                            }}>Apply filters</button>
                            <button type="button" disabled={Object.keys(requiredAnswers).length === 0} className={cx("btn", "btn-danger")} style={{ marginLeft: '10px' }} onClick={e => {
                                props.setRequiredAnswers('clear');
                                props.setForceUpdate(!forceUpdate);
                            }}>Clear filters</button>
                        </div>
                    </div>}
                </div>
            </AccordionTab>
            <div className="answer-filter-tags">
                {Object.entries(props.requiredAnswers).map(reqAns => {
                    return <ScreeningTags reqAns={reqAns} removeTag={props.removeTag} />
                })}
            </div>
            <br />
        </div>
    );
}

export default ScreeningFilters;