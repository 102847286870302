import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../../components/Brand/Sidebar/CurrentCampaign';
import SubmissionTable from '../Table/Individual';
import { genericAction } from '../../../../../actions';
import { useInfluencerProfile } from '../../../../../hooks/generic';

const ApplicantSubmissions = (props) => {
    const profile = useInfluencerProfile(props.dispatch, props.match.params.influencerId);
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);    

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Job Submitters'/>
            ]}>
            <div className="">
                <a href={`/brand/campaign/${props.match.params.id}/submissions`} className={cx("back-btn-wrap")}>
                    <span className={cx("campaign-sidebar-nav-sprite campaign-back-icon")}></span>
                    <span>Go Back</span>
                </a>
                <h1>Submissions - {profile ? profile.fullname : ''} - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <SubmissionTable campaignId={props.match.params.id}
                    influencerId={props.match.params.influencerId}
                    conditions={[]} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantSubmissions);