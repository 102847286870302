import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import FacebookManualConnect from '../../../../components/Influencer/Profile/Connect/Manual/Fb';
import InstagramManualConnect from '../../../../components/Influencer/Profile/Connect/Manual/Insta';
import FacebookConnect from '../../../../components/Influencer/Profile/Connect/Modules/Fb';
import FacebookPageConnect from '../../../../components/Influencer/Profile/Connect/Modules/FbPage';
import InstagramGraphConnect from '../../../../components/Influencer/Profile/Connect/Modules/InstaGraph';
// import InstagramConnect from '../../../../components/Influencer/Profile/Connect/Modules/Insta';
import TwitterConnect from '../../../../components/Influencer/Profile/Connect/Modules/Twitter';
//import YoutubeConnect from '../../../../components/Influencer/Profile/Connect/Modules/Youtube';
// import LineConnect from '../../../../components/Influencer/Profile/Connect/Modules/Line';
// import PinterestConnect from '../../../../components/Influencer/Profile/Connect/Modules/Pinterest';
import TiktokConnect from '../../../../components/Influencer/Profile/Connect/Modules/Tiktok';
import { translate } from 'react-i18next';

const StepsToConnectInstagramIframe = (props) => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div className={'steps-connect-instagram-iframe'}>
            <a 
                href="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/StepsToConnectInstagram/VIP-Parents-Landing-page.pdf" 
                onClick={(e) => {e.preventDefault(); setOpen(true)}} 
            >
                Click to see Steps to connect Instagram
            </a>

            <span> / </span>

            <a 
                href="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/StepsToConnectInstagram/VIP-Parents-Landing-page.pdf" 
                target="_blank"
            >
                Download PDF
            </a>

            <div className={`popup ${isOpen ? "active" : ""}`}>
                <section>
                    <span onClick={() => setOpen(false)} >X</span>

                    <iframe src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/StepsToConnectInstagram/VIP-Parents-Landing-page.pdf" 
                        title="description" 
                        loading="lazy"
                    />
                </section>
            </div>
        </div>
    )
}

const Connect = (props) => {
    const {t} = props;

    return (
        <BaseLayout leftCards={[]}>
            <h2>{t('reconnect_social_accounts')}</h2>
            <div className="influencer-profile-connect">
                <FacebookConnect t={t} />
                {/*<InstagramConnect t={t} />*/}
                <InstagramGraphConnect t={t} />
                <TwitterConnect t={t} />
                {/*<YoutubeConnect t={t} />*/}
                <FacebookPageConnect t={t} />
                {/*<LineConnect t={t} />*/}
                {/*<PinterestConnect t={t} />*/}
                <TiktokConnect t={t} />
            </div>

            <StepsToConnectInstagramIframe />
        </BaseLayout>
    );
}

export default translate()(Connect);