import React, { useEffect } from 'react';
import { Typography, Tooltip, Icon } from 'antd';
import cx from 'classnames/bind';
import { useState } from 'react';
import { useCurrencyCreditConversionList, useSurveyCategoryList } from '../../../../hooks/generic/index';
import { createImagePreview } from '../../../../utils';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import enGB from "date-fns/locale/en";
import { Fragment } from 'react';
import CONFIG from '../../../../config';
import notification from '../../../UIElems/Notification';
import { brandPanelPollExists } from '../../../../actions';
import Loader from '../../../UIElems/Loader';

const { Text } = Typography;

const Form = ({ onSubmit, values, type, deviceType }) => {

    const dV = (key, values, defaultVal) => {
        return values[key] ? values[key] : defaultVal;
    }

    const conversionList = useCurrencyCreditConversionList();
    const [currencyType, setCurrencyType] = useState(dV('currencyType', values, ''));
    const [paymentValue, setPaymentValue] = useState(dV('payment_value', values, ''));
    let [equivalentCredits, setEquivalentCredits] = useState(dV('equivalentCredits', values, 0));
    const [paymentType, setPaymentType] = useState(dV('payment_type', values, ''));
    if(conversionList.length && currencyType && paymentType === 'vipcredits') {
        equivalentCredits = paymentValue * conversionList.find(c => c.currency_code.toLowerCase() === currencyType.toLowerCase())['equivalent_credits'];
    }    

    const [clientName, setClientName] = useState(dV('client_name', values, ''));
    const [brandName, setBrandName] = useState(dV('brand_name', values, ''));
    const [ioNumber, setIONumber] = useState(dV('io_number', values, 0));
    const [surveyCategoryId, setSurveyCategoryId] = useState(dV('survey_category_id', values, ''));
    const [surveyName, setSurveyName] = useState(dV('jobName', values, ''));
    const [surveyImage, setSurveyImage] = useState(dV('jobImage', values, ''));
    const [surveyImagePreview, setSurveyImagePreview] = useState(dV('surveyImagePreview', values, ''));
    const [surveyDuration, setSurveyDuration] = useState(dV('survey_duration', values, ''));
    const [payment, setPayment] = useState(dV('paymentDescription', values, ''));
    const [description, setDescription] = useState(dV('description', values, ''));
    const [surveyLink, setSurveyLink] = useState(dV('webviewJobSubmission', values, ''));
    const [surveyStartDate, setSurveyStartDate] = useState(values.start_date? new Date(values.start_date): '');
    const [surveyEndDate, setSurveyEndDate] = useState(values.end_date? new Date(values.end_date): '');
    const [imageRequired, setImageRequired] = useState(type === 'create');
    const [currencyCodesMap, setCurrencyCodesMap] = useState({});
    const [panelType, setPanelType] = useState(dV('panelType', values, ''));
    const [responseLimit, setResponseLimit] = useState(dV('responseLimit', values, ''));
    const [pollFetchId, setPollFetchId] = useState();
    const [pollIds, setPollIds] = useState(dV('pollIds', values, []));
    const [isLoading, setIsLoading] = useState(false);

    const surveyCategoryList = useSurveyCategoryList();

    const onSurveyImageChange = (e) => {
        let uploadedFile = e.target.files[0];

        setSurveyImage(uploadedFile);
        createImagePreview(uploadedFile, previewImage => setSurveyImagePreview(previewImage));                

        let fr = new FileReader;

        fr.onload = () => {

            let img = new Image;
            img.onload = function() {
                function gcd (a, b) {
                    return (b === 0) ? a : gcd (b, a%b);
                }

                let w = img.width;
                let h = img.height;
                const r = gcd(h ,w );
                if((w/r) !== 16 && (h/r) !== 9){
                    uploadedFile = null;
                    alert('Aspect ratio of the image should be 16:9');
                }
            };        
            img.src = fr.result;

        };

        fr.readAsDataURL(uploadedFile);
    }

    const onFormSubmit = e => {
        e.preventDefault();
        onSubmit({
            clientName,
            brandName,
            ioNumber,
            surveyCategoryId,
            surveyName,
            surveyImage,
            surveyDuration,
            paymentType,
            paymentValue,
            currencyType,
            payment,
            description,
            surveyLink,
            surveyStartDate: moment(surveyStartDate).format('YYYY-MM-DD'),
            surveyEndDate: moment(surveyEndDate).format('YYYY-MM-DD'),
            panelType,
            responseLimit,
            pollIds
        });
    }

    const addPollToJob = id => {
        setIsLoading(true);
        if (!id || id === '') {
            return notification.send({device: deviceType, message: 'No ID provided. Please provide a poll ID to fetch!', type: 'error'});
        }
        const existingPoll = pollIds.find(pid => id === pid.pollId);
        if (existingPoll && ((existingPoll.id && existingPoll.status) || !existingPoll.id)) {
            return notification.send({device: deviceType, message: 'This poll ID is already in the list. Please add another ID!', type: 'error'});
        }
        brandPanelPollExists(id).then(data => {
            if (!data.data.ifPollExists) {
                setIsLoading(false);
                const newPolls = [...pollIds];
                if (existingPoll && existingPoll.id && !existingPoll.status) {
                    existingPoll.status = 1
                } else {
                    newPolls.push({pollId: id});
                }
                setPollIds(newPolls);
                setPollFetchId('');
            } else {
                throw new Error('Poll has already been used in another job. Please add another poll!');
            }
        }).catch(err => {
            setIsLoading(false);
            return notification.send({device: deviceType, message: err.message, type: 'error'});
        });
    }

    const removePoll = poll => {
        const newPollIds = [...pollIds];
        if (poll.id) {
            poll.status = 0;
        } else {
            const i = newPollIds.findIndex(p => p === poll);
            newPollIds.splice(i, 1);
        }
        setPollIds(newPollIds);
    }

    useEffect(() => {
        registerLocale("en", enGB);
        setSurveyImagePreview(surveyImage);
    }, []);

    useEffect(() => {
        const newCurrencyCodes = {};
        conversionList.forEach(conversion => {
            newCurrencyCodes[conversion['currency_code']] = conversion;
        });
        setCurrencyCodesMap(newCurrencyCodes);
    }, [conversionList]);

    useEffect(() => {
        if (currencyCodesMap[currencyType] && paymentType === 'vipcredits' && currencyType > '') {
            setEquivalentCredits(currencyCodesMap[currencyType].equivalent_credits * paymentValue);
        }
    }, [currencyType, paymentValue, paymentType]);

    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <div className={cx("form-vertical-row")}>
                    <Text code>Part I</Text>
                    <h2>
                        Client Details&nbsp;
                        <Tooltip title="Enter all the client's details here">
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </h2>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="client-name">Client Name: </label>
                        <input id="client-name" type="text" value={clientName} onChange={e => setClientName(e.target.value)} required />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="brand-name">Brand Name: </label>
                        <input id="brand-name" type="text" value={brandName} onChange={e => setBrandName(e.target.value)} required />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="io-number">IO Number: </label>
                        <input id="io-number" type="text" value={ioNumber} onChange={e => setIONumber(e.target.value)} required />
                    </div>

                    <Text code>Part II</Text>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="panel-type">Do you want to create a survey or a poll?</label>
                        <div className={cx("form-horizontal-row")}>
                            <div style={{marginRight: '15px'}}>
                                <input type="radio" name="panel-type" value="survey" checked={panelType === 'survey'} onChange={e => setPanelType(e.target.value)} />
                                &nbsp;&nbsp;
                                <label className={cx("style-for-radio")} style={{color: "#000000", fontWeight: "400"}}>Survey </label>
                            </div>
                            
                            <div>
                                <input type="radio" name="panel-type" value="poll" checked={panelType === 'poll'} onChange={e => setPanelType(e.target.value)} />
                                &nbsp;&nbsp;
                                <label className={cx("style-for-radio")} style={{color: "#000000", fontWeight: "400"}}>Poll </label>
                            </div>
                            
                        </div>
                        
                    </div>

                    {
                        panelType === 'survey' &&
                        <Fragment>
                            <h2 htmlFor="">
                                Survey Category:&nbsp;
                                <Tooltip title="Select the category here">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </h2>
                            <div className={cx("form-wrap")}>
                                <select id="survey-category" value={surveyCategoryId} onChange={e => setSurveyCategoryId(e.target.value)} required>
                                    <option value="" disabled>Please Select</option>
                                    {
                                        surveyCategoryList.map((category, i) => {
                                            return <option value={category.id} key={i}>{category.name}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className={cx("form-wrap")}>
                                <label htmlFor="survey-name">Survey Name: </label>
                                <input id="survey-name" type="text" value={surveyName} onChange={e => setSurveyName(e.target.value)} required />
                            </div>
                        </Fragment>
                    }

                    {
                        panelType === 'poll' &&
                        <Fragment>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="survey-name">Poll Job Name: </label>
                                <input id="survey-name" type="text" value={surveyName} onChange={e => setSurveyName(e.target.value)} required />
                            </div>
                        </Fragment>
                    }
                        <div className={cx("form-wrap")}>
                            <label htmlFor="survey-image">{panelType === 'poll' ? 'Image of the Poll(s)' : 'Image of the product or event:'}&nbsp;
                                <Tooltip title="Add an image that explains the Survey more. This image will be viewable by the VIParent on the detailed brief">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </label>
                            <div>
                                <span className={cx("btn-choose-file")}>
                                    <input id="survey-image" type="file" className={cx("choose-file")} onChange={onSurveyImageChange} required={imageRequired} />
                                    <label htmlFor="">
                                        <span>Choose File</span>
                                        <span className={cx("upload-icon")}></span>
                                    </label>
                                </span>
                                <span>Extension support : jpeg, jpg & png</span>
                            </div>
                        </div>
                        <div>
                            {surveyImagePreview && <img src={surveyImagePreview} className={cx("logo-preview")} alt="image-preview" width="400" />}
                        </div>
                    {
                        panelType === 'survey' &&
                        <Fragment>
                            <h2 htmlFor="survey-duration">
                                Survey Duration(In mins):&nbsp;
                            </h2>
                            <input id="survey-duration" type="number" min={0} value={surveyDuration} onChange={e => setSurveyDuration(e.target.value)} required />
                        </Fragment>
                    }

                    <h2 htmlFor="payment-type">
                        Payment Type:&nbsp;
                            <Tooltip title="Select the payment type">
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </h2>
                    <div className={cx("form-wrap")}>
                        <select id="payment-type" value={paymentType} onChange={e => setPaymentType(e.target.value)} required>
                            <option value="" disabled>Please Select</option>
                            <option value="vipcredits">VIP Credits</option>
                            <option value="points">Points</option>
                        </select>
                    </div>

                    {
                        paymentType === 'vipcredits' &&
                        <Fragment>
                            <h2 htmlFor="currency-type">Currency Type:&nbsp;
                                <Tooltip title="ex - USD, INR, RMB etc">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </h2>
                            <div className={cx("form-wrap")}>
                                <select id="currency-type" value={currencyType} onChange={e => setCurrencyType(e.target.value)} >
                                    <option value="" hidden>Please Select</option>
                                    {
                                        conversionList.map((conversion, i) => {
                                            return <option value={conversion.currency_code} key={i}>{conversion.currency_code.toUpperCase()}</option>
                                        })
                                    }
                                </select>
                                <h5>
                                    {currencyCodesMap[currencyType] && currencyType > '' ? 
                                    ((currencyCodesMap[currencyType] && currencyCodesMap[currencyType].equivalent_credits < 1) ? 
                                    `${currencyType.toUpperCase()} ${1/currencyCodesMap[currencyType].equivalent_credits} = 1 Credits`:
                                    `${currencyType.toUpperCase()} 1 = ${currencyCodesMap[currencyType].equivalent_credits} Credits`)
                                 : ''}
                                 </h5>
                            </div>
                        </Fragment>
                    }

                    <div className={cx("form-wrap")}>
                        <label htmlFor="payment-value">Payment Value: </label>
                        <input id="payment-value" type="number" min={0} value={paymentValue} onChange={e => setPaymentValue(e.target.value)} required />
                    </div>

                    {
                        paymentType == 'vipcredits' &&
                        <div className={cx("form-vertical-row")}>
                            <h2 htmlFor="equivalent-credits">
                                Equivalent Credits:
                            </h2>
                            <div className={cx("form-wrap")}>
                                <input style={{backgroundColor: '#e6e6e6'}} id="equivalent-credits" type="number" value={equivalentCredits} onChange={e => setEquivalentCredits(e.target.value)} disabled />
                            </div>
                        </div>
                    }

                    <div className={cx("form-wrap")}>
                        <label htmlFor="payment">Payment Description: </label>
                        <input id="payment" type="text" value={payment} onChange={e => setPayment(e.target.value)} required />
                    </div>

                    {
                        panelType === 'survey' &&
                        <Fragment>
                            <h2 htmlFor="description">A little bit about this survey:&nbsp;
                                <Tooltip title="Give a synopsis of this survey.">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </h2>
                            <div className={cx("form-wrap")}>
                                <textarea id="description" type="text" value={description} onChange={e => setDescription(e.target.value)} cols="20" rows="4"></textarea>
                            </div>

                            <div className={cx("form-wrap")}>
                                <label htmlFor="survey-link">Survey Link: </label>
                                <input id="survey-link" type="text" value={surveyLink} onChange={e => setSurveyLink(e.target.value.trim())} required />
                            </div>
                        </Fragment>
                    }

                    {
                        panelType === 'poll' &&
                        <Fragment>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="response-limit">Poll Response limit: </label>
                                <input id="response-limit" type="number" min={0} value={responseLimit} onChange={e => setResponseLimit(e.target.value)} required />
                            </div>
                        </Fragment>
                    }

                    <div className={cx("form-wrap")}>
                        <label>Start &amp; End Date: </label>
                        <div className={cx("form-horizontal-row")}>
                            <DatePicker locale={"en"}
                                selected={surveyStartDate}
                                onChange={setSurveyStartDate}
                                dateFormat="dd-MM-yyyy"
                                maxDate={surveyEndDate}
                                required /> 
                            &nbsp; to &nbsp;
                            <DatePicker locale={"en"}
                                selected={surveyEndDate}
                                onChange={setSurveyEndDate}
                                dateFormat="dd-MM-yyyy"
                                minDate={surveyStartDate}
                                required /> 
                        </div>
                        
                    </div>

                    {
                        panelType === 'poll' &&
                        <Fragment>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="poll-fetch">Poll Id: </label>
                                <div className={cx("form-horizontal-row")}>
                                    <input id="poll-fetch" type="number" value={pollFetchId} onChange={e => setPollFetchId(e.target.value)} />
                                    &nbsp;&nbsp;&nbsp;
                                    <button className={cx("btn", "btn-blue")} type="button" onClick={e => addPollToJob(pollFetchId)}>Fetch</button>
                                </div>
                                <div className={cx("brand-panel-poll-grid")}>
                                    {
                                        pollIds.filter(poll => (poll.id && poll.status) || !poll.id).map(obj => (
                                            <div className={cx("form-wrap center-elements")}>
                                                <div className={cx("iframe-label")}>
                                                    #{obj.pollId}
                                                </div>
                                                <div className={cx("iframe-wrapper")}>
                                                    <iframe src={`${CONFIG['communityUrl']}${CONFIG['communityPollsUrl']}${obj.pollId}`} />
                                                </div>
                                                <br/>
                                                <button type="button" className={cx("btn-remove")} onClick={e => removePoll(obj)}><Icon type="delete" theme="outlined" /> Delete Poll</button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Fragment>
                    }

                    <hr />

                    <div className={cx("button-wrap")}>
                        <button className={cx("btn", "btn-blue")} type="submit">{type == 'create' ? 'Next' : 'Save'}</button>
                        <button className={cx("btn", "btn-light")}>Cancel</button>
                    </div>
                </div>
            </form>
            { isLoading && <Loader /> }
        </div>
    );
}

export default Form;