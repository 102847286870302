import React from 'react';
import { useState } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignEmailTracker } from '../../../../../hooks/generic';
import { brandCampaignEmailTrackerDownload } from '../../../../../actions';
import Table from '../../../../../components/Common/Table';
import { format } from 'date-fns';

const EmailsTrackerTable = (props) => {
    const [viewStatusFilter, setViewStatusFilter] = useState(null);
    const [errorFilter, setErrorFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [conditions, setConditions] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);

    const emailTrackerList = useBrandCampaignEmailTracker(
        () => setLoading(false),
        props.dispatch,
        props.panelId,
        page,
        pageSize,
        sorted,
        filtered,
        {
            ...conditions,
            type: 'newPanelPublished',
            ...(viewStatusFilter && {viewStatus: viewStatusFilter}),
            ...(errorFilter && {ifError: errorFilter})
        },
        forceUpdate
    );
    
    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const openedStatus = (ifViewed) => {
        const label = {
            0: 'No',
            1: 'Yes',
        }
        const color = {
            0: ["status-bg-orange", "status-text-orange", "status-style"],
            1: ["status-bg-green", "status-text-green", "status-style"],
        }

        return <span className={cx(color[ifViewed])}>{label[ifViewed]}</span>;
    }

    const errorStatus = (ifError) => {
        const label = {
            0: 'No',
            1: 'Yes',
        }
        const color = {
            0: ["status-bg-orange", "status-text-orange", "status-style"],
            1: ["status-bg-red", "status-text-red", "status-style"],
        }

        return <span className={cx(color[ifError])}>{label[ifError]}</span>;
    }

    const onViewStatusFilter = (value) => {
        setViewStatusFilter(value);
        setForceUpdate(!forceUpdate);
    }

    const onErrorFilter = (value) => {  
        setErrorFilter(value);
        setForceUpdate(!forceUpdate);
    }

    const onDownload = () => {
        if(window.confirm('Download?')) {
            props.actions.brandCampaignEmailTrackerDownload(
                props.panelId,
                page,
                pageSize,
                sorted,
                filtered,
                {
                    ...conditions,
                    type: 'newPanelPublished',
                    ...(viewStatusFilter && {viewStatus: viewStatusFilter}),
                    ...(errorFilter && {ifError: errorFilter})
                }
            )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `email-status.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = [
        {
            Header: "User Id",
            accessor: "userId",
            filterable: false,
            sortable: false,
            Footer: "Total: " + emailTrackerList.total
        },
        {
            Header: "Name",
            accessor: "fullname",
            filterable: true,
            sortable: false
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: true,
            sortable: false
        },
        // {
        //     Header: "Type",
        //     accessor: "type",
        //     filterable: false,
        //     sortable: false
        // },
        {
            Header: "Opened",
            accessor: "ifViewed",
            filterable: false,
            sortable: false,
            Cell: props => openedStatus(props.value)
        },
        {
            Header: "Viewed On",
            accessor: "viewedAt",
            filterable: false,
            sortable: false,
            Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
        },
        {
            Header: "Error",
            accessor: "ifError",
            filterable: false,
            sortable: false,
            Cell: props => errorStatus(props.value)
        }
    ];

    return (
        <div>

            <div className="form-horizontal-row">
                <div className={cx("form-wrap")}>
                    <label htmlFor="view-status">Email Status: </label>
                    <select id="view-status" name="view_status" onChange={e => onViewStatusFilter(e.target.value)}>
                        <option value="">All</option>
                        <option value="1">Opened</option>
                        <option value="0">Un Opened</option>
                    </select>
                </div>

                <div className={cx("form-wrap")}>
                    <label htmlFor="error-filter">Error: </label>
                    <select id="error-filter" name="error_filter" onChange={e => onErrorFilter(e.target.value)}>
                        <option value="">select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>

                <div className={cx("form-wrap")}>
                    <button type="button" className={cx("btn", "btn-danger")} onClick={e => onDownload()}>Download</button>
                </div>
            </div>

            <div>&nbsp;</div>

            <Table
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                columns={columns}
                manual
                data={emailTrackerList.list}
                pages={Math.ceil(emailTrackerList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignEmailTrackerDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailsTrackerTable);