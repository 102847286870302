export const myIsSet = (obj, key, checkNull = false, checkEmpty = false) => {
  if (typeof obj == 'undefined') {
    return false
  }
  let keys = key.split('.')
  if (keys.length === 0) {
    return false
  }
  for (let i in keys) {
    if (obj != null && obj.hasOwnProperty(keys[i])) {
      obj = obj[keys[i]]
    } else {
      return false
    }
  }
  if (checkNull === true && obj === null) {
    return false
  }
  return !(checkEmpty === true && obj === '')
}

export const getUrlParameter = (name) => {
  if (typeof window != 'undefined') {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = regex.exec(window.location.search)
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  } else {
    return ''
  }
}
