/**/
const CONFIG = {
    en: [
        {
            "heading": "About theAsianparent:",
            "desc": "theAsianparent is the flagship parenting portal of Tickled Media and the largest community of mums across Southeast Asia. We are the #1 parenting portal in Asia with theAsianparent reaching 25M Asian women across 12 countries and 13 languages.\n Given our online platform's success, it isn't surprising that theAsianparent app is currently at 1.6 million downloads and is the world’s highest-rated parenting app!"
        },
        {
            "heading": "More about theAsianparent's Influencer Platforms TapFluencer and VIP Mums:",
            "desc": "From theAsianparent’s inception, we have worked tirelessly to build our reputation as a parenting expert that mums and dads in the region trust. Through this journey, we have connected with numerous childcare experts, bloggers, advocates and most importantly, mums and dads. Each have contributed their thoughts and opinions into our content be it through resource, lifestyle or branded content.\n Through TapFluencer and VIP Mums, we have made these connections official, bringing onboard some pretty awesome key opinion leaders to work with us on a wide variety of projects."
        },
        {
            "heading": "The main difference between TapFluencer and VIP Mums:",
            "desc": "It is pretty simple - if you have over 5000 followers on a single social media platform, you can apply to be in our TapFluencer pool. However, if your following is a little bit smaller, you maybe more suited for our VIP Mums group."
        },
        {
            "heading": "There are many benefits to being a TapFluencer or a VIP Mum:",
            "desc": "Get the opportunity to review products, and get paid for your review.\nHave access to exclusive giveaways not open to our entire audience.\nGet exclusive invites to events organised by TAP or TAP's clients, and much more!"
        },
        {
            "heading": "Requirements to be a TapFluencer:",
            "desc": "Naturally, you need to be a parent or be pregnant.\nHave more than 5000 followers on either Facebook or Instagram.\nHaving a blog rich in parenting content is a plus point!\nNot be connected to other influencer agencies or parenting platforms.\n"
        },
        {
            "heading": "Requirements to be a VIP Mum:",
            "desc": "You need to be a parent or be pregnant.\nYou need to have a following of 500 or more on either Facebook or Instagram. (Having a blog is a plus!)\nYou should be willing to work with brands and post sponsored posts on your social media platforms."
        },
        {
            "heading": "Duration of these partnerships:",
            "desc": "There is no binding contract to be part of the TapFluencer Network or VIP Mums. Thus, there is no expiration date to either one!\nHowever, should by any circumstances you would like to opt out and pursue other opportunities, we will require adequate notice to be submitted. No hard feelings, we promise!"
        },
        {
            "heading": "Will theAsianparent act as your 'talent manager'?:",
            "desc": "No, as there won’t be any binding contract nor any strict exclusivity aside from working with other parenting titles/publications, we allow our TapFluencer and VIP Mums to engage with direct clients who may invite them to events, send samples or tap on them for individual projects. Instead, we will approach our TapFluencer and VIP Mums with opportunities with our own set of clients should there be any requirements in specific campaigns."
        },
        {
            "heading": "Will you be doing these projects for free?:",
            "desc": "Absolutely not! We believe in the many years that you have spent diligently crafting your own unique voice and building the fanbase of your blog/social media account. We will definitely be pitching our TapFluencer and VIP Mums to various brands not only in the country but in different TAP markets as well.\nTapFluencers fill a rate card and have their rates are adjusted annually and VIP Mum too will receive competitive rates based on each project’s deliverables."
        },
        {
            "heading": "Whom should I contact in case if I have any issue?",
            "desc": "Based on your country you can write to us at  \n - vipadminsg@tickledmedia.com"
        }
    ],
    id: [
        {
            "heading": "Tentang theAsianparent:",
            "desc": "theAsianparent merupakan situs parenting andalan Tickled Media dan komunitas ibu terbesar di Asia Tenggara. Kami merupakan portal parenting #1 di Asia dengan theAsianparent telah mencapai 25 juta wanita Asia di 12 negara dengan 13 bahasa.\nMengingat keberhasilan platform online kami, tak mengherankan kini app kami telah diunduh 1 juta pengguna dan merupakan app parenting tertinggi di dunia!"
        },
        {
            "heading": "Lebih lanjut tentang TapFluencer dan VIP Mums:",
            "desc": "Tak mengenal kata puas, kami bekerja tanpa lelah untuk meningkatkan reputasi kami sebagai sumber informasi parenting terpercaya. Perjalanan ini membuat kami terkoneksi dengan banyak pakar pola asuh anak, blogger, advokat, dan yang paling penting orangtua. Mereka berkontribusi dengan pemikiran dan opini terhadap konten kami baik dengan menjadi narasumber, gaya hidup atau advertorial brand tertentu.\nMelalui TapFluencer dan VIP Mums, kami membawa beberapa narasumber untuk bekerja sama dengan kami di beragam proyek yang bermanfaat untuk pembaca kami."
        },
        {
            "heading": "Perbedaan TapFluencer dan VIP Mums:",
            "desc": "Sederhana - bagi Anda yang memiliki lebih dari 10.000 followers di media sosial tertentu, maka Anda bisa bergabung menjadi TapFluencer. Namun, jika pengikut Anda sedikit lebih kecil dari itu Anda tetap berkesempatan untuk bergabung dalam VIP Mums."
        },
        {
            "heading": "Beragam keuntungan yang didapat dengan menjadi TapFluencer atau VIP Mum:",
            "desc": "Kesempatan mengulas produk, bahkan dibayar untuk review Anda.\nAkses eksklusif mendapatkan hadiah spesial\nUndangan terbatas untuk menghadiri event yang diselenggarakan theAsianparent, klien dan masih banyak yang lainnya!\n"
        },
        {
            "heading": "Syarat jika ingin menjadi TapFluencer:",
            "desc": "Telah menjadi orangtua atau sedang hamil.\nMemiliki lebih dari 10.000 followers di Facebook dan/atau Instagram.\nAktif di blog parenting akan menjadi nilai plus!\nTidak terlibat kerjasama dengan agensi atau platform parenting sejenis.\n"
        },
        {
            "heading": "Syarat jika ingin menjadi VIP Mum:",
            "desc": "Telah menjadi orangtua atau sedang hamil.\nMemiliki lebih dari 1.500 atau lebih follower di Facebook dan/atau Instagram. (Aktif di blog akan menjadi nilai plus!)\nBersedia mengunggah pesan sponsor di akun media sosial Anda"
        },
        {
            "heading": "Rentang waktu kerja sama:",
            "desc": "Tidak ada kontrak yang mengikat untuk menjadi bagian dari TapFluencer atau VIP Mums. Dengan kata lain, tidak ada batasan untuk kedua program tersebut.\nNamun, jika dalam suatu kondisi Anda memilih keluar dan mengejar kesempatan lain, kami akan meminta pemberitahuan yang memadai untuk dikirimkan.\n"
        },
        {
            "heading": "Akankah theAsianparent bertindak sebagai Talent Manager Anda?:",
            "desc": "Tidak, karena tidak ada kontrak yang mengikat atau eksklusivitas yang resmi selain dari bekerja sama dengan judul/publikasi parenting lainnya. Kami mengizinkan TapFluencer dan VIP Mums kami untuk terlibat langsung dengan klien yang mengundang mereka ke acara, mengirim sampel atau tertarik bekerja sama dalam proyek pribadi.  Sebagai gantinya, kami akan memberi  TapFluencer dan VIP Mums kesempatan untuk tergabung dalam kampanye tertentu klien kami."
        },
        {
            "heading": "Akankah Anda melakukan projek tersebut secara gratis?:",
            "desc": "Tentu saja, tidak! Kami percaya dalam beberapa tahun Anda telah menghabiskan banyak waktu untuk membangun identitas dengan beragam cara; misalnya meluncurkan fanbase, blog pribadi, dan akun media sosial Anda.\nKami pasti akan memperkenalkan TapFluencer dan VIP Mums ke berbagai brand, tidak hanya di Indonesia tetapi juga di negara lainnya.\nTapFluencer diharapkan untuk mengisi rate card yang mana besarannya disesuaikan setiap tahun. VIP Mum juga akan menerima bayaran yang kompetitif berdasarkan hasil proyeknya masing-masing.\n"
        },
        {
            "heading": "Siapa yang perlu saya hubungi bila saya mengalami masalah?",
            "desc": "Berdasarkan negara Anda, Anda bisa menulisnya kepada kami di  \n - vipadminid@tickledmedia.com"
        }
    ],
    th: [
        {
            "heading": "เกี่ยวกับ theAsianparent:",
            "desc": "theAsianparent เป็นชุมชนออนไลน์ของบริษัท Tickled Media และเป็นชุมชนออนไลน์ที่ใหญ่ที่สุดในเอเชียตะวันออกเฉียงใต้ เราคือชุมชนออนไลน์อันดับ 1 ของพ่อแม่ชาวเอเชีย ด้วยการเข้าถึงผู้หญิงเอเชียถึง 25 ล้านคน จากทั้งหมด 13 ภาษา 12 ประเทศ\nเราประสบความสำเร็จเป็นอย่างมาก จากยอดดาวน์โหลดแอปพลิเคชันมากถึง 1,000,000 ดาวน์โหลด ซึ่งเป็นยอดดาวน์โหลดแอปพลิเคชันสำหรับคุณแม่ที่สูงที่สุดในโลก!"
        },
        {
            "heading": "เกี่ยวกับ ระบบtheAsianparent's Influencer, TapFluencer และ VIP Mums:",
            "desc": "ตั้งแต่แรกเริ่ม เราได้ทำงานอย่างไม่รู้จักเหน็ดเหนื่อยเพื่อสร้างความน่าเชื่อถือให้พ่อแม่ทุกคนในฐานะผู้เชี่ยวชาญด้านการเลี้ยงเด็ก เราได้ทำงานร่วมกับผู้เชี่ยวชาญด้านการดูแลเด็กมากมาย ไม่ว่าจะเป็นคุณหมอ Blogger แบรนด์สินค้าเกี่ยวกับแม่และเด็ก และที่สำคัญที่สุดคือพ่อแม่ทุกคนที่อ่านบทความของเรา ซึ่งทุกคนมีส่วนร่วมในเนื้อหาของเราไม่ว่าจะเป็น บทความวิชาการ  บทความไลฟ์สไตล์ และบทความเชิงพาณิชย์\nระบบ TapFluencer และ VIP Mumsจะช่วยให้เรารู้จักคุณ และเพิ่มโอกาสในการรับงานรีวิวหรืองานอื่นๆ ที่เหมาะกับคุณ"
        },
        {
            "heading": "ข้อแตกต่างระหว่าง TapFluencer และ VIP Mums:",
            "desc": "ถ้าคุณมีผู้ติดตามในโซเชียลมีเดีย (Facebook หรือ Instagram) มากกว่า 5,000 คน คุณสามารถสมัครเป็น TapFluencer  แต่ถ้าหากคุณมีผู้ติดตามน้อยกว่านั้น คุณสามารถสมัครเป็น VIP Mum"
        },
        {
            "heading": "ข้อดีของการเป็น  TapFluencer หรือ  VIP Mum:",
            "desc": "มีโอกาสรับงานรีวิวสินค้า และได้รับค่าตอบแทน \nได้รับของรางวัลพิเศษสุดเฉพาะสมาชิกเท่านั้น\nได้รับเชิญไปงานอีเว้นท์สุดเอ็กซ์คลูซีฟทั้งงานของ theAsianparent, งานเปิดตัวสินค้า และอื่นๆ อีกมากมาย!\n"
        },
        {
            "heading": "เงื่อนไขในการสมัครเป็น  TapFluencer:",
            "desc": "เป็นพ่อแม่ที่มีลูก หรือ กำลังตั้งครรภ์\nมีผู้ติดตามในโซเชียลมีเดีย (Facebook หรือ Instagram) มากกว่า 5,000 คน \nหากมี Blog ด้วย คุณจะได้รับพิจารณาเป็นพิเศษ!\nต้องไม่เป็น  influencer กับเอเยนซี่ หรือระบบ influencer อื่นๆ\n"
        },
        {
            "heading": "เงื่อนไขในการสมัครเป็น  VIP Mum:",
            "desc": "เป็นพ่อแม่ที่มีลูก หรือกำลังตั้งครรภ์\nมีผู้ติดตามใน Facebook หรือ Instagram มากกว่า 1,000 คน (หากมีBlog ด้วย คุณจะได้รับพิจารณาเป็นพิเศษ!)\nสามารถโพสต์งานรีวิวต่างๆ บน Facebook หรือ Instagram ได้\nต้องไม่เป็นสมาชิก หรือร่วมกิจกรรมของเว็บไซต์แม่และเด็กอื่นๆ\n"
        },
        {
            "heading": "ระยะเวลาในการเป็นสมาชิก:",
            "desc": "จะไม่มีการทำสัญญาผูกมัดในการเป็น TapFluencer Network หรือ VIP Mums ดังนั้นจึงไม่มีวันหมดอายุ\nอย่างไรก็ตาม หากคุณต้องการยกเลิกสัญญาไม่ว่าในกรณีใดก็ตาม โปรดแจ้งให้เราทราบล่วงหน้า 1 เดือน\n"
        },
        {
            "heading": "theAsianparent จะทำตัวเป็นเหมือนผู้จัดการของคุณหรือไม่?:",
            "desc": "คำตอบคือไม่ เนื่องจากไม่มีการเซ็นสัญญาผูกมัด หรือข้อบังคับใดๆ นอกจากการห้ามไปร่วมงานกับเอเยนซี่หรือเว็บไซต์อื่นๆที่เกี่ยวกับแม่และเด็ก แต่TapFluencer และ VIP Mums สามารถร่วมงานกับลูกค้าตรง หากลูกค้าเชิญไปงานอีเว้นท์\n หรือส่งสินค้าตัวอย่างมาให้ในโปรเจคแยกอื่นๆ นอกจากนี้เราจะแนะนำคุณให้กับลูกค้าของเราด้วย"
        },
        {
            "heading": "งานรีวิวหรืออื่นๆ จะได้รับสิ่งตอบแทนหรือไม่?:",
            "desc": "มีแน่นอน   ฐานแฟนคลับของคุณมีค่ากับเรามาก ดังนั้นเราจะโปรโมทคุณให้กับลูกค้าทั้งในประเทศและต่างประเทศของเราเช่นกัน\nTapFluencers สามารถแจ้งเรทราคาในการีวิวของคุณตามที่คุณต้องการกับเรา ส่วน VIP MUM เราจะมีราคามาตรฐานให้มากน้อยขึ้นกับตามรายละเอียดงานต่างๆ\n"
        },
        {
            "heading": "หากมีปัญหาฉันควรติดต่อใคร?",
            "desc": "ขึ้นอยู่กับประเทศของคุณ โดยคุณสามารถติดต่อเราได้ที่  \n - vipadminth@tickledmedia.com"
        }
    ],
    vn: [
        {
            "heading": "AVề theAsianparent:",
            "desc": "theAsianparent là cổng thông tin làm cha mẹ trực thuộc công ty Tickled Media và là cộng đồng mẹ lớn nhất tại Đông Nam Á. Chúng tôi cũng là cổng thông tin nuôi dạy con số 1 ở châu Á với người dùng là 25 triệu phụ nữ ở 12 quốc gia, sử dụng 13 ngôn ngữ. Với sự đầu tư thành công vào nền tảng trực tuyến, ứng dụngAsianparent hiện có 1,6 triệu lượt tải và đứng đầu trong những ứng dụng làm cha mẹ được đánh giá cao nhất thế giới!"
        },
        {
            "heading": "Thông tin thêm về nền tảng KOL của theAsianparent và chương trình 'Thành viên VIP':",
            "desc": "Từ sự khởi đầu của theAsianparent, chúng tôi đã làm việc không ngừng để xây dựng mình thành một chuyên gia nuôi dạy con mà các bố mẹ trong khu vực tin tưởng. Thông qua hành trình này, chúng tôi đã kết nối với nhiều chuyên gia chăm sóc trẻ em, các blogger, những fan trung thành và quan trọng nhất là các bố mẹ. Mỗi người đã đóng góp suy nghĩ và ý kiến của họ vào nội dung của chúng tôi thông qua các thông tin, lối sống hoặc nội dung cho thương hiệu. Thông qua chương trình TapFluencer và thành viên VIP, chúng tôi đã làm các mối quan hệ này gắn bó hơn, trao cơ hội cho một số KOL tuyệt vời làm việc với chúng tôi trong nhiều dự án."
        },
        {
            "heading": "Sự khác biệt chính giữa TapFluencer và Thành viên VIP:",
            "desc": "Điều này khá đơn giản - nếu bạn có hơn 5.000 người theo dõi trên một nền tảng truyền thông xã hội, bạn có thể đăng ký tham gia nhóm TapFluencer của chúng tôi. Tuy nhiên, nếu số lượt theo dõi của bạn nhỏ hơn một chút, bạn có thể phù hợp hơn với nhóm Thành viên VIP."
        },
        {
            "heading": "Có rất nhiều lợi ích khi trở thành TapFluencer hoặc Thành viên VIP:",
            "desc": "Có cơ hội dùng thử các sản phẩm và được trả tiền cho đánh giá của bạn. Bạn cũng có quyền truy cập vào các quà tặng độc quyền chỉ dành riêng cho bạn. Ngoài ra, bạn còn được mời độc quyền đến các sự kiện do khách hàng của TAP hoặc TAP tổ chức và hơn thế nữa!"
        },
        {
            "heading": "Yêu cầu gia nhập TapFluencer:",
            "desc": "Bạn đã làm bố mẹ hoặc đang mang thai. Ngoài ra, bạn cần có hơn 5.000 người theo dõi trên Facebook hoặc Instagram. Nếu không, bạn cần có một blog với nội dung hấp dẫn về nuôi dạy con. Bạn không được kết nối với bất kì công ty quản lý KOL hoặc nền tảng nuôi dạy con cái nào."
        },
        {
            "heading": "Yêu cầu gia nhập Thành viên VIP:",
            "desc": "Bạn cần phải là bố mẹ hoặc đang mang thai. Bạn cũng cần có số người theo dõi từ 1.500 trở lên trên Facebook hoặc Instagram. (Có blog là một lợi thế!) Bạn cũng cần sẵn sàng làm việc với các thương hiệu và đăng các bài truyền thông cho thương hiệu trên các trang cá nhân của bạn. Bạn không được là thành viên của bất kỳ chương trình khách hàng thân thiết / phần thưởng / thành viên VIP của bất kỳ cộng đồng / nền tảng nuôi dạy con nào khác ."
        },
        {
            "heading": "Thời hạn làm TapFluencer hoặc Thành viên VIP:",
            "desc": "Không có hợp đồng ràng buộc nào khi bạn là TapFluencer hay Thành viên VIP. Do đó, không có ngày hết hạn cho tài khoản này. Tuy nhiên, trong mọi trường hợp bạn muốn từ chối và theo đuổi các cơ hội khác, chúng tôi sẽ yêu cầu bạn thông báo trước. Không bao giờ có rắc rối, chúng tôi hứa!"
        },
        {
            "heading": "theAsianparent sẽ đóng vai trò là 'người quản lý KOL'?:",
            "desc": "Không, vì sẽ không có bất kỳ hợp đồng ràng buộc cũng như bất kỳ sự độc quyền nào ngoài việc làm việc với các nền tảng làm cha mẹ khác, chúng tôi cho phép các TapFluencer và thành viên VIP làm việc trực tiếp với các khách hàng và họ có thể mời bạn tham gia các sự kiện, gửi mẫu thử hoặc làm các dự án cá nhân khác. Ngoài ra, chúng tôi cũng tiếp cận với TapFluencer và Thành viên VIP để trao cơ hội làm việc với các khách hàng của riêng chúng tôi nếu khi họ có bất kỳ chiến dịch nào."
        },
        {
            "heading": "Bạn sẽ làm những dự án này miễn phí?:",
            "desc": "Tuyệt đối không! Chúng tôi tin rằng trong nhiều năm bạn đã nỗ lực tạo nên một trang cá nhân độc đáo của riêng bạn và xây dựng một lực lượng người hâm mộ vững chắc. Chúng tôi chắc chắn sẽ giới thiệu TapFluencer và thành viên VIP cho các thương hiệu khác không chỉ trong nước mà cả các thị trường TAP khác. TapFluencers có một mức chi trả nhất định và mức chi phí này cũng được điều chỉnh hàng năm. Thành viên VIP cũng sẽ nhận được mức giá cạnh tranh dựa trên mỗi sản phẩm của dự án."
        },
        {
            "heading": "Tôi nên liên hệ với ai trong trường hợp có vấn đề xảy ra?",
            "desc": "Theo từng quốc gia, bạn có thể viết thư cho chúng tôi tại  \n - vipadminvn@tickledmedia.com"
        }
    ],
    my: [
        {
            "heading": "Tentang theAsianparent:",
            "desc": "theAsianparent ialah lambang kemegahan portal keibubapaan Tickled Media dan komuniti ibu-ibu yang terbesar di Asia Tenggara. Kami adalah #1 portal keibubapaan di Asia dengan theAsianparent yang mencapai 25M wanita Asia di 12 buah negara dan dalam 13 bahasa. Berdasarkan kejayaan platform atas talian kami, ia tidak mengejutkan bahawa aplikasi theAsianparent saat ini telah mencecah 1.6 juta muat turun dan merupakan aplikasi keibubapaan tertinggi didunia."
        },
        {
            "heading": "Banyak lagi mengenai theAsianparent Incluencer Platform, Tapfluencer dan VIP Mums:",
            "desc": "Sejak penubuhan theAsianparent, kami telah bekerja keras untuk membina reputasi kami sebagai pakar keibubapaan dimana ibu dan ayah dipercayai. Dalam perjalanan ini, kami telah berhubung dengan banyak pakar penjagaan anak-anak, blogger, penyokong dan yang paling penting, para ibu dan ayah. Setiap mereka menyumbangkan buah fikiran dan pendapat ke dalam konten kami melalui sumber, gaya hidup atau 'branded content'.\n Melalui TapFluencer dan VIP Mums, kami telah membuat hubungan ini rasmi, bekerja sama dengan pelbagai projek yang memberi manfaat kepada pengguna kami."
        },
        {
            "heading": "Perbezaan utama diantara TapFluencer dan VIP Mums:",
            "desc": "Ianya mudah - jika Anda mempunyai lebih 10,000 pengikut di setiap sosial media platform, Anda boleh memohon untuk menjadi TapFluencer. Namun, jika pengikut Anda sedikit kurang, Anda mungkin sesuai untuk menjadi VIP mum kami."
        },
        {
            "heading": "Ada banyak faedah menjadi TapFluencer atau VIP Mum:",
            "desc": "Dapat peluang untuk review barang, dibayar untuk review barang.\n Dapat akses untuk hadiah eksklusif yang tidak terbuka kepada pengikut lain. \nDapat jemputan eksklusif untuk acara yang dianjurkan oleh TAP atau TAP klien dan banyak lagi!"
        },
        {
            "heading": "Syarat-syarat untuk menjadi TapFluencer:",
            "desc": "Anda mestilah seorang ibu bapa atau sedang hamil.\nMempunyai lebih 10,000 pengikut sama ada di Facebook atau Instagram.\nMempunyai blog keibubapaan adalah satu bonus!\nTidak terikat dengan dengan mana mana influencer agensi atau platform keibubapaan."
        },
        {
            "heading": "Syarat-syarat untuk menjadi VIP Mum:",
            "desc": "Anda mestilah seorang ibu bapa atau sedang hamil.\nMempunyai lebih 1,000 pengikut sama ada di Facebook atau Instagram. (Mempunyai blog keibubapaan adalah satu bonus!)\nBersedia untuk menyiarkan barang tajaan di laman sosial anda."
        },
        {
            "heading": "Jangka masa rakan kongsi:",
            "desc": "Tiada terikat dengan kontrak untuk menjadi salah seorang TapFluencer Network atau VIP Mums. Malah, tiada tarikh luput untuk kedua-dua platform ini. Namun, sebarang keadaan dimana Anda pilih untuk keluar dan kejar peluang lain, kami akan meminta notis yang mencukupi untuk dihantar."
        },
        {
            "heading": "Adakah theAsianparent bertindak sebagai 'Talent Manager' anda?:",
            "desc": "No, as there won’t be any binding contract nor any strict exclusivity aside from working with other parenting titles/publications, we allow our TapFluencer and VIP Mums to engage with direct clients who may invite them to events, send samples or tap on them for individual projectTidak, kerana tiada kontrak yang terikat atau mana-mana eksklusif rasmi selain dari bekerja dengan penerbitan keibubapaan lain, kami benarkan TapFluencer dan VIP Mums untuk terlibat secara terus dengan klien yang menjemput mereka ke acara, hantar sampel atau bekerja sama untuk projek individu. Sebaliknya, kami akan mendekatkan TapFluencer atau VIP Mums kami dengan peluang untuk berhubung dengan klien dalam kempen yang tertentu."
        },
        {
            "heading": "Adakah anda akan lakukan projek ini secara percuma?:",
            "desc": "Pastinya tidak! Kami percaya dalam beberapa tahun Anda telah gigih meluangkan banyak masa dengan mencipta suara unik anda sendiri dan membangun akaun/sosial media 'fanbase' Anda. Kami pasti akan perkenalkan TapFluencer dan VIP Mums kami kepada pelbagai jenama bukan sahaja didalam negara tetapi juga di negara lain.\nTapFluencer perlu mengisi 'rate card' dimana ianya akan diatur setiap tahun dan VIP Mums juga akan menerima kadar bayaran yang kompetitif berdasarkan projek masing-masing."
        },
        {
            "heading": "Siapa perlu saya hubungi sekiranya saya menghadapi masalah?",
            "desc": "Berdasarkan negara anda, anda boleh menulis kepada kami di \n - vipadminmy@tickledmedia.com"
        }
    ],
    ph: [
        {
            "heading": "Tungkol sa theAsianParent:",
            "desc": "theAsianparent ang flagship portal para sa mga magulang ng Tickled Media at ang pinakamalaking community ng mommies sa South East Asia. Kami ang #1 parenting portal sa Asia nang umaabot sa 25M kababaehang Asian sa 12 na bansa at 13 languages. Dahil sa pagtagumpay namin dito, di na kakapagtaka na ang theAsianparent app ay nasa 1.6M downloads at ang pinakamataas na rated app."
        },
        {
            "heading": "Tungkol sa theAsianparent Influencer platforms, TapFlluencer at VIP Moms:",
            "desc": "Sa simula pa lang, walang tigil ang pagtrabaho nang theAsianparent para magtayo ng reputation na expert para sa mga magulang na pagkakatiwalaan sa region. Sa pag gawa nito, naka connect kami sa madaming childcare experts, bloggers, tagapagtaguyod at ang pinakaimportante, mga nanay at tatay.  Lahat sila ay nakapag bigay nang sarili nilang mga opinyon at mga isip sa content namin na naging resource, lifestyle or branded content. Sa TapFluencer at VIP Moms, ginawa na namin itong offiicial, at kasama ang mga key opinion leaders na makakatulong sa atin sa mga malawak na iba't ibang mga paksa."
        },
        {
            "heading": "And pagkakaiba ng TapFluencer at VIP Moms:",
            "desc": "Madali lang naman - kung meron kang over 1000 followers sa isang social media platform, puwede ka mag-apply na maging TapFluencer. Pero kung ang following mo ay mas maliit, baka mas magangang nasa VIP Mums group ka."
        },
        {
            "heading": "Mga iba't ibang benefits a pagiging TapFluencer o VIP Mom:",
            "desc": "Mag review ka nang mga products at bayaran ka para sa review mo. May access ka sa mga exclusive giveaways na hindi available sa lahat. Makakuha nang exclusive invites sa mga TAP organized events o TAP client events, at iba pa!"
        },
        {
            "heading": "Ang requirements nang TapFluencer:",
            "desc": "Natural, kailangan mo maging isang magulang o buntis. Kailangan na higit sa 1000 ang followers mo sa Facebook o Instagram. Kung may blog ka na madaming parenting content, malaking plus yon! At dapat hindi ka connected sa ibang influencer agencies o parenting platforms. "
        },
        {
            "heading": "Ang requirements nang VIP Mom:",
            "desc": "Kailangang na magulang ka o buntis. May higit na 1000 followers ka sa Facebook o sa Instagram. (kung may blog ka malaking PLUS yon!) Kailangan willing ka mag-post ng brand-sponsored content sa social media account/s mo."
        },
        {
            "heading": "Gaano katagal ang partnerships:",
            "desc": "Walang kontrata kailangan para sumali nang TapFluencer Network o VIP Moms. Kaya wala din expiration date! Pero kung sakali may mga pangyayari at kaiilangan ninyo mag opt out, o kung gusto ninyo magpursue nang ibang mga opportunities, kakailanganin namin nang submitted notice. Walang tampuhaan, promise!"
        },
        {
            "heading": "Ang theAsianparent ba magiging 'talent manager' mo?:",
            "desc": "Hindi, walang binding kontrata o strikto na exclusivity na kakailanganin maliban sa pagiging member nang ibang parenting titles/publications, at puwede naman ang mga TapFluencer at VIP moms namin mag-engage directly sa mga kliyente nanag-invite sa mga events, nagbigay ng samples, o kunin sila para sa mga individual projects. binibigyan namin ang mga TapFluencer at VIP moms namin nang mga opportunities sa mga kliyente namin lung may mga requirements sa specific campaigns."
        },
        {
            "heading": "Gagawin mo ba itong mga projects for free?:",
            "desc": "Siempre hindi! Naiintindihan namin na may sarili kayong loyal following sa social media kaya gusto namin supportahan ng maigi ang influencer platform namin sa pagdadagdag pa sa malapit nahinaharap. I-pipitch namin ang mga TapFluencer at VIP Moms sa mga iba't ibang brands na hindi lang dito sa bansa natin, pero sa iba't ibang TAP markets din. Ang mga TapFluencers kailangan mag fill up nang rate card at kailangan magadjust nang rates taun-taon, at ang VIP Mom din makakakuha nang competitve rates para sa iba't ibang project deliverables."
        },
        {
            "heading": "Sino ang aking dapat kausapin kapag may issues?",
            "desc": "Base sa'yong bansa, maari mo kaming makausap sa \n - vipadminph@tickledmedia.com"
        }
    ]
}

export default CONFIG;