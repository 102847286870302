import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {} from '../../actions';
import BaseLayout from '../../components/Layout/Brand/BaseLayout';
import StatsTemplateDefaultComponent from '../../components/Stats/Template/Default';
import Loader from '../../components/UIElems/Loader';
import notification from '../../components/UIElems/Notification';

const Stats = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <BaseLayout leftCards={[]}>
            <StatsTemplateDefaultComponent />
            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

export default Stats;
