import React from "react";
import CarouselComponent from "../../UIElems/Carousel";
import EarningSteps from "./EarningSteps";
import Testimonials from "./Testimonials";
import TabbedFAQ from "../../Common/TabbedFAQ";

const WelcomeV1 = (props) => {
    
    const { data = {}, t } = props;
    if(!data.success) {
        return null;
    }

    const pageData = data.data || {};

    const coreBenifitCard = (benifit) => {
        return (
            <div className={"core-benifit-card"}>
                <div>
                    <span>{benifit.title}</span>
                    <span className={"cb-desc"}>{benifit.description}</span>
                </div>
                <img style={{ backgroundColor: benifit.bg_color }} src={benifit.icon} />
            </div>
        )
    }

    const loadCoreBenifits = (benifits = []) => {
        let coreBenifits = [];
        for(let counter = 0; counter < benifits.length; counter += 2) {
            coreBenifits.push(
                <div className={"core-benifits-row"}>
                    {
                        coreBenifitCard(benifits[counter])
                    }
                    {
                        benifits[counter + 1] && coreBenifitCard(benifits[counter + 1])
                    }
                </div>
            )
        }
        return coreBenifits;
    }

    const carouselData = (steps = []) => {
        let data = [];
        for(let counter = 0; counter < steps.length; ++counter) {
            data.push(
                <div className={"e-step"}>
                    {/* {counter + 1} */}
                    <span>{steps[counter].title}</span>
                    <img src={steps[counter].image} />
                </div>
            )
        }
        return data;
    }

    return (
        <div className={"welcome-page-container"}>
            <div className={"header-part"}>
                {/* header image tag comes here */}
                {/* <div dangerouslySetInnerHTML={}></div> */}
                <img src={"https://tapfluencer.s3.ap-southeast-1.amazonaws.com/onboarding_hero_banner.png"} />
                <div><div>{"Create "}</div><div>{"Influence "}</div><div>{"Earn"}</div></div>
                <div>{pageData.subheader}</div>
                <div>{pageData.description}</div>

            </div>
            <div className={"core-benifits-container"}>
                <div className={"cb-header"}>{pageData.core_benefits.title}</div>
                <div className={"core-benifits"}>
                    {
                        loadCoreBenifits(pageData.core_benefits.benefits)
                    }
                </div>
            </div>
            <EarningSteps title={pageData.starting_steps.title} steps={pageData.starting_steps.steps} />
            {
                (pageData.collaborate_brands && pageData.collaborate_brands.brands && (pageData.collaborate_brands.brands.length > 0)) &&
                <div className={"brands"}>
                    <div className="b-title">
                        {pageData.collaborate_brands.title}
                    </div>
                    <div className="brands-list">
                        {
                            (pageData.collaborate_brands.brands || []).map((item, index) => {
                                return (
                                    // <div key={`bl-${index}`}>
                                        <img key={`bl-${index}`} src={item.logo} />
                                    // </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
            <Testimonials
                title={pageData.testimonials.title}
                clients={pageData.testimonials.people}
            />
            <div className={"faq-list"}>
                <div className={"fl-title"}>
                    { props.t("onboarding_faq_title") }
                </div>
                <TabbedFAQ
                    activeKey={0}
                    t={props.t}
                />
            </div>
        </div>
    )
}

export default WelcomeV1;