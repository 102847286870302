import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import PreviewComponent from '../../../../components/Brand/Campaign/Preview';
import { brandCampaignPublish, brandCampaignPublishBulkNotification } from '../../../../actions';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import notification from '../../../../components/UIElems/Notification';
import { format, parse } from 'date-fns';

const Preview = (props) => {

    const campaignPublishBulkNotification = () => {
        if(window.confirm('Send notification to all the eligible users? Make sure you save all the campaign changes before proceeding.')) {
            props.actions.brandCampaignPublishBulkNotification(props.match.params.id)
            .then(res => {
                notification.send({device: props.rdx.appReducer.deviceType, placement: 'topRight', duration: 5, message: res.msg, type: (res.err ? 'error' : 'success')});
            })
        }        
    }

    const onCampaignPublish = (startDate, endDate, submissionEndDate, campaignId) => {
        if(!startDate || !endDate) return notification.send({device: 'desktop', message: 'Campaign dates required!', type: 'error'});

        let formattedStartDate = format(startDate, 'YYYY-MM-DD hh:mm:ss');
        let formattedEndDate = format(endDate, 'YYYY-MM-DD hh:mm:ss');
        let formattedSubmissionEndDate = format(submissionEndDate, 'YYYY-MM-DD hh:mm:ss');

        props.actions.brandCampaignPublish(campaignId, formattedStartDate, formattedEndDate, formattedSubmissionEndDate)
        .then(res => {
            if(!res.err) {
                notification.send({device: 'desktop', message: 'Done!', type: 'success'});
                setTimeout(() => {
                    window.location.reload(true);
                }, 1000)
            }
        })
    }

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Preview'/>
            ]}>
            <div className="campaign-preview">
                <h1>Preview - {props.rdx.brandReducer.currentCampaign && props.rdx.brandReducer.currentCampaign.campaignName}</h1>
                <PreviewComponent data={props.rdx.brandReducer.currentCampaign}
                    onCampaignPublish={onCampaignPublish}
                    campaignPublishBulkNotification={campaignPublishBulkNotification} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignPublish,
        brandCampaignPublishBulkNotification
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
