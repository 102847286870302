import React, { Component } from 'react';
import cx from 'classnames/bind';

const Loader = () => {
    return (
        <div className="loader-gif" style={{display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 99999}}>
            <img src={'/assets/images/loader.gif'} style={{position: 'absolute', top: '40%', left: '47%'}} alt="loading icon" width="auto" height="60px" className={cx("mobile-laoder-alignment")} />
        </div>
    );
};

export default Loader;