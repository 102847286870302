import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import Feed from '../Feed';

const CampaignList = (props) => {
    console.log("cicd test log");

    return (
        <BaseLayout leftCards={[]}>
            <div>
                <Feed />
            </div>                        
        </BaseLayout>        
    );
}

export default CampaignList;