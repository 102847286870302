import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentPanelSidebar from '../../../../components/Brand/Sidebar/CurrentPanel';
import SubmitterTable from './Table/Submitters';
import { panelDetailFetch } from '../../../../actions';

const PanelAllSubmissions = (props) => {
    const panel = props.rdx.brandReducer.currentPanel;
    
    return (
        <BaseLayout leftCards={[
                <CurrentPanelSidebar panelId={props.match.params.id} currentTitle='Job Submitters'/>
            ]}>
            <div className="campaign-applications">
                <h1>Job Submitters - {panel ? panel.jobName : ''}</h1>
                {
                    panel.panelType === 'poll' &&
                    <h3 style={{color: '#7c808e'}}>
                        {`${panel.pollIds.length} Polls (${panel.pollIds.map(poll => `#${poll.pollId}`).join(', ')})`}
                    </h3>
                }
                <SubmitterTable panelId={props.match.params.id} job={panel} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        panelDetailFetch
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PanelAllSubmissions);