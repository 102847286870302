import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { NavigateBeforeSharp } from '@material-ui/icons';

const Sidebar = ({heading, iconClass, links = [], defaultState='expand'}) => {
    const [navState, setNavState] = useState(defaultState);

    const onClick = () => {
        setNavState((navState == 'expand') ? 'collapse' : 'expand');
    }
    
    return (
       <div>
            <div className={cx("toggle-navigation")}>
                <div onClick={onClick} className={cx("nav-heading", (navState == 'expand') && 'active')}>
                    {/*<h3>{ heading }</h3>
                                        <span className={cx("campaign-sidebar-nav-sprite", `sidebar-${navState}-chevron-icon`)}></span>*/}
                    <h3>
                        <span className={cx("campaign-sidebar-nav-sprite", "sidebar-icon-spacing", iconClass)}></span>
                        <span className={cx("navigation-title")}>{ heading }</span>
                    </h3>
                    <span className={cx("campaign-sidebar-nav-sprite", `sidebar-${navState}-chevron-icon`)}></span>
                </div>
                <ul className={cx(`sidebar-container-${navState}`)}>
                    {
                        [...links].map((d, i) => {
                            if (!Array.isArray(d)) {
                                return <li key={i} className={d.ifActive}>
                                    <a href={d.path}>{d.title}</a>
                                </li>
                            } else {
                                return <li key={i}>
                                    {
                                        d.map((nestedPath, j) => {
                                            return <React.Fragment>
                                                <a href={nestedPath.path} className={nestedPath.ifActive === 'active-item'? 'active-multi-item':''}>
                                                    {nestedPath.title}
                                                </a> {j < (d.length - 1) && <span>|</span>}
                                            </React.Fragment> 
                                            
                                        })
                                    }
                                </li>
                            }
                        })
                    }
                </ul>
            </div>
       </div>
    );
}

export default Sidebar;