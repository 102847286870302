import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandAdminSignup } from '../../../actions';

const Signup = ({rdx, actions}) => {
    const [brandId, setBrandId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();       
        actions.brandAdminSignup(brandId, firstName, lastName, email, password)
        .then(res => {
            if(!res.err)
                window.location = '/brand/home';
            else
                alert('error!')
        });
    }

    return (
       <form onSubmit={onSubmit}>
            <div>
                <label htmlFor="brand-id">Brand Id: </label>
                <input id="brand-id" type="text" name="brand_id" value={brandId} onChange={e => setBrandId(e.target.value)} />
            </div>
            <div>
                <label htmlFor="first-name">First Name: </label>
                <input id="first-name" type="text" name="first_name" value={firstName} onChange={e => setFirstName(e.target.value)} />
            </div>            
            <br />
            <div>
                <label htmlFor="last-name">Last Name: </label>
                <input id="last-name" type="text" name="last_name" value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor="email">Email: </label>
                <input id="email" type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor="password">Password: </label>
                <input id="password" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <button type="submit">submit</button>
       </form>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandAdminSignup
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
