/**
 * 
 * NOTE: This function is the exact copy ot @tickled-media/web-components.tm-helpers:0.0.14
 * toDo: Integrate bit config and import this file from bit
 */
const generateAssetCdnUrl = (bucketName, fileName, assetSizeParams = {}) => {
    const assetCdnDomain = 'https://static.cdntap.com/';
    if (!bucketName)
        return assetCdnDomain;
    if (Object.keys(assetSizeParams).length > 0) {
        const optimizeSizeParams = [];
        for (const [key, value] of Object.entries(assetSizeParams)) {
            optimizeSizeParams.push(`${key}=${value}`);
        }
        return `${assetCdnDomain}${bucketName}/${fileName}?${optimizeSizeParams.join('&')}`;
    }
    return `${assetCdnDomain}/${bucketName}${fileName ? ('/' + fileName) : ''}`;
};

export default generateAssetCdnUrl;