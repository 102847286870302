import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    influencerTiktokConnect,
    influencerTiktokConnectPreview
} from '../../../../../../actions';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';
import { Typography, Input, Button, Icon, Avatar } from 'antd';
import Loader from '../../../../../UIElems/Loader';
import Modal from '../../../../../UIElems/Modal';
import moment from 'moment';
import notification from '../../../../../UIElems/Notification';

const { Text } = Typography;

const IconSvg = ({centered = false, size = 37}) => {
    return (
        <span style={{ display: 'inline-block', borderRadius: '50%', width: centered ? `${size}px` : '30px', height: centered ? `${size}px` : '30px', position: 'absolute', top: centered ? '50%' : '47px', left: centered ? '50%' : '51px', background: '#517fa5', border: 'none', transform: centered ? 'translate(-50%, -50%)' : ''}} >
            <svg width={`${size}px`} height={`${size}px`}  viewBox="0 0 37 37"  version="1.1">
                <title>tiktok</title>
                <g id="Flow-Ph2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="9-vip-job-board" transform="translate(-15.000000, -465.000000)" fill="#000000" fill-rule="nonzero">
                        <g id="tiktok" transform="translate(15.000000, 465.000000)">
                            <path d="M18.5,0 C8.28431445,0 0,8.28431445 0,18.5 C0,28.7156855 8.28431445,37 18.5,37 C28.7156855,37 37,28.7156855 37,18.5 C37,8.28431445 28.7156855,0 18.5,0 Z M27.7810742,14.1548848 L27.7810742,16.6598281 C26.5998926,16.6602617 25.4522422,16.4287949 24.3698477,15.9721484 C23.6738574,15.6783164 23.025418,15.2997168 22.432334,14.8422031 L22.4501113,22.5524395 C22.442668,24.2886211 21.7557832,25.919873 20.5125977,27.1486055 C19.5008789,28.1487617 18.2188867,28.7847715 16.8293633,28.9906563 C16.5028672,29.039002 16.1705898,29.0637891 15.8345547,29.0637891 C14.3471113,29.0637891 12.9349688,28.5819219 11.7798027,27.6929102 C11.5624277,27.525543 11.3545195,27.344084 11.156584,27.1486055 C9.80926367,25.8169668 9.11450195,24.0122051 9.2315,22.1135703 C9.32074805,20.6683301 9.89937891,19.2900078 10.8639805,18.209709 C12.1401914,16.7801504 13.9255859,15.9866738 15.8345547,15.9866738 C16.1705898,15.9866738 16.5028672,16.0118945 16.8293633,16.0602402 L16.8293633,16.9863965 L16.8293633,19.5628105 C16.5197773,19.4606992 16.1891621,19.4045488 15.8448887,19.4045488 C14.1008301,19.4045488 12.6898437,20.827459 12.7159316,22.5728184 C12.7324805,23.6895391 13.3424746,24.6657031 14.2421816,25.203793 C14.6650078,25.4567227 15.1514277,25.6133945 15.6705117,25.6418672 C16.0772227,25.6641973 16.4677461,25.607541 16.8293633,25.4881582 C18.0754395,25.0765332 18.9743516,23.9061191 18.9743516,22.5257012 L18.9784707,17.3625391 L18.9784707,7.93621094 L22.4281426,7.93621094 C22.4314668,8.27802734 22.4661543,8.6115332 22.5306152,8.93470508 C22.7909883,10.2427852 23.5283145,11.3776445 24.5544863,12.1513203 C25.4492793,12.826209 26.5634707,13.2262715 27.7711016,13.2262715 C27.7718965,13.2262715 27.7818691,13.2262715 27.7810742,13.2254766 L27.7810742,14.1548848 L27.7810742,14.1548848 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </span>
    );
}

const Tiktok = (props) => {
    const t = props.t;
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'tiktok', false);

    const onConnect = () => {
        setIsModalOpen(true);         
    }

    const onCountRefresh = () => {
        if(window.confirm("Are you sure you want to refresh the account?")) {
            setIsLoading(true);
            props.actions.influencerTiktokConnect(props.rdx.influencerReducer.id, socialAccount.tiktok.slug, 'refresh')
            .then(data => {
                setIsLoading(false);
                if (data.err) {
                    return notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: data.msg || 'Something went wrong!', type: 'error', placement: 'topRight'})
                }
                if (data.data.redirectUrl) {
                    window.location = data.data.redirectUrl;
                } else {
                    window.location.reload();
                }
            });
        }        
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("tiktok-icon-social-account-bg")}>
                    <IconSvg centered={true} size={37} />
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>{t('social_account_connect')} +</button>
            </div>
        );        
    }

    const ConnectedState = () => {
        const data = socialAccount.tiktok;

        return (
            <div className="">
                {
                    data.profileImage
                    ? 
                        <div style={{position: 'relative'}} className={cx("followers-profile-image-wrap")}>
                            <img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                            <IconSvg />
                        </div>
                    : 
                        <div style={{position: 'relative'}} className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")} />
                            <IconSvg />
                        </div>
                }
                <div>{data.slug}</div>
                <div className={cx("btn", "btn-followers")}>{t('social_account_followers')}: {data.followerCount || 0}</div>

                <div className={cx("refresh-followers-wrap")}>
                    <a href="#" className={cx("btn-refresh")} style={{justifyContent: 'center'}} onClick={e => onConnect()}>
                        <span>{t("sidebar_edit")}</span>
                    </a>
                </div>
            </div>
        );        
    }

    const TiktokConnectModal = () => {
        const [tiktokUsername, setTiktokUsername] = useState();
        const [preview, setPreview] = useState();
        const [loading, setLoading] = useState(false);

        const onModalClose = () => {
            setIsModalOpen(false);
        }

        const onPageConnect = () => {
            setIsLoading(true);
            props.actions.influencerTiktokConnect(props.rdx.influencerReducer.id, tiktokUsername)
            .then(data => {
                if (!data.err) {
                    setIsModalOpen(false);
                    window.location = data.data.redirectUrl;
                }
            });
        }

        const onPreview = () => {
            setLoading(true);
            props.actions.influencerTiktokConnectPreview(tiktokUsername)
            .then(data => {
                if (!data.err) {
                    setPreview(data.data);
                } else {
                    notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: data.msg || 'Something went wrong!', type: 'error', placement: 'topRight'})
                }
                setLoading(false);
            });
        }

        const PreviewButton = (
            <Button type="primary" onClick={onPreview}>
                {
                    loading ?
                    <Icon type="loading" style={{ width: "32px"}} /> :
                    <Icon type="arrow-right" style={{ width: "32px"}} />
                }
            </Button>
        );

        return (
            <Modal title={t("tiktok_username_title")}
                openModal={isModalOpen}
                onClose={onModalClose}
                onOk={onPageConnect}
                okText={t("tiktok_confirm")}
                okButtonProps={{disabled: !preview}}
                isLoading={isLoading}
                cancelButtonProps={{ style: { display: 'none' } }} >

                <div className="infinite-container">
                    <div className={cx("form-vertical-row")}>
                        <div className={cx("form-wrap")}>
                            <label htmlFor="tiktok-username">{t("tiktok_username_title")}</label>
                            <Input id="tiktok-username" value={tiktokUsername} onChange={e => setTiktokUsername(e.target.value)} addonBefore="@" addonAfter={PreviewButton} />
                        </div>
                    </div>
                    {
                        preview && preview.user &&
                        <React.Fragment>
                            <hr/>
                            <div className={cx("form-horizontal-row")}>
                                <p>{`${t("tiktok_is_this_your_account")} ${t("tiktok_search_again")}`}</p>
                            </div>
                            <div className={cx("form-horizontal-row")}>
                                <Avatar style={{marginRight: "10px"}} size={64} src={preview.user.avatarLarger} />
                                <div className={cx("form-vertical-row")}>
                                    <h3>{preview.user.uniqueId}</h3>
                                    <p>{preview.user.nickname}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

            </Modal>
        );
    }
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.tiktok
                ? <ConnectedState />
                : <ConnectState />
            }

            <TiktokConnectModal />

            { isLoading && <Loader /> }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerTiktokConnect,
        influencerTiktokConnectPreview
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Tiktok);
