/*Custom Hooks*/
import { useState, useEffect } from 'react';
import {
    surveyCategoryListFetch,
    productCategoryListFetch,
    countryListFetch,
    citiesListFetch,
    campaignListFetch,
    campaignDetailFetch,
    genericAction,

    brandCampaignApplicationsFetch,
    brandCampaignSubmissions,
    brandCampaignFeedbackAnswers,
    brandCampaignScreeningQuestions,
    brandCampaignLinkStats,
    brandCampaignEmailTracker,
    brandCampaignEmailTrackerLegacyOpened,
    brandCampaignEmailTrackerLegacySent,
    campaignFeedbackQuestions,
    allUsers,
    allAdmins,
    brandInfluencerPayments,

    influencerProfile,
    influencerProfileSocial,
    influencerCampaignsApplied,
    influencerCampaignApplicationStatus,
    influencerSocialAccounts,
    influencerCampaignSubmissionStatus,
    panelListFetch,
    jobListFetch,
    allPanelUsers,
    brandPanelSubmittersFetch,
    apiJobAppliedListFetch,
    currencyCreditConversionList
 } from '../../actions';

 export const useJobList = (loaderCallback, dispatch, brandId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra=null, forInfluencer=false, shouldReset=false, ifDontDispatch=false, forceUpdate=false, jobType='all') => {
    const [jobList, setJobList] = useState({list: [], total: 0, totalClicks: 0});

    useEffect(() => {
        dispatch(jobListFetch(brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset, ifDontDispatch, jobType))
        .then(response => {
            setJobList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate, filtered]);

    return jobList;
}

export const useApiJobAppliedList = (loaderCallback, userId, jobType, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra=null, forceRefresh=false, isAdmin=false) => {
    const [jobList, setJobList] = useState({list: [], total: 0, totalClicks: 0});

    useEffect(() => {
        apiJobAppliedListFetch(userId, jobType, page, pageSize, sorted, filtered, conditions, extra, isAdmin)
            .then(response => {
                loaderCallback();
                setJobList(response.data);
            }).catch(err => {
                loaderCallback();
            });
    }, [jobType, page, pageSize, filtered, forceRefresh]);

    return jobList;
}

 export const useSurveyCategoryList = () => {
    const [surveyCategoryList, setSurveyCategoryList] = useState([]);

    useEffect(() => {
        surveyCategoryListFetch()
        .then(response => {
            setSurveyCategoryList(response.data);
        })
        .catch(err => console.log(err))
    }, []);

    return surveyCategoryList;
}

export const useProductCategoryList = (refresh) => {
    const [productCategoryList, setProductCategoryList] = useState([]);

    useEffect(() => {
        productCategoryListFetch()
        .then(response => {
            setProductCategoryList(response.data);
        })
        .catch(err => console.log(err))
    }, [refresh]);

    return productCategoryList;
}

export const useCountryList = () => {
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        countryListFetch()
        .then(response => {
            setCountryList(response.data);
        })
        .catch(err => console.log(err))
    }, []);

    return countryList;
}

export const useCountryOptions = () => {
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        countryListFetch().then(response => {
            const list = response.data
                .filter(country => [100, 129, 169, 192, 211, 232].includes(country.id))
                .map(country => {
                    return {
                        label: country.name,
                        value: country.id,
                        selected: false
                    };
                });
            setCountryOptions(list);
        })
    }, []);

    return [countryOptions, setCountryOptions];
}

export const useCitiesList = (countryId) => {
    const [citiesList, setCitiesList] = useState([]);

    useEffect(() => {
        citiesListFetch(countryId)
        .then(response => setCitiesList(response.data))
        .catch(err => console.log(err))
    }, [countryId]);

    return citiesList;
}

export const useCurrencyList = (dispatch) => {
    const [currencyList, setCurrencyList] = useState([]);

    useEffect(() => {
        dispatch(genericAction('currencyList', []))
        .then(response => {
            setCurrencyList(response.data);
        })
        .catch(err => console.log(err))
    }, []);

    return currencyList;
}

export const useBankList = (dispatch, countryId = null) => {
    const [bankList, setBankList] = useState([]);

    useEffect(() => {
        dispatch(genericAction('bankList', [countryId]))
        .then(response => {
            if (countryId === 100) {
               const onlyGopay = response.data.filter(bank => bank.bankCode === 'gopay');
               setBankList(onlyGopay);
            } else {
                setBankList(response.data);
            }
        })
        .catch(err => console.log(err))
    }, [countryId]);

    return bankList;
}

export const useCampaignList = (loaderCallback, dispatch, brandId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra=null, forInfluencer=false, shouldReset=false, ifDontDispatch=false, forceUpdate=false) => {
    const [campaignList, setCampaignList] = useState({list: [], total: 0, totalClicks: 0});

    useEffect(() => {
        dispatch(campaignListFetch(brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset, ifDontDispatch))
        .then(response => {
            setCampaignList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate, filtered]);

    return campaignList;
}

export const useCampaignDetail = (dispatch, campaignId) => {
    const [campaignDetail, setCampaignDetail] = useState([]);

    useEffect(() => {
        dispatch(campaignDetailFetch(campaignId))
        .then(response => {
            setCampaignDetail(response.data);
        })
        .catch(err => console.log(err))
    }, [])

    return campaignDetail;
}

export const useCurrencyCreditConversionList = () => {
    const [conversionList, setConversionList] = useState([]);

    useEffect(() => {
        currencyCreditConversionList()
        .then(response => {
            if (!response.err && response.data) {
                setConversionList(response.data);
            } else {
                throw new Error('Error fetching conversion list');
            }
        }).catch(err => console.log(err));
    }, []);

    return conversionList;
}

export const useGenericAction = (dispatch, fnName, args, forceUpdate=false, loaderCallback = () => {}) => {
    const [results, setResults] = useState(null);

    useEffect(() => {
        dispatch(genericAction(fnName, args))
        .then(response => {
            setResults(('data' in response ? response.data : response));
            loaderCallback();
        })
        .catch(err => console.log('e ', err))
    }, [forceUpdate])

    return results;
}

//------------- brand ---------------------------------------------------------------------------------------------------------------------

export const useBrandCampaignApplications = (loaderCallback, dispatch, brandId=null, campaignId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}, forceUpdate=false) => {
    const [applicationList, setApplicationList] = useState({list: [], total: 0});
    useEffect(() => {
        dispatch(brandCampaignApplicationsFetch(brandId, campaignId, page, pageSize, sorted, filtered, conditions, extra))
        .then(response => {
            setApplicationList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return [applicationList, setApplicationList];
}

export const useBrandCampaignSubmissions = (dispatch, campaignId=null, influencerId=null, page=1, pageSize=10, conditions=[], forceUpdate=false) => {
    const [submissionList, setSubmissionList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignSubmissions(campaignId, influencerId, page, pageSize, conditions))
        .then(response => {
            setSubmissionList(response.data);
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return submissionList;
}

export const useBrandInfluencerPayments = (loaderCallback, dispatch, campaignId, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], forceUpdate=false) => {
    const [influencerList, setInfluencerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandInfluencerPayments(campaignId, page, pageSize, sorted, filtered, conditions))
        .then(response => {
            setInfluencerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return influencerList;
}

export const useBrandInfluencerGradingAggregate = (loaderCallback, dispatch, campaignId = null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}, forceUpdate=false) => {
    const [influencerList, setInfluencerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('brandInfluencerGradingAggregate', [campaignId, page, pageSize, sorted, filtered, conditions, extra]))
        .then(response => {
            setInfluencerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return influencerList;
}

export const useBrandInfluencerPaymentHistory = (loaderCallback, dispatch, influencerId, campaignId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [paymentList, setPaymentList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('brandInfluencerPaymentHistory', [influencerId, campaignId, page, pageSize, sorted, filtered, conditions]))
        .then(response => {
            setPaymentList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return paymentList;
}

export const useBrandInfluencerGradingHistory = (loaderCallback, dispatch, influencerId = undefined, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, extra={}, forceUpdate=false, campaignId=undefined) => {
    const [influencerList, setInfluencerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('brandInfluencerGradingHistory', [influencerId, page, pageSize, sorted, filtered, conditions, extra, campaignId]))
        .then(response => {
            setInfluencerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return influencerList;
}

export const useCampaignFeedbackQuestions = (dispatch, campaignId, forceUpdate=false) => {
    const [questionList, setQuestionList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(campaignFeedbackQuestions(campaignId))
        .then(response => {
            setQuestionList(response.data);
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return questionList;
}

export const useBrandCampaignScreeningQuestions = (dispatch, campaignId, forceUpdate=false) => {
    const [questionList, setQuestionList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignScreeningQuestions(campaignId))
        .then(response => {
            setQuestionList(response.data);
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return questionList;
}

export const useBrandCampaignFeedbackAnswers = (dispatch, campaignId, influencerId, page=1, pageSize=10, conditions=[], forceUpdate=false) => {
    const [answerList, setAnswerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignFeedbackAnswers(campaignId, influencerId, page, pageSize, conditions))
        .then(response => {
            setAnswerList(response.data);
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return answerList;
}

export const useAllUsers = (loaderCallback, dispatch, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [userList, setUserList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(allUsers(page, pageSize, sorted, filtered, conditions))
        .then(response => {
            if(!response.err && response.data) {
                setUserList(response.data);
            }
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return [userList, setUserList];
}

export const useAllPanelUsers = (loaderCallback, dispatch, page=1, pageSize=10, forceUpdate=false, conditions={}, filtered={}) => {
    const [userList, setUserList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(allPanelUsers(conditions, filtered))
        .then(response => {
            setUserList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return userList;
}

export const useAllAdmins = (loaderCallback, dispatch, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [userList, setUserList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(allAdmins(page, pageSize, sorted, filtered, conditions))
        .then(response => {
            setUserList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return userList;
}

export const useBrandCampaignLinkStats = (loaderCallback, dispatch, campaignId, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [linkStatsList, setLinkStatsList] = useState({list: [], total: 0, totalClicks: 0});

    useEffect(() => {
        dispatch(brandCampaignLinkStats(campaignId, page, pageSize, sorted, filtered, conditions))
        .then(response => {
            if(!response.err && response.data) {
                setLinkStatsList(response.data);
            }
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return linkStatsList;
}

export const useBrandCampaignEmailTracker = (loaderCallback, dispatch, campaignId, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [emailTrackerList, setEmailTrackerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignEmailTracker(campaignId, page, pageSize, sorted, filtered, conditions))
        .then(response => {
            setEmailTrackerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return emailTrackerList;
}

export const useBrandCampaignEmailTrackerLegacyOpened = (loaderCallback, dispatch, campaignId, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [emailTrackerList, setEmailTrackerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignEmailTrackerLegacyOpened(campaignId, page, pageSize, sorted, filtered, conditions))
        .then(response => {
            setEmailTrackerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return emailTrackerList;
}

export const useBrandCampaignEmailTrackerLegacySent = (loaderCallback, dispatch, campaignId, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [emailTrackerList, setEmailTrackerList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandCampaignEmailTrackerLegacySent(campaignId, page, pageSize, sorted, filtered, conditions))
        .then(response => {
            setEmailTrackerList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return emailTrackerList;
}

export const useBrandCampaignModeration = (loaderCallback, dispatch, campaignId, influencerId = null, page = 0, pageSize = 10, forceUpdate = false) => {
    const [moderationData, setModerationData] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('brandCampaignModeration', [campaignId, influencerId, page, pageSize]))
        .then(response => {
            setModerationData(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return moderationData;
}

export const useBrandInfluencerFeedback = (loaderCallback, dispatch, page=1, pageSize=10, sorted=[], filtered=[], conditions={}, forceUpdate=false) => {
    const [feedbackList, setFeedbackList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('brandInfluencerFeedback', [page, pageSize, sorted, filtered, conditions]))
        .then(response => {
            setFeedbackList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return feedbackList;
}

//------------- influencer -----------------------------------------------------------------------------------------------------------------

export const useInfluencerProfile = (dispatch, influencerId) => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        dispatch(influencerProfile(influencerId))
        .then(response => {
            setProfile(response.data);
        })
        .catch(err => console.log(err))
    }, [])

    return profile;
}

export const useInfluencerProfileSocial = (dispatch, influencerId) => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        dispatch(influencerProfileSocial(influencerId))
        .then(response => {
            setProfile(response.data);
        })
        .catch(err => console.log(err))
    }, [])

    return profile;
}

export const useInfluencerCampaignsApplied = (dispatch, influencerId, page=1, pageSize=10, conditions=[], forceUpdate=false) => {
    const [campaigns, setCampaigns] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(influencerCampaignsApplied(influencerId, page, pageSize, conditions))
        .then(response => {
            setCampaigns(response.data);
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate])

    return campaigns;
}

export const useInfluencerCampaignApplicationStatus = (dispatch, influencerId, campaignId, forceUpdate=false) => {
    const [applicationStatus, setApplicationStatus] = useState(null);

    useEffect(() => {
        dispatch(influencerCampaignApplicationStatus(influencerId, campaignId))
        .then(response => {
            setApplicationStatus(response.data);
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return applicationStatus;
}

export const useInfluencerSocialAccounts = (dispatch, influencerId, type, forceUpdate = false) => {
    const [socialAccounts, setSocialAccounts] = useState(null);

    useEffect(() => {
        dispatch(influencerSocialAccounts(influencerId, type))
        .then(response => {
            let profiles = {};
            for(let i in response.data) {
                profiles[response.data[i].type] = response.data[i];
            }
            setSocialAccounts(profiles);
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return socialAccounts;
}

export const useInfluencerCampaignActions = (loaderCallback, dispatch, campaignId, influencerId = null, forceUpdate = false) => {
    const [actionData, setActionData] = useState({fields: [], pendingFields: [], results: {}});

    useEffect(() => {
        dispatch(genericAction('influencerCampaignActions', [campaignId, influencerId]))
        .then(response => {
            setActionData(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return actionData;
}

export const useInfluencerCampaignModeration = (loaderCallback, dispatch, campaignId, influencerId = null, forceUpdate = false) => {
    const [moderationData, setModerationData] = useState([]);

    useEffect(() => {
        dispatch(genericAction('influencerCampaignModeration', [campaignId, influencerId]))
        .then(response => {
            let data = Array.isArray(response.data) ? response.data : [response.data];
            setModerationData(data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return moderationData;
}

export const useInfluencerCampaignModerationPostFeedback = (loaderCallback, dispatch, postId, forceUpdate = false) => {
    const [feedbackData, setFeedbackData] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('influencerCampaignModerationPostFeedback', [postId]))
        .then(response => {
            setFeedbackData(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return feedbackData;
}

export const useInfluencerInstaPostMetrics = (loaderCallback, dispatch, influencerId, forceUpdate = false) => {
    const [posts, setPosts] = useState({list: []});

    useEffect(() => {
        dispatch(genericAction('influencerComposeInstaPostMetrics', [influencerId]))
        .then(response => {
            setPosts(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return posts;
}

export const useInfluencerPaymentDetails = (loaderCallback, dispatch, influencerId, forceUpdate = false) => {
    const [paymentDetails, setPaymentDetails] = useState(null);

    useEffect(() => {
        dispatch(genericAction('influencerPaymentDetails', [influencerId]))
        .then(response => {
            setPaymentDetails(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [influencerId, forceUpdate])

    return paymentDetails;
}

export const useInfluencerIfCampaignSubmissionRequired = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [ifCampaignSubmissionRequired, setIfCampaignSubmissionRequired] = useState(true);

    useEffect(() => {
        dispatch(genericAction('influencerIfCampaignSubmissionRequired', [campaignId]))
        .then(response => {
            setIfCampaignSubmissionRequired(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return ifCampaignSubmissionRequired;
}

export const useInfluencerCampaignSubmissionStatus = (loaderCallback, dispatch, campaignId, userId, forceUpdate = false) => {
    const [isInfluencerCampaignSubmitted, setIsInfluencerCampaignSubmitted] = useState(false);

    useEffect(() => {
        dispatch(influencerCampaignSubmissionStatus({campaignId, userId}))
        .then(response => {
            setIsInfluencerCampaignSubmitted(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])

    return isInfluencerCampaignSubmitted;
}

// -------------------- Panel -------------------------

export const usePanelList = (loaderCallback, dispatch, brandId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra=null, forInfluencer=false, shouldReset=false, ifDontDispatch=false, forceUpdate=false, isAdmin=1) => {
    const [panelList, setPanelList] = useState({list: [], total: 0, totalClicks: 0});

    useEffect(() => {
        dispatch(panelListFetch(brandId, page, pageSize, sorted, filtered, conditions, extra, forInfluencer, shouldReset, ifDontDispatch, isAdmin))
        .then(response => {
            setPanelList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate, filtered]);

    return panelList;
}

export const useBrandPanelSubmitters = (loaderCallback, dispatch, panelId=null, page=1, pageSize=10, sorted=[], filtered=[], conditions=[], extra={}, forceUpdate=false) => {
    const [submittersList, setSubmittersList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(brandPanelSubmittersFetch(panelId, page, pageSize, sorted, filtered, conditions, extra))
        .then(response => {
            setSubmittersList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [page, pageSize, forceUpdate, filtered]);

    return [submittersList, setSubmittersList];
}

export default {
    useSurveyCategoryList,
    useProductCategoryList,
    useCountryList,
    useCountryOptions,
    useCitiesList,
    useCurrencyList,
    useBankList,
    useCampaignList,
    useCampaignDetail,
    useGenericAction,

    useBrandCampaignApplications,
    useBrandCampaignSubmissions,
    useBrandInfluencerPayments,
    useBrandInfluencerGradingAggregate,
    useBrandInfluencerPaymentHistory,
    useBrandInfluencerGradingHistory,
    useCampaignFeedbackQuestions,
    useBrandCampaignScreeningQuestions,
    useBrandCampaignFeedbackAnswers,
    useAllUsers,
    useAllAdmins,
    useBrandInfluencerFeedback,
    useBrandCampaignLinkStats,
    useBrandCampaignEmailTracker,
    useBrandCampaignEmailTrackerLegacyOpened,
    useBrandCampaignEmailTrackerLegacySent,

    useInfluencerProfile,
    useInfluencerProfileSocial,
    useInfluencerCampaignsApplied,
    useInfluencerCampaignApplicationStatus,
    useInfluencerSocialAccounts,
    useInfluencerPaymentDetails,

    usePanelList,
    useBrandPanelSubmitters
}
