import { Carousel } from "antd";
import React, { useState } from "react";
import DotIndicator from "../DotIndicator";
import { getRandomIntInRange } from "../../../../utils";

const Testimonials = ({ title, clients = [] }) => {

    const [ slide, setSlide ] = useState(0);

    const placeholderAvatars = [
        "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_1.png",
        "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_2.png",
        "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_3.png",
        "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_4.png",
        "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_5.png"
    ]

    const UserCard = ({ avatar, name, location }) => {
        return (
            <div className={"user-bar"}>
                <div>
                    <img 
                        src={avatar}
                    />
                </div>
                <div>
                    <span>
                        { name }
                    </span>
                    <span>
                        { location }
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className={"testimonials"}>
            <div className="tes-title">
                { title }
            </div>
            <Carousel
                autoplay={false}
                afterChange={(sno) => setSlide(sno)}
            >
                {
                    clients.map((item, index) => {
                        const userAvatar = item.image ? item.image : "https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimonial_placeholder_5.png"; // placeholderAvatars[getRandomIntInRange(1, 6)];
                        return (
                            <div className={"tes-client"} key={`tes-${index}`}>
                                <UserCard
                                    name={item.name}
                                    location={item.location}
                                    avatar={userAvatar}
                                />
                                <img
                                    src={"https://tapfluencer.s3.ap-southeast-1.amazonaws.com/testimony_comment.png"}
                                />
                                <div className={"tes-feedback"}>
                                    {
                                        item.feedback
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
            <DotIndicator
                current={slide + 1}
                capacity={clients.length}
            />
        </div>
    )
}

export default Testimonials;