import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandInfluencerPaymentAdjust } from '../../../../actions';
import { Typography } from 'antd';
import PaymentAdjustComponent from '../../../../components/Brand/Payments/Adjust';
import notification from '../../../../components/UIElems/Notification';

const { Text } = Typography;

const PaymentAdjust = (props) => {

    const onAdjust = (data, callback) => {
        let influencerId = props.influencerId;
        let campaignId = ('campaignId' in props && props.campaignId) ? props.campaignId : null;
        //validation
        //amount range validation
        if(data.actionType == 'add' && data.amount <= 0) {
            callback();
            return notification.send({device: 'desktop', message: 'Enter the proper amount!', type: 'error'});
        }
        if(data.actionType == 'sub' && data.amount >= 0) {
            callback();
            return notification.send({device: 'desktop', message: 'Enter the proper amount!', type: 'error'});   
        }
        //send
        props.actions.brandInfluencerPaymentAdjust(influencerId, data.paymentType, data.amount, data.actionType, data.comments, data.currencyCode, campaignId)
        .then(r => {
            notification.send({device: 'desktop', message: 'Done!', type: 'success'});
            //child callback
            callback();
            //parent callback
            props.callback();
        })
        .catch(e => console.log(e))        
    }
    
    return (
        <div className={cx('adjust-payment')}>
            <PaymentAdjustComponent title={props.title}
                onAdjust={onAdjust}
                dispatch={props.dispatch} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerPaymentAdjust
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAdjust);