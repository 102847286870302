import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { influencerLoginByApi, setInfluencerLoggedIn, emailCheckByApi, passwordForgotByApi } from '../../../actions';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import EmailLogin from './Email';
import CONFIG from '../../../config';
import Loader from '../../UIElems/Loader';
import notification from '../../UIElems/Notification';
import qs from 'qs';
import {Icon} from '../../Common/Icon';
import { Spin } from 'antd';
import Carousel from '../../UIElems/Carousel';
import 'font-awesome/css/font-awesome.min.css';

const Login = (props) => {
    const t = props.t;
    const [isLoading, setIsLoading] = useState(false);
    const [isFbLoginSdkLoaded, setIsFbLoginSdkLoaded] = useState(false);
    const homeScrollRef = useRef(null);
    const aboutScrollRef = useRef(null);
    const benefitsScrollRef = useRef(null);
    const requirementScrollRef = useRef(null);
    const whyJoinUsScrollRef = useRef(null);
    const signInScrollRef = useRef(null);

    const smoothScroll = (e, refElem) => {
        e.preventDefault();
        refElem.current.scrollIntoView({behavior: 'smooth'});
    }

    const onSocialLoginClick = (socialType) => {
        setIsLoading(true);
    }

    const emailCheck = (email, callback) => {
        emailCheckByApi(email)
        .then(res => {
            callback(res.response.email_count);
        });
    }

    const onEmailLogin = (request) => {
        setIsLoading(true);
        let { email, password, id=null, name, gender=null } = request;
        props.actions.influencerLoginByApi('email', email, id, name, gender, null, null, password)
        .then(res => {
            setIsLoading(false);
            onLoginSuccess(res);
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const onPasswordForgot = (email) => {
        setIsLoading(true);
        props.actions.passwordForgotByApi(email)
        .then(res => {
            setIsLoading(false);
            if(res.status)
                notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: "An email has been sent to the specified address.", type: 'success', placement: 'topLeft'});
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const onFacebookResponse = (response) => {
        let { email, id, name, gender=null } = response;
        props.actions.influencerLoginByApi('facebook', email, id, name, gender)
        .then(res => onLoginSuccess(res))
        .catch(err => console.log(err));
    }

    const onGoogleResponse = (response) => {
        let { email, name, gender=null } = response.profileObj;
        let id = response.googleId;
        props.actions.influencerLoginByApi('googleplus', email, id, name, gender)
        .then(res => onLoginSuccess(res))
        .catch(err => console.log(err));
    }

    const onLoginSuccess = (res) => {
        setIsLoading(false);
        if(!res.status)
            return notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: res.message, type: 'error', placement: 'topLeft'});
        //else        
        props.actions.setInfluencerLoggedIn(res)
        .then((response) => {
            // redirection
            let queryParams = [];
            if(window.location.search) {
                queryParams = qs.parse(window.location.search.replace('?', ''));
            }
            if(!response.err) {
                // NOTE: DO NOT remove this comment
                // window.location = ('redirect' in queryParams) ? queryParams['redirect'] : '/';
                // let url = ('redirect' in queryParams) ? ('/signup/basic' + queryParams['redirect']) : '/signup/basic';
                // window.location = url;
                let url = '';
                const progress = (response.data && response.data.progress) || {};
                if(!progress.basic) {
                    url = ('redirect' in queryParams) ? ('/signup/basic?redirect=' + queryParams['redirect']) : '/signup/basic';
                } else if(('redirect' in queryParams) && (queryParams['redirect'] !== '/')) {
                    url = queryParams['redirect']
                } else {
                    url = '/content-creator/jobs';
                }
                console.log("url", url);
                window.location = url;
                return;
            } else if(response.redirect && response.redirect === '/influencer/profile/connect/') {
                let url = '/content-creator/jobs';
                console.log("redirecting to url", url);
                window.location = url;
                return;
            }
            else
                notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: response.message, type: 'error', placement: 'topLeft'});
            if (response.redirect) {
                window.location = response.redirect;
            }
        });        
    }

    const onLoginFailure = (e) => {
        console.log(e);
    }        

    const FacebookLoginButton = () => {
        let redirectUri = CONFIG.siteUrl + 'influencer/login';
        return <FacebookLogin
            // textButton={t("login_continue_with_Facebook")}      
            appId={CONFIG['fb']['appId']}
            autoLoad={false}
            scope="email"
            fields="name,email"
            onClick={() => onSocialLoginClick('facebook')}
            callback={onFacebookResponse}
            onFailure={onLoginFailure}
            redirectUri={redirectUri}
            // isMobile={props.rdx.appReducer.deviceType == 'mobile' ? true : false}
            isMobile={false}
            disableMobileRedirect={props.rdx.appReducer.deviceType == 'mobile' ? false : true}
            render={
                renderProps => {
                    if(renderProps.isSdkLoaded) setTimeout(() => setIsFbLoginSdkLoaded(true), 500);

                    return <button className={cx("kep-login-facebook")} onClick={renderProps.onClick}>
                        {t("login_continue_with_Facebook")}
                    </button>
                }
            } />
    }

    const GoogleLoginButton = () => {
        return <GoogleLogin
            buttonText={t("login_continue_with_Google")}
            clientId={CONFIG['google']['appId']}
            autoLoad={false}
            scope="profile"
            fields="name,email,gender"
            onRequest={() => onSocialLoginClick('google')}
            onSuccess={onGoogleResponse}
            onFailure={onLoginFailure}
            disableMobileRedirect={true} />
    }

    return (
        <Fragment>
            <div className={cx("influencer-base-layout")}>
                <header className={cx("influencer-header")}>
                    <div className={cx("header-top")}>
                        <div className={cx("h-left")}>
                            <div className={cx("logo-wrap")} id="Home" ref={homeScrollRef}>
                                <a href='#'>
                                    <img loading="lazy" className={cx('not-mobile', 'logo')} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/vip+tap+logo.png'} 
                                width="270" alt="logo" />

                                    <img loading="lazy" className={cx('only-mobile', 'logo')} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/vip+tap+logo.png'} alt="logo" />
                                </a>
                            </div>  
                        </div>
                        {
                            // <div className={cx("h-center")}></div>
                        }
                        <div className={cx("h-right", "only-mobile")}>
                           <a href='#signIn' className={cx("signIn-text")} onClick={e => smoothScroll(e, signInScrollRef)}>{t("login_nav_sign_in")}</a>
                        </div>
                        <div className={cx("h-right", "not-mobile")}>
                            <ul className={cx("primary")}>
                                <li>
                                    <a href="#Home" onClick={e => smoothScroll(e, homeScrollRef)}>{t("login_nav_home")}</a>
                                </li>
                                <li>
                                    <a href="#about" onClick={e => smoothScroll(e, aboutScrollRef)}>{t("new_login_about")}</a>
                                </li>
                                <li>
                                    <a href="#benefits" onClick={e => smoothScroll(e, benefitsScrollRef)}>{t("new_login_benefits")}</a>
                                </li>
                                <li>
                                    <a href="#" onClick={e => smoothScroll(e, requirementScrollRef)}>{t("login_nav_requirements")}</a>
                                </li>
                                <li className={cx("only-desktop", "animation-1")}>
                                    <a href='#signIn' className={cx("signIn-text", "btn-hover", "pulse")} onClick={e => smoothScroll(e, signInScrollRef)}>{t("new_login_login")}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={cx("only-mobile", "menu-wrap")}>
                        <nav>
                            <ul className={cx("flex", "flex-center", "primary")}>
                                <li><a href='#Home' onClick={e => smoothScroll(e, homeScrollRef)}>{t("login_nav_home")}</a></li>
                                <li><a href='#about' onClick={e => smoothScroll(e, aboutScrollRef)}>{t("new_login_about")}</a></li>
                                <li><a href='#benefits' onClick={e => smoothScroll(e, benefitsScrollRef)}>{t("new_login_benefits")}</a></li>
                                <li><a href='#requirement' onClick={e => smoothScroll(e, requirementScrollRef)}>{t("login_nav_requirements")}</a></li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <div className={cx("influencer-main-container")}>
                    <div className={cx("right-panel-decative")}>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid")} id="Home" ref={homeScrollRef} style={{position:"relative", paddingBottom:"100px"}}>
                <div className={cx("influencer-container")}>
                    <div className={cx("main-header-wrapper")}>
                        <div className={cx("brief")}>
                            <div className={cx("login-title-wrapper", "login-margin-bottom-20", "only-desktop")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>{t("new_login_why_are_you_our_VIP")}</span>
                                </h1>
                            </div>
                            <div className={cx("description", "login-margin-bottom-30", "login-margin-bottom-15")}>
                                {t("new_login_as_parents_you_only_want_the_best_for_your_child_from_diapers")}
                            </div>
                            <div className={cx("btn-wrapper", "animation-1", "btn-wrap-mob")}>
                                <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/booklet.png" alt=""/>
                                <div>
                                    <p>
                                        {t("new_login_love_to_learn_more")} <br/>
                                        {t("new_login_download_our_startup_kits_here")}
                                    </p>
                                    <a href={t("new_login_book_url")} className={cx("btn btn-primary-rounded", "btn-hover", "pulse")}>
                                        {t("new_login_download_now")}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx("image-wrapp")} > 
                            <div className={cx("login-title-wrapper", "login-margin-bottom-20", "only-mobile")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>{t("new_login_why_are_you_our_VIP")}</span>
                                </h1>
                            </div>
                            <div className={cx("mum-images")}>
                                <img loading="lazy" className={cx("bg-heart-img")} src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/banner-img-1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                       
                </div>
                <div id="about" ref={aboutScrollRef}></div>
            </div>
            
            <div className={cx("influencer-container-fluid", "requirement-wrapper")}  >
                <div className={cx("requirement-details-warpper")}>
                    <div className={cx("login-title-wrapper", "only-mobile")}>
                        <h1 className={cx("title")}>
                            <span className={cx("title-left")}>{t("new_login_so_what_exactly_is_vip_parents")}</span>
                        </h1>
                    </div>
                    <div className={cx("img-section")}>
                        <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/banner-img-2.png" alt=""/>
                    </div>
                    <div className={cx("content-section")}>
                        <div className={cx("vip-mamma-list-one", "common-list-style")}>
                             <div className={cx("login-title-wrapper", "login-margin-bottom-20", "only-desktop")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>{t("new_login_so_what_exactly_is_vip_parents")}</span>
                                </h1>
                            </div>
                        </div>
                        <ul>
                            <li>
                                {t("new_login_vip_parents_is_a")} {t("new_login_under_theAsianparent_Southeast_Asia’s_largest_parenting_media_with_over_33")} 
                            </li>
                            <li id="benefits" ref={benefitsScrollRef}>
                                {t("new_login_we_are_in_the_mission_to")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid", "brands-wrapper")}>
                <div className={cx("influencer-container")}>
                    <div className={cx("login-title-wrapper", "center", "become-vip-mum-padding-top", "requirment-padding-bottom")}>
                        <h1 className={cx("title")}>
                            <span className={cx("title-left")}>
                                {t("new_login_amazing_what_are_the_benefits_of_becoming_a_vip_member")}
                            </span>
                        </h1>
                    </div>
                    <div className={cx("brand-details-warpper")}>
                        <div className={cx("content-section")}>
                            <ul>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-1.png" alt=""/>
                                    <span>
                                        <strong>{t("new_login_earn_extra_income")}</strong> {t("new_login_from_home_or_remotely")}
                                    </span>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-2.png" alt=""/>
                                    <span>
                                        <strong> {t("new_login_get_free_products")}</strong> {t("new_login_from_brands")}
                                    </span>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-3.png" alt=""/>
                                    <span>
                                       {t("new_login_receive_exclusive")} <strong>{t("new_login_event_invitations")} </strong> 
                                    </span>
                                </li>

                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-4.png" alt=""/>
                                    <span>
                                        <strong> {t("new_login_learn_new_skills")} </strong> {t("new_login_through_our_workshops")}
                                    </span>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-5.png" alt=""/>
                                    <span>
                                        {t("new_login_participate_in")} <strong> {t("new_login_charity_programs")} </strong>
                                    </span>
                                </li>

                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-6.png" alt=""/>
                                    <span>
                                        {t("new_login_enjoy")} <strong> {t("new_login_discounts")} </strong> {t("new_login_and")} <strong>{t("new_login_special_giveaways")} </strong> 
                                    </span>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-7.png" alt=""/>
                                    <span>
                                    {t("new_login_participate_in")} <strong>{t("new_login_branded_surveys_and_polls")} </strong> 
                                    </span>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-8.png" alt=""/>
                                    <span>
                                    <strong>{t("new_login_be_invited")} </strong> {t("new_login_to_our")} <strong>{t("new_login_online_groups")} </strong>
                                    </span>
                                </li>
                                {
                                    // <li className={cx("only-mobile")}>
                                    //     <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-9.png" alt=""/>
                                    //     <span>
                                    //     <strong>{t("new_login_connect_and_meet")} </strong> {t("new_login_other_vip_members")}
                                    //     </span>
                                    // </li>
                                }
                            </ul>
                            <ul className={cx("only-desktop")} style={{marginTop:"50px"}}>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-6.png" alt=""/>
                                    <span>
                                        {t("new_login_enjoy")} <strong> {t("new_login_discounts")} </strong> {t("new_login_and")} <strong>{t("new_login_special_giveaways")} </strong>  
                                    </span>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-7.png" alt=""/>
                                    <span>
                                     {t("new_login_participate_in")} <strong>{t("new_login_branded_surveys_and_polls")} </strong>  
                                    </span>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-8.png" alt=""/>
                                    <span>
                                    <strong>{t("new_login_be_invited")} </strong> {t("new_login_to_our")} <strong>{t("new_login_online_groups")} </strong>
                                    </span>
                                </li>
                                {
                                    // <li>
                                    //     <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/icon-benefit-9.png" alt=""/>
                                    //     <span>
                                    //     <strong>{t("new_login_connect_and_meet")} </strong> {t("new_login_other_vip_members")}
                                    //     </span>
                                    // </li>
                                }
                            </ul>
                            {
                                // <p className="center">
                                //     {t("new_login_as_a_vip_parent_you_will_not_only_enjoy_these_benefits")} 
                                // </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid", "why-join-us-wrapper")} id="requirement" ref={requirementScrollRef}>
                <div className={cx("influencer-container")}>
                    <div className={cx("why-join-us-content")}>
                        <div className={cx("img-section")}>
                            <div className={cx("login-title-wrapper", "left", "requirment-padding-bottom", "only-mobile")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>
                                       {t("new_login_what_are_the_requirements_to_join")}
                                    </span>
                                </h1>
                            </div>
                            <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/vip-parent-mobile.png" alt=""/>
                        </div>
                        <div className={cx("content-section")}>
                            <div className={cx("login-title-wrapper", "left", "become-vip-mum-padding-top", "requirment-padding-bottom", "only-desktop")}>
                                <h1 className={cx("title")}>
                                    <span className={cx("title-left")}>
                                        {t("new_login_what_are_the_requirements_to_join")}
                                    </span>
                                </h1>
                            </div>
                            <p>
                                1. {t("new_login_have_more_than")} <strong>{t("new_login_500_friends_on_Facebook_or_500_followers_on_Instagram")} </strong>
                            </p>
                            <p>
                                2. {t("new_login_have")} <strong>{t("new_login_no_fake_followers")} </strong>
                            </p>
                            <p>
                                3. <strong>{t("new_login_be_a_real_parent")} </strong> - {t("new_login_no_fake_accounts")}
                            </p>
                            <p>
                                {t("new_login_if_you_do_not_have_the_followers_yet_do_not_be_discouraged")}
                            </p>
                           {
                            //  <p>
                            //     {t("new_login_if_you_are_a_stable_influencer")}
                            // </p>

                            // <p>
                            //     {t("new_login_you_also_need_to_change_your_Instagram_account")} <strong>{t("new_login_find_out_how_to_do_it_here")} </strong>
                            // </p>
                            }
                            <div className={cx("btn-wrapper", "animation-1")}>
                                <a 
                                    href={'#signIn'} 
                                    className={cx("btn btn-primary-rounded", "btn-hover", "pulse")}
                                    onClick={e => smoothScroll(e, signInScrollRef)}
                                >
                                    {t("new_login_become_a_vip_Member")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid", "brands-wrapper")}>
                <div className={cx("influencer-container")}>
                    <div className={cx("login-title-wrapper", "center", "brand-padding-top", "requirment-padding-bottom")}>
                        <h1 className={cx("title")}>
                            <span className={cx("brand-title")}>
                                {t("new_login_great_brands_we_have_worked_with")}
                            </span>
                        </h1>
                    </div>
                    <div className={cx("brand-details-warpper")}>
                        <div className={cx("content-section")}>
                            <ul className={cx("bNw-brands")}>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-1.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-2.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-3.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-4.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-5.png" alt=""/>
                                </li>
                               
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-7.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-8.png" alt=""/>
                                </li>

                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-8.png" alt=""/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-9.png" alt=""/>
                                </li>

                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-10.png" alt=""/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-11.png" alt=""/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-12.png" alt=""/>
                                </li>
                                <li className={cx("only-mobile")}>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-13.png" alt=""/>
                                </li>
                            </ul>
                            <ul className={cx("bNw-brands", "only-desktop")} style={{marginTop:"50px"}}>
                                
                                
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-9.png" alt=""/>
                                </li>

                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-10.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-11.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-12.png" alt=""/>
                                </li>
                                <li>
                                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/Logo_client-13.png" alt=""/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid", "social-signup-wrapper")} id="signIn" ref={signInScrollRef}>
                <div className={cx("influencer-container")}>
                <div className={cx("left-section")}>
                    <div id="influencer-login" className={cx("login-actions")}>
                        <div className={cx("fixed-section")}>
                            <h1>
                                <span>{t("new_login_welcome_to_VIP_Parents_Platform")} </span>
                            </h1>
                            <div className={cx("cust-login-wrapper")}>
                                <div className={cx("login-logo")}>
                                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/new-landing-page/VIPparent.png" alt=""/>
                                </div>
                                <div className={cx("login-details")}>
                                    <Spin spinning={!isFbLoginSdkLoaded} tip="You have a slow connection, kindly wait or get on a faster connection">
                                        <div className={cx(props.rdx.appReducer.country == "ph" ? "social-login-button width285" : "social-login-button")}>
                                            <div className={cx("animation-3")}>
                                                <div className={cx("fb-login-wrap","pulse")}>
                                                    <FacebookLoginButton />
                                                </div>
                                            </div>
                                            <div className={cx("animation-4")}>
                                                <div className={cx("g-login-wrap", "pulse")}>
                                                    <GoogleLoginButton />
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>                            
                                    <div className={cx('email-login')}>
                                        <EmailLogin emailCheck={emailCheck}
                                            onEmailLogin={onEmailLogin}
                                            onPasswordForgot={onPasswordForgot}
                                            t={t}
                                            sendNotification={
                                                (message, type) =>
                                                notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: message, type: type, placement: 'topLeft'})
                                            } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className={cx("right-section")}>
                    <img loading="lazy" src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/gift.svg" alt="" />
                </div>  
            </div>
            <div className={cx("influencer-container-fluid", "footer-one")}>
                <div className={cx("influencer-container")}>
                    <div className={cx("footer-misc-wrap")}>
                        <div className={cx("logo")}>
                            <a href="/" title="logo">
                                <i className={cx("sprite")}></i>
                            </a>
                            <div className={cx("social-icons")}>
                                <span className={cx("icon-group", "icon-black")}>
                                    <a href="https://www.facebook.com/theasianparent" target="_blank" title="facebook link">
                                        <i className={cx("icon", "fa", "fa-facebook", "icon-xs", "fficon")}></i>
                                    </a>
                                    <a href="https://www.instagram.com/theasianparent/" target="_blank" title="instagram link">
                                        <i className={cx("icon", "fa fa-instagram", "icon-s", "igicon")}></i>
                                    </a>
                                    <a href="https://twitter.com/theAsianparent" target="_blank" title="twitter link">
                                        <i className={cx("icon", "fa fa-twitter", "icon-xs")}></i>
                                    </a>
                                    <a href="https://www.youtube.com/user/theasianparent" target="_blank" title="youtube link">
                                        <i className={cx("icon", "fa", "fa-youtube-play", "icon-xs")}></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className={cx("download-app")}>
                            <div className={cx("download-txt")}>
                                <span>Download the free App today!</span>
                            </div>
                            <div className={cx("app-logo")}>
                                <a href="#" target="_blank" rel="nofollow"><img loading="lazy" src="https://s3-ap-southeast-1.amazonaws.com/html-edm/2018/sep/tap-welcome/icon-android.svg" alt="google play store" className={cx("google-play")} /></a>
                                <a href="#" target="_blank" rel="nofollow">
                                    <Icon name="app-store" />
                                </a>
                            </div>
                        </div>
                        <div className={cx("clear-fix")}></div>
                    </div>

                    <div className={cx("right-side", "only-mobile")}>
                        <a href="/about-us" title="about us link">
                            <span>About Us</span>
                        </a>
                        <span className={cx("divider")}>|</span>
                        <a href="/privacy-policy" title="privacy and policy details link">
                            <span>Privacy Policy</span>
                        </a>
                        <span className={cx("divider")}>|</span>
                        <a href="/terms-and-conditions" title="terms and condition details link">
                            <span>Terms of Use</span>
                        </a>
                    </div>

                    <div className={cx("intl-sites")}>
                        <label className={cx("section-heading")}>
                            <span>Mums around the world</span>
                        </label>
                        <ul className={cx("ui-list-h")}>
                            <li>
                                <a href="https://sg.theasianparent.com" target="_blank" title="Singapore country link">
                                    <i className={cx("icon-flag sprite sprite-Singapore")}></i> Singapore
                                </a>
                            </li>
                            <li>
                                <a href="https://th.theasianparent.com" target="_blank" title="Thailand country link">
                                    <i className={cx("icon-flag sprite sprite-Thailand")}></i> Thailand
                                </a>
                            </li>
                            <li>
                                <a href="https://id.theasianparent.com" target="_blank" title="Indonesia country link">
                                    <i className={cx("icon-flag sprite sprite-Indonesia")}></i> Indonesia
                                </a>
                            </li>
                            <li>
                                <a href="https://ph.theasianparent.com" target="_blank" title="Philippinescountry link">
                                    <i className={cx("icon-flag sprite sprite-Philippines")}></i> Philippines
                                </a>
                            </li>
                            <li>
                                <a href="https://my.theasianparent.com" target="_blank" title="Malaysia country link">
                                    <i className={cx("icon-flag sprite sprite-Malaysia")}></i> Malaysia
                                </a>
                            </li>
                            <li>
                                <a href="https://lk.theasianparent.com" target="_blank" title="Sri-Lanka country link">
                                    <i className={cx("icon-flag sprite sprite-Sri-Lanka")}></i> Sri Lanka
                                </a>
                            </li>
                            <li>
                                <a href="https://theindusparent.com" target="_blank" title="India country link">
                                    <i className={cx("icon-flag sprite sprite-India")}></i> India
                                </a>
                            </li>
                            <li>
                                <a href="https://vn.theasianparent.com" target="_blank" title="Vietnam country link">
                                    <i className={cx("icon-flag sprite sprite-Vietnam")}></i> Vietnam
                                </a>
                            </li>
                            <li>
                                <a href="https://www.theaussieparent.com/" target="_blank" title="Australia country link">
                                    <i className={cx("icon-flag sprite sprite-Australia")}></i> Australia
                                </a>
                            </li>
                            <li>
                                <a href="https://jp.theasianparent.com" target="_blank" title="Japan country link">
                                    <i className={cx("icon-flag sprite sprite-Japan")}></i> Japan
                                </a>
                            </li>
                            <li>
                                <a href="https://www.africaparent.com" target="_blank" title="Nigeria country link">
                                    <i className={cx("icon-flag sprite sprite-Nigeria")}></i> Nigeria
                                </a>
                            </li>
                            <li>
                                <a href="https://www.africaparent.com" target="_blank" title="Kenya country link">
                                    <i className={cx("icon-flag sprite sprite-Kenya")}></i> Kenya
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("brand-wrap")}>
                        <div className={cx("brand-title")}>
                            <span>Partner Brands</span>
                        </div>
                        <div className={cx("brand-logos")}>
                            {
                                // <a href="https://www.nonilo.com" target="_blank">
                                //     <i className={cx("icon-logo logo-sprite sprite-noni")}></i>
                                // </a>
                                // <a href="https://www.herstyleasia.com" target="_blank">
                                //     <i className={cx("icon-logo logo-sprite sprite-hsa")}></i>
                                // </a>
                                // <a href="https://www.asianmoneyguide.com" target="_blank">
                                //     <i className={cx("icon-logo logo-sprite sprite-amg")}></i>
                                // </a>
                            }
                            <a href="https://www.99.co" target="_blank">
                                <i className={cx("icon-logo logo-sprite sprite-99co")}></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("influencer-container-fluid", "footer-two")}>
                <div className={cx("influencer-container", "copywrite-bar")}>
                    <div className={cx("left-side")}>
                        © Copyright VIParent Platform 2020. All rights reserved
                    </div>
                    <div className={cx("right-side", "only-desktop")}>
                        <a href="https://sg.theasianparent.com/about-us" title="about us link">
                            <span>About Us</span>
                        </a>
                        <span className={cx("divider")}>|</span>
                        <a href="https://sg.theasianparent.com/privacy-policy" title="privacy and policy details link">
                            <span>Privacy Policy</span>
                        </a>
                        <span className={cx("divider")}>|</span>
                        <a href="https://sg.theasianparent.com/terms-and-conditions" title="terms and condition details link">
                            <span>Terms of Use</span>
                        </a>
                    </div>
                </div>
            </div>

            { isLoading && <Loader /> }
        </Fragment>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerLoginByApi,
        setInfluencerLoggedIn,
        passwordForgotByApi
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
