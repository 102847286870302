import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { useCurrencyCreditConversionList, useProductCategoryList } from '../../../../hooks/generic';
import { createImagePreview } from '../../../../utils';
import Switch from "react-switch";
import { Typography, Tooltip, Icon } from 'antd';
import { productCategoryAdd } from '../../../../actions';
import ScreeningQuestions from './ScreeningQuestions';

const { Text } = Typography;

const Form = ({onSubmit, values, type, setLoading, sendNotification, t}) => {
    const dV = (key, values, defaultVal) => {
        return values[key] ? values[key] : defaultVal;
    }

    const [refresh, setRefresh] = useState(false);
        //custom hooks
        const productCategoryList = useProductCategoryList(refresh);
        const conversionList = useCurrencyCreditConversionList();
        const [paymentValue, setPaymentValue] = useState(dV('paymentValue', values, 0));
        const [currencyType, setCurrencyType] = useState(dV('currencyType', values, ''));
        let [equivalentCredits, setEquivalentCredits] = useState(dV('equivalentCredits', values, 0));
        const [paymentType, setPaymentType] = useState(dV('paymentType', values, 'points'));

        if(conversionList.length && currencyType && paymentType==='vipcredits') {
            equivalentCredits = paymentValue * conversionList.find(c => c.currency_code.toLowerCase() === currencyType.toLowerCase())['equivalent_credits'];
        }    

    const [clientName, setClientName] = useState(dV('clientName', values, ''));
    const [brandName, setBrandName] = useState(dV('brandName', values, ''));
    const [ioNumber, setIONumber] = useState(dV('ioNumber', values, ''));
    const [logo, setLogo] = useState(dV('logo', values, ''));
    const [logoPreview, setLogoPreview] = useState(dV('logoPreview', values, ''));
    const [fbLink, setFbLink] = useState(dV('fbLink', values, ''));
    const [instaLink, setInstaLink] = useState(dV('instaLink', values, ''));
    const [twitterLink, setTwitterLink] = useState(dV('twitterLink', values, ''));
    const [youtubeLink, setYoutubeLink] = useState(dV('youtubeLink', values, ''));
    const [tiktokLink, setTiktokLink] = useState(dV('tiktokLink', values, ''));

    const [campaignId, setCampaignId] = useState(dV('campaignId', values, ''));
    const [campaignName, setCampaignName] = useState(dV('campaignName', values, ''));
    const [description, setDescription] = useState(dV('description', values, ''));
    const [instructions, setInstructions] = useState(dV('instructions', values, ''));
    const [guidelinesDont, setGuidelinesDont] = useState(dV('guidelinesDont', values, ''));     
    const [productImage, setProductImage] = useState(dV('productImage', values, ''));
    const [productImagePreview, setProductImagePreview] = useState(dV('productImagePreview', values, ''));
    const [productCategoryId, setProductCategoryId] = useState(dV('productCategoryId', values, 1));
    const [newProductCategory, setNewProductCategory] = useState();
    const [isNewCategoryVisible, setIsNewCategoryVisible] = useState(false);
    const [campaignObjectives, setCampaignObjectives] = useState(dV('campaignObjectives', values, ''));
    const [targetAudience, setTargetAudience] = useState(dV('targetAudience', values, ''));
    const [toneAndVoice, setToneAndVoice] = useState(dV('toneAndVoice', values, ''));
    const [consideration, setConsideration] = useState(dV('consideration', values, ''));
    const [backgroundInformation, setBackgroundInformation] = useState(dV('backgroundInformation', values, ''));
    const [mandatoryInclusions, setMandatoryInclusions] = useState(dV('mandatoryInclusions', values, ''));
    const [payment, setPayment] = useState(dV('payment', values, ''));
    const [paymentTerms, setPaymentTerms] = useState(dV('paymentTerms', values, ''));
    const [termsAndConditions, setTermsAndConditions] = useState(dV('termsAndConditions', values, ''));
    const [isDefaultDistribution, setIsDefaultDistribution] = useState(values['isDefaultDistribution'] !== undefined || values['isDefaultDistribution'] !== null ? !!values['isDefaultDistribution'] : true)
    const [distributionRightsAndCopyright, setDistributionRightsAndCopyright] = useState(dV('distributionRightsAndCopyright', values, ''));
    const [slotsLimit, setSlotsLimit] = useState(dV('slotsLimit', values, 0));
    const [hasSlotsLimit, setHasSlotsLimit] = useState(!!dV('hasSlotsLimit', values, false));
    const [hasDynamicUrl, setHasDynamicUrl] = useState(!!dV('hasDynamicUrl', values, 0));
    const [utmSource, setUtmSource] = useState(dV('utmSource', values, 'tapfluencer'));
    const [utmMedium, setUtmMedium] = useState(dV('utmMedium', values, '%USER%'));
    const [utmCampaign, setUtmCampaign] = useState(dV('utmCampaign', values, '%CAMPAIGN_ID%'));
    const [utmUrl, setUtmUrl] = useState(dV('utmUrl', values, ''));

    const [hasVariableField, setHasVariableField] = useState(!!dV('variableFieldLabel', values, false));
    const [variableFieldLabel, setVariableFieldLabel] = useState(dV('variableFieldLabel', values, ''));
    const [variableFieldValue, setVariableFieldValue] = useState(dV('variableFieldValue', values, ''));
    
    const [ifJobActionOther, setIfJobActionOther] = useState(dV('actionOther', values, '') ? true : false);
    const [noOfLinksOther, setNoOfLinksOther] = useState(dV('noOfLinksOther', values, null));
    const [actionOther, setActionOther] = useState(dV('actionOther', values, ''));
    const [ifJobBlog, setIfJobBlog] = useState(dV('blog', values, '') ? true : false);
    const [noOfLinksBlog, setNoOfLinksBlog] = useState(dV('noOfLinksBlog', values, null));
    const [blog, setBlog] = useState(dV('blog', values, ''));
    const [ifJobAttendEvent, setIfJobAttendEvent] = useState(dV('attendEvent', values, '') ? true : false);
    const [noOfLinksAttendEvents, setNoOfLinksAttendEvents] = useState(dV('noOfLinksAttendEvents', values, null));
    const [attendEvent, setAttendEvent] = useState(dV('attendEvent', values, ''));
    const [ifJobIGPost, setIfJobIGPost] = useState(dV('igPost', values, '') ? true : false);
    const [noOfLinksIGPost, setNoOfLinksIGPost] = useState(dV('noOfLinksIGPost', values, null));
    const [igPost, setIGPost] = useState(dV('igPost', values, ''));
    const [ifJobFBPost, setIfJobFBPost] = useState(dV('fbPost', values, '') ? true : false);
    const [noOfLinksFBPost, setNoOfLinksFBPost] = useState(dV('noOfLinksFBPost', values, null));
    const [fbPost, setFBPost] = useState(dV('fbPost', values, ''));
    const [ifJobInstaStory, setIfJobInstaStory] = useState(dV('instaStory', values, '') ? true : false);
    const [noOfLinksIGStory, setNoOfLinksIGStory] = useState(dV('noOfLinksIGStory', values, null));
    const [instaStory, setInstaStory] = useState(dV('instaStory', values, ''));
    const [ifJobFBStory, setIfJobFBStory] = useState(dV('fbStory', values, '') ? true : false);
    const [noOfLinksFBStory, setNoOfLinksFBStory] = useState(dV('noOfLinksFBStory', values, null));
    const [fbStory, setFBStory] = useState(dV('fbStory', values, ''));
    const [ifJobTiktokPost, setIfJobTiktokPost] = useState(dV('tiktokPost', values, '') ? true : false);
    const [noOfLinksTiktokPost, setNoOfLinksTiktokPost] = useState(dV('noOfLinksTiktokPost', values, null));
    const [tiktokPost, setTiktokPost] = useState(dV('tiktokPost', values, ''));
    // const [ifJobSocialMediaPost, setIfJobSocialMediaPost] = useState(dV('socialMediaPost', values, '') ? true : false);
    // const [socialMediaPost, setSocialMediaPost] = useState(dV('socialMediaPost', values, ''));
    const [ifJobActionImage, setIfJobActionImage] = useState(dV('actionImage', values, '') ? true : false);
    const [noOfLinksImage, setNoOfLinksImage] = useState(dV('noOfLinksImage', values, null));
    const [actionImage, setActionImage] = useState(dV('actionImage', values, ''));
    const [currencyCodesMap, setCurrencyCodesMap] = useState({});

    const screeningQuestionDS = { 
        screeningFormTitle: '',
        screeningFormDesc: '',
        questions: []
    }

    const [screeningQuestions, setScreeningQuestions] = useState(dV('screeningQuestions', values, screeningQuestionDS));

    const onProductImageChange = (e) => {
        let uploadedFile = e.target.files[0];
        setProductImage(uploadedFile);
        createImagePreview(uploadedFile, previewImage => setProductImagePreview(previewImage));                
            
        let fr = new FileReader;
    
        fr.onload = () => {
            let img = new Image;
            
            img.onload = function() {
                function gcd (a, b) {
                    return (b === 0) ? a : gcd (b, a%b);
                }
                let w = img.width;
                let h = img.height;
                const r = gcd(h ,w );
                if((w/r) !== 16 && (h/r) !== 9){
                    uploadedFile = null;
                    alert('Aspect ratio of the image should be 16:9');
                } 
            };
            
            img.src = fr.result;
        };
        
        fr.readAsDataURL(uploadedFile);
    }

        useEffect(() => {
                const newCurrencyCodes = {};
                conversionList.forEach(conversion => {
                    newCurrencyCodes[conversion['currency_code']] = conversion;
                });
                setCurrencyCodesMap(newCurrencyCodes);
            }, [conversionList]);
        
            useEffect(() => {
                if (paymentType === 'vipcredits' && currencyType > '' && currencyCodesMap[currencyType]) {
                    setEquivalentCredits(currencyCodesMap[currencyType].equivalent_credits * paymentValue);
                }
            }, [currencyType, paymentValue, paymentType]);

    const onLogoImageChange = (e) => {
        setLogo(e.target.files[0]);
        createImagePreview(e.target.files[0], previewImage => setLogoPreview(previewImage));
    }

    const onJobToggle = (e, toggleCallback, ...unsetCallbacks) => {
        toggleCallback(e.target.checked);
        unsetCallbacks.forEach(unsetCallback => unsetCallback(''));
    }

    const onNoOfLinksChange = (event, setCallback) => {
        let { value, min } = event.target;
        if (value !== undefined && value!== undefined && value !== '') {
            value = Math.max(min, value);
        }
        setCallback(value);
    }

    //set preview images for edit form
    useEffect(() => {
        if(type === 'edit') {
            setSlotsLimit(slotsLimit);
            //logo
            setLogoPreview(logo);
            //product image
            setProductImagePreview(productImage)    
        }
    }, []);

    useEffect(() => {
        const newCurrencyCodes = {};
        conversionList.forEach(conversion => {
            newCurrencyCodes[conversion['currency_code']] = conversion;
        });
        setCurrencyCodesMap(newCurrencyCodes);
    }, [conversionList]);

    useEffect(() => {
        if (paymentType === 'vipcredits' && currencyType > '' && currencyCodesMap[currencyType]) {
            setEquivalentCredits(currencyCodesMap[currencyType].equivalent_credits * paymentValue);
        }
    }, [currencyType, paymentValue, paymentType]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            screeningQuestions,
            campaignId,
            clientName,
            slotsLimit,
            hasSlotsLimit,
            brandName,
            ioNumber,
            logo,
            logoPreview,
            fbLink,
            instaLink,
            twitterLink,
            youtubeLink,
            tiktokLink,
            campaignName,
            description,
            instructions,
            guidelinesDont,
            productImage,
            productCategoryId,
            campaignName,
            description,
            instructions,
            guidelinesDont,
            productImage,
            productImagePreview,
            productCategoryId,
            campaignObjectives,
            targetAudience,
            toneAndVoice,
            consideration,
            backgroundInformation,
            mandatoryInclusions,
            paymentType,
            paymentValue,
            currencyType,
            payment,
            paymentTerms,
            termsAndConditions,
            isDefaultDistribution: +isDefaultDistribution,
            distributionRightsAndCopyright,
            hasDynamicUrl: +hasDynamicUrl,
            utmUrl,
            utmSource,
            utmMedium: '',
            utmCampaign: '',
            variableFieldLabel: hasVariableField ? variableFieldLabel : '',
            variableFieldValue: hasVariableField ? variableFieldValue : '',
            actionOther,            
            blog,
            attendEvent,
            igPost,
            fbPost,
            instaStory,
            fbStory,
            tiktokPost,
            // socialMediaPost,
            actionImage,
            noOfLinksAttendEvents,
            noOfLinksBlog,
            noOfLinksFBPost,
            noOfLinksFBStory,
            noOfLinksIGPost,
            noOfLinksIGStory,
            noOfLinksOther,
            noOfLinksTiktokPost,
            noOfLinksImage
        });
    }

    const onQuestionTitleChanged = (qTitle) => {
        setScreeningQuestions({
            ...screeningQuestions,
            screeningFormTitle: qTitle
        });
    }

    const onQuestionDescChanged = (qDescription) => {
        screeningQuestions.screeningFormDesc = qDescription;
        setScreeningQuestions({...screeningQuestions});
    }

    const onAddNewQuestionClicked = () => {
        screeningQuestions.questions.push({
            question: "",
            type:'dropdown',
            required: false,
            options: [],
            status: 1
        });
        setScreeningQuestions({...screeningQuestions})
    }

    const updateQuestion = (id, field, value) => {
        // this id is actually an index
        const questions = screeningQuestions.questions.map((q, i) => {
            if (i === id) {
                q[field] = value;
            }
            return q;
        });
        setScreeningQuestions({
            screeningFormTitle: screeningQuestions.screeningFormTitle,
            screeningFormDesc: screeningQuestions.screeningFormDesc,
            questions: [
                ...questions
            ]
        })
    }

    const onDuplicateQuestionClicked = (id) => {
        const questions = screeningQuestions.questions;
        screeningQuestions.questions.push({
            question: questions[id].question,
            type: questions[id].type,
            required: questions[id].required,
            options: questions[id].options,
            status:  questions[id].status
            });
        setScreeningQuestions({...screeningQuestions});
    }

    const onDeleteQuestionClicked = (id) => {
        screeningQuestions.questions[id].status = 0;        
        let activeQuestions = screeningQuestions.questions.filter(q => { 
            if(q.status > 0){
                return q;
            }});
        if(activeQuestions.length === 0) {
            alert('Minimum one question required');
        } else {
            setScreeningQuestions({...screeningQuestions});
        }
    }
    
    const showNewCategoryInput = () => {
        setIsNewCategoryVisible(true);
    }

    const addNewProductCategory = () => {
        const existingCategory = productCategoryList.find(category => category.name === newProductCategory);
        if (existingCategory) {
            return sendNotification('Product Category already exists!', 'error');
        }
        setLoading(true);
        productCategoryAdd(newProductCategory)
        .then(data => {
            setLoading(false);
            setIsNewCategoryVisible(false);
            setProductCategoryId(data.data.insertId);
            setNewProductCategory(undefined);
            setRefresh(!refresh);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    }

    const toggleDefaultDistribution = val => {
        setIsDefaultDistribution(val);
        setDistributionRightsAndCopyright('');
    }

    return (
        <div>
            {/*<ul class="wizard">
                            <li class="wizard-step-active">
                                <a href="#1" class="wizard-step-link">
                                    Campaign Details
                                </a>
                            </li>
                            <li class="wizard-step">
                                <a href="#2" class="wizard-step-link">
                                    Select Influencers
                                </a>
                            </li>
                            <li class="wizard-step">
                                <a href="#3" class="wizard-step-link">
                                    Review & Publish
                                </a>
                            </li>
                        </ul>*/}
            
            <form onSubmit={onFormSubmit}>
                <div className={cx("form-vertical-row")}>
                    <Text code>Part I</Text>
                    <h2>
                        Client Details&nbsp;
                        <Tooltip title="Enter all the client's details here">
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </h2>
                    

                    <div className={cx("form-wrap")}>
                        <label htmlFor="client-name">Client Name: </label>
                        <input id="client-name" type="text" value={clientName} onChange={e => setClientName(e.target.value)} required />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="brand-name">Brand Name: </label>
                        <input id="brand-name" type="text" value={brandName} onChange={e => setBrandName(e.target.value)} required />
                    </div>            

                    <div className={cx("form-wrap")}>
                        <label htmlFor="io-number">IO Number: </label>
                        <input id="io-number" type="text" value={ioNumber} onChange={e => setIONumber(e.target.value)} />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="logo">Brand's Logo: </label>
                        <div>
                            <span className={cx("btn-choose-file")}>
                                <input id="logo" type="file" className={cx("choose-file")} onChange={onLogoImageChange} />
                                <label htmlFor="">
                                    <span>Choose File</span>
                                    <span className={cx("upload-icon")}></span>
                                </label>
                            </span>
                            <span>Extension support : jpeg, jpg & png</span>
                        </div>
                    </div>
                    <div>
                        { logoPreview && <img src={logoPreview} className={cx("logo-preview")} alt="logo-preview" width="120" /> }
                    </div>
                </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2>
                            Brand's Social Media&nbsp;
                            <Tooltip title="Enter the brand social media links here">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("social-media-wrap")}>
                        <div className={cx("form-wrap")}><input className={cx("icon-fb")} type="text" value={fbLink} onChange={e => setFbLink(e.target.value)} placeholder="Fb link" /></div>
                        <div className={cx("form-wrap")}><input className={cx("icon-insta")} type="text" value={instaLink} onChange={e => setInstaLink(e.target.value)} placeholder="Insta link" /></div>
                        <div className={cx("form-wrap")}><input className={cx("icon-twitter")} type="text" value={twitterLink} onChange={e => setTwitterLink(e.target.value)} placeholder="Twitter link"/></div>
                        <div className={cx("form-wrap")}><input className={cx("icon-youtube")} type="text" value={youtubeLink} onChange={e => setYoutubeLink(e.target.value)} placeholder="Youtube link"/></div>
                        <div className={cx("form-wrap")}><input className={cx("icon-tiktok")} type="text" value={tiktokLink} onChange={e => setTiktokLink(e.target.value)} placeholder="Tiktok link"/></div>
                        </div>
                    </div>

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="">
                            Product Category:&nbsp;
                            <Tooltip title="Select the category here">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip> 
                        </h2>
                        <div className={cx("form-wrap")}>
                            <select id="product-category" value={productCategoryId} onChange={e => setProductCategoryId(e.target.value)} >
                                {
                                    productCategoryList.map((category, i) => {
                                        return <option value={category.id} key={i}>{category.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        {/*<div className={cx("form-wrap")}>
                            <span className={cx("blue-text")}>Add New Category <Icon type="plus-circle" onClick={showNewCategoryInput} /></span>
                        </div>
                        {
                            isNewCategoryVisible &&
                            <div className={cx("form-horizontal-row")}>
                                <div className={cx("form-wrap", "left-align")} style={{width: '100%'}}>
                                    <input id="new-category" type="text" style={{width: '100%'}} value={newProductCategory} onChange={e => setNewProductCategory(e.target.value)} required />
                                </div>
                                <div className={cx("form-wrap", "left-align")}>
                                    <button className={cx("btn-blue", "btn-circle")} type="button" onClick={addNewProductCategory}><Icon type="plus" /></button>
                                </div>
                            </div>
                        }*/}
                    </div>
                    

                <div className={cx("form-vertical-row")}>                    
                    <h2>Campaign Details</h2>                
                    <div className={cx("form-wrap")}>
                        <label htmlFor="campaign-name">Name of campaign:&nbsp;
                            <Tooltip title="E.g: Prudential Marina Bay Carnival">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </label>
                        <input id="campaign-name" type="text" value={campaignName} onChange={e => setCampaignName(e.target.value)} required />
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="product-image">Image of the product or event:&nbsp;
                            <Tooltip title="Add an image that explains the campaign more. This image will be viewable by the VIParent on the detailed brief">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </label>
                        <div>
                            <span className={cx("btn-choose-file")}>
                                <input id="product-image" type="file" className={cx("choose-file")} onChange={onProductImageChange} />
                                <label htmlFor="">
                                    <span>Choose File</span>
                                    <span className={cx("upload-icon")}></span>
                                </label>
                            </span>
                            <span>Extension support : jpeg, jpg & png</span>
                        </div>
                    </div>
                    <div>
                        { productImagePreview && <img src={productImagePreview} className={cx("logo-preview")} alt="image-preview" width="400" /> }
                    </div>
                </div>

                <div className={cx("form-vertical-row")}>
                    <h2 htmlFor="campaign-objectives">Campaign Objectives:&nbsp;
                        <Tooltip title="What are the objectives the client has set for this campaign? Note: the VIParent won't see this information">
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </h2>
                    <div className={cx("form-wrap")}>
                        <textarea id="campaign-objectives" type="text" value={campaignObjectives} onChange={e => setCampaignObjectives(e.target.value)} cols="20" rows="4"></textarea>
                    </div>
                </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="target-audience">Target Audience:&nbsp;
                            <Tooltip title="Through this campaign, what is the target audience the client is trying to reach? Note: the VIParent won't see this information">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="target-audience" type="text" value={targetAudience} onChange={e => setTargetAudience(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                    <hr/>

                    <Text code>Part II</Text>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="description">A little bit about this project:&nbsp;
                            <Tooltip title="Give a synopsis of this project. Do not mention deliverables here.">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="description" type="text" value={description} onChange={e => setDescription(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="mandatory-inclusions">Deliverable in brief:&nbsp;
                            <Tooltip title="Give a brief but adequate description of what the client wants the VIParents to do">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="mandatory-inclusions" type="text" value={mandatoryInclusions} onChange={e => setMandatoryInclusions(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="payment-type">
                            Payment Type:&nbsp;
                            <Tooltip title="Select the payment type">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip> 
                        </h2>
                        <div className={cx("form-wrap")}>
                            <select id="payment-type" value={paymentType} onChange={e => {
                                setPaymentType(e.target.value);
                                if (e.target.value === 'other') {
                                    setPaymentValue(0);
                                }
                            }} >
                                <option value="monetary">Monetary</option>
                                <option value="points">Points</option>
                                <option value="vipcredits">VIP Credits</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>

                    {
                        ['vipcredits', 'monetary'].includes(paymentType) &&
                        <div className={cx("form-vertical-row")}>
                            <h2 htmlFor="currency-type">Currency Type:&nbsp;
                                <Tooltip title="ex - USD, INR, RMB etc">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </h2>
                            <div className={cx("form-wrap")}>
                                <select id="currency-type" value={currencyType} onChange={e => setCurrencyType(e.target.value)} >
                                    <option value="" hidden>Please Select</option>
                                    {
                                        conversionList.map((conversion, i) => {
                                            return <option value={conversion.currency_code} key={i}>{conversion.currency_code.toUpperCase()}</option>
                                        })
                                    }
                                </select>
                                <h5>
                                    {paymentType === 'vipcredits' && currencyCodesMap[currencyType] && currencyType > '' ? 
                                    ((currencyCodesMap[currencyType] && currencyCodesMap[currencyType].equivalent_credits < 1) ? 
                                    `${currencyType.toUpperCase()} ${1/currencyCodesMap[currencyType].equivalent_credits} = 1 Credits`:
                                    `${currencyType.toUpperCase()} 1 = ${currencyCodesMap[currencyType].equivalent_credits} Credits`)
                                 : ''}
                                 </h5>
                            </div>
                        </div>
                    }

                    {
                        paymentType !== 'other' &&
                        <div className={cx("form-vertical-row")}>
                            <h2 htmlFor="payment-value">Payment Value:&nbsp;
                                <Tooltip title="Payment value in whole integers">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </h2>
                            <div className={cx("form-wrap")}>
                                <input id="payment-value" type="number" value={paymentValue} onChange={e => setPaymentValue(e.target.value)} />
                            </div>
                        </div> 
                    }


                    {
                        paymentType == 'vipcredits' &&
                        <div className={cx("form-vertical-row")}>
                            <h2 htmlFor="equivalent-credits">
                                Equivalent Credits:
                            </h2>
                            <div className={cx("form-wrap")}>
                                <input style={{backgroundColor: '#e6e6e6'}} id="equivalent-credits" type="number" value={equivalentCredits} onChange={e => setEquivalentCredits(e.target.value)} disabled />
                            </div>
                        </div>
                    }                 

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="payment">Payment:&nbsp;
                            <Tooltip title="List down exactly what the VIParents will be paid for this and how the payments will be made. E.g: $50 in NTUC vouchers">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="payment" type="text" value={payment} onChange={e => setPayment(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                    <div className={cx("form-vertical-row")}>
                    <h2>Does this campaign have limited slots available?
                        <Switch onChange={val => setHasSlotsLimit(val)}
                                checked={hasSlotsLimit}
                                id="dynamic-link-switch" /> </h2>

                    {hasSlotsLimit && <div className={cx("form-wrap")}>
                        <h2 htmlFor="slots-limit">Number of slots&nbsp;
                            <Tooltip title="Number of slots">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <input id="slots-limit" type="number" value={slotsLimit} onChange={e => setSlotsLimit(e.target.value)} />
                        </div>
                    </div>}
                </div>

                    <hr/>

                    <Text code>Part III</Text>

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="background-information">More about this campaign:&nbsp;
                            <Tooltip title="Please enter as much information about this campaign. You many include a background about the brand as well so VIParents have a good understanding about the project they're working on">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="background-information" type="text" value={backgroundInformation} onChange={e => setBackgroundInformation(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="instructions">Detailed Deliverables:&nbsp;
                            <Tooltip title="Provide VIParents with a detailed idea about what they are supposed to deliver">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="instructions" type="text" value={instructions} onChange={e => setInstructions(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="consideration">Consider adding:&nbsp;
                            <Tooltip title="Is there something you want the VIParents to consider adding? E.g: Consider showing your child holding the brand and smiling">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="consideration" type="text" value={consideration} onChange={e => setConsideration(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="do-not">Do not:&nbsp;
                            <Tooltip title="Is there anything you don't want them to mention at all? E.g: Do not mention competitor brands in this project">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <input id="do-not" type="text" value={guidelinesDont} onChange={e => setGuidelinesDont(e.target.value)} />
                        </div>
                    </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="tone-and-voice">Tone and Voice:&nbsp;
                            <Tooltip title="This is to give the VIParents an idea on how the post should read. E.g: Mum-to-mum sharing, friendly">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="tone-and-voice" type="text" value={toneAndVoice} onChange={e => setToneAndVoice(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>
                    
                    {/*<div className={cx("form-vertical-row")}>
                        <h2 htmlFor="payment-terms">Payment Terms: </h2>
                        <textarea id="payment-terms" type="text" value={paymentTerms} onChange={e => setPaymentTerms(e.target.value)} cols="20" rows="4"></textarea>
                    </div>*/}
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="tnc">Terms and Conditions:&nbsp;
                            <Tooltip title="Please mention any terms and conditions for this project. E.g. If the project isn't completed in accordance with the guidelines provided, payment won't be made.">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <textarea id="tnc" type="text" value={termsAndConditions} onChange={e => setTermsAndConditions(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>
                    
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="tnc">Distribution Rights and Copyright:&nbsp;
                            <Tooltip title="E.g: The client will own rights to the content collected through the study.">
                                <Icon type="info-circle" theme="filled" />
                            </Tooltip>
                        </h2>
                        <div className={cx("form-wrap")}>
                            <label>Default? </label>
                            <div className={cx("toggle-switach")}>
                                <Switch onChange={val => toggleDefaultDistribution(val)}
                                    checked={isDefaultDistribution}
                                    id="dynamic-link-switch" />
                            </div>
                        </div>
                        <div className={cx("form-wrap")}>
                            <textarea id="tnc" type="text" placeholder={isDefaultDistribution ? t('campaign_details_defaut_distribution_rights') : ''} disabled={isDefaultDistribution} value={distributionRightsAndCopyright} onChange={e => setDistributionRightsAndCopyright(e.target.value)} cols="20" rows="4"></textarea>
                        </div>
                    </div>

                <hr/>

                <div className={cx("form-vertical-row")}>
                    <h2>Dynamic Link</h2>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="">Set? </label>
                        <div className={cx("toggle-switach")}>
                            <Switch onChange={val => setHasDynamicUrl(val)}
                                checked={hasDynamicUrl}
                                id="dynamic-link-switch" />
                        </div>
                    </div>

                    {
                        hasDynamicUrl &&
                        <div>
                            <div className={cx("form-vertical-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="utm-url">Url: </label>
                                    <input id="utm-url" type="text" value={utmUrl} onChange={e => setUtmUrl(e.target.value)} required />
                                </div>
                                <Text mark>
                                    Don't use an already shortened url!
                                </Text>
                            </div>

                            <div className={cx("form-vertical-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="utm-source">UTM Source: </label>
                                    <input id="utm-source" type="text" value={utmSource} readOnly />
                                </div>
                            </div>

                            <div className={cx("form-vertical-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="utm-medium">UTM Medium: </label>
                                    <input id="utm-medium" type="text" value={utmMedium} readOnly />
                                </div>
                            </div>

                            <div className={cx("form-vertical-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="utm-campaign">UTM Campaign: </label>
                                    <input id="utm-campaign" type="text" value={utmCampaign} readOnly />
                                </div>
                            </div>

                            <div className={cx("form-vertical-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="utm-preview">Preview: </label>
                                    <input id="utm-preview" type="text" value={utmUrl + `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`} readOnly />
                                </div>
                            </div> 
                        </div>
                    }
                </div>

                <hr/>

                <div className={cx("job-details")}>
                    <h3>Variable Field</h3> 
                    <div className={cx("form-group")}>
                        <label htmlFor="">Set? </label>
                        <div className={cx("")}>
                            <Switch onChange={bVal => {
                                    setHasVariableField(bVal);
                                }}
                                checked={hasVariableField}
                                id="variable-field-switch" />
                        </div>
                    </div>
                    {
                        hasVariableField &&
                        <div className={cx("form-group")}>
                            <label htmlFor="variable-field">Variable Field:&nbsp;
                                <Tooltip title="Add a custom label and value. Will only be shown to approved users.">
                                    <Icon type="info-circle" theme="filled" />
                                </Tooltip>
                            </label>                        
                            <input id="variable-field-label" placeholder="Label" type="text" value={variableFieldLabel} onChange={e => setVariableFieldLabel(e.target.value)} style={{width: '150px'}} required />
                            <span> : </span>
                            <input id="variable-field-value" placeholder="Value" type="text" value={variableFieldValue} onChange={e => setVariableFieldValue(e.target.value)} style={{width: '250px'}} required />
                        </div>
                    }
                </div>

                <hr/>
                            
                <div className={cx("form-vertical-row")}>                    
                    <h2>Job Details</h2>
                    <Text mark>
                        Only fill in the box which is relevant to this campaign with the deliverable. If the client doesn't need any of the below deliverables, leave the box blank.
                    </Text>

                </div>
                
                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-other" type="checkbox" checked={ifJobActionOther} onChange={e => onJobToggle(e, setIfJobActionOther, setActionOther, setNoOfLinksOther)} />
                        <label for="chk-other">Other</label>
                    </div>  
                    {
                        ifJobActionOther &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-other" type="number" value={noOfLinksOther} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksOther)} required />
                        </div>
                    }                   
                    {
                        ifJobActionOther &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="action-other">Other: </label>*/}
                                <textarea id="action-other" type="text" value={actionOther} onChange={e => setActionOther(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>                    

                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-blog" type="checkbox" checked={ifJobBlog} onChange={e => onJobToggle(e, setIfJobBlog, setBlog, setNoOfLinksBlog)} />
                        <label for="chk-blog">Blog</label>
                    </div>
                    {
                        ifJobBlog &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-blog" type="number" value={noOfLinksBlog} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksBlog)} required />
                        </div>
                    }  
                    {
                        ifJobBlog &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="blog">Blog: </label>*/}
                            <textarea id="blog" type="text" value={blog} onChange={e => setBlog(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>
                    
                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-attend-event" type="checkbox" checked={ifJobAttendEvent} onChange={e => onJobToggle(e, setIfJobAttendEvent, setAttendEvent, setNoOfLinksAttendEvents)} />
                        <label for="chk-attend-event">Attend Event</label>
                    </div>
                    {
                        ifJobAttendEvent &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-attend-events" type="number" value={noOfLinksAttendEvents} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksAttendEvents)} required />
                        </div>
                    }  
                    {
                        ifJobAttendEvent &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="attend-event">Attend Event: </label>*/}
                            <textarea id="attend-event" type="text" value={attendEvent} onChange={e => setAttendEvent(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>
                    
                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-ig-post" type="checkbox" checked={ifJobIGPost} onChange={e => onJobToggle(e, setIfJobIGPost, setIGPost, setNoOfLinksIGPost)} />
                        <label for="chk-ig-post">IG Post</label>
                    </div>
                    {
                        ifJobIGPost &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-ig-post" type="number" value={noOfLinksIGPost} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksIGPost)} required />
                        </div>
                    }  
                    {
                        ifJobIGPost &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="ig-post">IG Post: </label>*/}
                            <textarea id="ig-post" type="text" value={igPost} onChange={e => setIGPost(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>

                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-fg-post" type="checkbox" checked={ifJobFBPost} onChange={e => onJobToggle(e, setIfJobFBPost, setFBPost, setNoOfLinksFBPost)} />
                        <label for="chk-fg-post">FB Post</label>
                    </div>
                    {
                        ifJobFBPost &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-fb-post" type="number" value={noOfLinksFBPost} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksFBPost)} required />
                        </div>
                    }  
                    {
                        ifJobFBPost &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="fb-post">FB Post: </label>*/}
                            <textarea id="fb-post" type="text" value={fbPost} onChange={e => setFBPost(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>

                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-ig-story" type="checkbox" checked={ifJobInstaStory} onChange={e => onJobToggle(e, setIfJobInstaStory, setInstaStory, setNoOfLinksIGStory)} />
                        <label for="chk-ig-story">IG Story</label>
                    </div>
                    {
                        ifJobInstaStory &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-ig-story" type="number" value={noOfLinksIGStory} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksIGStory)} required />
                        </div>
                    }  
                    {
                        ifJobInstaStory &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="ig-story">IG Story: </label>*/}
                            <textarea id="ig-story" type="text" value={instaStory} onChange={e => setInstaStory(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>
                    
                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-fb-story" type="checkbox" checked={ifJobFBStory} onChange={e => onJobToggle(e, setIfJobFBStory, setFBStory, setNoOfLinksFBStory)} />
                        <label for="chk-fb-story">FB Story</label>
                    </div>
                    {
                        ifJobFBStory &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-fb-story" type="number" value={noOfLinksFBStory} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksFBStory)} required />
                        </div>
                    }  
                    {
                        ifJobFBStory &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="fb-story">FB Story: </label>*/}
                            <textarea id="fb-story" type="text" value={fbStory} onChange={e => setFBStory(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>

                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-tiktok-post" type="checkbox" checked={ifJobTiktokPost} onChange={e => onJobToggle(e, setIfJobTiktokPost, setTiktokPost, setNoOfLinksTiktokPost)} />
                        <label for="chk-tiktok-post">Tiktok</label>
                    </div>
                    {
                        ifJobTiktokPost &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-tiktok-post" type="number" value={noOfLinksTiktokPost} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksTiktokPost)} required />
                        </div>
                    }  
                    {
                        ifJobTiktokPost &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="tiktok-post">FB Story: </label>*/}
                            <textarea id="tiktok-post" type="text" value={tiktokPost} onChange={e => setTiktokPost(e.target.value)} cols="10" rows="2" placeholder="Write instructions" />
                        </div>
                    }                        
                </div>
                    
                {/*<div className={cx("form-group")}>
                    <label className={cx("checkbox-wrapper", "action-other")}>Social Media Post
                        <input type="checkbox" checked={ifJobSocialMediaPost} onChange={e => onJobToggle(e, setIfJobSocialMediaPost, setSocialMediaPost)} />
                        <span className={cx("checkmark")}></span>
                    </label>
                    {
                        ifJobSocialMediaPost &&
                        <div className={cx("form-group")}>
                            {<label htmlFor="social-media-post">Social Media Post: </label>}
                            <textarea id="social-media-post" type="text" value={socialMediaPost} onChange={e => setSocialMediaPost(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>*/}

                <div className={cx("form-vertical-row")}>
                    <div class="custom-checkbox-wrap">
                        <input class="styled-checkbox" id="chk-supporting-img" type="checkbox" checked={ifJobActionImage} onChange={e => onJobToggle(e, setIfJobActionImage, setActionImage, setNoOfLinksImage)} />
                        <label for="chk-supporting-img">Supporting Image</label>
                    </div>
                    {
                        ifJobActionImage &&
                        <div className={cx("form-wrap")}>
                            <input placeholder="No of links to be submitted" id="no-of-links-image" type="number" value={noOfLinksImage} min="1" onChange={e => onNoOfLinksChange(e, setNoOfLinksImage)} required />
                        </div>
                    }
                    {
                        ifJobActionImage &&
                        <div className={cx("form-wrap")}>
                            {/*<label htmlFor="action-image">Supporting Image: </label>*/}
                            <textarea id="action-image" type="text" value={actionImage} onChange={e => setActionImage(e.target.value)} cols="10" rows="2" placeholder="Write instructions"></textarea>
                        </div>
                    }                        
                </div>

                <hr/>

                <ScreeningQuestions
                    data={screeningQuestions}
                    onQuestionTitleChanged={onQuestionTitleChanged}
                    onQuestionDescChanged={onQuestionDescChanged}
                    onAddNewQuestionClicked={onAddNewQuestionClicked}
                    onQuestionTextChanged={(id, qText) => updateQuestion(id, "question", qText)}
                    onQuestionTypeChanged={(id, qType) => updateQuestion(id, "type", qType)}
                    onUpdateOptions={(id, options) => updateQuestion(id, "options", options)}
                    onIsRequiredSwitched={(id, state) => updateQuestion(id, "required", state)}
                    onDuplicateQuestionClicked={onDuplicateQuestionClicked}
                    onDeleteQuestionClicked={onDeleteQuestionClicked}
                />

                <hr />
                
                <div className={cx("button-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">{type === 'create' ? 'Next' : 'Save'}</button>
                    <button className={cx("btn", "btn-light")}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default Form;