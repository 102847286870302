import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import UserTable from './Table';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';
import UserApprovalWidget from '../../Stats/Widget/UserApproval';
import Tabs from '../../../components/UIElems/Tabs';
import { Icon } from 'antd';

const Users = (props) => {
    let params = new URLSearchParams(props.location.search);
    let tabIndex = params.get('tab');

    return (
        <BaseLayout leftCards={[]}>
            <div className={cx('all-users', 'last-col-manage-padding')}>
                <h1>Users</h1>

                <Tabs tabsHandler={() => {}}
                    defaultActiveKey={tabIndex}
                    elems={[
                        {
                            name: <Icon type="bar-chart" />,
                            elem: <UserApprovalWidget height="600px" />
                        },
                        {
                            name: 'List',
                            elem: <UserTable conditions={{}} />
                        }
                    ]} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);
