export const loadState = (serverState) => {
    try {
        const serializedState = serverState ? serverState : sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return (typeof serializedState === 'string') ? JSON.parse(serializedState) : serverState;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        // console.log(err);
        // Ignore write errors.
    }
};