import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { format, differenceInDays } from 'date-fns';
import { useInfluencerCampaignApplicationStatus, useInfluencerCampaignSubmissionStatus } from '../../../../../hooks/generic';

const CampaignListCard = ({t, data, influencerId, dispatch}) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    // const applicationStatus = useInfluencerCampaignApplicationStatus(dispatch, influencerId, data.campaignId, forceUpdate);
    const applicationStatus = data.applicationStatus;
    const isSubmitted = useInfluencerCampaignSubmissionStatus(
        () => {},
        dispatch,
        data.campaignId,
        influencerId,
        forceUpdate
    );

    const applicationStatusText = {
        0: t('campaign_status_decline'),
        1: t('campaign_status_approved'),
        2: t('campaign_status_awaiting_approval'),
        4: t('approval_status_4'),
        5: t('approval_status_5')
    };

    return (
       <div className={cx("campaign-list-card-wrapper")}>
            <div className={cx("campaign-list-card-header")}>
                <div className={cx("clients-details")}>
                    <div className={cx("client-logo")}>
                        <img src={data.logo} alt="logo" />
                    </div>
                    <div className={cx("client-name")}>
                        <div>{data.clientName}</div>
                        <div>{data.brandName}</div>
                    </div>
                </div>
                <div className={cx("card-status")}>
                {
                    applicationStatus !== null &&
                    <div className={cx("dynamic-status-" + applicationStatus)}>{ applicationStatusText[applicationStatus] }</div>
                }
                </div>
            </div>          
            
            <div className={cx("campaign-list-card-container")}>
                <div className={cx("card-description")}>
                    {data.description}
                </div> 
                <div className={cx("social-links-container")}>
                    { 
                        data.fbLink && 
                        <div className={cx("social-links")}>
                            <span className={cx("fb-iconFluencer")}></span>
                            <span className={cx("social-links-text")}>
                                <a href={data.fbLink} target="blank">Facebook</a>
                            </span>
                        </div> 
                    }
                    { 
                        data.twitterLink && 
                        <div className={cx("social-links")}>
                            <span className={cx("twitter-iconFluencer")}></span>
                            <span className={cx("social-links-text")}>
                                <a href={data.twitterLink} target="blank">Twitter</a>
                            </span>
                        </div> 
                    }
                    { 
                        data.instaLink && 
                        <div className={cx("social-links")}>
                            <span className={cx("insta-iconFluencer")}></span>
                            <span className={cx("social-links-text")}>
                                <a href={data.instaLink} target="blank">Instagram</a>
                            </span>
                        </div> 
                    }
                    { 
                        data.youtubeLink && 
                        <div className={cx("social-links")}>
                            <span className={cx("youtube-iconFluencer")}></span>
                            <span className={cx("social-links-text")}>
                                <a href={data.youtubeLink} target="blank">Youtube</a>
                            </span>
                        </div> 
                    }
                    { 
                        data.tiktokLink && 
                        <div className={cx("social-links")}>
                            <span className={cx("tiktok-iconFluencer")} />
                            <span className={cx("social-links-text")}>
                                <a href={data.tiktokLink} target="blank">Tiktok</a>
                            </span>
                        </div> 
                    }
                </div>
            </div>

            <div className={cx("date-wrap")}>
                <div>
                    <span>{t('campaign_list_date')}: </span>
                    <span>{ data.startDate ? format(data.startDate, 'Do MMM YYYY') : '' } </span>
                    <span>{t('campaign_list_to')} </span>
                    <span>{ data.endDate ? format(data.endDate, 'Do MMM YYYY') : '' }</span>
                </div>
                <div>
                    <span>{ differenceInDays(data.endDate, data.startDate) } </span>
                    <span>{t('campaign_list_days_to_do')}</span>
                </div>
            </div>

            <div className={cx("campaign-list-card-footer")}>
                {
                    applicationStatus === 1 &&
                    <a className={cx("btn", "btn-blue", "item")} href={`/influencer/campaign/${data.campaignId}`}>{t('campaign_list_view_and_submit')}</a>
                }
                {
                    applicationStatus === 5 &&
                    <a className={cx("btn", "btn-blue", "item")} href={`/influencer/campaign/${data.campaignId}`}>{t('campaign_list_view_and_edit')}</a>
                }
                {
                    applicationStatus !== 1 && applicationStatus !== 5 &&
                    <a className={cx("btn", "btn-blue", "item")} href={`/influencer/campaign/${data.campaignId}`}>{t('campaign_list_view')}</a>
                }
            </div> 
        </div>
    );
}

export default CampaignListCard;