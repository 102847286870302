import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import SidebarTemplate from '../Template/index';

const Credits = ({links = [], currentTitle=null, defaultState='expand'}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: `/brand/credits/evoucher/all`, title: 'eVoucher', ifActive: (pathname.startsWith('/brand/credits/evoucher') ? 'active-item' : '')},
    ];

    //set current
    if(currentTitle) {
        for(let k in defaultLinks) {
            if(defaultLinks[k]['title'] == currentTitle)
                defaultLinks[k]['ifActive'] = 'active-item';
        }
    }


    return (
        <SidebarTemplate heading="VIP Credits"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-credits-icon" />
    );
}

export default Credits;