import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Rate } from 'antd';

const RateComponent = (props) => {
    return (
        <div>
            <Rate {...props}
                allowHalf={false} />
        </div>        
    );
}

export default RateComponent;