import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { influencerSocialAccountDelete } from '../../../../../../actions';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';

const Icon = () => {
    return (
        <span style={{ display: 'inline-block', borderRadius: '50%', width: '30px', height: '30px', position: 'absolute', top: '47px', left: '51px', background: '#517fa5', border: 'none' }}>
            <svg style={{ width: '22px', height: 'auto', marginTop: '4px'}} enableBackground="new 0 0 56.693 56.693" height="56.693px" version="1.1" viewBox="0 0 56.693 56.693" width="56.693px"><path fill="#fff" d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029  c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77  L40.43,21.739z"/></svg>
        </span>
    );
}

const Fb = (props) => {
    const t = props.t;
    
    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'fb', false);

    const onConnect = () => {
        window.location = '/social/auth/facebook';
    }

    const onCountRefresh = () => {
        if(window.confirm("Are you sure you want to refresh the account?")) {
            // props.actions.influencerSocialAccountDelete(props.rdx.influencerReducer.id, 'fb')
            // .then(res => {
                window.location = '/social/auth/facebook';
            // })
        }        
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("fb-icon-social-account-bg")}>
                    <div className={cx("fb-followers-social-icon")}></div>
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>{t('social_account_connect')} +</button>
            </div>
        );        
    }

    const ConnectedState = () => {
        let data = socialAccount.fb;

        return (
            <div>
                {
                    data.profileImage
                    ? 
                        <div style={{position: 'relative'}} className={cx("followers-profile-image-wrap")}>
                            <a href={data.profileUrl} target="_blank" ><img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                            <Icon />
                            </a>
                        </div>
                    : 
                        <div style={{position: 'relative'}} className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")}></div>
                            <Icon />
                        </div>
                }
                {/*<div>{data.socialName}</div>
                <div><img src={} alt=""/></div>*/}
                <div className={cx("btn", "btn-followers")}>{t('social_account_followers')}: {data.followerCount ? data.followerCount : 0}</div>
                {/*<div>Posts: {data.postsCount ? data.postsCount : 0}</div>*/}

                <div className={cx("refresh-followers-wrap")}>
                    <a href="#" className={cx("btn-refresh")} onClick={e => onCountRefresh()}>
                        <span className={cx("icon-refresh")}></span>
                        <span>{t('social_account_refresh')}</span>
                    </a>
                </div>
            </div>
        );        
    }
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.fb
                ? <ConnectedState />
                : <ConnectState />
            }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerSocialAccountDelete
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Fb);
