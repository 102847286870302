import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerCampaignActions, useInfluencerCampaignModeration } from '../../../../hooks/generic';
import { genericAction, influencerCampaignModerationUpdate } from '../../../../actions';
import ModerationCreateComponent from '../../../../components/Mob/Campaign/Moderation/Create';
import ModerationPostFeedbackComponent from './Feedback';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';
import Loader from '../../../../components/UIElems/Loader';
import Tabs from '../../../../components/UIElems/Tabs';

const Moderation = (props) => {
    const t = props.t;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const campaignDetail = props.rdx.webviewReducer.campaignDetail;
    
    const actionData = useInfluencerCampaignActions(
        () => setIsLoading(false),
        props.dispatch,
        campaignDetail.campaignId,
        props.rdx.webviewReducer.profile.influencerId,
        forceUpdate
    );

    const moderationData = useInfluencerCampaignModeration(
        () => setIsLoading(false),
        props.dispatch,
        campaignDetail.campaignId,
        props.rdx.webviewReducer.profile.influencerId,
        forceUpdate
    );

    const onSubmit = (data) => {
        setIsLoading(true);
        props.actions.influencerCampaignModerationUpdate(data)
        .then(() => {
            setForceUpdate(!forceUpdate);
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
        })
        .catch(() => setIsLoading(false))
    }

    const notify = (message, type) => {
        notification.send({device: props.rdx.appReducer.deviceType, message: message, type: type});
    }

    const POST_TYPES = {
        fb: 'Facebook',
        fbStory: 'Facebook Story',
        insta: 'Instagram',
        instaStory: 'Instagram Story',
        youtube: 'Youtube',
        twitter: 'Twitter'
    };

    const PostTab = () => {
        return (
            <div>
                {
                    moderationData
                    .map((d, i) => {
                        return (
                            <div className={cx("white-block")} key={i}>
                                <div className={cx("campaign-sub-title")}>
                                    <h2>{d.type in POST_TYPES ? POST_TYPES[d.type] : d.type}</h2>
                                </div>
                                <Tabs tabsHandler={() => {}}
                                    defaultActiveKey={1}
                                    elems={[
                                        {
                                            name: t('moderation_post'),
                                            elem: <ModerationCreateComponent campaignId={campaignDetail.campaignId}
                                                    influencerId={props.rdx.webviewReducer.profile.influencerId}
                                                    postType={d.type}
                                                    mode="edit"
                                                    defaultValues={d}
                                                    onSubmit={onSubmit}
                                                    notify={notify}
                                                    t={props.t} />
                                        },
                                        {
                                            name: t('moderation_feedback'),
                                            elem: <ModerationPostFeedbackComponent postId={d.id} />
                                        }
                                    ]} />                            
                            </div>
                        )
                    })                    
                }
                {
                    actionData.pendingFields
                    .filter(f => ['other', 'image', 'blog', 'event'].indexOf(f.name) == -1)
                    .map((field, i) => {
                        return (
                            <div className={cx("white-block")} key={i}>
                                <div className={cx("campaign-sub-title")}>
                                    <h2>{field.name in POST_TYPES ? POST_TYPES[field.name] : field.name}</h2>
                                </div>
                                <Tabs tabsHandler={() => {}}
                                    defaultActiveKey={1}
                                    elems={[
                                        {
                                            name: t('moderation_post'),
                                            elem: <ModerationCreateComponent campaignId={campaignDetail.campaignId}
                                                    influencerId={props.rdx.webviewReducer.profile.influencerId}
                                                    postType={field.name}
                                                    mode="create"
                                                    onSubmit={onSubmit}
                                                    notify={notify}
                                                    t={props.t} />
                                        },
                                        {
                                            name: t('moderation_feedback'),
                                            elem: <ModerationPostFeedbackComponent postId={null} />
                                        }
                                    ]} />                            
                            </div>
                        )
                    })                    
                }
            </div>
        );
    }
    
    return (
        <div className={cx("mob-moderation-wrapper")}>
            <div className={cx("white-block")}>
                <div className={cx("campaign-sub-title")}>
                    <h1>{t("moderation_create_post")}</h1>
                </div>
                <h3>{t("moderation_before_you_create_a_post")}</h3>
            </div>
            <PostTab />
            { isLoading && <Loader /> }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
        influencerCampaignModerationUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Moderation));