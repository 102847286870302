import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../hooks/generic';
import { influencerFeedbackAdd } from '../../../actions';
import FeedbackAddComponent from '../../../components/Influencer/Feedback/Add';
import BaseLayout from '../../../components/Layout/Influencer/BaseLayout';
import { translate } from 'react-i18next';
import notification from '../../../components/UIElems/Notification';
import Loader from '../../../components/UIElems/Loader';

const InfluencerFeedback = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => {
        setIsLoading(true);
        data['influencerId'] = props.rdx.influencerReducer.id;
        props.actions.influencerFeedbackAdd(data)
        .then(() => {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Feedback Sent!', type: 'success'});
        })     
        
    }

    return (
        <BaseLayout leftCards={[]}>
            <div>
                <FeedbackAddComponent onSubmit={onSubmit}
                    t={props.t} />
                    
                { isLoading && <Loader />}
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerFeedbackAdd
    }, dispatch), 
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(InfluencerFeedback));