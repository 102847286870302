import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../actions';
import {} from '../../../../hooks/generic';
import { Typography, Popover, Icon } from 'antd';
import notification from '../../../../components/UIElems/Notification';
import GradeAdjust from '../../../../containers/Brand/Grading/Rate';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';

const { Text } = Typography;

const RatingList = ({data}) => {
    const content = (
        <div>
            <div>
                Primary Rating -
                <GradeAdjust value={data.primaryRating}
                    campaignId={data.campaignId}
                    influencerId={data.influencerId}
                    type="primary_rating"
                    comment={data.comment}
                    disabled={true} /> 
            </div>            
            <div>
                Correctness -
                <GradeAdjust value={data.correctness}
                    campaignId={data.campaignId}
                    influencerId={data.influencerId}
                    type="correctness"
                    comment={data.comment}
                    disabled={true} /> 
            </div>
            <div>
                Creativity -
                <GradeAdjust value={data.creativity}
                    campaignId={data.campaignId}
                    influencerId={data.influencerId}
                    type="creativity"
                    comment={data.comment}
                    disabled={true} /> 
            </div>
            <div>
                Punctuality -
                <GradeAdjust value={data.punctuality}
                    campaignId={data.campaignId}
                    influencerId={data.influencerId}
                    type="punctuality"
                    comment={data.comment}
                    disabled={true} /> 
            </div>
        </div>
    );

    return content;
}

const RatingInfoPopover = ({data, content}) => {
    return (
        <Popover content={content}
            title={ <a href={`/brand/user/${data.influencerId}/grading/history`} target="_blank">Detailed Ratings</a> }
            trigger="hover">
            <div>
                <GradeAdjust value={data.primaryRating}
                    campaignId={data.campaignId}
                    influencerId={data.influencerId}
                    type="primary_rating"
                    comment={data.comment}
                    disabled={true} /> 
            </div>
        </Popover>
    );
}

const RatingPopup = ({data, mode = 'popover'}) => {    
    return (
        <div className={cx('grading-history-parent')}>
            {
                mode == 'popover'
                ? <RatingInfoPopover data={data} content={<RatingList data={data} />} />
                : <RatingList data={data} />
            }
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({        
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(RatingPopup);