import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerCampaignModerationPostFeedback } from '../../../../../hooks/generic';
import { genericAction } from '../../../../../actions';
import { translate } from 'react-i18next';
import notification from '../../../../../components/UIElems/Notification';
import Loader from '../../../../../components/UIElems/Loader';
import {ScrollPanel} from 'primereact/scrollpanel';

const Moderation = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const feedbackData = useInfluencerCampaignModerationPostFeedback(
        () => setIsLoading(false),
        props.dispatch,
        props.postId,
        forceUpdate
    );
    
    return (
        <div>
            <ScrollPanel style={{width: '100%', height: '200px'}}>
                {
                    feedbackData.list.map((d, i) => {
                        return <div key={i}>
                            {d.comment}
                        </div>
                    })
                }
                {
                    feedbackData.total == 0 &&
                    <div>
                        No Feedback.
                    </div>
                }
            </ScrollPanel>
            { isLoading && <Loader /> }
        </div>            
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Moderation));