import React from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from '../../Layout/Influencer/BaseLayout';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';

const Template404 = ({t}) => {
    return (
    	<BaseLayout>
	        <div className={cx("template-404-wrapper")}>
	        	<div className={cx("template-404-details")}>
	        	<div className={cx("template-404-page-not-found")}>
						<p>{t("404-page-message")}</p>
		            </div>
		            <div className={cx("template-404-image")}>
		            	<img src="https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/panels/mobile_survey.png" alt="404"/>
		            </div>
		           {/* <div className={cx("template-404-text")}>
		            	<h2>404</h2>
		            </div>
		            <div className={cx("template-404-go-back")}>
						<a href="/">{t('campaign_go_back')}</a>
		            </div>*/}
	            </div>
	        </div>
        </BaseLayout>
    );
}

export default translate()(Template404);