/*Custom Hooks*/
import { useState, useEffect } from 'react';
import {
    genericAction
 } from '../../actions';


export const useStatsUserApproval = (loaderCallback, dispatch, fromDate, toDate, forceUpdate = false) => {
    const [rowList, setRowList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('statsUserApproval', [fromDate, toDate]))
        .then(response => {
            if(!response.err && response.data) {
                setRowList(response.data);
            }
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return rowList;
}

export const useStatsCampaignApproval = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsCampaignApproval', [campaignId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsSubmissions = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsSubmissions', [campaignId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsRatings = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsRatings', [campaignId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsReach = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsReach', [campaignId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsEmail = (loaderCallback, dispatch, campaignId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsEmail', [campaignId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsApplicationsAndSubmissionsByWeek = (loaderCallback, dispatch, campaignId, startDate, endDate, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsApplicationsAndSubmissionsByWeek', [campaignId, startDate, endDate]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate, startDate, endDate])    

    return stats;
}

export const useStatsPanelUsersSubmitted = (loaderCallback, dispatch, fromDate, toDate, forceUpdate = false) => {
    const [rowList, setRowList] = useState({list: [], total: 0});

    useEffect(() => {
        dispatch(genericAction('statsPanelUsersSubmitted', [fromDate, toDate]))
        .then(response => {
            setRowList(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return rowList;
}

export const useStatsPanelJobSubmissions = (loaderCallback, dispatch, panel, forceUpdate = false) => {
    const [stats, setStats] = useState(null);
    useEffect(() => {
        dispatch(genericAction('statsPanelJobSubmissions', [panel]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export const useStatsPanelEmail = (loaderCallback, dispatch, panelId, forceUpdate = false) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        dispatch(genericAction('statsPanelEmail', [panelId]))
        .then(response => {
            setStats(response.data);
            loaderCallback();
        })
        .catch(err => console.log(err))
    }, [forceUpdate])    

    return stats;
}

export default {
    useStatsUserApproval,
    useStatsCampaignApproval,
    useStatsSubmissions,
    useStatsRatings,
    useStatsReach,
    useStatsEmail,
    useStatsApplicationsAndSubmissionsByWeek,
    useStatsPanelUsersSubmitted,
    useStatsPanelJobSubmissions,
    useStatsPanelEmail
}