import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TabComponent = ({elems = [], defaultActiveKey = '1', tabsHandler = () => {}, animated={}}) => {
    return (
        <div>
            <Tabs defaultActiveKey={String(defaultActiveKey)} onChange={tabsHandler} animated={animated}>
                {
                    elems.map((elem, i) => {
                        return <TabPane tab={elem.name} key={i + 1}>
                            {elem.elem}
                        </TabPane>
                    })
                }
            </Tabs>
        </div>        
    );
}

export default TabComponent;