/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
 function IS() {
    return {
        globalLoader: false,
        deviceType: 'desktop',
        country: 'sg',
        isCrawler: false
    }
}

const INITIAL_STATE = IS();

export var appReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();
    //    
    switch (action.type) {
        case 'SET_APP_PARAMS':
            let newObj = {...state};
            for(let k in action.payload) {
                newObj[k] = action.payload[k];
            }
            return newObj;
        break;
        case 'UNSET_APP_PARAMS':
            let updatedObj = {...state};
            for(let k in action.payload) {
                if(updatedObj[k]) delete updatedObj[k];
            }
            return updatedObj;
        break; 
        case 'START_GLOBAL_LOADER':
            return {
                ...state,
                globalLoader: true
            };
        break;
        case 'COMPLETE_GLOBAL_LOADER':
            return {
                ...state,
                globalLoader: false
            };
        break;                
        default:
            return state;
    }
};
