import React, { Fragment, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { translate } from 'react-i18next';
import Loading from '../../../../components/Mob/Loading';
import qs from 'qs';
import { mobTiktokConnectCallback } from '../../../../actions';

const TiktokAuth = (props) => {
    useEffect(() => {
        const {influencerId, username, code, state, error} = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        props.actions.mobTiktokConnectCallback(influencerId, username, code, state, error)
            .then(data => {
                window.location = data.data.redirectUrl;
            });
    }, []);
    return <Loading />
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        mobTiktokConnectCallback
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TiktokAuth));