import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { translate } from "react-i18next";

import MobCampaignTermsAndDetailsComponent from "../../../../components/Mob/Campaign/TermsAndConditions";

const TermsAndConditions = (props) => {
    return (
        <MobCampaignTermsAndDetailsComponent
            data={props.rdx.webviewReducer.data}
            t={props.t}
        />
    )
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TermsAndConditions));