import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';

const SubmissionMetrics = ({taskCaller}) => {
    const [type, setType] = useState('');

    const onClick = () => {
        if(!type) return alert("Select something!");
        taskCaller({type});
    }

    return (
        <div>
            <div className="submission-metrics">
                Queue Submission Metrics:
                <div className={cx("form-group")}>
                    <label htmlFor="">Select type: </label>
                    <select id="type" value={type} onChange={e => setType(e.target.value)} >
                        <option value="">select</option>
                        <option value="insta">Insta</option>
                    </select>
                </div>
                <button type="button" onClick={onClick}>Queue</button>
            </div>
        </div>
    );
}

export default SubmissionMetrics;