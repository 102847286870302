import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Card} from 'antd';

const CardElem = (props) => {    
    let style = 'style' in props ? props.style : {};
    let bordered = 'bordered' in props ? props.bordered : false;

    return (
        <Card className={cx("stats-cards")} title={props.title} bordered={bordered} style={style}>
            {props.children}
        </Card>
    );
}

export default CardElem;