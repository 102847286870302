import React, { useState} from 'react';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import {connect} from 'react-redux';
import CreateForm from '../../../../components/Brand/Panel/Form';
import { panelSave } from '../../../../actions';
import { bindActionCreators } from 'redux';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';

const Create = props => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = data => {
        if (data.panelType && data.panelType === 'poll' && (!data.pollIds || data.pollIds.length === 0)) {
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Please add atleast 1 poll to the job!', type: 'error'});
        }
        setIsLoading(true);
        props.actions.panelSave(data).then(res => {
            setIsLoading(false);
            if(!res.err) window.location = `/brand/panel/${res.insertId}/filter`;
        })
    }

    return (
        <BaseLayout>
            <div className="jobs-panel-create">
                <h1>Create Job - Panel</h1>
                <CreateForm 
                    onSubmit={onSubmit}
                    values={{}}
                    type="create"
                    deviceType={props.rdx.appReducer.deviceType}
                />
            </div>

            { isLoading && <Loader /> }
        </BaseLayout>

    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state});

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        panelSave
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Create);