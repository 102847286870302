import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// import {} from '../../../actions';
import { useStatsApplicationsAndSubmissionsByWeek } from '../../../../hooks/stats';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';
import { LineChart } from '../../../../components/UIElems/Charts';
import { DatePicker } from 'antd';
// import moment from 'moment';

// const { RangePicker } = DatePicker;

// const USER_DATE_FORMAT = 'DD-MM-YYYY';
// const SYS_DATE_FORMAT = 'YYYY-MM-DD';

const UserActivity = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldRefreshChart, setShouldRefreshChart] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    // const [fromDateMoment, setFromDateMoment] = useState(moment('2019-01-01', SYS_DATE_FORMAT));
    // const [toDateMoment, setToDateMoment] = useState(moment());
    // 
    const list = useStatsApplicationsAndSubmissionsByWeek(
        () => {
            setIsLoading(false);
            setTimeout(() => setShouldRefreshChart(!shouldRefreshChart), 1000);
        },
        props.dispatch,
        props.campaignId,
        props.startDate,
        props.endDate,
        forceUpdate
    );

    // const onFetch = () => {
    //     if(!fromDateMoment && !toDateMoment) {
    //         return alert('Set a proper date range!');
    //     } else {
    //         setIsLoading(true);
    //         setForceUpdate(!forceUpdate);
    //     }
    // }
    
    return (
        <div>
            {/*<div className="filters" style={{marginBottom: '10px'}}>
                <div className="row1 row-wrapper">
                    <span>
                        <DatePicker value={fromDateMoment}
                            onChange={(d, s) => setFromDateMoment(d)} />
                        <span> ~ </span>
                        <DatePicker value={toDateMoment}
                            onChange={(d, s) => setToDateMoment(d)} />
                    </span>
                </div>

                <div>&nbsp;</div>

                <div className="row4">
                    <button type="button" className={cx("btn", "btn-blue")} onClick={e => onFetch()}>Fetch</button>
                </div>
            </div>*/}

            <div  className={cx("stats-cards-white")} onClick={e => window.open(`/brand/campaign/${props.campaignId}/grading`, '_blank')} style={{cursor: 'pointer'}}>
                <div className={cx("ant-card", "stats-cards")}>
                    <div className={cx("ant-card-head")}>
                        <div className={cx("ant-card-head-wrapper")}>
                            <div className={cx("ant-card-head-title")}>
                                Activity By Week
                            </div>
                        </div>
                    </div>
                    <LineChart data={list}
                        refresh={shouldRefreshChart} />
                </div>
            </div>

            { isLoading && <Loader /> }
        </div>      
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity);