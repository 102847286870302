import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Timeline, Icon } from 'antd';
import { format } from 'date-fns';

const PaymentHistory = ({name, data}) => {    

    return (
        <div className={cx('payment-history')}>
            <h2 className={cx('text-center')} style={{marginBottom: '30px'}}>Payments - {name}</h2>
            <Timeline mode="alternate">
                {
                    data.list.map((d, i) => {
                        return <Timeline.Item color={d.actionType == 'add' ? 'green' : 'red'} key={i}>
                            <p>
                                {d.amount  + ' ' + (d.paymentType == 'points' ? 'points' : d.currencyCode)}
                                <span>  </span>
                                on {format(d.createdAt, 'Do MMM YYYY')}
                            </p>
                        </Timeline.Item>
                    })
                }
            </Timeline>
        </div>
    );
}

export default PaymentHistory;
