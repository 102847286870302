import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandAdminLogin, brandAdminPasswordForget } from '../../../actions';
import Loader from '../../UIElems/Loader';
import notification from '../../UIElems/Notification';
import qs from 'qs';
import Modal from '../../UIElems/Modal';

const Login = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [passwordForgotEmail, setPasswordForgotEmail] = useState();

    const forgotPasswordClicked = () => {
        setIsModalOpen(true);
    }

    const forgotPasswordModalClosed = () => {
        setIsModalOpen(false);
    }

    const onPasswordForgotModalOk = () => {
        if(validateEmail(passwordForgotEmail)) {
            onPasswordForgot(passwordForgotEmail);
        } else {
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Enter a proper email address!', type: 'error'});
        }        
    }

    const onPasswordForgot = email => {
        setIsLoading(true);
        props.actions.brandAdminPasswordForget(passwordForgotEmail)
        .then(res => {
            setIsLoading(false);
            if(!res.err){
                notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: "An email has been sent to the specified address.", type: 'success', placement: 'topLeft'});
            } else {
                notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: "Error occured while sending reset email", type: 'error', placement: 'topLeft'});
            }
        })
        .catch(err => {
            setIsLoading(false);
            notification.send({device: props.rdx.appReducer.deviceType, duration: 6, message: "Error occured while sending reset email", type: 'error', placement: 'topLeft'});
        });
    }

    const validateEmail = (email) => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }
 
    const onSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        props.actions.brandAdminLogin(email, password)
        .then(res => {
            setIsLoading(false);
            //redirection
            let queryParams = [];
            if(window.location.search) {
                queryParams = qs.parse(window.location.search.replace('?', ''));
            }
            if(!res.err)
                window.location = ('redirect' in queryParams) ? queryParams['redirect'] : '/brand/home';
            else
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Some error occurred!', type: 'error'});
        })
        .catch(() => alert('error!'));
    }

    return (
        <Fragment>
            <div class="split left">
                <div className={cx("brand-login")}>
                    <img src="https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/tap-logo-vip.svg" alt="Logo"/>
                    <form onSubmit={onSubmit}>
                        <div className={cx("form-group")}>
                            <label htmlFor="email">Email: </label>
                            <input id="email" type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>    
                        <div className={cx("form-group")}>
                            <label htmlFor="password">Password: </label>
                            <input id="password" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className={cx("form-group")}>
                            <a onClick={forgotPasswordClicked}>Forgot Password?</a>
                        </div>
                        <div className={cx("button-wrap")}>
                            <button className={cx("btn", "btn-blue")} type="submit">Login</button>
                        </div>
                    </form>
               </div>
            </div>

            <div class="split right">
                <div class="centered">
                </div>
            </div>

            <Modal openModal={isModalOpen} onClose={forgotPasswordModalClosed} onOk={onPasswordForgotModalOk}>
                <div className={cx("form-group")}>
                    <label htmlFor="forgot-password-email">Email: </label>
                    <input id="forgot-password-email" type="text" value={passwordForgotEmail} onChange={e => setPasswordForgotEmail(e.target.value)} size="40" />
                </div>
            </Modal>

                    { isLoading && <Loader /> }
        </Fragment>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandAdminLogin,
        brandAdminPasswordForget
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
