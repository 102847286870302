import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandCampaignFeedbackQuestionsUpdate } from '../../../../../actions';
import { useCampaignFeedbackQuestions } from '../../../../../hooks/generic';
import cx from 'classnames/bind';
import notification from '../../../../UIElems/Notification';
import { Button } from 'antd';

const FeedbackCreate = (props) => {

    let newQs = null;    

    const updateQuestions = () => {
        props.actions.brandCampaignFeedbackQuestionsUpdate(
            props.campaignId,
            newQs.filter(d => d.value.length)
        )
        .then(() => {
            notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
        })
    }         

    return (
        <div className={cx("create-feedback-question")}>
            <ExistingQuestions props={props} />
            
            <NewQuestions props={props} 
                assignUpdater={d => newQs = d}/>

            <div className={cx("button-align-left")}>
                <button className={cx("btn", "btn-blue")} type="button" onClick={updateQuestions}>Save Questions</button>                
            </div>
        </div>
    );
}

const ExistingQuestions = ({props}) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const existingQuestions = useCampaignFeedbackQuestions(props.dispatch, props.campaignId, false);

    return (
        <div className={cx("existing-questions")}>
            {
                existingQuestions.list.map((d, i) => {
                    return <div className={cx("form-group")} key={i}>
                        <input type="text" value={d.question} name={'old-' + d.id} readOnly size="90" />
                    </div>                        
                })
            }
        </div>
    );        
}

const NewQuestions = ({props, assignUpdater}) => {
    const [newQuestions, setNewQuestions] = useState([{value: '', id: ''}]);

    const onNewInputChange = (index, value) => {        
        let shallowArr = [...newQuestions];
        shallowArr[index].value = value;
        setNewQuestions(shallowArr);
    }

    const addMore = () => {
        //check
        if(newQuestions[newQuestions.length - 1].value == '')
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'question cant be empty!', type: 'error'});
        //else
        setNewQuestions([...newQuestions, {value: '', id: ''}]);
    }

    //assign updater -- closure bug fix
    useEffect(() => {
        assignUpdater(newQuestions);
    }, [newQuestions.length])

    return (
        <div className={cx("new-questions")}>
            {
                newQuestions.map((d, i) => {
                    return <div className={cx("feedback-add-new-row")} key={i}>
                        <input type="text" value={d.value} name={'new' + i} className="Test" onChange={e => onNewInputChange(i, e.target.value)} size="90" />
                        { (i == newQuestions.length - 1) && <Button className={cx("")} type="primary" shape="circle" size="default" icon="plus" onClick={addMore} />  }
                    </div>
                })                
            }            
        </div>            
    );
}



//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCampaignFeedbackQuestionsUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackCreate);
