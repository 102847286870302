import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';

const Approvals = ({links = [], defaultState='expand'}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);
    
    const defaultLinks = [
        {path: '/brand/users/all?tab=2&approvalFilter=2', title: 'Platform - Pending', ifActive: ((pathname == '/brand/users/all') ? 'active-item' : '')},
        {path: '/brand/campaigns/applications/pending', title: 'All Campaigns - Pending', ifActive: ((pathname == '/brand/campaigns/applications/pending') ? 'active-item' : '')},
        // {path: '/brand/campaigns/applications/all', title: 'Campaigns - all', ifActive: ((pathname == '/brand/campaigns/applications/all') ? 'active-item' : '')}        
    ];

    return (
        <SidebarTemplate heading="Approvals"
            links={[...defaultLinks, ...links]}
            defaultState={defaultState}
            iconClass="brand-approval-icon" />
    );
}

export default Approvals;
