import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import faq from '../../../config/faq';
import Accordion from '../../UIElems/Accordion';
import {Icon} from '../../Common/Icon';

const Faq = ({t, country, lang, howToUrl}) => {
    const faqList = faq[(['th', 'id', 'vn', 'my', 'ph'].indexOf(country) > -1 ? country : 'en')];

    return (
        <div className={cx("influencer-campaign-faq-wrapper")}>
            <div className={cx("mob-navigation")}>
                <ul className={cx("faq-back")}>
                    <li>
                        <a href={howToUrl}>
                            {t("mob_how_it_works")}
                        </a>
                    </li>
                </ul>
            </div>
            <div className={cx("faq-title", "text-center", "cd-padding-top40")}>
                <h1>{t("mob-how-faq")} <Icon name="mob-icon-faq"></Icon></h1>
            </div>
            <div>
                <Accordion elems={faqList}/>
            </div>
        </div>
    );
}

export default Faq;