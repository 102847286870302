import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../../actions';
import { useBrandInfluencerGradingHistory, useBrandInfluencerGradingAggregate } from '../../../../hooks/generic';
import { Typography, Tooltip, Modal, Icon } from 'antd';
import notification from '../../../../components/UIElems/Notification';
import GradingHistory from '../../../../components/Brand/Grading/History';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';

const { Text } = Typography;

const RatingHistory = (props) => {
    // let params = new URLSearchParams(props.location.search);
    // let campaignId = params.get('campaignId');
    const [loading, setLoading] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);

    const influencerList = useBrandInfluencerGradingHistory(
        () => setLoading(false),
        props.dispatch,
        props.match.params.influencerId,
        1,
        100,
        [],
        [],
        [],
        {},
        forceUpdate
    );

    const gradingAverageData = useBrandInfluencerGradingAggregate(
        () => {},
        props.dispatch,
        null,
        1,
        1,
        [],
        [],
        [],
        {
            influencerId: props.match.params.influencerId
        },        
        false
    );
    
    return (
        <BaseLayout leftCards={[]}>
            <div className={cx('grading-history-parent')}>
                <GradingHistory data={influencerList}
                    gradingAverageData={gradingAverageData}
                    name={influencerList.total ? influencerList['list'][0].fullname : ''} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(RatingHistory);