import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import FeedbackTable from './Table';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';

const Feedback = (props) => {

    return (
        <BaseLayout leftCards={[]}>
            <div className="all-admins">
                <h1>Feedback</h1>
                <FeedbackTable conditions={{}} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
