import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {} from '../../../actions';
import MobWelcomeComponent from '../../../components/Mob/Welcome';
import { translate } from 'react-i18next';
import notification from '../../../components/UIElems/Notification';
import CONFIG from '../../../config';
import { myIsSet } from '../../../helpers';

const Welcome = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);

    return (
        <MobWelcomeComponent t={props.t}
            // deviceType={props.rdx.appReducer.deviceType}
            welcomeFaqUrl={`../mob/welcome/faq?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
            welcomeFeedbackUrl={`../mob/welcome/feedback?influencerId=${myIsSet(props, 'rdx.webviewReducer.profile.influencerId') ? props.rdx.webviewReducer.profile.influencerId : 0}`}
        />
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Welcome));
