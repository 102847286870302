import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ApplicationsTable from '..//CampaignApplicationsTable';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';

const BrandCampaignsApplicationsAll = (props) => {
    return (
        <BaseLayout leftCards={[]}>
            <div className="all-applications">
                <h1>Applications</h1>
                <ApplicationsTable brandId={props.rdx.brandReducer.brandId}
                    campaignId={null}
                    conditions={[]}
                    extra={{defaultSort: 'approvalStatus'}}
                    includeCampaignName={true} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandCampaignsApplicationsAll);
