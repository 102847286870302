import React, { useEffect, useState } from "react";

import cx from "classnames/bind";
import { Typography, Tooltip, Icon } from 'antd';

import Question from "./Question";

const { Text } = Typography;

const ScreeningQuestions = (props) => {
    const { data = {} } = props;
    const [title, setTitle] = useState(data.screeningFormTitle || "");
    const [description, setDescription] = useState(data.screeningFormDesc || "");
    const [ isNew, setIsNew ] = useState(true);

    useEffect(() => {
        let check = true;
        if(title && title.length > 0) {
            check = false;
        } else if(description && description.length > 0) {
            check = false;
        } else if(data.questions && data.questions.length > 0) {
            check = false;
        }
        if (check !== isNew) {
            setIsNew(check);
        }
    }, []);

    const onQuestionTitleChanged = (event) => {
        setTitle(event.target.value);
        if (props.onQuestionTitleChanged) {
            props.onQuestionTitleChanged(event.target.value);
        }
    }

    const onQuestionDescChanged = (event) => {
        setDescription(event.target.value);
        if (props.onQuestionDescChanged) {
            props.onQuestionDescChanged(event.target.value);
        }
    }

    const onAddNewQuestionClicked = () => {
        if (props.onAddNewQuestionClicked) {
            props.onAddNewQuestionClicked();
        }
    }

    const startQuestionnaireClicked = () => {
        setIsNew(!isNew);
        if (props.onAddNewQuestionClicked) {
            props.onAddNewQuestionClicked();
        }
    }

    return (
        <div className={"questionnaire-wrap"}>
            <Text code>Part IV</Text>
            <div className={cx("form-vertical-row")}>
                <h2>Screening questions</h2>
            </div>
            {
                data.questions && data.questions.length > 0 &&
                <div className={cx("form-vertical-row")}>
                    <div className={"form-wrap head-wrap"}>
                        <input
                            placeholder="Untitled screening form"
                            type="text"
                            value={title}
                            onChange={(e) => onQuestionTitleChanged(e)}
                            className={"inp-title inp-head"}
                            required
                        />
                        <input
                            type="text"
                            value={description}
                            onChange={(e) => onQuestionDescChanged(e)}
                            // cols="10"
                            // rows="2"
                            placeholder="Screening Form Description"
                            className={"inp-desc inp-head"}
                        />
                    </div>

                    <div className={"question-section"}>
                        <div className={"question-list"}>
                            {
                                data.questions.map((question, id) => {
                                    if (question.status > 0) {
                                        return (
                                            <Question
                                                question={question}
                                                onQuestionTextChanged={(qText) => props.onQuestionTextChanged(id, qText)}
                                                onQuestionTypeChanged={(qType) => props.onQuestionTypeChanged(id, qType)}
                                                onAddNewOptionClicked={() => props.onAddNewOptionClicked(id)}
                                                onOptionTextChanged={(typeId, opText) => props.onOptionTextChanged(id, typeId, opText)}
                                                onDuplicateQuestionClicked={() => props.onDuplicateQuestionClicked(id)}
                                                onDeleteQuestionClicked={() => props.onDeleteQuestionClicked(id)}
                                                onIsRequiredSwitched={(state) => props.onIsRequiredSwitched(id, state)}
                                                onUpdateOptions={(options) => props.onUpdateOptions(id, options)}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className={"add-que"}>
                            <div onClick={onAddNewQuestionClicked}>
                                <img
                                    src={"https://tapfluencer.s3.ap-southeast-1.amazonaws.com/screening_add_que_light.png"}
                                    className={"add-que-ico"}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            }
            {
                isNew && <div className={"start-que"} onClick={startQuestionnaireClicked}>
                    <div>
                        <img
                            src={"https://tapfluencer.s3-ap-southeast-1.amazonaws.com/screening_add_blue.png"}
                            className={"start-que-ico"}
                        />
                    </div>
                    {"Start a new screening Questions"}
                </div>
            }
        </div>
    )

}

export default ScreeningQuestions;