import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parse } from 'date-fns';
// import "react-datepicker/dist/react-datepicker.css";
import FeedbackCreate from '../../../../containers/Brand/Campaign/Feedback/Create';
import notification from '../../../UIElems/Notification';
import enGB from "date-fns/locale/en";

const Preview = ({data, onCampaignPublish, campaignPublishBulkNotification}) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [submissionEndDate, setSubmissionEndDate] = useState(null);
    const [disableNotification, setDisableNotification] = useState(false);
    //set intitial date
    useEffect(() => {
        registerLocale("en", enGB);
        if(data.startDate) setStartDate(parse(data.startDate));
        if(data.endDate) setEndDate(parse(data.endDate));
        if(data.submissionEndDate) setSubmissionEndDate(parse(data.submissionEndDate));
    }, [])

    return (
        <Fragment>
            {/*<ul class="wizard">
                            <li class="wizard-step">
                                <a href="#1" class="wizard-step-link">
                                    Campaign Details
                                </a>
                            </li>
                            <li class="wizard-step">
                                <a href="#2" class="wizard-step-link">
                                    Select Influencers
                                </a>
                            </li>
                            <li class="wizard-step-active">
                                <a href="#3" class="wizard-step-link">
                                    Review & Publish
                                </a>
                            </li>
                        </ul>*/}
            <div className={cx("campaign-preview-container")}>
            <h2>Campaign Review</h2>
                <div className={cx("campaign-preview-wrap")}>
                    <div className={cx("campaign-review-wrap")}>
                        <div className="campaign-review">                            
                            <h2>{data.campaignName}</h2>
                            <div>
                                <img src={data.productImage} className={cx("logo-preview")} width="400" alt="product image"/>
                            </div>
                            {
                                !!data.hasDynamicUrl &&
                                <div className={cx("preview-content")}>
                                    <label>Campaign Url</label>
                                    <div>{data.utmUrl + `?utm_source=${data.utmSource}&utm_medium=%USER%&utm_campaign=${data.campaignId}`}</div>
                                </div>
                            }
                            {
                                data.payment &&
                                <div className={cx("preview-content")}>
                                    <label>Payment</label>
                                    <div>{data.payment}</div>
                                </div>
                            }
                            <div className={cx("preview-content")}>
                                <label>More about this campaign</label>
                                <div>{data.backgroundInformation}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Detailed Deliverables</label>
                                <div>{data.instructions}</div>
                            </div>
                            {data.slotsLimit > 0 && <div className={cx("preview-content")}>
                                <label>Number of slots</label>
                                <div>{data.slotsLimit}</div>
                            </div>}
                            <div className={cx("preview-content")}>
                                <label>Consider adding</label>
                                <div>{data.consideration}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Do Not</label>
                                <div>{data.guidelinesDont}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Tone And Voice</label>
                                <div>{data.toneAndVoice}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Terms And Conditions</label>
                                <div>{data.termsAndConditions}</div>
                            </div>
                            <div className={cx("preview-content")}>
                                <label>Distribution Rights And Copyright:</label>
                                <div>{data.distributionRightsAndCopyright}</div>
                            </div>                            
                            {/*
                                data.paymentTerms &&
                                <div className={cx("preview-content")}>
                                    <label>Payment Terms</label>
                                    <div>{data.paymentTerms}</div>
                                </div>
                            */}                            
                        </div>

                        <div className="form-horizontal-row-with-label">
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Start Date</label>
                                <DatePicker locale={"en"}
                                    selected={startDate}
                                    onChange={startDate => {
                                        setStartDate(startDate)
                                        if(!endDate){
                                            let submissionDateSuggestion = new Date(startDate);
                                            submissionDateSuggestion.setDate(submissionDateSuggestion.getDate() + 1);
                                            setSubmissionEndDate(submissionDateSuggestion);    
                                        }
                                    }}
                                    maxDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Start Date'
                                    popperPlacement="top-end" />
                            </div>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">End Date</label>
                                <DatePicker locale={"en"}
                                    selected={endDate}
                                    onChange={endDate => { 
                                        setEndDate(endDate)
                                        let submissionDateSuggestion = new Date(endDate);
                                        submissionDateSuggestion.setDate(submissionDateSuggestion.getDate() + 7);
                                        setSubmissionEndDate(submissionDateSuggestion);
                                    }}
                                    minDate={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='End Date'
                                    popperPlacement="top-end" />
                            </div>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Submission End Date</label>
                                <DatePicker locale={"en"}
                                    selected={submissionEndDate}
                                    onChange={submissionEndDate => setSubmissionEndDate(submissionEndDate)}
                                    minDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Submission End Date'
                                    popperPlacement="top-end" />
                            </div>
                            <br/>
                        </div>
                    </div>

                    <div className="client-details">
                        <h2>Client Details</h2>
                        <div>
                            <h3 className={cx("")}>{data.brandName}</h3>
                        </div>
                        <div>
                            <img src={data.logo} alt="logo" className={cx("logo-preview")} width="120"/>
                        </div>
                        <div className={cx("clients-social-icon-wrap")}>
                            <div>
                                <span className={cx("icon-fb", "social-icon-style")}></span>
                                <a href={data.fbLink} target="blank">Facebook</a>
                            </div>
                            <div>
                                <span className={cx("icon-twitter", "social-icon-style")}></span>
                                <a href={data.twitterLink} target="blank">Twitter</a>
                            </div>
                            <div>
                                <span className={cx("icon-insta", "social-icon-style")}></span>
                                <a href={data.instaLink} target="blank">Instagram</a>
                            </div>
                            <div>
                                <span className={cx("icon-youtube", "social-icon-style")}></span>
                                <a href={data.youtubeLink} target="blank">Youtube</a>
                            </div>                        
                        </div>
                    </div>
                </div>

                <hr/>

                <FeedbackCreate campaignId={data.campaignId} />

                <hr/>
                
                <div className={cx('button-wrap-left')}>
                    <h2>Notifications</h2>
                    <button type="button" disabled={disableNotification} className={cx("btn", "btn-blue")} onClick={() => {setDisableNotification(true); campaignPublishBulkNotification()}}>
                        Notify users
                    </button>{" "} 
                    {data.notification && <label>Last notification sent: {JSON.parse(data.notification).last_triggered}, notification # {JSON.parse(data.notification).counter}</label>}
                </div>

                <div className={cx("button-wrap")}>                    
                    <button className={cx("btn", "btn-blue")} type="button" onClick={e => onCampaignPublish(startDate, endDate, submissionEndDate, data.campaignId)}>
                        Save
                    </button>
                    <button className={cx("btn", "btn-light")}>Cancel</button>
                </div>

            </div>

        </Fragment>
    );
}

export default Preview;