import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import EmailTrackerTable from './Table';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import { genericAction } from '../../../../actions';
import { Typography, Spin } from 'antd';
import notification from '../../../../components/UIElems/Notification';

const { Text } = Typography;

const EmailTracker = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    //campaign basic details
    useEffect(() => {
        props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName'], props.match.params.id])
        .then(res => setCampaignDetails(res.data))
    }, []);

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Email Tracker'/>
            ]}>
            <div className="email-tracker">
                <h1>Email Tracker - {campaignDetails ? campaignDetails.campaignName : ''}</h1>

                <Text mark>
                    These filters will only work for campaigns created after 9th Dec, 2019. If you want to check the mails sent for campaigns before this date, click here - 
                    <a href={`/brand/campaign/${props.match.params.id}/email/tracker/legacy/opened`} target="_blank"><Text type="secondary" underline={true}> legacy link</Text></a>
                </Text>

                <EmailTrackerTable campaignId={props.match.params.id}
                    conditions={{}} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailTracker);