import React from "react";

const TermsAndConditions = (props) => {
    return (
        <div className={"tnc-main-container"}>
            <div className={"tnc-header"}>
                {
                    props.t("campaign_terms_and_conditions")
                }
            </div>
            {
                props.data.terms_and_conditions
            }
        </div>
    )
}

export default TermsAndConditions;