import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import ReactTable from "react-table";
import 'react-table/react-table.css';

const Table = (props) => {
    return (
        <div>
            <ReactTable
                {...props}
                manual
                className="-striped -highlight" />
        </div>
    );
}

export default Table;