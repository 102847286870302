import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandCampaignEmailTrackerLegacyOpened } from '../../../../../../../hooks/generic';
import {} from '../../../../../../../actions';
import Table from '../../../../../../../components/Common/Table';
import MenuPopper from '../../../../../../../components/UIElems/MenuPopperAntd';

const EmailsOpenedTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [conditions, setConditions] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);

    const emailTrackerList = useBrandCampaignEmailTrackerLegacyOpened(
        () => setLoading(false),
        props.dispatch,
        props.campaignId,
        page,
        pageSize,
        sorted,
        filtered,
        {...conditions, type: 'newCampaignPublished'},
        forceUpdate
    );
    
    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        // if(state.filtered.length && state.filtered[0]['value'].length < 2) return false;
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const columns = [
        {
            Header: "User Id",
            accessor: "userId",
            filterable: false,
            sortable: false,
            Footer: "Total: " + emailTrackerList.total
        },
        {
            Header: "Name",
            accessor: "fullname",
            filterable: false,
            sortable: false
        },
        {
            Header: "Email",
            accessor: "email",
            filterable: false,
            sortable: false
        },
        /*{
            Header: "Type",
            accessor: "type",
            filterable: false,
            sortable: false
        },*/
        {
            Header: "Status",
            accessor: "count",
            filterable: false,
            sortable: false,
            Cell: props => <span>{props.value ? 'Opened' : ''}</span>
        }

    ];

    return (
        <div>
            <Table
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultPageSize={10}
                columns={columns}
                manual
                data={emailTrackerList.list}
                pages={Math.ceil(emailTrackerList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({

    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailsOpenedTable);