import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const styles = {
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    }
};

const AvatarElem = (props) => {
    const { classes } = props;
    return (
        <Grid container justify="center" alignItems="center">
            <Avatar alt="avatar" src={props.image} className={classes.avatar} />
        </Grid>
    );
}

AvatarElem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AvatarElem);