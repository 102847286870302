import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useBrandInfluencerGradingHistory } from '../../../../hooks/generic';
import {} from '../../../../actions';
import Table from '../../../../components/Common/Table';
import MenuPopper from '../../../../components/UIElems/MenuPopperAntd';
import GradeAdjust from '../Rate';
import GradeComment from '../Comment';
import { Popover, Icon } from 'antd';
import { format } from 'date-fns';

const GradingTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [extra, setExtra] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);

    const influencerList = useBrandInfluencerGradingHistory(
        () => setLoading(false),
        props.dispatch,
        undefined,
        page,
        pageSize,
        sorted,
        filtered,
        [
            ...props.conditions
        ],
        extra,        
        forceUpdate,
        props.campaignId
    );
    
    const profileLink = (influencerId) => {
        let link = `/brand/user/${influencerId}/grading/history`;
        return link;
    }

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        //add payment
        {/*let paymentElem = 
            <PaymentAdjust title={'Payment - ' + rowData.fullname}
                influencerId={rowData.influencerId}
                campaignId={props.campaignId}
                callback={() => setForceUpdate(!forceUpdate)} />;
        links.push({title: 'Adjust Payment', extraElem: paymentElem});*/}
        //payment history
        links.push({title: 'Rating History', handler: () => window.open(profileLink(rowData.influencerId), '_blank'), icon: <Icon type="history" />});       

        return <MenuPopper title='Manage' links={links} />
    }

    const columns = [
        {
            Header: "ID",
            accessor: "influencerId",
            sortable: false,
            filterable: false,
            Footer: "Total: " + influencerList.total
        },
        {
            id: "fullname",
            Header: "Name",
            accessor: "",
            sortable: false,
            filterable: true,
            Cell: props => <a target="_blank" href={profileLink(props.value.influencerId)}>{props.value.fullname}</a>
        },
        {
            Header: "Email",
            accessor: "email",
            sortable: false,
            filterable: true
        },
        {
            Header: "Primary Rating",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <GradeAdjust value={props.value.primaryRating}
                    campaignId={props.value.campaignId}
                    influencerId={props.value.influencerId}
                    type="primary_rating"
                    comment={props.value.comment} />
        },
        {
            Header: "Correctness",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <GradeAdjust value={props.value.correctness}
                    campaignId={props.value.campaignId}
                    influencerId={props.value.influencerId}
                    type="correctness"
                    comment={props.value.comment} />
        },
        {
            Header: "Creativity",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <GradeAdjust value={props.value.creativity}
                    campaignId={props.value.campaignId}
                    influencerId={props.value.influencerId}
                    type="creativity"
                    comment={props.value.comment} />
        },
        {
            Header: "Punctuality",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <GradeAdjust value={props.value.punctuality}
                    campaignId={props.value.campaignId}
                    influencerId={props.value.influencerId}
                    type="punctuality"
                    comment={props.value.comment} />
        },
        ...(
            props.campaignId
            ?
                [
                    {
                        Header: "Updated On",
                        accessor: "updatedAt",
                        sortable: false,
                        filterable: false,
                        Cell: props => props.value ? format(props.value, 'DD-MM-YYYY') : ''
                    },
                    {
                        Header: "Graded By",
                        accessor: "gradedByName",
                        sortable: false,
                        filterable: false
                    },
                    {
                        Header: "Comment",
                        accessor: "",
                        sortable: false,
                        filterable: false,
                        Cell: props =>
                            <GradeComment comment={props.value.comment}
                                campaignId={props.value.campaignId}
                                influencerId={props.value.influencerId} />
                    }
                ]
            : 
                []
        ),
        {
            Header: "Manage",
            accessor: "",
            sortable: false,
            filterable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div className="payment-filters">
                {/*<div className="row1 row-wrapper">
                    <span>
                        <label htmlFor="payment-type">Payment Type: </label>
                        <select id="payment-type" value={paymentTypeFilter} onChange={e => setPaymentTypeFilter(e.target.value)}>
                            <option value="">all</option>
                            <option value="monetary">Monetary</option>
                            <option value="points">Points</option>
                        </select>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                </div>*/}

                <span>&nbsp;</span>

                {/*<div className="row4">
                    <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceUpdate(!forceUpdate)}>Fetch</button>
                </div>*/}
            </div>

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={influencerList.list}
                pages={Math.ceil(influencerList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(GradingTable);