import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { 
    influencerRegisterProfileDefaultsAction,
    influencerRegisterKidsDefaultsAction,
    influencerRegisterProfileSave,
    influencerSocialAccounts,
    influencerSocialAccountDelete,
    brandInfluencerApprove,
    brandInfluencerDelete
} from '../../../../actions';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import ProfileComponent from '../../../../components/Brand/User/Profile';
import notification from '../../../../components/UIElems/Notification';
import Loader from '../../../../components/UIElems/Loader';
import { useApiJobAppliedList } from '../../../../hooks/generic';

const Profile = (props) => {
    const [profile, setProfile] = useState(null);
    const [kids, setKids] = useState([]);
    const [socialProfiles, setSocialProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [jobType, setJobType] = useState('vip_all');

    const params = new URLSearchParams(props.location.search);
    const tabIndex = params.get('tab');

    const jobList = useApiJobAppliedList(
        () => setIsLoading(false),
        props.match.params.id,
        jobType,
        page,
        pageSize,
        [],
        filtered,
        [],
        null,
        forceRefresh,
        true
    );
    //
    useEffect(() => {
        //profile
        props.actions.influencerRegisterProfileDefaultsAction(props.match.params.id)
        .then(results => setProfile(results.data));
        //kids
        props.actions.influencerRegisterKidsDefaultsAction(props.match.params.id)
        .then(results => setKids(results.data));
        //social profiles
        props.actions.influencerSocialAccounts(props.match.params.id)
        .then(results => setSocialProfiles(results.data));
    }, [])

    const onInfluencerApprove = (influencerId, flag) => {
        if(window.confirm('Are you sure?')) {
            props.actions.brandInfluencerApprove(influencerId, flag)
            .then(() => {
                props.actions.influencerRegisterProfileDefaultsAction(props.match.params.id)
                .then(results => setProfile(results.data));
            });
        }        
    }

    const onSocialAccountDelete = (influencerId, type) => {
        if(window.confirm('Are you sure?')) {
            props.actions.influencerSocialAccountDelete(influencerId, type)
            .then(res => {
                window.location.reload(true);
            })
        }        
    }

    const onInfluencerDelete = (influencerId) => {
        if(window.confirm('Are you sure? This will delete the main account!')) {
            props.actions.brandInfluencerDelete(influencerId)
            .then(res => {
                window.location = '/brand/users/all';
            })
        }   
    }

    const onInfluencerEdit = (data) => {
        if(window.confirm('Update?')) {
            setIsLoading(true);
            props.actions.influencerRegisterProfileSave(data)
            .then(() => {
                setIsLoading(false);
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Updated!', type: 'success'});
                window.location.reload(true);
            })
        }        
    }

    const onFetchData = (state, instance) => {
        const jobTypeFilter = state.filtered.find(fil => fil.id === 'jobType');
        if (jobTypeFilter) {
            setJobType(jobTypeFilter.value);
        }
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setIsLoading(true);
        setForceRefresh(!forceRefresh);
    }

    return (
        <BaseLayout leftCards={[]}>
            <div>
                <ProfileComponent profile={profile}
                    socialProfiles={socialProfiles}
                    kids={kids}
                    jobList={jobList}
                    loading={isLoading}
                    pageSize={pageSize}
                    tabIndex={tabIndex}
                    onInfluencerApprove={onInfluencerApprove}
                    onSocialAccountDelete={onSocialAccountDelete}
                    onInfluencerDelete={onInfluencerDelete}
                    onInfluencerEdit={onInfluencerEdit}
                    onFetchData={onFetchData} />
            </div>

            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerRegisterProfileDefaultsAction,
        influencerRegisterKidsDefaultsAction,
        influencerRegisterProfileSave,
        influencerSocialAccounts,
        influencerSocialAccountDelete,
        brandInfluencerApprove,
        brandInfluencerDelete
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
