import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useStatsPanelEmail } from '../../../../../hooks/stats';
import Loader from '../../../../../components/UIElems/Loader';
import { PieChart } from '../../../../../components/UIElems/Charts';

const Email = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shouldRefreshChart, setShouldRefreshChart] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);

    const data = useStatsPanelEmail(
        () => {
            setIsLoading(false);
            setTimeout(() => setShouldRefreshChart(!shouldRefreshChart), 1000);
        },
        props.dispatch,
        props.panelId,
        forceUpdate
    );

    return (
        <div>

            <div className={cx("stats-cards-white")} onClick={e => window.open(`/brand/panel/${props.panelId}/email/tracker`, '_blank')} style={{ cursor: 'pointer' }}>
                <div className={cx("ant-card", "stats-cards")}>
                    <div className={cx("ant-card-head")}>
                        <div className={cx("ant-card-head-wrapper")}>
                            <div className={cx("ant-card-head-title")}>
                                Email Stats
                            </div>
                        </div>
                    </div>
                    <div className="no-data-found">Total Sent: {data ? data.total : 0}</div>
                    <PieChart data={data ? [data] : null}
                        refresh={shouldRefreshChart}
                        width={400}
                        height={250} />
                </div>
            </div>

            { isLoading && <Loader />}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({ rdx: state });

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Email);