export const genderOptions = [
    {
        label: 'Male',
        value: 'male',
        selected: false
    },
    {
        label: 'Female',
        value: 'female',
        selected: false
    },
    {
        label: 'Other',
        value: 'other',
        selected: false
    }
];

export const ethnicityOptions = [
    {
        label: 'Malay',
        value: 'malay',
        selected: false
    },
    {
        label: 'Chinese',
        value: 'chinese',
        selected: false
    },
    {
        label: 'Indian',
        value: 'indian',
        selected: false
    },
    {
        label: 'Other',
        value: 'other',
        selected: false
    }
];

export const maritalOptions = [
    {
        label: 'Single',
        value: 'single',
        selected: false
    },
    {
        label: 'Married',
        value: 'married',
        selected: false
    },
    {
        label: 'In Relationship',
        value: 'in_relationship',
        selected: false
    },
    {
        label: 'Divorced',
        value: 'divorced',
        selected: false
    },
    {
        label: 'Widowed',
        value: 'widowed',
        selected: false
    }
];

export const educationOptions = [
    {
        label: 'Graduation',
        value: 'graduation',
        selected: false
    },
    {
        label: 'Post Graduation',
        value: 'post_graduation',
        selected: false
    },
    {
        label: 'Doctorate',
        value: 'doctorate',
        selected: false
    },
    {
        label: 'High School',
        value: 'high_school',
        selected: false
    },
    {
        label: 'Drop Out',
        value: 'drop_out',
        selected: false
    }
    /*
    {
        label: 'Graduate/University Degree',
        value: 'graduate',
        selected: false
    },
    {
        label: 'Post Graduation',
        value: 'post_graduate',
        selected: false
    },
    {
        label: 'Doctorate',
        value: 'doctorate',
        selected: false
    },
    {
        label: 'Technical School/Vocational School',
        value: 'vocational',
        selected: false
    },
    {
        label: 'High School(Grade 11-12)',
        value: 'high_school',
        selected: false
    },
    {
        label: 'Secondary School(Grade 7-10)',
        value: 'secondary_school',
        selected: false
    },
    {
        label: 'Primary School(Grade 1-6)',
        value: 'primary_school',
        selected: false
    },
    {
        label: 'No Formal Schooling',
        value: 'no_schooling',
        selected: false
    }
    */
];

export const occupationOptions = [
    {
        label: 'Business Owner/Self Employed (With 10+ employees)',
        value: 'business_owner_or_self_employed_10_plus',
        selected: false
    },
    {
        label: 'Business Owner/Self Employed (With 1-9 employees)',
        value: 'business_owner_or_self_employed_1_9',
        selected: false
    },
    {
        label: 'Professional/Senior/Mid level executive',
        value: 'professional',
        selected: false
    },
    {
        label: 'Business Owner(With no employees)',
        value: 'business_owner_self',
        selected: false
    },
    {
        label: 'Semi Professional/Junior Executive',
        value: 'semi_professional',
        selected: false
    },
    {
        label: 'Clerical/Administrative Job',
        value: 'clerical',
        selected: false
    },
    {
        label: 'Skilled Worker(Eg. Law enforcement officers, Financial technicians, nurses, sales representatives, electricians)',
        value: 'skilled_worker',
        selected: false
    },
    {
        label: 'Semi-Skilled Worker(Truck Drivers, Reatil salespersons, bartenders, flight attendants, taxi drivers, waiters and security guards)',
        value: 'semi_skilled_worker',
        selected: false
    },
    {
        label: 'Unskilled Worker',
        value: 'unskilled_worker',
        selected: false
    },
    {
        label: 'Unemployed/Housewife',
        value: 'unemployed_hosewife',
        selected: false
    }
];

export const wageEarnerOptions = [
    {
        label: 'Husband/Male Partner',
        value: 'husband_male_partner',
        selected: false
    },
    {
        label: 'Wife/Female Partner',
        value: 'wife_male_partner',
        selected: false
    },
    {
        label: 'Equal Contribution',
        value: 'equal_contribution',
        selected: false
    }
];

export const spouseEducationOptions = educationOptions.map(opt => ({...opt}));

export const spouseOccupationOptions = occupationOptions.map(opt => ({...opt}));

export const incomeOptions = {
    192: [
        {
            label: 'Less than $2000',
            value: 'SGD-0_2000',
            selected: false
        },
        {
            label: '$2001-$4000',
            value: 'SGD-2001_4000',
            selected: false
        },
        {
            label: '$4001-$6000',
            value: 'SGD-4001_6000',
            selected: false
        },
        {
            label: '$6001-$8000',
            value: 'SGD-6001_8000',
            selected: false
        },
        {
            label: '$8001-$11000',
            value: 'SGD-8001_11000',
            selected: false
        },
        {
            label: '$11001-$15000',
            value: 'SGD-11001_15000',
            selected: false
        },
        {
            label: 'More than $15000',
            value: 'SGD-15000_+',
            selected: false
        }
    ],
    129: [
        {
            label: 'Less than RM2500',
            value: 'MYR-0_2500',
            selected: false
        },
        {
            label: 'RM2500-RM2999',
            value: 'MYR-2500_2999',
            selected: false
        },
        {
            label: 'RM3000-RM3999',
            value: 'MYR-3000_3999',
            selected: false
        },
        {
            label: 'RM4000-RM4999',
            value: 'MYR-4000_4999',
            selected: false
        },
        {
            label: 'RM5000-RM5999',
            value: 'MYR-5000_5999',
            selected: false
        },
        {
            label: 'RM6000-RM6999',
            value: 'MYR-6000_6999',
            selected: false
        },
        {
            label: 'RM7000-RM7999',
            value: 'MYR-7000_7999',
            selected: false
        },
        {
            label: 'RM8000-RM8999',
            value: 'MYR-8000_8999',
            selected: false
        },
        {
            label: 'RM9000-RM9999',
            value: 'MYR-9000_9999',
            selected: false
        },
        {
            label: 'RM10000-RM11999',
            value: 'MYR-10000_11999',
            selected: false
        },
        {
            label: 'RM12000-RM13999',
            value: 'MYR-12000_13999',
            selected: false
        },
        {
            label: 'RM14000-RM14999',
            value: 'MYR-14000_14999',
            selected: false
        },
        {
            label: 'More than RM15000',
            value: 'MYR-15000_+',
            selected: false
        }
    ],
    100: [
        {
            label: 'Less than Rp600000 or Rp600000',
            value: 'IDR-0_600000',
            selected: false
        },
        {
            label: 'Rp600001-Rp3000000',
            value: 'IDR-600001_3000000',
            selected: false
        },
        {
            label: 'Rp3000001-Rp5000000',
            value: 'IDR-3000001_5000000',
            selected: false
        },
        {
            label: 'Rp5000001-Rp7000000',
            value: 'IDR-5000001_7000000',
            selected: false
        },
        {
            label: 'Rp7000001-Rp10000000',
            value: 'IDR-7000001_10000000',
            selected: false
        },
        {
            label: 'Rp10000001-Rp16000000',
            value: 'IDR-10000001_16000000',
            selected: false
        },
        {
            label: 'More than Rp160000000',
            value: 'IDR-16000000_+',
            selected: false
        }
    ],
    211: [
        {
            label: 'Below 20000 Baht',
            value: 'THB-0_20000',
            selected: false
        },
        {
            label: '20000-29999 Baht',
            value: 'THB-20000_29999',
            selected: false
        },
        {
            label: '30000-49999 Baht',
            value: 'THB-30000_49999',
            selected: false
        },
        {
            label: '40000-59999 Baht',
            value: 'THB-40000_59999',
            selected: false
        },
        {
            label: '60000 Baht & above',
            value: 'THB-60000_+',
            selected: false
        }
    ],
    169: [
        {
            label: 'PHP 1600 or less',
            value: 'PHP-0_1600',
            selected: false
        },
        {
            label: 'PHP 1601-4100',
            value: 'PHP-1601_4100',
            selected: false
        },
        {
            label: 'PHP 4101-8300',
            value: 'PHP-4101_8300',
            selected: false
        },
        {
            label: 'PHP 8301-13300',
            value: 'PHP-8301_13300',
            selected: false
        },
        {
            label: 'PHP 13301-18300',
            value: 'PHP-13301_18300',
            selected: false
        },
        {
            label: 'PHP 18301-40000',
            value: 'PHP-18301_40000',
            selected: false
        },
        {
            label: 'PHP 40001-83000',
            value: 'PHP-40001_83000',
            selected: false
        },
        {
            label: 'PHP 83001-140000',
            value: 'PHP-83001_140000',
            selected: false
        },
        {
            label: 'PHP 140001-230000',
            value: 'PHP-140001_230000',
            selected: false
        },
        {
            label: 'PHP 230000 or More',
            value: 'PHP-230000_+',
            selected: false
        }
    ],
    232: [
        {
            label: 'VND 1660000 or less',
            value: 'VND-0_1660000',
            selected: false
        },
        {
            label: 'VND 1660001-7500000',
            value: 'VND-1660001_7500000',
            selected: false
        },
        {
            label: 'VND 75000001-12500000',
            value: 'VND-75000001_12500000',
            selected: false
        },
        {
            label: 'VND 12500001-16600000',
            value: 'VND-12500001_16600000',
            selected: false
        },
        {
            label: 'VND 16600001-20800000',
            value: 'VND-16600001_20800000',
            selected: false
        },
        {
            label: 'VND 20800001-33300000',
            value: 'VND-20800001_33300000',
            selected: false
        },
        {
            label: 'VND 33300001 or More',
            value: 'VND-33300001_+',
            selected: false
        }
    ]
}

export const numKidsOptions = [
    {
        label: '0',
        value: '0',
        selected: false
    },
    {
        label: '1',
        value: '1',
        selected: false
    },
    {
        label: '2',
        value: '2',
        selected: false
    },
    {
        label: '3',
        value: '3',
        selected: false
    },
    {
        label: '4',
        value: '4',
        selected: false
    },
    {
        label: '5 or more',
        value: '5+',
        selected: false
    }
];

export const languageOptions = {
    common: [
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    192: [
        {
            label: 'Chinese',
            value: 'chinese',
            selected: false
        },
        {
            label: 'Bahasa Malayu',
            value: 'bahasa_malayu',
            selected: false
        },
        {
            label: 'Tamil/Hindi',
            value: 'tamil',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    129: [
        {
            label: 'Chinese',
            value: 'chinese',
            selected: false
        },
        {
            label: 'Bahasa Malayu',
            value: 'bahasa_malayu',
            selected: false
        },
        {
            label: 'Tamil/Hindi',
            value: 'tamil',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    100: [
        {
            label: 'Bahasa Indonesia',
            value: 'bahasa_indonesia',
            selected: false
        },
        {
            label: 'Javanese',
            value: 'javanese',
            selected: false
        },
        {
            label: 'Sudanese',
            value: 'sudanese',
            selected: false
        },
        {
            label: 'Malay',
            value: 'malay',
            selected: false
        },
        {
            label: 'Batak',
            value: 'batak',
            selected: false
        },
        {
            label: 'Madurese',
            value: 'madurese',
            selected: false
        },
        {
            label: 'Minangkabau',
            value: 'minangkabau',
            selected: false
        },
        {
            label: 'Betawi',
            value: 'betawi',
            selected: false
        },
        {
            label: 'Buginese',
            value: 'buginese',
            selected: false
        },
        {
            label: 'Aceh',
            value: 'aceh',
            selected: false
        },
        {
            label: 'Balinese',
            value: 'balinese',
            selected: false
        },
        {
            label: 'Makassar',
            value: 'makassar',
            selected: false
        },
        {
            label: 'Sasak',
            value: 'sasak',
            selected: false
        },
        {
            label: 'Lampung',
            value: 'lampung',
            selected: false
        },
        {
            label: 'Gorontalo',
            value: 'Gorontalo',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    211: [
        {
            label: 'Thai',
            value: 'thai',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    169: [
        {
            label: 'Tagalog',
            value: 'tagalog',
            selected: false
        },
        {
            label: 'Cebuano',
            value: 'cebuano',
            selected: false
        },
        {
            label: 'Ilocanp',
            value: 'ilocano',
            selected: false
        },
        {
            label: 'Hiligaynon',
            value: 'hiligaynon',
            selected: false
        },
        {
            label: 'Chinese',
            value: 'chinese',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ],
    232: [
        {
            label: 'Vietnamese',
            value: 'Vietnamese',
            selected: false
        },
        {
            label: 'Chinese',
            value: 'chinese',
            selected: false
        },
        {
            label: 'Mon-Khmer',
            value: 'mon_khmer',
            selected: false
        },
        {
            label: 'English',
            value: 'english',
            selected: false
        },
        {
            label: 'Other',
            value: 'other',
            selected: false
        }
    ]
}

export const pregnancyStatusOptions = [
    {
        label: 'Not Pregnant',
        value: 'not_pregnant',
        selected: false
    },
    {
        label: 'Pregnant With 1st Trimester',
        value: 'pregnant_first_trimester',
        selected: false
    },
    {
        label: 'Pregnant With 2nd Trimester',
        value: 'pregnant_second_trimester',
        selected: false
    },
    {
        label: 'Pregnant With 3rd Trimester',
        value: 'pregnant_third_trimester',
        selected: false
    }
]

export default {
    genderOptions,
    ethnicityOptions,
    maritalOptions,
    educationOptions,
    occupationOptions,
    wageEarnerOptions,
    spouseEducationOptions,
    spouseOccupationOptions,
    incomeOptions,
    numKidsOptions,
    languageOptions,
    pregnancyStatusOptions
}