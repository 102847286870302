import React, { useState } from "react";
import { Icon } from "../Icon";
import Tabs from "../../UIElems/Tabs";

const FaqAccordion = ({id, question, answer, active, setActive}) => {

    return (
        <div className={(active === id ? "accordion-shadow" : "") + " accordion"}>
            <div className="accordionHeading" onClick={() => setActive(id)}>
                <div className="container">
                    <p>{question}</p>
                    <span>
                        {active === id ? <Icon className="icon-closed" name="icon-down-chevron" /> : <Icon className="icon-open" name="icon-down-chevron" />}
                    </span>
                </div>
            </div>

            <div className={(active === id ? "show" : "") + " accordionContent"}>
                <div className="container">
                    <p>{answer}</p>
                </div>
            </div>

        </div>
    );

}


const TabInfluencer = ({t}) => {

    const [active, setActive] = useState();

    const setActiveTab = (id) => { 
        if(id === active) {
            setActive(-1);
        } else {
            setActive(id)
        }
    }

    return (
       <div className="faq-wrapper">
            {
                Array.from(Array(9), (e, i) => {
                   return <FaqAccordion 
                        id={i + 1}
                        key={i} 
                        question={t("welcome_faq_influencer_Q"+i)} 
                        answer={t("welcome_faq_influencer_A"+i)} 
                        active={active}
                        setActive={setActiveTab}
                    /> 
                })
            }
           
       </div>
    );
}

const TabPanel = ({t}) => {
    
    const [active, setActive] = useState();

    const setActiveTab = (id) => { 
        if(id === active) {
            setActive(-1);
        } else {
            setActive(id)
        }
    }

    return (
       <div className="faq-wrapper">
            {
                Array.from(Array(8), (e, i) => {
                   return <FaqAccordion 
                        id={i + 1}
                        key={i} 
                        question={t("welcome_faq_panel_Q"+i)} 
                        answer={t("welcome_faq_panel_A"+i)} 
                        active={active}
                        setActive={setActiveTab}
                    /> 
                })
            }
           
       </div>
    );
}

const TabbedFAQ = ({ t, activeKey = 0, animated = {}}) => {
    const onTabChange = (activeKey) => {}

    return (
        <div className={"page-spacing custom-mob-tabs"}>
            <Tabs tabsHandler={onTabChange}
                defaultActiveKey={activeKey}
                animated={animated}
                elems={[
                    {
                        name: t("mob_welcome_faq_influencer"),
                        elem: (
                            <TabInfluencer t={t} />
                        )
                    },
                    {
                        name: t("mob_welcome_faq_panel"),
                        elem: (
                            <TabPanel t={t} />
                        )
                    }
                ]} />
        </div>
    )
}

export default TabbedFAQ;