import React, { useEffect, useRef, useState } from 'react';
import cx from '../../../styles';

const MultiSelect = ({options = [], onChange = null, index = 0, optionsCleared = null, refreshDropdown = null}) => {
    const [dropdownOptions, setDropdownOptions] = useState(options);
    const [isVisible, setVisible] = useState(false);
    const [displayString, setDisplayString] = useState(loadDisplayString());
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);

    useEffect(() => {
        if (isVisible) {
            menuRef.current.focus();
        }
    }, [isVisible]);

    useEffect(() => {
        if(optionsCleared) {
            clearOptions();
        }
    }, [optionsCleared]);

    useEffect(() => {
        setDisplayString(loadDisplayString());
        setDropdownOptions([...options]);
    }, [refreshDropdown])

    const toggleDropdown = () => {
        setVisible(visible => !visible);
    }

    const closeDropdown = e => {
        if (!dropdownRef.current.contains(e.relatedTarget)) {
            setVisible(false);
        }
    }

    const dropdownValueChanged = index => {
        let display = '';
        const selectedOptions = [];
        const newOptions = dropdownOptions.map((opt, i) => {
            if (i === index) {
                opt.selected = !opt.selected;
            }
            if (opt.selected) {
                if (display === '') {
                    display = opt.label;
                }
                selectedOptions.push(opt.value);
            }
            return opt;
        });
        if (selectedOptions.length > 1) {
            display += `+${selectedOptions.length - 1}`;
        }
        onChange(selectedOptions);
        setDropdownOptions(newOptions);
        setDisplayString(display);
    }

    const clearOptions = () => {
        const newOptions = [...dropdownOptions];
        newOptions.forEach(opt => opt.selected = false);
        setDropdownOptions(newOptions);
        setDisplayString('');
    }

    function loadDisplayString() {
        let display = ``;
        let counter = 0;
        options.forEach(opt => {
            if (opt.selected) {
                if (display === '') {
                    display = opt.label;
                }
                counter++;
            }
        });
        if (counter > 1) {
            display += `+${counter - 1}`;
        }
        return display;
    }

    return (
        <div className={cx("multi-select-dropdown")} ref={dropdownRef} onBlur={closeDropdown}>
            <div className="dropdown-btn" onMouseDown={toggleDropdown}>{displayString}</div>
            <div className={`dropdown-content ${isVisible? "show": ""}`} ref={menuRef} tabIndex={index} onBlur={closeDropdown}>
                {dropdownOptions.map((option, i) => (
                    <span key={i} className={cx("dropdown-option")}>
                        <label>
                            <input type="checkbox" value={option.value} checked={option.selected} onChange={e => dropdownValueChanged(i)} />
                            &nbsp;
                            {option.label}
                        </label>
                    </span>
                ))}
            </div>
        </div>
    );
}

export default MultiSelect;