function IS() {
    return {
        list: [],
        page: 1,
        total: 0,
        currentFilter: null,
        mobPanelWebviewData:null
    }    
}

const INITIAL_STATE = IS();

export var panelReducer = (state = INITIAL_STATE, action) => {
    if(!action) return IS();

    switch (action.type) {    
        case 'COMPLETE_PANEL_SAVE':
            return {
                ...state,
                mobPanelWebviewData: action.payload.data,
                isLoading: false
            }
        break;    
        case 'COMPLETE_PANEL_LIST_FETCH':
            return {
                ...state,
                page: action.payload.page,
                list: [...(action.payload.shouldReset ? action.payload.data.list : [...state.list, ...action.payload.data.list])],
                total: action.payload.data.total
            };
        break;
        case 'COMPLETE_BRAND_PANEL_FILTER_FETCH':
            return {
                ...state,
                currentFilter: action.payload.data
            }               
        default:
            return state;
    }
};