import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseLayout from '../../../../../components/Layout/Brand/BaseLayout';
import { brandCreditsEvoucherPreview, brandCreditsEvoucherPreviewSubmit, brandCreditsEvoucherPreviewCancel } from '../../../../../actions';
import { Popover } from 'antd'
import Table from '../../../../../components/Common/Table';
import notification from '../../../../../components/UIElems/Notification';
import cx from 'classnames/bind';
import Loader from '../../../../../components/UIElems/Loader';
import DescriptionPopover from '../../../../../components/Brand/Credits/Evoucher/DescriptionPopover';

const Preview = props => {

    const [evoucherList, setEvoucherList] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        setIsloading(true);
        props.actions.brandCreditsEvoucherPreview(props.match.params.id)
        .then(data => {
            if (!data.err && data.data) {
                setEvoucherList(data.data);
            } else {
                const errMsg = data.msg ?
                    data.msg :
                    'Something went wrong!';
                notification.send({device: props.rdx.appReducer.deviceType, message: errMsg, type: 'error'});
                setSubmitDisabled(true);
                setEvoucherList([]);
            }
            setIsloading(false);
        });
    }, []);

    const onSubmit = () => {
        if (window.confirm('You will not be able to edit the evouchers once they are uploaded. Are you sure you want to continue?')) {
            setIsloading(true)
            props.actions.brandCreditsEvoucherPreviewSubmit(props.match.params.id)
            .then(data => {
                setIsloading(false);
                if (data && !data.err) {
                    notification.send({device: props.rdx.appReducer.deviceType, message: `Successfully processed ${data.data.insertedCodes} codes`, type: 'success'});
                    setTimeout(() => {
                        window.location = '/brand/credits/evoucher/all';
                    }, 1500);
                } else {
                    setSubmitDisabled(true);
                    const errMsg = data.msg ?
                        data.msg :
                        'Something went wrong!';
                    notification.send({device: props.rdx.appReducer.deviceType, message: errMsg, type: 'error'});
                }
            });
        }
    }

    const onCancel = () => {
        setIsloading(true);
        props.actions.brandCreditsEvoucherPreviewCancel(props.match.params.id)
        .then(data => {
            if (data && !data.err) {
                setSubmitDisabled(true);
                setIsloading(false);
                notification.send({device: props.rdx.appReducer.deviceType, message: `Cancelled the processing for this batch of codes. Screen will redirect soon.`, type: 'success'});
                setTimeout(() => {
                    window.location = '/brand/credits/evoucher/add';
                }, 1500);
            } else {
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Something went wrong!', type: 'error'});
            }
        })
    }

    const columns = [
        {
            id: "code",
            Header: "eVoucher Code",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <DescriptionPopover code={(typeof props.value.code === "object") ? props.value.code.text : props.value.code} description={props.value.description} />
            // Cell: props => <DescriptionPopover code={props.value.code} description={props.value.description} />
        },
        {
            id: "evoucherValue",
            Header: "Value",
            accessor: "evoucherValue",
            filterable: false,
            sortable: false
        },
        {
            id: "equivalentCredits",
            Header: "Eq. Credits",
            accessor: "equivalentCredits",
            filterable: false,
            sortable: false
        },
        {
            id: "providerName",
            Header: "Provider",
            accessor: "providerName",
            filterable: false,
            sortable: false,
        },
        {
            id: "subcategoryName",
            Header: "Subcategory",
            accessor: "subcategoryName",
            filterable: false,
            sortable: false,
        },
        {
            id: "country",
            Header: "Country",
            accessor: "country",
            filterable: false,
            sortable: false,
        },
        {
            id: "endDate",
            Header: "Valid Till",
            accessor: "endDate",
            filterable: false,
            sortable: false,
        }
    ]

    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-credits">
                <h1>Preview VIP eVoucher</h1>
            </div>
            <Table
                columns={columns}
                manual
                data={evoucherList}
                loading={isLoading}
                showPagination={false}
                defaultPageSize={10}
            />

            <hr />

            <div className={cx("button-wrap")}>
                <button className={cx("btn", "btn-blue")} onClick={onSubmit} disabled={submitDisabled}>Confirm Upload</button>
                <button className={cx("btn", "btn-light")} onClick={onCancel}>Cancel</button>
            </div>
            { isLoading && <Loader /> }
        </BaseLayout>
    );
}

const mapStateToProps = (state, ownProps) => ({rdx: state})

const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandCreditsEvoucherPreview,
        brandCreditsEvoucherPreviewSubmit,
        brandCreditsEvoucherPreviewCancel
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview);