import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { format } from 'date-fns';
import { Button, Popconfirm, Modal, Popover } from 'antd';
import ElemModal from '../../../UIElems/Modal';
import Tabs from '../../../UIElems/Tabs';
import Table from '../../../Common/Table';

const CustomDeclineModal = ({rowData, flag, callback}) => {
    const [isCustomDeclineVisible, setIsCustomDeclineVisible] = useState(false);
    const [isCustomDeclineLoading, setIsCustomDeclineLoading] = useState(false);
    const [customDeclineText, setCustomDeclineText] = useState("");

    const onClick = () => {
        setIsCustomDeclineVisible(true);
    }

    const onOk = () => {
        if(!customDeclineText.length) return alert("You need to specify the reason for declining the user!");
        setIsCustomDeclineLoading(true);
        callback(rowData.influencerId, flag, customDeclineText);
    }

    return (
        <span>
            <a className={cx("brand-status-button", "brand-status-decline-btn")} onClick={onClick}>Decline</a>
            <Modal
                title={'Reason for declining?'}
                visible={isCustomDeclineVisible}
                okText="Send"
                onOk={onOk}
                confirmLoading={isCustomDeclineLoading}
                onCancel={e => setIsCustomDeclineVisible(false)}
                maskClosable={false}>
                <textarea value={customDeclineText} onChange={e => setCustomDeclineText(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

const Profile = ({profile, socialProfiles, kids, jobList, loading, pageSize = 5, tabIndex, onInfluencerApprove, onSocialAccountDelete, onInfluencerDelete, onInfluencerEdit, onFetchData}) => {
    if(!profile) return null;  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editType, setEditType] = useState('');
    const [editLabel, setEditLabel] = useState('');
    const [editValue, setEditValue] = useState('');

    const onEditClick = (type, label, value) => {
        setEditType(type);
        setEditLabel(label);
        setEditValue(value);
        setIsModalOpen(true);
    }

    const onEditModalClose = () => {
        setIsModalOpen(false);
        setEditType('');
        setEditLabel('');
        setEditValue('');
    }

    const onEditModalOk = () => {
        onInfluencerEdit({...profile, [editType]: editValue});
    }
    
    const approvalStatus = (status) => {
        let statusString = "";        
        switch(status) {
            case 0:
            statusString = 'declined';
            break;
            case 1:
            statusString = 'approved';
            break;
            case 2:
            statusString = 'pending';
            break;
        }

        return statusString;
    }

    const PopApproveConfirm = ({onConfirm, onCancel}) => {
        return <Popconfirm placement="top"
            title="account type?"
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText="tapfluencer"
            cancelText="vip"
            okType="default">
            <a className={cx("brand-status-button", "brand-status-approve-btn")} href="#!">Approve</a>
        </Popconfirm>;
    }

    const DeliverablePopover = ({deliverable}) => {
        const maxLength = 30;
        const truncatedString = deliverable.length > maxLength? deliverable.substring(0, maxLength - 3) + '...': deliverable;
        const content = (
            <div>
                <span>{deliverable}</span>
            </div>
        )
        return (
            <Popover title="Deliverable" content={content} placement="top">
                <span style={{cursor: "pointer"}}>{truncatedString}</span>
            </Popover>
        )
    }

    const JobLink = ({ job }) => {
        const { id, job_type, jobName} = job;
        const url = job_type === 'vip_campaign' ?
        `/brand/campaign/${id}/home` :
        `/brand/panel/${id}/home`
        return (
            <a target="_blank" href={url}>
                {jobName}
            </a>
        )
    }

    const JobTypeFilter = ({ filter, onChange }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = [
            {
                text: 'All',
                value: 'vip_all'
            },
            {
                text: 'Influencer',
                value: 'vip_campaign'
            },
            {
                text: 'Panel',
                value: 'vip_panel'
            }
        ]
      
        // Render a multi-select box
        return (
          <select
            className="select-filter"
            value={filter && filter.value ? filter.value : 'vip_all'}
            onChange={e => {
              onChange(e.target.value || undefined)
            }}
          >
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        )
      }

    const applicationStatusMap = {
        0: 'Declined',
        1: 'Approved',
        2: 'Awaiting Approval',
        3: 'Need More Info',
        4: 'Completed',
        5: 'Job Submitted',
        6: 'Expired'
    }

    const columns = [
        {
            Header: "Client Name",
            accessor: "client_name",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Brand Name",
            accessor: "brand_name",
            filterable: false,
            sortable: false,
        },
        {
            id: "jobType",
            Header: "Job Type",
            accessor: "job_type",
            filterable: true,
            sortable: false,
            Filter: JobTypeFilter,
            Cell: (props => props.value === 'vip_campaign'? 'Influencer': (props.value === 'vip_panel' ? 'Panel' : 'Poll'))
        },
        {
            Header: "Job Name",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => <JobLink job={props.value} />
        },
        {
            Header: "Deliverables",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => (props.value.job_type === 'vip_campaign' ?
                <DeliverablePopover deliverable={props.value.instructions} /> :
                '')
        },
        {
            Header: "Job Status",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => (
                <span style={{textAlign: 'justify'}}>
                    {props.value.job_type === 'vip_campaign'? applicationStatusMap[props.value.applicationStatus]: 'Completed'}
                </span>
            )
        }
    ]

    return (
       <div className={cx("brand-user-profile-wrap")}>

            <h1>Profile</h1>
                
            <div className={cx("user-profile-container")}>
                <div className={cx("user-profile")}>
                    <div className={cx("user-profile-details")}>
                        <div className={cx("profile-pic")}>
                            <img src={profile.image} alt="profile-image" />
                        </div>
                        <div className={cx("user-status")}>
                            <div className={cx("user-name")}>
                                <p>{profile.name}</p>
                            </div>
                            <div>
                                <span className={cx("user-status-button")}>
                                    {approvalStatus(profile.isApproved)}

                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                    
                                    {
                                        (profile.isApproved !== 1)
                                        && <PopApproveConfirm onConfirm={e => onInfluencerApprove(profile.influencerId, 2)} onCancel={e => onInfluencerApprove(profile.influencerId, 1)} />
                                    }
                                    &nbsp;
                                    {
                                        profile.isApproved !== 0
                                        && <CustomDeclineModal rowData={profile} flag={0} callback={onInfluencerApprove} />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={cx("user-details-container")}>
                        <Tabs
                            defaultActiveKey={tabIndex || '1'}
                            elems={[
                                {
                                    name: 'User Details',
                                    elem: <div className={cx("user-details-container")}>
                                        <div className={cx("user-details")}>
                                            <div className={cx("user-alises")}>
                                                <label >Gender:</label>
                                                <p>{profile.gender}</p>
                                            </div>
                                            <div className={cx("user-alises")}>
                                                <label >Birth Date:</label>
                                                <p>{format(profile.birthDate, 'DD-MM-YYYY')}</p>
                                            </div>
                                            <div className={cx("user-alises")}>
                                                <label >Phone: </label>
                                                <p>{(profile.countryCode || '') + ' ' + (profile.phoneNo || '')}</p>
                                            </div>
                                            {profile.work_position && <div className={cx("user-alises")}>
                                                <label >Work position: </label>
                                                <p>{profile.work_position}</p>
                                            </div>}
                                            <div className={cx("user-alises")}>
                                                <label >Country:</label>
                                                <p>{profile.country}</p>
                                            </div>

                                            <div className={cx("user-alises")}>
                                                <label >Due Date: </label>
                                                <p>{profile.dueDate ? format(profile.dueDate, 'DD-MM-YYYY') : 'NA'}</p>
                                            </div>

                                            {
                                                profile.nricName &&
                                                <div className={cx("user-alises")}>
                                                    <label >NRIC Name:</label>
                                                    <p>{profile.nricName}</p>
                                                </div>
                                            }

                                            {
                                                profile.nricNo &&
                                                <div className={cx("user-alises")}>
                                                    <label >NRIC No: </label>
                                                    <p>{'*******' + profile.nricNo.slice(-4)}</p>
                                                </div>
                                            }

                                        </div>

                                        <div className={cx("activity-wrap")}>
                                            <h3>Activity</h3>
                                            <div className={cx("activity-items")}>
                                                <div className={cx("activity-details")}>
                                                    <div>
                                                        <label>Joined On: </label>
                                                        {format(profile.joinedOn, 'DD-MM-YYYY')}
                                                    </div>
                                                </div>
                                                <div className={cx("activity-details")}>
                                                    <div>
                                                        <label>Total Points: </label>
                                                        {profile.totalPoints}
                                                    </div>
                                                </div>
                                                <div className={cx("activity-details")}>
                                                    <div>
                                                        <label>Active Points: </label>
                                                        {profile.activePoints}
                                                    </div>
                                                </div>
                                                <div className={cx("activity-details")}>
                                                    <div>
                                                        <label>Questions Posted: </label>
                                                        {profile.questionsPosted}
                                                    </div>
                                                </div>
                                                <div className={cx("activity-details")}>
                                                    <div>
                                                        <label>Answers Posted: </label>
                                                        {profile.answersPosted}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={cx("kids-details-wrap")}>
                                            {!!kids.length && <h3>Kids</h3>}
                                            {
                                                kids.map((kid, i) => {
                                                    return <div key={i} className={cx("kids-details")}>
                                                        <div>
                                                            <label htmlFor="">Name: </label>
                                                            {kid.name}
                                                        </div>
                                                        <div>
                                                            <label htmlFor="">Birth Date: </label>
                                                            {format(kid.birthDate, 'DD-MM-YYYY')}</div>
                                                        <div>
                                                            <label htmlFor="">Gender: </label>
                                                            {kid.gender}</div>
                                                    </div>
                                                })
                                            }
                                        </div>

                                        <div className={cx("user-alises")}>
                                            <h3>Address:</h3>
                                            <p>{profile.address}</p>
                                        </div>

                                    </div>
                                },
                                {
                                    name: 'All Applied Jobs',
                                    elem: <Table
                                            columns={columns}
                                            manual
                                            data={jobList.list}
                                            pages={Math.ceil(jobList.total / pageSize)}
                                            loading={loading}
                                            onFetchData={onFetchData}
                                            defaultPageSize={5} />
                                }
                            ]}
                        />
                    </div>

                    <div className={cx("user-details-container")}>
                        <Tabs tabsHandler={() => {}}
                            defaultActiveKey={1}
                            elems={[
                                {
                                    name: 'Panel',
                                    elem: <div className={cx("ant-tabs-items")}>
                                    {profile.ethnicity && <div className={cx("user-alises")}>
                                        <label htmlFor="">Ethinicity: </label>
                                        <p>{profile.ethnicity}</p>
                                    </div>}

                                    {profile.marital_status && <div className={cx("user-alises")}>
                                        <label htmlFor="">Marital status: </label>
                                        <p>{profile.marital_status}</p>
                                    </div>}
                                    {profile.qualification && <div className={cx("user-alises")}>
                                        <label htmlFor="">Qualification: </label>
                                        <p>{profile.qualification}</p>
                                    </div>}
                                    {profile.occupation &&  <div className={cx("user-alises")}>
                                        <label htmlFor="">Occupation: </label>
                                        <p>{profile.occupation}</p>
                                    </div>}
                                    {profile.chief_wage_earner && <div className={cx("user-alises")}>
                                        <label htmlFor="">Chief wage earner: </label>
                                        <p>{profile.chief_wage_earner}</p>
                                    </div>}
                                    {profile.use_tap_on && <div className={cx("user-alises")}>
                                        <label htmlFor="">Use TAP on: </label>
                                        <p>{profile.use_tap_on}</p>
                                    </div>}
                                    {profile.income_monthly && <div className={cx("user-alises")}>
                                        <label htmlFor="">Monthly income: </label>
                                        <p>{profile.income_monthly}</p>
                                    </div>}
                                    {profile.spouse_education_level && <div className={cx("user-alises")}>
                                        <label htmlFor="">Spouse education level: </label>
                                        <p>{profile.spouse_education_level}</p>
                                    </div>}
                                    {profile.spouse_occupation && <div className={cx("user-alises")}>
                                        <label htmlFor="">Spouse occupation: </label>
                                        <p>{profile.spouse_occupation}</p>
                                    </div>}
                                    {profile.language_known && <div className={cx("user-alises")}>
                                        <label htmlFor="">Language known: </label>
                                        <p>{profile.language_known}</p>
                                    </div>}
                                    </div>
                                },
                                {
                                    name: 'Influencer',
                                    elem: <div className={cx("ant-tabs-items")}>
                                     <div className={cx("user-alises")}>
                                        <label htmlFor="">Email:</label> 
                                        <p>{profile.email}</p>
                                    </div>
                                    {profile.job && <div className={cx("user-alises")}>
                                        <label htmlFor="">Job:</label>
                                        <p>{profile.job}</p>
                                    </div>}
                                    <div className={cx("user-alises")}>
                                        <label htmlFor="">Secondary Email:</label> 
                                        <p>
                                            {profile.secondaryEmail}
                                            &nbsp;<Button type="default" shape="circle" icon="edit" size="small" onClick={e => onEditClick('email', 'Secondary Email', profile.secondaryEmail)} />
                                        </p>
                                    </div>  
                                    <div className={cx("user-alises")}>
                                        <label htmlFor="">Job Category: </label>
                                        <p>{profile.jobCategory}</p>
                                    </div>
                                     {
                                        profile.governmentIdImage &&
                                        <div className={cx("user-alises")}>
                                            <label htmlFor="">Government Id: </label>
                                            <p>
                                                <a href={profile.governmentIdImage} target="_blank">
                                                    <img src={profile.governmentIdImage} alt="government-id-preview" width="120" height="60" />
                                                </a>                                        
                                            </p>
                                        </div>
                                    }
                                    <div className={cx("user-alises")}>
                                        <label htmlFor="">Origin: </label>
                                        <p>{profile.origin}</p>
                                    </div>                

                                    </div>
                                }
                            ]} />

                            </div>

                            <div className={cx("user-details-container")}>
                                <div className={cx("social-details-wrap")}>
                                    { !!socialProfiles.length && <h3>Social Profiles</h3> }
                                    <div className={cx("social-container")}>
                                        <SocialProfiles socialProfiles={socialProfiles}
                                            onSocialAccountDelete={onSocialAccountDelete} />
                                    </div>
                               </div>

                                <div className="user-delete-wrap">
                                    <button type="button" className={cx("btn", "btn-danger")} onClick={e => onInfluencerDelete(profile.influencerId)}>Delete Main Account</button>
                                </div>
                            </div>
                   
                </div> 
           </div>

           <ElemModal title="Edit"
                openModal={isModalOpen}
                onClose={onEditModalClose}
                onOk={onEditModalOk} >

                <div className={cx("form-group")}>
                    <label htmlFor="edit-value">{editLabel}: </label>
                    <input id="edit-value" type="text" value={editValue} onChange={e => setEditValue(e.target.value)} size="40" />
                </div>

            </ElemModal>
              
       </div>
    );
}

export default Profile;


const SocialProfiles = ({socialProfiles, onSocialAccountDelete}) => {
    return (
        socialProfiles.map((socialProfile, i) => {
            //profile image
            let defaultProfileImg = "";
            if(!socialProfile.profileImage) {
                defaultProfileImg =
                    <img src="https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/default-social-profile-icon.jpg" alt="profile-url" />
            } else {
                defaultProfileImg =
                    <img src={socialProfile.profileImage} alt="profile-url" />
            }
            //
            let socialNetworks = {
                fb: 'Facebook',
                fbPage: 'Fb Page',
                twitter: 'Twitter',
                insta: 'Instagram',
                youtube: 'Youtube',
                blog: 'Blog',
                tiktok: 'Tiktok'
            }
            //
            const profileLinks = {
                insta: `https://instagram.com/${socialProfile.slug}`,
                fbPage: socialProfile.profileUrl,
                twitter: `https://twitter.com/${socialProfile.slug}`,
                fb: socialProfile.profileUrl,
                blog: socialProfile.profileUrl,
                tiktok: socialProfile.profileUrl
            }

            return <div key={i} className={cx("social-profile-details")}>
                <div className={cx("social-profile-pic")}>
                    {defaultProfileImg}
                </div>
                <div className={cx("social-profile-info")}>
                    <div>
                        <label>Name: </label>
                        {
                            ((socialProfile.type in profileLinks) && (socialProfile.slug || socialProfile.profileUrl))
                            ? <a href={profileLinks[socialProfile.type]} target="_blank" style={{textDecoration: 'underline'}}>{socialProfile.socialName || socialProfile.type}</a>
                            : <span>{socialProfile.socialName}</span>  
                        }                        
                    </div>
                    {
                        <div>
                            {/*<span className={cx("icon-"+socialProfile.type, "social-icon-style")}></span>*/}
                            <span>{socialNetworks[socialProfile.type]}</span>
                        </div>
                    }
                    <div>
                        <label>Follower Count: </label> 
                        {socialProfile.followerCount}
                    </div>
                    <div>
                        <label>Last Updated: </label> 
                        {format(socialProfile.updatedAt, 'DD-MM-YYYY')}
                    </div>
                </div>
                <div style={{marginLeft: '16px'}}>
                    <button type="button" className={cx("brand-status-button", "brand-status-decline-btn")} onClick={e => onSocialAccountDelete(socialProfile.influencerId, socialProfile.type)}>Delete</button>
                </div>
            </div>
        })
    );
}
