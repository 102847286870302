import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { 
    influencerSocialAccountDelete,
    influencerSocialInstaGraphConnectInitiate,
    influencerSocialFbPageAccountList,
    influencerInstaBusinessAccountConnect,
    genericAction,
    influencerValidateValue

} from '../../../../../../actions';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';
import { Icon, Typography, List, Avatar, Spin } from 'antd';
import Loader from '../../../../../UIElems/Loader';
import Modal from '../../../../../UIElems/Modal';

const { Text } = Typography;

const IconSvg = () => {
    return (
        <span style={{ display: 'inline-block', borderRadius: '50%', width: '30px', height: '30px', position: 'absolute', top: '47px', left: '51px', background: '#517fa5', border: 'none' }}>
            <svg style={{ width: '20px', height: 'auto', marginTop: '4px'}} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                 viewBox="0 0 56.7 56.7" enableBackground="new 0 0 56.7 56.7">
                <g>
                    <path fill="#fff" d="M28.2,16.7c-7,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S41,36.5,41,29.5S35.2,16.7,28.2,16.7z M28.2,37.7
                        c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2S32.7,37.7,28.2,37.7z"/>
                    <circle fill="#fff" cx="41.5" cy="16.4" r="2.9"/>
                    <path fill="#fff" d="M49,8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7,0-14.5,5.8-14.5,14.5v20.5c0,4.3,1.4,8,4.2,10.7c2.7,2.6,6.3,3.9,10.4,3.9
                        h20.4c4.3,0,7.9-1.4,10.5-3.9c2.7-2.6,4.1-6.3,4.1-10.6V19.3C53,15.1,51.6,11.5,49,8.9z M48.6,39.9c0,3.1-1.1,5.6-2.9,7.3
                        s-4.3,2.6-7.3,2.6H18c-3,0-5.5-0.9-7.3-2.6C8.9,45.4,8,42.9,8,39.8V19.3c0-3,0.9-5.5,2.7-7.3c1.7-1.7,4.3-2.6,7.3-2.6h20.6
                        c3,0,5.5,0.9,7.3,2.7c1.7,1.8,2.7,4.3,2.7,7.2V39.9L48.6,39.9z"/>
                </g>
            </svg>
        </span>
    );
}

const AccountListModal = ({isModalOpen, setIsModalOpen, fbPageAccountList, setIsLoading, props}) => {

    const onModalClose = () => {
        // props.actions.influencerSocialInstaGraphConnectInitiate(props.rdx.influencerReducer.id, false);
        setIsModalOpen(false);
    }

    const onInstaBusinessAccountConnect = (influencerId, pageId) => {
        props.actions.influencerSocialInstaGraphConnectInitiate(influencerId, false);
        setIsLoading(true);
        props.actions.influencerInstaBusinessAccountConnect(influencerId, pageId)
        .then(() => {
            window.location.reload(true);
        })
    }

    return (
        <Modal title="Select The Page Associated With The Business Account"
            openModal={isModalOpen}
            onClose={onModalClose}
            onOk={onModalClose} >

            <div className="infinite-container">
                <List dataSource={fbPageAccountList}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                avatar={
                                    <Avatar src={item.picture.data.url} />
                                }
                                title={item.name}
                                description={<span>count: {item.fan_count}</span>} />
                            <div>
                                <button type="button" onClick={e => onInstaBusinessAccountConnect(props.rdx.influencerReducer.id, item.id)}>
                                    connect
                                </button>
                            </div>
                        </List.Item>
                    )} >
                </List>
            </div>

        </Modal>
    );
}

const InstaGraph = (props) => {
    const t = props.t;
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fbPageAccountList, setFbPageAccountList] = useState([]);

    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'insta', false);

    //check if initiated
    useEffect(() => {
        props.actions.influencerValidateValue(props.rdx.influencerReducer.id, 'ifInstaGraphInitiate')
        .then(res => {
            if(res.data) {
                setIsLoading(true);
                props.actions.influencerSocialFbPageAccountList(props.rdx.influencerReducer.id)
                .then(results => {
                    setIsLoading(false);
                    setFbPageAccountList(results.data);
                    setIsModalOpen(true);
                })
            }            
        })
    }, [])

    const onConnect = () => {
        setIsLoading(true);
        props.actions.influencerSocialInstaGraphConnectInitiate(props.rdx.influencerReducer.id, true)
        .then(() => {
            window.location = '/social/auth/instagram';
        })                
    }

    const onCountRefresh = () => {
        if(window.confirm("Are you sure you want to refresh the account?")) {
            props.actions.influencerSocialInstaGraphConnectInitiate(props.rdx.influencerReducer.id, true)            
            .then(r => {
                window.location = '/social/auth/instagram';
            })           
        }        
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("insta-icon-social-account-bg")}>
                    <div className={cx("insta-followers-social-icon")}></div>
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>{t('social_account_connect')} +</button>
            </div>
        );        
    }

    const ConnectedState = () => {
        let data = socialAccount.insta;

        const onClick = () => {
            window.location = '/influencer/profile/social/insta';
        }

        return (
            <div className="">
                {
                    data.profileImage
                    ? 
                        <div style={{position: 'relative'}} className={cx("followers-profile-image-wrap")}>
                            <img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                            <IconSvg />
                        </div>
                    : 
                        <div style={{position: 'relative'}} className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")}></div>
                            <IconSvg />
                        </div>
                }
                <div>{data.socialName}</div>
                {/*<div><img src={} alt=""/></div>*/}
                <div className={cx("btn", "btn-followers")} onClick={onClick}>{t('social_account_followers')}: {data.followerCount ? data.followerCount : 0}</div>
                {/*<div>Posts: {data.postsCount ? data.postsCount : 0}</div>*/}

                <div className={cx("refresh-followers-wrap")}>
                    <a href="#" className={cx("btn-refresh")} onClick={e => onCountRefresh()}>
                        <span className={cx("icon-refresh")}></span>
                        <span>{t('social_account_refresh')}</span>
                    </a>
                </div>
            </div>
        );        
    }    
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.insta
                ? <ConnectedState />
                : <ConnectState />
            }

            <AccountListModal isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                fbPageAccountList={fbPageAccountList}
                setIsLoading={setIsLoading}
                props={props} />

            { isLoading && <Loader /> }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerSocialAccountDelete,
        influencerSocialInstaGraphConnectInitiate,
        influencerSocialFbPageAccountList,
        influencerInstaBusinessAccountConnect,
        genericAction,
        influencerValidateValue
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(InstaGraph);
