import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames/bind';
import Button from '@material-ui/core/Button';
import BaseLayout from '../../Layout/Brand/BaseLayout';

const Login = () => {
    return (
        <BaseLayout>
            <div className="login">
                <h1>Start</h1>
                <div>
                    <Button variant="contained" color="primary" href="/influencer/login">
                        Influencer
                    </Button>
                </div>
                {/*
                <div> - or - </div>
                <div>
                    <Button variant="contained" color="primary" href="/brand/admin/signup">
                        Brand
                    </Button>
                </div>
                */}
            </div>
        </BaseLayout>                 
    );
}

export default Login;