import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const Accordion = ({elems = []}) => {
    return (
        <div>
            <Collapse accordion>
                {
                    elems.map((elem, i) => {
                        return <Panel header={elem['heading']} key={i}>
                            <p style={{whiteSpace: 'pre-line'}}>{elem['desc']}</p>
                        </Panel>
                    })
                }
            </Collapse>
        </div>        
    );
}

export default Accordion;