import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import SidebarTemplate from '../Template';
import { translate } from 'react-i18next';

const Profile = ({links = [], defaultState='expand', t}) => {
    const [pathname, setPathname] = useState('');
    //set pathname
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    const defaultLinks = [
        {path: '/influencer/profile/connect', title: t('sidebar_social_accounts'), ifActive: ((pathname == '/influencer/profile/connect') ? 'active-item' : '')},
        {path: '/influencer/profile/edit', title: t('sidebar_edit'), ifActive: ((pathname == '/influencer/profile/edit') ? 'active-item' : '')}
    ];

    return (
        <SidebarTemplate heading={t('sidebar_profile')}
            links={[...defaultLinks, ...links]}
            defaultState={defaultState} 
            iconClass="influ-sidebar-profile-icon" />
    );
}

export default translate()(Profile);
