import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';
import Signup from '../../../components/Brand/Signup';
import Login from '../../../components/Brand/Login';
import Button from '@material-ui/core/Button';

const BrandSignup = (props) => {
    const [ifSignup, toggleSignup] = useState(true);
    console.log(props.location.search);
    return (
        <Fragment>
            {
                (props.location.search == '?shazam')
                    ?
                    <div className="Signup">
                        <Signup />
                    </div>
                    :
                    <div className="Login">
                        <Login />
                    </div>
            }

            <br/>

            {/*<div>-- else --</div>*/}
            
            <br/>

            {/*<div>
                <Button variant="contained" color="primary" onClick={() => toggleSignup(!ifSignup)}>
                    {ifSignup ? 'Login' : 'Signup'}
                </Button>
            </div>*/}
        </Fragment>
    );
}

export default BrandSignup;
