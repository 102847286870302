import React, { Fragment } from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames/bind';
import { format } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Affix, Button } from 'antd';
import PaymentDetailsUpdateModal from '../../../Brand/Payments/PaymentDetailsUpdate';
import StickyFooter from '../../../UIElems/StickyFooter';

const CampaignDetail = ({data, profile, deadlineDetails, declineDetails, shareUrl, campaignApplicationStatus, onCopy, t, deviceType}) => {
    if(!data) return null;
    
    const paymentScrollRef = useRef(null);

    const smoothScroll = (e, refElem) => {
        e.preventDefault();
        refElem.current.scrollIntoView({behavior: 'smooth'});
    }
    
    const campaignApplicationStatusText = {
        0: t('campaign_status_decline'),
        1: t('campaign_status_approved'),
        2: t('campaign_status_awaiting_approval')
    };

    const statusBarText = () => {
        let color = "";
        let label = "";
        let status = "";
        // 
        switch(campaignApplicationStatus) {
            case 0:
                color = "red-";
                label = "";
                status = (declineDetails && 'customMessage' in declineDetails && declineDetails['customMessage']) ? declineDetails.customMessage : campaignApplicationStatusText[campaignApplicationStatus];
            break;
            case 1:
                color = "green-";
                label = ('deadlineDate' in deadlineDetails && deadlineDetails['deadlineDate']) ? t("mob_campaign_details_deadline_of_submission") : "";
                status = ('deadlineDate' in deadlineDetails && deadlineDetails['deadlineDate']) ? format(deadlineDetails.deadlineDate, 'Do MMM YYYY') + '\n' + deadlineDetails.deadlineComments : "";
            break;
            case 2:
                color = "green-";
                label = "";
                status = campaignApplicationStatusText[campaignApplicationStatus];
            break;
        }

        return {color, label, status}
    }

    return (
       <div className={cx("mob-influencer-campaign-detail-wrapper", "influencer-main-container")}>

            <div className="campaign-detail-header">
                { 
                    //<h1>{t('campaign_details_title')}</h1> 
                }
                {
                    // campaignApplicationStatus !== null && campaignApplicationStatus !== 1 &&
                    // <Affix offsetTop={deviceType == 'desktop' ? 80 : 100}>
                    //     <div className={cx("dynamic-status-" + campaignApplicationStatus)}>{ campaignApplicationStatusText[campaignApplicationStatus] }</div>
                    // </Affix>     
                }
            </div>

            <div className={cx("campaign-product-image")}>
                <img src={data.productImage} alt="product-image" width="500" />
            </div>

            <div className={cx("campaign-details-body")}>
                <div className={cx("white-block")}>
                    <div className={cx("campaign-sub-title")}>
                        <h1 className={cx("cd-margin-bottom0")}>{data.campaignName}</h1>
                        {/*<div className={cx("posted-date")}>
                            {t('mob_campaign_details_posted')} 2 {t('mob_campaign_details_day_ago')}
                        </div>*/}
                    </div>
                    
                    <div className={cx("points-wrapper")}>
                        <span className={cx("pts-row-one")}>
                            <span className={"pts"}>
                                <img src={data.paymentTypeImage} alt=""/> {data.paymentString}
                            </span>
                        </span>
                        <span className={cx("pts-row-two")}>
                            <a onClick={e => smoothScroll(e, paymentScrollRef)}>
                                {t('mob_campaign_details_view_payment_details')}
                            </a>
                        </span>
                    </div>
                    <div className={cx("campaign-date")}>
                        <span></span>
                        <span> {t('mob_campaign_details_campaign_date')} : </span>
                        <span> { (data.startDate && data.endDate) ? format(data.startDate, 'Do MMM YYYY') + ' - ' + format(data.endDate, 'Do MMM YYYY') : '' } </span>
                    </div>
                </div>

                <div className={cx("brand-information-wrap", "white-block")}>
                    <div className={cx("campaign-sub-title", "cd-margin-bottom0")}>
                        <h2 className={cx("cd-margin-bottom0", "cd-margin-bottom10")}>{t('mob_campaign_details_brand_information')}</h2>
                    </div>
                    <div className={cx("campaign-client-header", "cd-margin-bottom15")}>
                        <div className={cx("client-logo")}>
                            <img src={data.logo} alt="logo" />
                        </div>
                        <div className={cx("client-name")}>
                            {/*<div>{data.clientName}</div>*/}
                            <div className={cx("cd-margin-left10")}>
                                <h4>
                                    {data.brandName}
                                </h4>
                            </div>
                            <div className={cx("cd-margin-left10", "brand-tag")}>{data.productCategory}</div>
                        </div>
                    </div>
                    <div className={cx("campaign-detail-social-links")}>
                        { 
                            data.fbLink && 
                            <div className={cx("social-links", "cd-margin-bottom10")}>
                                <a href={data.fbLink} target="blank">
                                    <span className={cx("fb-iconFluencer")}></span>
                                    <span className={cx("last-child")}>Facebook</span>
                                </a>
                            </div> 
                        }
                        { 
                            data.twitterLink && 
                            <div className={cx("social-links", "cd-margin-bottom10")}>
                                <a href={data.twitterLink} target="blank">
                                    <span className={cx("twitter-iconFluencer")}></span>
                                    <span className={cx("last-child")}>Twitter</span>
                                </a>
                            </div> 
                        }
                        { 
                            data.instaLink && 
                            <div className={cx("social-links", "cd-margin-bottom10")}>
                                <a href={data.instaLink} target="blank">
                                    <span className={cx("insta-iconFluencer")}></span>
                                    <span className={cx("last-child")}>Instagram</span>
                                </a>
                            </div> 
                        }
                        { 
                            data.youtubeLink && 
                            <div className={cx("social-links", "cd-margin-bottom10")}>
                                <a href={data.youtubeLink} target="blank">
                                    <span className={cx("youtube-iconFluencer")}></span>
                                    <span className={cx("last-child")}>Youtube</span>
                                </a>
                            </div> 
                        }
                        { 
                            data.tiktokLink && 
                            <div className={cx("social-links", "cd-margin-bottom10")}>
                                <a href={data.tiktokLink} target="blank">
                                    <span className={cx("tiktok-iconFluencer")}></span>
                                    <span className={cx("last-child")}>Tiktok</span>
                                </a>
                            </div> 
                        }
                    </div>
                </div>

                { 
                    !!data.hasDynamicUrl &&
                    <div className={cx("campaign-detail-wrap", "white-block")}>                    
                        <div className={cx("campaign-detail-utm")}>
                            <h4>{t("mob_campaign_details_url")}</h4>
                            <div className={cx("campaign-detail-copy-link-wrap")}>
                                <div className={cx("copy-link")}>
                                    {shareUrl}
                                </div>

                                <CopyToClipboard text={shareUrl}
                                    onCopy={() => onCopy()}>
                                    <button className={cx("btn-copy")}>{t("mob_campaign_details_copy")}</button>
                                </CopyToClipboard>
                            </div>                    
                        </div>                    
                    </div>
                }

                { 
                    !!data.variableFieldLabel && !!data.variableFieldValue &&
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{data.variableFieldLabel}</h2>
                        </div>                    
                        <div className={cx("campaign-detail-description")}>
                            { campaignApplicationStatus == 1 ? data.variableFieldValue : 'Will be visible, once approved'}
                        </div>
                    </div>
                }

                <div className={cx("white-block")}>
                    <div className={cx("campaign-sub-title")}>
                        <h2>Bank Details</h2>
                    </div>
                    <div className={cx("campaign-detail-description")}>
                        <PaymentDetailsUpdateModal influencerId={profile.influencerId}
                            countryId={profile.countryId} />
                    </div>
                </div>                

                {   
                    data.payment &&
                    <div className={cx("campaign-payemnt", "white-block")} ref={paymentScrollRef}>                    
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t("mob_campaign_details_payment")}</h2>     
                        </div>               
                        <div className={cx("campaign-detail-description")}>
                            { data.payment }
                        </div>
                    </div>
                }                

                {
                    data.backgroundInformation &&                    
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t('mob_campaign_details_background_information')}</h2>
                        </div>
                        <div className={cx("campaign-detail-description")}>
                            { data.backgroundInformation }
                        </div>
                    </div>
                }
                
                {
                    data.instructions &&
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t("mob_campaign_details_detailed_deliverables")}</h2>
                        </div>
                        <div className={cx("campaign-detail-description")}>
                            { data.instructions }
                        </div>
                    </div>
                }

                {
                    data.consideration &&
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t("mob_campaign_details_consider_adding")}</h2>
                        </div>
                        <div className={cx("campaign-detail-description")}>
                            { data.consideration }
                        </div>
                    </div>
                }

                { 
                    data.guidelinesDont &&
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t('campaign_details_do_not')}</h2>      
                        </div>              
                        <div className={cx("campaign-detail-description")}>
                            { data.guidelinesDont }
                        </div>
                    </div>
                }

                {
                    data.toneAndVoice &&
                    <div className={cx("white-block")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2>{t("mob_campaign_details_tone_and_voice")}</h2> 
                        </div>                   
                        <div className={cx("campaign-detail-description")}>
                            { data.toneAndVoice }
                        </div>
                    </div>
                }

                {
                    data.termsAndConditions &&
                    // <a href={data.campaignTnCWebviewUrl}>
                        <div className={cx("white-block center-align-siblings")}>
                                <div style={{marginBottom: 0}} className={cx("campaign-sub-title")}>
                                    <h2 style={{marginBottom: 0}}>{t("terms_and_conditions")}</h2>             
                                </div>
                            <a href={data.campaignTnCWebviewUrl}>
                            <div className={"view-btn"}>
                                {
                                    t("view")
                                }
                                <img
                                    src={'https://tapfluencer.s3.ap-southeast-1.amazonaws.com/screening_dropdown_close.png'}
                                />
                            </div>
                            </a>
                        </div>
                    // </a>
                }                

                {
                    data.distributionRightsAndCopyright &&
                    <div className={cx("campaign-publish-detail", "white-block", "cd-margin-bottom90")}>
                        <div className={cx("campaign-sub-title")}>
                            <h2 className={cx('cd-margin-bottom0')}>{t("mob_campaign_details_distribution_rights_and_copyright")}:</h2>
                        </div>
                        <div className={cx("campaign-detail-description")}>
                            { data.distributionRightsAndCopyright }
                        </div>
                    </div>
                }
                
                {
                    campaignApplicationStatus != null && (statusBarText()['label'] || statusBarText()['status']) &&
                    <div className={cx(statusBarText()['color'] + "campaign-deadline-wrap")}>
                        <div className={"submission-txt"}>
                            <span></span>
                            <span>
                               {statusBarText()['label'] + (statusBarText()['label'] ? ': ' : '') + statusBarText()['status']}
                            </span>
                        </div>
                    </div>
                }

                {
                    campaignApplicationStatus === 2 &&
                    <StickyFooter>
                        <div className={cx("campaign-application-status-note")} style={{backgroundColor: "#ffe1ad", color:"#f9a920"}}>
                            <span>{t('approval_status_2')}</span>
                        </div>
                    </StickyFooter>

                }
                {
                    campaignApplicationStatus === 5 &&
                    <StickyFooter>
                        <div className={cx("campaign-application-status-note")}>
                            <span>{t('approval_status_5')}<br />{t('campaign_status_job_submitted_note') + ` ${format(data.submissionEndDate, 'Do MMM YYYY')}`}</span>
                        </div>
                    </StickyFooter>
                }
                {
                    campaignApplicationStatus === 4 &&
                    <StickyFooter>
                        <div className={cx("campaign-application-status-note")}>
                            <span>{t('campaign_status_completed_note')}</span>
                        </div>
                    </StickyFooter>
                }
                {
                    campaignApplicationStatus === 6 &&
                    <StickyFooter>
                        <div className={cx("campaign-application-status-note")} style={{ backgroundColor: "#C8C8C8", color: "#959595" }}>
                            <span>{t('campaign_status_expired_note')}</span>
                        </div>
                    </StickyFooter>
                }

            </div>

        </div>
    );
}



export default CampaignDetail;