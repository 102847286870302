import React, { Fragment, useState } from "react";

const QuestionOptions = ({ type = "checkbox", options = [], ...props }) => {
    type = type === 'dropdown' ? 'radio' : type;
    return (
        <Fragment>            
            {
                options.map((item, index) => {
                    return item.status === 1 && <div className={"option-card"}>
                            <div className={"input-box"}>
                                {type && <input disabled type={type} id={item.id || `qo-${index}`} name={item.id || `qo-${index}`} />}
                                <input
                                    type="text"
                                    value={item.value}
                                    onChange={(event) => props.onOptionTextChanged(index, event)}
                                    required
                                    placeholder={"Option text goes here"} // TOD: add internationalization
                                    className={"inpt"}
                                />
                            </div>
                            <div className={"opt-del"} onClick={(event) => props.onDeleteOption(index, event)} >
                                <img
                                    src={"https://tapfluencer.s3-ap-southeast-1.amazonaws.com/screening_cancel.png"}
                                    className={"opt-del-ico"}
                                />
                            </div>
                        </div>;                    
                })
            }
            {type && <div className={"add-option"} onClick={props.onAddOption}>
                {"Add option"}
                <img
                    src={"https://tapfluencer.s3-ap-southeast-1.amazonaws.com/screening_add_blue.png"}
                    className={"add-opt-ico"}
                />
            </div>}
        </Fragment>
    )
}

const Question = (props) => {
    const { question } = props;
    const [qText, setQText] = useState(question.question || "");
    const [qType, setQType] = useState(question.type || "");
    const [qOptions, setQOptions] = useState(question.options || []);
    const [isRequired, setIsRequired] = useState(!!question.required || false);

    const onQuestionTextChanged = (event) => {
        setQText(event.target.value);
        if (props.onQuestionTextChanged) {
            props.onQuestionTextChanged(event.target.value);
        }
    }

    const onQuestionTypeChanged = (event) => {
        setQType(event.target.value);
        if (props.onQuestionTypeChanged) {
            props.onQuestionTypeChanged(event.target.value);
        }
    }

    const onAddNewOptionClicked = (event) => {
        // qOptions.push("");
        const options = qOptions.concat([{
            "value": "",
            "status": 1
        }]);
        setQOptions([...options]);
        if (props.onUpdateOptions) {
            props.onUpdateOptions(options);
        }
    }

    
    const onDeleteOption = (index, event) => {
        const options = qOptions;
        options[index] = {            
            ...options[index],
            status: 0
        };
        setQOptions([...options]);
        if (props.onUpdateOptions) {
            props.onUpdateOptions(options);
        }
    }

    const onOptionTextChanged = (index, event) => {
        const options = qOptions;
        options[index] = {
            ...options[index],
            value: event.target.value
        };
        setQOptions([...options]);
        if (props.onUpdateOptions) {
            props.onUpdateOptions(options);
        }
    }

    const onDuplicateQuestionClicked = () => {
        if (props.onDuplicateQuestionClicked) {
            props.onDuplicateQuestionClicked()
        }
    }

    const onDeleteQuestionClicked = () => {
        if (props.onDeleteQuestionClicked) {
            props.onDeleteQuestionClicked();
        }
    }

    const onIsRequiredSwitched = () => {
        const state = !isRequired
        setIsRequired(state);
        if (props.onIsRequiredSwitched) {
            props.onIsRequiredSwitched(state ? 1 : 0);
        }
    }

    return <div className={"form-wrap question-card"}>
            <div className={"que-head"}>
                <input
                    placeholder="Untitled question / enter your question"
                    type="text"
                    value={qText}
                    onChange={onQuestionTextChanged}
                    required
                    className={"que-title"}
                />
                <select
                    id="type"
                    value={qType === 'dropdown' ? 'radio' : qType}
                    onChange={onQuestionTypeChanged}
                >
                    <option value="text">Short answer</option>
                    <option value="radio">Single choice</option>
                    <option value="checkbox">Multi choice</option>
                </select>
            </div>
            {
                qType === "text" ?
                    <textarea
                        readOnly
                        placeholder="Short answer text"
                        type="text"
                        placeholder="Short answer text"
                        cols="10"
                        rows="2"
                    /> :
                    <QuestionOptions
                        type={qType}
                        options={qOptions}
                        onAddOption={onAddNewOptionClicked}
                        onDeleteOption={onDeleteOption}
                        onOptionTextChanged={onOptionTextChanged}
                    />
            }
            <div className={"bottom-bar"}>
                {/* Required
                <Switch
                    id="required-switch"
                    checked={isRequired}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={"#249AFF"}
                    height={16}
                    width={32}
                    onChange={onIsRequiredSwitched}
                /> */}
                {/* <div className={"bottom-btn disabled"} onClick={onDuplicateQuestionClicked}>
                    {"Duplicate"}
                    <img
                        src={"https://tapfluencer.s3-ap-southeast-1.amazonaws.com/screening_duplicate.png"}
                        className={"duplicate-ico"}
                    />
                </div> */}
                <div className={"bottom-btn"} onClick={onDeleteQuestionClicked}>
                    {"Delete"}
                    <img
                        src={"https://tapfluencer.s3.ap-southeast-1.amazonaws.com/screening_que_del_dark.png"}
                        className={"delete-ico"}
                    />
                </div>
            </div>
        </div>
}

export default Question;