import React, { Fragment } from 'react';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';

const Footer = ({t}) => {
    
    return (
        <footer className={cx("influencer-footer")}>
            <div className={cx("footer-row3")}>
                <div className={cx("bottom-nav-container")}>
                    <div className={("copyright")}>
                        &copy; {t('campaign_footer_copyright_text')}
                    </div>
                    <div className={("right-nav")}>
                           {/*<ul>
                                <li><a href="/">Home</a></li>
                           </ul>*/}
                    </div>
                    <div className={cx("clear-fix")}></div>
                </div>
            </div>
        </footer>
    )
}

export default translate()(Footer);
