import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import ScreeningComponent from '../../../../components/Brand/Campaign/Screening';
import {} from '../../../../actions';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import notification from '../../../../components/UIElems/Notification';

const Screening = (props) => {

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Screening'/>
            ]}>
            <div className="campaign-screening">
                <h1>Screening Questions - {props.rdx.brandReducer.currentCampaign.campaignName}</h1>
                <ScreeningComponent data={props.rdx.brandReducer.currentCampaign} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({        
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Screening);
