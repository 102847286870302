import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useCampaignDetail, useInfluencerCampaignApplicationStatus, useGenericAction, useInfluencerCampaignSubmissionStatus } from '../../../../hooks/generic';
import { influencerCampaignApply, influencerCampaignUrlShorten } from '../../../../actions';
import CampaignDetailComponent from '../../../../components/Influencer/Campaign/Detail';
import BaseLayout from '../../../../components/Layout/Influencer/BaseLayout';
import { translate } from 'react-i18next';
import notification from '../../../../components/UIElems/Notification';

const CampaignDetail = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    // const campaignDetail = useCampaignDetail(props.dispatch, props.match.params.id);
    const campaignDetail = props.rdx.influencerReducer.currentCampaign;
    const campaignApplicationStatus = useInfluencerCampaignApplicationStatus(props.dispatch, props.rdx.influencerReducer.id, props.match.params.id, forceUpdate);
    const deadlineDetails = useGenericAction(props.dispatch, 'influencerCampaignDeadline', [props.rdx.influencerReducer.id, props.match.params.id]);
    const declineDetails = useGenericAction(props.dispatch, 'influencerDeclineReasonForCampaign', [props.rdx.influencerReducer.id, props.match.params.id]);
    const isInfluencerCampaignSubmitted = useInfluencerCampaignSubmissionStatus(
        () => {},
        props.dispatch,
        props.match.params.id,
        props.rdx.influencerReducer.id,
        forceUpdate
    );

    //for dynamic url
    useEffect(() => {
        if(campaignDetail.hasDynamicUrl) {
            props.actions.influencerCampaignUrlShorten(
                props.rdx.influencerReducer.id,
                campaignDetail.campaignId,
                campaignDetail.utmUrl + `?utm_source=${campaignDetail.utmSource}&utm_medium=${props.rdx.influencerReducer.slug}&utm_campaign=${campaignDetail.campaignId}`
            )
            .then(response => {
                if(!response.err) setShareUrl(response.data);
            })            
        }
    }, [campaignDetail]);

    const onApply = () => {
        props.actions.influencerCampaignApply(props.rdx.influencerReducer.id, props.match.params.id, campaignDetail.brandId)
        .then((res) => {
            if(res.err === true){
                notification.send({device: props.rdx.appReducer.deviceType, message: res.error.msg, type: 'error'});
            }
            setForceUpdate(!forceUpdate);
        })
    }

    const onCopy = () => {
        notification.send({device: props.rdx.appReducer.deviceType, message: 'Copied!', type: 'success'});
    }

    return (
        <BaseLayout leftCards={[]}>
            <CampaignDetailComponent data={campaignDetail}
                deadlineDetails={deadlineDetails}
                declineDetails={declineDetails}
                shareUrl={shareUrl}
                campaignApplicationStatus={campaignApplicationStatus}
                onApply={onApply}
                t={props.t}
                onCopy={onCopy}
                deviceType={props.rdx.appReducer.deviceType}
                isSubmitted={isInfluencerCampaignSubmitted} />
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerCampaignApply,
        influencerCampaignUrlShorten
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignDetail));