import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import EmailTrackerTable from './Table';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentPanelSidebar from '../../../../components/Brand/Sidebar/CurrentPanel';
import { genericAction, panelDetailFetch } from '../../../../actions';
import { Typography, Spin } from 'antd';
import notification from '../../../../components/UIElems/Notification';

const { Text } = Typography;

const EmailTracker = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <BaseLayout leftCards={[
            <CurrentPanelSidebar panelId={props.match.params.id} currentTitle='Email Tracker'/>
            ]}>
            <div className="email-tracker">
                <h1>Email Tracker - {props.rdx.brandReducer.currentPanel ? props.rdx.brandReducer.currentPanel.jobName : ''}</h1>

                <EmailTrackerTable panelId={props.match.params.id}
                    conditions={{}} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction,
        panelDetailFetch
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailTracker);