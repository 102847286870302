import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';
import BulkRemindContainer from './BulkRemind';
import ProfileMetricsContainer from './ProfileMetrics';
import SubmissionMetricsContainer from './SubmissionMetrics';

const Other = (props) => {
    return (
        <BaseLayout leftCards={[]}>
            <div>
                <BulkRemindContainer />
            </div>

            <hr/>

            <div>
                <ProfileMetricsContainer />
            </div>

            <hr/>

            <div>
                <SubmissionMetricsContainer />
            </div>

        </BaseLayout>
    );
}

export default Other;
