import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ApplicationsTable from '../../Applications/CampaignApplicationsTable';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import CurrentCampaignSidebar from '../../../../components/Brand/Sidebar/CurrentCampaign';
import { campaignDetailFetch } from '../../../../actions';

const CampaignApplications = (props) => {
    const [campaignDetails, setCampaignDetails] = useState(null);

    //campaign basic details
    useEffect(() => {
        props.actions.campaignDetailFetch(props.match.params.id)
        .then(res => {
            setCampaignDetails(res.data)})
    }, []);

    return (
        <BaseLayout leftCards={[
            <CurrentCampaignSidebar campaignId={props.match.params.id} currentTitle='Applications'/>
            ]}>
            <div className="campaign-applications">
                <h1>Applications - {campaignDetails ? campaignDetails.campaignName : ''}</h1>
                <ApplicationsTable campaignDetails={campaignDetails} brandId={props.rdx.brandReducer.brandId}
                    campaignId={props.match.params.id}
                    conditions={[]}
                    extra={{defaultSort: 'approvalStatus'}}
                    showApplicationStatusFilter={true} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        campaignDetailFetch
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignApplications);
