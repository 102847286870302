import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import CurrentPanelSidebar from '../../../../components/Brand/Sidebar/CurrentPanel';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import FilterForm from '../../../../components/Brand/Panel/Filter';
import { brandPanelFilterSave, genericAction, brandPanelFilterTargetCount } from '../../../../actions';
import notification from '../../../../components/UIElems/Notification';

const Filter = (props) => {
    // const [campaignDetails, setCampaignDetails] = useState(null);
    
    // useEffect(() => {
    //     //campaign basic details
    //     props.actions.genericAction('campaignDetailSpecificFields', [['tapfluencer_campaign.campaign_name as campaignName', 'tapfluencer_campaign_filter.city_id as cityId'], props.match.params.id])
    //     .then(res => setCampaignDetails(res.data))
    // }, []);

    // const validateIfGreater = (value, compareWith) => {
    //     return value > compareWith;
    // }

    const onSubmit = (data) => {
        const panelId = props.match.params.id;
        data['panelId'] = panelId;
        props.actions.brandPanelFilterSave(data)
        .then(res => {
            if (!res.err) window.location = `/brand/panel/${panelId}/preview`;
        })
        //validations
        // if(!data.countryId)
        //     return notification.send({device: props.rdx.appReducer.deviceType, message: 'You need to set a specific country!', type: 'error'});
        //atleast one social filter
        // if(!(data.ifFbFollowerCount || data.ifFbPageFollowerCount || data.ifInstaFollowerCount || data.ifTwitterFollowerCount || data.ifYoutubeFollowerCount))
        //     return notification.send({device: props.rdx.appReducer.deviceType, message: 'You need to set atleast one social filter!', type: 'error'});
        //social range check
        // if(
        //     validateIfGreater(data.fbCountMin, data.fbCountMax) ||
        //     validateIfGreater(data.fbPageCountMin, data.fbPageCountMax) ||
        //     validateIfGreater(data.twitterCountMin, data.twitterCountMax) ||
        //     validateIfGreater(data.instaCountMin, data.instaCountMax) ||
        //     validateIfGreater(data.youtubeCountMin, data.youtubeCountMax)
        // )
        //     return notification.send({device: props.rdx.appReducer.deviceType, message: 'min count should be less than or equal to max!', type: 'error'});
        //else
        // data['campaignId'] = props.match.params.id;
        // props.actions.brandCampaignFilterSave(data)
        // .then(res => {
        //     if(!res.err) window.location = `/brand/campaign/${props.match.params.id}/preview`;
        // });
    }  

    return (
        <BaseLayout leftCards={[
            <CurrentPanelSidebar panelId={props.match.params.id} currentTitle='Filter'/>
        ]}>
            <h1>Filter - Panel Job</h1>
            <FilterForm onSubmit={onSubmit}
                    values={ props.rdx.panelReducer.currentFilter ? props.rdx.panelReducer.currentFilter : {} }
                    targetCountFetchAction={props.actions.brandPanelFilterTargetCount} 
            />
        </BaseLayout>
            
            // <div className="target-count">               

            //     <FilterForm onSubmit={onSubmit}
            //         values={ props.rdx.brandReducer.currentFilter ? props.rdx.brandReducer.currentFilter : {} }
            //         targetCountFetchAction={props.actions.brandCampaignFilterTargetCount} 
            //         campaignDetailsCityId={campaignDetails ? campaignDetails.cityId : null}
            //     />
            // </div>
        // </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => {
    return {
        rdx: state
    }
}

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandPanelFilterSave,
        genericAction,
        brandPanelFilterTargetCount
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Filter);