import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginComponent from './components/Static/Login';
import InfluencerLoginContainer from './containers/Influencer/Login';
import BrandSignupContainer from './containers/Brand/Signup';
import BrandAdminPasswordResetContainer from './containers/Brand/Admin/Password/Reset';
import BrandHomeContainer from './containers/Brand/Home';
import InfluencerHomeContainer from './containers/Influencer/Home';
import InfluencerCampaignListContainer from './containers/Influencer/Campaign/List';
import InfluencerCampaignDetailContainer from './containers/Influencer/Campaign/Detail';
import InfluencerCampaignsAppliedContainer from './containers/Influencer/Campaign/Applied';
import InfluencerRegistrationProfileContainer from './containers/Influencer/Registration/Profile';
import InfluencerRegistrationKidsContainer from './containers/Influencer/Registration/Kids';
import InfluencerProfileConnectContainer from './containers/Influencer/Profile/Connect';
import InfluencerProfileEditContainer from './containers/Influencer/Profile/Edit';
import InfluencerProfileSocialContainer from './containers/Influencer/Profile/Social';
import InfluencerCampaignModerationContainer from './containers/Influencer/Campaign/Moderation';
import InfluencerCampaignSubmissionContainer from './containers/Influencer/Campaign/Submission';
import InfluencerAboutContainer from './containers/Influencer/About';
import BrandEnquiryPageContainer from './containers/Brand/BrandEnquiryPage';
import InfluencerFeedbackContainer from './containers/Influencer/Feedback';
// import DebugContainer from './containers/Influencer/Debug';

import BrandCampaignAllContainer from './containers/Brand/Campaign/All';
import BrandCampaignListContainer from './containers/Brand/Campaign/List';
import BrandCampaignStatsContainer from './containers/Brand/Campaign/Stats';
import BrandCampaignCreateContainer from './containers/Brand/Campaign/Create';
import BrandCampaignEditContainer from './containers/Brand/Campaign/Edit';
import BrandCampaignFilterContainer from './containers/Brand/Campaign/Filter';
import BrandCampaignPreviewContainer from './containers/Brand/Campaign/Preview';
import BrandCampaignManagerContainer from './containers/Brand/Campaign/Manager';
import BrandCampaignsApplicationsAllContainer from './containers/Brand/Applications/CampaignsAll';
import BrandCampaignsApplicationsPendingContainer from './containers/Brand/Applications/CampaignsPending';
import BrandCampaignApplicationsContainer from './containers/Brand/Campaign/Applications';
import BrandCampaignScreeningContainer from './containers/Brand/Campaign/Screening';
import BrandCampaignApplicantContainer from './containers/Brand/Campaign/Applicant';
import BrandCampaignModerationContainer from './containers/Brand/Campaign/Moderation';
import BrandCampaignSubmissionsContainer from './containers/Brand/Campaign/Submissions';
import BrandCampaignGradingContainer from './containers/Brand/Campaign/Grading';
import BrandCampaignApplicantSubmissionsContainer from './containers/Brand/Campaign/Submissions/Individual';
import BrandCampaignPaymentsContainer from './containers/Brand/Campaign/Payments';
import BrandCampaignLinkStatsContainer from './containers/Brand/Campaign/LinkStats';
import BrandCampaignEmailTrackerContainer from './containers/Brand/Campaign/EmailTracker';
import BrandCampaignEmailTrackerLegacySentContainer from './containers/Brand/Campaign/EmailTracker/Legacy/Sent';
import BrandCampaignEmailTrackerLegacyOpenedContainer from './containers/Brand/Campaign/EmailTracker/Legacy/Opened';
import BrandCampaignFeedbackAnswersContainer from './containers/Brand/Campaign/Feedback/Answers';
import BrandAllInfluencerUsersContainer from './containers/Brand/Users';
import BrandAllInfluencerUsersDownloadContainer from './containers/Brand/Users/Download/index';
import BrandAllPanelUsersContainer from './containers/Brand/Users/Panel/All';
import BrandUserProfileContainer from './containers/Brand/User/Profile';
import BrandAllAdminsContainer from './containers/Brand/Admins';
import BrandOtherContainer from './containers/Brand/Other';
import BrandInfluencerPaymentHistoryContainer from './containers/Brand/Payments/History';
import BrandInfluencerGradingHistoryContainer from './containers/Brand/Grading/History';
import BrandInfluencerFeedbackContainer from './containers/Brand/Feedback';

import BrandPanelCreateContainer from './containers/Brand/Panel/Create';
import BrandPanelAllContainer from './containers/Brand/Panel/All';
import BrandPanelPreviewContainer from './containers/Brand/Panel/Preview';
import BrandPanelFilterContainer from './containers/Brand/Panel/Filter';
import BrandPanelEditContainer from './containers/Brand/Panel/Edit';
import BrandPanelHomeContainer from './containers/Brand/Panel/Home';
import BrandPanelSubmissionsContainer from './containers/Brand/Panel/Submissions';
import BrandPanelEmailTrackerContainer from './containers/Brand/Panel/EmailTracker';
// import BrandRemoteContainer from './containers/Brand/Remote';

import BrandCreditsEvoucherAll from './containers/Brand/Credits/Evoucher/All';
import BrandCreditsEvoucherAdd from './containers/Brand/Credits/Evoucher/Add';
import BrandCreditsEvoucherPreview from './containers/Brand/Credits/Evoucher/Preview';
import brandCreditsEvoucherProviderAll from './containers/Brand/Credits/Evoucher/Provider/All';

import MobWelcomeContainer from './containers/Mob/Welcome';
import MobFaqContainer from './containers/Mob/Faq';
import MobHowToContainer from './containers/Mob/HowTo';
import MobCampaignDetailContainer from './containers/Mob/Campaign/Detail';
import MobCampaignModerationContainer from './containers/Mob/Campaign/Moderation';
import MobCampaignSubmissionContainer from './containers/Mob/Campaign/Submission';
import MobCampaignTermsAndConditionsContainer from './containers/Mob/Campaign/TermsAndConditions';
import MobCampaignEmptyContainer from './containers/Mob/Campaign/Empty';
import Mobile404Template from './components/Mob/404';
import MobileLoadingTemplate from './components/Mob/Loading';
import MobAuthTiktokContainer from './containers/Mob/Auth/Tiktok';
import MobPanelDetailContainer from './containers/Mob/Panel/Detail';
import mobWelcomeFaqContainer from './containers/Mob/Welcome/Faq';
import mobWelcomeFeedbackContainer from './containers/Mob/Welcome/Feedback';
import MobWelcomeV1Container from './containers/Mob/WelcomeV1';

import StatsHomeContainer from './containers/Stats';

import Template404 from './components/Static/404';

import 'antd/dist/antd.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './style.css';

const App = () => (
    <Switch>
        <Route path="/" component={InfluencerCampaignListContainer} exact />
        <Route path="/login" component={LoginComponent} />
        {/*---------------------------influencer---------------------------------*/}
        <Route path="/influencer/login" component={InfluencerLoginContainer} />
        <Route path="/influencer/register/profile" component={InfluencerRegistrationProfileContainer} />
        <Route path="/influencer/register/kids" component={InfluencerRegistrationKidsContainer} />
        <Route path="/influencer/campaigns" component={InfluencerCampaignListContainer} exact />
        <Route path="/influencer/campaign/:id" component={InfluencerCampaignDetailContainer} exact />
        <Route path="/influencer/campaigns/applied" component={InfluencerCampaignsAppliedContainer} />
        <Route path="/influencer/profile/connect" component={InfluencerProfileConnectContainer} />
        <Route path="/influencer/profile/edit" component={InfluencerProfileEditContainer} />
        <Route path="/influencer/profile/social/:type" component={InfluencerProfileSocialContainer} />
        <Route path="/influencer/campaign/:id/moderation" component={InfluencerCampaignModerationContainer} />
        <Route path="/influencer/campaign/:id/submission" component={InfluencerCampaignSubmissionContainer} />
        <Route path="/influencer/about" component={InfluencerAboutContainer} />
        <Route path="/brand/enquiry" component={BrandEnquiryPageContainer} />
        <Route path="/influencer/feedback" component={InfluencerFeedbackContainer} />
        {/*<Route path="/influencer/debug" component={DebugContainer} />*/}        
        {/*--------------------------brand----------------------------------------*/}
        <Route path="/brand/home" component={BrandHomeContainer} exact />
        <Route path="/brand/admin/signup" component={BrandSignupContainer} />
        <Route path="/brand/admin/password/reset" component={BrandAdminPasswordResetContainer} />
        <Route path="/brand/campaign/all" component={BrandCampaignAllContainer} />
        <Route path="/brand/campaign/list" component={BrandCampaignListContainer} />
        <Route path="/brand/campaign/create" component={BrandCampaignCreateContainer} />
        <Route path="/brand/campaigns/applications/all" component={BrandCampaignsApplicationsAllContainer} />
        <Route path="/brand/campaigns/applications/pending" component={BrandCampaignsApplicationsPendingContainer} />
        <Route path="/brand/campaign/:id/home" component={BrandCampaignStatsContainer} />
        <Route path="/brand/campaign/:id/edit" component={BrandCampaignEditContainer} />
        <Route path="/brand/campaign/:id/filter" component={BrandCampaignFilterContainer} />
        <Route path="/brand/campaign/:id/preview" component={BrandCampaignPreviewContainer} />
        <Route path="/brand/campaign/:id/manager" component={BrandCampaignManagerContainer} />        
        <Route path="/brand/campaign/:id/applications" component={BrandCampaignApplicationsContainer} />
        <Route path="/brand/campaign/:id/screening" component={BrandCampaignScreeningContainer} />
        <Route path="/brand/campaign/:campaignId/applicant/:influencerId" component={BrandCampaignApplicantContainer} exact />
        <Route path="/brand/campaign/:id/moderation" component={BrandCampaignModerationContainer} />
        <Route path="/brand/campaign/:id/submissions" component={BrandCampaignSubmissionsContainer} />
        <Route path="/brand/campaign/:id/grading" component={BrandCampaignGradingContainer} />
        <Route path="/brand/campaign/:id/applicant/:influencerId/submissions" component={BrandCampaignApplicantSubmissionsContainer} />
        <Route path="/brand/campaign/:id/payments" component={BrandCampaignPaymentsContainer} />
        <Route path="/brand/campaign/:id/link/stats" component={BrandCampaignLinkStatsContainer} />
        <Route path="/brand/campaign/:id/email/tracker" component={BrandCampaignEmailTrackerContainer} exact />
        <Route path="/brand/campaign/:id/email/tracker/legacy/opened" component={BrandCampaignEmailTrackerLegacyOpenedContainer} />
        <Route path="/brand/campaign/:id/email/tracker/legacy/sent" component={BrandCampaignEmailTrackerLegacySentContainer} />
        <Route path="/brand/campaign/:id/feedback/answers" component={BrandCampaignFeedbackAnswersContainer} />
        <Route path="/brand/users/influencer/all" component={BrandAllInfluencerUsersContainer} />
        <Route path="/brand/users/influencer/download" component={BrandAllInfluencerUsersDownloadContainer} />
        <Route path="/brand/users/panel/all" component={BrandAllPanelUsersContainer} />
        <Route path="/brand/admins/all" component={BrandAllAdminsContainer} />
        <Route path="/brand/user/:id" component={BrandUserProfileContainer} exact />
        <Route path="/brand/other" component={BrandOtherContainer} />
        <Route path="/brand/user/:influencerId/payment/history" component={BrandInfluencerPaymentHistoryContainer} />
        <Route path="/brand/user/:influencerId/grading/history" component={BrandInfluencerGradingHistoryContainer} />
        <Route path="/brand/influencer/feedback" component={BrandInfluencerFeedbackContainer} />
        {/* ------------------ Panel --------------------- */}
        <Route path="/brand/panel/create" component={BrandPanelCreateContainer} />
        <Route path="/brand/panel/all" component={BrandPanelAllContainer} />
        <Route path="/brand/panel/:id/preview" component={BrandPanelPreviewContainer} />
        <Route path="/brand/panel/:id/filter" component={BrandPanelFilterContainer} />
        <Route path="/brand/panel/:id/edit" component={BrandPanelEditContainer} />
        <Route path="/brand/panel/:id/home" component={BrandPanelHomeContainer} />
        <Route path="/brand/panel/:id/submissions" component={BrandPanelSubmissionsContainer} />
        <Route path="/brand/panel/:id/email/tracker" component={BrandPanelEmailTrackerContainer} />
        {/*<Route path="/brand/remote" component={BrandRemoteContainer} />*/}

        {/* ------------------ Credits ------------------- */}
        <Route path="/brand/credits/evoucher/all" component={BrandCreditsEvoucherAll} />
        <Route path="/brand/credits/evoucher/add" component={BrandCreditsEvoucherAdd} />
        <Route path="/brand/credits/evoucher/preview/:id" component={BrandCreditsEvoucherPreview} />
        <Route path="/brand/credits/evoucher/provider/all" component={brandCreditsEvoucherProviderAll} />

        {/*--------------------------mob----------------------------------------*/}
        <Route path="/mob/welcome" component={MobWelcomeContainer} exact />
        <Route path="/mob/faq" component={MobFaqContainer} exact />
        <Route path="/mob/how" component={MobHowToContainer} exact />
        <Route path="/mob/campaign/empty" component={MobCampaignEmptyContainer} exact />
        <Route path="/mob/campaign/:id" component={MobCampaignDetailContainer} exact />
        <Route path="/mob/campaign/:id/moderation" component={MobCampaignModerationContainer} exact />
        <Route path="/mob/campaign/:id/submission" component={MobCampaignSubmissionContainer} exact />
        <Route path="/mob/campaign/:campaignId/terms-and-conditions" component={MobCampaignTermsAndConditionsContainer} exact />
        <Route path="/mob/panel/detail/:id" component={MobPanelDetailContainer} exact />
        <Route path="/mob/welcome/faq" component={mobWelcomeFaqContainer} exact />
        <Route path="/mob/welcome/feedback" component={mobWelcomeFeedbackContainer} exact />
        <Route path="/mob/404" component={Mobile404Template} exact />
        <Route path="/mob/loading" component={MobileLoadingTemplate} exact />
        <Route path="/mob/auth/tiktok" component={MobAuthTiktokContainer} exact />
        <Route path={"/mob/v1/welcome"} component={MobWelcomeV1Container} exact/>
        {/*--------------------------stats----------------------------------------*/}
        <Route path="/stats/home" component={StatsHomeContainer} exact />
        <Route path='/404' component={Template404} />
        <Route component={Template404} />
    </Switch>
);

export default App;