import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash/throttle';
// import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { loadState, saveState } from './sessionStorage';
import allReducers from '../reducers/index';

export default function configureStore(serverState) {
    const enhancers = [];
    const middleware = [
        thunk,
      // routerMiddleware(history)
    ];
    //dev tools
    if (process.env.NODE_ENV === 'development' && typeof window != 'undefined') {
        const devToolsExtension = window.devToolsExtension;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }
    //enhancers
    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    )
    //server state
    const persistedState = loadState(serverState);

    const store = createStore(
        allReducers,
        persistedState,
        composedEnhancers
    )

    // store.subscribe(throttle(() => {
    //     saveState({
    //         userReducer: store.getState().userReducer,
    //     });
    // }, 1000));

    return store;
}
