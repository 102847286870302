import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { Icon } from 'antd';
import { useCitiesList, useCountryList, useCountryOptions } from '../../../../hooks/generic';
import { Tween, Timeline } from 'react-gsap';
import MultiSelect from '../../../Common/MultiSelect';
import { stateListFetch, stateCitiesListFetch } from '../../../../actions';
import {
    genderOptions,
    ethnicityOptions,
    maritalOptions,
    educationOptions,
    occupationOptions,
    spouseEducationOptions,
    spouseOccupationOptions,
    wageEarnerOptions,
    incomeOptions,
    numKidsOptions,
    languageOptions,
    pregnancyStatusOptions
} from './options';
import RangeSelector from '../../../UIElems/RangeSelector';

const Filter = ({ onSubmit, targetCountFetchAction, values }) => {

    const dV = (key, values, defaultVal) => {
        return values[key] !== null && values[key] !== undefined ? values[key] : defaultVal;
    }

    const constructKidRangeValue = () => {
        return ('kidAgeFrom' in values && 'kidAgeTo' in values)
            ? { min: values.kidAgeFrom, max: values.kidAgeTo }
            : { min: 0, max: 20 };
    }

    const [id, setId] = useState(dV('id', values, null));
    const [targetCount, setTargetCount] = useState(0);
    const [gender, setGender] = useState(dV('gender', values, []));
    const [ethnicity, setEthnicity] = useState(dV('ethnicity', values, []));
    const [countryId, setCountryId] = useState(dV('countryId', values, []));
    const [stateId, setStateId] = useState(dV('stateId', values, []));
    const [cityId, setCityId] = useState(dV('cityId', values, []));
    const [maritalStatus, setMaritalStatus] = useState(dV('maritalStatus', values, []));
    const [educationProfile, setEducationProfile] = useState(dV('educationProfile', values, []));
    const [occupationProfile, setOccupationProfile] = useState(dV('occupationProfile', values, []));
    const [chiefWageEarner, setChiefWageEarner] = useState(dV('chiefWageEarner', values, []));
    const [spouseEducationProfile, setSpouseEducationProfile] = useState(dV('spouseEducationProfile', values, []));
    const [spouseOccupationProfile, setSpouseOccupationProfile] = useState(dV('spouseOccupationProfile', values, []));
    const [monthlyHouseholdIncome, setMonthlyHouseholdIncome] = useState(dV('monthlyHouseholdIncome', values, []));
    const [numOfKids, setNumOfKids] = useState(dV('numOfKids', values, []));
    const [languages, setLanguages] = useState(dV('languages', values, []));
    const [pregnancyStatus, setPregnancyStatus] = useState(dV('pregnancyStatus', values, []));
    const [filterString, setFilterString] = useState([]);
    const [isPregnant, setIsPregnant] = useState(dV('isPregnant', values, 2));
    const [kidAgeRange, setKidAgeRange] = useState(constructKidRangeValue());
    const [countryRows, setCountryRows] = useState([getNewCountryRow()]);
    const [isKidAgeRequired, setIsKidAgeRequired] = useState(dV('isKidAgeRequired', values, false));

    const countryList = useCountryList();

    setSelectedValues(genderOptions, gender);
    setSelectedValues(ethnicityOptions, ethnicity);
    setSelectedValues(maritalOptions, maritalStatus);
    setSelectedValues(educationOptions, educationProfile);
    setSelectedValues(occupationOptions, occupationProfile);
    setSelectedValues(wageEarnerOptions, chiefWageEarner);
    setSelectedValues(spouseOccupationOptions, spouseOccupationProfile);
    setSelectedValues(spouseEducationOptions, spouseEducationProfile);
    setSelectedValues(numKidsOptions, numOfKids);
    setSelectedValues(pregnancyStatusOptions, pregnancyStatus);

    function setSelectedValues(options, values) {
        values.forEach(value => {
            options.find(opt => opt.value === value).selected = true;
        });
    }

    function setInitialCountryRows(countrySpecificFilters) {
        if (countrySpecificFilters && Array.isArray(countrySpecificFilters) && countrySpecificFilters.length > 0) {
            const allPromises = [];
            countrySpecificFilters.forEach(filter => {
                const promise = new Promise((resolve, reject) => {
                    if (filter.countryId && filter.countryId !== '') {
                        const rowPromise = [];
                        const statePromise = stateListFetch(filter.countryId).then(data => data.data);
                        rowPromise.push(statePromise);
                        if (filter.stateIds && filter.stateIds.length > 0) {
                            const cityPromise = stateCitiesListFetch(filter.stateIds).then(data => data.data);
                            rowPromise.push(cityPromise);
                        }
                        Promise.all(rowPromise).then(results => {
                            const row = {
                                country: filter.countryId,
                                state: filter.stateIds && filter.stateIds.length > 0? filter.stateIds: [],
                                stateOptions: results[0].map(stateObj => ({
                                    label: stateObj.stateName,
                                    value: stateObj.stateId,
                                    selected: filter.stateIds.includes(stateObj.stateId)
                                })),
                                city: filter.cityIds && filter.cityIds.length > 0? filter.cityIds: [],
                                cityOptions: results[1] && results[1].length > 0? results[1].map(cityObj => ({
                                    label: cityObj.cityName,
                                    value: cityObj.cityId,
                                    selected: filter.stateIds.includes(cityObj.cityId)
                                })): [],
                                ethnicity: filter.ethnicity && filter.ethnicity.length > 0? filter.ethnicity: [],
                                ethnicityOptions: [129, 192].includes(filter.countryId)? ethnicityOptions: [],
                                languages: filter.languages && filter.languages.length > 0? filter.languages: [],
                                languageOptions: languageOptions[filter.countryId],
                                income: filter.monthlyHouseholdIncome && filter.monthlyHouseholdIncome.length > 0? filter.monthlyHouseholdIncome: [],
                                incomeOptions: incomeOptions[filter.countryId]
                            };
                            setSelectedValues(row.ethnicityOptions, row.ethnicity);
                            setSelectedValues(row.languageOptions, row.languages);
                            setSelectedValues(row.incomeOptions, row.income);
                            resolve(row);
                        });
                    } else {
                        resolve([getNewCountryRow()]);
                    }
                });
                allPromises.push(promise);
            });
            Promise.all(allPromises).then(rows => {
                setCountryRows([...rows]);
            });
        } else {
            setCountryRows([getNewCountryRow()]);
        }
    }

    function getNewCountryRow() {
        return {
            country: '',
            state: [],
            stateOptions: [],
            city: [],
            cityOptions: [],
            ethnicity: [],
            ethnicityOptions: [],
            languages: [],
            languageOptions: [],
            income: [],
            incomeOptions: []
        };
    }

    const addCountryRow = () => {
        setCountryRows(rows => [...rows, getNewCountryRow()]);
    }

    const removeCountryRow = i => {
        setCountryRows(rows => {
            const newRows = [...rows];
            newRows.splice(i, 1);
            return newRows;
        });
    }

    const countryValueChanged = (val, i) => {
        if (val && val !== '') {
            stateListFetch(val).then(data => {
                setCountryRows(rows => {
                    return rows.map((row, index) => {
                        if (index === i) {
                            const newRow = {...row};
                            newRow.country = Number(val);
                            newRow.stateOptions = data.data.map(state => ({
                                label: state.stateName,
                                value: state.stateId,
                                selected: false
                            }));
                            if ([129, 192].includes(newRow.country)) {
                                newRow.ethnicityOptions = [...ethnicityOptions];
                            } else {
                                newRow.ethnicityOptions = [];
                            }
                            newRow.languageOptions = [...languageOptions[newRow.country]];
                            newRow.incomeOptions = [...incomeOptions[newRow.country]];
                            return newRow;
                        } else {
                            return {...row};
                        }
                    });
                });
            });
        }
    }

    const stateValueChanged = (values, i) => {
        stateCitiesListFetch(values).then(data => {
            setCountryRows(rows => {
                return rows.map((row, index) => {
                    if (index === i) {
                        const newRow = {...row};
                        newRow.state = [...values];
                        if (values && values.length > 0) {
                            newRow.cityOptions = data.data.map(city => ({
                                label: city.cityName,
                                value: city.cityId,
                                selected: false
                            }));
                        } else {
                            newRow.cityOptions = [];
                            newRow.city = [];
                        }
                        return newRow;
                    } else {
                        return {...row};
                    }
                });
            });
        });
    }

    const cityValueChanged = (values, i) => {
        setCountryRows(rows => {
            return rows.map((row, index) => {
                if (index === i) {
                    const newRow = {...row};
                    newRow.city = [...values];
                    return newRow;
                } else {
                    return {...row};
                }
            })
        });
    }
    
    const ethncityValueChanged = (values, i) => {
        setCountryRows(rows => {
            return rows.map((row, index) => {
                if (index === i) {
                    const newRow = {...row};
                    newRow.ethnicity = [...values];
                    return newRow;
                } else {
                    return {...row};
                }
            })
        });
    }

    const languageValueChanged = (values, i) => {
        setCountryRows(rows => {
            return rows.map((row, index) => {
                if (index === i) {
                    const newRow = {...row};
                    newRow.languages = [...values];
                    return newRow;
                } else {
                    return {...row};
                }
            })
        });
    }

    const incomeValueChanged = (values, i) => {
        setCountryRows(rows => {
            return rows.map((row, index) => {
                if (index === i) {
                    const newRow = {...row};
                    newRow.income = [...values];
                    return newRow;
                } else {
                    return {...row};
                }
            })
        });
    }

    const onTargetCountFetch = (data) => {
        targetCountFetchAction(data)
            .then(res => {
                if (!res.err && res.data)
                    setTargetCount(res.data.total);
            });
    }

    const TimelineComponent = ({ children }) => (
        <Timeline
            target={children}>
            <Tween from={{ x: 0, y: -150, fontSize: 20 }} to={{ x: 0, y: 0, fontSize: 16 }}
                duration={2}
                ease="Back.easeInOut" />
        </Timeline>
    );

    const onFormSubmit = (e) => {
        e.preventDefault();
        const countrySpecificFilters = countryRows.map(row => ({
            countryId: row.country,
            stateIds: row.state,
            cityIds: row.city,
            ethnicity: row.ethnicity,
            languages: row.languages,
            monthlyHouseholdIncome: row.income
        }));
        onSubmit({
            gender,
            maritalStatus,
            educationProfile,
            occupationProfile,
            chiefWageEarner,
            spouseEducationProfile,
            spouseOccupationProfile,
            numOfKids,
            isPregnant,
            countrySpecificFilters,
            pregnancyStatus,
            kidAgeFrom: kidAgeRange['min'],
            kidAgeTo: kidAgeRange['max'],
            isKidAgeRequired
        });
    }

    const injectChangeHook = (arg, callback) => {
        callback(arg);
        // setTimeout(fetchUpdatedFilterResults, 500);
    }

    const fetchUpdatedFilterResultCount = () => {
        onTargetCountFetch({
            gender,
            ethnicity,
            countryId,
            stateId,
            cityId,
            maritalStatus,
            educationProfile,
            occupationProfile,
            chiefWageEarner,
            spouseEducationProfile,
            spouseOccupationProfile,
            monthlyHouseholdIncome,
            numOfKids,
            languages,
            pregnancyStatus,
            childAge: {
                minimum: kidAgeRange['min'],
                maximum: kidAgeRange['max']
            },
            isKidAgeRequired
        });
    }

    const numOfKidsChanged = opts => {
        if (!opts.length) {
            setKidAgeRange({
                min: 0,
                max: 20
            });
        }
        setNumOfKids(opts);
    }

    // on filter change
    useEffect(() => {
        fetchUpdatedFilterResultCount();
        //filter link
        // setFilterString(qs.stringify({
        //     countryId,
        //     cityId,
        //     gender,
        //     ageRangeMin: ageRange['min'],
        //     ageRangeMax: ageRange['max'],
        //     ifKidFilter,
        //     kidGender,
        //     kidAgeRangeMin: kidAgeRange['min'],
        //     kidAgeRangeMax: kidAgeRange['max'],
        //     fbCountMin,
        //     fbCountMax,
        //     fbPageCountMin,
        //     fbPageCountMax,
        //     twitterCountMin,
        //     twitterCountMax,
        //     instaCountMin,
        //     instaCountMax,
        //     youtubeCountMin,
        //     youtubeCountMax,
        //     ifPregnantFilter,
        //     ifFbFollowerCount,
        //     ifFbPageFollowerCount,
        //     ifInstaFollowerCount,
        //     ifTwitterFollowerCount,
        //     ifYoutubeFollowerCount
        // }));
    }, [
        gender,
        ethnicity,
        countryId,
        stateId,
        cityId,
        maritalStatus,
        educationProfile,
        occupationProfile,
        chiefWageEarner,
        spouseEducationProfile,
        spouseOccupationProfile,
        monthlyHouseholdIncome,
        numOfKids,
        languages,
        pregnancyStatus,
        kidAgeRange,
        isKidAgeRequired
    ]);

    useEffect(() => {
        const countries = [];
        let states = [];
        let cities = [];
        let eths = [];
        let langs = [];
        let incomes = [];
        countryRows.forEach(row => {
            row.country !== ''? countries.push(Number(row.country)): null;
            row.state? states = [...states, ...row.state]: null;
            row.city? cities = [...cities, ...row.city]: null;
            row.ethnicity? eths = [...eths, ...row.ethnicity]: null;
            row.languages? langs = [...langs, ...row.languages]: null;
            row.income? incomes = [...incomes, ...row.income]: null;
        });
        setCountryId(countries);
        setStateId(states);
        setCityId(cities);
        setEthnicity(eths);
        setLanguages(langs);
        setMonthlyHouseholdIncome(incomes);
    }, [countryRows])

    useEffect(() => {
        setInitialCountryRows(dV('countrySpecificFilters', values, []));
    }, []);

    return (
        <div className={cx("filter-wrap")}>
            <form onSubmit={onFormSubmit}>

                <div className={cx("target-count")}>
                    <h2>
                        People Targeted:&nbsp;
                        <TimelineComponent>
                            <span style={{ position: 'absolute', color: 'black' }}>
                                <a href={`/brand/users/all?filters=1&${filterString}`} target="_blank" style={{ textDecoration: 'underline' }}>{targetCount}</a>
                            </span>
                        </TimelineComponent>
                    </h2>
                </div>

                <div className={cx("category-filter")}>
                    <div className={cx("form-horizontal-row")}>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Gender</label>
                                <MultiSelect options={genderOptions} onChange={setGender} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Marital Status</label>
                                <MultiSelect options={maritalOptions} onChange={setMaritalStatus} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Education Profile</label>
                                <MultiSelect options={educationOptions} onChange={setEducationProfile} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Pregnancy Status</label>
                                <MultiSelect options={pregnancyStatusOptions} onChange={setPregnancyStatus} />
                            </div>
                        </div>
                    </div>


                    <div className={cx("form-horizontal-row")}>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Occupation Profile</label>
                                <MultiSelect options={occupationOptions} onChange={setOccupationProfile} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Chief Wage Earner</label>
                                <MultiSelect options={wageEarnerOptions} onChange={setChiefWageEarner} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Spouse Education Profile</label>
                                <MultiSelect options={spouseEducationOptions} onChange={setSpouseEducationProfile} />
                            </div>
                        </div>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-wrap")}>
                                <label htmlFor="">Spouse Occupation Profile</label>
                                <MultiSelect options={spouseOccupationOptions} onChange={setSpouseOccupationProfile} />
                            </div>
                        </div>
                    </div>

                    <div className={cx("form-horizontal-row")}>
                        <div className={cx("form-vertical-row .mb0")}>
                            <div className={cx("form-horizontal-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="">Number of Kids</label>
                                    <MultiSelect options={numKidsOptions} onChange={numOfKidsChanged} />
                                </div>
                                <div className="form-wrap">
                                    <div className="custom-checkbox-wrap">
                                        <input className="styled-checkbox" id="chk-child-filters" type="checkbox" checked={isKidAgeRequired} onChange={e => setIsKidAgeRequired(e.target.checked)} />
                                        <label for="chk-child-filters">Age: </label>
                                    </div>
                                    <div className={cx("form-row-wrap")}>
                                        {
                                            isKidAgeRequired &&
                                            <RangeSelector
                                                maxValue={20}
                                                minValue={0}
                                                value={kidAgeRange}
                                                onChange={val => injectChangeHook(val, setKidAgeRange)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {countryRows.map((row, i) => (
                        <div className="country-info">
                            <div className={cx("form-horizontal-row")}>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">Country</label>
                                        <select defaultValue="" value={row.country} onChange={e => countryValueChanged(e.target.value, i)}>
                                            <option value="" disabled></option>
                                            {countryList.filter(country => [100, 129, 169, 192, 211, 232].includes(country.id))
                                                .map(country => <option value={country.id}>{country.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">State</label>
                                        <MultiSelect key={row.stateOptions} options={row.stateOptions} onChange={values => stateValueChanged(values, i)} />
                                    </div>
                                </div>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">City</label>
                                        <MultiSelect key={row.cityOptions} options={row.cityOptions} onChange={values => cityValueChanged(values, i)} />
                                    </div>
                                </div>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">Monthly Household Income</label>
                                        <MultiSelect key={row.incomeOptions} options={row.incomeOptions} onChange={values => incomeValueChanged(values, i)} />
                                    </div>
                                </div>
                            </div>

                            <div className={cx("form-horizontal-row")}>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">Ethnicity</label>
                                        <MultiSelect key={row.ethnicityOptions} options={row.ethnicityOptions} onChange={values => ethncityValueChanged(values, i)} />
                                    </div>
                                </div>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <label htmlFor="">Languages</label>
                                        <MultiSelect key={row.languageOptions} options={row.languageOptions} onChange={values => languageValueChanged(values, i)} />
                                    </div>
                                </div>
                                <span className={cx("flex-spacer")}></span>
                                <div className={cx("form-vertical-row .mb0")}>
                                    <div className={cx("form-wrap")}>
                                        <button className={cx("btn-common btn-red")} onClick={e => removeCountryRow(i)}><Icon type="delete" theme="outlined" />Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={cx("form-horizontal-row")}>
                        <button type="button" className={cx("btn-common btn-grey")} onClick={addCountryRow}><b>+</b> Add country</button>
                    </div>
                </div>

                <hr />

                <div className={cx("button-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">Save</button>
                    <button className={cx("btn", "btn-light")}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default Filter;