import React from "react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import WelcomeV1 from "../../../components/Mob/WelcomeV1";

const MobWelcomeV1Container = (props) => {
    return (
        <WelcomeV1
            data={props.rdx ? props.rdx.webviewReducer : null}
            t={props.t}
        />
    )
}

const mapStateToProps = (state, ownProps) => ({rdx: state});

const mapDispatchToProps = (dispatch, ownProps) => ({
    action: bindActionCreators({

    }, dispatch),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MobWelcomeV1Container))