import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { parseAsync } from 'json2csv';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import {
    usersDownloadFile,
    usersDownloadFileCheck
} from '../../../../actions';
import Lottie from 'react-lottie';

const Users = (props) => {
    const [disableDownload, setDisableDownload] = useState(true);
    let params = {};
    try {
        params = JSON.parse(decodeURIComponent(props.location.search.substring(1)));
    }
    catch (e) { }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('./../../../../../public/assets/lottie-animations/loader.json'),
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        if(disableDownload) {
            checkExistenceOfFile({ params });
        }
    });

    const checkExistenceOfFile = ({ params }) => {

        props.actions.usersDownloadFileCheck({ params })
            .then(response => {
                onDownloadUsersFile({ response });
                setDisableDownload(!disableDownload);
            });
    }

    const onDownloadUsersFile = ({ response }) => {
        const fields = response.fields;
        const opts = { fields };
        parseAsync(response.rows, opts)
            .then(csv => {
                const url = window.URL.createObjectURL(new Blob([csv]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `users.csv`);
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    window.close();
                }, 2000);
            });
    }

    return (
        <BaseLayout leftCards={[]}>
            <div className={cx('all-users', 'last-col-manage-padding')}>
                <h1>Your file will be downloaded automatically</h1>
                <span className={'loader-animation'}>
                    <Lottie options={defaultOptions} />
                </span>
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({ rdx: state });

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        usersDownloadFile,
        usersDownloadFileCheck
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);
