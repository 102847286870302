import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import cx from 'classnames/bind';
// import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import {  } from '../../../../../hooks/generic';
import { createImagePreview } from '../../../../../utils';
import Switch from "react-switch";
import { parse } from 'date-fns';
import enGB from "date-fns/locale/en";

const Form = ({onSubmit, values, dueDateDefault, t}) => {
    const [totalKids, setTotalKids] = useState(0);
    const [ifExpecting, setIfExpecting] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [kidsData, setKidsData] = useState({
        1: {id: '', gender: '', birthDate: null, name: ''},
        2: {id: '', gender: '', birthDate: null, name: ''},
        3: {id: '', gender: '', birthDate: null, name: ''},
        4: {id: '', gender: '', birthDate: null, name: ''},
        5: {id: '', gender: '', birthDate: null, name: ''},
        6: {id: '', gender: '', birthDate: null, name: ''}
    });

    //existing kids and due date
    useEffect(() => {
        registerLocale("en", enGB);
        //kids
        let total = 0;
        let formattedValues = {};
        for(let k in values) {
            let i = parseInt(k);
            if(values[k].id) total++;
            formattedValues[i] = values[k];
            if(formattedValues[i]['birthDate'])
               formattedValues[i]['birthDate'] = parse(formattedValues[i]['birthDate']);
        }
        setTotalKids(total);
        setKidsData(formattedValues);
        //due date
        if(dueDateDefault) {
            setIfExpecting(true);
            setDueDate(parse(dueDateDefault));
        }
    }, []);    

    const onFormSubmit = (e) => {
        e.preventDefault();
        let data = Object.values(kidsData).filter(d => d.birthDate);
        for (let i in data) {
            data[i]['birthDate'] = format(data[i]['birthDate'], 'YYYY-MM-DD');
        }
        //submit
        onSubmit({
            kidsData: data,
            dueDate: dueDate ? format(dueDate, 'YYYY-MM-DD') : null
        });
    }

    return (
        <div className={cx("influencer-kids-registration-form")}>
            <h2>{t('registration_kids_complete_your_profile')}</h2>

            <form onSubmit={onFormSubmit}>
                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_kids_number_of_kids')}: </label>
                    <select id="total-kids" value={totalKids} onChange={e => setTotalKids(parseInt(e.target.value))} >
                        {
                            [...Array(6).keys()].map((key, i) => {
                                return <option value={i} key={i}>{i}</option>
                            })
                        }
                    </select>
                </div>
                
                <div>
                    {
                        [...Array(totalKids).keys()].map((key, i) => {
                            return <div key={i + 1}>
                                <KidElem childIndex={i + 1}
                                    kidsData={kidsData}
                                    setKidsData={setKidsData} 
                                    t={t} />
                            </div>
                        })
                    }                      
                </div>
                
                <div className={cx("form-group")}>
                    <label htmlFor="">{t('registration_kids_i_am_expecting')}</label>
                    <div className={cx("am-expecting-style")}>
                        <Switch
                        onChange={setIfExpecting}
                        checked={ifExpecting}
                        id="expecting-switch" />
                    </div>

                    {
                        ifExpecting &&
                        <div className={cx("form-group")}>
                            <label htmlFor="">{t('registration_kids_due_date')}: </label>
                            <DatePicker locale={"en"}
                                selected={dueDate}
                                onChange={dueDate => setDueDate(dueDate)}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={t('registration_kids_due_date')}
                                popperPlacement="top-end" />
                        </div>
                    }                
                </div>
                <div className={cx("button-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">{t('registration_kids_button_next')}</button>
                </div>
            </form>

        </div>        
    );
}

const KidElem = ({childIndex, kidsData, setKidsData, t}) => {
    const onKidDataChange = (childIndex, type, data) => {
        setKidsData({...kidsData, [childIndex]: {...kidsData[childIndex], [type]: data}});  
    }

    return (
        <div>
            <div>{t('registration_kids_child')} {childIndex} </div>

            <div className={cx("form-group")}>
                <label htmlFor="">{t('registration_kids_name')}: </label>
                <input type="text" value={kidsData[childIndex]['name']} onChange={e => onKidDataChange(childIndex, 'name', e.target.value)} required />
            </div>

            <div className={cx("form-group")}>
                <label htmlFor="">{t('registration_kids_gender')}: </label>
                <select id="gender" value={kidsData[childIndex]['gender']} onChange={e => onKidDataChange(childIndex, 'gender', e.target.value)} required>
                    <option value="">{t('registration_kids_please_select')}</option>
                    <option value="male">{t('registration_kids_male')}</option>
                    <option value="female">{t('registration_kids_female')}</option>
                    <option value="other">{t('registration_kids_other')}</option>
                </select>
            </div>
            <div className={cx("form-group")}>
                <label htmlFor="age">{t('registration_kids_dob')}: </label>
                <DatePicker locale={"en"}
                    showMonthDropdown
                    showYearDropdown
                    selected={kidsData[childIndex]['birthDate']}
                    onChange={birthDate => onKidDataChange(childIndex, 'birthDate', birthDate)}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t('registration_kids_dob')}
                    popperPlacement="top-end"
                    required />
            </div>
        </div>
    );
}

export default Form;