import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import ProfileElem from '../../../UIElems/Profile';
import ProfileIcon from '@material-ui/icons/AccountBox';

const Header = (props) => {

    return (
        <header className={cx("brand-header")}>

            <div className={cx("header-top")}>
                <div className={cx("h-left")}>
                    <div className={cx("logo-wrap")}>
                        <a href='/brand/home'>
                            <img className={cx('not-mobile', 'logo')} src={'https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/logo_brands.png'} 
                                height="44" width="170" alt="logo" />

                            <img className={cx('only-mobile', 'logo')} src={'https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/logo_brands.png'}
                                height="33" width="130" alt="logo" />
                        </a>
                    </div>                
                </div>

                <div className={cx("h-center")}>
                    {
                    // <nav className={cx("not-mobile")}>
                    //     <ul className={cx("flex", "flex-center")}>
                    //         <li><Link to='/' className={cx("")}>Home</Link></li>
                    //     </ul>       
                    // </nav>
                    }
                    {/*<div className={cx("header-search-wrap")}>
                        <input type="text" id="search" name="search" placeholder="Search" />
                    </div>*/}
                </div>

                <div className={cx("h-right")}>
                    {
                        props.rdx.brandReducer.brandId &&
                        <ProfileElem links={[
                            {handler: () => window.location = '/logout', title: 'logout'}
                        ]}>
                            <ProfileIcon />
                        </ProfileElem>
                    }
                </div>
            </div>

            <div className={cx("only-mobile", "menu-wrap")}>
                <nav>
                    <ul className={cx("flex", "flex-center")}>
                        <li><a href='/brand/home' className={cx("")}>Home</a></li>
                    </ul>
                </nav>
            </div>

            <div className={cx("only-mobile", "left-slide-panel")}>

                <ul>
                    <li><a href='/brand/home' className={cx("")}>Home</a></li>
                </ul>

                <div className={cx("download-app-section")}>
                    
                </div>
            </div>
            
        </header>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);