import React from 'react';
import { useState, useEffect } from 'react';
import { createImagePreview } from '../../../../../utils';
import cx from 'classnames/bind';
import { Typography, Tooltip, Icon } from 'antd';
import { translate } from 'react-i18next';

const { Text, Title, Paragraph } = Typography;

const POST_TYPES = {
    fb: 'Facebook',
    fbStory: 'Facebook Story',
    insta: 'Instagram',
    instaStory: 'Instagram Story',
    youtube: 'Youtube',
    twitter: 'Twitter'
};

const Create = ({postType, campaignId, influencerId, mode, onSubmit, notify, defaultValues, t = null}) => {

	const dV = (key, values, defaultVal) => {
		if(!values) return defaultVal;
        return values[key] ? values[key] : defaultVal;
    }

    const [name, setName] = useState(dV('name', defaultValues, ''));
	const [image, setImage] = useState(dV('image', defaultValues, ''));
    const [imagePreview, setImagePreview] = useState(dV('imagePreview', defaultValues, ''));
    const [caption, setCaption] = useState(dV('caption', defaultValues, ''));

    //set preview images
    useEffect(() => {
        if(mode == 'edit') {
            //image
            setImage(image);
            //preview
            setImagePreview(image)    
        }
    }, []);

	const onImageChange = (e) => {
        setImage(e.target.files[0]);
        createImagePreview(e.target.files[0], previewImage => setImagePreview(previewImage));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        //validation
        //caption
        if(!image) return notify(t('mob_moderation_kindly_update_the_image'), 'error');
        //caption
        if(!caption) return notify(t('mob_moderation_kindly_fill_in_the_caption'), 'error');
        // 
        onSubmit({
            ...((defaultValues && 'id' in defaultValues) && {id: defaultValues.id}),
            influencerId,
            campaignId,
            type: postType,
            name,
            image,
            caption
        });
    }

	return (
		<div>
			<form onSubmit={onFormSubmit}>
                <div className={cx("form-group")}>
                    <label htmlFor="name">{t("mob_moderation_name")}:&nbsp;
                        <Tooltip title={t('mob_moderation_name_of_this_template')}>
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} required />
                </div>

				<div className={cx("form-group")}>
		            <label htmlFor="image">{t("mob_moderation_image")}:&nbsp;
		                <Tooltip title={t('mob_moderation_upload_the_image_you_are_going_to_use')}>
		                    <Icon type="info-circle" theme="filled" />
		                </Tooltip>
		            </label>
		            <div className={cx("choose-file-wrap")}>
                        <span className={cx("btn-choose-file")}>
                            <input id="image" type="file" className={cx("choose-file")} onChange={onImageChange} />
                            <label htmlFor="">
                                <span>{t("mob_moderation_choose_file")}</span>
                                <span className={cx("upload-icon")}></span>
                            </label>
                        </span>
                        <span className={cx("img-ext")}>{t("mob_moderation_ext_support")} : jpeg, jpg & png</span>      
                    </div>
		        </div>
		        <div className={cx("image-preview")}>
		            { imagePreview && <img src={imagePreview} className={cx("logo-preview")} alt="image-preview" width="320" height="480" /> }
		        </div>

		        <div className={cx("form-group")}>
                    <label htmlFor="caption">{t("mob_moderation_caption")}:&nbsp;
                        <Tooltip title={t('moderation_write_the_caption_as_you_would')}>
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                    </label>
                    <Paragraph id="caption" editable={{onChange: setCaption}}>{caption}</Paragraph>
                </div>

		        <div className={cx("btn-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">{t("mob_moderation_save")}</button>
                </div>
			</form>
		</div>		
	);
}

export default translate()(Create);