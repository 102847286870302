import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import JobListTable from './List';
import BaseLayout from '../../../components/Layout/Brand/BaseLayout';
import { genericAction } from '../../../actions';

const BrandHome = (props) => {

    return (
        <BaseLayout leftCards={[]}>
            <div className="brand-home">
                <h1>Campaigns</h1>
                <JobListTable brandId={props.rdx.brandReducer.brandId}
                    conditions={[]}
                    extra={null} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandHome);

