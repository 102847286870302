import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { translate } from 'react-i18next';

const Feedback = ({t}) => {

    return (
        <div>
            <div className={cx("toggle-navigation")}>
                <div onClick={() => window.location = '/influencer/feedback'} className={cx("nav-heading")}>
                    <h3>
                        <span className={cx("sidebar-icon-spacing", "influ-sidebar-about-icon")}></span>
                        <span className={cx("navigation-title")}>Feedback</span>
                    </h3>
                </div>
            </div>
       </div>
    );
}

export default translate()(Feedback);