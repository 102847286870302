import React from "react";

const LineIndicator = ({ current, capacity }) => {
    return (
        <div className={"indicator"}>
            {
                <div className={current === 1 ? "hideline" : ""}></div>
            }
            <div className={"indicator-icon"}><div></div></div>
            {
                <div className={current === capacity ? "hide" : ""}></div>
            }
        </div>
    )
}

export default LineIndicator;