import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { useCountryList, useCitiesList } from '../../../../../hooks/generic';
import Switch from "react-switch";
import { Tween, Timeline } from 'react-gsap';
import qs from 'qs';
import { Tooltip } from 'antd';
import RangeSelector from '../../../../UIElems/RangeSelector';

const  Filter = ({onSubmit, targetCountFetchAction, values, campaignDetailsCityId = null}) => {    

    const dV = (key, values, defaultVal) => {
        return values[key] ? values[key] : defaultVal;
    }

    const constructRangeValue = () => {
        return ('ageFrom' in values && 'ageTo' in values) && (values.ageFrom && values.ageTo)
            ? { min: values.ageFrom, max: values.ageTo }
            : { min: 18, max: 40 };
    }

    const constructKidRangeValue = () => {
        return ('kidAgeFrom' in values && 'kidAgeTo' in values)
            ? { min: values.kidAgeFrom, max: values.kidAgeTo }
            : { min: 0, max: 20 };
    }

    const [id, setId] = useState(dV('id', values, null));
    const [ifKidFilter, setIfKidFilter] = useState(!!dV('ifKidFilter', values, 0));
    const [gender, setGender] = useState(dV('gender', values, ''));
    const [kidGender, setKidGender] = useState(dV('kidGender', values, ''));
    const [countryId, setCountryId] = useState(dV('countryId', values, ''));
    const [cityId, setCityId] = useState(campaignDetailsCityId);
    const [ageRange, setAgeRange] = useState(constructRangeValue);
    const [kidAgeRange, setKidAgeRange] = useState(constructKidRangeValue);
    const [fbCountMin, setFbCountMin] = useState(dV('fbCountMin', values, 0));
    const [fbCountMax, setFbCountMax] = useState(dV('fbCountMax', values, 10000000));
    const [fbPageCountMin, setFbPageCountMin] = useState(dV('fbPageCountMin', values, 0));
    const [fbPageCountMax, setFbPageCountMax] = useState(dV('fbPageCountMax', values, 10000000));
    const [instaCountMin, setInstaCountMin] = useState(dV('instaCountMin', values, 0));
    const [instaCountMax, setInstaCountMax] = useState(dV('instaCountMax', values, 10000000));
    const [twitterCountMin, setTwitterCountMin] = useState(dV('twitterCountMin', values, 0));
    const [twitterCountMax, setTwitterCountMax] = useState(dV('twitterCountMax', values, 10000000));
    const [youtubeCountMin, setYoutubeCountMin] = useState(dV('youtubeCountMin', values, 0));
    const [youtubeCountMax, setYoutubeCountMax] = useState(dV('youtubeCountMax', values, 10000000));    
    const [tiktokCountMin, setTiktokCountMin] = useState(dV('tiktokCountMin', values, 0));
    const [tiktokCountMax, setTiktokCountMax] = useState(dV('tiktokCountMax', values, 10000000));
    const [targetCount, setTargetCount] = useState(0);
    const [filterString, setFilterString] = useState('');
    const [ifPregnantFilter, setIfPregnantFilter] = useState(!!dV('ifPregnantFilter', values, 0));
    const [ifFbFollowerCount, setifFbFollowerCount] = useState(!!dV('ifFbFollowerCount', values, 0));
    const [ifFbPageFollowerCount, setifFbPageFollowerCount] = useState(!!dV('ifFbPageFollowerCount', values, 0));
    const [ifInstaFollowerCount, setifInstaFollowerCount] = useState(!!dV('ifInstaFollowerCount', values, 0));
    const [ifTwitterFollowerCount, setifTwitterFollowerCount] = useState(!!dV('ifTwitterFollowerCount', values, 0));
    const [ifYoutubeFollowerCount, setifYoutubeFollowerCount] = useState(!!dV('ifYoutubeFollowerCount', values, 0));
    const [ifTiktokFollowerCount, setifTiktokFollowerCount] = useState(!!dV('ifTiktokFollowerCount', values, 0));
    const [isModerationEnabled, setIsModerationEnabled] = useState(!!dV('isModerationEnabled', values, 0));

    
    const countryList = useCountryList();    

    const citiesList = useCitiesList(countryId); 

    const onTargetCountFetch = (data) => {
        targetCountFetchAction(data)
        .then(res => {
            if(!res.err && res.data)
                setTargetCount(res.data.total);
        });
    }

    const TimelineComponent = ({children}) => (
        <Timeline
                target={children}>
                <Tween from={{x: 0, y: -150, fontSize: 20}} to={{x:0, y: 0, fontSize: 16}}
                    duration={2}
                    ease="Back.easeInOut" />
        </Timeline>
    );

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            id,
            gender: gender ? gender : null,
            ifKidFilter: +ifKidFilter,
            kidGender: kidGender ? kidGender : null,
            countryId: countryId ? countryId : null,
            cityId: cityId || null,
            ageFrom: ageRange['min'],
            ageTo: ageRange['max'],
            kidAgeFrom: kidAgeRange['min'],
            kidAgeTo: kidAgeRange['max'],
            fbCountMin,
            fbCountMax,
            fbPageCountMin,
            fbPageCountMax,
            twitterCountMin,
            twitterCountMax,
            instaCountMin,
            instaCountMax,
            youtubeCountMin,
            youtubeCountMax,
            tiktokCountMin,
            tiktokCountMax,
            ifPregnantFilter: +ifPregnantFilter,
            ifFbFollowerCount: +ifFbFollowerCount,
            ifFbPageFollowerCount: +ifFbPageFollowerCount,
            ifInstaFollowerCount: +ifInstaFollowerCount,
            ifTwitterFollowerCount: +ifTwitterFollowerCount,
            ifYoutubeFollowerCount: +ifYoutubeFollowerCount,
            ifTiktokFollowerCount: +ifTiktokFollowerCount,
            isModerationEnabled: +isModerationEnabled
        });
    }

    const injectChangeHook = (arg, callback) => {
        callback(arg);
        // setTimeout(fetchUpdatedFilterResults, 500);
    }    

    const fetchUpdatedFilterResultCount = () => {
        onTargetCountFetch({
            id,
            gender: gender ? gender : null,
            ifChildFilter: +ifKidFilter,
            childGender: kidGender ? kidGender : null,
            countryId: countryId ? countryId : null,
            cityId: cityId || null,
            ageRange,
            childAgeRange: kidAgeRange,
            fbCountMin: parseInt(fbCountMin),
            fbCountMax: parseInt(fbCountMax),
            fbPageCountMin: parseInt(fbPageCountMin),
            fbPageCountMax: parseInt(fbPageCountMax),
            twitterCountMin: parseInt(twitterCountMin),
            twitterCountMax: parseInt(twitterCountMax),
            instaCountMin: parseInt(instaCountMin),
            instaCountMax: parseInt(instaCountMax),
            youtubeCountMin: parseInt(youtubeCountMin),
            youtubeCountMax: parseInt(youtubeCountMax),
            tiktokCountMin: parseInt(tiktokCountMin),
            tiktokCountMax: parseInt(tiktokCountMax),
            ifPregnantFilter: +ifPregnantFilter,
            ifFbFollowerCount: +ifFbFollowerCount,
            ifFbPageFollowerCount: +ifFbPageFollowerCount,
            ifInstaFollowerCount: +ifInstaFollowerCount,
            ifTwitterFollowerCount: +ifTwitterFollowerCount,
            ifYoutubeFollowerCount: +ifYoutubeFollowerCount,
            ifTiktokFollowerCount: +ifTiktokFollowerCount
        });
    }

    useEffect(() => {
        setCityId(campaignDetailsCityId);
    }, [campaignDetailsCityId])

    //on filter change
    useEffect(() => {
        fetchUpdatedFilterResultCount();
        //filter link
        setFilterString(qs.stringify({
            countryId,
            cityId,
            gender,
            ageRangeMin: ageRange['min'],
            ageRangeMax: ageRange['max'],
            ifKidFilter,
            kidGender,
            kidAgeRangeMin: kidAgeRange['min'],
            kidAgeRangeMax: kidAgeRange['max'],
            fbCountMin,
            fbCountMax,
            fbPageCountMin,
            fbPageCountMax,
            twitterCountMin,
            twitterCountMax,
            instaCountMin,
            instaCountMax,
            youtubeCountMin,
            youtubeCountMax,
            tiktokCountMin,
            tiktokCountMax,
            ifPregnantFilter,
            ifFbFollowerCount,
            ifFbPageFollowerCount,
            ifInstaFollowerCount,
            ifTwitterFollowerCount,
            ifYoutubeFollowerCount,
            ifTiktokFollowerCount
        }));
    }, [
        countryId,
        cityId,
        gender,
        ageRange['min'],
        ageRange['max'],
        ifKidFilter,
        kidGender,
        kidAgeRange['min'],
        kidAgeRange['max'],
        fbCountMin,
        fbCountMax,
        fbPageCountMin,
        fbPageCountMax,
        twitterCountMin,
        twitterCountMax,
        instaCountMin,
        instaCountMax,
        youtubeCountMin,
        youtubeCountMax,
        tiktokCountMin,
        tiktokCountMax,
        ifPregnantFilter,
        ifFbFollowerCount,
        ifFbPageFollowerCount,
        ifInstaFollowerCount,
        ifTwitterFollowerCount,
        ifYoutubeFollowerCount,
        ifTiktokFollowerCount
    ]);

    return (
        <div className={cx("filter-wrap")}>
        {/*<ul class="wizard">
                    <li class="wizard-step">
                        <a href="#1" class="wizard-step-link">
                            Campaign Details
                        </a>
                    </li>
                    <li class="wizard-step-active">
                        <a href="#2" class="wizard-step-link">
                            Select Influencers
                        </a>
                    </li>
                    <li class="wizard-step">
                        <a href="#3" class="wizard-step-link">
                            Review & Publish
                        </a>
                    </li>
                </ul>*/}
            <form onSubmit={onFormSubmit}>

                <div className={cx("target-count")}>
                    <h2>
                        People Targeted:&nbsp;
                        <TimelineComponent>                            
                            <span style={{position: 'absolute', color: 'black'}}>
                                <a href={`/brand/users/influencer/all?filters=1&${filterString}`} target="_blank" style={{textDecoration: 'underline'}}>{targetCount}</a>
                            </span>
                        </TimelineComponent>
                    </h2>
                </div>

                <div className={cx("category-filter")}>
                    <h2>Influencer Categories</h2>

                    <div className={cx("form-horizontal-row")}>
                        <div className={cx("form-wrap")}>
                            <label htmlFor="">Gender: </label>
                            <select id="gender" value={gender} onChange={e => injectChangeHook(e.target.value, setGender)} >
                                <option value="">All</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>

                        <div className={cx("form-wrap")}>
                            <label htmlFor="">Location: </label>
                            <select id="country-list" value={countryId} onChange={e => injectChangeHook(e.target.value, setCountryId)} >
                                <option value="">All</option>
                                {
                                    countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                        return <option value={country.id} key={i}>{country.name}</option>
                                    })
                                }
                            </select>
                        </div>

                        {
                            citiesList && citiesList.length !== 0 &&
                            <div className={cx("form-wrap")}>
                                <label>City: </label>
                                <select id="cities-list" value={cityId} onChange={e => injectChangeHook(e.target.value, setCityId)} required>
                                    <option value={0} >Select City</option>
                                    {
                                        citiesList.map((city, i) => {
                                            return <option value={city.id} key={i}>{city.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                    </div>

                    <div className={cx("form-horizontal-row")}>
                        <div className={cx("form-wrap")}>
                            <label htmlFor="">Age: </label>
                            <RangeSelector
                                maxValue={100}
                                minValue={10}
                                value={ageRange}
                                onChange={val => injectChangeHook(val, setAgeRange)}
                            />
                        </div>
                    </div>
                </div>                

                <hr/>

                <div>
                    <h2>Social Media Followers(specify atleast one condition)</h2>
                    <div className={cx("form-vertical-row")}>
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-fb" type="checkbox" checked={ifFbFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifFbFollowerCount)} />
                                <label for="chk-fb">FB: </label>
                            </div>
                            {
                                ifFbFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="fb-count-min"  className={cx("icon-fb")} placeholder="min" type="text" value={fbCountMin} onChange={e => injectChangeHook(e.target.value, setFbCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="fb-count-max"  className={cx("icon-fb")} placeholder="max" type="text" value={fbCountMax} onChange={e => injectChangeHook(e.target.value, setFbCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-insta" type="checkbox" checked={ifInstaFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifInstaFollowerCount)} />
                                <label for="chk-insta">Insta:</label>
                            </div>
                            {
                                ifInstaFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="insta-count-min"  className={cx("icon-insta")} placeholder="min" type="text" value={instaCountMin} onChange={e => injectChangeHook(e.target.value, setInstaCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="insta-count-max"  className={cx("icon-insta")} placeholder="max" type="text" value={instaCountMax} onChange={e => injectChangeHook(e.target.value, setInstaCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-fb-page" type="checkbox" checked={ifFbPageFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifFbPageFollowerCount)} />
                                <label for="chk-fb-page">Fb page:</label>
                            </div>
                            {
                                ifFbPageFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="fb-count-page-min"  className={cx("icon-fb")} placeholder="min" type="text" value={fbPageCountMin} onChange={e => injectChangeHook(e.target.value, setFbPageCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="fb-count-page-max"  className={cx("icon-fb")} placeholder="max" type="text" value={fbPageCountMax} onChange={e => injectChangeHook(e.target.value, setFbPageCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-twitter" type="checkbox" checked={ifTwitterFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifTwitterFollowerCount)} />
                                <label for="chk-twitter">Twitter:</label>
                            </div>
                            {
                                ifTwitterFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="twitter-count-min"  className={cx("icon-twitter")} placeholder="min" type="text" value={twitterCountMin} onChange={e => injectChangeHook(e.target.value, setTwitterCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="twitter-count-max"  className={cx("icon-twitter")} placeholder="max" type="text" value={twitterCountMax} onChange={e => injectChangeHook(e.target.value, setTwitterCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>                        
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-youtube" type="checkbox" checked={ifYoutubeFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifYoutubeFollowerCount)} />
                                <label for="chk-youtube">Youtube:</label>
                            </div>
                            {
                                ifYoutubeFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="youtube-count-min"  className={cx("icon-youtube")} placeholder="min" type="text" value={youtubeCountMin} onChange={e => injectChangeHook(e.target.value, setYoutubeCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="youtube-count-max"  className={cx("icon-youtube")} placeholder="max" type="text" value={youtubeCountMax} onChange={e => injectChangeHook(e.target.value, setYoutubeCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>    
                        <div className={cx("form-wrap")}>
                            <div class="custom-checkbox-wrap">
                                <input class="styled-checkbox" id="chk-tiktok" type="checkbox" checked={ifTiktokFollowerCount} onChange={e => injectChangeHook(e.target.checked, setifTiktokFollowerCount)} />
                                <label for="chk-tiktok">Tiktok:</label>
                            </div>
                            {
                                ifTiktokFollowerCount &&
                                <span>
                                    <Tooltip title="min">
                                        <input id="tiktok-count-min"  className={cx("icon-tiktok")} placeholder="min" type="text" value={tiktokCountMin} onChange={e => injectChangeHook(e.target.value, setTiktokCountMin)} />
                                    </Tooltip>
                                    <Tooltip title="max">
                                        <input id="tiktok-count-max"  className={cx("icon-tiktok")} placeholder="max" type="text" value={tiktokCountMax} onChange={e => injectChangeHook(e.target.value, setTiktokCountMax)} />
                                    </Tooltip>
                                </span>
                            }                                                        
                        </div>                      
                    </div>    
                </div>

                <hr/>

                <div className={cx('filter-wrap')}>
                    <div className={cx("form-vertical-row")}>
                        <h2 htmlFor="">Kid Filter</h2>
                        <div className={cx("toggle-switach ")}>
                            <Switch
                            onChange={val => injectChangeHook(val, setIfKidFilter)}
                            checked={ifKidFilter}
                            id="kid-filter-switch" />
                        </div>
                    </div>
                    {
                        ifKidFilter &&
                        <div>
                            <h2>Kid Details</h2>
                            <div className={cx("form-horizontal-row")}>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="">Gender: </label>
                                    <select id="kid-gender" value={kidGender} onChange={e => injectChangeHook(e.target.value, setKidGender)} >
                                        <option value="">All</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className={cx("form-wrap")}>
                                    <label htmlFor="">Age: </label>
                                    <RangeSelector
                                        maxValue={20}
                                        minValue={0}
                                        value={kidAgeRange}
                                        onChange={val => injectChangeHook(val, setKidAgeRange)}
                                    />
                                </div>
                            </div>
                        </div>
                    }                 
                </div>        

                <hr/>

                <div>
                    <div className={cx("form-group")}>
                        <label htmlFor="">Set only for pregnant users?</label>
                        <div className={cx("toggle-switach")}>
                            <Switch
                            onChange={val => injectChangeHook(val, setIfPregnantFilter)}
                            checked={ifPregnantFilter}
                            id="pregnant-filter-switch" />
                        </div>
                    </div>
                </div>                   
                
                <hr/>

                <div className={cx("form-group")}>
                    <label htmlFor="">Enable Moderation?</label>
                    <div className={cx("")}>
                        <Switch
                            onChange={val => setIsModerationEnabled(val)}
                            checked={isModerationEnabled}
                            id="moderation-switch" />
                    </div>
                </div>

                <hr/>

                <div className={cx("button-wrap")}>
                    <button className={cx("btn", "btn-blue")} type="submit">Save</button>  
                    <button className={cx("btn", "btn-light")}>Cancel</button>
                </div>   
            </form>
        </div>
    );
}

export default Filter;