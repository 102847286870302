import React, { useState } from 'react';

const AccordionTab = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen || false);

    if(props.isOpen !== isOpen) {
        setIsOpen(props.isOpen);
    }

    const toggle = () => {
        const curState = isOpen
        setIsOpen(!curState);
        if(!curState && props.onOpen) {
            props.onOpen()
        } else if(curState && props.onClose) {
            props.onClose()
        }
    }

    return (
        <div>
            <div className="accordion-header" onClick={toggle}>{props.header} { isOpen ? <span> &minus;</span> : <span> &#43;</span>} </div>
            {isOpen && <div>{props.children}</div>}
        </div>
    );
}

export default AccordionTab;