import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandInfluencerBulkRemind } from '../../../../actions';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import BulkRemindComponent from '../../../../components/Brand/Other/BulkRemind';
import Loader from '../../../../components/UIElems/Loader';
import notification from '../../../../components/UIElems/Notification';

const BulkRemind = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const bulkRemindUsers = (data) => {
        if(window.confirm('Bulk remind users?')) {
            setIsLoading(true);
            props.actions.brandInfluencerBulkRemind(data)
            .then(() => {
                notification.send({device: props.rdx.appReducer.deviceType, message: 'Done!', type: 'success'});
                setIsLoading(false);
            })
        }
    } 

    return (
        <div>
            <BulkRemindComponent bulkRemindUsers={bulkRemindUsers} />

            { isLoading && <Loader /> }
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        brandInfluencerBulkRemind
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkRemind);
