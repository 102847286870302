import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import { format } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Affix, Button } from 'antd';

const CampaignDetail = ({data, deadlineDetails, declineDetails, shareUrl, campaignApplicationStatus, onApply, onCopy, t, deviceType, isSubmitted}) => {

    campaignApplicationStatus = isSubmitted !== 0? isSubmitted: campaignApplicationStatus;
    
    const campaignApplicationStatusText = {
        0: t('campaign_status_decline'),
        1: t('campaign_status_approved'),
        2: t('campaign_status_awaiting_approval'),
        4: t('approval_status_4'),
        5: t('approval_status_5')
    };

    return (
       <div className={cx("influencer-campaign-detail-wrapper")}>

            <div className="campaign-detail-header">
                <h1>{t('campaign_details_title')}</h1>
                {
                    campaignApplicationStatus !== null && campaignApplicationStatus !== 1 &&
                    <Affix offsetTop={deviceType == 'desktop' ? 80 : 100}>
                        <div className={cx("dynamic-status-" + campaignApplicationStatus)}>{ campaignApplicationStatusText[campaignApplicationStatus] }</div>
                    </Affix>     
                }
            </div>

            <div className="">
                <div className={cx("campaign-company-name")}>
                    <h2>{data.campaignName}</h2>
                </div>

                <div className={cx("compaign-product-image")}>
                    <img src={data.productImage} alt="product-image" width="500" />
                </div>
            </div>
            <hr/>
            <div className={cx("campaign-client-details")}>

                <h2>{t('campaign_details_client_details')}</h2>

                <div className={cx("campaign-client-header")}>
                    <div className={cx("client-logo")}>
                        <img src={data.logo} alt="logo" />
                    </div>
                    <div className={cx("client-name")}>
                        {/*<div>{data.clientName}</div>*/}
                        <div>{data.brandName}</div>
                    </div>
                </div>

                <div className={cx("campaign-detail-social-links")}>
                    { 
                        data.fbLink && 
                        <div className={cx("social-links")}>
                            <a href={data.fbLink} target="blank">
                                <span className={cx("fb-iconFluencer")}></span>
                            </a>
                        </div> 
                    }
                    { 
                        data.twitterLink && 
                        <div className={cx("social-links")}>
                            <a href={data.twitterLink} target="blank">
                                <span className={cx("twitter-iconFluencer")}></span>
                            </a>
                        </div> 
                    }
                    { 
                        data.instaLink && 
                        <div className={cx("social-links")}>
                            <a href={data.instaLink} target="blank">
                                <span className={cx("insta-iconFluencer")}></span>
                            </a>
                        </div> 
                    }
                    { 
                        data.youtubeLink && 
                        <div className={cx("social-links")}>
                            <a href={data.youtubeLink} target="blank">
                                <span className={cx("youtube-iconFluencer")}></span>
                            </a>
                        </div> 
                    }
                    { 
                        data.tiktokLink && 
                        <div className={cx("social-links")}>
                            <a href={data.tiktokLink} target="blank">
                                <span className={cx("tiktok-iconFluencer")} />
                            </a>
                        </div> 
                    }
                </div>
            </div>

            { 
                !!data.hasDynamicUrl &&
                <div className={cx("campaign-detail-utm")}>
                    <h2>{t("campaign_details_url")}</h2>
                    <div className={cx("campaign-detail-utm")}>
                        {shareUrl}

                        <CopyToClipboard text={shareUrl}
                            onCopy={() => onCopy()}>
                            <button style={{marginLeft: '5px'}}>{t("campaign_details_copy")}</button>
                        </CopyToClipboard>
                    </div>                    
                </div>
            }

            { 
                !!data.variableFieldLabel && !!data.variableFieldValue &&
                <div className={cx("")}>
                    <h2>{data.variableFieldLabel}</h2>                    
                    <div className={cx("campaign-detail-description")}>
                        { campaignApplicationStatus == 1 ? data.variableFieldValue : 'Will be visible, once approved'}
                    </div>
                </div>
            }

            {   
                data.payment &&
                <div className={cx("")}>
                    <h2>{t("campaign_details_payment")}</h2>                    
                    <div className={cx("campaign-detail-description")}>
                        { data.payment }
                    </div>
                </div>
            }

            <div className={cx("")}>
                <h2>{t('campaign_details_background_information')}</h2>
                <div className={cx("campaign-detail-description")}>
                    { data.backgroundInformation }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t("campaign_details_detailed_deliverables")}</h2>
                <div className={cx("campaign-detail-description")}>
                    { data.instructions }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t("campaign_details_consider_adding")}</h2>
                <div className={cx("campaign-detail-description")}>
                    { data.consideration }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t('campaign_details_do_not')}</h2>                    
                <div className={cx("campaign-detail-description")}>
                    { data.guidelinesDont }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t("campaign_details_tone_and_voice")}</h2>                    
                <div className={cx("campaign-detail-description")}>
                    { data.toneAndVoice }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t("terms_and_conditions")}</h2>                    
                <div className={cx("campaign-detail-description")}>
                    { data.termsAndConditions }
                </div>
            </div>

            <div className={cx("")}>
                <h2>{t("campaign_details_distribution_rights_and_copyright")}:</h2>                    
                <div className={cx("campaign-detail-description")}>
                    { data.distributionRightsAndCopyright }
                </div>
            </div>

            <div className={cx("campaign-publish-detail")}>
                <h2>{t('campaign_details_campaign_publish_details')}</h2>
                <div className={cx("campaign-detail-description")}>
                    {(data.startDate && data.endDate) ? `${format(data.startDate, 'Do MMM YYYY')} - ${format(data.endDate, 'Do MMM YYYY')}` : ''}
                </div>
            </div>

            {
                campaignApplicationStatus !== null &&
                campaignApplicationStatus === 2 &&
                <div className={cx("campaign-application-status-note")} style={{backgroundColor: "#ffe1ad", color:"#f9a920"}}>
                    <span>{t('approval_status_2')}</span>
                </div>
            }
            {
                campaignApplicationStatus !== null &&
                campaignApplicationStatus === 5 &&
                <div className={cx("campaign-application-status-note")}>
                    <span>{t('approval_status_5')}<br />{`${t('campaign_status_job_submitted_note')} ${format(data.submissionEndDate, 'Do MMM YYYY')}`}</span>
                </div>
            }
            {
                campaignApplicationStatus !== null &&
                campaignApplicationStatus === 4 &&
                <div className={cx("campaign-application-status-note")}>
                    <span>{t('campaign_status_completed_note')}</span>
                </div>
            }
            {
                campaignApplicationStatus !== null &&
                campaignApplicationStatus === 6 &&
                <div className={cx("campaign-application-status-note")} style={{backgroundColor: "#C8C8C8", color:"#959595"}}>
                    <span>{t('campaign_status_expired_note')}</span>
                </div>
            }

            <div className={cx("campaign-application-status")}>
                { 
                    campaignApplicationStatus === null &&
                    (
                        deviceType == 'desktop'
                        ?
                            <div className={cx("button-container")}>
                                <button className={cx("btn", "btn-blue", "btn-apply-style")} type="button" onClick={onApply}>{t('campaign_button_apply')}</button>
                            </div>
                        :
                            <Affix offsetBottom={10}>
                                <Button type="primary" className={cx("")} onClick={onApply} block> {t('campaign_button_apply')}</Button>
                            </Affix>
                    )                    
                }

                {/*
                    campaignApplicationStatus !== null && campaignApplicationStatus !== 1 && 
                    <div className={cx("dynamic-status-" + campaignApplicationStatus)}>{ campaignApplicationStatusText[campaignApplicationStatus] }</div>
                */}

                {
                    campaignApplicationStatus === 1 &&
                    <Affix offsetBottom={10}>
                        <Button type="primary" className={cx("")} href={`/influencer/campaign/${data.campaignId}/submission`} block> {t('campaign_button_submit_work')}</Button>
                    </Affix>
                }

                {
                    campaignApplicationStatus === 2 &&
                    <Affix offsetBottom={10}>
                        <Button type="primary" disabled style={{backgroundColor: "#333333", color: "#ffffff"}} className={cx("")} href={`/influencer/campaign/${data.campaignId}/submission`} block> {t('campaign_button_submit_work')}</Button>
                    </Affix>
                }

                {
                    campaignApplicationStatus === 4 &&
                    <Affix offsetBottom={10}>
                        <Button type="primary" disabled style={{backgroundColor: "#4a94f3", color: "#ffffff"}} className={cx("")} href={`/influencer/campaign/${data.campaignId}/submission`} block> {t('completed')}</Button>
                    </Affix>
                }
                {
                    campaignApplicationStatus == 5 &&
                    <Affix offsetBottom={10}>
                        <Button type="primary" className={cx("")} href={`/influencer/campaign/${data.campaignId}/submission`} block> {t('campaign_button_edit')}</Button>
                    </Affix>
                }
            </div>

        </div>
    );
}

export default CampaignDetail;