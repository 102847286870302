import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames/bind';
import BaseLayout from '../../../components/Layout/Influencer/BaseLayout';
import AboutComponent from '../../../components/Influencer/About';
import { translate } from 'react-i18next';

const About = (props) => {
    return (
       <BaseLayout leftCards={[]}>
            <div className="influencer-about">
                <AboutComponent t={props.t} country={props.rdx.influencerReducer.country} /> 
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(translate()(About));
