import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerCampaignsApplied } from '../../../../../hooks/generic';
import {} from '../../../../../actions';
import Table from '../../../../../components/Common/Table';
import MenuPopper from '../../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';

const ApplicationsTable = (props) => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [forceUpdate, setForceUpdate] = useState(false);
    const campaignList = useInfluencerCampaignsApplied(props.dispatch, props.influencerId, page, pageSize, props.conditions, forceUpdate);
    
    const onFetchData = (state, instance) => {
        setLoading(false);
        setPage(state.page + 1);
        setPageSize(state.pageSize);
    };

    const createActionLink = (rowData) => {
        let links = [];
        links.push({title: 'View', handler: () => window.location = `/influencer/campaign/${rowData.campaignId}`});

        return <MenuPopper title='Manage' links={links} />
    }

    const applicationStatus = (isApproved) => {
        const status = {
            0: 'Declined',
            1: 'Approved',
            2: 'Pending'
        }

        return status[isApproved];
    }

    const jobStatus = (jobStatus) => {
        const status = {
            0: 'In Progress',
            1: 'Completed'
        }

        return status[jobStatus];
    }

    const columns = [
        {
            Header: "Client Name",
            accessor: "clientName"
        },
        {
            Header: "Brand Name",
            accessor: "brandName"
        },
        {
            Header: "Campaign Title",
            accessor: "campaignName"
        },
        {
            Header: "Start Date",
            accessor: "startDate",
            Cell: props => props.value ? format(props.value, 'Do MMM YYYY') : ''
        },
        {
            Header: "End Date",
            accessor: "endDate",
            Cell: props => props.value ? format(props.value, 'Do MMM YYYY') : ''
        },
        {
            Header: "Application Status",
            accessor: "applicationStatus",
            Cell: props => applicationStatus(props.value)
        },
        {
            Header: "Job Status",
            accessor: "jobStatus",
            Cell: props => jobStatus(props.value)
        },
        {
            Header: "Manage",
            accessor: "",
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <Table
                columns={columns}
                manual
                data={campaignList.list}
                pages={Math.ceil(campaignList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsTable);
