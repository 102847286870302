import React, { useState } from 'react';
import cx from 'classnames/bind';
import { gtmSend } from '../../../../utils';
import TabbedFAQ from '../../../Common/TabbedFAQ';

const Faq = ({t, welcomeUrl, welcomeFeedbackUrl, tabIndex}) => {
    
    return (
        <div className={cx("faq-wrapper")}>
            
            <div className={cx("mob-navigation")}>
                <ul>
                    <li>
                        <a href={welcomeUrl} onClick={() => {gtmSend('dynamic','menu','click'); }}>
                            {t("mob_onboarding_home")}
                        </a>
                    </li>
                    <li>
                        <a href={`${welcomeUrl}#Benefits`} onClick={() => {gtmSend('dynamic','benefits','click'); }}>
                            {t("mob_onboarding_benefits")}
                        </a>
                    </li>
                    <li>
                        <a href="#" className={cx("active")} onClick={() => {gtmSend('dynamic','faqs','click'); }}>
                            {t("mob_welcome_faq")}
                        </a>
                    </li>
                    <li>
                        <a href={welcomeFeedbackUrl} onClick={() => {gtmSend('dynamic','feedback','click'); }}>
                            {t("mob_feedback_feedback")}
                        </a>
                    </li>
                </ul>
            </div>

            <TabbedFAQ
                activeKey={0}
                t={t}
            />
        </div>
    );
}

export default Faq;