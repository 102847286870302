import React from 'react';
import Loader from '../../UIElems/Loader';
import { translate } from 'react-i18next';

const Loading = ({t}) => {
    return (
        <div>
            <Loader />
        </div>
    )
}

export default translate()(Loading);