import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useAllUsers, useCountryList, useCitiesList, useAllPanelUsers } from '../../../../../hooks/generic';
import { 
    panelUsersDownload,
    citiesListFetch
} from '../../../../../actions';
import Table from '../../../../../components/Common/Table';
import MenuPopper from '../../../../../components/UIElems/MenuPopperAntd';
import { format } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en";
import qs from 'qs';
import RatingPopover from '../../../Grading/Popover';
import { Popover } from 'antd';
import RangeSelector from '../../../../../components/UIElems/RangeSelector';

const UserTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [genderFilter, setGenderFilter] = useState("");
    const [ifAgeRange, setIfAgeRange] = useState(false);
    const [ageRangeFilter, setAgeRangeFilter] = useState({min: 0, max: 100});
    const [ifChildFilter, setIfChildFilter] = useState(false);
    const [childAgeRangeFilter, setChildAgeRangeFilter] = useState({min: 0, max: 20});
    const [childGenderFilter, setChildGenderFilter] = useState("");
    const [ifDueDate, setIfDueDate] = useState(false);
    const [dueDateStart, setDueDateStartFilter] = useState(null);
    const [dueDateEnd, setDueDateEndFilter] = useState(null);
    const [countryIdFilter, setCountryIdFilter] = useState([]);
    const [cityIdFilter, setCityIdFilter] = useState([]);
    const [userId, setUserId] = useState(null);
    const [citiesList, setCitiesList] = useState([]);
    
    const countryList = useCountryList();

    const conditions = { 
        ...props.conditions,
        ...{
            countryId: countryIdFilter,
            cityId: cityIdFilter,
            gender: genderFilter,
            userId,
            ...(ifAgeRange && {userAge: {
                minimum: ageRangeFilter.min,
                maximum: ageRangeFilter.max
            }}),
            ifChildFilter: +ifChildFilter,
            ...(ifChildFilter && {childAge: {
                minimum: childAgeRangeFilter.min,
                maximum: childAgeRangeFilter.max
            }}),
            ...(ifChildFilter && {childGender: childGenderFilter}),
            ...(ifDueDate && {dueDate: {startDate: (dueDateStart ? format(dueDateStart, 'YYYY-MM-DD') : null), endDate: (dueDateEnd ? format(dueDateEnd, 'YYYY-MM-DD') : null)}}),
        },
        page,
        pageSize,
        forPanelList: true
    };
    const userList = useAllPanelUsers(() => setLoading(false), props.dispatch, page, pageSize, forceUpdate, conditions, filtered);

    //linked filters
    useEffect(() => {
        registerLocale("en", enGB);
        let filters;        
        //approval filter
        if(window.location.search && window.location.search.indexOf('approvalFilter') > -1) {
            filters = qs.parse(window.location.search.replace('?', ''));
            setTimeout(() => setForceUpdate(!forceUpdate), 1000);
        } 
        //from single campaign filter
        if(window.location.search && window.location.search.indexOf('filters') > -1) {
            filters = qs.parse(window.location.search.replace('?', ''));
            // console.log(filters);
            setIfAgeRange(true);
            setAgeRangeFilter({min: parseInt(filters.ageRangeMin), max: parseInt(filters.ageRangeMax)});
            setCountryIdFilter(filters.countryId);
            setCityIdFilter(filters.cityId);
            setGenderFilter(filters.gender);
            setIfChildFilter(filters.ifKidFilter == 'true');
            setChildAgeRangeFilter({min: parseInt(filters.kidAgeRangeMin), max: parseInt(filters.kidAgeRangeMax)});
            setChildGenderFilter(filters.kidGender);
            setTimeout(() => setForceUpdate(!forceUpdate), 1000);
        }
    }, [])

    useEffect(() => {
        if (countryIdFilter && Array.isArray(countryIdFilter) && countryIdFilter.length > 0) {
            const promises = [];
            countryIdFilter.forEach(country => {
                promises.push(citiesListFetch(country).then(response => response.data));
            });
            Promise.all(promises).then(results => {
                setCitiesList(results.flat(1));
            });
        }
    }, [countryIdFilter]);

    const EmailPopover = ({email, secondaryEmail = null}) => {
        const content = (
            <div>
                <span>{secondaryEmail}</span>
            </div>
        )
        return (
            <Popover title="Secondary Email" content={content} placement="topLeft">
                <span style={{cursor: "pointer"}}>{email}</span>
            </Popover>
        )
    }

    const onFetchData = (state, instance) => {
        setPage(state.page + 1);
        setPageSize(state.pageSize);
        setSorted(state.sorted);
        setFiltered(state.filtered);
        //check for filters
        for(let k in state.filtered) {
            if(state.filtered[k]['value'].length && state.filtered[k]['value'].length < 2) return false;
        }
        setLoading(true);
        if(state.sorted.length || state.filtered.length) setForceUpdate(!forceUpdate);
    };

    const createActionLink = (rowData) => {
        let links = [];
        //influencer approval
        // let approvalLink = 
        //     <PopApproveConfirm onConfirm={e => influencerApprove(rowData.userId, 2)}
        //         onCancel={e => influencerApprove(rowData.userId, 1)} />;
        // //influencer decline
        // let declineLink =            
        //     <CustomDeclineModal rowData={rowData}
        //         flag={0}
        //         callback={influencerApprove} />;        
        // //declined        
        // if(rowData.isApproved == 0) {
        //     //approve            
        //     links.push({title: 'Approve', extraElem: approvalLink});
        //     //more info
        //     links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.userId, 3)});            
        // }
        // //approved
        // if(rowData.isApproved == 1) {
        //     links.push({title: 'Decline', extraElem: declineLink});
        //     links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.userId, 3)});
        // }
        // //pending        
        // if(rowData.isApproved == 2) {
        //     //approve            
        //     links.push({title: 'Approve', extraElem: approvalLink});
        //     //decline
        //     links.push({title: 'Decline', extraElem: declineLink});
        //     //more info
        //     links.push({title: 'More Info Required', handler: () => influencerApprove(rowData.userId, 3)});            
        // }
        // //more info
        // if(rowData.isApproved == 3) {
        //     links.push({title: 'Approve', extraElem: approvalLink});
        //     links.push({title: 'Decline', extraElem: declineLink});
        // }        
        // //view profile
        // links.push({title: 'View Profile', handler: () => window.open(`/brand/user/${rowData.userId}`, '_blank')});
        // //remind
        // let reminderLink =            
        //     <CustomReminderModal rowData={rowData}
        //         callback={influencerRemind}/>;
        // links.push({title: 'Remind', extraElem: reminderLink});
        // //campaign brief
        // let campaignBriefLink =            
        //     <CampaignBriefModal rowData={rowData}
        //         campaignList={campaignList}
        //         callback={sendCampaignBrief}/>;
        // links.push({title: 'Send campaign brief', extraElem: campaignBriefLink});
        // //ratings history
        // links.push({title: 'Ratings History', handler: () => window.open(`/brand/user/${rowData.userId}/grading/history`, '_blank')});

        return <MenuPopper title='Manage' links={links} />
    }

    const onGenderFilter = (value) => {
        setGenderFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onChildGenderFilter = (value) => {
        setChildGenderFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onAgeRangeFilter = (value) => {
        setAgeRangeFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onChildAgeRangeFilter = (value) => {
        setChildAgeRangeFilter(value);
        // setForceUpdate(!forceUpdate);
    }

    const onDueDateFilter = (type, value) => {
        if(type == 'start') {
            setDueDateStartFilter(value);
        } else {
            setDueDateEndFilter(value);
        }
        // setForceUpdate(!forceUpdate);        
    }

    const onEnablingConditionalFilter = (value, callback) => {
        callback(value);
        // setForceUpdate(!forceUpdate);
    }

    const onUsersDownload = () => {
        if(window.confirm('Download all users?')) {
            props.actions.panelUsersDownload(conditions)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `panel_users.csv`);
                document.body.appendChild(link);
                link.click();
            })
        }        
    }

    const columns = [
        {
            Header: "Id",
            accessor: "userId",
            filterable: false,
            sortable: false,
            Footer: "Total: " + userList.total
        },
        {
            Header: "Name",
            accessor: "fullname",
            filterable: true,
            sortable: false
        },
        {
            id: "email",
            Header: "Email",
            accessor: "",
            filterable: true,
            sortable: false,
            Cell: props => (
                !props.value.secondaryEmail || props.value.email === props.value.secondaryEmail?
                    props.value.email:
                    <EmailPopover email={props.value.email} secondaryEmail={props.value.secondaryEmail} />
            )
        },
        {
            Header: "Account Type",
            accessor: "accountType",
            filterable: false,
            sortable: true,
        },
        {
            Header: "Average Rating",
            accessor: "",
            sortable: false,
            filterable: false,
            width: 160,
            Cell: props =>
                <RatingPopover data={props.value} />
        },
        {
            Header: "Origin",
            accessor: "origin",
            filterable: false,
            sortable: true
        },
        {
            Header: "Country",
            accessor: "country",
            filterable: false,
            sortable: true
        },
        {
            Header: "Gender",
            accessor: "gender",
            filterable: false,
            sortable: true
        },
        {
            Header: "Last Reminder On",
            accessor: "reminderOn",
            filterable: false,
            sortable: true
        },
        {
            Header: "Action",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: props => createActionLink(props.value)
        }
    ];

    return (
        <div>
            <div className="user-filters">
                <div className="form-horizontal-row">

                    <div className={cx("form-wrap")}>
                        <label htmlFor="country-list">Country: </label>
                        <select id="country-list" value={countryIdFilter} onChange={e => e.target.value !== ''? setCountryIdFilter([e.target.value]): setCountryIdFilter(null)} >
                            <option value="">All</option>
                            {
                                countryList.filter(country => [99,100,129,169,192,211,232].indexOf(country.id) > -1).map((country, i) => {
                                    return <option value={country.id} key={i}>{country.name}</option>
                                })
                            }
                        </select>
                    </div>  
                      
                    <div className={cx("form-wrap")}>
                        <label htmlFor="city-list">City: </label>
                        <select id="city-list" value={cityIdFilter} onChange={e => e.target.value !== ''? setCityIdFilter([e.target.value]): setCityIdFilter(null)} >
                            <option value="">All</option>
                            {
                                citiesList.map((city, i) => {
                                    return <option value={city.id} key={i}>{city.name}</option>
                                })
                            }
                        </select>
                    </div>  

                    <div className={cx("form-wrap")}>
                        <label htmlFor="gender">Gender: </label>
                        <select id="gender" name="gender" value={genderFilter} onChange={e => e.target.value !== ''? onGenderFilter([e.target.value]): onGenderFilter(null)}>
                            <option value="">All</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div className={cx("form-wrap")}>
                        <label htmlFor="influencer-id">User Id: </label>
                        <input type="number" id="influencer-id" name="influencer-id" value={userId} onChange={e => setUserId(e.target.value)} />
                    </div>               
                </div>

                <div className="form-horizontal-row">
                    <div className={cx("form-wrap")}>
                        <div className={cx("form-vertical-row")} style={{marginBottom: '0px'}}>
                            <div className="custom-checkbox-wrap ">
                                <input className="styled-checkbox" id="chk-user-age" type="checkbox" checked={ifAgeRange} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfAgeRange)} />
                                <label for="chk-user-age">User Age:</label>
                            </div>

                            <div>
                            {
                                ifAgeRange &&
                                <RangeSelector
                                    maxValue={100}
                                    minValue={0}
                                    timeout={0}
                                    value={ageRangeFilter}
                                    onChange={val => onAgeRangeFilter(val)}
                                />
                            }
                            </div>
                        </div>
                    </div>

                    <div className={cx("form-wrap")}>
                        <div className={cx("form-vertical-row")} style={{ marginBottom: '0px' }}>
                            <div className="custom-checkbox-wrap">
                                <input className="styled-checkbox" id="chk-duedate" type="checkbox" checked={ifDueDate} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfDueDate)} />
                                <label for="chk-duedate">Due Date:</label>
                            </div>
                            <div className={cx("form-row-wrap")}>
                                {
                                    ifDueDate &&
                                    <React.Fragment>
                                        <DatePicker locale={"en"}
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={dueDateStart}
                                            onChange={dueDateStart => onDueDateFilter('start', dueDateStart)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Start Date"
                                            popperPlacement="top-end" />

                                        <DatePicker locale={"en"}
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={dueDateEnd}
                                            onChange={dueDateEnd => onDueDateFilter('end', dueDateEnd)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="End Date"
                                            popperPlacement="top-end" />
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-wrap">
                        <div className={cx("form-vertical-row")} style={{ marginBottom: '0px' }}>
                            <div className="custom-checkbox-wrap">
                                <input className="styled-checkbox" id="chk-child-filters" type="checkbox" checked={ifChildFilter} onChange={e => onEnablingConditionalFilter(e.target.checked, setIfChildFilter)} />
                                <label for="chk-child-filters">Child Filters:</label>
                            </div>
                            <div className={cx("form-row-wrap")}>
                                {
                                    ifChildFilter &&
                                    <RangeSelector
                                        maxValue={20}
                                        minValue={0}
                                        timeout={0}
                                        value={childAgeRangeFilter}
                                        onChange={val => onChildAgeRangeFilter(val)}
                                        formatLabel={value => `${value} yrs`}
                                    />
                                }

                                {
                                    ifChildFilter &&
                                    <span className={cx("space-between-fields")}>
                                        <select id="child-gender" name="child_gender" value={childGenderFilter} onChange={e => e.target.value !== ''? onChildGenderFilter([e.target.value]): onChildGenderFilter(null)}>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>               

                <div className="row4">
                    <button type="button" className={cx("btn", "btn-blue")} onClick={e => setForceUpdate(!forceUpdate)}>Fetch</button>                                                          

                    <button type="button" className={cx("btn", "btn-danger")} onClick={e => onUsersDownload()} style={{float: 'right'}}>Download</button>
                </div>                                
            </div>           

            <div>&nbsp;</div>

            <Table
                columns={columns}
                manual
                data={userList.list}
                pages={Math.ceil(userList.total / pageSize)}
                loading={loading}
                onFetchData={onFetchData}
                defaultPageSize={10} />
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        panelUsersDownload
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
