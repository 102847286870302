import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import cx from 'classnames/bind';
import { format, parse } from 'date-fns';
// import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, Icon, Modal } from 'antd';
import enGB from "date-fns/locale/en";

const CustomDeclineModal = ({rowData, flag, callback}) => {
    const [isCustomDeclineVisible, setIsCustomDeclineVisible] = useState(false);
    const [isCustomDeclineLoading, setIsCustomDeclineLoading] = useState(false);
    const [customDeclineText, setCustomDeclineText] = useState("");

    const onClick = () => {
        // if(window.confirm('Remind user about social accounts?')) {
            setIsCustomDeclineVisible(true);
        // }
    }

    const onOk = () => {
        if(!customDeclineText.length) return alert("You need to specify the reason for declining the user!");
        setIsCustomDeclineLoading(true);
        callback(flag, customDeclineText);
    }

    return (
        <span>
            <button className={cx("btn", "btn-danger")} type="button" onClick={onClick}>Decline</button>
            <Modal
                title={'Reason for declining?'}
                visible={isCustomDeclineVisible}
                okText="Send"
                onOk={onOk}
                confirmLoading={isCustomDeclineLoading}
                onCancel={e => setIsCustomDeclineVisible(false)}
                maskClosable={false}>
                <textarea value={customDeclineText} onChange={e => setCustomDeclineText(e.target.value)} rows="3" cols="50" />
            </Modal>
        </span>
    );
}

const Profile = ({campaignId, influencerId, profile, profileSocial, deadlineEdit, applicationStatus, approveApplication, campaignsApplied=[]}) => {
    if(!profile || !profileSocial.length) return null;

    const [deadlineDate, setDeadlineDate] = useState(null);
    const [comments, setComments] = useState('');

    const jobStatus = (jobStatus) => {
        const status = {
            0: 'In Progress',
            1: 'Completed'
        }

        return status[jobStatus];
    }

    useEffect(() => {
        registerLocale("en", enGB);
    }, []);

    //set current campaign data
    useEffect(() => {
        let currentCampaign = campaignsApplied.filter(campaign => campaign.campaignId == campaignId);
        if(currentCampaign.length && currentCampaign[0].deadlineDate) {
            console.log(currentCampaign[0].deadlineDate, currentCampaign[0].deadlineComments);            
            setDeadlineDate(parse(currentCampaign[0].deadlineDate));
            setComments(currentCampaign[0].deadlineComments);   
        }
    }, [campaignsApplied.length])
    
    return (
        <div className={cx("applicant-container")}>
            <div className={cx("user-profile")}>
                <div className={cx("profile-pic")}>
                    <img src={profile.image} alt="profile-image" width="64" height="64" />
                </div>
                <div>
                    <div className={cx("profile-name")}>{profile.fullname} <a style={{fontSize: '12px'}} href={`/brand/user/${profile.influencerId}`} target="_blank">full bio</a></div>
                    <div className={cx("profile-tagline")}>{profile.tagline}</div>
                </div>
            </div>

            <div className={cx("user-social")}>
                <div className={cx("user-social-head")}>
                    <span>Social Media Details </span>
                    <span>Followers </span>
                    {/*<span>Post </span>*/}
                </div>
                {
                    profileSocial.map((data, i) => {
                        return <div className={cx("user-social-body")} key={i}>
                            <span><i className={cx("icon-" + data.socialType, "social-icon-style")}></i>{data.fullname}</span>
                            <span>{data.followerCount ? data.followerCount : 0}</span>
                            {/*<span>{data.postCount ? data.postCount : 0}</span>*/}
                        </div>
                    })
                }
            </div>

            <div className={cx("campaigns-past")}>
                <h3>Past Campaign Details</h3>
                <table>
                    <thead>
                        <tr>
                            <th width="20%">Client Name</th>
                            <th width="20%">Brand Name</th>
                            <th width="20%">Campaign</th>
                            <th width="30%">Deliverables</th>
                            <th width="10%">Job Status</th>
                            <th width="20%">Earnings</th>
                        </tr>                        
                    </thead>
                    <tbody>
                        {
                            campaignsApplied.map((data, i) => {
                                return <tr key={i}>
                                    <td>{data.clientName}</td>
                                    <td>{data.brandName}</td>
                                    <td>{data.campaignName}</td>
                                    <td>{data.instructions}</td>
                                    <td>{jobStatus(data.jobStatus)}</td>
                                    <td>{data.earnings}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="deadline-date">
                <h3>Set Deadline</h3>
                <div className={cx("deadline-date-wrap")}>
                    <div className={cx("form-group")}>
                        <DatePicker locale={"en"}
                            selected={deadlineDate}
                            onChange={deadlineDate => setDeadlineDate(deadlineDate)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText='deadline'
                            popperPlacement="top-end"
                            />
                    </div>
                    
                    <div className={cx("form-group")}>  
                        <Tooltip title="Are there any special instructions for this project that is relevant to just this user? Mention it here and don't forget to click 'Inform Participant'">
                            <Icon type="info-circle" theme="filled" />
                        </Tooltip>
                        <textarea id="comments" placeholder="Comments" value={comments} onChange={e => setComments(e.target.value)} rows="5" cols="80"/>
                    </div>

                    <div className={cx("form-group")}>
                        <button className={cx("btn", "btn-blue")} type="button" onClick={() => deadlineEdit(format(deadlineDate, 'YYYY-MM-DD hh:mm:ss'), comments)}>Inform Participant</button>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="button-wrap">
                {
                    (applicationStatus == 1 || applicationStatus == 2) &&
                    <CustomDeclineModal rowData={{campaignId, influencerId}}
                        flag={0}
                        callback={approveApplication} />
                }
                {
                    (applicationStatus == 0 || applicationStatus == 2) &&
                    <button className={cx("btn", "btn-success")} type="button" onClick={() => approveApplication(1)}>Approve</button>
                }
            </div>

        </div>
    );
}

export default Profile;
