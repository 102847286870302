import React, { Fragment } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ApprovalsSidebar from '../../../Brand/Sidebar/Approvals';
import AllUsersSidebar from '../../../Brand/Sidebar/Users';
import LogoutSidebar from '../../../Brand/Sidebar/Logout';
import AdminSidebar from '../../../Brand/Sidebar/Admins';
import OtherSidebar from '../../../Brand/Sidebar/Other';
import FeedbackSidebar from '../../../Brand/Sidebar/Feedback';
import JobsSidebar from '../../../Brand/Sidebar/Jobs'
import CreditsSidebar from '../../../Brand/Sidebar/Credits';
// import RemoteSidebar from '../../../Brand/Sidebar/Remote';

const BaseLayout = ({rdx, actions, children=null, leftCards=[], rightCards=[], shouldShowSidebars=true}) => {
    //sidebar defaults
    leftCards = 
        rdx.brandReducer.adminType == 3
        ? [<AllUsersSidebar defaultState='collapse' />]
        : [
            ...leftCards,
            <JobsSidebar defaultState='collapse'/>,
            <ApprovalsSidebar defaultState='collapse' />,
            <AllUsersSidebar defaultState='collapse' />,
            rdx.brandReducer.adminType === 1 && <CreditsSidebar defaultState='collapse' />,
            rdx.brandReducer.adminType === 1 && <AdminSidebar defaultState='collapse' />,
            rdx.brandReducer.adminType === 1 && <OtherSidebar defaultState='collapse' />,
            <FeedbackSidebar defaultState='collapse' />,
            <LogoutSidebar defaultState='collapse' />,
            // rdx.brandReducer.adminType == 1 && <RemoteSidebar defaultState='collapse' />
        ];
    //hide if
    if(!shouldShowSidebars) {
        leftCards = [];
        rightCards = [];
    }

    let ifLeftCards = false;
    if(typeof leftCards != "undefined" && leftCards.length != 0) {
        ifLeftCards = true;
    }
    let ifRightCards = false;
    if(typeof rightCards != "undefined" && rightCards.length != 0) {
        ifRightCards = true;
    }

    return (
        <div className={cx('brand-base-layout')}>

            {
                // <Header />
            }

            <main className={cx("brand-main-container")}>

                <div id="startMainContentSection"></div>

                <div>
                    {
                        ifLeftCards &&

                        <aside className={cx("left-panel")}>
                            <div className={cx("h-left")}>
                                <div className={cx("logo-wrap")}>
                                    <a href='/brand/home'>
                                        <img className={cx('not-mobile', 'logo')} src={'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/tap-logo-vip.svg'} 
                                             alt="logo" />

                                        <img className={cx('only-mobile', 'logo')} src={'https://s3-ap-southeast-1.amazonaws.com/tapfluencer/assets/logo_brands.png'}
                                            height="33" width="130" alt="logo" />
                                    </a>
                                </div>                
                            </div>
                            <div className={cx("")}>
                                <div id="leftPanelTop"></div>

                                <div id="leftPanelMain" className={cx("left-panel-container")}>
                                    {
                                        leftCards.map((card, i) => {
                                            return <Fragment key={i}>{card}</Fragment>
                                        })
                                    }
                                </div>

                                <div id="leftPanelBottom"></div>
                            </div>
                        </aside>
                    }

                    <div className={cx("center-panel")}>
                        <div id="centerPanelTop"></div>

                        <div id="centerPanelMain">
                            {children}
                        </div>
                        
                        <div id="centerPanelBottom"></div>
                    </div>

                    {
                        ifRightCards &&

                        <aside className={cx("right-panel")}>
                            <div className="">
                                <div id="rightPanelTop"></div>

                                <div id="rightPanelMain">
                                    {
                                        rightCards.map((card, i) => {
                                            return <Fragment key={i}>{card}</Fragment>
                                        })
                                    }
                                </div>

                                <div id="rightPanelBottom"></div>
                            </div>
                        </aside>
                    }
                </div>                    

            </main>

            <Footer />

        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
