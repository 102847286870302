import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { brandInfluencerPaymentAdjust } from '../../../../actions';
import { useBrandInfluencerPaymentHistory } from '../../../../hooks/generic';
import { Typography } from 'antd';
import PaymentHistoryComponent from '../../../../components/Brand/Payments/History';
import notification from '../../../../components/UIElems/Notification';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';

const { Text } = Typography;

const PaymentHistory = (props) => {
    let params = new URLSearchParams(props.location.search);
    let campaignId = params.get('campaignId');
    const [loading, setLoading] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);

    const paymentList = useBrandInfluencerPaymentHistory(
        () => setLoading(false),
        props.dispatch,
        props.match.params.influencerId,
        campaignId ? campaignId : null,
        1,
        100,
        [],
        [],
        [],
        forceUpdate
    );
    
    return (
        <BaseLayout leftCards={[]}>
            <div className={cx('payment-history-parent')}>
                <PaymentHistoryComponent data={paymentList}
                    name={paymentList.total ? paymentList['list'][0].fullname : ''} />
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);