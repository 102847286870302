import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { 
    influencerSocialAccountDelete,
    influencerSocialFbPageConnectInitiate,
    influencerSocialFbPageAccountList,
    influencerSocialFbPageAccountConnect,
    influencerValidateValue
} from '../../../../../../actions';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';
import { Icon, Typography, List, Avatar, Spin } from 'antd';
import Loader from '../../../../../UIElems/Loader';
import Modal from '../../../../../UIElems/Modal';

const { Text } = Typography;

const IconSvg = () => {
    return (
        <span style={{ display: 'inline-block', borderRadius: '50%', width: '30px', height: '30px', position: 'absolute', top: '47px', left: '51px', background: '#517fa5', border: 'none' }}>
            <svg style={{ width: '22px', height: 'auto', marginTop: '4px'}} enableBackground="new 0 0 56.693 56.693" height="56.693px" version="1.1" viewBox="0 0 56.693 56.693" width="56.693px"><path fill="#fff" d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029  c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77  L40.43,21.739z"/></svg>
        </span>
    );
}

const FbPage = (props) => {
    const t = props.t;
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fbPageAccountList, setFbPageAccountList] = useState([]);

    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'fbPage', false);

    //check if initiated
    useEffect(() => {
        props.actions.influencerValidateValue(props.rdx.influencerReducer.id, 'ifFbPageInitiate')
        .then(res => {
            if(res.data) {
                setIsLoading(true);
                props.actions.influencerSocialFbPageAccountList(props.rdx.influencerReducer.id)
                .then(results => {
                    setIsLoading(false);
                    setFbPageAccountList(results.data);
                    setIsModalOpen(true);
                })
            }
        })
    }, [])

    const onConnect = () => {
        setIsLoading(true);
        props.actions.influencerSocialFbPageConnectInitiate(props.rdx.influencerReducer.id, true)
        .then(() => {
            window.location = '/social/auth/fbPage';
        })         
    }

    const onCountRefresh = () => {
        // if(window.confirm("Are you sure you want to refresh the account?")) {
        //     props.actions.influencerSocialAccountDelete(props.rdx.influencerReducer.id, 'fbPage')
        //     .then(res => {
        //         window.location = '/social/auth/facebook';
        //     })
        // }        
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("fb-page-icon-social-account-bg")}>
                    <Icon type="file-ppt" style={{ fontSize: '48px', margin: '8px'}} theme="twoTone" />
                    <div>
                        <Text strong>FB Page</Text>
                    </div>
                    {/*<div className={cx("fb-page-followers-social-icon")}></div>*/}
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>{t('social_account_connect')} +</button>
            </div>
        );        
    }

    const ConnectedState = () => {
        let data = socialAccount.fbPage;

        return (
            <div className="">
                {
                    data.profileImage
                    ? 
                        <div style={{position: 'relative'}} className={cx("followers-profile-image-wrap")}>
                            <img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                            <IconSvg />
                        </div>
                    : 
                        <div style={{position: 'relative'}} className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")}></div>
                            <IconSvg />
                        </div>
                }
                <div>FB Page</div>
                <div>{data.socialName}</div>
                {/*<div><img src={} alt=""/></div>*/}
                <div className={cx("btn", "btn-followers")}>{t('social_account_followers')}: {data.followerCount ? data.followerCount : 0}</div>
                {/*<div>Posts: {data.postsCount ? data.postsCount : 0}</div>*/}

                {/*<div className={cx("refresh-followers-wrap")}>
                    <a href="#" className={cx("btn-refresh")} onClick={e => onCountRefresh()}>
                        <span className={cx("icon-refresh")}></span>
                        <span>{t('social_account_refresh')}</span>
                    </a>
                </div>*/}
            </div>
        );        
    }

    const AccountListModal = () => {

        const onModalClose = () => {
            // props.actions.influencerSocialFbPageConnectInitiate(props.rdx.influencerReducer.id, false);
            setIsModalOpen(false);
        }

        const onPageConnect = (pageId, name, fanCount, picUrl, accessToken, link) => {
            setIsLoading(true);
            props.actions.influencerSocialFbPageAccountConnect(props.rdx.influencerReducer.id, pageId, name, fanCount, picUrl, accessToken, link)
            .then(() => {
                window.location.reload(true);
            })
        }

        return (
            <Modal title="Select Page To Connect"
                openModal={isModalOpen}
                onClose={onModalClose}
                onOk={onModalClose} >

                <div className="infinite-container">
                    <List dataSource={fbPageAccountList}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={item.picture.data.url} />
                                    }
                                    title={item.name}
                                    description={<span>count: {item.fan_count}</span>} />
                                <div>
                                    <button type="button" onClick={e => onPageConnect(item.id, item.name, item.fan_count, item.picture.data.url, item.access_token, item.link)}>
                                        connect
                                    </button>
                                </div>
                            </List.Item>
                        )} >
                    </List>
                </div>

            </Modal>
        );
    }
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.fbPage
                ? <ConnectedState />
                : <ConnectState />
            }

            <AccountListModal />

            { isLoading && <Loader /> }            
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerSocialAccountDelete,
        influencerSocialFbPageConnectInitiate,
        influencerSocialFbPageAccountList,
        influencerSocialFbPageAccountConnect,
        influencerValidateValue
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(FbPage);
