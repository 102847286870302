import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { useInfluencerSocialAccounts } from '../../../../../../hooks/generic';
import { influencerSocialAccountUpdate } from '../../../../../../actions';
import Modal from '../../../../../UIElems/Modal';
import notification from '../../../../../UIElems/Notification';

const Fb = (props) => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const socialAccount = useInfluencerSocialAccounts(props.dispatch, props.rdx.influencerReducer.id, 'insta', forceUpdate);
    const [isModalOpen, setIsModalOpen] = useState(false);
    //form
    const [id, setId] = useState('');
    const [profileUrl, setProfileUrl] = useState('');
    const [followerCount, setFollowerCount] = useState(0);

    const onConnect = () => {
        setIsModalOpen(true);
    }

    const ConnectState = () => {
        return (
            <div>
                <div className={cx("insta-icon-social-account-bg")}>
                    <div className={cx("insta-followers-social-icon")}></div>
                </div>
                <button className={cx("btn", "btn-connect")} onClick={onConnect}>Connect +</button>
            </div>
        );        
    }    

    const ConnectedState = () => {
        let data = socialAccount.insta;

        return (
            <div className="">
                {
                    data.profileImage
                    ? 
                        <div className={cx("followers-profile-image-wrap")}>
                            <img src={data.profileImage} className={cx("followers-profile-image")} alt="profile"/>
                        </div>
                    : 
                        <div className={cx("default-icon-social-account-bg")}>
                            <div className={cx("default-followers-social-icon")}></div>
                        </div>
                }
                {/*<div>{data.socialName}</div>
                <div><img src={} alt=""/></div>*/}
                <div className={cx("btn", "btn-followers")}>Followers: {data.followerCount ? data.followerCount : 0}</div>
                {/*<div>Posts: {data.postsCount ? data.postsCount : 0}</div>*/}
            </div>
        );        
    }

    const updateSocialAccount = () => {
        if(!profileUrl || !followerCount)
            return notification.send({device: props.rdx.appReducer.deviceType, message: 'Fill in all the info!', type: 'error'});

        props.actions.influencerSocialAccountUpdate({
            id,
            profileUrl,
            followerCount,
            type: 'insta',
            influencerId: props.rdx.influencerReducer.id
        })
        .then(() => {
            setForceUpdate(!forceUpdate);
        });
    }
    
    return (
        <div className={cx("influencer-connect-state-wrapper")}>
            {
                socialAccount && socialAccount.insta
                ? <ConnectedState />
                : <ConnectState />
            }
            <Modal title="Instagram"
                openModal={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onOk={updateSocialAccount} >

                <UpdateForm values={{profileUrl, followerCount}}
                    setters={{setProfileUrl, setFollowerCount}}  />

            </Modal>            
        </div>
    );
}

const UpdateForm = ({values, setters}) => {
    return (
        <div>
            <div className={cx("form-group")}>
                <label htmlFor="account">Profile Url: </label>
                <input id="account" type="text" value={values.profileUrl} onChange={e => setters.setProfileUrl(e.target.value)} required />
            </div>

            <br/>

            <div className={cx("form-group")}>
                <label htmlFor="follower-count">Follower Count: </label>
                <input id="follower-count" type="text" value={values.followerCount} onChange={e => setters.setFollowerCount(e.target.value)} required />
            </div>       
        </div>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        influencerSocialAccountUpdate
    }, dispatch),
    dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Fb);
