import paths from '../server/routes/paths';
import faq from './faq';
import faqInfluencer  from './faqInfluencer';
import faqPanel from './faqPanel';

const tapApi = {
    influencerLogin: {
        url: 'login',
        method: 'post'
    },    
    vipUserApprove: {
        url: 'vip/application/approve',
        method: 'post'
    },    
    vipUserCacheUpdate: {
        url: 'vip/profile/cacheUpdate',
        method: 'post'
    },    
    emailCheck: {
        url: 'email/check',
        method: 'post'
    },    
    passwordForgot: {
        url: 'email/forgot',
        method: 'post'
    },
    vipUserRoleUpdate: {
        url: 'admin/user-role/update',
        method: 'post'
    }
};

const tap = {
    // token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFkOGIwODZhNzBjN2YwNTBmNjkzYjBlMjQ3MDA3NTA5YzU0NmNjMWUyN2Y5MThkZTMyNDAwY2MyZjEyMjYxZDZmNGRmOTc0YmEyN2Q5OTcxIn0.eyJhdWQiOiIzIiwianRpIjoiYWQ4YjA4NmE3MGM3ZjA1MGY2OTNiMGUyNDcwMDc1MDljNTQ2Y2MxZTI3ZjkxOGRlMzI0MDBjYzJmMTIyNjFkNmY0ZGY5NzRiYTI3ZDk5NzEiLCJpYXQiOjE1MjM4ODQ3MjUsIm5iZiI6MTUyMzg4NDcyNSwiZXhwIjoxNTU1NDIwNzI1LCJzdWIiOiIzIiwic2NvcGVzIjpbIioiXX0.AdXI1wRtrLnnX5pv5nTKp8xRZzk8-kSCZBhDH-rpAmPq1fDi83eHMSFrz_RtvS0BHDeZUn7lu04PFeqJn-jHgsaeHw0CA0aRyhphM-6P3DgjOEQvgWyfNPGbuxYZMfGZVrwP5k71MafK0jrmzPaBXxKJI2Q9xynip2HeLR6bsrzXQBvuc3M2zz1W1fa0IwoYA_Tm1FhGCg9xG-HMwEJMiGSrV5fIHPu71RPb_Rqbf3A7H7N7hC3hyUzia2nRx-IvExjR3vQe7uQ_2tl2YFCGJ89emZWRhNkJKAU5IDT9zuH2fWFD_zR0iQOiLxA6LqdPT12Hjwn3Lpc5fAgKllvz8mFRYSKzbxZZdTEqPINEkGi1IpiJwcGpisnOQWtUlLT84HxRptmxTIP1QPkef302l-s3ymbDtkJ5vqDHDu2yOD03ghhr0O3LlfqK-u9TOqIcp9sNDPcDJ3q1s4hHiXdIDeLWtX5pPmi_Mn-bpfkQCAy92KxVyZwlCHWBlDY0BhuEBcPS89wCjo1wjTPdyuCTAbn-nPRpJITEhhEne8Kyhqx05cZiuJt3s59sls9qVxFWat3TuGl28qSkO7zm0RgH0QKR1iLKQxqbCCpzJTRd8U50vyDASuPIw-7mbfw6w5YOSm6xVYhJAd5Ur1qdnuH4vVCcKljU0RBCZw9OKQmTqFc',
    development: {
        token: '',
        serverToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImZkODQzNzc4M2VlODY1NGQ5NjRmMTY3YjY4NzRmMDlhOWJmYzc4MzBhZjkyZTAxOGQ2ODA5NTFjYzMzYTRkODUxMGI5NDBiNjM5YTlmMTgxIn0.eyJhdWQiOiIzIiwianRpIjoiZmQ4NDM3NzgzZWU4NjU0ZDk2NGYxNjdiNjg3NGYwOWE5YmZjNzgzMGFmOTJlMDE4ZDY4MDk1MWNjMzNhNGQ4NTEwYjk0MGI2MzlhOWYxODEiLCJpYXQiOjE1NDc0NjAzMDYsIm5iZiI6MTU0NzQ2MDMwNiwiZXhwIjoxNTc4OTk2MzA2LCJzdWIiOiIxODUiLCJzY29wZXMiOlsiKiJdfQ.ga25YS3zzLQHWSw5rKfHF8nK6sQOKzAs_XHE5b89gu8pqbeIzpxTFtNBPAU313IbxGjPP6xfc1ki0LdEhs6G2G6vK0x_9Exd5pKWXL4QMI1UTDijycyCv7f8Ijuhhds4E0v9cBmhqqyx3gz-j-3csjZ-BcXdlBeCKjjd3Ba23oPgJVDZ0LsFih4WYXP4N2rwpsguVndUKHNdxNn2S8xT7crEYSL2609OS1igyUqDwbaoj9r1NuEcv2p6WHmW-g9Sp6W8ITZI6BTS7aD8FDTBHXs_RL6Gm4jTQVm5ih6BTF0Jv0_uUt09Y3-rUwnydwnPskcnAAV38o5rFlA5QLcc_YdU0J-GhnzBo37ewCt44tN7yr_FGdQVumB87Jdpl3KHyc-cr5VhDxntkPKBQuOyBu1L5gTZ-SKhiUCelrf5TSJW72RNzMQhSjvcedOeYBlav_6RMcU9eHBTYW_nmXlcnJQQzr14BNuMFMkAo9Bj8uvFbfCt-LykbuSsjduFoeE2YxEiMtrYfIdfpN-XOKLYGAiGTULhkxtYG6QE0kGD1rE2G9iQUZiWVz0wzHu3UvsA241Rcuy2sep3A92tk7oVHQPoamBv4iUpn6szfZOCaIXrrR-HqkTJQaOH6-RzMg27uFwrDfqmrmlrFyqO9XN8JNmaXGPE3ZfDVM8-nQx2Gdc',
        ...tapApi
    },
    staging: {
        token: '',
        serverToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhiYWYxZGQ5M2RhNjhiYmVlYzMwMjQyZDliN2JiYTM5NDFjNmIxMDE5OTI1ZGRiNDU5OTc1MDA0NWY4OGYzOGRlODI4MWUwMDQwYmI0ZDUzIn0.eyJhdWQiOiI3IiwianRpIjoiOGJhZjFkZDkzZGE2OGJiZWVjMzAyNDJkOWI3YmJhMzk0MWM2YjEwMTk5MjVkZGI0NTk5NzUwMDQ1Zjg4ZjM4ZGU4MjgxZTAwNDBiYjRkNTMiLCJpYXQiOjE1NjM3Njg2MzYsIm5iZiI6MTU2Mzc2ODYzNiwiZXhwIjoxNTk1MzkxMDM2LCJzdWIiOiIzNzQwMiIsInNjb3BlcyI6WyIqIl19.Pl_KMLbxoSPLyjrs_zFgbkEOs_fQFxvJpEfbfgGNxRpgByL-lgSA-K2o3OZbUxlYM1AFAyXiVIUqUKkupacgCfOua4oPlPTAXrWVfMFyi0lDSIWJgDi3EgnL4dIb69AMStp6U2WvOmqS8CisgD2WFr43cB2akBkOkahOq2aCCzVcyyfCjcFiMD-Ap3cw0EtouCf53j8xtNNZQy-gMnsiV8iLmFMPu1hZ0W25iAd4Ict5udnldxSz_YJBx80Kj0o7UiNo68IhpdYFihXORMNJcpBrzH_PR67mfuhdWysXT3oZ7DN1uxTWgWONOXnJvLW2WhrZ4lGIicD1f8gdvsCE-CU80G-NneMpEwvP1xE7hAtlX2XXgiTGMwfQPdT4oQBCUKlFaUvKFtxWHh8efMxQ2pC_7m2lHZcedeWM9jNraxGX1o3DIAm1A7AHC3xhsy-MBJ4oZ0keU6bJ2684NpOOMCmLCIgYb9NMq5a6pWsy3-bEEEdom-T58w078OU_DCopq8clYZZB9o214dmPkdCDTJUWsx6d-A5Fg_9llA7idZ6nkP7WJn_fopFl5jcLs5mcp4vnZvn_fkC_qKFAQpubkZuyH1YiXRN6dohVsURt2tyX84jUDsRq7FJr4uY43103t17JDh85QoG71CqRr5EeajamOUv53H6hZQnJZqq_vMM',
        ...tapApi
    },
    production: {
        token: '',
        serverToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJiMDJkYTc1ZjJiNjlkZTY0MzlhNzIzNzVlZWViOTk4ZjkzOGE1NDVjOTlhZmJkYjFkMTQzYjgzZGEzODA2NzZmNDg0MDFjNWNjYzNmZjgxIn0.eyJhdWQiOiIyIiwianRpIjoiYmIwMmRhNzVmMmI2OWRlNjQzOWE3MjM3NWVlZWI5OThmOTM4YTU0NWM5OWFmYmRiMWQxNDNiODNkYTM4MDY3NmY0ODQwMWM1Y2NjM2ZmODEiLCJpYXQiOjE1NTYwMDg1MjEsIm5iZiI6MTU1NjAwODUyMSwiZXhwIjoxNTg3NjMwOTIxLCJzdWIiOiIxIiwic2NvcGVzIjpbIioiXX0.CHzbgt4QGY-mtOz7DbpA1lcigtqRizo4gnSFq3r1JGkiCz7ZJAslFFAZ861mQqo-SxCjhkV8YP2RWXu-3_QVFHi__IJgsc38cw_lQti4oA1T2oxIfb2102IFNFGKfV2l0t9achpx1FuVesE0QZix2lTVCsiM7zKta98m3NBCU9bWGs-SUWfitNjm3ZWHZRtfQwBUS7CNq7kkdJKFC9n51AoBmwdWMwtlyT5q_R69XEzi1T6cME27-4s6cJwHO-PW22ENfiM8C3VO8UoQuI1lX7amvWSUMEEgo0ckhRpCRympEOsEnjMDKcBiyEEB_xTLoW3OuIXmkl3IcBZyJH0LyHo81f6YvqMXF7Thh-8Amh1SuhV_4JgTKIdjQI0JmZDUW6ouHc4NCbJDVJKZ193nAwNcj2Hb4DAiN6A2C-CQxM2hZs59chn7rUM29xHce-HMZZunvW2rCGnis5wCQQ4B9OxcmL9BxrSxv31OR7C5EnHQUv8gCmGWCbzUCIzshgX453bvcuZlDtCCZrVarmOB75n8l9xovh2Ck48enss70OBx-onm4kvOJ9fI5VF553xvYepUxjV1_E6GDtXK1_9NxK12tC_891XBqbxRzaWZA4_CzhvGvhAg1iem5Zeu656Y4jemXc5KTwPOBsUeh6QcR5R-4bqDx3EuH2khF1ObDJo',
        ...tapApi
    }
};

let devPath = {}
if(process.env.RAZZLE_REACT_APP_ENV === 'development') {
    for(let i in paths) {
        devPath[i] = `${paths[i]}?bypass=1`;
    }
}   

const db = {
    'host': process.env.RAZZLE_DB_HOST,
    "hostRead": process.env.RAZZLE_DB_HOST_READ && process.env.RAZZLE_DB_HOST_READ === 'true' ? [
        process.env.RAZZLE_DB_HOST_READ_REPLICA_1,
        process.env.RAZZLE_DB_HOST_READ_REPLICA_2,
        process.env.RAZZLE_DB_HOST_READ_REPLICA_3,
        process.env.RAZZLE_DB_HOST_READ_REPLICA_4
    ] : undefined,
    'user': process.env.RAZZLE_DB_USER,    
    'password': process.env.RAZZLE_DB_PASSWORD, 
    'database': process.env.RAZZLE_DB_DATABASE
}

const fb = {
    appId: process.env.RAZZLE_FB_APP_ID,
    secret: process.env.RAZZLE_FB_APP_SECRET
}

const insta = {
    appId: process.env.RAZZLE_INSTA_APP_ID,
    secret: process.env.RAZZLE_INSTA_APP_SECRET
}

const twitter = {
    appId: process.env.RAZZLE_TWITTER_APP_ID,
    secret: process.env.RAZZLE_TWITTER_APP_SECRET,
    tokenId: process.env.RAZZLE_TWITTER_TOKEN_ID,
    tokenSecret: process.env.RAZZLE_TWITTER_TOKEN_SECRET
}

const tiktok = {
    appId: process.env.RAZZLE_TIKTOK_APP_ID,
    secret: process.env.RAZZLE_TIKTOK_APP_SECRET
}

const google = {
    appId: process.env.RAZZLE_GOOGLE_APP_ID,
    secret: process.env.RAZZLE_GOOGLE_APP_SECRET
}

const line = {
    appId: process.env.RAZZLE_LINE_APP_ID,
    secret: process.env.RAZZLE_LINE_APP_SECRET
}

const pinterest = {
    appId: process.env.RAZZLE_PINTEREST_APP_ID,
    secret: process.env.RAZZLE_PINTEREST_APP_SECRET
}

const polr = {
    polrDb: {
        'host': process.env.RAZZLE_POLR_DB_HOST,
        'user': process.env.RAZZLE_POLR_DB_USER,    
        'password': process.env.RAZZLE_POLR_DB_PASSWORD, 
        'database': process.env.RAZZLE_POLR_DB_DATABASE
    },
    polrKey: process.env.RAZZLE_POLR_KEY
}

const webengage = {
    license: process.env.RAZZLE_WEBENGAGE_LICENCE,
    token: process.env.RAZZLE_WEBENGAGE_TOKEN
}

const kraken = {
    key: process.env.RAZZLE_KRAKEN_KEY,
    secret: process.env.RAZZLE_KRAKEN_SECRET
}

const security = {
    jwtSecretKey: process.env.RAZZLE_JWT_SECRET_KEY,
    cipherKey: process.env.RAZZLE_CIPHER_KEY,
    cipherIv: process.env.RAZZLE_CIPHER_IV
}

const tokenGeneration = {
    clientId: process.env.RAZZLE_TOKENGEN_CLIENTID,
    clientSecret: process.env.RAZZLE_TOKENGEN_SECRET
}

const moengage = {
    dataApiId: process.env.RAZZLE_MOENGAGE_DATA_API_ID,
    authtoken: process.env.RAZZLE_MOENGAGE_AUTH_TOKEN
}

const devEmailConfig = {
    adminEmail: {
        en: "tech@tickledmedia.com",
        sg: "tech@tickledmedia.com",
        id: "tech@tickledmedia.com",
        my: "tech@tickledmedia.com",
        th: "tech@tickledmedia.com",
        ph: "tech@tickledmedia.com",
        vn: "tech@tickledmedia.com"
    },
    brandEnquiryMail: {
        to: 'sharim.wandrick@tickledmedia.com',
        cc: [{
            email: 'satish.prajapati@tickledmedia.com'
        },
        {
            email: 'aniket.jagtap@tickledmedia.com'
        }],
        attachmentBase: 'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/panels/ebooks',
        attachmentName: 'Digital Mums Survey.pdf'
    },
}

const netcoreConfig = {
    event: {
        VIP_INSTAGRAM_CONNECT_SUCCESS: 'vip_instagram_connect_success',
        VIP_TIKTOK_CONNECT_SUCCESS: 'vip_tiktok_connect_success',
        INFLUENCER_JOB_VIEW: 'influencer_job_view',
        INFLUENCER_JOB_APPLY: 'influencer_job_apply',
        INFLUENCER_APPLICATION_SUBMIT: 'influencer_application_submit',
        VIP_REGISTRATION_APPROVED: 'vip_registration_approved'
    },
    activity: {
        apiHost: process.env.RAZZLE_NETCORE_ACTIVITY_API_HOST,
        apiPath: 'v1/activity/upload',
    },
    contact: {
        apiHost: process.env.RAZZLE_NETCORE_CONTACT_API_HOST,
        apiPath: 'apiv2?data',
    },
    appConfig: JSON.parse(process.env.RAZZLE_NETCORE_APP_CONFIG || '{}'),
    apiKey: process.env.RAZZLE_NETCORE_API_KEY
}

const vipInfluencer = {
    minimumFollowers: parseInt(process.env.RAZZLE_VIP_MINIMUM_FOLLOWERS, 10)
}

const CONFIG = {
    development: {
        siteUrl: process.env.RAZZLE_VIP_SITEURL,
        apiBase: `${process.env.RAZZLE_GTAP_APIBASE}api/v2/`,
        apiBaseInternal: `${process.env.RAZZLE_GTAP_INTERNALHOST}api/v2/`,
        apiBaseVIP: process.env.RAZZLE_VIP_APIBASE, 
        apiBaseOauth: process.env.RAZZLE_GTAP_APIBASE,
        apiVipBackend: process.env.RAZZLE_VIP_BACKEND_API,
        //apiBaseVIP: 'https://dev-influencer.theasianparent.com/',
        //apiBaseVIP: 'https://dev-api.parents.vip/', 
        s3ImgPath: 'https://s3-ap-southeast-1.amazonaws.com/parenttown-prod/community/',
        apiFacebook: `${process.env.RAZZLE_FB_APIBASE}`,
        port: 3000,
        api: tap[process.env.RAZZLE_REACT_APP_ENV],
        paths: devPath,
        communityUrl: process.env.RAZZLE_COMMUNITY_URL,
        communityPollsUrl: 'tap/poll/country/slug/',
        cookieKey: 'STG',
        db,
        fb,
        insta,
        twitter,
        google,
        line,
        pinterest,
        tiktok,
        redisUrl: process.env.RAZZLE_REDIS_URL,
        redisInstance: process.env.RAZZLE_REDIS_INSTANCE,
        ...devEmailConfig,
        ...polr,
        webengage,
        kraken,
        moengage,
        editJobSubmission: {
            minVersion: {
                ios: 20108,
                android: 597,
                web: 1
            }
        },
        vipCreditsFeature: {
            minVersion: {
                ios: 20123,
                android: 622,
                web: 1
            }
        },
        screeningFeature: {
            minVersion: {
                ios: 20138,
                android: 637,
                web: 1
            }
        },
        tiktokIntegration: {
            minVersion: {
                ios: 20123,
                android: 626
            }
        },
        ...security,
        cloudflare: {
            baseUrl: 'https://s3.theasianparent.com/',
            s3BucketsAllowed: [
                'tapfluencer.s3-ap-southeast-1.amazonaws.com',
                'tapfluencer.s3.amazonaws.com',
                'tapfluencer.s3.ap-southeast-1.amazonaws.com'
            ]
        },
        brandAdminAppliedEmailSchedule: '0 17 * * * ',
        userAccessTokenRefreshSchedule: '0 16 * * * ',
        setEligibleInfluencersAsPendingSchedule: '0 16 * * * ',
        fetchCintProfilersSchedule: '0 22 1 * * ',
        tokenGeneration,
        cint: {
            surveyUrl: 'https://www.your-surveys.com/suppliers_api/surveys',
            profilersUrl: 'https://www.your-surveys.com/suppliers_api/surveys/profilers',
            apiKey: process.env.RAZZLE_CINT_APIKEY,
            minVersion: {
                ios: 20138,
                android: 637
            },
            s3BucketUrl: 'https://tapfluencer.s3.ap-southeast-1.amazonaws.com/assets/',
            numRandomImages: 30,
            numRandomDescription: 6,
            imageBaseName: 'TAP+Insight_Generic+Survey_6Oct2021_'
        },
        netcoreConfig,
        vipInfluencer
    },
    staging: {
        siteUrl: process.env.RAZZLE_VIP_SITEURL,
        apiBase: `${process.env.RAZZLE_GTAP_APIBASE}api/v2/`,
        apiBaseInternal: `${process.env.RAZZLE_GTAP_INTERNALHOST}api/v2/`,
        apiBaseVIP: process.env.RAZZLE_VIP_APIBASE,
        apiBaseOauth: process.env.RAZZLE_GTAP_APIBASE,
        apiVipBackend: process.env.RAZZLE_VIP_BACKEND_API,
        //apiBaseVIP: 'https://dev-api.parents.vip/', 
        s3ImgPath: 'https://s3-ap-southeast-1.amazonaws.com/parenttown-prod/community/',
        apiFacebook: `${process.env.RAZZLE_FB_APIBASE}`,
        port: 3000,
        api: tap[process.env.RAZZLE_REACT_APP_ENV],
        paths: paths,
        communityUrl: process.env.RAZZLE_COMMUNITY_URL,
        communityPollsUrl: 'tap/poll/country/slug/',
        cookieKey: 'STG',
        db,
        fb,
        insta,
        twitter,
        google,
        line,
        pinterest,
        tiktok,
        redisUrl: process.env.RAZZLE_REDIS_URL,
        redisInstance: process.env.RAZZLE_REDIS_INSTANCE,
        ...devEmailConfig,
        ...polr,
        webengage,
        kraken,
        moengage,
        editJobSubmission: {
            minVersion: {
                ios: 20108,
                android: 597,
                web: 1
            }
        },
        vipCreditsFeature: {
            minVersion: {
                ios: 20121,
                android: 622,
                web: 1
            }
        },
        screeningFeature: {
            minVersion: {
                ios: 20138,
                android: 637,
                web: 1
            }
        },
        tiktokIntegration: {
            minVersion: {
                ios: 20123,
                android: 626
            }
        },
        ...security,
        cloudflare: {
            baseUrl: 'https://s3.theasianparent.com/',
            s3BucketsAllowed: [
                'tapfluencer.s3-ap-southeast-1.amazonaws.com',
                'tapfluencer.s3.amazonaws.com',
                'tapfluencer.s3.ap-southeast-1.amazonaws.com'
            ]
        },
        brandAdminAppliedEmailSchedule: '0 9 * * * ',
        userAccessTokenRefreshSchedule: '0 20 * * 6 ',
        setEligibleInfluencersAsPendingSchedule: '0 20 * * 7 ',
        fetchCintProfilersSchedule: '0 22 1 * * ',
        tokenGeneration,
        cint: {
            surveyUrl: 'https://www.your-surveys.com/suppliers_api/surveys',
            profilersUrl: 'https://www.your-surveys.com/suppliers_api/surveys/profilers',
            apiKey: process.env.RAZZLE_CINT_APIKEY,
            minVersion: {
                ios: 20138,
                android: 637
            },
            s3BucketUrl: 'https://tapfluencer.s3.ap-southeast-1.amazonaws.com/assets/',
            numRandomImages: 30,
            numRandomDescription: 6,
            imageBaseName: 'TAP+Insight_Generic+Survey_6Oct2021_'
        },
        netcoreConfig,
        vipInfluencer
    },
    production: {
        siteUrl: process.env.RAZZLE_VIP_SITEURL,
        apiBase: `${process.env.RAZZLE_GTAP_APIBASE}api/v2/`,
        apiBaseInternal: `${process.env.RAZZLE_GTAP_INTERNALHOST}api/v2/`,
        apiBaseVIP: process.env.RAZZLE_VIP_APIBASE, 
        apiBaseOauth: process.env.RAZZLE_GTAP_APIBASE,
        apiVipBackend: process.env.RAZZLE_VIP_BACKEND_API,
        s3ImgPath: 'https://s3-ap-southeast-1.amazonaws.com/parenttown-prod/community/',
        apiFacebook: `${process.env.RAZZLE_FB_APIBASE}`,
        port: 3000,
        api: tap[process.env.RAZZLE_REACT_APP_ENV],
        paths: paths,
        communityUrl: process.env.RAZZLE_COMMUNITY_URL,
        communityPollsUrl: 'tap/poll/country/slug/',
        cookieKey: 'PRD',
        db,
        fb,
        insta,
        twitter,
        google,
        line,
        pinterest,
        tiktok,
        redisUrl: process.env.RAZZLE_REDIS_URL,
        redisInstance: process.env.RAZZLE_REDIS_INSTANCE,
        adminEmail: {
            en: "vipadmin@tickledmedia.com", //"influencer-admin@tickledmedia.com",
            sg: "vipadminsg@tickledmedia.com",
            id: "vipadminid@tickledmedia.com",
            my: "vipadminmy@tickledmedia.com",
            th: "vipadminth@tickledmedia.com",
            ph: "vipadminph@tickledmedia.com",
            vn: "vipadminvn@tickledmedia.com"
        },
        ...polr,
        webengage,
        kraken,
        moengage,
        brandEnquiryMail: {
            to: 'vipadmin@tickledmedia.com',
            cc: [{
                email: 'heena@tickledmedia.com'
            }, {
                email: 'jessica@tickledmedia.com'
            }],
            attachmentBase: 'https://tapfluencer.s3-ap-southeast-1.amazonaws.com/assets/panels/ebooks',
            attachmentName: 'Digital Mums Survey.pdf'
        },
        editJobSubmission: {
            minVersion: {
                ios: 20111,
                android: 606,
                web: 1
            }
        },
        vipCreditsFeature: {
            minVersion: {
                ios: 20121,
                android: 622,
                web: 1
            }
        },
        screeningFeature: {
            minVersion: {
                ios: 20138,
                android: 637,
                web: 1
            }
        },
        tiktokIntegration: {
            minVersion: {
                ios: 20124,
                android: 628
            }
        },
        ...security,
        cloudflare: {
            baseUrl: 'https://s3.theasianparent.com/',
            s3BucketsAllowed: [
                'tapfluencer.s3-ap-southeast-1.amazonaws.com',
                'tapfluencer.s3.amazonaws.com',
                'tapfluencer.s3.ap-southeast-1.amazonaws.com'
            ]
        },
        brandAdminAppliedEmailSchedule: '0 5 * * * ',
        userAccessTokenRefreshSchedule: '0 20 * * 6 ',
        setEligibleInfluencersAsPendingSchedule: '0 20 * * 7 ',
        fetchCintProfilersSchedule: '0 22 1 * * ',
        tokenGeneration,
        cint: {
            surveyUrl: 'https://www.your-surveys.com/suppliers_api/surveys',
            profilersUrl: 'https://www.your-surveys.com/suppliers_api/surveys/profilers',
            apiKey: process.env.RAZZLE_CINT_APIKEY,
            minVersion: {
                ios: 20138,
                android: 637
            },
            s3BucketUrl: 'https://tapfluencer.s3.ap-southeast-1.amazonaws.com/assets/',
            numRandomImages: 30,
            numRandomDescription: 6,
            imageBaseName: 'TAP+Insight_Generic+Survey_6Oct2021_'
        },
        netcoreConfig,
        vipInfluencer
    }
    
};

const config = {
    ...CONFIG[process.env.RAZZLE_REACT_APP_ENV],
    ...( process.env.RAZZLE_TEST_APP_ENV === 'uat' && devEmailConfig )
}

export default config;
