import React, { Fragment } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BaseLayout from '../../../../components/Layout/Brand/BaseLayout';
import { genericAction } from '../../../../actions';
import CurrentPanelSidebar from '../../../../components/Brand/Sidebar/CurrentPanel';
import { Row, Col } from 'antd';
import CardElem from '../../../../components/UIElems/Card';
import SubmissionsWidget from '../../../Stats/Widget/Panel/Submissions';
import EmailWidget from '../../../Stats/Widget/Panel/Email';

const PanelHome = (props) => {

    return (
        <BaseLayout leftCards={[
            <CurrentPanelSidebar panelId={props.match.params.id} currentTitle='Home'/>
            ]}>
            <div className="campaign-stats">
                <h1>Home - Panel Jobs</h1>

                <h3>{props.rdx.brandReducer.currentPanel.jobName}</h3>
                
                <Row type="flex" justify="space-between">
                    <Col span={11}>
                        <CardElem bordered={true}>
                            <SubmissionsWidget panelId={props.match.params.id} />
                        </CardElem>
                    </Col>
                    <Col span={11}>
                        <CardElem bordered={true}>
                            <EmailWidget panelId={props.match.params.id} />
                        </CardElem>
                    </Col>
                </Row>
            </div>
        </BaseLayout>
    );
}

//map state
const mapStateToProps = (state, ownProps) => ({rdx: state});

//map dispatch
const mapDispatchToProps = (dispatch, ownProps) => ({
    actions: bindActionCreators({
        genericAction
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PanelHome);