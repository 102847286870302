import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames/bind';

const Default = (props) => {
    return (
        <div className="template-default">
            {props.children}
        </div>
    );
}

export default Default;